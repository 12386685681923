
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component, OnChanges, Input, Output, EventEmitter, ChangeDetectorRef,
  OnInit, OnDestroy, LOCALE_ID, Inject, TemplateRef, ElementRef, ViewChild, AfterViewInit
} from "@angular/core";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { CalendarEvent, WeekDay, MonthView, MonthViewDay, ViewPeriod } from "calendar-utils";
import { Subject, Subscription, takeUntil } from "rxjs";
import { CalendarAppointmentTimesChangedEvent, CalendarAppointmentTimesChangedEventType } from "../common/calendar-appointment-times-changed-event.interface";
import { CalendarUtils } from "../common/calendar-utils.provider";
import { validateEvents } from "../common/util";
import { DateAdapter } from "../date-adapters/date-adapter";
import { PlacementArray } from "positioning";
import { ConfigService } from "src/app/config.service";

export interface CalendarMonthViewBeforeRenderEvent {
  header: WeekDay[];
  body: MonthViewDay[];
  period: ViewPeriod;
}

export interface CalendarMonthViewAppointmentTimesChangedEvent
  <EventMetaType = any, DayMetaType = any> extends CalendarAppointmentTimesChangedEvent<EventMetaType> { day: MonthViewDay<DayMetaType>; }

@Component({
  selector: "vp-calendar-month-view",
  templateUrl: "./calendar-month-view.component.html",
})
export class CalendarMonthViewComponent
  implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  @Input() viewDate: Date;
  @Input() events: CalendarEvent[] = [];
  @Input() excludeDays: number[] = [];
  @Input() activeDayIsOpen: boolean = false;
  @Input() activeDay: Date;
  @Input() refresh: Subject<any>;
  @Input() locale: string;
  @Input() tooltipPlacement: PlacementArray = "auto";
  @Input() tooltipTemplate: TemplateRef<any>;
  @Input() tooltipAppendToBody: boolean = true;
  @Input() tooltipDelay: number | null = null;
  @Input() weekStartsOn: number;
  @Input() headerTemplate: TemplateRef<any>;
  @Input() cellTemplate: TemplateRef<any>;
  @Input() openDayEventsTemplate: TemplateRef<any>;
  @Input() eventTitleTemplate: TemplateRef<any>;
  @Input() eventActionsTemplate: TemplateRef<any>;
  @Input() weekendDays: number[];
  @Input() isSidebarExpanded: boolean = false;
  @Output() beforeViewRender = new EventEmitter<CalendarMonthViewBeforeRenderEvent>();
  @Output() dayClicked = new EventEmitter<{ day: MonthViewDay; }>();
  @Output() eventClicked = new EventEmitter<{ event: CalendarEvent; isSelecting: boolean }>();
  @Output() dayHeaderClicked = new EventEmitter<Date>();
  @Output() columnHeaderClicked = new EventEmitter<number>();
  @Output() appointmentTimesChanged = new EventEmitter<CalendarMonthViewAppointmentTimesChangedEvent>();
  @Output() onViewSwiped = new EventEmitter<any>();
  @Output() onContextMenuClicked = new EventEmitter<any>();
  @Output() handleDoubleClicked = new EventEmitter<{ event: CalendarEvent; }>();
  @Output() hourSegmentContextMenuClicked = new EventEmitter<any>();
  @Output() longPressClicked = new EventEmitter<any>();

  @ViewChild("calMonthView", {static: false}) calMonthView: ElementRef;

  columnHeaders: WeekDay[];
  view: MonthView;
  openRowIndex: number;
  openDay: MonthViewDay;
  eventRefresh: Subject<any> = new Subject();
  refreshSubscription: Subscription;
  isMobileScreen: boolean = false;
  rowOffsetHeight: number;
  colCellWidth: number;
  setupHammer: boolean;

  private isAlive$ = new Subject<boolean>();
  showCalendarWeekNumber: boolean = false;

  trackByRowOffset = (index: number, offset: number) =>
    this.view.days.slice(offset, this.view.totalDaysVisibleInWeek).map(day => day.date.toISOString()).join("-")

  trackByDate = (index: number, day: MonthViewDay) => day.date.toISOString();

  constructor(
    protected cdr: ChangeDetectorRef,
    protected utils: CalendarUtils,
    @Inject(LOCALE_ID) locale: string,
    protected dateAdapter: DateAdapter,
    private breakpointObserver: BreakpointObserver,
    private configService: ConfigService
  ) {
    this.locale = locale;
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
    this.showCalendarWeekNumber = this.isShowWeekNumberEnabled();
  }

  ngOnInit(): void {
    if (this.refresh) {
      this.refreshSubscription = this.refresh.subscribe(() => {
        this.refreshAll();
        this.cdr.markForCheck();
      });
    }
    this.breakpointObserver
    .observe(["(max-width: 599px)"])
    .pipe(takeUntil(this.isAlive$))
    .subscribe((state: BreakpointState) => {
      if (state.matches && !this.setupHammer) {
        if (!!document.querySelector(".cal-month-view")) {
          this.setupHammer = true;
          new Hammer(<HTMLElement> document.querySelector(".cal-month-view")).on("swipeleft", () => {
            this.onViewSwiped.emit("swipeleft");
          });
          new Hammer(<HTMLElement> document.querySelector(".cal-month-view")).on("swiperight", () => {
              this.onViewSwiped.emit("swiperight");
          });
        }
      }
    });
    // this.calculateRowOffsetHeight();
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: any): void {
    const refreshHeader =
      changes.viewDate || changes.excludeDays || changes.weekendDays;
    const refreshBody =
      changes.viewDate ||
      changes.events ||
      changes.excludeDays ||
      changes.weekendDays;

    if (refreshHeader) {
      this.refreshHeader();
    }

    if (changes.events) {
      validateEvents(this.events);
    }

    if (refreshBody) {
      this.refreshBody();
    }

    if (refreshHeader || refreshBody) {
      this.emitBeforeViewRender();
    }

    if (
      changes.activeDayIsOpen ||
      changes.viewDate ||
      changes.events ||
      changes.excludeDays ||
      changes.activeDay
    ) {
      this.checkActiveDayIsOpen();
    }
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  toggleDayHighlight(event: CalendarEvent, isHighlighted: boolean): void {
    this.view.days.forEach(day => {
      if (isHighlighted && day.events.indexOf(event) > -1) {
        day.backgroundColor =
          (event.color && event.color.secondary) || "#D1E8FF";
      } else {
        delete day.backgroundColor;
      }
    });
  }

  eventDropped(
    droppedOn: MonthViewDay,
    event: CalendarEvent,
    draggedFrom?: MonthViewDay
  ): void {
    if (droppedOn !== draggedFrom) {
      const year: number = this.dateAdapter.getYear(droppedOn.date);
      const month: number = this.dateAdapter.getMonth(droppedOn.date);
      const date: number = this.dateAdapter.getDate(droppedOn.date);
      const newStart: Date = this.dateAdapter.setDate(
        this.dateAdapter.setMonth(
          this.dateAdapter.setYear(event.start, year),
          month
        ),
        date
      );
      let newEnd: Date;
      if (event.end) {
        const secondsDiff: number = this.dateAdapter.differenceInSeconds(
          newStart,
          event.start
        );
        newEnd = this.dateAdapter.addSeconds(event.end, secondsDiff);
      }
      this.appointmentTimesChanged.emit({
        event,
        newStart,
        newEnd,
        day: droppedOn,
        type: CalendarAppointmentTimesChangedEventType.Drop
      });
    }
  }

  handleAppointmentClicked($event: any) {
    this.eventRefresh.next({ event: $event.event, isSelecting: $event.isSelecting });
    this.eventClicked.emit({ event: $event.event, isSelecting: $event.isSelecting });
  }

  onViewSwipe(event) {
    if (this.isMobileScreen) {
      this.onViewSwiped.emit(event.type);
    }
  }

  protected refreshHeader(): void {
    this.columnHeaders = this.utils.getWeekViewHeader({
      viewDate: this.viewDate,
      weekStartsOn: this.weekStartsOn,
      excluded: this.excludeDays,
      weekendDays: this.weekendDays
    });
  }

  protected refreshBody(): void {
    this.view = this.utils.getMonthView({
      events: this.events,
      viewDate: this.viewDate,
      weekStartsOn: this.weekStartsOn,
      excluded: this.excludeDays,
      weekendDays: this.weekendDays
    });

    // this.calculateRowOffsetHeight();
  }

  protected checkActiveDayIsOpen(): void {
    if (this.activeDayIsOpen === true) {
      const activeDay = this.activeDay || this.viewDate;
      this.openDay = this.view.days.find(day =>
        this.dateAdapter.isSameDay(day.date, activeDay)
      );
      const index: number = this.view.days.indexOf(this.openDay);
      this.openRowIndex =
        Math.floor(index / this.view.totalDaysVisibleInWeek) *
        this.view.totalDaysVisibleInWeek;
    } else {
      this.openRowIndex = null;
      this.openDay = null;
    }
  }

  protected refreshAll(): void {
    this.refreshHeader();
    this.refreshBody();
    this.emitBeforeViewRender();
    this.checkActiveDayIsOpen();
  }

  protected emitBeforeViewRender(): void {
    if (this.columnHeaders && this.view) {
      this.beforeViewRender.emit({
        header: this.columnHeaders,
        body: this.view.days,
        period: this.view.period
      });
    }
  }

  protected calculateRowOffsetHeight() {
    const parentElement: HTMLElement = this.calMonthView.nativeElement;
    this.rowOffsetHeight = (parentElement.clientHeight - 40) / this.view.rowOffsets.length;
    this.colCellWidth = Math.round((parentElement.clientWidth / 7) - 6);
  }

  handleDoubleClickedHandled(ev): void {
    this.handleDoubleClicked.emit({event: ev.event});
  }

  isShowWeekNumberEnabled(): boolean {
    let showCalendarWeek: boolean = false;
    const showWeek = this.configService.prefs.zimbraPrefShowCalendarWeek;
    if (!!showWeek && showWeek === "TRUE") {
      showCalendarWeek = true;
    }
    return showCalendarWeek;
  }

}
