
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ContactService } from "../services/contact-service";
import { Store, select } from "@ngrx/store";
import {
  getIsContactsNextPageLoading,
  getIsContactssNextPageLoaded,
  getContactsCurrentPageOffset,
  getIsMoreContacts,
  getRoutedFolderTitle
} from "../store/reducers/index";
import {
  LoadContactsSuccess,
  LoadContacts,
  LoadContactsFail,
  CreateContact,
  CreateContactFail,
  CreateContactSuccess,
  RemoveContact,
  RemoveContactFail,
  RemoveMultipleContactSuccess,
  UpdateContact,
  UpdateContactFail,
  UpdateContactSuccess,
  StopLoading,
  StartLoading,
  EmptyContactSuccess
} from "../store/actions/contacts.action";
import { ContactFolder } from "../models/contact-folder.model";
import { Injectable } from "@angular/core";
import { ContactRootState } from "../store/reducers/index";
import { ErrorService } from "../../common/providers/error-service";
import { ErrorType } from "../shared/contacts-enum";
import { SuccessService } from "../../common/providers/sucess-service";
import { TranslateService } from "@ngx-translate/core";
import { ContactConstants } from "../shared/contacts-constants";
import { SuccessType } from "../shared/contacts-enum";
import { ContactUtils } from "../../common/utils/contact-utils";
import { Contact } from "../models/contact.model";
import { NextLoadContacts, NextLoadContactsSuccess, NextLoadContactsFail } from "../store/actions/contacts.action";
import { RestoreContactSuccess } from "../store/index";
import { ContactsFolderRepository } from "./contact-folder.repository";
import { MessageTranslatorService } from "../services/message-translator-service";
import { getOnlineStatus } from "../../reducers";
import { take } from "rxjs/operators";
import { CommonUtils } from "src/app/common/utils/common-util";
import { getContactFolders } from "../store/selectors";
import { Observable, Subject } from "rxjs";

@Injectable()
export class ContactsRepository {
  private limit: number = 100;
  private backupSourceFolder: ContactFolder = null;
  private backupContacts: Contact[] = [];
  private translatedMessages: any = {};
  isMobileView = CommonUtils.isOnMobileDevice();
  isOnline: boolean = true;
  folderTitle: string;
  constructor(
    private contactService: ContactService,
    public store: Store<ContactRootState>,
    private errorService: ErrorService,
    private success: SuccessService,
    private translate: TranslateService,
    private contactsFolderRepository: ContactsFolderRepository,
    private messageTranslatorService: MessageTranslatorService
  ) {

    this.store.select(getOnlineStatus).subscribe(res => {
      this.isOnline = res;
    });

    this.translatedMessages = this.messageTranslatorService.getTranslations();
    console.log("[ContactsRepository]", this.translatedMessages);

    this.messageTranslatorService.tarnslatedMessagesd$.subscribe(res => {
      this.translatedMessages = res;
      console.log("[ContactsRepository] translatedMessages", res);
    });
  }

  public getContacts(query: string, contactList?: number, noMailFilter?: boolean) {
    let offset = 0;
    if (query || contactList) {
      console.log("[getContacts]", query);
      this.store.dispatch(new LoadContacts());
      this.contactService.getAllContacts(offset, this.limit, query, contactList, noMailFilter).pipe(take(1)).subscribe(res => {
        let contacts: Contact[] = res;
        let isMoreContacts = false;
        offset = contacts.length;
        if (contacts.length === this.limit) {
          isMoreContacts = true;
        } else {
          offset = 0;
          isMoreContacts = false;
        }
        this.store.dispatch(new LoadContactsSuccess({ currOffset: offset, isMoreContacts: isMoreContacts, contact: contacts }));
        contacts.forEach(c => {
          let data = localStorage.getItem(c.id + "_photo");
          if (c.image && (!data || data === undefined)) {
            this.getLoadImage(c, this);
          }
        });
      }, err => {
        if (err === undefined) {
          this.store.dispatch(new LoadContactsFail());
          this.errorService.emit({ id: ErrorType.Generic, messages: this.translatedMessages.NETWORK_CONNECTION_LOST });
          return;
        }
        this.store.dispatch(new LoadContactsFail());
        this.errorService.emit({ id: ErrorType.Generic, messages: err });
      });
    }
  }

  public loadMoreContacts(query: string) {
    let nextPageLoading: boolean;
    let nextPageLoaded: boolean;
    const nextPageLoading$ = this.store.select(getIsContactsNextPageLoading).pipe(take(1)).subscribe(o => nextPageLoading = o);
    const nextPageLoaded$ = this.store.select(getIsContactssNextPageLoaded).pipe(take(1)).subscribe(o => nextPageLoaded = o);

    let offset = 0;
    let isMoreContacts = false;
    this.store.select(getContactsCurrentPageOffset).pipe(take(1)).subscribe(o => offset = o);
    this.store.select(getIsMoreContacts).pipe(take(1)).subscribe(l => isMoreContacts = l);
    if (nextPageLoading) {
      return;
    }

    if (!isMoreContacts) {
      return;
    }
    this.store.dispatch(new NextLoadContacts());
    this.contactService.getAllContacts(offset, this.limit, query).subscribe(contacts => {
      const selector = <HTMLElement> document.querySelector(".contact-list-height");
      if (selector !== null) {
        selector.scrollTop = selector.scrollHeight - 1350;
      }
      let newOffset = offset + contacts.length;
      if (contacts.length === this.limit) {
        isMoreContacts = true;
      } else {
        newOffset = 0;
        isMoreContacts = false;
      }
      this.store.dispatch(new NextLoadContactsSuccess({
        currOffset: newOffset,
        isMoreContacts: isMoreContacts,
        contacts: contacts as Contact[]
      }));
      contacts.forEach(c => {
        let data = localStorage.getItem(c.id + "_photo");
        if (c.image && (!data || data === undefined)) {
          this.getLoadImage(c, this);
        }
      });
    }, err => {
      if (err === undefined) {
        this.store.dispatch(new NextLoadContactsFail());
        this.errorService.emit({ id: ErrorType.Generic, messages: this.translatedMessages.NETWORK_CONNECTION_LOST });
        return;
      }
      this.store.dispatch(new NextLoadContactsFail());
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
  }

  getLoadImage(contact: Contact, currentRepo: any) {
    this.contactService.getContactBlobAvtar(contact.image).subscribe(x => {
      let reader = new FileReader();
      reader.onloadend = (e) => {
        localStorage.setItem(contact.id + "_photo", reader.result.toString());
        contact.imageData = reader.result;
        currentRepo.store.dispatch(new UpdateContactSuccess({ id: contact.id, changes: contact }));
      };
      reader.readAsDataURL(x);
    });
  }

  createContact(contactRequest: any, isContactInSameList?: boolean): Observable<any> {
    this.store.dispatch(new CreateContact());
    const subject = new Subject<any>();
    this.contactService.createContact(contactRequest).subscribe(
      res => {
        let api_url = this.contactService.getApiUrl();
        if (!res.duplicates && res.cn) {
          let newContact = ContactUtils.getContact(res.cn, api_url);
          if (newContact.image) {
            this.getLoadImage(newContact, this);
          }
          if (isContactInSameList) {
            this.store.dispatch(new CreateContactSuccess(newContact));
          } else {
            this.store.dispatch(new CreateContactFail());
          }
          this.contactsFolderRepository.mantainSourceFolder();
          this.updateContactListById(contactRequest.folderId);
          this.translate.get(this.translatedMessages.CONTACT_CREATED_MSG).pipe(take(1)).subscribe(text => {
            this.success.emit({ id: SuccessType.ContactCreateUpdate, messages: text });
          });
        } else {
          this.store.dispatch(new CreateContactFail());
        }
        subject.next(res);
      }, err => {
        subject.error(err);
        if (err === undefined) {
          this.store.dispatch(new CreateContactFail());
          this.translate.get(this.translatedMessages.NETWORK_CONNECTION_LOST).pipe(take(1)).subscribe(text => {
            this.errorService.emit({ id: SuccessType.ContactCreateUpdate, messages: text });
          });
          return;
        }
        this.store.dispatch(new CreateContactFail());
        if (err === "CONTACT_MUST_HAVE_FIELDS") {
          this.translate.get("CONTACT_MUST_HAVE_FIELDS").pipe(take(1)).subscribe(text => {
            this.errorService.emit({ id: SuccessType.ContactCreateUpdate, messages: text });
          });
          return;
        } else {
          this.errorService.emit({ id: ErrorType.Generic, messages: err });
        }
      });
    return subject.asObservable();
  }

  createContactOnly(contactRequest: any): Observable<any> {
    const subject = new Subject<any>();
    this.contactService.createContact(contactRequest).subscribe(
      res => {
        subject.next(res);
      }, err => {
        subject.error(err);
      });
    return subject.asObservable();
  }

  createVNCdContact(contactRequest: any): Observable<any> {
    const subject = new Subject<any>();
    this.contactService.createVNCdContact(contactRequest).subscribe(
      res => {
        subject.next(res);
      }, err => {
        subject.error(err);
      });
    return subject.asObservable();
  }

  updateVNCdContact(contactRequest: any): Observable<any> {
    const subject = new Subject<any>();
    this.contactService.updateVNCdContact(contactRequest).subscribe(
      res => {
        subject.next(res);
      }, err => {
        subject.error(err);
      });
    return subject.asObservable();
  }

  updateContactVNCd(contactRequest: any): Observable<any> {
    return this.contactService.updateContact(contactRequest);
  }

  createContactGroup(contactRequest: any, isGroupInSameList: boolean = true) {
    this.store.dispatch(new CreateContact());
    this.contactService.createContactGroup(contactRequest).subscribe(
      res => {
        let api_url = this.contactService.getApiUrl();
        let newContact = ContactUtils.getContact(res.cn, api_url);
        if (newContact.image) {
          this.getLoadImage(newContact, this);
        }
        if (isGroupInSameList) {
          this.store.dispatch(new CreateContactSuccess(newContact));
        } else {
          this.store.dispatch(new CreateContactFail());
        }
        this.contactsFolderRepository.mantainSourceFolder();
        this.updateContactListById(contactRequest.folderId);
        this.translate.get(this.translatedMessages.CONTACT_CREATED_MSG).pipe(take(1)).subscribe(text => {
          this.success.emit({ id: SuccessType.ContactCreateUpdate, messages: text });
        });
      }, err => {
        if (err === undefined) {
          this.store.dispatch(new CreateContactFail());
          this.translate.get(this.translatedMessages.NETWORK_CONNECTION_LOST).pipe(take(1)).subscribe(text => {
            this.errorService.emit({ id: SuccessType.ContactCreateUpdate, messages: text });
          });
          return;
        }
        this.store.dispatch(new CreateContactFail());
        if (err === "CONTACT_MUST_HAVE_FIELDS") {
          this.translate.get("CONTACT_MUST_HAVE_FIELDS").pipe(take(1)).subscribe(text => {
            this.errorService.emit({ id: SuccessType.ContactCreateUpdate, messages: text });
          });
          return;
        } else {
          this.errorService.emit({ id: ErrorType.Generic, messages: err });
        }
      });
  }

  deleteContacts(selectedContacts: Contact[], sourceFolder, destinationFolder) {
    {
      let jsonBody = {
        id: selectedContacts.map(c => c.id).toString(),
        op: "move",
        folderId: destinationFolder.id
      };
      this.store.dispatch(new RemoveContact());
      if (!this.isOnline) {
        this.deleteOfflineContacts(selectedContacts, destinationFolder);
        return;
      }
      this.contactService.contactAction(jsonBody).subscribe(
        res => {
          let msg;
          msg = selectedContacts.length + " " + this.translatedMessages.CONTACT_MOVED_TO + " " + destinationFolder.name;
          this.store.dispatch(new RemoveMultipleContactSuccess(selectedContacts.map(c => c.id)));
          this.backupContacts = selectedContacts;
          this.deleteAllContactAvtar(selectedContacts);
          this.contactsFolderRepository.mantainSourceFolder();
          this.success.emit({ id: SuccessType.ContactDelete, messages: msg });
        },
        err => {
          if (err === undefined) {
            this.store.dispatch(new RemoveContactFail());
            this.errorService.emit({ id: ErrorType.Generic, messages: this.translatedMessages.NETWORK_CONNECTION_LOST });
            return;
          }
          this.store.dispatch(new RemoveContactFail());
          this.errorService.emit({ id: ErrorType.Generic, messages: err });
        }
      );
    }
  }

  updateContact(
    existingContact: Contact, contactRequest: any, isAvtarRemove?: boolean, isContactInSameList: boolean = true, selectedListId?: string
  ) {
    this.store.dispatch(new UpdateContact());
    this.store.dispatch(new StartLoading());
    this.contactService.modifyContact(contactRequest).subscribe(
      res => {
        if (!isContactInSameList) {
          if (existingContact.m) {
              this.changeContactsList([existingContact], selectedListId);
          } else {
            this.changeContactsList([existingContact], selectedListId);
          }
        }
        let api_url = this.contactService.getApiUrl();
        this.upadteExstingContact(existingContact, ContactUtils.getContact(res.cn, api_url));
        if (existingContact.image) {
          this.getLoadImage(existingContact, this);
        } else {
          existingContact.imageData = null;
        }
        this.store.dispatch(new UpdateContactSuccess({ id: existingContact.id, changes: existingContact }));
        if (isAvtarRemove) {
          this.success.emit({ id: SuccessType.GenericMessage, messages: this.translatedMessages.IMAGE_REMOVE_MSG });
        } else {
          this.success.emit({ id: SuccessType.ContactCreateUpdate, messages: this.translatedMessages.CONTACT_UPDATED_MSG });
        }
        this.store.dispatch(new StopLoading());
      }, err => {
        if (err === undefined) {
          this.store.dispatch(new UpdateContactFail());
          this.errorService.emit({ id: ErrorType.Generic, messages: this.translatedMessages.NETWORK_CONNECTION_LOST });
          this.store.dispatch(new StopLoading());
          return;
        }
        this.store.dispatch(new UpdateContactFail());
        if (err === "CONTACT_MUST_HAVE_FIELDS") {
          this.translate.get("CONTACT_MUST_HAVE_FIELDS").pipe(take(1)).subscribe(text => {
            this.errorService.emit({ id: SuccessType.ContactCreateUpdate, messages: text });
          });
          return;
        } else {
          this.errorService.emit({ id: ErrorType.Generic, messages: err });
        }
        this.store.dispatch(new StopLoading());
      });
  }

  restoreContacts(sourceFolder, destinationFolder) {
    {
      let jsonBody = {
        id: this.backupContacts.map(c => c.id).toString(),
        op: "move",
        folderId: destinationFolder.id
      };
      this.backupContacts.forEach(c => c.isChecked = false);
      this.store.dispatch(new RemoveContact());
      this.contactService.contactAction(jsonBody).subscribe(
        res => {
          let msg;
          msg = this.backupContacts.length + " " + this.translatedMessages.CONTACT_MOVED_TO + " "
          + CommonUtils.getShortNameForMobile(destinationFolder.name, 10);
          this.backupContacts.forEach(cn => {
            if (cn.image) {
              this.getLoadImage(cn, this);
            }
          });
          this.store.dispatch(new RestoreContactSuccess(this.backupContacts));
          this.contactsFolderRepository.mantainDestinationFolder(this.backupSourceFolder);
          this.contactsFolderRepository.mantainSourceFolder();
          this.backupContacts = [];
          this.success.emit({ id: SuccessType.GenericMessage, messages: msg });
        },
        err => {
          this.store.dispatch(new RemoveContactFail());
          this.errorService.emit({ id: ErrorType.Generic, messages: err });
        }
      );
    }
  }

  moveContacts(selectedContacts: Contact[], sourceFolder, destinationFolder) {
    {
      let jsonBody = {
        id: selectedContacts.map(c => c.id).toString(),
        op: "move",
        folderId: destinationFolder.id
      };
      this.store.dispatch(new RemoveContact());
      this.store.select(getRoutedFolderTitle).pipe(take(1)).subscribe(title => {
        this.folderTitle = title;
      });
      this.contactService.contactAction(jsonBody).subscribe(
        res => {
          let msg;
          msg = selectedContacts.length + " " + this.translatedMessages.CONTACT_MOVED_TO
          + " " + CommonUtils.getShortNameForMobile(destinationFolder.name, 10);
          this.store.dispatch(new RemoveMultipleContactSuccess(selectedContacts.map(c => c.id)));
          this.backupContacts = selectedContacts;
          this.backupSourceFolder = destinationFolder;
          if (this.folderTitle !== "search") {
            this.contactsFolderRepository.mantainDestinationFolder(destinationFolder);
            this.contactsFolderRepository.mantainSourceFolder();
            this.success.emit({ id: SuccessType.ContactMove, messages: msg });
          } else {
            this.success.emit({ id: SuccessType.SearchContactMove, messages: msg });
            this.contactsFolderRepository.getContactFolders();
          }
        },
        err => {
          if (err === undefined) {
            this.store.dispatch(new RemoveContactFail());
            this.errorService.emit({ id: ErrorType.Generic, messages: this.translatedMessages.NETWORK_CONNECTION_LOST });
            return;
          }
          this.store.dispatch(new RemoveContactFail());
          this.errorService.emit({ id: ErrorType.Generic, messages: err });
        }
      );
    }
  }

  emptyContacts(contactFolder: ContactFolder) {
    {
      let jsonBody = {
        id: contactFolder.id,
        op: "empty",
        recursive: true
      };
      this.store.dispatch(new RemoveContact());
      this.contactService.folderAction(jsonBody).subscribe(
        res => {
          this.contactsFolderRepository.mantainSourceFolder();
          this.store.dispatch(new EmptyContactSuccess());
          this.success.emit({ id: SuccessType.GenericMessage, messages: this.translatedMessages.EMPTY_ITEM_MSG });
        },
        err => {
          if (err === undefined) {
            this.store.dispatch(new RemoveContactFail());
            this.errorService.emit({ id: ErrorType.Generic, messages: this.translatedMessages.NETWORK_CONNECTION_LOST });
            return;
          }
          this.store.dispatch(new RemoveContactFail());
          this.errorService.emit({ id: ErrorType.Generic, messages: err });
        }
      );
    }
  }

  createOfflineContact(contact: Contact) {
    this.store.dispatch(new CreateContact());
    this.contactsFolderRepository.updateOfflineFolder(1);
    this.store.dispatch(new CreateContactSuccess(contact));
    this.success.emit({ id: SuccessType.ContactCreateUpdate, messages: this.translatedMessages.CONTACT_CREATED_MSG });
  }

  modifyOfflineContact(contact: Contact) {
    this.store.dispatch(new UpdateContact());
    this.store.dispatch(new UpdateContactSuccess({ id: contact.id, changes: contact }));
    this.success.emit({ id: SuccessType.ContactCreateUpdate, messages: this.translatedMessages.CONTACT_UPDATED_MSG });
  }

  deleteOfflineContacts(selectedContacts: Contact[], folder) {
    let jsonBody = {
      id: [] = selectedContacts.map(c => c.id),
      op: "move",
      folderId: folder.id
    };
    let body: any = localStorage.getItem("deleteItems");
    if (body && body !== undefined) {
      let body2 = JSON.parse(body);
      selectedContacts.forEach(c => {
        if (!c.isOfflineCreate) {
          body2.id.push(c.id);
        }
      });
      localStorage.removeItem("deleteItems");
      jsonBody.id = body2.id;
      localStorage.setItem("deleteItems", JSON.stringify(jsonBody));
    } else {
      localStorage.setItem("deleteItems", JSON.stringify(jsonBody));
    }
    this.store.dispatch(new RemoveMultipleContactSuccess(selectedContacts.map(c => c.id)));
    this.contactsFolderRepository.updateOfflineFolder(-selectedContacts.length);
    let msg = selectedContacts.length + " " + this.translatedMessages.CONTACT_MOVED_TO + " " + folder.name;
    this.success.emit({ id: SuccessType.ContactDelete, messages: msg });
  }

  addOfflineFavouriteContacts(contacts: Contact[]) {
    let jsonBody = {
      id: contacts.map(c => c.id),
      tn: ContactConstants.FAVORITE_TAG,
      op: "tag"
    };

    let body: any = localStorage.getItem("addFavourite");
    if (body && body !== undefined) {
      let body2 = JSON.parse(body);
      contacts.forEach(c => {
        body2.id.push(c.id);
      });
      localStorage.removeItem("addFavourite");
      jsonBody.id = body2.id;
      localStorage.setItem("addFavourite", JSON.stringify(jsonBody));
    } else {
      localStorage.setItem("addFavourite", JSON.stringify(jsonBody));
    }
  }

  removeOfflineFavouriteContacts(contacts: Contact[]) {
    let jsonBody = {
      id: contacts.map(c => c.id),
      tn: ContactConstants.FAVORITE_TAG,
      op: "!tag"
    };

    let body: any = localStorage.getItem("unFavourite");
    if (body && body !== undefined) {
      let body2 = JSON.parse(body);
      contacts.forEach(c => {
        body2.id.push(c.id);
      });
      localStorage.removeItem("unFavourite");
      jsonBody.id = body2.id;
      localStorage.setItem("unFavourite", JSON.stringify(jsonBody));
    } else {
      localStorage.setItem("unFavourite", JSON.stringify(jsonBody));
    }
  }

  syncupOnOnline(contacts: Contact[]) {
    let isUpdated: boolean = false;
    let jsonBody = localStorage.getItem("deleteItems");
    if (jsonBody && jsonBody !== undefined) {
      let body2 = JSON.parse(jsonBody);
      body2.id = body2.id.toString();
      this.contactService.contactAction(body2).subscribe(
        res => {
          localStorage.removeItem("deleteItems");
        });
    }
    contacts.forEach(con => {
      if (con.isOfflineCreate) {
        this.contactService.createContact(con.offlineRequest).subscribe(
          res => {
            let api_url = this.contactService.getApiUrl();
            let newContact = ContactUtils.getContact(res.cn, api_url);
            this.resetContactAfterOnline(newContact);
            this.store.dispatch(new UpdateContactSuccess({ id: con.id, changes: newContact }));
            isUpdated = true;
          });
      } else if (con.isOfflineModify) {
        this.contactService.modifyContact(con.offlineRequest).subscribe(
          res => {
            isUpdated = true;
            this.resetContactAfterOnline(con);
            this.store.dispatch(new UpdateContactSuccess({ id: con.id, changes: con }));
          });
      }
    });

    setTimeout(() => {
      jsonBody = localStorage.getItem("addFavourite");
      if (jsonBody && jsonBody !== undefined) {
        let body2 = JSON.parse(jsonBody);
        body2.id = body2.id.toString();
        this.contactService.contactAction(body2).subscribe(
          res => {
            localStorage.removeItem("addFavourite");
          });
      }
    }, 20000);

    setTimeout(() => {
      jsonBody = localStorage.getItem("unFavourite");
      if (jsonBody && jsonBody !== undefined) {
        let body2 = JSON.parse(jsonBody);
        body2.id = body2.id.toString();
        this.contactService.contactAction(body2).subscribe(
          res => {
            localStorage.removeItem("unFavourite");
          });
      }
    }, 5000);
  }

  resetContactAfterOnline(contact: Contact) {
    contact.offlineRequest = null;
    contact.isOfflineCreate = false;
    contact.isOfflineModify = false;
  }

  private upadteExstingContact(exstingContact: Contact, newContact: Contact) {
    if (newContact) {
      exstingContact.email = newContact.email;
      exstingContact.firstName = newContact.firstName;
      exstingContact.fullName = newContact.fullName;
      exstingContact.lastName = newContact.lastName;
      exstingContact.nickname = newContact.nickname;
      exstingContact.jobTitle = newContact.jobTitle;
      exstingContact.company = newContact.company;
      exstingContact.image = newContact.image;
      exstingContact.phone = newContact.phone;
      exstingContact.notes = newContact.notes;
      exstingContact.other = newContact.other;
      exstingContact.im = newContact.im;
      exstingContact.address = newContact.address;
      exstingContact.userURL = newContact.userURL;
      exstingContact.tags = newContact.tags;
      exstingContact.firstLastCharacters = newContact.firstLastCharacters;
      if (newContact.type && newContact.type === "group") {
        exstingContact.m = newContact.m;
      }
    }
  }

  removeContactFromFavorite(contacts: Contact[]): void {
    this.store.dispatch(new RemoveContact());
    this.store.dispatch(new RemoveMultipleContactSuccess(contacts.map(c => c.id)));
  }


  addContactFromFavorite(contact: Contact): void {
    this.store.dispatch(new CreateContact());
    this.store.dispatch(new CreateContactSuccess(contact));
  }

  deleteAllContactAvtar(selectedContact: Contact[]) {
    selectedContact.forEach(c => localStorage.removeItem(c.id + "_photo"));
  }

  copyContacts(selectedContacts: Contact[], sourceFolder, destinationFolder): void {
    let jsonBody = {
      id: selectedContacts.map(c => c.id).toString(),
      op: "copy",
      folderId: destinationFolder.id
    };
    this.contactService.contactAction(jsonBody).pipe(take(1)).subscribe(res => {
      const contactLength = selectedContacts.length;
      const parameter = contactLength > 1 ? "ITEMS_COPIED" : "ITEM_COPIED";
      this.translate.get(parameter).pipe(take(1)).subscribe((text: string) => {
        const msg = contactLength + " " + text + " " + CommonUtils.getShortNameForMobile(destinationFolder.name, 10);
        this.success.emit({ id: SuccessType.CopyContact, messages: msg });
      });
      this.contactsFolderRepository.mantainDestinationFolder(destinationFolder);
    }, err => {
      if (err === undefined) {
        this.errorService.emit({ id: ErrorType.Generic, messages: this.translatedMessages.NETWORK_CONNECTION_LOST });
        return;
      }
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
  }

  changeContactsList(selectedContacts: Contact[], destinationFolderId: string) {
    let jsonBody = {
      id: selectedContacts.map(c => c.id).toString(),
      op: "move",
      folderId: destinationFolderId
    };
    this.store.dispatch(new RemoveContact());
    this.contactService.contactAction(jsonBody).pipe(take(1)).subscribe(
      res => {
        this.store.dispatch(new RemoveMultipleContactSuccess(selectedContacts.map(c => c.id)));
        this.contactsFolderRepository.mantainSourceFolder();
        this.updateContactListById(destinationFolderId);
      },
      err => {
        if (err === undefined) {
          this.store.dispatch(new RemoveContactFail());
          this.errorService.emit({ id: ErrorType.Generic, messages: this.translatedMessages.NETWORK_CONNECTION_LOST });
          return;
        }
        this.store.dispatch(new RemoveContactFail());
        this.errorService.emit({ id: ErrorType.Generic, messages: err });
      }
    );
  }

  updateContactListById(contactlistId: string): void {
    this.store.select(getContactFolders).pipe(take(1)).subscribe(res => {
      const folder = res.filter(f => f.id === contactlistId)[0];
      if (!!folder && folder !== null) {
        console.log("[updateContactListById]: ", folder);
        this.contactsFolderRepository.mantainDestinationFolder(folder);
      }
    });
  }

  getParentFolder(targetFolder: ContactFolder, rootFolder: ContactFolder): ContactFolder {
    return this.getSelectedSubFolder(targetFolder.l, rootFolder);
  }

  getSelectedSubFolder(folderId: string, rootFolder: ContactFolder): ContactFolder {
    if (rootFolder === null || rootFolder === undefined) {
      return null;
    }
    if (rootFolder.id === folderId) {
      return rootFolder;
    }
    if (rootFolder.children) {
      for (let i = 0; i < rootFolder.children.length; i++) {
        if (folderId === rootFolder.children[i].id) {
          return rootFolder.children[i];
        } else if (rootFolder.children[i].children) {
          const f =  this.getSelectedSubFolder(folderId, rootFolder.children[i]);
          if (f) {
            return f;
          }
        }
      }
    } else {
      return null;
    }
  }

  deleteContactsParmanent(selectedContacts: Contact[], sourceFolder, destinationFolder) {
    {
      let jsonBody = {
        id: selectedContacts.map(c => c.id).toString(),
        op: "delete"
      };
      this.store.dispatch(new RemoveContact());
      if (!this.isOnline) {
        this.deleteOfflineContacts(selectedContacts, destinationFolder);
        return;
      }
      this.contactService.contactAction(jsonBody).subscribe(
        res => {
          let msg;
          msg = this.translatedMessages.CONTACT_PERMANENTLY_DELETE;
          this.store.dispatch(new RemoveMultipleContactSuccess(selectedContacts.map(c => c.id)));
          this.backupContacts = selectedContacts;
          this.deleteAllContactAvtar(selectedContacts);
          this.contactsFolderRepository.mantainSourceFolder();
          this.success.emit({ id: SuccessType.ContactDeleteParmanent, messages: msg });
        },
        err => {
          if (err === undefined) {
            this.store.dispatch(new RemoveContactFail());
            this.errorService.emit({ id: ErrorType.Generic, messages: this.translatedMessages.NETWORK_CONNECTION_LOST });
            return;
          }
          this.store.dispatch(new RemoveContactFail());
          this.errorService.emit({ id: ErrorType.Generic, messages: err });
        }
      );
    }
  }

  createContactsList(body) {
    return this.contactService.createContactsList(body);
  }

}
