
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

  <div #dialogContent class="advanced-search-body">
    <div class="section">
      <h4 class="section__header">{{'CONTACT_FILTER' | translate}}</h4>
      <div class="section__item">
        <vnc-input
          size="s"
          dropdownLabel="contains"
          leadingIconName="mdi-magnify"
          placeholder="Contains"
          [showLabel]="false"
          [showClear]="true"
          [type]="'dropdown-search'"
          [searchFilterControl]="searchFilterControl"
          [form]="searchText"
          [searchFilterOptions]="contactFolders"
          [required]="true">
        </vnc-input>
      </div>
    </div>

    <section class="section action-buttons">
      <div class="filter-actions">
        <vnc-button
          class="themed-control"
          shape="rectangle"
          [height]="'small'"
          [type]="'link'"
          label="{{ 'CLEAR' | translate }}"
          (click)="clear()">
        </vnc-button>

        <vnc-button
          class="themed-control"
          shape="rectangle"
          [height]="'small'"
          [type]="'primary'"
          [roundButtonType]="'lightgreen'"
          label="{{ 'APPLY_LBL' | translate }}"
          (click)="submit()">
        </vnc-button>
      </div>
    </section>
  </div>
