
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="confirm-header" fxLayout="row" fxLayoutAlign="start center">
        <span>{{ "CONFIRMATION" | translate }}</span>
</div>
<div class="content">
    {{ 'CALENDARS.CANCEL_INVITE_DIALOG_CONTENT' | translate }}
</div>
<div class="operation-button">
    <div>
        <button mat-button (click)="close('edit')">{{ 'CALENDARS.EDIT_MSG_LBL' | translate }}</button>
    </div>
    <div>
        <button mat-button (click)="close('send')">{{ 'CALENDARS.SEND_CANCELATION_LBL' | translate }}</button>
        <button mat-button (click)="close('cancel')">{{ 'CANCEL' | translate }}</button>
    </div>
</div>