
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import * as fromMail from "./mail.reducer";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { RootState, getMailTagState } from "../../../reducers";
import * as fromMailFolder from "./mail-folder.reducer";
import * as fromMailTag from "../../../reducers/mail-tag.reducer";
import * as fromConversation from "./conversation.reducer";
import * as fromMessage from "./message.reducer";

export interface MailRootState extends RootState {
  mail: State;
}

export interface State {
  conversation: fromConversation.ConversationState;
  mail: fromMail.MailState;
  mailFolder: fromMailFolder.MailFolderState;
  message: fromMessage.MailMessageState;
}

export const getMailRootState = createFeatureSelector<State>("mail");

export const mailRootReducer = {
  mailFolder: fromMailFolder.mailFolderReducer,
  mail: fromMail.mailReducer,
  conversation: fromConversation.conversationReducer,
  message: fromMessage.mailMessageReducer
};

export const getMailState = createSelector(
  getMailRootState,
  state => state?.mail
);

export const getMailFolderState = createSelector(
  getMailRootState,
  state => state.mailFolder
);

export const getConversationState = createSelector(
  getMailRootState,
  state => state?.conversation
);

export const getIsMailsLoading = createSelector(
    getMailState,
    fromMail._getIsMailsLoading
);

export const getIsMailsLoaded = createSelector(
    getMailState,
    fromMail._getIsMailsLoaded
);

export const getIsConversationLoading = createSelector(
  getConversationState,
  fromConversation._getIsConversationLoading
);

export const getIsConversationLoaded = createSelector(
  getConversationState,
  fromConversation._getIsConversationLoaded
);

export const getHasMoreConversations = createSelector(
  getConversationState,
  fromConversation._getHasMoreConversations
);

export const getSelectedConversationIds = createSelector(
  getConversationState,
  fromConversation._getSelectedConversationIds
);

export const getSelectedMessageIds = createSelector(
  getMailState,
  fromMail._getSelectedMessageIds
);

export const getCheckedConversationIds = createSelector(
  getConversationState,
  fromConversation._getCheckedConversationIds
);


export const getMailTabs = createSelector(
  getConversationState,
  fromConversation._getMailTabs
);

export const getSelectedTab = createSelector(
  getConversationState,
  fromConversation._getSelectedTab
);

export const getLastConversationData = createSelector(
  getConversationState,
  fromConversation._getLastConversationData
);

export const getLastConversationDataById = (state: MailRootState, target: string) => {
  return fromConversation._getLastConversationDataById(getConversationState(state), target);
};

export const getCheckedMessageIds = createSelector(
  getMailState,
  fromMail._getCheckedMessageIds
);

export const getSealedData = createSelector(
  getMailState,
  fromMail._getSealedData
);

export const getMailDetailId = createSelector(
  getConversationState,
  fromConversation._getMailDetailId
);

export const getMessageDetailId = createSelector(
  getMailState,
  fromMail._getMessageDetailId
);

export const getCurrentQuery = createSelector(
  getConversationState,
  fromConversation._getCurrentQuery
);

export const getSelectedMailFolder = createSelector(
  getMailFolderState,
  fromMailFolder._getSelectedMailFolder
);

export const getSelectedMailTag = createSelector(
  getMailTagState,
  fromMailTag._getSelectedMailTag
);

export const getMessageState = createSelector(
  getMailRootState,
  state => state.message
);
