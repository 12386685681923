
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, NgZone } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { BroadcastKeys } from "../../../../common/enums/broadcast.enum";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { MailBroadcaster } from "../../../../common/providers/mail-broadcaster.service";

@Component({
    selector: "vp-send-read-receipt-dialog",
    templateUrl: "./send-read-receipt-dialog.component.html"
})
export class SendReadReceiptDialogComponent implements OnInit, OnDestroy {

    private isAlive$ = new Subject<boolean>();

    constructor(
        private dialogRef: MatDialogRef<SendReadReceiptDialogComponent>,
        private mailBroadcaster: MailBroadcaster,
        private ngZone: NgZone
    ) {
        this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_READ_RECEIPT_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.dialogRef.close({ sendNotification: false });
            });
        });
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
    }

    close(sendNotification: boolean): void {
        this.dialogRef.close({ sendNotification: sendNotification });
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

}
