
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="shortcut_dialog">
  <div class="header">
    {{brandName}} {{ 'ZIMBRA_KEYBORAD_SHORT_CUTS' | translate}}
  </div>
  <div class="content">
    <div class="" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column">
      <!-- <div class="left_part" fxFlex="40" fxFlex.xs="100" fxFlex.sm="100" fxLayout="column" fxLayout.sm="column"
        fxLayout.xs="column">
        <div class="heading">
          {{ 'ALL_APPLICATION_LBL' | translate }}
        </div>
        <div fxLayout="row" class="add_space">
          <div fxFlex="70" class="right-align">
            <button mat-button>C</button>
          </div>
          <div fxFlex="30" class="description">
            <small>{{ 'COMPOSE_LABEL' | translate }}</small>
          </div>
        </div>
        <div fxLayout="row" class="add_space">
          <div fxFlex="70" class="right-align">
            <button mat-button>N</button>+
            <button mat-button>M</button>
          </div>
          <div fxFlex="30" class="description">
            <small>{{ 'COMPOSE_LABEL' | translate }}</small>
          </div>
        </div>
        <div fxLayout="row" class="add_space">
          <div fxFlex="70" class="right-align">
            <button mat-button>SHIFT</button>+
            <button mat-button>C</button>
          </div>
          <div fxFlex="30" class="description">
            <small>{{ 'COMPOSE_IN_NEW_WINDOW' | translate }}</small>
          </div>
        </div>
      </div> -->

      <div class="right_part" fxFlex="40" fxLayout="column" fxLayout.sm="column" fxLayout.xs="column">
        <div class="heading">
          {{ 'CONVERSATION_LIST_VIEW' | translate }}
        </div>
        <div fxLayout="row" class="add_space">
          <div fxFlex="70" class="right-align">
            <button mat-button>
              <mat-icon>arrow_forward</mat-icon>
            </button>
          </div>
          <div fxFlex="30" class="description">
            <small>{{ 'EXPAND_CONVERSATION' | translate }}</small>
          </div>
        </div>

        <div fxLayout="row" class="add_space">
          <div fxFlex="70" class="right-align">
            <button mat-button>
              <mat-icon>arrow_back</mat-icon>
            </button>
          </div>
          <div fxFlex="30" class="description">
            <small>{{ 'COLLAPSE_CONVERSATION' | translate }}</small>
          </div>
        </div>

        <div fxLayout="row" class="add_space">
          <div fxFlex="70" class="right-align">
            <button mat-button>
              O
            </button>
          </div>
          <div fxFlex="30" class="description">
            <small>{{ 'EXPAND_COLLAPSE_CONVERSATION' | translate }}</small>
          </div>
        </div>

        <!-- <div fxLayout="row" class="add_space">
          <div fxFlex="70" class="right-align">
            <button mat-button>
              SHIFT
            </button>+
            <button mat-button>
              O
            </button>
          </div>
          <div fxFlex="30" class="description">
            <small>{{ 'EXPAND_ALL_CONVERSATION' | translate }}</small>
          </div>
        </div> -->
        <!-- <div fxLayout="row" class="add_space">
          <div fxFlex="70" class="right-align">
            <button mat-button>
              CTRL
            </button>+
            <button mat-button>
              O
            </button>
          </div>
          <div fxFlex="30" class="description">
            <small>{{ 'COLLAPSE_ALL_CONVERSATION' | translate }}</small>
          </div>
        </div> -->

        <!-- <div fxLayout="row" class="add_space">
          <div fxFlex="70" class="right-align">
            <button mat-button>
              CTRL
            </button>+
            <button mat-button>
              [
            </button>
          </div>
          <div fxFlex="30" class="description">
            <small>{{ 'SELECT_PREVIOUS_UNRED_MSG' | translate }}</small>
          </div>
        </div> -->

        <!-- <div fxLayout="row" class="add_space">
          <div fxFlex="70" class="right-align">
            <button mat-button>
              CTRL
            </button>+
            <button mat-button>
              ]
            </button>
          </div>
          <div fxFlex="30" class="description">
            <small>{{ 'SELECT_NEXT_UNREAD_MSG' | translate }}</small>
          </div>
        </div> -->

        <!-- <div fxLayout="row" class="add_space">
          <div fxFlex="70" class="right-align">
            <button mat-button>
              SHIFT
            </button>+
            <button mat-button>
              CTRL
            </button>+
            <button mat-button>
              [
            </button>
          </div>
          <div fxFlex="30" class="description">
            <small>{{ 'SELECT_FIRST_UNREAD_MSG' | translate }}</small>
          </div>
        </div> -->

        <!-- <div fxLayout="row" class="add_space">
          <div fxFlex="70" class="right-align">
            <button mat-button>
              SHIFT
            </button>+
            <button mat-button>
              CTRL
            </button>+
            <button mat-button>
              ]
            </button>
          </div>
          <div fxFlex="30" class="description">
            <small>{{ 'SELECT_LAST_UNREAD_MSG' | translate }}</small>
          </div>
        </div> -->

        <div class="heading">
          {{ 'COMPOSE_LABEL' | translate }}
        </div>

        <div fxLayout="row" class="add_space">
          <div fxFlex="70" class="right-align">
            <button mat-button>
              CTRL
            </button>+
            <button mat-button>
              ENTER
            </button>
          </div>
          <div fxFlex="30" class="description">
            <small>{{ 'SEND_MAIL_LABEL' | translate }}</small>
          </div>
        </div>

        <div fxLayout="row" class="add_space">
          <div fxFlex="70" class="right-align">
            <button mat-button>
              ESC
            </button>
          </div>
          <div fxFlex="30" class="description">
            <small>{{ 'CANCEL_MAIL_LABEL' | translate }}</small>
          </div>
        </div>

        <div fxLayout="row" class="add_space">
          <div fxFlex="70" class="right-align">
            <button mat-button>
              CTRL
            </button>+
            <button mat-button>
              M
            </button>
          </div>
          <div fxFlex="30" class="description">
            <small>{{ 'ADD_ATTACHMENT' | translate }}</small>
          </div>
        </div>

        <div fxLayout="row" class="add_space">
          <div fxFlex="70" class="right-align">
            <button mat-button>
              CTRL
            </button>+
            <button mat-button>
              Q
            </button>
          </div>
          <div fxFlex="30" class="description">
            <small>{{ 'SHOW_SHORTCUTS' | translate }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <button mat-button (click)="close()">
      {{ 'CLOSE' | translate }}
    </button>
  </div>
</div>