import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ScheduleAssistantComponent } from "./schedule-assistant.component";
import { SharedModule } from "src/app/shared/shared.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CalendarMiniModule } from "../mini-date-picker/calendar-mini-view.module";



@NgModule({
  declarations: [ScheduleAssistantComponent],
  exports: [ScheduleAssistantComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatTooltipModule,
    CalendarMiniModule
  ]
})
export class ScheduleAssistantModule { }
