
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from ".";
import { MailTag } from "../mail/models/mail-tag.model";
import { Update } from "@ngrx/entity/src/models";
import { AppActionTypes } from "./app";


export class MailTagActionTypes extends AppActionTypes {
  static SELECT_MAIL_TAG = "[TAGS] Select Mail Tag";
  static LOAD_TAGS = "[TAGS] Load Tags";
  static LOAD_TAGS_SUCCESS = "[TAGS] Load Tags Success";
  static LOAD_TAGS_FAIL = "[TAGS] Load Tags Fail";

  static CREATE_MAIL_TAG = "[TAGS] Create Mail Tag";
  static CREATE_MAIL_TAG_FAIL = "[TAGS] Create Mail Tag Fail";
  static CREATE_MAIL_TAG_SUCCESS = "[TAGS] Create Mail Tag Success";

  static DELETE_MAIL_TAG = "[TAGS] Delete Mail Tag";
  static DELETE_MAIL_TAG_FAIL = "[TAGS] Delete Mail Tag Fail";
  static DELETE_MAIL_TAG_SUCCESS = "[TAGS] Delete Mail Tag Success";

  static UPDATE_MAIL_TAG = "[TAGS] Update Mail Tag";
  static UPDATE_MAIL_TAG_FAIL = "[TAGS] Update Mail Tag Fail";
  static UPDATE_MAIL_TAG_SUCCESS = "[TAGS] Update Mail Tag Success";
  static RESET_MAIL_TAG_STATE = "[TAGS] Reset State";
  static DELETE_TAGS = "[TAGS] Delete Tags";

}

export class SetSelectedMailTag implements Action {
  readonly type = MailTagActionTypes.SELECT_MAIL_TAG;
  constructor(public payload: MailTag) {
  }
}

export class LoadMailTagsSuccess implements Action {
  readonly type = MailTagActionTypes.LOAD_TAGS_SUCCESS;
  constructor(public payload: { tags: MailTag[] }) { }
}

export class LoadMailTags implements Action {
  readonly type = MailTagActionTypes.LOAD_TAGS;
}

export class LoadMailTagsFail implements Action {
  readonly type = MailTagActionTypes.LOAD_TAGS_FAIL;
}

export class CreateMailTag implements Action {
  readonly type = MailTagActionTypes.CREATE_MAIL_TAG;
}

export class CreateMailTagFail implements Action {
  readonly type = MailTagActionTypes.CREATE_MAIL_TAG_FAIL;
}

export class CreateMailTagSuccess implements Action {
  readonly type = MailTagActionTypes.CREATE_MAIL_TAG_SUCCESS;
  constructor(public payload: MailTag) { }
}

export class DeleteMailTag implements Action {
  readonly type = MailTagActionTypes.DELETE_MAIL_TAG;
}

export class DeleteMailTagFail implements Action {
  readonly type = MailTagActionTypes.DELETE_MAIL_TAG_FAIL;
}

export class DeleteMailTagSuccess implements Action {
  readonly type = MailTagActionTypes.DELETE_MAIL_TAG_SUCCESS;
  constructor(public payload: string) { }
}

export class UpdateMailTag implements Action {
  readonly type = MailTagActionTypes.UPDATE_MAIL_TAG;
}

export class UpdateMailTagFail implements Action {
  readonly type = MailTagActionTypes.UPDATE_MAIL_TAG_FAIL;
}

export class UpdateMailTagSuccess implements Action {
  readonly type = MailTagActionTypes.UPDATE_MAIL_TAG_SUCCESS;
  constructor(public payload: Update<MailTag>) { }
}

export class DeleteMultipleMailTag implements Action {
  readonly type = MailTagActionTypes.DELETE_TAGS;
  constructor(public payload: string[]) { }
}

export class ResetMailTagState implements Action {
  readonly type = MailTagActionTypes.RESET_MAIL_TAG_STATE;
  constructor() { }
}
