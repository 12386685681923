<div class="spinner-seal-send-mail" *ngIf="isSealMessage">
        <div class="lbl">{{ 'SEALING_SECURE_MAIL' | translate }}</div>
        <div>
            <div class="basic-container">
                <mat-spinner [diameter]="40"></mat-spinner>
                <img [src]="vereignMobileIcon">
            </div>
        </div>
</div>
<div class="spinner-seal-send-mail" *ngIf="!isSealMessage">
    <div class="lbl">{{ 'EMAIL_SEALED_AND_SECURE' | translate }}</div>
    <div class="ok-lbl">
        {{ 'OK' | translate }}
    </div>
</div>