
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component, Input, Output, EventEmitter, TemplateRef, HostBinding,
  OnInit, OnDestroy, ChangeDetectorRef
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { MonthViewDay, CalendarEvent } from "calendar-utils";
import { DateAdapter } from "../date-adapters/date-adapter";
import { trackByEventId } from "../common/util";
import { PlacementArray } from "positioning";
import { CalenderUtils } from "../../utils/calender-utils";
import { CalendarRepository } from "../../repositories/calendar.repository";

@Component({
  selector: "vp-calendar-month-cell",
  template: `
    <ng-template
      #defaultTemplate
      let-day="day"
      let-openDay="openDay"
      let-locale="locale"
      let-tooltipPlacement="tooltipPlacement"
      let-highlightDay="highlightDay"
      let-unhighlightDay="unhighlightDay"
      let-eventClicked="eventClicked"
      let-dayHeaderClicked="dayHeaderClicked"
      let-tooltipTemplate="tooltipTemplate"
      let-tooltipAppendToBody="tooltipAppendToBody"
      let-tooltipDelay="tooltipDelay"
      let-onDoubleClicked="onDoubleClicked"
      let-trackByEventId="trackByEventId",
      let-colWidth="colWidth",
      let-onContextMenuClicked="onContextMenuClicked",
    >
      <div class="cal-cell-top" (vpCalClick)="dayHeaderClicked.emit(day.date)" >
        <span class="cal-day-number-bg"></span>
        <span class="cal-day-number">{{
          day.date | vpCalendarDate: 'monthViewDayNumber':locale
        }}</span>
      </div>
      <div *ngIf="day.events.length > 0" class="cal-events" >
      <!--[ngStyle]="{ width: colWidth + 'px' }"-->
        <div
          class="cal-event"
          [class.cal-event-selecting]="event['isSelecting']"
          *ngFor="let event of day.events; trackBy: trackByEventId"
          [ngStyle]="{ background: getBackgroundColor(event.bgcolor), borderColor: event.color?.primary ,
            display: isCalendarCheck(event.folderId)}"
          [ngClass]="event?.cssClass"
          (mouseenter)="highlightDay.emit({ event: event })"
          (mouseleave)="unhighlightDay.emit({ event: event })"
          [vpCalendarTooltip]="event.title | vpCalendarAppointmentTitle: 'monthTooltip':event"
          [tooltipPlacement]="tooltipPlacement"
          [tooltipEvent]="event"
          [tooltipTemplate]="tooltipTemplate"
          [tooltipAppendToBody]="tooltipAppendToBody"
          [tooltipDelay]="tooltipDelay"
          mwlDraggable
          [class.cal-draggable]="event.draggable"
          dragActiveClass="cal-drag-active"
          [dropData]="{ event: event, draggedFrom: day }"
          [dragAxis]="{ x: event.draggable, y: event.draggable }"
          (vpCalClick)="handleAppointmentClicked(event)"
          (dblclick)="handleDoubleClicked(event)"
          (contextmenu)="onContextMenuClicked.emit({ $event: $event, calendarEvent: { event: event }})"
          vpLongPress [timeout]="700" (onLongPress)="longPressEvent(event)"
          >
          <span class="cal-visibility"
            [class.cal-visibility-F]="event['fba'] === 'F'"
            [class.cal-visibility-T]="event['fba'] === 'T'"
            [class.cal-visibility-B]="event['fba'] === 'B'"
            [class.cal-visibility-O]="event['fba'] === 'O'"
          ></span>
          <vp-calendar-appointment-title
            [event]="event"
            [customTemplate]="eventTitleTemplate"
            view="month">
          </vp-calendar-appointment-title>
        </div>
      </div>
    </ng-template>
    <ng-template
      [ngTemplateOutlet]="customTemplate || defaultTemplate"
      [ngTemplateOutletContext]="{
        day: day,
        openDay: openDay,
        locale: locale,
        tooltipPlacement: tooltipPlacement,
        highlightDay: highlightDay,
        unhighlightDay: unhighlightDay,
        eventClicked: eventClicked,
        dayHeaderClicked: dayHeaderClicked,
        tooltipTemplate: tooltipTemplate,
        tooltipAppendToBody: tooltipAppendToBody,
        tooltipDelay: tooltipDelay,
        trackByEventId: trackByEventId,
        colWidth: colWidth,
        onContextMenuClicked: onContextMenuClicked
      }"
    >
    </ng-template>
  `
})
export class CalendarMonthCellComponent implements OnInit, OnDestroy {
  @HostBinding("class.cal-cell") defaultCallCellClass: boolean = true;
  @HostBinding("class.cal-day-cell") defaultCallDayCellClass: boolean = true;
  @HostBinding("class.cal-past") defaultCallPastClass: boolean = false;
  @HostBinding("class.cal-today") defaultCallTodayClass: boolean = false;
  @HostBinding("class.cal-future") defaultCallFutureClass: boolean = false;
  @HostBinding("class.cal-weekend") defaultCallWeekendClass: boolean = false;
  @HostBinding("class.cal-in-month") defaultCallInMonthClass: boolean = false;
  @HostBinding("class.cal-out-month") defaultCallOutMonthClass: boolean = false;
  @HostBinding("class.cal-has-events") defaultCallHasEventsClass: boolean = false;
  @HostBinding("class.cal-open") defaultCallOpenClass: boolean = false;
  @HostBinding("class.cal-event-highlight") defaultCallEventHighlightClass: boolean = false;
  @Input() day: MonthViewDay;
  @Input() openDay: MonthViewDay;
  @Input() locale: string;
  @Input() tooltipPlacement: PlacementArray;
  @Input() tooltipAppendToBody: boolean;
  @Input() customTemplate: TemplateRef<any>;
  @Input() tooltipTemplate: TemplateRef<any>;
  @Input() eventTitleTemplate: TemplateRef<any>;
  @Input() tooltipDelay: number | null;
  @Input() refresh: Subject<any>;
  @Input() colWidth: number;
  @Output() highlightDay: EventEmitter<any> = new EventEmitter();
  @Output() unhighlightDay: EventEmitter<any> = new EventEmitter();
  @Output() eventClicked: EventEmitter<{ event: CalendarEvent, isSelecting: boolean }> =
    new EventEmitter<{ event: CalendarEvent; isSelecting: boolean }>();
  @Output() dayHeaderClicked: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() onContextMenuClicked: EventEmitter<any> = new EventEmitter();
  @Output() onDoubleClicked: EventEmitter<any> = new EventEmitter();
  @Output() longPressClick: EventEmitter<any> = new EventEmitter();

  refreshSubscription: Subscription;
  trackByEventId = trackByEventId;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected dateAdapter: DateAdapter,
    private calendarRepository: CalendarRepository
  ) {
  }

  ngOnInit(): void {
    if (this.refresh) {
      this.refreshSubscription = this.refresh.subscribe((selectedDate) => {
        this.day.events.forEach(event => {
          if (event["eventId"] !== selectedDate.event["eventId"]) {
            event["isSelecting"] = false;
          }
        });
      });
    }

    this.refreshAll();
  }

  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  refreshAll() {
    this.defaultCallPastClass = this.day.isPast;
    this.defaultCallTodayClass = this.day.isToday;
    this.defaultCallFutureClass = this.day.isFuture;
    this.defaultCallWeekendClass = this.day.isWeekend;
    this.defaultCallInMonthClass = this.day.inMonth;
    this.defaultCallOutMonthClass = !this.day.inMonth;
    this.defaultCallHasEventsClass = this.day.events.length > 0;
    this.defaultCallOpenClass = this.day === this.openDay;
    this.defaultCallEventHighlightClass = !!this.day.backgroundColor;
  }

  private handleAppointmentClicked(event: any) {
    event.isSelecting = true;
    this.eventClicked.emit({ event: event, isSelecting: event.isSelecting });
  }

  private handleDoubleClicked(event: any) {
    this.onDoubleClicked.emit({event: event});
  }

  getBackgroundColor(color: string): string {
    const grandient = CalenderUtils.getBackgroundGrandientColor(color);
    if (!!grandient) {
      return grandient;
    }
    return color;
  }

  isCalendarCheck(folderId: string): string {
    const checkUncheck = this.calendarRepository.isCalendarCheck(folderId);
    return checkUncheck ? "block" : "none";
  }

  longPressEvent(ev: any): void {
    this.longPressClick.emit(ev);
  }

}
