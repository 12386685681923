
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { NativeDateAdapter } from "@angular/material/core";
import { Injectable } from "@angular/core";
import { ConfigService } from "src/app/config.service";
import { Platform } from "@angular/cdk/platform";

@Injectable()
export class DatePickAdapterService extends NativeDateAdapter {

    workWeekDays: number[] = [0, 0, 1, 2, 3, 4, 5, 6];
    firstDayOfWeek: number = 1;
    weekStartsOn = 1;
    currentLocale: string = "en";

    constructor(private config: ConfigService) {
        super(
            !!localStorage.getItem("mailLanguage") && localStorage.getItem("mailLanguage") !== null ? localStorage.getItem("mailLanguage")
            : "en", new Platform(null)
        );
        this.setStartWorkWeekDay();
    }
    getFirstDayOfWeek(): number {
        return this.weekStartsOn;
    }
    setStartWorkWeekDay(): void {
        const workWeekDays: number[] = [7, 1, 2, 3, 4, 5, 6];
        const weekDay = this.config.prefs.zimbraPrefCalendarFirstDayOfWeek;
        if (!!weekDay && weekDay !== null) {
            this.firstDayOfWeek = workWeekDays[weekDay];
            this.weekStartsOn = this.firstDayOfWeek;
        }
    }
}
