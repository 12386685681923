<ng-template #defaultTemplate let-event="event" let-view="view" let-locale="locale" >
    <ng-container *ngIf="calendarRepository.daysInWeek !== 1">
        <span style="display: flex;">
            <mat-icon *ngIf="event.tn && event.tn !== '' && event.tn.split(',').length === 1" [style.color]="getTagColorByNames(event?.tn)"
              style="vertical-align: middle; font-size: 14px; height: 16px; width: 16px;"
              class="event-tag-item">local_offer</mat-icon>
            <img *ngIf="event.tn && event.tn !== '' && event.tn.split(',').length > 1" [src]="rainbowTagIcon" class="rainbow-colored-tag tag-sm">
            <mat-icon *ngIf="event.class && event.class==='PRI'" class="private-event-icon">lock</mat-icon>
            <div *ngIf="event.title !== ''"
              class="cal-event-title" [class.display-inline]="checkTimeSpanUnderAnHour()" [class.titleshow]="event.tn && event.tn !== '' && event.tn.split(',').length > 1"
              [innerHTML]="event.title | vpCalendarAppointmentTitle: view:event" [class.event-font-color]="isLightColor(event.bgcolor)">
            </div>
            <div *ngIf="event.title === ''"
              class="cal-event-title" [class.display-inline]="checkTimeSpanUnderAnHour()">
              {{ noTitle }}
            </div>
          </span>
          <span *ngIf="view !== 'month' && event.location !== ''"
            class="cal-event-location" [class.display-inline]="checkTimeSpanUnderAnHour()"
            [class.event-font-color]="isLightColor(event.bgcolor)">
            <a
            *ngIf="!!event && !!event.location && event?.location?.indexOf('/assets/meeting') !== -1 && !pastCheck()"
            [href]="event.location"
            target="_blank"
            class="join-meeting open-new-window">{{ 'JOIN' | translate }}</a>
            <ng-container *ngIf="event?.location?.indexOf('/assets/meeting') === -1">{{ event.location }}</ng-container>
          </span>
          <span *ngIf="sampleAppointment && view !== 'month' && !event.allDay" class="cal-event-title-time resizing-time" [class.display-inline]="checkTimeSpanUnderAnHour()"
          [class.event-font-color]="isLightColor(sampleAppointment.bgcolor)">
            <span *ngIf="locale === 'en'">
              <span class="cal-event-title-start-time" [innerHTML]="sampleAppointment.start | date:'hh:mm aaa': '': locale"></span> -
              <span class="cal-event-title-end-time" [innerHTML]="sampleAppointment.end | date:'hh:mm aaa': '': locale"></span>
            </span>
            <span *ngIf="locale === 'de'">
              <span class="cal-event-title-start-time" [innerHTML]="sampleAppointment.start | date:'HH:mm': '': locale"></span> -
              <span class="cal-event-title-end-time" [innerHTML]="sampleAppointment.end | date:'HH:mm': '': locale"></span>
            </span>
          </span>
          <span *ngIf="view !== 'month' && !event.allDay" class="cal-event-title-time" [class.display-inline]="checkTimeSpanUnderAnHour()"
          [class.event-font-color]="isLightColor(event.bgcolor)">
            <span *ngIf="locale === 'en'">
              <span class="cal-event-title-start-time" [innerHTML]="event.start | date:'hh:mm aaa': '': locale"></span> -
              <span class="cal-event-title-end-time" [innerHTML]="event.end | date:'hh:mm aaa': '': locale"></span>
            </span>
            <span *ngIf="locale === 'de'">
              <span class="cal-event-title-start-time" [innerHTML]="event.start | date:'HH:mm': '': locale"></span> -
              <span class="cal-event-title-end-time" [innerHTML]="event.end | date:'HH:mm': '': locale"></span>
            </span>
          </span>
    </ng-container>
    <ng-container *ngIf="calendarRepository.daysInWeek === 1">
        <span style="display: flex;gap: 6px;" class="new-event-title">
          <span style="display: flex;">
            <mat-icon *ngIf="event.tn && event.tn !== '' && event.tn.split(',').length === 1" [style.color]="getTagColorByNames(event?.tn)"
            style="vertical-align: middle; font-size: 14px; height: 16px; width: 16px;"
            class="event-tag-item">local_offer</mat-icon>

            <img *ngIf="event.tn && event.tn !== '' && event.tn.split(',').length > 1" [src]="rainbowTagIcon" class="rainbow-colored-tag tag-sm">

            <ng-container [ngTemplateOutlet]="recursiveIcon" *ngIf="event.recur"> </ng-container>

              <mat-icon *ngIf="event.class && event.class==='PRI'" class="private-event-icon">lock</mat-icon>
          </span>

            <div *ngIf="event.title !== ''"
              class="cal-event-title" [class.display-inline]="checkTimeSpanUnderAnHour()" [class.titleshow]="event.tn && event.tn !== '' && event.tn.split(',').length > 1"
              [innerHTML]="event.title | vpCalendarAppointmentTitle: view:event" [class.event-font-color]="isLightColor(event.bgcolor)">
            </div>
            <div *ngIf="event.title === ''"
              class="cal-event-title" [class.display-inline]="checkTimeSpanUnderAnHour()">
              {{ noTitle }}
            </div>
          </span>

          <span *ngIf="event.allDay" class="cal-event-title-time event-font-color">
            <span >
              {{'CALENDARS.ALL_DAY_TITLE' | translate}}
            </span>
          </span>

          <span *ngIf="sampleAppointment && view !== 'month' && !event.allDay" class="cal-event-title-time resizing-time" [class.display-inline]="checkTimeSpanUnderAnHour()"
          [class.event-font-color]="isLightColor(sampleAppointment.bgcolor)">
            <span *ngIf="locale === 'en'">
              <span class="cal-event-title-start-time" [innerHTML]="sampleAppointment.start | date:'hh:mm aaa': '': locale"></span> -
              <span class="cal-event-title-end-time" [innerHTML]="sampleAppointment.end | date:'hh:mm aaa': '': locale"></span>
            </span>
            <span *ngIf="locale === 'de'">
              <span class="cal-event-title-start-time" [innerHTML]="sampleAppointment.start | date:'HH:mm': '': locale"></span> -
              <span class="cal-event-title-end-time" [innerHTML]="sampleAppointment.end | date:'HH:mm': '': locale"></span>
            </span>
          </span>

          <span *ngIf="view !== 'month' && !event.allDay" class="cal-event-title-time new-event-time" [class.display-inline]="checkTimeSpanUnderAnHour()"
          [class.event-font-color]="isLightColor(event.bgcolor)">
            <span *ngIf="locale === 'en'">
              <span class="cal-event-title-start-time" [innerHTML]="event.start | date:'hh:mm aaa': '': locale"></span> -
              <span class="cal-event-title-end-time" [innerHTML]="event.end | date:'hh:mm aaa': '': locale"></span>
            </span>
            <span *ngIf="locale === 'de'">
              <span class="cal-event-title-start-time" [innerHTML]="event.start | date:'HH:mm': '': locale"></span> -
              <span class="cal-event-title-end-time" [innerHTML]="event.end | date:'HH:mm': '': locale"></span>
            </span>
          </span>

        <div *ngIf="view !== 'month' && event.location !== ''" class="cal-event-location new-event-location"
            [class.display-inline]="checkTimeSpanUnderAnHour()" [class.event-font-color]="isLightColor(event.bgcolor)">
            <svg width="16px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Icon/product/location-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Icon-24px" transform="translate(4.000000, 1.000000)" fill="#000000">
                      <path d="M8,0 C12.4228571,0 16,3.443 16,7.7 C16,9.65461538 15.0835503,11.9242604 13.8710168,14.0717708 L13.5334511,14.6540989 C13.476122,14.7505394 13.4182977,14.8466454 13.3600364,14.942376 L13.0056914,15.5119246 C12.9459153,15.6059889 12.8858188,15.6995955 12.8254602,15.7927035 L12.4606281,16.3450387 L12.0923004,16.8834349 L11.7232736,17.4059213 L11.3563441,17.9105274 L10.9943086,18.3952825 L10.6399636,18.8582157 L10.2961056,19.2973566 L8.76209811,21.1029683 C8.40451496,21.5238635 7.7734336,21.575188 7.35253844,21.2176049 C7.31128664,21.1825583 7.2729485,21.1442201 7.23790189,21.1029683 L5.7038944,19.2973566 L5.184,18.6296 L4.82546017,18.1555095 L4.46062813,17.6605826 L4.09230041,17.14679 C4.03077651,17.0596952 3.96922349,16.9718965 3.90769959,16.8834349 L3.53937187,16.3450387 L3.17453983,15.7927035 L2.816,15.2284 C2.69784615,15.0385231 2.5812071,14.8469799 2.46654893,14.6540989 L2.12898316,14.0717708 C0.916449704,11.9242604 0,9.65461538 0,7.7 C0,3.443 3.57714286,0 8,0 Z M8,2 C4.66497074,2 2,4.56503434 2,7.7 C2,8.69322797 2.3458444,9.96053154 3.01409327,11.4203421 C3.64939915,12.8081876 4.54667863,14.3111997 5.63770505,15.8676171 C6.30819597,16.8241143 7.01937804,17.7553435 7.73858591,18.6369503 L8,18.952 L8.26141409,18.6369503 C8.80081999,17.9757452 9.33571138,17.2866275 9.85230757,16.5798717 L10.362295,15.8676171 C11.4533214,14.3111997 12.3506009,12.8081876 12.9859067,11.4203421 C13.6541556,9.96053154 14,8.69322797 14,7.7 C14,4.56503434 11.3350293,2 8,2 Z M8,5.5 C9.38,5.5 10.5,6.62 10.5,8 C10.5,9.38 9.38,10.5 8,10.5 C6.62,10.5 5.5,9.38 5.5,8 C5.5,6.62 6.62,5.5 8,5.5 Z" id="Combined-Shape"></path>
                  </g>
              </g>
            </svg>
            <a *ngIf="!!event && !!event.location && event?.location?.indexOf('/assets/meeting') !== -1 && !pastCheck()"
                [href]="event.location" target="_blank" class="join-meeting day-view-join open-new-window">{{ 'JOIN' | translate }}</a>
            <span><ng-container *ngIf="event?.location?.indexOf('/assets/meeting') === -1">{{ event.location }}</ng-container></span>
        </div>

        <div class="appointment-participant-sec">
            <svg width="16px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Icon/product/account-circle-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Icon-24px" transform="translate(2.000000, 2.000000)" fill="#000000">
                <path
                  d="M10,13.012 L10.3700198,13.0237689 C12.1606366,13.1044387 13.6501533,13.5145491 14.852186,14.244448 L15.1053691,14.4057461 L15.3183123,14.5538382 L15.5180441,14.7048128 C16.416552,15.4139042 16.8897167,16.2189581 16.9856386,17.1535465 C15.1825612,18.9143965 12.7172881,20 10,20 C7.28169492,20 4.8155936,18.9135837 3.01250114,17.1515563 L3.01416063,17.1279972 C3.12061798,16.0901116 3.72351453,15.1880152 4.89463085,14.4057461 C6.05566386,13.6302124 7.50320835,13.1737778 9.25088033,13.0460942 L9.62998021,13.0237689 L10,13.012 Z M10,0 C15.52,0 20,4.48 20,10 C20,11.9310307 19.4517505,13.7347892 18.5025197,15.2640074 C18.0447371,14.2920855 17.2759581,13.4504854 16.2162683,12.7426457 C14.6470851,11.6944803 12.7186975,11.1260277 10.4480505,11.0252763 L10.0182974,11.0118633 L9.55194952,11.0252763 C7.28130251,11.1260277 5.35291495,11.6944803 3.78373172,12.7426457 C2.72224462,13.4516859 1.95265609,14.2949406 1.49437841,15.2588608 C0.547292653,13.7316398 0,11.9293449 0,10 C0,4.48 4.48,0 10,0 Z M10,2 C7.78771525,2 6,3.78771525 6,6 C6,8.21228475 7.78771525,10 10,10 C12.2122847,10 14,8.21228475 14,6 C14,3.78771525 12.2122847,2 10,2 Z M10,4 C11.1077153,4 12,4.89228475 12,6 C12,7.10771525 11.1077153,8 10,8 C8.89228475,8 8,7.10771525 8,6 C8,4.89228475 8.89228475,4 10,4 Z"
                  id="Shape"></path>
              </g>
            </g>
            </svg>
            <div class="new-event-description" style="display: flex; align-items: center; padding: 0;position: absolute;
              left: 28px; width: calc(100% - 39px);overflow: hidden; ">
              <span *ngIf="event?.participants">{{ event.participants.length }} {{ 'CALENDARS.PARTICIPANTS' | translate }}: </span>
              <ng-container *ngFor="let item of event.participants; last as last" style="margin-right: 4px;">{{ item.d }} {{last?"":","}}</ng-container>
            </div>
        </div>

        <span *ngIf="event.description" class="new-event-description">
          {{eventDescriptionText}}
        </span>
    </ng-container>

  </ng-template>

  <ng-template
    [ngTemplateOutlet]="customTemplate || defaultTemplate"
    [ngTemplateOutletContext]="{
      event: event,
      view: view,
      locale:locale
    }"
  >
  </ng-template>

  <ng-template #tagIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/tag-filled-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M11,2 C11.5,2 11.9259259,2.14814815 12.2777778,2.44444444 L12.40625,2.5625 L21.40625,11.5625 C21.78125,11.9375 21.96875,12.40625 21.96875,12.96875 C21.96875,13.46875 21.8206019,13.8946759 21.5243056,14.2465278 L21.40625,14.375 L14.375,21.40625 C14,21.78125 13.53125,21.96875 12.96875,21.96875 C12.46875,21.96875 12.0428241,21.8206019 11.6909722,21.5243056 L11.5625,21.40625 L2.5625,12.40625 C2.22916667,12.0729167 2.04398148,11.6655093 2.00694444,11.1840278 L2,11 L2,3.96875 C2,3.40625 2.1875,2.9375 2.5625,2.5625 C2.89583333,2.22916667 3.30324074,2.04398148 3.78472222,2.00694444 L3.96875,2 L11,2 Z M6.4921875,5.015625 C6.0703125,5.015625 5.71875,5.15625 5.4375,5.4375 C5.15625,5.71875 5.015625,6.0703125 5.015625,6.4921875 C5.015625,6.9140625 5.15625,7.265625 5.4375,7.546875 C5.71875,7.828125 6.0625,7.96875 6.46875,7.96875 C6.90625,7.96875 7.265625,7.828125 7.546875,7.546875 C7.828125,7.265625 7.96875,6.9140625 7.96875,6.4921875 C7.96875,6.0703125 7.828125,5.71875 7.546875,5.4375 C7.265625,5.15625 6.9140625,5.015625 6.4921875,5.015625 Z" id="Shape" [fill]="tagColor"></path>
      </g>
    </svg>
  </ng-template>


  <ng-template #recursiveIcon>
    <svg width="16px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/autorenew" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Icon-24px" transform="translate(4.000000, 1.480291)" fill="#000000">
              <path d="M8,6.52075285 C8,7.07246132 8.31387329,7.20583612 8.70591205,6.81379735 L12,3.51970941 L8.70591205,0.22562146 C8.31604759,-0.164243002 8,-0.0376302616 8,0.518665968 L8,2.51970941 C3.58,2.51970941 0,6.09970941 0,10.5197094 C0,12.0897094 0.46,13.5497094 1.24,14.7797094 L2.7,13.3197094 C2.25,12.4897094 2,11.5297094 2,10.5197094 C2,7.20970941 4.69,4.51970941 8,4.51970941 L8,6.52075285 Z M13.3,7.71970941 C13.74,8.55970941 14,9.50970941 14,10.5197094 C14,13.8297094 11.31,16.5197094 8,16.5197094 L8,14.518666 C8,13.9669575 7.68612671,13.8335827 7.29408795,14.2256215 L4,17.5197094 L7.29408795,20.8137974 C7.68395241,21.2036618 8,21.0770491 8,20.5207528 L8,18.5197094 C12.42,18.5197094 16,14.9397094 16,10.5197094 C16,8.94970941 15.54,7.48970941 14.76,6.25970941 L13.3,7.71970941 Z" id="Shape"></path>
          </g>
      </g>
    </svg>
  </ng-template>

  <ng-template #locationIcon>
    <svg width="16px" height="16px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/location-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icon-24px" transform="translate(4.000000, 1.000000)" fill="#0000">
          <path
            d="M8,0 C12.4228571,0 16,3.443 16,7.7 C16,9.65461538 15.0835503,11.9242604 13.8710168,14.0717708 L13.5334511,14.6540989 C13.476122,14.7505394 13.4182977,14.8466454 13.3600364,14.942376 L13.0056914,15.5119246 C12.9459153,15.6059889 12.8858188,15.6995955 12.8254602,15.7927035 L12.4606281,16.3450387 L12.0923004,16.8834349 L11.7232736,17.4059213 L11.3563441,17.9105274 L10.9943086,18.3952825 L10.6399636,18.8582157 L10.2961056,19.2973566 L8.76209811,21.1029683 C8.40451496,21.5238635 7.7734336,21.575188 7.35253844,21.2176049 C7.31128664,21.1825583 7.2729485,21.1442201 7.23790189,21.1029683 L5.7038944,19.2973566 L5.184,18.6296 L4.82546017,18.1555095 L4.46062813,17.6605826 L4.09230041,17.14679 C4.03077651,17.0596952 3.96922349,16.9718965 3.90769959,16.8834349 L3.53937187,16.3450387 L3.17453983,15.7927035 L2.816,15.2284 C2.69784615,15.0385231 2.5812071,14.8469799 2.46654893,14.6540989 L2.12898316,14.0717708 C0.916449704,11.9242604 0,9.65461538 0,7.7 C0,3.443 3.57714286,0 8,0 Z M8,2 C4.66497074,2 2,4.56503434 2,7.7 C2,8.69322797 2.3458444,9.96053154 3.01409327,11.4203421 C3.64939915,12.8081876 4.54667863,14.3111997 5.63770505,15.8676171 C6.30819597,16.8241143 7.01937804,17.7553435 7.73858591,18.6369503 L8,18.952 L8.26141409,18.6369503 C8.80081999,17.9757452 9.33571138,17.2866275 9.85230757,16.5798717 L10.362295,15.8676171 C11.4533214,14.3111997 12.3506009,12.8081876 12.9859067,11.4203421 C13.6541556,9.96053154 14,8.69322797 14,7.7 C14,4.56503434 11.3350293,2 8,2 Z M8,5.5 C9.38,5.5 10.5,6.62 10.5,8 C10.5,9.38 9.38,10.5 8,10.5 C6.62,10.5 5.5,9.38 5.5,8 C5.5,6.62 6.62,5.5 8,5.5 Z"
            id="Combined-Shape"></path>
        </g>
      </g>
    </svg>
  </ng-template>

  <ng-template #participantsIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/account-circle-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icon-24px" transform="translate(2.000000, 2.000000)" fill="#8B96A0">
          <path
            d="M10,13.012 L10.3700198,13.0237689 C12.1606366,13.1044387 13.6501533,13.5145491 14.852186,14.244448 L15.1053691,14.4057461 L15.3183123,14.5538382 L15.5180441,14.7048128 C16.416552,15.4139042 16.8897167,16.2189581 16.9856386,17.1535465 C15.1825612,18.9143965 12.7172881,20 10,20 C7.28169492,20 4.8155936,18.9135837 3.01250114,17.1515563 L3.01416063,17.1279972 C3.12061798,16.0901116 3.72351453,15.1880152 4.89463085,14.4057461 C6.05566386,13.6302124 7.50320835,13.1737778 9.25088033,13.0460942 L9.62998021,13.0237689 L10,13.012 Z M10,0 C15.52,0 20,4.48 20,10 C20,11.9310307 19.4517505,13.7347892 18.5025197,15.2640074 C18.0447371,14.2920855 17.2759581,13.4504854 16.2162683,12.7426457 C14.6470851,11.6944803 12.7186975,11.1260277 10.4480505,11.0252763 L10.0182974,11.0118633 L9.55194952,11.0252763 C7.28130251,11.1260277 5.35291495,11.6944803 3.78373172,12.7426457 C2.72224462,13.4516859 1.95265609,14.2949406 1.49437841,15.2588608 C0.547292653,13.7316398 0,11.9293449 0,10 C0,4.48 4.48,0 10,0 Z M10,2 C7.78771525,2 6,3.78771525 6,6 C6,8.21228475 7.78771525,10 10,10 C12.2122847,10 14,8.21228475 14,6 C14,3.78771525 12.2122847,2 10,2 Z M10,4 C11.1077153,4 12,4.89228475 12,6 C12,7.10771525 11.1077153,8 10,8 C8.89228475,8 8,7.10771525 8,6 C8,4.89228475 8.89228475,4 10,4 Z"
            id="Shape"></path>
        </g>
      </g>
    </svg>
  </ng-template>


