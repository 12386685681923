
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { SearchActionTypes } from "../actions/search";
import { SearchFolder } from "../shared/models/search-folder";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Action } from "../actions/index";

export interface SearchState extends EntityState<SearchFolder> {
  selectedSearchFolder: SearchFolder;
  keyword: string;
  filters: any;
}

export const searchAdapter: EntityAdapter<SearchFolder> = createEntityAdapter<SearchFolder>({
  selectId: (searchFolder: SearchFolder) => searchFolder.id
});

export const initialState: SearchState = searchAdapter.getInitialState({
  selectedSearchFolder: null,
  filters: {},
  keyword: ""
});

export function searchReducer(state = initialState, action: Action): SearchState {
  switch (action.type) {

    case SearchActionTypes.SEARCH_KEYWORD_STORE: {
      return {
        ...state,
        keyword: action.payload as string
      };
    }

    case SearchActionTypes.SET_SEARCH_FILTERS: {
      return {
        ...state,
        filters: action.payload
      };
    }

    case SearchActionTypes.LOAD_SEARCHES_SUCCESS: {
      return searchAdapter.addAll(action.payload.folders, {
        ...state
      });
    }

    case SearchActionTypes.CREATE_SEARCHES_SUCCESS: {
      return searchAdapter.addOne(action.payload.folder, {
        ...state
      });
    }

    case SearchActionTypes.DELETE_SEARCHES_SUCCESS: {
      return searchAdapter.removeOne(action.payload.folder.id, {
        ...state
      });
    }

    case SearchActionTypes.UPDATE_SEARCHES_SUCCESS: {
      return searchAdapter.updateOne(action.payload.folder, {
        ...state
      });
    }

    case SearchActionTypes.SET_SEARCHES_SUCCESS: {
      return {
        ...state,
        selectedSearchFolder: action.payload.folder
      };
    }

    default: {
      return state;
    }
  }
}

export const _getSearchKeyword = (state: SearchState) => state.keyword;
export const _getSearchFilters = (state: SearchState) => state.filters;
