
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { CalendarState } from "src/app/reducers/calendar.reducer";
import { Subject } from "rxjs";
import { getCalendarFolders } from "src/app/reducers";
import { takeUntil } from "rxjs/operators";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { CreateCalendarItemFolderComponent } from "../create-calendar-folder/create-calendar-folder.component";

@Component({
    selector: "vp-add-item-dialog",
    templateUrl: "./add-item-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddItemDialogComponent implements OnInit, OnDestroy {
    private isAlive$ = new Subject<boolean>();
    calendarFolders: any[];
    allFolders: any[] = [];
    type: string = "";
    treeControl = new NestedTreeControl<any>(node => node.folder);
    dataSource = new MatTreeNestedDataSource<any>();
    selectedFolder: any;
    hasChild = (_: number, node: any) => !!node.folder && node.folder.length > 0;
    constructor(
        private dialogRef: MatDialogRef<AddItemDialogComponent>,
        private store: Store<CalendarState>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private changeDetectionRef: ChangeDetectorRef,
        private matDialog: MatDialog
    ) {
        if (this.data && this.data.type) {
            this.type = this.data.type;
        }
        this.store.select(getCalendarFolders).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            if (!!res) {
                this.calendarFolders = res;
                this.calendarFolders = this.calendarFolders.filter(f => !f.perm);
                if (this.type === "calendar") {
                    this.allFolders = this.calendarFolders;
                    this.dataSource.data = this.allFolders;
                    this.treeControl.dataNodes = this.allFolders;
                    this.treeControl.expandAll();
                    this.changeDetectionRef.markForCheck();
                }
            }
        });
        this.changeDetectionRef.markForCheck();
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    close(): void {
        this.dialogRef.close();
    }

    folderItem(folder: any): void {
        if (folder.id !== "") {
            this.selectedFolder = folder;
            this.changeDetectionRef.markForCheck();
        }
    }

    newFolderCreate(): void {
        this.matDialog.open(CreateCalendarItemFolderComponent, {
            width: "350px",
            height: "258px",
            autoFocus: true,
            panelClass: "calendar_folder-create_dialog",
            data: { targetFolder: this.selectedFolder }
        });
    }

    submit(): void {
        this.dialogRef.close({ folderId: this.selectedFolder.id });
    }

}
