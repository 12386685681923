
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Http, RequestOptions, Response, Headers, ResponseContentType } from "@angular/http";
import { ContactFolder } from "../models/contact-folder.model";
import { ContactUtils } from "../../common/utils/contact-utils";
import { ContactConstants } from "../shared/contacts-constants";
import { ContactOperationType, SuccessType } from "../shared/contacts-enum";
import { ContactOperation } from "../models/operation.model";
import { TranslateService } from "@ngx-translate/core";
import { Store } from "@ngrx/store";
import {
    ContactRootState, getContactFolderState, getContactState
} from "../store/reducers/index";
import { Contact } from "../models/contact.model";
import { UserProfile } from "../../shared/models/user-profile";
import { SetUserProfile, RestoreSavedState } from "../../actions/app";
import { SuccessService } from "../../common/providers/sucess-service";
import { getAppState, getUserProfile } from "../../reducers";
import * as _ from "lodash";
import { AuthService } from "../../common/providers/auth.service";
import { map, combineLatest, bufferTime, filter, catchError, take } from "rxjs/operators";
import { BehaviorSubject, Subject, Observable, Observer, throwError } from "rxjs";
import { isArray } from "util";
import { environment } from "src/environments/environment";
import { ConfigService } from "src/app/config.service";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { CommonUtils } from "src/app/common/utils/common-util";

@Injectable()
export class ContactService {
    createSearch: any;
    getSearchedQueries: any;
    private unknownError: string;
    tagList: any = [];
    openOperationDialog$: BehaviorSubject<ContactOperation> = new BehaviorSubject(null);
    titleHeaderShow$: Subject<boolean> = new Subject();
    private baseLoactionUrl: string = "";
    private veiwLoactionUrl: string = "";
    private viewEmbedMapLocation: string = "";
    private googleApiKey: string = "";
    private translationMessage: any = {};
    currentUser: UserProfile;
    flatFolders = {};
    flatArrayFolders = [];
    sharedIds: any = [];
    isCordovaOrElectron = environment.isCordova || environment.isElectron;

    constructor(private http: Http,
        private configService: ConfigService,
        private translate: TranslateService,
        public appStore: Store<ContactRootState>,
        private successService: SuccessService,
        private mailBroadcaster: MailBroadcaster,
        private authService: AuthService
    ) {
        this.translate
                .get([
                    ContactConstants.SOME_UNKNOWN_ERROR,
                    ContactConstants.SEARCH_NOT_ALLOWED_CHARACTER,
                    ContactConstants.CONTACT_SERVER_DOWN_ERROR,
                    "IMAGE_UPLOAD_MSG"
                ])
                .subscribe(res => {
                    this.unknownError = res.SOME_UNKNOWN_ERROR;
                    this.translationMessage = res;
                });
        this.openOperationDialog$.next({ type: ContactOperationType.None });
        this.googleApiKey = "AIzaSyCnwNeABa952XtuLvoQe-EDesdoI3mk8Q4";
        this.baseLoactionUrl = "https://maps.googleapis.com/maps/api/geocode/json?key=" + this.googleApiKey;
        this.veiwLoactionUrl = "http://maps.google.com/?key=" + this.googleApiKey;
        this.viewEmbedMapLocation = "https://www.google.com/maps?key=" + this.googleApiKey;
        this.appStore.select(getUserProfile).pipe(filter(v => !!v)).subscribe(res => {
            if (!!res) {
                console.log("getUserProfile", res);
                if (isArray(res.email)) {
                    res.email = res.email[0];
                }
              this.currentUser = res;
            }
          });
    }

    public setupStatePersistance() {
    }

    public restoreFromState() {
        const savedState = JSON.parse(localStorage.getItem("saved_state"));
        if (savedState) {
            this.appStore.dispatch(new RestoreSavedState(savedState));
        }
    }

    getlatlng(address): Observable<any> {
        return this.http.get(this.baseLoactionUrl + "&address=" + address).pipe(map(this.extractData));
    }

    getEmbedMapUrl(address) {
        return this.viewEmbedMapLocation + "&output=embed&q=" + address;

    }

    getDesktopMapUrl(address) {
        return this.veiwLoactionUrl + "&q=" + address;
    }

    titleHeaderShow(show: boolean) {
        this.titleHeaderShow$.next(show);
    }

    contcatActions(operation: ContactOperation) {
        this.openOperationDialog$.next(operation);
    }

    resetActions() {
        this.openOperationDialog$.next({ type: ContactOperationType.None });
    }

    getApiUrl(): string {
        return this.configService.API_URL;
    }

    handleTagForFavourite() {
        this.getTagList().subscribe(res => {
            if (res.gettagresponse) {
                if (res.gettagresponse.tag === undefined) {
                    this.createTag({ tagName: ContactConstants.FAVORITE_TAG }).subscribe();
                } else {
                    if (isArray(res.gettagresponse.tag)) {
                        res.gettagresponse.tag.map((tag) => {
                            this.tagList.push(tag.name);
                        });
                    } else {
                        this.tagList.push(res.gettagresponse.tag.name);
                    }
                    if (!this.isTagExists(this.tagList, ContactConstants.FAVORITE_TAG)) {
                        this.createTag({ tagName: ContactConstants.FAVORITE_TAG }).subscribe();
                    }
                }
            }
        });
    }

    downloadCsvFile(responseData: any, fileName: string): void {
        let blob = new Blob([(<any>responseData)], { type: "application/csv" });
        if (typeof cordova !== "undefined") {
            this.downloadCsvFileFromCordova(blob, fileName).subscribe();
        } else {
            let data = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.href = data;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
                document.body.removeChild(link);
                window.URL.revokeObjectURL(data);
            }, 100);
        }
    }

    downloadCsvFileFromCordova(blob: Blob, filename: string): any {
        return Observable.create((obs: Observer<any>) => {
            window["requestFileSystem"](window["PERSISTENT"], blob.size, (fs) => {
                fs.root.getFile(`Download/${filename}`, { create: true, exclusive: false }, (fileEntry) => {
                    fileEntry.createWriter((fileWriter) => {
                        fileWriter.onwriteend = () => {
                            obs.next(`Download/${filename}`);
                            obs.complete();
                        };
                        fileWriter.onerror = function (e) {
                            obs.error(e);
                            obs.complete();
                        };
                        fileWriter.write(blob);
                    });
                });
            });
        });
    }

    getInfo(): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });
        return this.http.get(this.configService.API_URL + "/api/getInfo", options).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    printContact(): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });
        let url = this.configService.API_URL + "/api/printContact";
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            url = url + "?token=" + token;
        }
        return this.http.get(url, options).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    getContactFolderList(body: any): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });
        return this.http.post(this.configService.API_URL + "/api/getFolderList", body, options).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    getContactsList(): Observable<any> {
        return this.http.get(this.configService.API_URL + "/api/contact_lists.json", { headers: this.getZimbraHeader() }).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    getFavoriteList(): Observable<any> {
        return this.http.get(this.configService.API_URL + "/api/getFavoriteList", { headers: this.getZimbraHeader() }).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    createContactsList(body): Observable<any> {
        return this.http.post(this.configService.API_URL + "/api/contact_lists.json", body, { headers: this.getZimbraHeader() }).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    getContactFolders(): Observable<ContactFolder[]> {
        let body = {
            view: "contact"
        };
        let options = new RequestOptions({ headers: this.getZimbraHeader() });
        return this.http.post(this.configService.API_URL + "/api/getFolderList", body, options).pipe(map(this.extractData),
            map(res => {
                let contactFolderResponse = ContactUtils.parseAllContactFolders(res);
                return contactFolderResponse;
            }), catchError(this.authService.handleErrorObservable.bind(this)));
    }

    searchRequest(body: any): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });
        return this.http.post(this.configService.API_URL + "/api/searchRequest", body, options).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    importContacts(files: any, destinationFolderName: string): Observable<any> {
        let options;
        let formData = new FormData();
        formData.append("file", files[0]);
        let destination = destinationFolderName === undefined ? "" : "&destination=" + encodeURIComponent(destinationFolderName);

        if (typeof cordova !== "undefined") {
            let headers = new Headers({ "Authorization": this.authService.getAuthToken() });
            options = new RequestOptions({ headers: headers });
        }

        return this.http.post(this.configService.API_URL + "/api/importContacts?email=" + this.currentUser.email +
            destination,
            formData,
            options
        ).pipe(map(this.returnResponse)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    exportContacts(folderName: string): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });
        console.log("[this.currentUser]", this.currentUser);
        return this.http.get(this.configService.API_URL + "/api/exportContacts?email=" + this.currentUser.email +
            "&contactType=" + encodeURIComponent(folderName) +
            "&filename="
            , options)
            .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
    }

    getAllContacts(curentOffset: number, limit: number, parmQuery: string, contactList?: number, noMailFilter?: boolean): Observable<Contact[]> {
        let body: any = {
            sortBy: "nameAsc",
            offset: curentOffset,
            limit: limit,
            fetch: 1,
            needExp: 1,
            query: parmQuery,
            noMailFilter,
            types: "contact"
        };
        if (contactList && +contactList > 0) {
          body.contact_list = contactList;
        }
        let options = new RequestOptions({ headers: this.getZimbraHeader() });
        return this.http.post(this.configService.API_URL + "/api/searchRequest", body, options).pipe(map(this.extractData), map(res => {
            let contacts: Contact[] = [];
            if (isArray(res.cn)) {
                contacts = res.cn.map(contact => {
                    return ContactUtils.getContact(contact, this.configService.API_URL) as Contact;
                });
            } else {
                if (res.cn) {
                    if (contacts.length > 0) {
                        contacts.push(ContactUtils.getContact(res.cn, this.configService.API_URL));
                    } else {
                        contacts = [ContactUtils.getContact(res.cn, this.configService.API_URL)];
                    }
                }
            }
            return contacts;
        }), catchError(this.authService.handleErrorObservable.bind(this)));
    }

    getContactBlobAvtar(avtarUrl: string): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader(), responseType: ResponseContentType.Blob });
        return this.http.get(avtarUrl, options).pipe(map(res2 => res2.blob())
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    getContactBlobAvtarForProfile(avtarUrl: string): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader(), responseType: ResponseContentType.Blob });
        return this.http.get(avtarUrl, { responseType: ResponseContentType.Blob }).pipe(map(res2 => res2.blob())
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    getContacts(body: any) {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });
        return this.http.post(this.configService.API_URL + "/api/getContacts", body, options).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    contactAction(body): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });

        return this.http.post(this.configService.API_URL + "/api/contactAction", body, options).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    modifyContact(body): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });

        return this.http.post(this.configService.API_URL + "/api/modifyContact", body, options).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    createContact(body): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });

        return this.http.post(this.configService.API_URL + "/api/createContact", body, options).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    createVNCdContact(body): Observable<any> {
        return this.http.post(this.configService.API_URL + "/api/createVNCdContact", body, {headers: this.getZimbraHeader()}).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    updateVNCdContact(body): Observable<any> {
        return this.http.put(this.configService.API_URL + "/api/updateVNCdContact", body, {headers: this.getZimbraHeader()}).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    removeContactsFromGroup(id, contactIds): Observable<any> {
        return this.http.delete(this.configService.API_URL + `/api/removeContactsFromGroup?group_id=${id}&contact_ids=${contactIds}`, {headers: this.getZimbraHeader()}).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    addContactToGroup(body): Observable<any> {
        return this.http.post(this.configService.API_URL + "/api/addContactToGroup", body, {headers: this.getZimbraHeader()}).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    updateContact(body): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });

        return this.http.post(this.configService.API_URL + "/api/updateContact", body, options).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    getContact(id): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });
        return this.http.get(this.configService.API_URL + `/api/getContact?id=${id}`, options).pipe(map(this.extractData),
        catchError(this.authService.handleErrorObservable.bind(this)));
    }

    getAccountDistributionLists (body: any): Observable<any> {
        return this.http.post(this.configService.API_URL + "/api/getAccountDistributionLists", body, { headers: this.getZimbraHeader() })
        .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
    }

    getDistributionList (body: any): Observable<any> {
        return this.http.post(this.configService.API_URL + "/api/getDistributionList", body, { headers: this.getZimbraHeader() })
        .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
    }

    getDistributionListMembers (body: any): Observable<any> {
        return this.http.post(this.configService.API_URL + "/api/getDistributionListMembers", body, { headers: this.getZimbraHeader() })
        .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
    }

    createFolder(body): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });
        return this.http.post(this.configService.API_URL + "/api/createFolder", body, options).pipe(map(this.extractData), map(res => {
            return res.folder[0] as ContactFolder;
        }), catchError(this.authService.handleErrorObservable.bind(this)));
    }

    folderAction(body): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });
        return this.http.post(this.configService.API_URL + "/api/folderAction", body, options).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    createTag(body): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });

        return this.http.post(this.configService.API_URL + "/api/createTag", body, options).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    createContactGroup(body): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });

        return this.http.post(this.configService.API_URL + "/api/createContactGroup", body, options).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    getAvatar(): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });

        return this.http.get(this.configService.API_URL + "/api/getAvatar", options).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    uploadAvatar(files): Observable<any> {
        let options;
        if (typeof cordova !== "undefined") {
            let headers = new Headers({ "Authorization": this.authService.getAuthToken() });
            options = new RequestOptions({ headers: headers });
        }
        return this.http.post(this.configService.API_URL + "/api/uploadAvatar", files, options).pipe(map(this.returnResponse)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    printContacts(contactIds) {
        if (typeof cordova !== "undefined") {
            this.getPrintContactData(contactIds).subscribe(res => {
                cordova.plugins.printer.print(res._body, { duplex: "long" }, (res2) => {
                });
            });
        } else {
            let url = this.configService.API_URL + "/api/printContact?id=" + contactIds;
            if (this.isCordovaOrElectron) {
                const token = localStorage.getItem("token");
                url = url + "&token=" + token;
            }
            window.open(url);
        }
    }

    getPrintContactData(contactIds: any): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });
        let url = this.configService.API_URL + "/api/printContact?id=" + contactIds;
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            url = url + "&token=" + token;
        }
        return this.http.get(url, options).pipe(map(this.returnResponse)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    getAttachment(): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });

        return this.http.get(this.configService.API_URL + "/api/getAttachment", options).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    getTagList(): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });
        return this.http.get(this.configService.API_URL + "/api/getTagList", options).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    modifyAvtar(body): Observable<any> {
        let options = new RequestOptions({ headers: this.getProfileManagerHeader() });
        body.folderId = ContactConstants.CONTACT_FOLDER_ID;
        return this.http.post(this.configService.API_URL + "/api/modifyProfileManagerContact", body, options)
        .pipe(map(this.extractData), map(res => {
            let contact: Contact = ContactUtils.getContact(res.cn, this.configService.API_URL, true);
            this.setUserProfile(contact, this);
        }));
    }

    createCurrentUserContact(user: any) {
        let userEmail: string = "";
        if (isArray(user.email)) {
            userEmail = user.email[0];
        } else {
            userEmail = user.email;
        }
        let body = {
            sortBy: "nameAsc",
            offset: 0,
            limit: 100,
            fetch: 1,
            needExp: 1,
            query: userEmail + " in: Contacts",
            types: "contact"
        };
        this.profileManagerSearchRequest(body).subscribe(resContacts => {
            if (resContacts.cn) {
                let contact: Contact = ContactUtils.getContact(resContacts.cn, this.configService.API_URL, true);
                this.setUserProfile(contact, this);
            } else {
                let userBody = { "contactAttrs": this.getContactAttributes(user), folderId: ContactConstants.CONTACT_FOLDER_ID, id: "" };
                this.createContactInProfileManager(userBody).subscribe(res => {
                    let contact: Contact = ContactUtils.getContact(res.cn, this.configService.API_URL, true);
                    this.setUserProfile(contact, this);
                });
            }
        });
    }

    getMatchedUserProfile(resContacts: any[], user): any {
        let roughContact = null;
        if (user) {
            resContacts.forEach(contact => {
                let coindition: number = 0;
                let firtsName = contact.firstName ? contact.firstName : "";
                let lastName = contact.lastName ? contact.lastName : "";
                let email = contact.email[0];

                if (user.email && user.email === email) {
                    coindition += 1;
                }
                if (user.firstName && user.firstName === firtsName) {
                    coindition += 1;
                }
                if (user.lastName && user.lastName === lastName) {
                    coindition += 1;
                }
                if (coindition > 2) {
                    roughContact = contact;
                }
            });
        }
        return roughContact;
    }

    getContactFolder(body: any): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });
        return this.http.post(this.configService.API_URL + "/api/getFolder",
        { folder: { "path": body.path }, view: "contact" }, options).pipe(map(this.extractData)
            , map(res => {
                let contactFolderResponse = ContactUtils.parseAllContactFolders(res);
                return contactFolderResponse;
            }), catchError(this.authService.handleErrorObservable.bind(this)));
    }

    private setUserProfile(contact: Contact, currentService: any) {
        let firtsName = contact.firstName ? contact.firstName : "";
        let lastName = contact.lastName ? contact.lastName : "";
        let fullName = firtsName + " " + lastName;
        let profile: UserProfile = {
            contactId: contact.id,
            firstName: contact.firstName,
            lastName: contact.lastName,
            email: contact.email[0],
            avtarUrl: contact.image,
            fullName: fullName,
            firstLastCharacters: contact.firstLastCharacters
        };
        if (profile.avtarUrl) {
            this.getContactBlobAvtarForProfile(profile.avtarUrl).subscribe(res => {
                let reader = new FileReader();
                reader.onloadend = (e) => {
                    profile.imageData = reader.result.toString();
                    if (profile.firstName !== undefined) {
                        localStorage.setItem("profileUser", JSON.stringify(profile));
                    }
                    currentService.appStore.dispatch(new SetUserProfile(profile));
                };
                reader.readAsDataURL(res);
            });
        } else {
            if (!!profile) {
                localStorage.setItem("profileUser", JSON.stringify(profile));
            }
            this.appStore.dispatch(new SetUserProfile(profile));
        }
    }

    private getContactAttributes(user: any): any[] {
        let contactAttrs: any[] = [];
        let email: string;
        if (isArray(user.email)) {
            email = user.email[0];
        } else { email = user.email; }
        if (user.firstName !== undefined) {
            contactAttrs.push({ "key": "firstName", "value": user.firstName });
        }
        if (user.lastName !== undefined) {
            contactAttrs.push({ "key": "lastName", "value": user.lastName });
        }
        if (user.email) {
            contactAttrs.push({ "key": "email", "value": email });
        }
        return contactAttrs;
    }

    getSearchError(): void {
        this.successService.emit({ id: SuccessType.GenericMessage, messages: this.translationMessage.SEARCH_NOT_ALLOWED_CHARACTER });
    }

    isTagExists(tagList: any, existingTag: string): boolean {
        let existTag: boolean = false;
        for (let i = 0; i < tagList.length; i++) {
            if (tagList[i] === existingTag) {
                existTag = true;
            }
        }
        return existTag;
    }
    private getZimbraHeader() {
        let headers = new Headers({
            "Content-Type": "application/json",
            "Accept": "application/json"
        });
        if (this.isCordovaOrElectron) {
            headers = new Headers({ "Content-Type": "application/json", "Accept": "application/json",
            "Authorization": this.authService.getAuthToken() });
        }
        return headers;
    }

    private extractData(res: Response) {
        let body = res.json();
        return body || {};
    }

    private returnResponse(res: Response) {
        return res;
    }

    private handleErrorObservable(error: Response | any) {
        if (error.error && error.error.msg === "no valid authtoken present" ) {
          setTimeout(() => {
            if (environment.isCordova) {
              this.http.get(this.configService.API_URL + `/api/cordova-logout`);
              window.location.href = CommonUtils.getBaseUrl() + "/www/index.html";
            } else {
                if (this.authService) {
                // this.authService.generateNewToken();
                console.log("[generateNewToken] from backend");
                } else {
                window.location.href = "/api/call-logout";
                }
            }
          }, 1500);
        }
        if (error.error && error.error.msg) {
          return throwError(error.error.msg);
        } else if (error._body && this.isJson(error._body)) {
            const body = JSON.parse(error._body);
            console.log("[handleErrorObservable]", body);
            if (body.msg) {
                if (body.msg.indexOf("contact must have fields") !== -1) {
                    return throwError("CONTACT_MUST_HAVE_FIELDS");
                } else {
                    return throwError(body.msg);
                }
            } else {
                return throwError(error);
            }
        } else if (error.error && error.error instanceof String) {
          if ( error.error.indexOf("RangeError: Maximum call stack size exceeded") !== -1) {
            return throwError("RangeError: Maximum call stack size exceeded");
          } else {
            return throwError(error.error);
          }
        } else {
          return throwError(error);
        }
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    private handleErrorPromise(error: Response | any) {
        return Promise.reject(error.message || error);
    }

    shareVCFToMobile(vcfData: string): void {
        window.requestFileSystem(window.TEMPORARY, 5 * 1024 * 1024, function (fs) {
            let month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            let date = new Date();
            let fileName = "vcards_" + date.getFullYear() + "" + month[date.getMonth()]
            + "" + date.getDate() + "_" + date.getTime() + ".vcf";
            fs.root.getFile(fileName, { create: true, exclusive: false }, function (fileEntry) {
                let dataObj;
                fileEntry.createWriter(function (fileWriter) {
                    fileWriter.onwriteend = function () {
                        /* Share contact in cordova */
                        let options = {
                            message: "VNCcontacts share",
                            subject: "VNCcontacts share",
                            files: [fileEntry.nativeURL],
                            chooserTitle: "Share with" // Android only, you can override the default share sheet title
                        };
                        let onSuccess = function (result) {
                        };
                        let onError = function (msg) {
                        };
                        window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
                    };
                    fileWriter.onerror = function (e) {
                    };
                    dataObj = new Blob([vcfData], { type: "text/vcard" });
                    fileWriter.write(dataObj);
                });
            }, function (error) {
            });
        }, function (error) {
        });
    }

    profileManagerLogin(): Observable<any> {
        let headers = new Headers({
            "Content-Type": "application/json"
        });
        let options = new RequestOptions({ headers: headers });
        return this.http.post(this.configService.API_URL + "/api/loginProfileManager", {}, options).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    profileManagerSearchRequest(body: any): Observable<any> {
        let options = new RequestOptions({ headers: this.getProfileManagerHeader() });
        return this.http.post(this.configService.API_URL + "/api/profileManagerSearchRequest", body, options).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    private getProfileManagerHeader() {
        let headers = new Headers({ "Content-Type": "application/json", "Accept": "application/json" });
        return headers;
    }

    createContactInProfileManager(body): Observable<any> {
        let options = new RequestOptions({ headers: this.getProfileManagerHeader() });
        return this.http.post(this.configService.API_URL + "/api/createContactInProfileManager", body, options).pipe(map(this.extractData)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    uploadAvatarToProfileManager(files): Observable<any> {
        let headers = new Headers();
        let options = new RequestOptions({ headers: headers });
        return this.http.post(this.configService.API_URL + "/api/uploadAvatarToProfileManager", files, options)
        .pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
    }

    getProfileManagerToken(): Observable<any> {
        let headers = new Headers();
        let options = new RequestOptions({ headers: headers });
        return this.http.get(this.configService.API_URL + "/api/getProfileManagerAuthToken", options).pipe(map(this.returnResponse)
            , catchError(this.authService.handleErrorObservable.bind(this)));
    }

    getContactFolderItem(body: any): Observable<any> {
        let options = new RequestOptions({ headers: this.getZimbraHeader() });
        return this.http.post(this.configService.API_URL + "/api/getFolder",
        { folder: { "path": body.path }, view: "contact" }, options).pipe(map(this.extractData),
            catchError(this.authService.handleErrorObservable.bind(this)));
    }

    setMailBoxMetaData(metaData: any): Observable<any> {
        const a = [];
        for (const key of Object.keys(metaData)) {
          if (key === "zimbraPrefFoldersExpanded" && metaData[key] === "") {
            a.push({
              "@": {
                n: key
              }
            });
          } else {
            a.push({
              "@": {
                n: key
              },
              "#": metaData[key]
            });
          }
        }

        const request = {
          SetMailboxMetadataRequest: {
            "@": {
              "xmlns": "urn:zimbraMail",
              "requestId": "0"
            },
            "meta": {
              "@": {
                "section": "zwc:implicit"
              },
              a
            }
          }
        };

        return this.http.post(
          this.configService.API_URL + "/api/batchRequest",
          request,
          {
            headers: this.getZimbraHeader()
          }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
      }
}
