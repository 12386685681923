
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ViewChild, ChangeDetectorRef, ElementRef, NgZone, OnInit, Inject, OnDestroy, AfterViewInit, ViewChildren, QueryList } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MailService } from "../../services/mail-service";
import { take, takeUntil, distinctUntilChanged, debounceTime } from "rxjs/operators";
import * as _ from "lodash";
import { ErrorService } from "src/app/common/providers/error-service";
import { ErrorType } from "src/app/common/enums/mail-enum";
import { SearchRequest } from "../../models";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { RootState, getOnlineStatus } from "src/app/reducers";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { FormControl } from "@angular/forms";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { DatabaseService } from "src/app/services/db/database.service";


@Component({
    selector: "vp-mobile-select-address-dialog",
    templateUrl: "./mobile-select-address-dialog.component.html"
})
export class MobileSelectAddressDialogComponent implements OnInit, OnDestroy, AfterViewInit {
    isSearchView: boolean = false;
    @ViewChild("searchFormInput", { static: false }) searchFormInput: ElementRef<HTMLInputElement>;
    searchForm = new FormControl("");
    elementScrolled$: any;
    searchField: string = "";
    isOnline: boolean = false;
    allEmailAddresses: any[] = [];
    allLoadedEmailAddresses: any[] = [];
    checkedAddressStatus: { [id: number]: boolean } = {};
    checkedIds: any[] = [];
    type: string = "";
    selectedShowOption: string = "";
    maxBufferPx = 960; // 380?
    minBufferPx = 960; // 193?
    offset: number = 0;
    @ViewChild(CdkVirtualScrollViewport, { static: false })
    viewport: CdkVirtualScrollViewport;
    @ViewChildren(CdkVirtualScrollViewport)
    viewports: QueryList<CdkVirtualScrollViewport>;
    private isAlive$ = new Subject<boolean>();
    isFirstSearch: boolean = true;
    constructor(
        public dialogRef: MatDialogRef<MobileSelectAddressDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private changeDetectorRef: ChangeDetectorRef,
        private mailService: MailService,
        private errorService: ErrorService,
        private mailBroadcaster: MailBroadcaster,
        private hotKeyService: NgxHotkeysService,
        private dbService: DatabaseService,
        private store: Store<RootState>,
        private ngZone: NgZone
    ) {
        this.hotKeyService.pause(this.hotKeyService.hotkeys);
        this.type = this.data.type;
        this.store.select(getOnlineStatus).pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(o => {
            this.isOnline = o;
        });
        this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_MOBILE_ADDRESS_SELECT_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
        this.searchForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
            if (this.isSearchView) {
                this.offset = 0;
                this.allLoadedEmailAddresses = [];
                this.allEmailAddresses = [];
                console.log("mobile-select-address-dialog triggersearch: ", this.searchForm.value, JSON.stringify(this.allLoadedEmailAddresses));
                this.searchEmailAddresses();
            }
        });

    }

    ngOnInit() {
        this.changeShowNames("contacts");
        this.selectedShowOption = "contacts";
    }
    subscribeElementScrolled() {
        if (!!this.viewport) {
            if (this.elementScrolled$) {
                this.elementScrolled$.unsubscribe();
            }

            this.elementScrolled$ = this.viewport.elementScrolled().pipe(debounceTime(20), takeUntil(this.isAlive$))
                .subscribe((event: any) => {
                    if (this.isOnline) {
                        if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 100) {
                            this.offset++;
                            this.searchEmailAddresses();
                        }
                    } else {
                        if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 50) {
                            if (this.allEmailAddresses.length > this.allLoadedEmailAddresses.length) {
                                // console.log("elementScrolled => extend", this.offset, this.offset + 1);
                                this.offset++;
                                const emailAddressesToAdd = this.allEmailAddresses.slice(this.offset * 50, (this.offset + 1) * 50);
                                this.allLoadedEmailAddresses = this.allLoadedEmailAddresses.concat(emailAddressesToAdd);
                                // console.log("elementScrolled => extend", emailAddressesToAdd, this.allLoadedEmailAddresses);
                                this.changeDetectorRef.markForCheck();
                            }
                        }
                    }
                });
        }
    }

    trackByFn(index: number, item: any): string {
        return item.id;
    }

    ngAfterViewInit() {

        this.viewports.changes.subscribe(v => {
            console.log("[mobnileSelectAddressesComponent][viewports change]", v);
            this.subscribeElementScrolled();
        });

    }


    close(address?: any[]) {
        this.dialogRef.close({ address: address });
    }
    showSearch(value: boolean): void {
        this.isSearchView = value;
        if (!value) {
            this.searchForm.setValue("");
            if ((this.selectedShowOption !== "contacts") || !this.isSearchView) {
                this.changeShowNames("contacts");
                this.selectedShowOption = "contacts";
            }
        } else {
            setTimeout(() => {
                if (this.searchFormInput) {
                    this.searchFormInput.nativeElement.focus();
                }
            }, 200);
        }
    }

    searchEmailAddressesEnter() {
        this.allLoadedEmailAddresses = [];
        this.changeShowNames(this.selectedShowOption, true);
    }

    searchEmailAddresses() {
        this.changeShowNames(this.selectedShowOption, true);
    }

    changeShowNames(value, isSearch?: boolean) {
        if (value !== this.selectedShowOption) {
            this.allEmailAddresses = [];
            this.allLoadedEmailAddresses = [];
        }
        this.selectedShowOption = value;
        this.checkedIds = [];
        this.checkedAddressStatus = {};
        this.isFirstSearch = false;

        if (value === "global") {
            const query = {
                name: this.searchField,
                limit: 100
            };
            this.mailService.searchGalRequest(query).pipe(take(1)).subscribe(res => {
                this.allEmailAddresses = [];
                if (res.cn) {
                    res.cn.forEach(ele => {
                        const fullName = ele._attrs.fullName ? ele._attrs.fullName : ele._attrs.firstName;
                        const firstName = ele._attrs.firstName ? ele._attrs.firstName : "";
                        const fullNameUser = !!fullName ? fullName : ele._attrs.email;
                        this.allEmailAddresses.push({ d: firstName, name: fullNameUser, email: ele._attrs.email });
                    });
                    this.allEmailAddresses = this.allEmailAddresses.filter( e => !!e.email && e.email !== "");
                }
            }, error => {
                this.errorService.emit({ id: ErrorType.Generic, messages: error });
            });

        } else if (value === "personalandshared") {
            this.getContactFolders(isSearch);
        } else {
            if (this.isOnline) {
                const query: SearchRequest = {
                    field: "contact",
                    limit: 100,
                    needExp: 1,
                    offset: 0 + (100 * this.offset),
                    query: this.searchForm.value !== "" ? "(\"" + this.searchForm.value + "\") (is:local)" : "(is:local)",
                    sortBy: "nameAsc",
                    types: "contact"
                };
                this.mailService.searchRequest(query).subscribe(
                    res => {
                        this.allEmailAddresses = [];
                        if (res.cn) {
                            res.cn.forEach(ele => {
                                const fullName = ele._attrs.fullName ? ele._attrs.fullName : ele._attrs.firstName;
                                const firstName = ele._attrs.firstName ? ele._attrs.firstName : "";
                                const fullNameUser = !!fullName ? fullName : ele._attrs.email;
                                this.allEmailAddresses.push({ d: firstName, name: fullNameUser, email: ele._attrs.email });
                            });
                            this.allEmailAddresses = this.allEmailAddresses.filter(e => !!e.email && e.email !== "");
                            this.allLoadedEmailAddresses = this.allLoadedEmailAddresses.concat(this.allEmailAddresses);
                            console.log("mobile-select-address-dialog online post-search: ", this.searchForm.value, this.allLoadedEmailAddresses);
                        }
                    }, error => {
                        this.errorService.emit({ id: ErrorType.Generic, messages: error });
                    });
            } else {
                this.dbService.searchContactsCalendar(this.searchForm.value.trim()).subscribe(res => {
                    // console.log("[Mobile-ParticipantAddressSearch selectEmailDBResults: ", res);
                    this.allEmailAddresses = [];
                    this.allLoadedEmailAddresses = [];
                    this.offset = 0;
                    res.forEach(ele => {
                        const fullName = ele._attrs.fullName ? ele._attrs.fullName : ele._attrs.firstName;
                        const firstName = ele._attrs.firstName ? ele._attrs.firstName : "";
                        const fullNameUser = !!fullName ? fullName : ele._attrs.nickname ? ele._attrs.nickname : ele._attrs.email;
                        this.allEmailAddresses.push({ d: firstName, name: fullNameUser, email: ele._attrs.email, id: ele.id });
                    });
                    this.allEmailAddresses = _.sortBy(this.allEmailAddresses.filter(e => !!e.email && e.email !== ""), o => o.name);
                    if (this.allEmailAddresses.length > 50) {
                        if (this.offset === 0) {
                            this.allLoadedEmailAddresses = this.allEmailAddresses.slice(0, 50);
                        } else {
                            const emailAddressesToAdd = this.allEmailAddresses.slice(this.offset * 50, (this.offset + 1) * 50);
                            emailAddressesToAdd.forEach(a => {
                                this.allLoadedEmailAddresses.push(a);
                            });
                        }
                    } else {
                        this.allLoadedEmailAddresses = this.allEmailAddresses;
                    }

                }, error => {
                    this.errorService.emit({ id: ErrorType.Generic, messages: error });
                });
            }
        }
    }

    getContactFolders(isSearch) {
        const body = {
            view: "contact",
        };
        this.mailService.getContactFolders(body).subscribe(
            res => {
                let que = "";
                if (res.folder[0].link) {
                    for (let i = 0; i < res.folder[0].link.length; i++) {
                        if (i === 0) {
                            que += "inid:" + res.folder[0].link[i].id;
                        } else {
                            que += " OR inid:" + res.folder[0].link[i].id;
                        }
                    }
                }
                if (que.length > 0) {
                    que = "(" + que + " OR is:local)";
                } else {
                    que = "(is:local)";
                }
                if (this.searchField !== "") {
                    que = "(" + this.searchField + ")" + que;
                }
                const query: SearchRequest = {
                    field: "contact",
                    limit: 100,
                    needExp: 1,
                    offset: 0,
                    query: que,
                    sortBy: "nameAsc",
                    types: "contact"
                };
                this.mailService.searchRequest(query).subscribe(searchRes => {
                    this.allEmailAddresses = [];
                    if (searchRes.cn) {
                        searchRes.cn.forEach(ele => {
                            const fullName = ele._attrs.fullName ? ele._attrs.fullName : ele._attrs.firstName;
                            const firstName = ele._attrs.firstName ? ele._attrs.firstName : "";
                            const fullNameUser = !!fullName ? fullName : ele._attrs.email;
                            this.allEmailAddresses.push({ d: firstName, name: fullNameUser, email: ele._attrs.email });
                        });
                        this.allEmailAddresses = this.allEmailAddresses.filter( e => !!e.email && e.email !== "");
                    }
                }, error => {
                    this.errorService.emit({ id: ErrorType.Generic, messages: error });
                });
            },
            err => {
                this.errorService.emit({ id: ErrorType.Generic, messages: err });
            }
        );
    }

    onSelectAddress(id: number): void {
        this.checkedAddressStatus[id] = this.checkedAddressStatus[id] ? false : true;
        this.toggleMessage(id);
    }

    toggleMessage(id: number): void {
        const index = this.checkedIds.indexOf(id);
        if (index === -1) {
            this.checkedAddressStatus[id] = true;
            this.checkedIds.push(id);
        } else {
            this.checkedAddressStatus[id] = false;
            this.checkedIds.splice(this.checkedIds.indexOf(id), 1);
        }
    }

    selectUnselectAll(): void {
        if (this.checkedIds.length === this.allEmailAddresses.length) {
            this.checkedIds = [];
            this.checkedAddressStatus = {};
        } else {
            this.checkedIds = [];
            this.checkedAddressStatus = {};
            for (let i = 0; i < this.allEmailAddresses.length; i++) {
                this.onSelectAddress(i);
            }
        }
    }

    submit(): void {
        const address: any[] = [];
        this.checkedIds.map(id => {
            address.push(this.allEmailAddresses[id]);
        });
        this.close(address);
    }

    ngOnDestroy() {
        this.hotKeyService.unpause(this.hotKeyService.hotkeys);
        this.isAlive$.next(false);
        this.isAlive$.unsubscribe();
    }
}
