
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="mail_folder-share_dialog">
  <!-- <div class="mail__dialog-header mobile-header">
    <div>
      <button (click)="close()" class="brand-color mat-button show-in-mobile">
        <mat-icon class="disable-select">chevron_left</mat-icon>
      </button>
    </div>
    <div class="header-title ">
      <span class="disable-select" *ngIf="!url.startsWith('/calendar')">{{ 'SHARE_FOLDER' | translate }}</span>
      <span class="disable-select" *ngIf="url.startsWith('/calendar')">{{ 'CALENDARS.SHARE_CALENDAR_LBL' | translate }}</span>
    </div>
    <div class="desktop-close" style="height: 55px;">
      <button class="brand-color mat-button" style="padding-top: 15px;" (click)="close()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="mobile_display_header">
    <div>
      <span *ngIf="!url.startsWith('/calendar')">{{ 'SHARE_FOLDER' | translate }}</span>
      <span *ngIf="url.startsWith('/calendar')">{{ 'CALENDARS.SHARE_CALENDAR_LBL' | translate }}</span>
    </div>
  </div> -->
  <vnc-header
    class="message"
    title="{{ 'SHARE_FOLDER' | translate }}"
    type="title-close"
    size="l"
    (onCloseClick)="close()"
    [showCloseIcon]="true"
    closeIconToolTip="{{ 'CLOSE' | translate }}">
  </vnc-header>
  <div class="mail__dialog-body cdk-virtual-scroll-viewport">
    <div>
      <vnc-input #foldername placeholder="{{'FOLDER_NAME_LBL'| translate }}" [showLabel]="true" [form]="folderTitleControl" ></vnc-input>
      <mat-hint>{{folderTitleControl.value.length}}/{{maxFolderTitleLength}}</mat-hint>
    </div>
    <div class="share_with_section">
      <div class="title">
        {{ "SHARE_WITH" | translate }}
      </div>
      <mat-radio-group class="share-radio-group" [(ngModel)]="shareWithOption">
        <mat-radio-button class="share-radio-button" [value]="'guest'" [disabled]="isDisabledExternalSharingFeature">
          {{ "EXTERNAL_GUEST" | translate }}
        </mat-radio-button>
        <mat-radio-button class="share-radio-button" [value]="'pub'" [disabled]="isDisabledPublicSharingFeature">
          {{ "PUBLICH_VIEW" | translate }}
        </mat-radio-button>
        <mat-radio-button class="share-radio-button" [value]="'usr'">
          {{ "OTHER_USER_MAIL" | translate }} {{prefixBold}}{{suffixNormal}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="shareWithOption !== 'pub'">
      <div class="email_section">
        <div class="to_compose_mail_autocomplete">
          <vp-autocomplete [appearance]="'outline'"
          labelInput="{{ 'ADD_A_USER' | translate }}"
          [isFocused]=true [place]="addEmailLabel" #addEmailAutoComplete [showAvatar]="true" [libraryChips]="true"></vp-autocomplete>
        </div>
      </div>
      <div class="role_section" *ngIf="shareWithOption === 'usr'">
        <div class="title">
          {{ "ROLE" | translate }}
        </div>
        <mat-radio-group class="share-radio-group" [(ngModel)]="shareRole">
          <mat-radio-button class="share-radio-button" [value]="''">
            {{ "NONE" | translate }}:
          </mat-radio-button>
          <mat-radio-button class="share-radio-button" [value]="'r'">
            {{ "VIEWER_VIEW" | translate }}
          </mat-radio-button>
          <mat-radio-button class="share-radio-button" [value]="'rwidx'">
            {{ "MANAGER_VIEW" | translate }}
          </mat-radio-button>
          <mat-radio-button class="share-radio-button" [value]="'rwidxa'">
            {{ "ADMIN_VIEW" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="message_section">
        <div class="title">
          {{ "MESSAGE_COLON" | translate }}
        </div>
        <div *ngIf="shareReply !== '0'" class="message-title">
          {{ "MESSAGE_NOTE" | translate }}
        </div>
        <mat-form-field appearance="outline">
          <mat-label>{{ "MESSAGE_OPTION" | translate }}</mat-label>
          <mat-select [(value)]="shareReply" (selectionChange)="changeshareReply($event.value)" panelClass="sharePanelClass">
            <mat-option value="0">{{ "MESSAGE_OPTION1" | translate }}</mat-option>
            <mat-option value="1">{{ "MESSAGE_OPTION2" | translate }}</mat-option>
            <mat-option value="2">{{ "MESSAGE_OPTION3" | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="shareReply === '2'">
        <mat-form-field appearance="outline">
          <mat-label>{{ "NOTE" | translate }}</mat-label>
          <textarea [(ngModel)]="notes" matInput placeholder="{{ 'MESSAGE_COLON'| translate }}"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="briefcaseShareURL!==''" class="briefcase-url">
      <div class="url">
        {{ 'URL_LBL_MSG' | translate }}
      </div>
      <div class="content-data">
        {{ 'SHARE_DIALOG_URL_CONTENT' | translate }}
      </div>
      <div class="send-url">
        <a href="{{briefcaseShareURL}}" class="copy-url-briefcase-share" target="_blank">{{briefcaseShareURL.substr(0,30) + '...' + briefcaseShareURL.substr(-10)}}</a>
        <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-content-copy" (click)="copyShareURL()"></mat-icon>
      </div>
    </div>

    <div *ngIf="isCalendarShare" class="briefcase-url">
      <div class="url">
        {{ 'URL_LBL_MSG' | translate }}
      </div>
      <div class="content-data">
        {{ 'SHARE_DIALOG_URL_CONTENT' | translate }}
      </div>
      <div class="send-url">
        <span class="lable-link">{{ 'CALENDARS.ICS_LBL' | translate }}:</span>
        <a href="{{calendarShareURL}}.ics" class="copy-url-briefcase-share" target="_blank">{{calendarShareURL.substr(0,25) + '...' + calendarShareURL.substr(-10)}}.ics</a>
        <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-content-copy" (click)="copyShareURL(calendarShareURL + '.ics')"></mat-icon>
      </div>
      <div class="send-url">
        <span class="lable-link">{{ 'VIEW' | translate }}:</span>
        <a href="{{calendarShareURL}}.html" class="copy-url-briefcase-share" target="_blank">{{calendarShareURL.substr(0,25) + '...' + calendarShareURL.substr(-10)}}.html</a>
        <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-content-copy" (click)="copyShareURL(calendarShareURL + '.html')"></mat-icon>
      </div>
      <div class="send-url">
        <span class="lable-link">{{ 'OUTLOOK' | translate }}:</span>
        <a href="{{calendarShareURL}}" class="copy-url-briefcase-share" target="_blank">{{calendarShareURL.substr(0,30) + '...' + calendarShareURL.substr(-10)}}</a>
        <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-content-copy" (click)="copyShareURL(calendarShareURL)"></mat-icon>
      </div>
    </div>

  </div>
  <vnc-footer [secondaryLabel]="'CANCEL_MAIL_LABEL' | translate | uppercase"
  [primaryLabel]="'ADD' | translate | uppercase"
  [type]="'secondary-primary'"
  [color]="'white'"
  (onSecondaryClick)="closeDialog()"
  (onPrimaryClick)="shareFolder()"></vnc-footer>
  <!-- <div class="footer_save_cancel">
    <span *ngIf="!url.startsWith('/calendar')">{{ 'SHARE_FOLDER_LBL' | translate }}</span>
    <span *ngIf="url.startsWith('/calendar')">{{ 'CALENDARS.SAHRE_CALENDAR_LBL' | translate }}</span>
    <button mat-button (click)="shareFolder()">
      <mat-icon class="disable-select">check</mat-icon>
    </button>
  </div>
  <div class="footer_save_cancel_mobile">
    <button mat-button class="cancel_btn" (click)="closeDialog()">{{ "CANCEL_MAIL_LABEL" | translate }}</button>
    <button mat-button class="save_btn" (click)="shareFolder()">{{ "PREFERENCES.SHARE" | translate }}</button>
  </div> -->
</div>