
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import * as fromPreference from "../actions/preference.action";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action } from "src/app/actions";
import * as _ from "lodash";
import { Preference, Signature, DataSource } from "../../shared/models";
import { Identity } from "../../shared/models/identity.model";
import { Attribute } from "../../shared/models/attribute.model";

export interface PreferenceState extends EntityState<Preference> {
    isLoading: boolean;
    isLoaded: boolean;
    signatures: Signature[];
    attributes: Attribute[];
    selectedAccount: DataSource;
    identity: Identity;
}

export const preferenceAdapter: EntityAdapter<Preference> = createEntityAdapter<Preference>({
    selectId: (preference: Preference) => preference.key
});

export const initialState: PreferenceState = preferenceAdapter.getInitialState({
    isLoading: false,
    isLoaded: false,
    signatures: [],
    attributes: [],
    selectedAccount: null,
    identity: null
});

export function referenceReducer(
    state = initialState,
    action: Action
): PreferenceState {
    switch (action.type) {

        case fromPreference.IS_PREFERENCE_LOADING: {
            return {
                ...state,
                isLoading: true
            };
        }

        case fromPreference.RESET_PREFERENCE_STATE: {
            return preferenceAdapter.removeAll(state);
        }

        case fromPreference.LOAD_PREFERENCE_SUCCESS: {
            const newState = {
                ...state,
                isLoading: false,
                isLoaded: true
            };
            return preferenceAdapter.addMany(action.payload, newState);
        }

        case fromPreference.LOAD_PREFERENCE_FAILED: {
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
            };
        }

        case fromPreference.REMOVE_PREFERENCES: {
            return preferenceAdapter.removeAll({
                ...state
            });
        }

        case fromPreference.UPDATE_MANY_PREFERENCES_SUCCESS: {
            const newState = {
                ...state,
                isLoading: false,
                isLoaded: true
            };
            const changes = action.payload.map(pref => {
                return { id: pref.key, changes: pref };
              });
            return preferenceAdapter.updateMany(changes, newState);
        }

        case fromPreference.UPDATE_SIGNATURES: {
            console.log("[UPDATE_SIGNATURES]", action.payload);
            return {
                ...state,
                signatures: action.payload
            };
        }

        case fromPreference.UPDATE_ATTRIBUTES: {
            console.log("[UPDATE_ATTRIBUTES]", action.payload);
            return state;
        }

        case fromPreference.RESET_SIGNATURES: {
            return {
                ...state,
                signatures: []
            };
        }

        case fromPreference.ADD_SIGNATURE: {
            return {
                ...state,
                signatures: [
                    ...state.signatures,
                    ...action.payload
                ]
            };
        }

        case fromPreference.REMOVE_SIGNATURES: {
            const ids = action.payload;
            const signatures = state.signatures.filter(signature => ids.indexOf(signature.id) === -1);
            return {
                ...state,
                signatures: signatures
            };
        }

        case fromPreference.SELECT_ACCOUNT: {
            return {
                ...state,
                selectedAccount: action.payload
            };
        }

        case fromPreference.SET_IDENTITY: {
            return {
                ...state,
                identity: action.payload
            };
        }

        default: {
            return state;
        }
    }
}

export const _getIsPreferencesLoading = (state: PreferenceState) => state.isLoading;
export const _getSignatures = (state: PreferenceState) => state.signatures;
export const _getAttributes = (state: PreferenceState) => state.attributes;
export const _getSelectedAccount = (state: PreferenceState) => state.selectedAccount;
export const _getIdentity = (state: PreferenceState) => state.identity;
