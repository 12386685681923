
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div>
    <div class="header">
        {{ 'OUT_OF_OFFICE_ALERT_LBL' | translate }}
    </div>
    <div class="content">
        <div class="message">
            {{ 'OUT_OF_OFFICE_ALERT_TURN_OFF_MSG' | translate }}
        </div>
        <div class="checkbox_div">
            <mat-checkbox name="remindAlert" [(ngModel)]="remind">{{ 'OUT_OF_OFFICE_ALERT_REMIND_MSG' | translate }}</mat-checkbox>
        </div>
    </div>
    <div class="footer">
        <button mat-button (click)="yes()" [disableRipple]=true>
            <div>{{ 'YES_LABEL' | translate }}</div>
        </button>
        <button mat-button [disableRipple]=true (click)="no()">
            <div>{{ 'NO_LABEL' | translate }}</div>
        </button>
    </div>
</div>