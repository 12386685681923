
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="folder_tree_division">
    <div class="header">
        <div class="text">{{ 'ADD_TO_CALEDAR' | translate }}</div>
        <div class="icon-close" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="tree-division">
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="folder-tree">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle [class.selected-class]="selectedFolder && node.id === selectedFolder.id">
                <li class="mat-tree-node">
                    <div (click)="folderItem(node)">
                        <button mat-icon-button disabled></button>
                        <mat-icon [style.color]="node.folderColor" class="mdi-18px disable-select" fontSet="mdi"
                            fontIcon="mdi-calendar-blank"></mat-icon>{{node.name}}
                    </div>
                </li>
            </mat-tree-node>
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <li>
                    <div class="mat-tree-node" [class.selected-class]="selectedFolder && node.id === selectedFolder.id" (click)="folderItem(node)">
                        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        <mat-icon [style.color]="node.folderColor" class="mdi-18px disable-select" fontSet="mdi"
                            fontIcon="mdi-calendar-blank"></mat-icon>{{node.name}}
                    </div>
                    <ul [class.folder-tree-invisible]="!treeControl.isExpanded(node)">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                </li>
            </mat-nested-tree-node>
        </mat-tree>
    </div>
    <div class="footer">
        <button mat-button (click)="newFolderCreate()" [disabled]="selectedFolder === undefined">
            {{ 'CALENDARS.NEW_LBL' | translate }}
        </button>
        <button mat-button (click)="submit()" [disabled]="selectedFolder === undefined">
            {{ 'COMMON.OK' | translate }}
        </button>
    </div>
</div>