
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { MailUtils } from "../mail/utils/mail-utils";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ElectronService } from "../services/electron.service";
import { MailConstants } from "../common/utils/mail-constants";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { ConfigService } from "../../app/config.service";
import { AuthService } from "../common/providers/auth.service";
import { SharedUtils } from "../mail/utils/shared.utils";
import { MailBroadcaster } from "../common/providers/mail-broadcaster.service";
import { catchError, map, take, tap } from "rxjs/operators";
import { SearchFolder } from "../shared/models/search-folder";
import { SearchRequest } from "../mail/shared/models";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CommonService {

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private configService: ConfigService,
    private mailBroadcaster: MailBroadcaster,
    private electronService: ElectronService,
    private authService: AuthService
  ) { }

  public allFolders: any = [];
  public attachmentInfo: any;
  public alarmEventsActive: any [] = [];
  public isEventReminderActivated: boolean = false;
  public isOpenedAppointment: boolean = false;
  public $isGlobalSearchModalOpen = new Subject<boolean>();
  public isSearchHeaderFocused$ = new Subject<boolean>();
  public selectedMailPath: string = "";
  public $eventData = new Subject();
  isCordovaOrElectron = environment.isCordova || environment.isElectron;
  parseFolders(isSingleNode, res, type = "mail") {
    if (isSingleNode) {
      return SharedUtils.parseSingleFolder(res);
    }
    let resp: any = res;
    if (resp.folder[0].folder && resp.folder[0].link) {
      resp = {
        folder: {
            folder: resp.folder[0].folder,
            link: resp.folder[0].link
        }
      };
    } else if (resp.folder[0].folder) {
      resp = {
        folder: {
            folder: resp.folder[0].folder
        }
      };
    } else if (resp.folder[0].link) {
      resp = {
        folder: {
            link: resp.folder[0].link
        }
      };
    }
    let mailFolderResponse;
    mailFolderResponse = SharedUtils.parseMailFoders(resp, type);

    return mailFolderResponse.filter(value => value);
  }

  getZimbraHeader(): HttpHeaders {
    const headers: HttpHeaders = new HttpHeaders();
    if (this.isCordovaOrElectron) {
      const token = localStorage.getItem(MailConstants.TOKEN);
      return headers.set("Authorization", token).set("Content-Type", "application/json").set("Accept", "application/json");
    }
    return headers.set("Content-Type", "application/json").set("Accept", "application/json");
  }

  modifyAppointment(body): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/modifyAppointment", body,
      { headers: this.getZimbraHeader() }).pipe(
        catchError(this.authService.handleErrorObservable.bind(this)));
  }

  createAppointmentException(body): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/createAppointmentException", body,
      { headers: this.getZimbraHeader() }).pipe(
        catchError(this.authService.handleErrorObservable.bind(this)));
  }

  createNewAppointment(body): Observable<any> {
    console.log("[common.service][createappointment] body: ", body);
    return this.http.post(this.configService.API_URL + "/api/createAppointment", body,
      { headers: this.getZimbraHeader() }).pipe(
        catchError(this.authService.handleErrorObservable.bind(this)));
  }

  calendarSearch(body): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/calendarSearch", body,
      { headers: this.getZimbraHeader() }).pipe(
        catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getMobileAppointmentPrintData(id: string, timezone: string): Observable<any> {
    let url = this.configService.API_URL + "/api/printAppointment?id=" + id + "&tz=" + timezone;
    if (this.isCordovaOrElectron) {
      const token = localStorage.getItem("token");
      url = url + "&token=" + token;
    }
    return this.http.get(url,
      { headers: this.getZimbraHeader(), responseType: "text" }
    ).pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }

  dismissApptRequest(appointmentId: string, dismissTime: number): Observable<any> {
    const request = {
      DismissCalendarItemAlarmRequest: {
        "@": {
          xmlns: "urn:zimbraMail"
        },
        "appt": {
          id: appointmentId,
          dismissedAt: dismissTime
        }
      }
    };
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getBatchAppointmentRequest(ids: any[]): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/getAppointmentBatch", { ids: ids },
    { headers: this.getZimbraHeader() }).pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getMobileBatchAppointmentRequest(ids: any[]): Observable<any> {
    const result = new Subject<any>();

    const body = { ids: ids };

    try {
      cordova.plugin.http.post(this.configService.API_URL + "/api/getAppointmentBatch", body, { Authorization: this.getToken() }, function (response) {
        // cordova.plugin.http.clearCookies();
        console.log("backgroundBatchAppointmentRequest advanced-http-post response: ", response, response.status);
        if (response && !!response.data) {
          let data = {};
          try {
            data = JSON.parse(response.data);
          } catch (e) {
            console.error("backroundBatchAppointmentRequest JSON parse error: ", e, response);
          }
          console.log("backgroundBatchAppointmentRequest advanced-http result: ", data);
          result.next(data);
        } else {
          result.next({});
        }

      }, function (response) {
        console.log("backgroundBatchAppointmentRequest advanced-http response: ", response, response.status);
        console.error("background BatchAppointmentRequest error: ", response.error);
        result.error(response.error);
      });
    } catch (error) {
      result.error(error);
    }
    return result.asObservable().pipe(take(1));
  }


  getAllLocationList(): Observable<any> {
    const body: any = {
      value: "Location"
    };
    return this.http.post(
      this.configService.API_URL + "/api/searchCalendarResourcesRequest",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  snozeApptRequest(appointmentId: string, snoozTime: number): Observable<any> {
    const request = {
      SnoozeCalendarItemAlarmRequest: {
        "@": {
          xmlns: "urn:zimbraMail"
        },
        "appt": {
          id: appointmentId,
          until: snoozTime
        }
      }
    };
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getConfigurations(): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders();
    headers.set("Content-Type", "application/json");
    const api = this.configService.API_URL || "";
    return this.http.get(api + "/api/config", { headers: headers });
  }

  getMsgRequest(body: any): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/getMsgRequest", body , { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][common.service][getMsgRequest] took ${performance} s for API call`, body);
    }));
  }

  getUserCalendarFolders(): Observable<any> {
    const request = {
      view: "appointment"
    };
    console.log("calendarfolders getCalendarFolders");
    return this.http.post(this.configService.API_URL + "/api/getFolderList", request, { headers: this.getZimbraHeader() })
      .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getAppointmentList(request): Observable<any> {
    request.types = "appointment";
    return this.http.post(this.configService.API_URL + "/api/searchRequest", request, { headers: this.getZimbraHeader() })
    .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }

  deleteAppointment(request: any): Observable<any> {
    return this.itemAction(request);
  }

  deleteInstanceAppointment(request: any): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/cancelAppointment", request, { headers: this.getZimbraHeader() })
      .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }

  private returnResponse(res: Response): Response {
    return res;
  }

  itemAction(body: any): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/itemAction", body, { headers: this.getZimbraHeader() })
      .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }

  sendInviteReply(body: any): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/sendInviteReply", body, { headers: this.getZimbraHeader() })
      .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }

  uploadAttachment(file: any): Observable<any> {
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post(
      this.configService.API_URL + "/api/upload",
      formData,
      {
        headers: this.getZimbraHeader().delete("Content-Type").delete("Accept"),
        responseType: "text"
      });
  }

  getSavedSearchFolder(): Observable<SearchFolder[]> {
    const request = {
      GetSearchFolderRequest: {
        "@": {
          xmlns: "urn:zimbraMail"
        }
      }
    };
    return this.http
      .post(this.configService.API_URL + "/api/batchRequest", request, { headers: this.getZimbraHeader() })
      .pipe(
        map(res => {
          const mailFolderResponse = MailUtils.parseSearchFoders(res);
          return mailFolderResponse;
        }),
        catchError(this.authService.handleErrorObservable.bind(this))
      );
  }

  createAppointmentWithRequest(request: any): Observable<any> {
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getFreeBusy(request: any): Observable<any> {
    return this.http.post(
      this.configService.API_URL + "/api/getFreeBusy",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getWorkingHours(request: any): Observable<any> {
    return this.http.post(
      this.configService.API_URL + "/api/getWorkingHours",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  createBatchRequest(request: any): Observable<any> {
    return this.http
      .post(this.configService.API_URL + "/api/batchRequest", request, { headers: this.getZimbraHeader() })
      .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getCalendarFolders(body: any): Observable<any> {
    console.log("[getFolderList getCalendarFolders] body: ", body);
    return this.http.post(
      this.configService.API_URL + "/api/getFolderList",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  createFolder(body): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/createFolder", body, { headers: this.getZimbraHeader() }).
      pipe(map((res: any) => {
        return res.folder[0];
      }), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  folderAction(body): Observable<any> {
    const t1 = new Date().getTime();
    const params = JSON.stringify(body);
    return this.http.post(this.configService.API_URL + "/api/folderAction", body, { headers: this.getZimbraHeader() }).
      pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][folderAction] took ${performance} s for API call`, body);
        this.mailBroadcaster.broadcast(MailConstants.CALL_NO_OP_REQUEST);
      }),
      catchError(this.authService.handleErrorObservable.bind(this)));
  }

  cancelCreateAppointment(ms: any, id: any, name: any, e: any, inst?: any): Observable<any> {
    let cancelText = "Cancelled";
    this.translate.get("CANCELED_LBL").pipe(take(1)).subscribe(v => cancelText = v);
    const request = {
      CancelAppointmentRequest: {
        "@": {
          xmlns: "urn:zimbraMail"
        },
        "ms": ms,
        "rev": ms,
        "id": id,
        "comp": "0",
        "m": {
          "e": e,
          "su": `${cancelText}: ` + name,
          "mp": {
            "mp": [{
              "ct": "text/plain",
              "content": ""
            }],
            "ct": "multipart/alternative"
          }
        }
      }
    };
    if (inst) {
      request.CancelAppointmentRequest["inst"] = inst;
    }
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  cancelScheduledMeeting(jid: string): Observable<any> {
    const request = {
      jid: jid
    };
    return this.http.post(
      this.configService.API_URL + "/api/cancelscheduledmeeting",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }


  forwardAppointment(body: any): Observable<any> {
    return this.http.post(
      this.configService.API_URL + "/api/forwardAppointment",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

   snoozeAllApptRequest(idUntilItem: any): Observable<any> {
    const request = {
      SnoozeCalendarItemAlarmRequest: {
        "@": {
          xmlns: "urn:zimbraMail"
        },
        "appt": idUntilItem
      }
    };
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  dismissAllApptRequest(item: any): Observable<any> {
    const request = {
      DismissCalendarItemAlarmRequest: {
        "@": {
          xmlns: "urn:zimbraMail"
        },
        "appt": item
      }
    };
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  deleteAppointmentAppointment(body: any): Observable<any> {
    return this.http.post(
      this.configService.API_URL + "/api/cancelAppointment",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getContactFolders(body: any): Observable<any> {
    const t1 = new Date().getTime();
    const params = JSON.stringify(body);
    return this.http
      .post(this.configService.API_URL + "/api/getFolderList", body, { headers: this.getZimbraHeader() })
      .pipe(
        tap(res => {
          const t2 = new Date().getTime();
          const performance = (t2 - t1) / 1000;
          console.log(`[API][getContactFolders] took ${performance} s for API call`);
        }),
        map(res => {
          return res;
        }),
        catchError(this.authService.handleErrorObservable.bind(this))
      );
  }

  searchGalRequest(body: any): Observable<any> {
    return this.http.post(
      this.configService.API_URL + "/api/searchGal",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  searchRequest(body: SearchRequest): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/searchRequest", body, { headers: this.getZimbraHeader() });
  }

  createDataSourceRequest(body: any): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/createDataSourceRequest", body, { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][tagAction] took ${performance} s for API call`);
    }));
  }

  zimbraPrefSpellIgnoreWord(word: string): Observable<any> {
    const body = {
      "ModifyPrefsRequest":
      {
        "@": {
          "xmlns": "urn:zimbraAccount",
          "requestId": "0"
        },
        "pref": {
          "@": {
            "name": "+zimbraPrefSpellIgnoreWord"
        },
        "#": word
        }
      }
    };
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  checkSpelling(request: any): Observable<any> {
    return this.http
      .post(this.configService.API_URL + "/api/checkSpelling", request, { headers: this.getZimbraHeader() })
      .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getAllVNCdContacts(updatedAfter?: string, offset = 0, limit = 100): Observable<any> {
    let url = this.configService.API_URL + "/api/contacts.json?all=1";
    if (updatedAfter) {
      url = `${url}&updated_after=${updatedAfter}`;
    }
    if (offset) {
      url = `${url}&offset=${offset}`;
    }
    if (limit) {
      url = `${url}&limit=${limit}`;
    }
    return this.http
      .get(url, { headers: this.getZimbraHeader() })
      .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }
  private getToken() {
    const token = environment.isElectron ? this.electronService.getFromStorage(MailConstants.TOKEN)
      : localStorage.getItem(MailConstants.TOKEN);

    return token;
  }

  public createFakeId(data: string) {
    const current = new Date();
    return `fake#${MailUtils.md5(data)}-${current.getTime()}`;
  }

  public createFakeIdWithoutTime(data: string) {
    return `fake#${MailUtils.md5(data)}`;
  }

  public isFakeId(id: string) {
    return id && id.startsWith("fake#");
  }
}
