
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PreferenceService } from "src/app/preference/shared/services/preference.service";
import { Signature } from "src/app/preference/shared/models";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { isArray } from "util";

@Component({
    selector: "vp-mobile-signature-dialog",
    templateUrl: "./mobile-signature-dialog.component.html"
})
export class MobileSignatureDialogComponent implements OnInit, OnDestroy {
    signatures: Signature[] = [];
    constructor(
        private dialogRef: MatDialogRef<MobileSignatureDialogComponent>,
        private preferenceService: PreferenceService,
        private hotKeyService: NgxHotkeysService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.hotKeyService.pause(this.hotKeyService.hotkeys);
        if (!!data && !!data.signatures && isArray(data.signatures)) {
            this.signatures = data.signatures;
        }
    }

    close(signature?: any) {
        this.dialogRef.close({ signature: signature });
    }

    ngOnInit() { }

    ngOnDestroy() {
        this.hotKeyService.unpause(this.hotKeyService.hotkeys);
    }

    selectSignature(signature: any): void {
        this.close(signature);
    }

}
