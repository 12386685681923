
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { Utils } from "src/app/common";
import { ToastService } from "src/app/common/providers/toast.service";
import { CreateCalendarFolderComponent } from "src/app/calendar/calendar-create-folder/calendar-create-folder.component";
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { CommonService } from "src/app/services/ common.service.";

@Component({
    selector: "vp-external-calendar-dialog",
    templateUrl: "./add-external-calendar.component.html"
})
export class AddExternalCalendarDialogComponent implements OnInit, OnDestroy {

    externalType: string = "caldev";
    externalEmail: string = "";
    externalPassword: string = "";
    externalServer: string = "";
    externalIcalUrl: string = "";
    private isAlive$ = new Subject<boolean>();

    constructor(
        private dialogRef: MatDialogRef<AddExternalCalendarDialogComponent>,
        private toastService: ToastService,
        private matDialog: MatDialog,
        private broadCaster: MailBroadcaster,
        private commonService: CommonService
    ) {
        this.broadCaster.on<any>("EXTERNAL_CALENDAR_FOLDER_FOLDER").pipe(takeUntil(this.isAlive$)).subscribe(ev => {
            if (!!ev && ev.folder) {
                this.createFolderAfterClose(ev.folder);
            }
        });
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
        this.isAlive$.unsubscribe();
    }

    close(): void {
        this.dialogRef.close();
    }

    nextPage(): void {
        if (this.externalType === "caldev") {
            if (!Utils.validateEmail(this.externalEmail)) {
                this.toastService.show("CALENDARS.INVALID_EMAIL_FORMAT_FOUND");
                return;
            } else if (this.externalPassword === "") {
                this.toastService.show("CALENDARS.PASSEWORD_IS_REQUIRED_FIELD");
                return;
            }
            this.openCreateFolderDialogCalDevExternal();
        } else if (this.externalType === "ical") {
            const expression = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
            const isValidURL = this.externalIcalUrl.match(expression);
            if (isValidURL === null) {
                this.toastService.show("CALENDARS.VALID_URL_WARNING_MSG");
                return;
            }

            this.openCreateFolderDialogIcalExternal();
        }

    }

    openCreateFolderDialogCalDevExternal(): void {
        this.matDialog.open(CreateCalendarFolderComponent, {
            width: "480px",
            height: "200px",
            autoFocus: true,
            panelClass: "mail_folder-create_dialog",
            data: { targetFolder: null }
        });
    }

    openCreateFolderDialogIcalExternal(): void {
        const dlg = this.matDialog.open(CreateCalendarFolderComponent, {
            width: "480px",
            height: "200px",
            autoFocus: true,
            panelClass: "mail_folder-create_dialog",
            data: { targetFolder: null, externalURL: this.externalIcalUrl.trim() }
        });
        dlg.afterClosed().pipe(take(1)).subscribe(res => {
            this.close();
        });
    }

    createFolderAfterClose(folder: any): void {
        const body = {
            username: this.externalEmail,
            password: this.externalPassword,
            serverURL: this.externalServer.replace("http://", "").replace("https://", "").replace("HTTP://", "").replace("HTTPS://", ""),
            folderName: folder.name,
            folderId: folder.id
        };
        this.commonService.createDataSourceRequest(body).pipe(take(1)).subscribe(res => {
            if (!!res && res.caldav) {
                this.toastService.show("CALENDARS.CALENDAR_CREATED_SYNC");
            }
            this.close();
        }, error => {
            if (error.error && error.error.msg) {
                const msg = error.error.msg;
                this.toastService.showPlainMessage(msg);
            }
            this.close();
        });
    }

}
