
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="header">
    <div class="mobile-back">
        <mat-icon (click)="close()">chevron_left</mat-icon>
    </div>
    <div class="text">{{ 'APPEARANCE' | translate }}</div>
    <div class="icon">
        <mat-icon (click)="close()">close</mat-icon>
    </div>
</div>
<div class="content">
    <div class="theme-lbl">{{ 'THEME_LBL' | translate }}</div>
    <div class="themes">
        <div *ngFor="let theme of themes" class="theme-item" (click)="themeChange(theme)">
            <div class="border {{theme.title}}" [class.selected-theme]="theme.selected">
                <div class="mail">
                    <mat-icon>email</mat-icon> {{ 'MAIL' | translate  }}
                </div>
                <div class="inbox-draft">
                    <mat-list>
                        <mat-list-item class="mat-list-item {{theme.title}}">
                            <span class="count-inbox">1</span>
                            <mat-icon>inbox</mat-icon> {{ 'INBOX' | translate }}
                        </mat-list-item>
                        <mat-list-item>
                            <mat-icon>drafts</mat-icon>{{ 'DRAFTS' | translate }}
                        </mat-list-item>
                    </mat-list>
                </div>
            </div>
            <div class="theme-name {{theme.title}}" [class.selected-theme]="theme.selected"> {{ theme.name}}</div>
        </div>
    </div>
</div>
<div class="footer" *ngIf="isThemeChange">
    <span>{{ 'UPDATE_APPEARANCE' | translate }}</span>
    <button mat-button (click)="updateTheme()">
        <mat-icon>check</mat-icon>
    </button>
</div>