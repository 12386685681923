
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, Inject, HostListener, EventEmitter } from "@angular/core";
import { trigger, state, style, transition, animate, AnimationEvent, group, query } from "@angular/animations";
import { FilePreviewOverlayRef } from "./file-preview-overlay-ref";
import { FILE_PREVIEW_DIALOG_DATA } from "./file-preview-overlay.tokens";

const ESCAPE = 27;
const ANIMATION_TIMINGS = "400ms cubic-bezier(0.25, 0.8, 0.25, 1)";

@Component({
  selector: "vp-file-preview-overlay",
  template: `
    <vp-file-preview-overlay-toolbar>
      <mat-icon>description</mat-icon>
      {{ image.name }}
    </vp-file-preview-overlay-toolbar>

    <div class="overlay-content"
      [@slideContent]="animationState"
      (@slideContent.start)="onAnimationStart($event)"
      (@slideContent.done)="onAnimationDone($event)">
      <div class="spinner-wrapper" *ngIf="loading">
        <vp-custom-spinner></vp-custom-spinner>
      </div>

      <img [@fade]="loading ? 'fadeOut' : 'fadeIn'" (load)="onLoad($event)" [src]="image.url">
    </div>
  `,
  animations: [
    trigger("fade", [
      state("fadeOut", style({ opacity: 0 })),
      state("fadeIn", style({ opacity: 1 })),
      transition("* => fadeIn", animate(ANIMATION_TIMINGS))
    ]),
    trigger("slideContent", [
      state("void", style({ transform: "translate3d(0, 25%, 0) scale(0.9)", opacity: 0 })),
      state("enter", style({ transform: "none", opacity: 1 })),
      state("leave", style({ transform: "translate3d(0, 25%, 0)", opacity: 0 })),
      transition("* => *", animate(ANIMATION_TIMINGS)),
    ])
  ]
})
export class FilePreviewOverlayComponent {

  loading = true;
  animationState: "void" | "enter" | "leave" = "enter";
  animationStateChanged = new EventEmitter<AnimationEvent>();

  @HostListener("document:keydown", ["$event"]) private handleKeydown(event: KeyboardEvent) {
    if (event.keyCode === ESCAPE) {
      this.dialogRef.close();
    }
  }

  constructor(
    public dialogRef: FilePreviewOverlayRef,
    @Inject(FILE_PREVIEW_DIALOG_DATA) public image: any) { }

  onLoad(event: Event) {
    this.loading = false;
  }

  onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  startExitAnimation() {
    this.animationState = "leave";
  }
}
