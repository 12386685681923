
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  NgZone
} from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { UserProfile } from "../../models";
import { MailRootState } from "src/app/mail/store";
import { Store, select } from "@ngrx/store";
import { getUserProfile, getOnlineStatus } from "../../../reducers";
import { filter, takeUntil, take, distinctUntilChanged } from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";
import { MailUtils } from "../../../mail/utils/mail-utils";
import { AvatarCropperDialogComponent } from "../avatar-cropper-dialog/avatar-cropper-dialog.component";
import { MailBroadcaster } from "../../../common/providers/mail-broadcaster.service";
import { MailService } from "../../../mail/shared/services/mail-service";
import { ToastService } from "../../../common/providers/toast.service";
import { MailConstants } from "../../../common/utils/mail-constants";
import { AppState } from "../../../reducers/app";
import { StartProcessing, StopProcessing, SetLastPhotoUpdate } from "../../../actions/app";
import { BroadcastKeys } from "../../../common/enums/broadcast.enum";
import { ElectronService } from "src/app/services/electron.service";
import { DesktopChangePasswordComponent } from "../../../mail/shared/components/desktop-change-password/desktop-change-password.component";
import { BreakpointObserver } from "@angular/cdk/layout";
import { MobileChangePasswordComponent } from "../../../mail/shared/components/mobile-change-password/mobile-change-password.component";
import { ConfigService } from "src/app/config.service";
import { environment } from "src/environments/environment";
import { CommonRepository } from "src/app/mail/repositories/common-repository";

@Component({
  selector: "vp-avatar-dialog",
  templateUrl: "./avatar-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarDialogComponent implements OnDestroy {
  currentUser: UserProfile;
  private isAlive$ = new Subject<boolean>();
  isMobileScreen: boolean = false;
  canUpdateAvatar: any;
  zimbraFeatureChangePasswordEnabled: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<AvatarDialogComponent>,
    private store: Store<MailRootState>,
    private matDialog: MatDialog,
    private mailBroadcaster: MailBroadcaster,
    private mailService: MailService,
    private toastService: ToastService,
    private appStore: Store<AppState>,
    private ngZone: NgZone,
    private changeDetectionRef: ChangeDetectorRef,
    private electronService: ElectronService,
    private configService: ConfigService,
    private commonRepository: CommonRepository,
    private breakpointObserver: BreakpointObserver
  ) {
    this.canUpdateAvatar = this.configService.get("canUpdateAvatar");
    const passwordEnabled = this.configService.get("zimbraFeatureChangePasswordEnabled");
    if (passwordEnabled === "TRUE") {
      this.zimbraFeatureChangePasswordEnabled = true;
    } else {
      this.zimbraFeatureChangePasswordEnabled = false;
    }

    this.store
      .select(getUserProfile)
      .pipe(
        filter(v => !!v),
        takeUntil(this.isAlive$)
      )
      .subscribe(res => {
        if (!!res) {
          this.currentUser = res;
          this.changeDetectionRef.markForCheck();
        } else {
          const contactUser = localStorage.getItem("profileUser");
          if (
            contactUser &&
            contactUser !== undefined &&
            contactUser !== "undefined"
          ) {
            this.currentUser = MailUtils.parseUserProfile(contactUser);
          }
        }
      });

    this.mailBroadcaster
      .on<any>("uploadAvatar")
      .pipe(takeUntil(this.isAlive$))
      .subscribe(data => {
        if (data) {
          if (!MailUtils.isIE()) {
            this.avatarUpload(this.blobToFile(data.operation, "avatar.png"));
          } else {
            this.avatarUpload(data.operation);
          }
        }
      });

    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_PROFILE_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
  }

  public blobToFile = (blob: Blob, fileName: string): File => {
    const date = new Date().getTime();
    let file = new File([blob], fileName, {lastModified: date});
    if (environment.isCordova) {
      file = new FileObject([blob], fileName, {lastModified: date});
    }
    return file;
  }

  avatarUpload(file) {
    console.log("avatarUpload", file);
    const formData = new FormData();
    formData.append("file", file);
    console.log("avatarUpload", formData);
    this.appStore.dispatch(new StartProcessing());
    this.mailService
      .uploadAvatar(formData)
      .pipe(take(1))
      .subscribe(res => {
        this.appStore.dispatch(new StopProcessing());
        const avatarURL = this.configService.get("avatarURL");
        const currentTime = new Date().getTime();
        this.store.dispatch(new SetLastPhotoUpdate({email: this.currentUser.email, timestamp: currentTime}));
        const id = res.replace(/"/g, "");
        this.currentUser.imageData = `${avatarURL}/${id}.jpg?v=${currentTime}`;
        this.changeDetectionRef.markForCheck();
      }, err => {
        this.toastService.show(MailConstants.FAIL_AVTAR_UPLOADED_MSG);
        this.appStore.dispatch(new StopProcessing());
      });
  }

  close(): void {
    this.dialogRef.close();
  }

  openCropperDialog() {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
      return;
    }

    const dialogArgs = {
      autoFocus: false,
      maxWidth: "100%",
      data: { userProfile: this.currentUser },
      panelClass: "mail__dialog"
    };
    this.matDialog.open(AvatarCropperDialogComponent, dialogArgs);
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  changePassword(): void {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
      return;
    }

    this.mailService.getChangePasswordData().then( res => {
      const changePasswordType =  res.changePasswordType;
      if (changePasswordType === MailConstants.ZIMBRA_TYPE) {
        const sectionAttributes = JSON.parse(res.sectionAttributes);
        if (sectionAttributes.attrs._attrs.zimbraFeatureChangePasswordEnabled === "TRUE") {
          const changePasswordURL = sectionAttributes.changePasswordURL !== undefined ? sectionAttributes.changePasswordURL : "";
          if (changePasswordURL === "") {
            this.openChangePasswordDialog(changePasswordType , changePasswordURL );
          } else {
            window.open(changePasswordURL, "_system");
          }
        } else {
          this.toastService.show(MailConstants.PASSWORD_CHANGE_FEATURE_NOT_ENABLE);
        }
      }
    });
  }

  openChangePasswordDialog(type: string , changePasswordUrl: string ): void {
    if (this.isMobileScreen) {
      this.openMobileChangePasswordDialog(type, changePasswordUrl);
    } else {
      const dialogArgs = {
        width: "325px",
        autoFocus: false,
        data: { type: type, changePasswordUrl: changePasswordUrl },
        panelClass: "desktop_change_password"
      };
      this.matDialog.open(DesktopChangePasswordComponent, dialogArgs);
    }
  }

  openMobileChangePasswordDialog(type: string , changePasswordUrl: string): void {
    const dialogArgs = {
      maxWidth: "100vw",
      autoFocus: false,
      data: { type: type, changePasswordUrl: changePasswordUrl },
      panelClass: "mobile_change_password"
    };
    this.matDialog.open(MobileChangePasswordComponent, dialogArgs);
  }

}
