
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "src/app/actions";
import { BriefcaseFolder } from "../../shared/models/briefcase-folder";
import { Update } from "@ngrx/entity/src/models";

export enum BriefcaseFolderActionTypes {
  SELECT_BRIEFCASE_FOLDER = "[Briefcase Folder] Select Briefcase Folder",

  LOAD_BRIEFCASE_FOLDERS = "[Briefcase Folder] Load Briefcase Folders",
  LOAD_BRIEFCASE_FOLDERS_SUCCESS = "[Briefcase Folder] Load Briefcase Folders Success",
  LOAD_BRIEFCASE_FOLDERS_FAIL = "[Briefcase Folder] Load Briefcase Folders Fail",

  CREATE_BRIEFCASE_FOLDER = "[FOLDERS] Create Briefcase Folder",
  CREATE_BRIEFCASE_FOLDER_SUCCESS = "[FOLDERS] Create Briefcase Folder Success",
  CREATE_BRIEFCASE_FOLDER_FAIL = "[FOLDERS] Create Briefcase Folder Fail",

  UPDATE_BRIEFCASE_FOLDER = "[Briefcase Folder] Update Briefcase Folder",
  UPDATE_BRIEFCASE_FOLDER_SUCCESS = "[FOLDERS] Update Briefcase Folder Success",
  UPDATE_BRIEFCASE_FOLDER_FAIL = "[FOLDERS] Update Briefcase Folder Fail",
  RESET_BRIEFCASE_FOLDER_STATE = "[Briefcase Folder] Reset Briefcase Folder State",

  DELETE_BRIEFCASE_FOLDER = "[Briefcase Folder] Delete Briefcase Folder",
  DELETE_BRIEFCASE_FOLDER_FAIL = "[Briefcase Folder] Delete briefcase folder fail",
  DELETE_BRIEFCASE_FOLDER_SUCCESS = "[Briefcase Folder] Delete briefcase folder success",

}

export class LoadBriefcaseFolders implements Action {
  readonly type = BriefcaseFolderActionTypes.LOAD_BRIEFCASE_FOLDERS;
}

export class LoadBriefcaseFoldersSuccess implements Action {
  readonly type = BriefcaseFolderActionTypes.LOAD_BRIEFCASE_FOLDERS_SUCCESS;
  constructor(public payload: { folders: BriefcaseFolder[] }) {}
}

export class LoadBriefcaseFoldersFail implements Action {
  readonly type = BriefcaseFolderActionTypes.LOAD_BRIEFCASE_FOLDERS_FAIL;
}

export class SelectBriefcaseFolder implements Action {
  readonly type = BriefcaseFolderActionTypes.SELECT_BRIEFCASE_FOLDER;
  constructor(public payload: { folder: BriefcaseFolder }) { }
}

export class CreateBriefcaseFolder implements Action {
  readonly type = BriefcaseFolderActionTypes.CREATE_BRIEFCASE_FOLDER;
}

export class UpdateBriefcaseFolder implements Action {
  readonly type = BriefcaseFolderActionTypes.UPDATE_BRIEFCASE_FOLDER;
  constructor() { }
}

export class CreateBriefcaseFolderSuccess implements Action {
  readonly type = BriefcaseFolderActionTypes.CREATE_BRIEFCASE_FOLDER_SUCCESS;
  constructor(public payload: { folder: BriefcaseFolder }) {}
}

export class CreateMailFolderFail implements Action {
  readonly type = BriefcaseFolderActionTypes.CREATE_BRIEFCASE_FOLDER_FAIL;
}

export class UpdateBriefcaseFolderFail implements Action {
  readonly type = BriefcaseFolderActionTypes.UPDATE_BRIEFCASE_FOLDER_FAIL;
}

export class UpdateBriefcaseFolderSuccess implements Action {
  readonly type = BriefcaseFolderActionTypes.UPDATE_BRIEFCASE_FOLDER_SUCCESS;
  constructor(public payload: Update<BriefcaseFolder>) {}
}

export class DeleteBriefcaseFolder implements Action {
  readonly type = BriefcaseFolderActionTypes.DELETE_BRIEFCASE_FOLDER;
}

export class DeleteBriefcaseFolderFail implements Action {
  readonly type = BriefcaseFolderActionTypes.DELETE_BRIEFCASE_FOLDER_FAIL;
}

export class DeleteBriefcaseFolderSuccess implements Action {
  readonly type = BriefcaseFolderActionTypes.DELETE_BRIEFCASE_FOLDER_SUCCESS;
  constructor(public payload: { folder: BriefcaseFolder }) {}
}


export type BriefcaseFolderActions = LoadBriefcaseFolders | UpdateBriefcaseFolder;
