import { openDB, deleteDB } from "idb/with-async-ittr.js";
import { take } from "rxjs/operators";
import { Subject, Observable, forkJoin } from "rxjs";
import { DatabaseInterface } from "./database.interface";
import { Conversation } from "../../mail/shared/models/conversation.model";
import { Message } from "../../mail/shared/models/message.model";
import { Signature } from "../../preference/shared/models/signature.model";
import { MailFolder } from "src/app/mail/models/mail-folder.model";
// import { CalendarFolder } from "src/app/common/models/calendar.model";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { environment } from "src/environments/environment";
import * as _ from "lodash";

const MESSAGES_DB_STORE = "MessagesStore";
const CONVERSATIONS_DB_STORE = "ConversationsStore";
const PENDING_OPERATIONS_DB_STORE = "PendingOperationsStore";
const FOLDERS_DB_STORE = "FolderStore";
const OLD_FOLDERS_DB_STORE = "FoldersStore";
const CALENDAR_FOLDERS_DB_STORE = "CalendarFoldersStore";
const TAGS_DB_STORE = "TagsStore";
const SIGNATURES_DB_STORE = "SignaturesStore";
const USERS_DB_STORE = "UsersStore";
const CURRENT_USER_DB_STORE = "CurrentUserStore";
const AVATAR_DB_STORE = "AvatarsStore";
const OLD_AVATAR_DB_STORE = "AvatarStore";
const CONTACT_DB_STORE = "ContactStore";
const APPOINTMENT_DB_STORE = "AppointmentItemStor";
const FAIL_APPOINTMENT_DB_STORE2 = "AppointmentsStor";
const FAIL_APPOINTMENT_DB_STORE = "AppointmentsStore";
const OLD_APPOINTMENT_DB_STORE = "AppointmentStore";
const ATTACHMENTS_DB_STORE = "AttachmentsStore";

const INDEX_BY_CONVERSATION_ID_INDEX = "by-conversation-id";
const INDEX_BY_DATE_ID_INDEX = "by-date";
const INDEX_BY_FOLDER = "by-folder";
const INDEX_BY_FOLDER_DATE = "by-folder-date";
const INDEX_BY_MID = "by-mid";
const INDEX_BY_TAG = "by-tag";
const INDEX_BY_ORIGINAL_ID = "by-appointment-id";
const INDEX_BY_TIMESTAMP = "by-timestamp";
const INDEX_BY_EMAIL = "by-email";

export class IndexedDBService implements DatabaseInterface {
  dbName = "OfflineDatabase3";

  dbVersion = 22;
  // A list of all versions:
  // 6 - initial
  // 7 - add  CURRENT_USER_DB_STORE
  // 8 - add  INDEX_BY_DATE_ID_INDEX
  // 9 - add  AVATAR_DB_STORE
  // 11 - add INDEX_BY_MID

  flatFolders: MailFolder[] = [];
  flatCalFolders: any[] = [];
  invalidMsgIds: any[] = [];

  constructor() {
    console.log("[IndexedDBService][constructor]");
  }
  dataBaseReadyCallback?: any;

  private idbContext() {
    return openDB(this.dbName, this.dbVersion, {
      upgrade(db, oldVersion, newVersion, transaction) {
        console.log("[IndexedDBService][idbContext][upgrade]", oldVersion, newVersion);

        if (oldVersion === 0) {
          console.log("[IndexedDBService][idbContext][upgrade] default");

          // Messages store
          const messagesStore = db.createObjectStore(MESSAGES_DB_STORE, {
            // The 'id' property of the object will be the key.
            keyPath: "id",
            autoIncrement: false,
          });
          messagesStore.createIndex(INDEX_BY_CONVERSATION_ID_INDEX, "cid");
          messagesStore.createIndex(INDEX_BY_FOLDER, "folders", { multiEntry: true });
          messagesStore.createIndex(INDEX_BY_TAG, "tags", { multiEntry: true });
          messagesStore.createIndex(INDEX_BY_DATE_ID_INDEX, "d");

          // Conversations store
          const conversationsStore = db.createObjectStore(CONVERSATIONS_DB_STORE, {
            // The 'id' property of the object will be the key.
            keyPath: "id",
            autoIncrement: false,
          });
          conversationsStore.createIndex(INDEX_BY_FOLDER, "folders", { multiEntry: true });
          conversationsStore.createIndex(INDEX_BY_MID, "mids", { multiEntry: true });
          conversationsStore.createIndex(INDEX_BY_TAG, "tags", { multiEntry: true });

          // Pendings store
          db.createObjectStore(PENDING_OPERATIONS_DB_STORE, {
            // The 'id' property of the object will be the key.
            keyPath: "id",
            autoIncrement: false
          });

          // Folders store
          db.createObjectStore(FOLDERS_DB_STORE, {
            keyPath: "id",
            autoIncrement: false
          });

          // Tags store
          db.createObjectStore(TAGS_DB_STORE, {
            // The 'id' property of the object will be the key.
            keyPath: "id",
            autoIncrement: false
          });

          // Signatures store
          db.createObjectStore(SIGNATURES_DB_STORE, {
            // The 'id' property of the object will be the key.
            keyPath: "id",
            autoIncrement: false
          });

          // Users store
          db.createObjectStore(USERS_DB_STORE, {
            // The 'id' property of the object will be the key.
            keyPath: "id",
            autoIncrement: false
          });

          db.createObjectStore(AVATAR_DB_STORE, {
            keyPath: "id",
            autoIncrement: false
          });
          db.createObjectStore(APPOINTMENT_DB_STORE, {
            // The 'id' property of the object will be the key.
            keyPath: "id",
            autoIncrement: false,
          });
        }

        if ((newVersion >= 8) && (oldVersion === 7)) {
          if (!db.objectStoreNames.contains(MESSAGES_DB_STORE)) {
            const messagesStore = db.createObjectStore(MESSAGES_DB_STORE, {
              // The 'id' property of the object will be the key.
              keyPath: "id",
              autoIncrement: false,
            });
            messagesStore.createIndex(INDEX_BY_CONVERSATION_ID_INDEX, "cid");
            messagesStore.createIndex(INDEX_BY_DATE_ID_INDEX, "d");
            messagesStore.createIndex(INDEX_BY_FOLDER, "folders", { multiEntry: true });
            messagesStore.createIndex(INDEX_BY_TAG, "tags", { multiEntry: true });

          } else {
            const txn = transaction;
            const store = txn.objectStore(MESSAGES_DB_STORE);
            store.createIndex(INDEX_BY_DATE_ID_INDEX, "d");
          }
        }

        if (newVersion >= 7) {
          if (!db.objectStoreNames.contains(CURRENT_USER_DB_STORE)) {
            // Current users store
            db.createObjectStore(CURRENT_USER_DB_STORE, {
              // The 'jid' property of the object will be the key.
              keyPath: "email",
              autoIncrement: false
            });
          }
        }
        if (newVersion >= 9) {
          console.log("create AVATAR_DB_STORE");
          if (!db.objectStoreNames.contains(AVATAR_DB_STORE)) {
            db.createObjectStore(AVATAR_DB_STORE, {
              keyPath: "id",
              autoIncrement: false
            });
          }
          if (db.objectStoreNames.contains(OLD_AVATAR_DB_STORE)) {
            db.deleteObjectStore(OLD_AVATAR_DB_STORE);
          }
        }


        if ((newVersion >= 11) && (oldVersion === 10)) {
          if (!db.objectStoreNames.contains(CONVERSATIONS_DB_STORE)) {
            const conversationsStore = db.createObjectStore(CONVERSATIONS_DB_STORE, {
              // The 'id' property of the object will be the key.
              keyPath: "id",
              autoIncrement: false,
            });
            conversationsStore.createIndex(INDEX_BY_FOLDER, "folders", { multiEntry: true });
            conversationsStore.createIndex(INDEX_BY_MID, "mids", { multiEntry: true });
            conversationsStore.createIndex(INDEX_BY_TAG, "tags", { multiEntry: true });
          } else {
            const ctxn = transaction;
            const cstore = ctxn.objectStore(CONVERSATIONS_DB_STORE);
            cstore.createIndex(INDEX_BY_MID, "mids", { multiEntry: true });
          }
        }

        if ((newVersion >= 15)) {
          if (db.objectStoreNames.contains(OLD_APPOINTMENT_DB_STORE)) {
            db.deleteObjectStore(OLD_APPOINTMENT_DB_STORE);
          }
          if (db.objectStoreNames.contains(FAIL_APPOINTMENT_DB_STORE)) {
            db.deleteObjectStore(FAIL_APPOINTMENT_DB_STORE);
          }
          if (db.objectStoreNames.contains(FAIL_APPOINTMENT_DB_STORE2)) {
            db.deleteObjectStore(FAIL_APPOINTMENT_DB_STORE2);
          }
          if (!db.objectStoreNames.contains(APPOINTMENT_DB_STORE)) {
            const appointmentStore = db.createObjectStore(APPOINTMENT_DB_STORE, {
              // The 'id' property of the object will be the key.
              keyPath: "id",
              autoIncrement: false,
            });
            appointmentStore.createIndex(INDEX_BY_ORIGINAL_ID, "apptId", { unique: false });
          } else {
            const txn = transaction;
            const store = txn.objectStore(APPOINTMENT_DB_STORE);
            if (!store.indexNames.contains(INDEX_BY_ORIGINAL_ID)) {
              store.createIndex(INDEX_BY_ORIGINAL_ID, "apptId", { unique: false });
            }
          }
        }

        if ((newVersion >= 16)) {
          if (!db.objectStoreNames.contains(ATTACHMENTS_DB_STORE)) {
            const attachmentsStore = db.createObjectStore(ATTACHMENTS_DB_STORE, {
              // The 'id' property of the object will be the key.
              keyPath: "id",
              autoIncrement: false,
            });
            attachmentsStore.createIndex(INDEX_BY_TIMESTAMP, "timestamp", { unique: false});
          } else {
            const txa = transaction;
            const stora = txa.objectStore(ATTACHMENTS_DB_STORE);
            if (!stora.indexNames.contains(INDEX_BY_TIMESTAMP)) {
              stora.createIndex(INDEX_BY_TIMESTAMP, "timestamp", { unique: false });
            }
          }
        }

        if (db.objectStoreNames.contains(MESSAGES_DB_STORE)) {
          const txn = transaction;
          const store = txn.objectStore(MESSAGES_DB_STORE);
          if (!store.indexNames.contains(INDEX_BY_DATE_ID_INDEX)) {
            store.createIndex(INDEX_BY_DATE_ID_INDEX, "d");
          }
          if (!store.indexNames.contains(INDEX_BY_FOLDER_DATE)) {
            store.createIndex(INDEX_BY_FOLDER_DATE, ["folders", "d"], { unique: false });
          }
        }

        if (!db.objectStoreNames.contains(CALENDAR_FOLDERS_DB_STORE)) {
          db.createObjectStore(CALENDAR_FOLDERS_DB_STORE, {
            keyPath: "id",
            autoIncrement: false
          });
        }


        if (db.objectStoreNames.contains(OLD_FOLDERS_DB_STORE)) {
          db.deleteObjectStore(OLD_FOLDERS_DB_STORE);
        }

        if (!db.objectStoreNames.contains(FOLDERS_DB_STORE)) {
          db.createObjectStore(FOLDERS_DB_STORE, {
            keyPath: "id",
            autoIncrement: false
          });
        }

        if (!db.objectStoreNames.contains(CONTACT_DB_STORE)) {
          const contactStore = db.createObjectStore(CONTACT_DB_STORE, {
            keyPath: "id",
            autoIncrement: false
          });
          contactStore.createIndex(INDEX_BY_FOLDER, "l", { multiEntry: false });
          contactStore.createIndex(INDEX_BY_EMAIL, "email", { multiEntry: true });
        } else {
          const txn = transaction;
          const store = txn.objectStore(CONTACT_DB_STORE);
          if (!store.indexNames.contains(INDEX_BY_EMAIL)) {
            store.createIndex(INDEX_BY_EMAIL, "email", { multiEntry: true });
          }

        }

        console.log("[IndexedDBService][idbContext][upgrade] done");
      },
      blocked() {
        console.log("[IndexedDBService][idbContext][blocked]");
      },
      blocking() {
        console.log("[IndexedDBService][idbContext][blocking]");
      }
    });
  }

  deleteDB(): Observable<any> {
    const response = new Subject<any>();

    deleteDB(this.dbName).then(res => {
      response.next(res);
    });

    return response.asObservable().pipe(take(1));
  }

  clearDB(): Observable<any> {
    console.info("[IndexedDBService][clearDB]");

    const response = new Subject<any>();

    this.idbContext().then(db => {
      Promise.all([
        db.clear(MESSAGES_DB_STORE),
        db.clear(CONVERSATIONS_DB_STORE),
        db.clear(PENDING_OPERATIONS_DB_STORE),
        db.clear(FOLDERS_DB_STORE),
        db.clear(TAGS_DB_STORE),
        db.clear(SIGNATURES_DB_STORE),
        db.clear(USERS_DB_STORE),
        db.clear(CURRENT_USER_DB_STORE),
        db.clear(AVATAR_DB_STORE),
        db.clear(CONTACT_DB_STORE),
        db.clear(APPOINTMENT_DB_STORE),
        db.clear(CALENDAR_FOLDERS_DB_STORE),
        db.clear(ATTACHMENTS_DB_STORE)
      ]).then(result => {
        console.info("[IndexedDBService][clearDB] result = ", result);
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][clearDB] error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getQueryByFolderId(folderId: number): string {
    let query = "";
    folderId = +folderId;
    switch (folderId) {
        case MailConstants.FOLDER_ID.INBOX: query = MailConstants.SEARCH_CRITERIA.IN_INBOX; break;
        case MailConstants.FOLDER_ID.DRAFTS: query = MailConstants.SEARCH_CRITERIA.IN_DRAFTS; break;
        case MailConstants.FOLDER_ID.SENT: query = MailConstants.SEARCH_CRITERIA.IN_SENT; break;
        case MailConstants.FOLDER_ID.JUNK: query = MailConstants.SEARCH_CRITERIA.IN_JUNK; break;
        case MailConstants.FOLDER_ID.TRASH: query = MailConstants.SEARCH_CRITERIA.IN_TRASH; break;
        case MailConstants.FOLDER_ID.STARRED: query = "is:flagged or ( in:trash and is:flagged )"; break;
        default: query = `(inid:"${folderId}")`;
    }
    return query;
  }

  // offline data

  addAppointments(appointments: any[]): Observable<any> {
    return this.addAppointmentsWithoutQuery(appointments);
  }

  addAppointmentsWithoutQuery(appointments: any[]): Observable<any> {
    const response = new Subject<any>();

    this.getAppointmentsByIdsForkJoin(appointments.filter(v => !!v).map(m => m.id), true).subscribe(existingAppointmentsMap => {
      // console.log("existingAppointmentsMap", existingAppointmentsMap);
      this.idbContext().then(db => {
        const tx = db.transaction(APPOINTMENT_DB_STORE, "readwrite");
        appointments.filter(v => !!v).forEach(appt => {
          console.log("appt", appt);
          // console.log("existingAppointmentsMap[appt.id]", existingAppointmentsMap[appt.id]);
          tx.store.put(appt);
        });

        tx.done.then(() => {
          response.next(true);
        }).catch(error => {
          console.error("[IndexedDBService][addAppointments] onerror", error);
          response.error(error);
        });
      });
    });

    return response.asObservable().pipe(take(1));
  }

  addMessages(messages: Message[], query?: any): Observable<any> {
    if (!!query && !!query.originalQuery && !!query.sortBy && !!query.limit && (query?.offset === 0)) {
      return this.addMessagesWithQuery(messages, query);
    } else {
      return this.addMessagesWithoutQuery(messages);
    }
  }

  addMessagesWithQuery(messages: Message[], query?: any): Observable<any> {
    const t1 = performance.now();

    const response = new Subject<any>();

    const addMessageIds = messages.filter(v => !!v).map(m => m.id);
    const _sortedMessageIds = addMessageIds.sort();
    console.log("[IndexedDBService][addMessagesWithQuery]", messages, query, _sortedMessageIds);
    const minId = _sortedMessageIds[0];
    const maxId = _sortedMessageIds[_sortedMessageIds.length - 1];
    let keyRangeValue;
    let isKeyRangeSet = false;
    try {
      keyRangeValue = IDBKeyRange.bound(minId, maxId);
      isKeyRangeSet = true;
    } catch (error) {

    }

    if (!isKeyRangeSet) {
      try {
        keyRangeValue = IDBKeyRange.lowerBound(minId);
        isKeyRangeSet = true;
      } catch (error) {

      }

    }


    this.idbContext().then(async db => {
      const tx = db.transaction(MESSAGES_DB_STORE, "readwrite");
      const store = tx.objectStore(MESSAGES_DB_STORE);
      const index = store.index(INDEX_BY_FOLDER);
      console.log("[IndexedDB][addMessages] getting all IDS...");
      const t3 = performance.now();
      let dbMessages = [];
      if (!!query && !!query.originalQuery && !!query.sortBy && !!query.limit && (query?.offset === 0)) {
        console.log("[IndexedDB][addMessages] getting all IDS...  query.limit: ", query.limit, !!query.offset, query.offset);
        const direction = (query.sortBy === "dateAsc") ? "next" : "prev";
        const allIndexKeys = await index.getAllKeys(query.originalQuery);
        console.log("[IndexedDB][addMessagesQuery] getting all IDS...  allIndexKeys: ", allIndexKeys);
        let cursor = await index.openCursor(query.originalQuery, "prev");

          if (!!cursor) {
            console.log("[IndexedDB][addMessagesQueryCursor1]", cursor.value?.id, allIndexKeys.indexOf(cursor.value?.id), cursor.value);
            dbMessages.push(cursor.value);
            let canAdvance = true;
            let i = 1;
            while ((i <= query.limit + 1 ) && canAdvance && !!cursor && !!cursor.value && (allIndexKeys.indexOf(cursor.primaryKey) > 1)) {
              try {
                i++;
                await cursor.continue().then(async () => {
                  if (!!cursor.value) {
                    console.log("[IndexedDB][addMessagesQueryCursor2]", cursor.value?.id, allIndexKeys.indexOf(cursor.value?.id), cursor.value);
                    dbMessages.push(cursor.value);
                  }
                });
              } catch (error) {
                canAdvance = false;
                console.error("[IndexedDBService][addMessagesQueryCursor3] cursor error2:  ", error);
              }
            }
          }

        const dbMessageId1s = dbMessages.map(m => m.id).sort();
        const t4 = performance.now();
        console.log(`[IndexedDB][addMessagesQuery] ${query.originalQuery} took ${t4 - t3} milliseconds.`, dbMessages, dbMessageId1s);
      } else {
        dbMessages = await store.getAll(keyRangeValue);
        const t5 = performance.now();
        console.log(`[IndexedDB][addMessages][keyRange] ${minId} -> ${maxId} took ${t5 - t3} milliseconds.`, dbMessages);

      }
      let existingMessagesMap = {};
      dbMessages.forEach(m => {
        if (!!m.id) {
          existingMessagesMap[m.id] = m;
        }
      });

      const dbMessageIds = Object.keys(existingMessagesMap);
      // const dbMessageIds = await tx.store.getAllKeys();
      let inserted = 0;
      let updated = 0;

      messages.filter(v => !!v).forEach(async msg => {
        const msgToStore = {
          ...msg,
          folders: [this.getQueryByFolderId(+msg.l)]
        };

        if (dbMessageIds.indexOf(msgToStore.id) > -1) {
          // keep 'message.mp' value
          // const t4 = performance.now();
          // const existingMessage: Message = await tx.store.get(msgToStore.id);
          const existingMessage: Message = existingMessagesMap[msgToStore.id];
          // const t5 = performance.now();
          // console.log(`[PERFORMANCE3][IndexedDBService] getExistingMessage took ${t5 - t4} milliseconds.`);
          if (existingMessage && existingMessage.mp && !msgToStore.mp) {
            console.log("[IndexedDBService] keepExistingMP: ", existingMessage.id, existingMessage.mp, msgToStore.mp);
            msgToStore.mp = existingMessage.mp;
          }
          if (existingMessage && !!existingMessage.mid) {
            msgToStore.mid = existingMessage.mid;
          }
          if (existingMessage && !!existingMessage.sd) {
            msgToStore.sd = existingMessage.sd;
          }
          if (existingMessage && existingMessage.e && (existingMessage.e.length > msgToStore.e.length)) {
            // console.log("[IndexedDBService] keepExistingReceipients: ", existingMessage, msgToStore, isSameMessage, diff);
            msgToStore.e = existingMessage.e;
          }
          const diff = _.difference(existingMessage, msgToStore);
          // console.log("[IndexedDBService] keepExistingReceipients: ", existingMessage, msgToStore, diff);
          const existingKeys = Object.keys(existingMessage);
          const storeKeys = Object.keys(msgToStore);
          const diffKeys = _.difference(existingKeys, storeKeys);
          // console.log("[IndexedDBService] keepExistingReceipientsKeys: ", existingKeys, storeKeys, diffKeys);
          if ((diff.length > 0) || (diffKeys.length > 0)) {
            updated++;
            console.log("IndexedDBService storeMessage1-update ", msgToStore);
            tx.store.put(msgToStore);
          }
        } else {
          inserted++;
          console.log("IndexedDBService storeMessage1-insert ", msgToStore.id, msgToStore);
          tx.store.put(msgToStore);
        }

        // console.log("[IndexedDBService][addMessages] msgToStore", msg.l, MailUtils.getQueryByFolderId(+msg.l), msgToStore.folders);


      });

      tx.done.then(() => {
        const t2 = performance.now();
        console.log(`[PERFORMANCE][IndexedDBService] addMessagesPerfomance for insert: ${inserted} / updated: ${updated} messages took ${t2 - t1} milliseconds.`);
        console.log("[IndexedDBService][addMessages] success");
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][addMessages] onerror", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  addMessagesWithoutQuery(messages: Message[]): Observable<any> {
    console.log("[IndexedDBService][addMessagesWithoutQuery]", messages);
    const response = new Subject<any>();

    this.getMessagesByIdsForkJoin(messages.filter(v => !!v).map(m => m.id), true).subscribe(existingMessagesMap => {

      this.idbContext().then(db => {
        const tx = db.transaction(MESSAGES_DB_STORE, "readwrite");
        messages.filter(v => !!v).forEach(msg => {
          const msgToStore = {
            ...msg,
            folders: [this.getQueryByFolderId(+msg.l)]
          };

          // keep 'message.mp' value
          const existingMessage: Message = existingMessagesMap[msg.id];
          if (existingMessage && existingMessage.mp && !msgToStore.mp) {
            console.log("[IndexedDBService] keepExistingMP: ", existingMessage.id, existingMessage.mp, msgToStore.mp);
            msgToStore.mp = existingMessage.mp;
          }
          if (existingMessage && !!existingMessage.mid) {
            msgToStore.mid = existingMessage.mid;
          }
          if (existingMessage && existingMessage.e && (existingMessage.e.length > msgToStore.e.length)) {
            console.log("[IndexedDBService] keepExistingReceipients: ", existingMessage.e, msgToStore.e);
            msgToStore.e = existingMessage.e;
          }

          console.log("[IndexedDBService][addMessagesWithoutQuery-msgToStore]", msgToStore.id, msgToStore.l, msgToStore.folders);
          console.log("IndexedDBService storeMessage2-upsert ", msgToStore);
          tx.store.put(msgToStore);
        });

        tx.done.then(() => {
          console.log("[IndexedDBService][addMessages] success");
          response.next(true);
        }).catch(error => {
          console.error("[IndexedDBService][addMessages] onerror", error);
          response.error(error);
        });
      });
    });

    return response.asObservable().pipe(take(1));
  }

  createOrUpdateMessages(messages: Message[]): Observable<any> {
    const t1 = performance.now();

    const response = new Subject<any>();

    const addMessageIds = messages.filter(v => !!v).map(m => m.id);
    const _sortedMessageIds = addMessageIds.sort();
    console.log("[IndexedDBService][createOrUpdateMessages]", addMessageIds.join(","));
    const minId = _sortedMessageIds[0];
    const maxId = _sortedMessageIds[_sortedMessageIds.length - 1];
    let keyRangeValue;
    let isKeyRangeSet = false;
    try {
      keyRangeValue = IDBKeyRange.bound(minId, maxId);
      isKeyRangeSet = true;
    } catch (error) {

    }

    if (!isKeyRangeSet) {
      try {
        keyRangeValue = IDBKeyRange.lowerBound(minId);
        isKeyRangeSet = true;
      } catch (error) {

      }

    }


    this.idbContext().then(async db => {
      const tx = db.transaction(MESSAGES_DB_STORE, "readwrite");
      const store = tx.objectStore(MESSAGES_DB_STORE);
      // console.log("[IndexedDB][createOrUpdateMessages] getting all IDS...");
      const t3 = performance.now();
      let dbMessages = [];

      dbMessages = await store.getAll(keyRangeValue);
      const t5 = performance.now();
      const actualMessageIds = dbMessages.map(m => m.id);
      // console.log(`[IndexedDB][createOrUpdateMessages][keyRange] ${minId} -> ${maxId} took ${t5 - t3} milliseconds.`, actualMessageIds.join(","));


      let existingMessagesMap = {};
      dbMessages.forEach(m => {
        if (!!m.id) {
          existingMessagesMap[m.id] = m;
        }
      });

      const dbMessageIds = Object.keys(existingMessagesMap);
      // console.log(`[IndexedDB][createOrUpdateMessages]existingMessagesMap ids: `, dbMessageIds.join(","));
      // const dbMessageIds = await tx.store.getAllKeys();
      let inserted = 0;
      let updated = 0;
      let insertedIds = [];
      let updatedIds = [];

      messages.filter(v => !!v).forEach(msg => {
        // console.log(`[IndexedDB][createOrUpdateMessages] processing message: `, msg.id);
        const msgToStore = {
          ...msg,
          folders: [this.getQueryByFolderId(+msg.l)]
        };

        if (dbMessageIds.indexOf(msgToStore.id) > -1) {
          // keep 'message.mp' value
          // const t4 = performance.now();
          // const existingMessage: Message = await tx.store.get(msgToStore.id);
          const existingMessage: Message = existingMessagesMap[msgToStore.id];
          // const t5 = performance.now();
          // console.log(`[PERFORMANCE3][IndexedDBService] getExistingMessage took ${t5 - t4} milliseconds.`);
          if (existingMessage && existingMessage.mp && !msgToStore.mp) {
            msgToStore.mp = existingMessage.mp;
          }
          if (existingMessage && !!existingMessage.mid) {
            msgToStore.mid = existingMessage.mid;
          }
          if (existingMessage && !!existingMessage.sd) {
            msgToStore.sd = existingMessage.sd;
          }
          if (existingMessage && !!existingMessage.e && !!msgToStore.e && (existingMessage.e.length > msgToStore.e.length)) {
            // console.log("[IndexedDBService] keepExistingReceipients: ", existingMessage, msgToStore, isSameMessage, diff);
            msgToStore.e = existingMessage.e;
          }
          if (existingMessage && !!existingMessage.su && !msgToStore.su) {
            msgToStore.su = existingMessage.su;
            // console.log("[IndexedDBService] createOrUpdateMessageskeepExistingSubject: ", msgToStore.id, msgToStore.su);
          }
          if (existingMessage && !!existingMessage.e && !msgToStore.e) {
            msgToStore.e = existingMessage.e;
            // console.log("[IndexedDBService] createOrUpdateMessageskeepExistingEmails: ", msgToStore.id, msgToStore.su);
          }
          const diff = _.difference(existingMessage, msgToStore);
          // console.log("[IndexedDBService] keepExistingReceipients: ", existingMessage, msgToStore, diff);
          const existingKeys = Object.keys(existingMessage);
          const storeKeys = Object.keys(msgToStore);
          const diffKeys = _.difference(existingKeys, storeKeys);

          // console.log(`[IndexedDBService] createOrUpdateMessages UPDATE: ${msgToStore.id} with flags: ${msg.f} / ${msgToStore.f} and l: ${msg.l} / ${msgToStore.l}`);
          updated++;
          updatedIds.push(msgToStore.id);
          // console.log("IndexedDBService storeMessage3-update ", msgToStore);
          tx.store.put(msgToStore);
          // console.log(`[IndexedDB][createOrUpdateMessages] updateExisting id: `, msgToStore.id);
          if (this.invalidMsgIds.includes(msgToStore.id)) {
            this.invalidMsgIds = this.invalidMsgIds.filter(v => (!!v && v !== msgToStore.id));
          }

        } else {


/*          if (!!msgToStore.e) {
            console.log(`[IndexedDBService] createOrUpdateMessages INSERT: ${msgToStore.id} with e: ${JSON.stringify(msgToStore.e)} `);
          } else {
            console.log(`[IndexedDBService] createOrUpdateMessages INSERT: ${msgToStore.id} without e`);
          }

          if (!!msgToStore.su) {
            console.log(`[IndexedDBService] createOrUpdateMessages INSERT: ${msgToStore.id} with su: ${JSON.stringify(msgToStore.su)} `);
          } else {
            console.log(`[IndexedDBService] createOrUpdateMessages INSERT: ${msgToStore.id} without su `);
          } */

          if (!!msgToStore.e) {
            inserted++;
            insertedIds.push(msgToStore.id);
            // console.log("IndexedDBService storeMessage3-insert ", msgToStore);
            tx.store.put(msgToStore);
            // console.log(`[IndexedDB][createOrUpdateMessages] addNew id: `, msgToStore.id);
          }
        }

        // console.log("[IndexedDBService][addMessages] msgToStore", msg.l, MailUtils.getQueryByFolderId(+msg.l), msgToStore.folders);


      });

      tx.done.then(() => {
        const t2 = performance.now();
        console.log(`[PERFORMANCE][IndexedDBService] createOrUpdateMessages for insert: ${inserted} / updated: ${updated} messages took ${t2 - t1} milliseconds.`);
        // console.log("[IndexedDBService][createOrUpdateMessages] inserted: ", insertedIds.join(","));
        // console.log("[IndexedDBService][createOrUpdateMessages] updated: ", updatedIds.join(","));
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][createOrUpdateMessages] onerror", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }


  addNewMessagesOnly(messages: Message[]): Observable<any> {
    const t1 = performance.now();

    const response = new Subject<any>();

    const addMessageIds = messages.filter(v => !!v).map(m => m.id);
    const _sortedMessageIds = addMessageIds.sort();
    console.log("[IndexedDBService][addNewMessagesOnly]", addMessageIds.join(","));
    const minId = _sortedMessageIds[0];
    const maxId = _sortedMessageIds[_sortedMessageIds.length - 1];
    let keyRangeValue;
    let isKeyRangeSet = false;
    try {
      keyRangeValue = IDBKeyRange.bound(minId, maxId);
      isKeyRangeSet = true;
    } catch (error) {

    }

    if (!isKeyRangeSet) {
      try {
        keyRangeValue = IDBKeyRange.lowerBound(minId);
        isKeyRangeSet = true;
      } catch (error) {

      }

    }


    this.idbContext().then(async db => {
      const tx = db.transaction(MESSAGES_DB_STORE, "readwrite");
      const store = tx.objectStore(MESSAGES_DB_STORE);
      // console.log("[IndexedDB][createOrUpdateMessages] getting all IDS...");
      const t3 = performance.now();
      let dbMessageIds = [];

      dbMessageIds = await store.getAllKeys(keyRangeValue);
      const t5 = performance.now();

      let inserted = 0;
      let insertedIds = [];

      messages.filter(v => !!v).forEach(msg => {
        const msgToStore = {
          ...msg,
          folders: [this.getQueryByFolderId(+msg.l)]
        };

        // only store messages not yet in db
        if (dbMessageIds.indexOf(msgToStore.id) === -1) {

          if (!!msgToStore.e) {
            inserted++;
            insertedIds.push(msgToStore.id);
            console.log("IndexedDBService addNewMessagesOnly-insert ", msgToStore);
            tx.store.put(msgToStore);
          }
        }

      });

      tx.done.then(() => {
        const t2 = performance.now();
        console.log(`[PERFORMANCE][IndexedDBService] addNewMessagesOnly for insert: ${inserted} messages took ${t2 - t1} milliseconds.`);
        // console.log("[IndexedDBService][addNewMessagesOnly] inserted: ", insertedIds.join(","));
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][addNewMessagesOnly] onerror", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }


  private getMessagesByIds(ids: string[], ignoreLog?: boolean): Observable<any> {
    if (!ignoreLog) {
      console.log("[IndexedDBService][getMessageByIds1]", ids);
    }
    const response = new Subject<any>();
    const t1 = performance.now();

    const messagesMap = {};
    const _sortedMessageIds = ids.sort();
    const minId = _sortedMessageIds[0];
    const maxId = _sortedMessageIds[_sortedMessageIds.length - 1];
    let keyRangeValue;
    let isKeyRangeSet = false;
    try {
      keyRangeValue = IDBKeyRange.bound(minId, maxId);
      isKeyRangeSet = true;
    } catch (error) {

    }

    if (!isKeyRangeSet) {
      try {
        keyRangeValue = IDBKeyRange.lowerBound(minId);
        isKeyRangeSet = true;
      } catch (error) {

      }

    }
    this.idbContext().then(async db => {
      const tx = db.transaction(MESSAGES_DB_STORE, "readonly");
      // const store = tx.objectStore(MESSAGES_DB_STORE);
      const dbMessageIds = await tx.store.getAllKeys(keyRangeValue);
      const t3 = performance.now();
      console.log("[IndexedDB][getMessagesByIds] allMessageIds: ", dbMessageIds);
      console.log(`[PERFORMANCE2][IndexedDBService] getMessagesByIds getAllKeys [${dbMessageIds.length}] took ${t3 - t1} milliseconds.`, dbMessageIds);
      const messagesMap = {};
      db.getAll(MESSAGES_DB_STORE, keyRangeValue).then(msgs => {
        console.log("[IndexedDB][getMessagesByIds] allMessagesInRange: ", msgs);
        msgs.filter(v => (!!v && ids.indexOf(v.id) > -1)).forEach((m: any) => {
          messagesMap[m.id] = m;
        });
        const t2 = performance.now();
        console.log(`[PERFORMANCE][IndexedDBService] getMessagesByIds for ${ids.length} messages took ${t2 - t1} milliseconds.`);
        response.next(messagesMap);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  private getMessagesByIdsForkJoin(ids: string[], ignoreLog?: boolean): Observable<any> {
    if (!ignoreLog) {
      console.log("[IndexedDBService][getMessageByIdsFJ]", ids);
    }
    const response = new Subject<any>();

    const getMessages$: any = [];
    ids.forEach(mid => {
      getMessages$.push(this.getMessageById(mid, true));
    });
    const messagesMap = {};
    forkJoin(getMessages$).subscribe((messages: any) => {
      messages.filter(v => !!v).forEach((m: any) => {
        if (m) {
          messagesMap[m.id] = m;
        }
      });
      response.next(messagesMap);
    });

    return response.asObservable().pipe(take(1));
  }

  private getAppointmentsByIdsForkJoin(ids: string[], ignoreLog?: boolean): Observable<any> {
    if (!ignoreLog) {
      console.log("[IndexedDBService][getAppointmentByIdsFJ]", ids);
    }
    const response = new Subject<any>();

    const getEvents$: any = [];
    ids.forEach(mid => {
      getEvents$.push(this.getEventById(mid, true));
    });

    const eventsMap = {};
    forkJoin(getEvents$).subscribe((messages: any) => {
      messages.filter(v => !!v).forEach((m: any) => {
        if (m) {
          eventsMap[m.id] = m;
        }
      });
      response.next(eventsMap);
    });

    return response.asObservable().pipe(take(1));
  }


  private getConversationsByIds(ids: string[]): Observable<any> {
    const response = new Subject<any>();

    const getConversations$: any = [];
    ids.forEach(mid => {
      getConversations$.push(this.getConversationById(mid));
    });

    const conversationsMap = {};
    forkJoin(getConversations$).subscribe((conversations: any) => {
      conversations.filter(v => !!v).forEach((m: any) => {
        if (m) {
          conversationsMap[m.id] = m;
        }
      });
      response.next(conversationsMap);
    });

    return response.asObservable().pipe(take(1));
  }

  getAppointmentsById(id): Observable<any[]> {
    console.log("[IndexedDBService][getAppointmentsById]", id);

    const response = new Subject<any>();
    if (!this.idbContext()) {
      console.log("[IndexedDBService][getAppointmentsById] - no idbContext", id);
      setTimeout(() => {
        response.next(null);
      }, 10);
      return;
    }

    this.idbContext().then(db => {
      const tx = db.transaction(APPOINTMENT_DB_STORE);

      if (!!tx) {
        if (!id) {
          console.log("[IndexedDBService][getAppointmentsById] no id");
          setTimeout(() => {
            response.next(null);
          }, 10);
        } else {
          tx.store.get(IDBKeyRange.only(id)).then(event => {
            if (!!event) {
              console.log("[IndexedDBService][getAppointmentsById] event", event);
              response.next(event);
            } else {
              console.log("[IndexedDBService][getAppointmentsById] no event");
              setTimeout(() => {
                response.next(null);
              }, 10);
            }
          }).catch(err => {
            console.error("[IndexedDBService][getAppointmentsById]", id, err);
            response.error(err);
          });
        }
      } else {
        setTimeout(() => {
          console.error("[IndexedDBService][getAppointmentsById] no tx", id);
          response.error({msg: "no tx"});
        }, 10);
      }
    });

    return response.asObservable().pipe(take(1));
  }



  deleteAppointments(ids: string[], type?: string): Observable<any> {


    const appIds = ((ids.length === 1) && (ids[0]?.indexOf(",") > -1)) ? ids[0]?.split(",") : ids;

    console.log("[IndexedDBService][deleteAppointmentsIDB]", appIds);

    const response = new Subject<any>();

    this.idbContext().then(async db => {
      let dbAppointmentIds = [];
      const tx = db.transaction(APPOINTMENT_DB_STORE, "readwrite");
      if (type) {
        if (type === "series-all" || type === "instance-after") {
          dbAppointmentIds = await tx.store.getAllKeys();
        }
      }
      appIds.forEach(id => {
        if (type === "series-all") {
          const indentifier = id.split("_")[0];
          let dbAppointmentIdsToDelete = dbAppointmentIds.filter((id: any) => id.includes(indentifier));
          dbAppointmentIdsToDelete.forEach(appId => {
            tx.store.delete(appId);
          });
        } if (type === "instance-after") {
          const indentifier = id.split("_")[0];
          let dbAppointmentIdsToDelete = dbAppointmentIds.filter((id: any) => id.includes(indentifier));
          let idIndex = dbAppointmentIdsToDelete.indexOf(id);
          if (idIndex > -1) {
            dbAppointmentIdsToDelete.splice(0, idIndex);
            dbAppointmentIdsToDelete.forEach(appId => {
              tx.store.delete(appId);
            });
          }
        } else {
          tx.store.delete(id);
        }
      });

      tx.done.then(() => {
        console.log("[IndexedDBService][deleteAppointments] success");
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][deleteAppointments]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getMessagesByConversationId(conversationId): Observable<Message[]> {
    console.log("[IndexedDBService][getMessagesByConversationId] conversationId", conversationId);

    const response = new Subject<Message[]>();

    this.idbContext().then(db => {
      db.getAllFromIndex(MESSAGES_DB_STORE, INDEX_BY_CONVERSATION_ID_INDEX, conversationId).then(msgs => {
        // console.log("[IndexedDBService][getMessagesByConversationId]", msgs);
        msgs = this.sortObjects(msgs);
        console.log("[IndexedDBService][getMessagesByConversationId]", msgs);
        response.next(msgs);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getMessagesByFolder(folderName, query, includingId?, quickFilterActive?): Observable<Message[]> {
    console.log("[[IndexedDBService][getMessagesByFolder]", quickFilterActive, includingId, folderName, query);
    const t1 = performance.now();
    const response = new Subject<Message[]>();
    if (!!query && !!query.limit && !!query.sortBy && (!!query.offset || query.offset === 0) && ((query.sortBy === "dateDesc") || (query.sortBy === "dateAsc"))) {
      this.idbContext().then(async db => {
        let msgs = [];
        const tx = db.transaction(MESSAGES_DB_STORE, "readonly");
        const store = tx.objectStore(MESSAGES_DB_STORE);

        if (!!includingId || !!quickFilterActive) {
          let rawMsgs = [];
          const index = store.index(INDEX_BY_FOLDER);
          const allKeys = await index.getAllKeys(folderName);
          const indexKeyLength = allKeys.length;
          const offset = (query.sortBy === "dateDesc") ? indexKeyLength - query.limit - query.offset : query.offset;
          const count = (query.sortBy === "dateDesc") ? indexKeyLength : query.offset + query.limit;
          console.log("[IndexedDBService][getMessagesByFolderquery2", count, offset, indexKeyLength, query.offset, quickFilterActive);
          if ((indexKeyLength > query.offset) && !quickFilterActive) {
            rawMsgs = await index.getAll(folderName, count);
          } else {
            rawMsgs = await index.getAll(folderName);
          }
          const validMessages = rawMsgs.filter(m => !!m.e);
          const invalidMessages = rawMsgs.filter(m => !m.e);
          // console.log("[IndexedDBService][getMessagesByFolderInvalid", invalidMessages, this.invalidMsgIds);
          console.log("[IndexedDBService][getMessagesByFolderquery2 rawMsgs.length ", rawMsgs.length);
          invalidMessages.forEach(im => {
            if (!this.invalidMsgIds.includes(im.id)) {
              this.invalidMsgIds.push(im.id);
            }
          });
          const sortedMessages = (query.sortBy === "dateDesc") ? _.orderBy(validMessages, "d", "desc") : _.orderBy(validMessages, "d", "asc");
          console.log("[IndexedDBService][getMessagesByFolderquerySorted", sortedMessages.length, sortedMessages[0]?.id);
          if (!!includingId || !!quickFilterActive) {
            msgs = sortedMessages;
          } else {
            msgs = sortedMessages.slice(query.offset, query.offset + query.limit);
          }
        } else {
          const index = store.index(INDEX_BY_FOLDER_DATE);
          const lowerBound = [[folderName], 0];
          const upperBound = [[folderName], Date.now()];
          const range = IDBKeyRange.bound(lowerBound, upperBound);

          const direction = (query.sortBy === "dateDesc") ? "prev" : "next";

          let cursor = await index.openCursor(range, direction);
          let canAdvance = true;
          if (!!cursor) {
            let initOffset = 0;
            // console.log("[[IndexedDBService][getMessagesByFolder]", cursor.value?.id, cursor);
            if (query.offset > 0) {
              try {
                await cursor.advance(query.offset);
              } catch (error) {
                // console.log("[IndexedDB-getMessagesByFolderCursor2] can not advance ...");
                canAdvance = false;
              }
            }
            if (!!cursor.value) {
              msgs.push(cursor.value);
              initOffset = 1;
            }
            let i = 1;
            // while ((i <= query.limit + 1) && canAdvance && !!cursor && !!cursor.value && (allKeys.indexOf(cursor.primaryKey) > 1)) {
            while ((i <= query.limit - initOffset) && canAdvance && !!cursor && !!cursor.value) {
              try {
                i++;
                await cursor.continue().then(async () => {
                  if (!!cursor.value) {
                    // console.log("[IndexedDB-getMessagesByFolderCursor3]", cursor.value?.id, allKeys.indexOf(cursor.value?.id), cursor.value);
                    // console.log("[IndexedDB-getMessagesByFolderCursor3]", cursor.value?.id, cursor.value);
                    msgs.push(cursor.value);
                  }
                });
              } catch (error) {
                canAdvance = false;
                // console.error("[IndexedDB-getMessagesByFolderCursor3] cursor error2:  ", error);
              }
            }

          }
        }


        tx.done.then(() => {
          // const t2 = performance.now();
          // console.log(`[PERFORMANCE][IndexedDB-getMessagesByFolderQuery took ${t2 - t1} milliseconds.`, query, msgs);
          // console.log("[IndexedDBService][getMessagesByFolderquery2", msgs);
          response.next(msgs);
        }).catch(error => {
          console.error("[IndexedDBService][getMessagesByFolder]", error);
          response.error(error);
        });
      });
    } else {
      this.idbContext().then(db => {
        console.log("[IndexedDBService][getMessagesByFolder] ALL");
        db.getAllFromIndex(MESSAGES_DB_STORE, INDEX_BY_FOLDER, folderName).then(msgs => {
          // msgs = this.sortObjects(msgs);
          console.log("[IndexedDBService][getMessagesByFolder] res", msgs);
          response.next(msgs);
        });
      });
    }

    return response.asObservable().pipe(take(1));
  }


  getMessagesByFolderOld(folderName, query): Observable<Message[]> {
    console.log("[IndexedDBService][getMessagesByFolder]", folderName, query);

    const response = new Subject<Message[]>();
    if (!!query && !!query.limit && (!!query.offset || query.offset === 0)) {
      this.idbContext().then(async db => {
        const tx = db.transaction(MESSAGES_DB_STORE, "readonly");
        const store = tx.objectStore(MESSAGES_DB_STORE);
        const index = store.index(INDEX_BY_FOLDER);
        const allKeys = await index.getAllKeys(folderName, query.limit + query.offset);
        const indexKeyLength = allKeys.length;
        console.log("[IndexedDBService][getMessagesByFolder] allKeys", allKeys);
        let msgs = [];
        index.openCursor(folderName, "prev").then(async (cursor) => {
          if (cursor) {
            let canAdvance = true;
            if (query.offset === 0) {
              msgs.push(cursor.value);
              console.log("[IndexedDBService][getMessagesByFolderCursor] ", cursor);
            } else {
              try {
                await cursor.continue(query.offset).then(async () => {
                  if (!!cursor.value) {
                    console.log("[IndexedDBService][getMessagesByFolder] skippeded for offset: ", query.offset);
                  } else {
                    canAdvance = false;
                  }
                });
              } catch (error) {
                console.error("[IndexedDBService][getMessagesByFolder] cursor error1:  ", error);
                canAdvance = false;
              }
            }
            console.log("[IndexedDBService][getMessagesByFolder] query1 ", msgs, cursor.value);
            let i = 1;


            while ((i <= query.limit) && canAdvance && !!cursor && !!cursor.value && (allKeys.indexOf(cursor.primaryKey) > 1)) {
              try {
                i++;
                await cursor.continue().then(async () => {
                  if (!!cursor.value) {
                    msgs.push(cursor.value);
                  }
                });
              } catch (error) {
                canAdvance = false;
                console.error("[IndexedDBService][getMessagesByFolder] cursor error2:  ", error);
              }
            }
          }
        });

        tx.done.then(() => {
          console.log("[IndexedDBService][getMessagesByFolder] query2", msgs);
          response.next(msgs);
        }).catch(error => {
          console.error("[IndexedDBService][getMessagesByFolder]", error);
          response.error(error);
        });
      });
    } else {
      this.idbContext().then(db => {
        db.getAllFromIndex(MESSAGES_DB_STORE, INDEX_BY_FOLDER, folderName).then(msgs => {
          // msgs = this.sortObjects(msgs);
          console.log("[IndexedDBService][getMessagesByFolder] res", msgs);
          response.next(msgs);
        });
      });
    }

    return response.asObservable().pipe(take(1));
  }


  getMessagesByTag(tagName): Observable<Message[]> {
    const response = new Subject<Message[]>();

    this.idbContext().then(db => {
      db.getAllFromIndex(MESSAGES_DB_STORE, INDEX_BY_TAG, tagName).then(msgs => {
        // msgs = this.sortObjects(msgs);
        console.log("[IndexedDBService][getMessagesByTag] res", msgs);
        response.next(msgs);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getLatestMessage(): Observable<Message[]> {
    const response = new Subject<Message[]>();

    this.idbContext().then(db => {
      const tx = db.transaction(MESSAGES_DB_STORE, "readonly");
      const store = tx.objectStore(MESSAGES_DB_STORE);
      const index = store.index(INDEX_BY_DATE_ID_INDEX);

      let msgs = [];
      index.openCursor(null, "prev").then((cursor) => {
        if (cursor) {
          // maxTs = cursor.value.Timestamp;
          msgs.push(cursor.value);
          console.log("[IndexedDBService][getLatestMessage] CONV", msgs, cursor.value);
        }
      });

      tx.done.then(() => {
        console.log("[IndexedDBService][getLatestMessage]", msgs);
        response.next(msgs);
      }).catch(error => {
        console.error("[IndexedDBService][getLatestMessage]", error);
        response.error(error);
      });


    });

    return response.asObservable().pipe(take(1));
  }

  getFirstMessageByFolder(folderName, order): Observable<Message[]> {
    const query = (!!folderName && !!folderName.id) ? this.getQueryByFolderId(folderName.id) : this.getQueryByFolderId(2);
    console.log("[IndexedDBService][getFirstMessageByFolder] ", folderName, query, order);
    const response = new Subject<Message[]>();

    this.idbContext().then(db => {
      const tx = db.transaction(MESSAGES_DB_STORE, "readonly");
      const store = tx.objectStore(MESSAGES_DB_STORE);
      const index = store.index(INDEX_BY_FOLDER);
      const direction = (order === "desc") ? "prev" : "next";

      let msgs = [];

      index.openCursor(query, direction).then((cursor) => {
        if (cursor) {
          // maxTs = cursor.value.Timestamp;
          msgs.push(cursor.value);
          console.log("[IndexedDBService][getFirstMessageByFolder] ", msgs, cursor.value);
        }
      });

      tx.done.then(() => {
        console.log("[IndexedDBService][getFirstMessageByFolder]", msgs);
        response.next(msgs);
      }).catch(error => {
        console.error("[IndexedDBService][getFirstMessageByFolder]", error);
        response.error(error);
      });


    });

    return response.asObservable().pipe(take(1));
  }


  getMessageCountInDatabaseByFolder(folderName): Observable<any> {
    const t1 = performance.now();
    const query = this.getQueryByFolderId(folderName);
    console.log("[IndexedDBService][getMessageCountInDatabaseByFolder] ", folderName, query);
    const response = new Subject<any>();

    this.idbContext().then(async db => {
      const tx = db.transaction(MESSAGES_DB_STORE, "readonly");
      const store = tx.objectStore(MESSAGES_DB_STORE);
      const index = store.index(INDEX_BY_FOLDER);

      const count = await index.count(query);


      tx.done.then(() => {
        const t2 = performance.now();
        console.log(`[PERFORMANCE][getMessageCountInDatabaseByFolder took ${t2 - t1} milliseconds.`, query, count);

        console.log("[IndexedDBService][getMessageCountInDatabaseByFolder]", folderName, count);
        response.next(count);
      }).catch(error => {
        console.error("[IndexedDBService][getMessageCountInDatabaseByFolder]", error);
        response.error(error);
      });


    });

    return response.asObservable().pipe(take(1));
  }

  getMessageById(id, ignoreLog?: boolean): Observable<any> {
    if (!ignoreLog) {
      console.log("[IndexedDBService][getMessageById]", id);
    }

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(MESSAGES_DB_STORE);
      tx.store.get(IDBKeyRange.only(id)).then(msg => {
        // if (!ignoreLog) {
          console.log("[IndexedDBService][getMessageById] msg", msg);
        // }
        response.next(msg);
      }).catch(error => {
        console.error("[IndexedDBService][getMessageById]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getEventById(id, ignoreLog?: boolean): Observable<any> {
    if (!ignoreLog) {
      console.log("[IndexedDBService][getEventById]", id);
    }

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(APPOINTMENT_DB_STORE);
      tx.store.get(IDBKeyRange.only(id)).then(msg => {
        // if (!ignoreLog) {
        // }
        response.next(msg);
      }).catch(error => {
        console.error("[IndexedDBService][getEventById]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getMessagesStarred(): Observable<Message[]> {
    console.log("[IndexedDBService][getMessagesStarred]");
    const response = new Subject<Message[]>();

    this.idbContext().then(db => {
      const tx = db.transaction(MESSAGES_DB_STORE, "readonly");

      // TODO: to replace with DB specific query instead of array filtering
      tx.store.getAll().then(msgs => {
        const starredMsgs = msgs.filter(message => {
          return message.f && message.f.includes("f"); // (f)lagged
        });
        console.log("[IndexedDBService][getMessagesStarred] success");
        response.next(starredMsgs);
      }).catch(error => {
        console.error("[IndexedDBService][getMessagesStarred] error", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getMessagesSent(): Observable<Message[]> {
    console.log("[IndexedDBService][getMessagesSent]");
    const response = new Subject<Message[]>();

    this.idbContext().then(db => {
      const tx = db.transaction(MESSAGES_DB_STORE, "readonly");

      // TODO: to replace with DB specific query instead of array filtering
      tx.store.getAll().then(msgs => {
        const sentMsgs = msgs.filter(message => {
          return message.l
            && message.l === "5"
            && message.folders
            && message.folders.indexOf("in:trash") === -1; // (s)ent by me and not deleted
        });
        console.log("[IndexedDBService][getMessagesSent] Ok", sentMsgs);
        response.next(sentMsgs);
      }).catch(error => {
        console.error("[IndexedDBService][getMessagesSent] error", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  updateMessages(messages: Message[]): Observable<any> {
    console.log("[IndexedDBService][indexedDBupdateMessages]", messages);
    const t1 = performance.now();

    const response = new Subject<any>();

    if (messages.length === 0) {
      setTimeout(() => {
        response.next(true);
      }, 3);
    } else {

      const sortedMessageIds = messages.filter(v => !!v).map(m => m.id).sort();
      let sortedMessages = [];
      for (let i = 0; i < sortedMessageIds.length; i++) {
        sortedMessages.push(messages.find(m => (m.id === sortedMessageIds[i])));
      }
      console.log("indexedDBupdateMessages sortedMessages: ", sortedMessages);
      //

      this.idbContext().then(db => {
        const tx = db.transaction(MESSAGES_DB_STORE, "readwrite");
        const store = tx.objectStore(MESSAGES_DB_STORE);
        const minId = sortedMessageIds[0];
        const maxId = sortedMessageIds[sortedMessageIds.length - 1];
        console.log("indexedDBupdateMessages sortedMessagesKeyRange: ", minId, maxId);
        const keyRangeValue = IDBKeyRange.bound(sortedMessageIds[0], sortedMessageIds[sortedMessageIds.length - 1]);


        store.openCursor(keyRangeValue, "next").then(async (cursor) => {
          let i = 0;
          let canAdvance = true;
          while ((i < sortedMessages.length) && !!cursor && canAdvance) {
            console.log("[IndexedDBService][indexedDBupdateMessages] cursor: ", cursor.primaryKey, cursor.value, sortedMessages[i]);
            if (sortedMessages[i].id === cursor.value.id) {
              const msg = sortedMessages[i];
              const existingMessage = cursor.value;
              const existingFolder = !!existingMessage.folders ? existingMessage.folders : [];
              const msgFolder = !!sortedMessages[i].l ? [this.getQueryByFolderId(+sortedMessages[i].l)] : existingFolder;
              const existingQuery = !!existingMessage.query ? existingMessage.query : "";
              const msgQuery = !!sortedMessages[i].l ? this.getQueryByFolderId(+sortedMessages[i].l) : existingQuery;
              const newL = !!sortedMessages[i].l ? sortedMessages[i].l : existingMessage.l;


              let msgToStore = {
                ...msg,
                folders: msgFolder,
                query: msgQuery,
                l: newL
              };

              if (existingMessage) {
                if (existingMessage.mp && !msgToStore.mp) {
                  msgToStore.mp = existingMessage.mp;
                }
                if (!!existingMessage.mid) {
                  msgToStore.mid = existingMessage.mid;
                }
                if (!!existingMessage.e && !!msgToStore.e && (existingMessage.e.length > msgToStore.e.length)) {
                  msgToStore.e = existingMessage.e;
                }
                msgToStore = { ...existingMessage, ...msgToStore };
              }

              const updateResult = await cursor.update(msgToStore);
              console.log("[IndexedDBService][indexedDBupdateMessages] cursor2: ", cursor.primaryKey, updateResult);
              i++;
            }
            try {
              if (i < sortedMessages.length) {
                await cursor.continue(sortedMessages[i].id);
              }
            } catch (error) {
              console.log("[IndexedDBService][indexedDBupdateMessages] cursor3 error: ", error);
              canAdvance = false;
            }
          }

        });

        tx.done.then(() => {
          console.log("[IndexedDBService][updateMessages] success");
          const t2 = performance.now();
          console.log(`[PERFORMANCE][IndexedDBService] indexedDBupdateMessages for update: ${messages.length} messages took ${t2 - t1} milliseconds.`);

          response.next(true);
        }).catch(error => {
          console.error("[IndexedDBService][updateMessages]", error);
          response.error(error);
        });
      });
    }


    return response.asObservable().pipe(take(1));
  }

  updateMessagesAsStarred(messages: Message[], isStared: boolean): Observable<any> {
    return this.updateMessages(messages);
  }

  moveMessagesBetweenFolders(ids: string[], newFolder: string): Observable<any> {
    const t1 = performance.now();

    const response = new Subject<any>();
    const sortedIds = ids.sort();
    console.log("[IndexedDBService][moveMessagesBetweenFolders]", ids, sortedIds, newFolder);

    let newL = newFolder;
    if (newFolder === MailConstants.SEARCH_CRITERIA.IN_TRASH) {
      newL = MailConstants.FOLDER_ID.TRASH.toString();
    } else if (newFolder === MailConstants.SEARCH_CRITERIA.IN_JUNK) {
      newL = MailConstants.FOLDER_ID.JUNK.toString();
    } else if (newFolder === MailConstants.SEARCH_CRITERIA.IN_INBOX) {
      newL = MailConstants.FOLDER_ID.INBOX.toString();
    } else if (newFolder === MailConstants.SEARCH_CRITERIA.IN_SENT) {
      newL = MailConstants.FOLDER_ID.SENT.toString();
    } else if (newFolder.indexOf("inid:") > -1) {
      newL = newFolder.split("\"")[1];
    }

    const newFolders = [this.getQueryByFolderId(+newL)];
    let newQuery = this.getQueryByFolderId(+newL);

    this.idbContext().then(async db => {
      const tx = db.transaction(MESSAGES_DB_STORE, "readwrite");
      const store = tx.objectStore(MESSAGES_DB_STORE);
      // console.log("[IndexedDBService][moveMessagesBetweenFolders] store: ", store);
      // ToDo keyrange base on length!
      const keyRangeValue = IDBKeyRange.bound(sortedIds[0], sortedIds[sortedIds.length - 1]);
      const dbMessageIds = await tx.store.getAllKeys(keyRangeValue);
      const idsToProcess = sortedIds.filter(i => dbMessageIds.indexOf(i) > -1);

      console.log("[IndexedDBService][moveMessagesBetweenFolders] idsToProcess: ", idsToProcess);

      if (idsToProcess.length > 0) {
        let canAdvaneCursor = true;

        await store.openCursor(IDBKeyRange.lowerBound(idsToProcess[0]), "next").then(async (cursor) => {
          let i = 0;
          while ((i < idsToProcess.length) && canAdvaneCursor) {
            console.log("moveMessagesBetweenFolders - processing ", newL, cursor.primaryKey, cursor.value);
            if (cursor.value?.id === idsToProcess[i]) {
              const existingMessage = cursor.value;
              const msgToStore = { ...existingMessage };
              msgToStore.l = newL;
              msgToStore.folders = newFolders;
              msgToStore.query = newQuery;
              await cursor.update(msgToStore);
              if (!msgToStore.su) {
                console.log("IndexedDBService storeMessage moveMessagesBetweenFoldersWithoutSubject ", msgToStore);
              }
              console.log("moveMessagesBetweenFolders - updated ", newL, cursor.primaryKey, cursor.value);
            }
            i++;
            if (i < idsToProcess.length) {
              try {
                await cursor.continue(idsToProcess[i]);
              } catch (error) {
                console.log("moveMessagesBetweenFolders - advancing cursor to ", idsToProcess[i]);
                console.log("moveMessagesBetweenFolders - error advancing cursor: ", error);
                canAdvaneCursor = false;
              }
            }

          }
        });
      }

      tx.done.then(() => {
        console.log("[IndexedDBService][moveMessagesBetweenFolders] success");
        const t2 = performance.now();
        console.log(`[PERFORMANCE][IndexedDBService] moveMessagesBetweenFoldersPerf for: ${idsToProcess.length} messages took ${t2 - t1} milliseconds.`, ids, newFolder);

        response.next(idsToProcess.length > 0);
      }).catch(error => {
        console.error("[IndexedDBService][moveMessagesBetweenFolders]", error);
        response.error(error);
      });
    });


    return response.asObservable().pipe(take(1));
  }

  moveMessagesBetweenFoldersOld(ids: string[], newFolder: string): Observable<any> {
    const t1 = performance.now();
    console.log("[IndexedDBService][moveMessagesBetweenFolders]", ids, newFolder);

    const response = new Subject<any>();

    // get all messsges with ids first
    const getMessages$: any = [];
    ids.forEach(id => {
      getMessages$.push(this.getMessageById(id));
    });
    //
    const existingMessagesMap = {};
    forkJoin(getMessages$).subscribe((rsp: any) => {
      rsp.filter(v => !!v).forEach((m: any) => {
        if (m) {
          existingMessagesMap[m.id] = m;
        }
      });

      const actualIds = Object.keys(existingMessagesMap).sort((a, b) => parseInt(a, 10) - parseInt(b, 10));
      console.log("[IndexedDBService][moveMessagesBetweenFolders] existingMessagesMap", existingMessagesMap, actualIds);

      this.idbContext().then(async db => {
        const tx = db.transaction(MESSAGES_DB_STORE, "readwrite");
        const store = tx.objectStore(MESSAGES_DB_STORE);
        // console.log("[IndexedDBService][moveMessagesBetweenFolders] store: ", store);


        await store.openCursor(null, "next").then(async (cursor) => {

          for (let i = 0; i < actualIds.length; i++) {
//          actualIds.forEach(id => {
            let id = actualIds[i];
            console.log("[IndexedDBService][moveMessagesBetweenFolders] cursor: ", cursor.value?.id, id);
            try {
              if (parseInt(cursor.value?.id, 10) > parseInt(id, 10)) {
                console.error("[IndexedDBService]IDBcursor op: ", cursor.value?.id, id);
              }
            } catch (error) {
              console.log("[IndexedDBService][error parsing ids] ", error);
            }
            if (parseInt(cursor.value?.id, 10) === parseInt(id, 10)) {
              try {

                const existingMsg = existingMessagesMap[id];
                if (!existingMsg) {
                  return;
                }

                const msgToStore = { ...existingMsg };

                msgToStore.folders = [newFolder];
                let newL = newFolder;
                let newQuery = newFolder;
                if (newFolder === MailConstants.SEARCH_CRITERIA.IN_TRASH) {
                  newL = MailConstants.FOLDER_ID.TRASH.toString();
                } else if (newFolder === MailConstants.SEARCH_CRITERIA.IN_JUNK) {
                  newL = MailConstants.FOLDER_ID.JUNK.toString();
                } else if (newFolder === MailConstants.SEARCH_CRITERIA.IN_INBOX) {
                  newL = MailConstants.FOLDER_ID.INBOX.toString();
                } else if (newFolder === MailConstants.SEARCH_CRITERIA.IN_SENT) {
                  newL = MailConstants.FOLDER_ID.SENT.toString();
                }
                msgToStore.l = newL;
                msgToStore.folders = [this.getQueryByFolderId(+msgToStore.l)];
                msgToStore.query = this.getQueryByFolderId(+msgToStore.l);
                // TODO: need to do this for SQL as well
                if (newFolder !== MailConstants.SEARCH_CRITERIA.IN_DRAFTS) {
                  if (msgToStore.cid) {
                    msgToStore.cid = msgToStore.cid; // msgToStore.cid.replace("-", ""); // remove '-', so it's not a draft anymore
                  }
                }

                console.log("[IndexedDBService][moveMessagesBetweenFolders] store1", msgToStore);

                // tx.store.put(msgToStore);
                await cursor.update(msgToStore);


              } catch (error) {
                console.log("[IndexedDBService][moveMessagesBetweenFolders] error: ", error);
              }
            } else {
              await cursor.continue(id).then(async () => {
                const existingMsg = existingMessagesMap[id];
                if (!existingMsg) {
                  return;
                }

                const msgToStore = { ...existingMsg };

                msgToStore.folders = [newFolder];
                let newL = newFolder;
                let newQuery = newFolder;
                if (newFolder === MailConstants.SEARCH_CRITERIA.IN_TRASH) {
                  newL = MailConstants.FOLDER_ID.TRASH.toString();
                } else if (newFolder === MailConstants.SEARCH_CRITERIA.IN_JUNK) {
                  newL = MailConstants.FOLDER_ID.JUNK.toString();
                } else if (newFolder === MailConstants.SEARCH_CRITERIA.IN_INBOX) {
                  newL = MailConstants.FOLDER_ID.INBOX.toString();
                } else if (newFolder === MailConstants.SEARCH_CRITERIA.IN_SENT) {
                  newL = MailConstants.FOLDER_ID.SENT.toString();
                }
                msgToStore.l = newL;
                msgToStore.folders = [this.getQueryByFolderId(+msgToStore.l)];
                msgToStore.query = this.getQueryByFolderId(+msgToStore.l);
                // TODO: need to do this for SQL as well
                if (newFolder !== MailConstants.SEARCH_CRITERIA.IN_DRAFTS) {
                  if (msgToStore.cid) {
                    msgToStore.cid = msgToStore.cid; // msgToStore.cid.replace("-", ""); // remove '-', so it's not a draft anymore
                  }
                }

                console.log("[IndexedDBService][moveMessagesBetweenFolders] store2", msgToStore);

                // tx.store.put(msgToStore);
                await cursor.update(msgToStore);
              }).catch(err => {
                console.log("[IndexedDBService][moveMessagesBetweenFolders] error: ", err);
              });
            }

          }
        });

        tx.done.then(() => {
          console.log("[IndexedDBService][moveMessagesBetweenFolders] success");
          const t2 = performance.now();
          console.log(`[PERFORMANCE][IndexedDBService] moveMessagesBetweenFoldersPerformance for: ${ids.length} messages took ${t2 - t1} milliseconds.`);

          response.next(Object.keys(existingMessagesMap).length > 0);
        }).catch(error => {
          console.error("[IndexedDBService][moveMessagesBetweenFolders]", error);
          response.error(error);
        });
      });
    });

    return response.asObservable().pipe(take(1));
  }

  deleteMessage(messageId: string): Observable<any> {
    console.log("[IndexedDBService][deleteMessage]", messageId);

    return this.deleteMessages([messageId]);
  }

  deleteMessages(ids: string[]): Observable<any> {
    const mids = ((ids.length === 1) && (ids[0].indexOf(",") > -1)) ? ids[0].split(",") : ids;

    console.log("[IndexedDBService][deleteMessagesIDB]", mids);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(MESSAGES_DB_STORE, "readwrite");

      mids.forEach(id => {
        tx.store.delete(id);
      });

      tx.done.then(() => {
        console.log("[IndexedDBService][deleteMessages] success");
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][deleteMessages]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  deleteMessagesByConversation(convId: string): Observable<any> {
    // console.log("[IndexedDBService][deleteMessagesByConversationIDB] convId", convId);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      db.getAllFromIndex(MESSAGES_DB_STORE, INDEX_BY_CONVERSATION_ID_INDEX, convId).then(msgs => {
        // console.log("[IndexedDBService][deleteMessagesByConversation]", msgs);

        const tx = db.transaction(MESSAGES_DB_STORE, "readwrite");

        msgs.forEach(message => {
          tx.store.delete(message.id);
          this.deleteMessage(message.id);
        });

        tx.done.then(() => {
          // console.log("[IndexedDBService][deleteMessagesByConversation] success");
          response.next(true);
        }).catch(error => {
          console.error("[IndexedDBService][deleteMessagesByConversation]", error);
          response.error(error);
        });
      });
    });

    return response.asObservable().pipe(take(1));
  }

  ///
  ///


  addConversations(conversations): Observable<any> {
    console.log("[IndexedDBService][addConversations0]", conversations);
    const t1 = performance.now();

    const response = new Subject<any>();

    const addConversationIds = conversations.filter(v => !!v).map(c => c.id);
    const _sortedIds = addConversationIds.sort();
    const minId = _sortedIds[0];
    const maxId = _sortedIds[_sortedIds.length - 1];
    console.log("[IndexedDBService][addConversations1]", minId, maxId, _sortedIds);
    // const keyRangeValue = IDBKeyRange.bound(minId, maxId);

    this.idbContext().then(async db => {
      const tx = db.transaction(CONVERSATIONS_DB_STORE, "readwrite");
      const dbConversationIds = await tx.store.getAllKeys();
      const t3 = performance.now();
      // console.log("[IndexedDB][addMessages] allMessageIds: ", dbMessageIds);
      console.log(`[PERFORMANCE2][IndexedDBService] getAllKeys [${dbConversationIds.length}] took ${t3 - t1} milliseconds.`, dbConversationIds);

      let inserted = 0;
      let updated = 0;

      conversations.filter(v => !!v).forEach(async conv => {

        let convToStore = {
          ...conv,
          folders: !!conv.folders ? conv.folders : [conv.query]
        };

        if (dbConversationIds.indexOf(conv.id) > -1) {
          const existingConv: Conversation = await tx.store.get(conv.id);
          const messages = existingConv.m;
          if (!!messages && (messages.length > 0)) {
            convToStore.m.forEach(m => {
              if (messages.find(v => v.id === m.id)) {
                m = { ...messages.find(v => v.id === m.id), ...m };
              }
            });
          }
          updated++;
          // ToDo: maybe compare existing / to store and only put if different?
          tx.store.put(convToStore);
        } else {
          inserted++;
          tx.store.put(convToStore);
        }
      });
      tx.done.then(() => {
        const t2 = performance.now();
        console.log(`[PERFORMANCE][IndexedDBService] addConversations for insert: ${inserted} / updated: ${updated} conversations took ${t2 - t1} milliseconds.`);

        console.log("[IndexedDBService][addConversations] success");
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][addConversations]", error);
        response.error(error);
      });
    });


    return response.asObservable().pipe(take(1));
  }



  addConversationsOld(conversations): Observable<any> {
    console.log("[IndexedDBService][addConversations0]", conversations);
    const t1 = performance.now();

    const response = new Subject<any>();


    this.getConversationsByIds(conversations.filter(v => !!v).map(c => c.id))
    .subscribe(existingConversationsMap => {
      this.idbContext().then(db => {
        const tx = db.transaction(CONVERSATIONS_DB_STORE, "readwrite");

        conversations.forEach(conv => {
          if (existingConversationsMap[conv.id] && existingConversationsMap[conv.id].m
            && existingConversationsMap[conv.id].m.length > 0) {
              const messages = existingConversationsMap[conv.id].m;
              console.log("[IndexedDBService][addConversations1] existing messages", conv.id, messages);
              conv.m.forEach(m => {
                if (messages.find(v => v.id === m.id) ) {
                  m = {...messages.find(v => v.id === m.id), ...m};
                }
              });
          }
          const request = tx.store.put({
            ...conv,
            folders: !!conv.folders ? conv.folders : [conv.query] // 'query' field is set by MailService
          });
        });

        tx.done.then(() => {
          const t2 = performance.now();
          console.log(`[PERFORMANCE][IndexedDBService] addConversations for ${conversations.length}  conversations took ${t2 - t1} milliseconds.`);

          console.log("[IndexedDBService][addConversations] success");
          response.next(true);
        }).catch(error => {
          console.error("[IndexedDBService][addConversations]", error);
          response.error(error);
        });
      });

    });


    return response.asObservable().pipe(take(1));
  }


  updateConversationMessages(convId: string, messages: any[]): Observable<any> {
    const response = new Subject<any>();
    this.getConversationById(convId).subscribe((conv) => {
      if (conv) {
        conv.m = messages;
        conv.n = messages.length.toString();
        this.updateConversations([conv]).subscribe((result) => {
          console.log("[IndexedDBService][updateConversationMessages] success");
          response.next(true);
        }, (error) => {
          console.error("[IndexedDBService][updateConversationMessages]", error);
          response.error(error);
        });
      } else {
        response.next(true);
      }
    });
    return response.asObservable();
  }

  getConversationsByFolder(folderName): Observable<Conversation[]> {
    console.log("[IndexedDBService][getConversationsByFolder]", folderName);

    const response = new Subject<Conversation[]>();

    this.idbContext().then(db => {
      db.getAllFromIndex(CONVERSATIONS_DB_STORE, INDEX_BY_FOLDER, folderName).then(convs => {
        convs = this.sortObjects(convs);
        console.log("[IndexedDBService][getConversationsByFolder] convs", convs);
        response.next(convs);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getAllAppointments(): Observable<any[]> {
    console.log("[IndexedDBService][getAllAppointments]");

    const response = new Subject<any[]>();

    this.idbContext().then(db => {
      db.getAll(APPOINTMENT_DB_STORE).then(appt => {
        appt = this.sortObjects(appt);
        console.log("[IndexedDBService][getAllAppointments] appt", appt);
        response.next(appt);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getConversationsByTag(tagName): Observable<Conversation[]> {
    const response = new Subject<Conversation[]>();

    this.idbContext().then(db => {
      db.getAllFromIndex(CONVERSATIONS_DB_STORE, INDEX_BY_TAG, tagName).then(convs => {
        convs = this.sortObjects(convs);
        console.log("[IndexedDBService][getConversationsByTag] convs", convs);
        response.next(convs);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getConversationById(id: string): Observable<Conversation> {
    console.log("[IndexedDBService][getConversationById]", id);

    const response = new Subject<Conversation>();
    if (!this.idbContext()) {
      console.log("[IndexedDBService][getConversationById] - no idbContext", id);
      setTimeout(() => {
        response.next(null);
      }, 10);
      return;
    }

    this.idbContext().then(db => {
      const tx = db.transaction(CONVERSATIONS_DB_STORE);

      if (!!tx) {
        tx.store.get(IDBKeyRange.only(id)).then(conv => {
          if (!!conv) {
            console.log("[IndexedDBService][getConversationById] conv", conv);
            response.next(conv);
          } else {
            console.log("[IndexedDBService][getConversationById] no conv");
            setTimeout(() => {
              response.next(null);
            }, 10);
          }
        }).catch(err => {
          console.error("[IndexedDBService][getConversationById]", id, err);
          response.error(err);
        });
      } else {
        setTimeout(() => {
          console.error("[IndexedDBService][getConversationById] no tx", id);
          response.error({msg: "no tx"});
        }, 10);
      }
    });

    return response.asObservable().pipe(take(1));
  }

  getConversationsStarred(): Observable<Conversation[]> {
    console.log("[IndexedDBService][getConversationsStarred]");
    const response = new Subject<Conversation[]>();

    this.idbContext().then(db => {
      const tx = db.transaction(CONVERSATIONS_DB_STORE, "readonly");

      // TODO: to replace with DB specific query instead of array filtering
      tx.store.getAll().then(convs => {
        convs = convs.filter(conv => {
          return conv.f && conv.f.includes("f"); // (f)lagged
        });
        convs = this.sortObjects(convs);
        console.log("[IndexedDBService][getConversationsStarred] convs", convs);
        response.next(convs);
      }).catch(error => {
        console.error("[IndexedDBService][getConversationsStarred] error", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getConversationsSent(): Observable<Conversation[]> {
    console.log("[IndexedDBService][getConversationsSent]");
    const response = new Subject<Conversation[]>();

    this.idbContext().then(db => {
      const tx = db.transaction(CONVERSATIONS_DB_STORE, "readonly");

      // TODO: to replace with DB specific query instead of array filtering
      tx.store.getAll().then(convs => {
        convs = convs.filter(conv => {
          return conv.l
          && conv.l === "5"
          && conv.folders
          && conv.folders.indexOf("in:trash") === -1; // (s)ent by me and not deleted;
        });
        convs = this.sortObjects(convs);
        console.log("[IndexedDBService][getConversationsSent] Ok", convs);
        response.next(convs);
      }).catch(error => {
        console.error("[IndexedDBService][getConversationsSent] error", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  updateConversations(convs: Conversation[]): Observable<any> {
    const t1 = performance.now();
    console.log("[IndexedDBService][updateConversations]", convs);

    const response = new Subject<any>();
    const updateConvIds = convs.filter(v => !!v).map(c => c.id);

    if (updateConvIds.length > 0) {
      const sortedUpdateIds = updateConvIds.sort();
      const keyRangeValue = IDBKeyRange.bound(sortedUpdateIds[0], sortedUpdateIds[sortedUpdateIds.length - 1]);
      console.log("[IndexedDBService][updateConversations]", convs, sortedUpdateIds[0], sortedUpdateIds[sortedUpdateIds.length - 1]);
      this.idbContext().then(async db => {
        const tx = db.transaction(CONVERSATIONS_DB_STORE, "readwrite");
        const store = tx.objectStore(CONVERSATIONS_DB_STORE);
        const dbConversationIds = await store.getAllKeys(keyRangeValue);
        const existingDBconvs = await store.getAll(keyRangeValue);
        const sortedConvs = _.orderBy(convs, "id", "asc");
        let convs2process = {};

        console.log("sortedConvs: ", sortedConvs);
        sortedConvs.forEach(cnv => {
          if (dbConversationIds.indexOf(cnv.id) > -1) {
            convs2process[cnv.id] = cnv;
          }
        });

        existingDBconvs.forEach(econv => {
          if (updateConvIds.indexOf(econv.id) > -1) {
            let convToStore = {
              ...econv,
              ...convs2process[econv.id]
            };
            let folders = [];
            let mids = [];
            if (convToStore.m && convToStore.m.length > 0) {
              convToStore.m.forEach(msg => {
                folders.push(this.getQueryByFolderId(+msg.l));
                mids.push(msg.id);
              });
            }
            convToStore.folders = folders;
            convToStore.mids = mids;
            tx.store.put(convToStore);
          }
        });

          tx.done.then(() => {
            console.log("[IndexedDBService][updateConversations] success");
            const t5 = performance.now();
            console.log(`[IndexedDB][updateConversationsPerformance] took ${t5 - t1} milliseconds.`, convs);

            response.next(true);
          }).catch(error => {
            console.error("[IndexedDBService][updateConversations]", error);
            response.error(error);
          });

      });

    } else {
      setTimeout(() => {
        response.next(true);
      }, 3);
    }
    return response.asObservable().pipe(take(1));
  }

  updateConversationsAsStarred(convs: Conversation[], isStared: boolean): Observable<any> {
    return this.updateConversations(convs);
  }

  addMessageToConversation(convId: string, message: Message): Observable<any> {
    console.info(`[IndexedDBService][addMessageToConversation] convId = ${convId}, message = ${JSON.stringify(message)}`);

    const response = new Subject<any>();

    this.getConversationById(convId).subscribe((conv) => {
      if (conv) {
        conv.m = conv.m || [];
        conv.m.unshift(message);
        conv.n = conv.m.length.toString();

        this.updateConversations([conv]).subscribe((result) => {
          console.log("[IndexedDBService][addMessageToConversation] success");
          response.next(true);
        }, (error) => {
          console.error("[IndexedDBService][addMessageToConversation]", error);
          response.error(error);
        });
      } else {
        response.next(true);
      }
    });

    return response.asObservable().pipe(take(1));
  }

  moveConversationsBetweenFolders(ids: string[], newFolder: string): Observable<any> {
    console.log("[IndexedDBService][moveConversationsBetweenFolders]", ids, newFolder);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(CONVERSATIONS_DB_STORE, "readwrite");

      ids.forEach(id => {
        tx.store.get(IDBKeyRange.only(id)).then(result => {
          if (result) {
            const conv = { ...result };
            console.log("[IndexedDBService][moveConversationsBetweenFolders] conv.folders", conv.folders);

            conv.folders = [newFolder];

            tx.store.put(conv);
          } else {
            console.warn("[IndexedDBService][moveConversationsBetweenFolders] skip, no conv found", id);
          }
        });
      });

      tx.done.then(() => {
        console.log("[IndexedDBService][moveConversationsBetweenFolders] success");
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][moveConversationsBetweenFolders]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  deleteConversation(conversationId: string, keepMessages?: boolean): Observable<any> {
    console.log(`[IndexedDBService][deleteConversation]`, conversationId, keepMessages);

    return this.deleteConversations([conversationId], keepMessages);
  }

  deleteConversations(ids: string[], keepMessages?: boolean): Observable<any> {
    console.log(`[IndexedDBService][deleteConversations]`, ids, keepMessages);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(CONVERSATIONS_DB_STORE, "readwrite");

      ids.forEach(id => {
        tx.store.delete(id);
        if (!keepMessages) {
          this.deleteMessagesByConversation(id).subscribe();
        }
      });

      tx.done.then(() => {
        console.log("[IndexedDBService][deleteConversations] success");
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][deleteConversations]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  ///

  addPendingOperation(objectId: string, op: string, request: any): Observable<any> {
    console.log("[IndexedDBService][addPendingOperation]", objectId, op, request);

    // in:inbox
    // in:drafts
    // in:sent
    // is:flagged or ( in:trash and is:flagged )
    // in:junk
    // in:trash

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(PENDING_OPERATIONS_DB_STORE, "readwrite");
      let key = this.getPendingOperationKey(objectId, op);
      if (op === "tag" || op === "!tag") {
        key += "_" + new Date().getTime() + (Math.ceil(Math.random() * 1000).toString().replace(".", "_"));
      }
      tx.store.put({
        id: key, // for a purpose to use when delete
        objectId,
        op,
        request,
      });
      console.log("[IndexedDBService][addPendingOperation]=====", objectId, op, request, key);
      // delete prev draft if any
      if (op === "sendEmail") {
        this.deletePendingOperation(this.getPendingOperationKey(objectId, "saveDraft")).subscribe();
      }

      tx.done.then(() => {
        console.log("[IndexedDBService][addPendingOperation] success");
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][addPendingOperation]", error);
        response.error(error);
      });

    });

    return response.asObservable().pipe(take(1));
  }

  getAllPendingOperations(): Observable<any> {
    console.log("[IndexedDBService][getAllPendingOperations]");
    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(PENDING_OPERATIONS_DB_STORE, "readonly");

      tx.store.getAll().then(res => {
        console.log("[IndexedDBService][getAllPendingOperations] success: ", res);
        response.next(res);
      }).catch(error => {
        console.error("[IndexedDBService][getAllPendingOperations]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  deletePendingOperation(key: string): Observable<any> {
    return this.deletePendingOperations([key]);
  }

  deletePendingOperations(keys: string[]): Observable<any> {
    console.log("[IndexedDBService][deletePendingOperations]", keys);
    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(PENDING_OPERATIONS_DB_STORE, "readwrite");

      keys.forEach(key => {
        tx.store.delete(key);
      });

      tx.done.then(() => {
        console.log("[IndexedDBService][deletePendingOperations] success");
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][deletePendingOperations]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  deleteAllPendingOperations(): Observable<any> {
    console.log("[IndexedDBService][deleteAllPendingOperations]");

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(PENDING_OPERATIONS_DB_STORE, "readwrite");

      tx.store.clear();

      tx.done.then(() => {
        console.log("[IndexedDBService][deleteAllPendingOperations] success");
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][deleteAllPendingOperations]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getPendingOperationKey(objectId: string, op: string) {
    return `${objectId}_${op}`;
  }

  ///

  createFlatFolder(folders: MailFolder[]): void {
    for (let i = 0; i < folders.length; i++) {
      const folder = folders[i];
      this.flatFolders.push(folder);
      if (folder.children) {
        this.createFlatFolder(folder.children);
      }
    }
  }

  createFlatCalFolder(folders: any[]): void {
    for (let i = 0; i < folders.length; i++) {
      const folder = folders[i];
      this.flatCalFolders.push(folder);
      if (folder.children) {
        this.createFlatCalFolder(folder.children);
      }
    }
  }


  addFolders(folders: MailFolder[]): Observable<any> {
    this.flatFolders = [];
    console.log("[IndexedDBService][addFolders]", folders);
    this.createFlatFolder(folders);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(FOLDERS_DB_STORE, "readwrite");

      tx.store.clear().then(res => {
        this.flatFolders.forEach(f => {
          // console.log("[IndexedDBServiceaddFolders] putting: ", f.absFolderPath);
          tx.store.put(f);
        });
      });

      tx.done.then(() => {
        console.log("[IndexedDBService][addFolders] success");
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][addFolders]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  addCalendarFolders(folders: any[]): Observable<any> {
    this.flatCalFolders = [];
    console.log("[IndexedDBService][addCalendarFolders]", folders);
    this.createFlatCalFolder(folders);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(CALENDAR_FOLDERS_DB_STORE, "readwrite");

      tx.store.clear().then(res => {
        this.flatCalFolders.forEach(f => {
          console.log("[IndexedDBServiceaddFolders] putting: ", f.absFolderPath);
          tx.store.put(f);
        });
      });

      tx.done.then(() => {
        console.log("[IndexedDBService][addFolders] success");
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][addFolders]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }


  getFolders(): Observable<MailFolder[]> {
    console.log("[IndexedDBService][getFolders]");

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(FOLDERS_DB_STORE, "readwrite");

      tx.store.getAll().then(res => {
        response.next(res);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getCalendarFolders(): Observable<any[]> {
    console.log("[IndexedDBService][getFolders]");

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(CALENDAR_FOLDERS_DB_STORE, "readwrite");

      tx.store.getAll().then(res => {
        response.next(res);
      });
    });

    return response.asObservable().pipe(take(1));
  }
  ///

  getSignatures(): Observable<Signature[]> {
    const response = new Subject<Signature[]>();

    this.idbContext().then(db => {
      db.getAll(SIGNATURES_DB_STORE).then(signatures => {
        response.next(signatures);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  setSignatures(signatures): Observable<any> {
    console.log("[IndexedDBService][setSignatures]", signatures);
    console.log("[IndexedDBService][setSignatures] length", signatures.length);
    const response = new Subject<any>();
    if (!!signatures.length) {
      this.idbContext().then(db => {
        const tx = db.transaction(SIGNATURES_DB_STORE, "readwrite");
        signatures.forEach(sign => {
          tx.store.put({
            ...sign
          });
        });

        tx.done.then(() => {
          console.log("[IndexedDBService][setSignatures] success");
          response.next(true);
        }).catch(error => {
          console.error("[IndexedDBService][setSignatures]", error);
          response.error(error);
        });
      });
    } else {
      console.log("no signatures");
      response.next(true);
    }

    return response.asObservable().pipe(take(1));
  }

  deleteSignatures(ids: string[]): Observable<any> {
    console.log("[IndexedDBService][deleteSignatures]", ids);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(SIGNATURES_DB_STORE, "readwrite");
      ids.forEach((id) => {
        tx.store.delete(id);
      });

      tx.done.then(() => {
        console.log("[IndexedDBService][deleteSignatures] success");
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][deleteSignatures]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  createSignature(signature: Signature): Observable<any> {
    console.log("[IndexedDBService][createSignature]", signature);

    const response = new Subject<Signature>();

    this.idbContext().then(db => {
      const tx = db.transaction(SIGNATURES_DB_STORE, "readwrite");

      tx.store.add(signature);

      tx.done.then(() => {
        console.log("[IndexedDBService][createSignature] success");
        response.next(signature);
      }).catch(error => {
        console.error("[IndexedDBService][createSignature]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  updateSignature(signature: Signature): Observable<any> {
    console.log("[IndexedDBService][updateSignature]", signature);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(SIGNATURES_DB_STORE, "readwrite");

      tx.store.put(signature);

      tx.done.then(() => {
        console.log("[IndexedDBService][updateSignature] success");
        response.next(signature);
      }).catch(error => {
        console.error("[IndexedDBService][updateSignature]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  ///

  addUsers(users): Observable<any> {
    console.log("[IndexedDBService][addUsers]", users);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(USERS_DB_STORE, "readwrite");
      users.forEach(user => {
        tx.store.put({
          ...user
        });
      });

      tx.done.then(() => {
        console.log("[IndexedDBService][addUsers] success");
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][addUsers]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getUsers(mailPart, fullMail?: boolean): Observable<any[]> {
    console.log("[IndexedDBService][getUsers]", mailPart);

    const response = new Subject<any[]>();

    let results = [];
    this.idbContext().then(db => {
      db.getAll(USERS_DB_STORE).then(users => {
        let filteredUsers = [];
        if (fullMail) {
          filteredUsers = users.filter(u => !!u.email && u.email === mailPart || !!u.emails && u.emails.find(v => v.email === mailPart));
        } else {
          filteredUsers = users.filter(u => !!u.email && ((u.email.split("@")[0].toLowerCase().indexOf(mailPart.toLowerCase()) > -1) || (u.email.split("@")[0].toLowerCase().indexOf(mailPart.toLowerCase()) > -1)));
        }
        if (filteredUsers.length > 0) {
          for (let i = 0; i < filteredUsers.length; i++) {
            if (!filteredUsers[i].name) {
              if (!!filteredUsers[i].first_name && !!filteredUsers[i].last_name) {
                filteredUsers[i].name = filteredUsers[i].first_name + " " + filteredUsers[i].last_name;
              }
            }
          }
        }
        response.next(filteredUsers);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getUsersOld(mailPart): Observable<any[]> {
    console.log("[IndexedDBService][getUsers]", mailPart);

    const response = new Subject<any[]>();

    let results = [];

    this.idbContext().then(async db => {
      const tx = db.transaction(USERS_DB_STORE, "readwrite");
      const store = tx.objectStore(USERS_DB_STORE);

      let canAdvaneCursor = true;

      await store.openCursor().then(async (cursor) => {
        while (canAdvaneCursor) {
          // console.log("getUsers - processing ", cursor.primaryKey, cursor.value);
          if ((!!cursor.value.email && ((cursor.value.email.split("@")[0].toLowerCase().indexOf(mailPart.toLowerCase()) > -1))) || (!!cursor.value.emails && (cursor.value.emails.filter(mail => mail?.email.split("@")[0].toLowerCase().indexOf(mailPart.toLowerCase()) > -1).length > 0))) {
            let c = cursor.value;
            try {
              if (!c.name) {
                c.name = !!c.middle_name ? c.first_name + " " + c.middle_name + " " + c.last_name : c.first_name + " " + c.last_name;
              }
            } catch (error) {

            }
            results.push(c);
          }

          try {
            await cursor.continue();
          } catch (error) {
            // console.log("moveMessagesBetweenFolders - error advancing cursor: ", error);
            canAdvaneCursor = false;
          }
        }
      });

      tx.done.then(() => {
        console.log("getUsers - results:  ", results);
        response.next(results);
      }).catch(error => {
        console.error("[IndexedDBService][moveMessagesBetweenFolders]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  ///

  private sortObjects(objects) {
    objects.sort((o1, o2) => o2.d - o1.d);
    return objects;
  }

  ///

  addTags(tags: any[]): Observable<any> {
    const response = new Subject<any>();

    console.log("[IndexedDBService][addTags]", tags);

    this.idbContext().then(db => {
      const tx = db.transaction(TAGS_DB_STORE, "readwrite");
      tags.forEach(tag => {
        tx.store.put({
          ...tag
        });
      });

      tx.done.then(() => {
        console.log("[IndexedDBService][addTags] success");
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][addTags]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getTags(): Observable<any[]> {
    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(TAGS_DB_STORE, "readwrite");

      tx.store.getAll().then(res => {
        console.log("[IndexedDBService][getTags] result", res);
        response.next(res);
      }).catch(error => {
        console.error("[IndexedDBService][getTags] error", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getCurrentDBUser(): Observable<any> {
    console.log("[IndexedDBService][getCurrentDBUser]");

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(CURRENT_USER_DB_STORE, "readwrite");

      tx.store.getAll().then(res => {
        response.next(res);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  setCurrentDBUser(email): Observable<any> {
    console.log("[IndexedDBService][setCurrentDBUser]", email);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(CURRENT_USER_DB_STORE, "readwrite");

      const request = tx.store.put({email: email});

      tx.done.then(() => {
        console.log("[IndexedDBService][setCurrentDBUser] success");
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][setCurrentDBUser]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  storeAvatar(avatarB64Url: string, email: string) {
    const response = new Subject<any>();
      this.idbContext().then(db => {
        const tx = db.transaction(AVATAR_DB_STORE, "readwrite");
        tx.store.put({ id: email, data: avatarB64Url, updated: Date.now() });
        tx.done.then(() => {
          response.next(true);
        }).catch(error => {
          console.error("[IndexedDBService][storeAvatar]", error);
          response.error(error);
        });
      });

    return response.asObservable().pipe(take(1));
  }

  deleteAvatar(email: string): Observable<any> {
    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(AVATAR_DB_STORE, "readwrite");
      tx.store.delete(email);
      tx.done.then(() => {
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][deleteAvatar]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getAvatarByEmail(email: string): Observable<any> {
    const response = new Subject<any>();
    this.idbContext().then(db => {
      const tx = db.transaction(AVATAR_DB_STORE);
      const store = tx.objectStore(AVATAR_DB_STORE);
      store.get(IDBKeyRange.only(email)).then(data => {
        response.next(data);
      }).catch(error => {
        console.error("[IndexedDBService][getAvatarByBare] error", error);
        response.next(null);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  fetchAllAvatarFromDatabase(): Observable<any[]> {
    const response = new Subject<any>();
    this.idbContext().then(db => {
      const tx = db.transaction(AVATAR_DB_STORE);
      tx.store.getAll().then(avatars => {
        console.log("[IndexedDBService][fetchAllAvatarFromDatabase] Ok", avatars);
        response.next(avatars);
      }).catch(error => {
        console.error("[IndexedDBService][fetchAllAvatarFromDatabase] error", error);
        response.next([]);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  getDatabaseVersion() {
    return this.dbVersion;
  }

  performDatabaseMigration(): Observable<any> {
    console.log("[IndexedDBService][performDatabaseMigration]");
    const t1 = performance.now();

    const response = new Subject<any>();

    if (this.dbVersion >= 11) {
      this.idbContext().then(async db => {
        const tx = db.transaction(CONVERSATIONS_DB_STORE, "readwrite");
        const store = tx.objectStore(CONVERSATIONS_DB_STORE);
        const indexByMid = store.index(INDEX_BY_MID);
        const version11keys = await indexByMid.getAllKeys();

        if (version11keys.length === 0) {
          let updated = 0;
          store.openCursor(null, "next").then(async (cursor) => {
            let canAdvance = true;
            while (canAdvance && !!cursor && !!cursor.value) {
              const existingConv = cursor.value;
              const convToStore = { ...existingConv };
              let mids = [];
              if (!!existingConv.m && existingConv.m.length > 0) {
                existingConv.m.forEach(msg => {
                  mids.push(msg.id);
                });
                convToStore.mids = mids;
                console.log("[IndexedDBService][performDatabaseMigration] updating: ", convToStore.id, convToStore.mids, convToStore);
                await cursor.update(convToStore);
              }
              try {
                updated++;
                await cursor.continue();
              } catch (error) {
                canAdvance = false;
              }
            }
          });
          tx.done.then(() => {
            console.log("[IndexedDBService][performDatabaseMigration] success");
            const t2 = performance.now();
            console.log(`[PERFORMANCE][IndexedDBService] performDatabaseMigration for update: ${updated} conversations took ${t2 - t1} milliseconds.`);
            response.next(true);
          }).catch(error => {
            console.error("[IndexedDBService][performDatabaseMigration]", error);
            response.error(error);
          });

        } else {
          response.next(true);
        }
      });
    } else {
      setTimeout(() => {
        response.next(true);
      }, 10);
    }

    return response.asObservable().pipe(take(1));
  }

  updateConvMessagesinDB(messages: Array<Message>, toFolderId: any): Observable<any> {
    const response = new Subject<any>();
    console.log("[IndexedDBService][updateConvMessagesinDB] messages: ", toFolderId, messages);

    const messageIds = messages.filter(v => !!v).map(m => m.id);
    const _sortedMessageIds = messageIds.sort();
    const minId = _sortedMessageIds[0];
    const maxId = _sortedMessageIds[_sortedMessageIds.length - 1];
    // const keyRangeValue = IDBKeyRange.bound(minId, maxId);
    const sortedMessages = _.orderBy(messages, "id", "asc");

    this.idbContext().then(async db => {
      const tx = db.transaction(CONVERSATIONS_DB_STORE, "readwrite");
      const store = tx.objectStore(CONVERSATIONS_DB_STORE);
      const indexByMid = store.index(INDEX_BY_MID);
      const dbMessageIds = await indexByMid.getAllKeys();

      let messages2process = [];
      sortedMessages.forEach(smsg => {
        if (dbMessageIds.indexOf(smsg.id) > -1) {
          messages2process.push(smsg);
        }
      });

      if (messages2process.length === 0) {
        response.next(true);
      } else {

        indexByMid.openCursor(messages2process[0].id, "next").then(async (cursor) => {
          if (!cursor) {
            console.error("[IndexedDBService][updateConvMessagesinDB] processing - no cursor ");
          }


          let i = 0;
          let canAdvance = true;
          while (canAdvance && (i < messages2process.length) && !!cursor) {
            const existingConv = cursor.value;
            let existingMessages = existingConv.m;
            let newMessages = [];
            let newFolders = [];
            let newMids = [];
            if (existingMessages.length > 0) {
              console.log("[IndexedDBService][updateConvMessagesinDB] processing existingConv, message ", existingConv, sortedMessages[i]);
              existingMessages.forEach(msg => {
                if (msg.id === sortedMessages[i].id) {
                  newMessages.push(sortedMessages[i]);
                  newFolders.push(this.getQueryByFolderId(+sortedMessages[i].l));
                  newMids.push(msg.id);
                } else {
                  newMessages.push(msg);
                  newFolders.push(this.getQueryByFolderId(+msg.l));
                  newMids.push(msg.id);
                }
              });
              let convToStore = {
                ...existingConv,
                folders: newFolders,
                mids: newMids
              };
              await cursor.update(convToStore);
            }

            i++;
            try {
              if (i < sortedMessages.length) {
                cursor.continue(messages2process[i].id);
              }
            } catch (error) {
              canAdvance = false;
            }
          }
        });

        tx.done.then(() => {
          console.log("[IndexedDBService][updateConvMessagesinDB] success");
          const t2 = performance.now();
          // console.log(`[PERFORMANCE][IndexedDBService] indexedDBupdateMessages for update: ${messages.length} messages took ${t2 - t1} milliseconds.`);

          response.next(true);
        }).catch(error => {
          console.error("[IndexedDBService][updateConvMessagesinDB]", error);
          response.error(error);
        });
      }

    });

    return response.asObservable().pipe(take(1));
  }

  getInvalidMsgIds() {
    return this.invalidMsgIds;
  }

  clearInvalidMessageIds() {
    this.invalidMsgIds = [];
    return true;
  }

  deleteAllMessagesFromDB(): Observable<any> {
    console.log("[IndexedDBService][deleteAllMessagesFromDB]");

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(MESSAGES_DB_STORE, "readwrite");

      tx.store.clear();

      tx.done.then(() => {
        console.log("[IndexedDBService][deleteAllMessagesFromDB] success");
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][deleteAllMessagesFromDB]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  deleteAllConvsFromDB(): Observable<any> {
    console.log("[IndexedDBService][deleteAllMessagesFromDB]");

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(MESSAGES_DB_STORE, "readwrite");

      tx.store.clear();

      tx.done.then(() => {
        console.log("[IndexedDBService][deleteAllMessagesFromDB] success");
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][deleteAllMessagesFromDB]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  createOrUpdateContacts(contacts: any): Observable<any> {
    console.log("[IndexedDBService][createOrUpdateContacts] ", contacts);

    const response = new Subject<any>();

    this.idbContext().then(async db => {
      const tx = db.transaction(CONTACT_DB_STORE, "readwrite");
      const store = tx.objectStore(CONTACT_DB_STORE);

      await store.clear();
      contacts.forEach(contact => {
        tx.store.put(contact);
      });

      tx.done.then(() => {
        console.log("[IndexedDBService][createOrUpdateContacts] success");
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][createOrUpdateContacts]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  searchContacts(searchText: string): Observable<any> {
    const response = new Subject<any>();
    let results = [];

    this.idbContext().then(async db => {
      const tx = db.transaction(CONTACT_DB_STORE, "readwrite");
      const store = tx.objectStore(CONTACT_DB_STORE);

      let canAdvanceCursor = true;

      console.log("this.dbName, this.dbVersion", this.dbName, this.dbVersion);
      await store.openCursor().then(async (cursor) => {
        while (canAdvanceCursor) {
          console.log("getUsers - processing ", cursor.primaryKey, cursor.value);

          if (cursor.value !== undefined && cursor.value !== null) {
            // Perform the search only if cursor.value is defined and not null
            if (
              !!cursor.value.email &&
              ((cursor.value.email.split("@")[0].toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
                (cursor.value.fileAsStr.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
                (cursor.value._attrs?.firstName?.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
                (cursor.value._attrs?.fullName?.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ||
                (cursor.value._attrs?.lastName?.toLowerCase().indexOf(searchText.toLowerCase()) > -1))
            ) {
              results.push(cursor.value);
            }
          }

          try {
            await cursor.continue();
          } catch (error) {
            canAdvanceCursor = false;
          }
        }
      });

      tx.done.then(() => {
        console.log("searchContacts - results:  ", results);
        response.next(results);
        response.complete(); // Complete the Subject after emitting results
      }).catch(error => {
        console.error("[IndexedDBService][searchContacts]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  searchContactsByMail(email: string): Observable<any> {
    const response = new Subject<any>();

    this.idbContext().then(async db => {
      const tx = db.transaction(CONTACT_DB_STORE, "readwrite");
      const store = tx.objectStore(CONTACT_DB_STORE);
      db.getAllFromIndex(CONTACT_DB_STORE, INDEX_BY_EMAIL, email).then(contacts => {
        response.next(contacts);
      }).catch(error => {
        response.next([]);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  searchContactsCalendar(searchText: string): Observable<any> {
    const response = new Subject<any>();

    let results = [];

    this.idbContext().then(async db => {
      const tx = db.transaction(CONTACT_DB_STORE, "readwrite");
      const store = tx.objectStore(CONTACT_DB_STORE);

      console.log("this.dbName, this.dbVersion", this.dbName, this.dbVersion);
      tx.store.getAll().then(contacts => {

        results = contacts.filter(contact => (!!contact.email &&
          ((contact.email.split("@")[0].toLowerCase().startsWith(searchText.toLowerCase()))
            || (contact.fileAsStr.toLowerCase().startsWith(searchText.toLowerCase()))
            || (contact._attrs?.firstName?.toLowerCase().startsWith(searchText.toLowerCase()))
            || (contact._attrs?.fullName?.toLowerCase().startsWith(searchText.toLowerCase()))
            || (contact._attrs?.lastName?.toLowerCase().startsWith(searchText.toLowerCase())))));


          response.next(results);
      }).catch(error => {
        response.next([]);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  fetchAllUsersFromDatabase(email: string): Observable<string> {
    const response = new Subject<any>();
    this.idbContext().then(db => {
      const tx = db.transaction(CONTACT_DB_STORE);
      tx.store.getAll().then(contacts => {
        const foundObject = contacts.find(obj => obj.email === email);
        if (foundObject) {
          response.next(foundObject.fileAsStr);
        }
      }).catch(error => {
        response.next([]);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  addAttachment(attachment: any) {
    const response = new Subject<any>();
    console.log("[IndexedDBService][addAttachment] raw", attachment);

      this.idbContext().then(db => {
        const tx = db.transaction(ATTACHMENTS_DB_STORE, "readwrite");

        const nts = Date.now();
        const attachmentToStore = { ... attachment };
        attachmentToStore["timestamp"] = nts;
        console.log("[IndexedDBService][addAttachment] processed ", attachmentToStore);
        tx.store.put(attachmentToStore);

        tx.done.then(() => {
          console.log("[IndexedDBService][addAttachment]", "OK");
          response.next(true);
        }).catch(error => {
          console.error("[IndexedDBService][addAttachment]", error);
          response.error(error);
        });
      });

    return response.asObservable().pipe(take(1));
  }

  fetchAttachmentById(id): Observable<any> {
    const response = new Subject<any>();
    this.idbContext().then(db => {
      const tx = db.transaction(ATTACHMENTS_DB_STORE);
      const store = tx.objectStore(ATTACHMENTS_DB_STORE);
      tx.store.get(IDBKeyRange.only(id)).then(attachment => {
        console.log("[IndexedDBService][fetchAttachmentById] Ok", attachment);
        response.next(attachment);
      }).catch(error => {
        console.error("[IndexedDBService][fetchAttachmentById] error", error);
        response.next({});
      });
    });
    return response.asObservable().pipe(take(1));
  }

  fetchAttachmentsBefore(ts): Observable<any> {
    const response = new Subject<any>();
    this.idbContext().then(db => {

      db.getAllFromIndex(ATTACHMENTS_DB_STORE, INDEX_BY_TIMESTAMP, IDBKeyRange.upperBound(ts)).then(attachments => {
        console.log("[IndexedDBService][fetchAttachmentsBefore] Ok", attachments);
        response.next(attachments);
      }).catch(error => {
        console.error("[IndexedDBService][fetchAttachmentsBefore] error", error);
        response.next({});
      });
    });
    return response.asObservable().pipe(take(1));
  }


  deleteAttachment(ids: string[]): Observable<any> {
    console.log("[IndexedDBService][deletettachment]", ids);
    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(ATTACHMENTS_DB_STORE, "readwrite");
      ids.forEach(id => {
        tx.store.delete(id);
      });

      tx.done.then(() => {
        console.log("[IndexedDBService][deletettachment]", "OK");
        response.next(true);
      }).catch(error => {
        console.error("[IndexedDBService][deletePendingAttachment]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

}
