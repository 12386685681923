
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-preferences-component">
          <mat-list>
              <mat-list-item id="mobile_sidebar_preference_main_pref" (click) = "navigate('general')">
                <mat-icon>settings</mat-icon>
                <span>{{'GENERAL'| translate}}</span>
              </mat-list-item>
              <ng-container *ngIf="!calendarOnly">
                <mat-list-item id="mobile_sidebar_preference_accounts_pref" (click) = "navigate('accounts')">
                  <mat-icon>account_box</mat-icon>
                  <span>{{'ACCOUNTS'| translate}}</span>
              </mat-list-item>
              <mat-list-item id="mobile_sidebar_preference_mail_pref" (click) = "navigate('mail')">
                  <mat-icon>email</mat-icon>
                  <span>{{'MAIL_PREFERENCES'| translate}}</span>
                </mat-list-item>
              <mat-list-item id="mobile_sidebar_preference_appearance" (click)="changeAppearance()" *ngIf="!themeChangeRestricted">
                <mat-icon>brush</mat-icon>
                <span>{{'APPEARANCE'| translate}}</span>
              </mat-list-item>
              <mat-list-item id="mobile_sidebar_preference_filters" (click) = "navigate('filters')" *ngIf="isMailFilterFeatureEnabled">
                  <img [src]="filterIcon">
                    <span>{{'FILTERS'| translate}}</span>
              </mat-list-item>
              <mat-list-item id="mobile_sidebar_preference_signatures" (click) = "navigate('signatures')">
                  <img [src]="penIcon">
                  <span>{{'SIGNATURES'| translate}}</span>
            </mat-list-item>
            <mat-list-item id="mobile_sidebar_preference_out_of_office" (click) = "navigate('out-of-office')" *ngIf="isOutOfOfficeFeatureEnabled">
                <mat-icon>access_time</mat-icon>
                <span>{{'OUT_OF_OFFICE'| translate}}</span>
            </mat-list-item>
            <mat-list-item id="mobile_sidebar_preference_resync" (click) = "startMobileResync()">
                <mat-icon>sync</mat-icon>
                <span>{{'RESYNC_LBL'| translate}}</span>
            </mat-list-item>
              </ng-container>

            <mat-list-item id="mobile_sidebar_preference_notifications" (click) = "navigate('notifications')">
                <mat-icon>notifications</mat-icon>
                <span>{{'APPOINTMENT_NOTIFICATION'| translate}}</span>
            </mat-list-item>

            <ng-container *ngIf="!calendarOnly">
            <mat-list-item id="mobile_sidebar_preference_import-export" (click) = "navigate('import-export')">
                <mat-icon>import_export</mat-icon>
                <span>{{'IMPORT_EXPORT'| translate}}</span>
            </mat-list-item>
            <mat-list-item id="mobile_sidebar_preference_sharing" (click) = "navigate('sharing')" *ngIf="isSharingFeatureEnabled">
              <mat-icon>share</mat-icon>
              <span>{{'PREFRENCES_SHARING'| translate}}</span>
            </mat-list-item>
            <mat-list-item id="mobile_sidebar_preference_trusted_Address" (click) = "navigate('trustee_address')" *ngIf="!hideTrustedAddressesFromSettings">
              <mat-icon>security</mat-icon>
              <span>{{'TRUSTEE_ADDRESS'| translate}}</span>
            </mat-list-item>
            <mat-list-item id="mobile_sidebar_preference_contact-settings" (click) = "navigate('contact-settings')" *ngIf="isContactSettingEnabled">
                <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-contacts"></mat-icon>
                <span>{{'PREFERENCE_CONTACT_LBL'| translate}}</span>
            </mat-list-item>
            <mat-list-item id="mobile_sidebar_preference_briefcase_notification" (click) = "navigate('briefcase-notification')" *ngIf="isBriefcaseNotificationEnabled">
                <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-briefcase"></mat-icon>
                <span>{{'BRIECASE_NOTIFICATIONS_PREFERENCES'| translate}}</span>
              </mat-list-item>
            </ng-container>
            <mat-list-item id="mobile_sidebar_preference_calendar" (click) = "navigate('calendar-settings')">
              <mat-icon class="mdi-16px" fontSet="mdi" fontIcon="mdi-calendar-blank"></mat-icon>
              <span>{{'CALENDAR_PREF_LBL'| translate}}</span>
            </mat-list-item>
          </mat-list>
</div>
