
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Action } from "src/app/actions";
import { Appointment } from "src/app/common/models/appoinment.model";
import { CalendarFolder, CalendarAppointment } from "src/app/common/models/calendar.model";
import { CalendarActionTypes, CalendarAppointmentActionTypes } from "../actions/calendar.actions";

export interface CalendarState extends EntityState<Appointment> {
  isLoading: boolean;
  isLoaded: boolean;
  event: CalendarAppointment;
  events: CalendarAppointment[];
  updatedEvent: CalendarAppointment;
  removedEvent: CalendarAppointment;
}

export interface CalendarFolderState extends EntityState<CalendarFolder> {
  calendarFolders: CalendarFolder[];
}

export interface CalendarEventState extends EntityState<CalendarAppointment> {
  isLoading: boolean;
  isLoaded: boolean;
  event: CalendarAppointment;
  deletedAppointments: CalendarAppointment[];
}

export const calendarAdapter: EntityAdapter<Appointment> = createEntityAdapter<Appointment>({
  selectId: (appointment: Appointment) => appointment.id
});

export const calendarEventAdapter: EntityAdapter<CalendarAppointment> = createEntityAdapter<CalendarAppointment>({
  selectId: (event: CalendarAppointment) => event.eventId
});

export const calendarFolderAdapter: EntityAdapter<CalendarFolder> = createEntityAdapter<CalendarFolder>({
  selectId: (folder: CalendarFolder) => folder.id
});

export const initialFolderState: CalendarFolderState = calendarFolderAdapter.getInitialState({
  calendarFolders: []
});


export const initialState: CalendarState = calendarAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  event: <CalendarAppointment>{},
  events: [],
  updatedEvent: <CalendarAppointment>{},
  removedEvent: <CalendarAppointment>{},
});

export const initialEventState: CalendarEventState = calendarEventAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  event: <CalendarAppointment>{},
  deletedAppointments: <CalendarAppointment[]>[]
});

export function referenceReducer(
  state = initialState,
  action: Action
): CalendarState {
  switch (action.type) {

    case CalendarActionTypes.SET_CALENDAR_APPOINTMENTS: {
      return {
        ...state,
        events: action.payload
      };
    }

    case CalendarActionTypes.SET_CALENDAR_APPOINTMENT: {
      return {
        ...state,
        event: action.payload
      };
    }

    case CalendarActionTypes.REMOVE_CALENDAR_APPOINTMENT: {
      return {
        ...state,
        removedEvent: action.payload
      };
    }

    case CalendarActionTypes.UPDATE_CALENDAR_APPOINTMENT: {
      return {
        ...state,
        updatedEvent: action.payload
      };
    }

    case CalendarActionTypes.RESET_CALENDAR_STATE: {
      return calendarAdapter.removeAll(state);
    }

    default: {
      return state;
    }
  }
}

export function calendarEventReducer(state = initialEventState, action: Action): CalendarEventState {
  switch (action.type) {

    case CalendarAppointmentActionTypes.LOAD_CALENDAR_APPOINTMENTS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case CalendarAppointmentActionTypes.LOAD_CALENDAR_APPOINTMENTS_SUCCESS: {
      return calendarEventAdapter.addAll(action.payload.appointments, {
        ...state,
        isloading: false,
        isLoaded: true
      });
    }

    case CalendarAppointmentActionTypes.LOAD_CALENDAR_APPOINTMENTS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false
      };
    }

    case CalendarAppointmentActionTypes.LOAD_MORE_CALENDAR_APPOINTMENTS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case CalendarAppointmentActionTypes.LOAD_MORE_CALENDAR_APPOINTMENTS_SUCCESS: {
      return calendarEventAdapter.addMany(action.payload.appointments, {
        ...state,
        isloading: false,
        isLoaded: true
      });
    }

    case CalendarAppointmentActionTypes.LOAD_MORE_CALENDAR_APPOINTMENTS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false
      };
    }

    case CalendarAppointmentActionTypes.CREATE_CALENDAR_APPOINTMENT: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CalendarAppointmentActionTypes.CREATE_CALENDAR_APPOINTMENT_SUCCESS: {
      return calendarEventAdapter.addOne(action.payload.appointment, {
        ...state,
        isLoading: false,
        isloaded: true
      });
    }

    case CalendarAppointmentActionTypes.CREATE_CALENDAR_APPOINTMENT_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false
      };
    }

    case CalendarAppointmentActionTypes.UPDATE_CALENDAR_APPOINTMENT: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CalendarAppointmentActionTypes.UPDATE_CALENDAR_APPOINTMENT_SUCCESS: {
      return calendarEventAdapter.updateOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true
      });
    }

    case CalendarAppointmentActionTypes.UPDATE_CALENDAR_APPOINTMENT_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false
      };
    }

    case CalendarAppointmentActionTypes.UPDATE_CALENDAR_APPOINTMENTS: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CalendarAppointmentActionTypes.UPDATE_CALENDAR_APPOINTMENTS_SUCCESS: {
      const newState = {
        ...state,
        isLoading: false,
        isLoaded: true
      };

      return calendarEventAdapter.updateMany(action.payload
        .map((calendarEvent: CalendarAppointment) =>
          Object.assign({ id: calendarEvent.eventId, changes: calendarEvent })), newState);
    }

    case CalendarAppointmentActionTypes.UPDATE_CALENDAR_APPOINTMENTS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false
      };
    }

    case CalendarAppointmentActionTypes.DELETE_CALENDAR_APPOINTMENTS: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CalendarAppointmentActionTypes.DELETE_CALENDAR_APPOINTMENTS_SUCCESS: {
      return calendarEventAdapter.removeMany(action.payload.appointmentIds, {
        ...state,
        isLoading: true
      });
    }

    case CalendarAppointmentActionTypes.DELETE_CALENDAR_APPOINTMENTS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false
      };
    }

    case CalendarAppointmentActionTypes.DELETE_CALENDAR_APPOINTMENT: {
      return {
        ...state,
        isLoading: true
      };
    }

    case CalendarAppointmentActionTypes.DELETE_CALENDAR_APPOINTMENT_SUCCESS: {
      return calendarEventAdapter.removeOne(action.payload.appointment.eventId, {
        ...state,
        isLoading: true
      });
    }

    case CalendarAppointmentActionTypes.DELETE_CALENDAR_APPOINTMENT_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false
      };
    }

    case CalendarAppointmentActionTypes.RESET_CALENDAR_APPOINTMENTS: {
      return calendarEventAdapter.removeAll({ ...state });
    }

    case CalendarAppointmentActionTypes.LOAD_DELETED_CALENDAR_APPOINTMENTS_SUCCESS: {
      return {
        ...state,
        deletedAppointments: action.payload
      };
    }

    default: {
      return state;
    }

  }
}

export function calendarFolderReducer(state = initialFolderState, action: Action): CalendarFolderState {
  switch (action.type) {

    case CalendarActionTypes.SET_CALENDAR_FOLDERS: {
      return calendarFolderAdapter.addAll(action.payload, {
        ...state
      });
    }

    case CalendarActionTypes.CREATE_CALENDAR_FOLDER_SUCCESS: {
      return calendarFolderAdapter.addOne(action.payload.folder, {
        ...state
      });
    }

    case CalendarActionTypes.UPDATE_CALENDAR_FOLDER_SUCCESS: {
      return calendarFolderAdapter.updateOne(action.payload, {
        ...state
      });
    }

    case CalendarActionTypes.DELETE_CALENDAR_FOLDER_SUCCESS: {
      return calendarFolderAdapter.removeOne(action.payload.folder.id, {
        ...state
      });
    }

    default: {
      return state;
    }

  }
}

export const _getCalendarFolders = (state: CalendarFolderState) => state.ids;
export const _getCalendarEvents = (state: CalendarState) => state.events;
export const _getCalendarEvent = (state: CalendarState) => state.event;
export const _getDeletedCalendarAppointments = (state: CalendarEventState) => state.deletedAppointments;
