
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

/**
 *  Generic HTTP Request Service.
 *  All requests will be generated through this service.
 */
@Injectable()
export class HttpService {

  /**
   *
   * @param {HttpClient} http
   */
  constructor(private http: HttpClient) {
  }

  /**
   *
   * @param {string} url
   * @returns {Observable<T>}
   */
  public get<T>(url: string): Observable<T> {
    return this.http.get<T>(url, {headers: this.getHeaders()});
  }

  /**
   *
   * @param {string} url
   * @param data
   * @returns {Observable<T>}
   */
  public post<T>(url: string, data: any): Observable<T> {
    return this.http.post<T>(url, data, {headers: this.getHeaders()});
  }

  /**
   *  Handles Authorized POST request for the application
   */
  public postAuth<T>(url: string, data: any): Observable<T> {
    return this.http.post<T>(url, data, {headers: this.getAuthHeaders()});
  }

  /**
   *  Handle Authorized DELETE request for the application
   */
  public deleteAuth<T>(url: string): Observable<T> {
    return this.http.delete(url, {headers: this.getAuthHeaders()}) as Observable<T>;
  }

  /**
   *  Handle Authorized PUT requrets for the application
   */
  public putAuth<T>(url: string, data: any): Observable<T> {
    return this.http.put<T>(url, data, {headers: this.getAuthHeaders()});
  }

  /**
   *
   * @param {string} url
   * @returns {Observable<T>}
   */
  public getAuth<T>(url: string): Observable<T> {
    return this.http.get<T>(url, {headers: this.getAuthHeaders()});
  }

  /**
   *
   * @returns {HttpHeaders}
   */
  private getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json",
    });
    return headers;
  }

  /**
   *
   * @returns {HttpHeaders}
   */
  private getAuthHeaders(): HttpHeaders {
    return null;
  }
}
