import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { take } from "rxjs/operators";

const SIGNATURE_ID_FOR_PREF_KEY = "SIGNATURE_ID_FOR_PREF_KEY";

@Injectable()
export class LocalStorageService {
  constructor() {

  }

  setSignatureIdForPrefName(prefName, signatureId) {
    localStorage.setItem(`${SIGNATURE_ID_FOR_PREF_KEY}_${prefName}`, signatureId);
  }

  getSignatureIdForPrefName(prefName): string {
    return localStorage.getItem(`${SIGNATURE_ID_FOR_PREF_KEY}_${prefName}`);
  }
}
