
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, ChangeDetectorRef, OnDestroy, NgZone } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ConfigService } from "../../../../config.service";
import { MailService } from "../../services/mail-service";
import { MailConstants } from "../../../../common/utils/mail-constants";
import { ToastService } from "../../../../common/providers/toast.service";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { takeUntil, map, take } from "rxjs/operators";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { Http, Headers } from "@angular/http";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { ElectronService } from "src/app/services/electron.service";
import { CommonUtils } from "src/app/common/utils/common-util";
import { PreferenceService } from "src/app/preference/shared/services/preference.service";
import { Preference } from "src/app/preference/shared/models";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { IdbUtils } from "src/app/services/idb.service";
import { AppService } from "src/app/services/app.service";

@Component({
  selector: "vp-general-settings-dialog",
  templateUrl: "./general-settings-dialog.component.html"
})
export class GeneralSettingsDialogComponent implements OnInit, OnDestroy {

  languageNames: any[] = [];
  themes = [
    { title: "DFB", value: "dfb" },
    { title: "VNCmail", value: "default" }
  ];
  selectedLanguage: string;
  selectedTheme: string;
  serverURL: string = "";
  isCordovaOrElectron = environment.isCordova || environment.isElectron;
  isCordovaApp = environment.isCordova;
  private isAlive$ = new Subject<boolean>();
  themeChangeRestricted: boolean = false;
  translations: any = {};
  constructor(
    public dialogRef: MatDialogRef<GeneralSettingsDialogComponent>,
    private translate: TranslateService,
    private configService: ConfigService,
    private changeDetection: ChangeDetectorRef,
    private mailBroadcaster: MailBroadcaster,
    private http: Http,
    private ngZone: NgZone,
    private hotKeyService: NgxHotkeysService,
    private electronService: ElectronService,
    private preferenceService: PreferenceService,
    private appService: AppService,
    private toastService: ToastService
  ) {
    this.hotKeyService.pause(this.hotKeyService.hotkeys);
    this.translate.get([
      "GERMAN_LBL",
      "ENGLISH_LBL"
    ]).pipe(take(1)).subscribe( res => {
      this.translations = res;
      this.changeDetection.markForCheck();
    });
    this.initialize();
  }

  ngOnInit() {
    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_GENERAL_SETTINGS).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });
    if (CommonUtils.isOnIOS()) {
      setTimeout(() => {
        document.querySelector(".cdk-global-overlay-wrapper").removeAttribute("style");
      }, 50);
    }
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
    this.hotKeyService.unpause(this.hotKeyService.hotkeys);
  }

  initialize() {
    this.initServerURL();
    this.changeDetection.markForCheck();
  }

  close() {
    this.dialogRef.close();
  }

  updateSettings() {
    if (this.isCordovaOrElectron && this.serverURL.trim() !== null) {
      this.updateServerURL();
    }
    this.close();
  }

  updateLanguage() {
    const pref: Preference[] = [];
    pref.push({
      key: "zimbraPrefLocale",
      value : this.selectedLanguage
    });
    this.preferenceService.modifyPrefs(pref).pipe(take(1)).subscribe( res => {
      if (!this.themeChangeRestricted) {
        this.updateTheme();
      }
      this.translate.use(this.selectedLanguage);
      this.translate.reloadLang(this.selectedLanguage);
      this.mailBroadcaster.broadcast(MailConstants.CHANGE_LANGUAGE);
      this.configService.setCurrentLanguage(this.selectedLanguage);
      if (this.electronService.isElectron) {
        this.electronService.setToStorage(MailConstants.MAIL_LANGUAGE, this.selectedLanguage);
      } else {
        localStorage.setItem(MailConstants.MAIL_LANGUAGE, this.selectedLanguage);
      }
      this.changeDetection.markForCheck();
    } , error => {
      this.toastService.showPlainMessage(error);
    });
  }

  initServerURL() {
    this.serverURL = this.configService.API_URL;
    const serverUrl = localStorage.getItem(MailConstants.SERVER_URL);
    if (serverUrl) {
      this.serverURL = serverUrl;
      setTimeout(() => {
        this.changeDetection.markForCheck();
      }, 200);
    }
  }

  updateServerURL() {
    if (this.serverURL === this.configService.API_URL) {
      return;
    }
    const headers = new Headers({ "Content-Type": "application/json" });
    if (this.serverURL.endsWith("/")) {
      this.serverURL = this.serverURL.substring(0, this.serverURL.lastIndexOf("/")).trim();
    }

    this.http.get(this.serverURL + "/api/config", { headers: headers }).pipe(map(response => response.json()))
      .subscribe(data => {
        if (data.boshURL) {
          this.configService.API_URL = this.serverURL;
          this.setServerURL(this.configService.API_URL);
          this.mailBroadcaster.broadcast(BroadcastKeys.HIDE_SIDEBAR_DRAWER);
        } else {
          alert("The Server URL entered by you is wrong. Please provide correct URL");
        }
      }, () => {
        alert("The Server URL entered by you is wrong. Please provide correct URL");
      });

  }

  setServerURL(url) {
    if (url) {
      if (!url.startsWith("http")) {
        url = "";
      }
      this.configService.setAPIURL(url);

      let serverUrl: string;
      sessionStorage.clear();
      if (CommonUtils.isOfflineModeSupported()) {
        IdbUtils.clearAll();
      }
      if (environment.isCordova) { // request is sent to backend before we remove the token
        if (typeof window.FirebasePlugin !== "undefined") {
          window.FirebasePlugin.unregister();
        }
        this.appService.setFirebaseToken().subscribe(res => {
          console.log("removed firebase token");
        });
      }
      serverUrl = localStorage.getItem("serverURL");
      localStorage.clear();
      localStorage.setItem("serverURL", url.trim());
      if (this.electronService.isElectron) {
        this.electronService.clearStorage();
      }

      if (serverUrl && environment.isCordova) {
        this.configService.selectedServer = true;
        window.location.href = CommonUtils.getBaseUrl() + "/index.html";
        this.configService.loginIframe();
      } else {
        window.location.href = CommonUtils.getBaseUrl() + "/index.html";
      }
    }
  }

  updateTheme(): void {
    const currentTheme = localStorage.getItem(MailConstants.THEME) || environment.theme;
    if (currentTheme !== this.selectedTheme) {
      localStorage.setItem(MailConstants.THEME, this.selectedTheme);
      MailUtils.reloadApp();
    }
  }

}
