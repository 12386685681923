
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="select_mail_addresses_dialog create-tag-dialog">
  <div class="select-mail-addresses__dialog-header" layout="row center-center">
    <div class="mobile-back-button" [fxHide.gt-sm]="true">
      <button (click)="close()" class="brand-color mat-button">
        <mat-icon class="disable-select">chevron_left</mat-icon>
      </button>
    </div>
    <h4>{{ 'SELECT_ADDRESSES' | translate}}</h4>
    <div class="desktop-close-button">
      <button class="brand-color mat-button" (click)="close()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="content">
    <div class="contact-loading-spinner" *ngIf="isLoading">
      <vp-custom-spinner></vp-custom-spinner>
    </div>
    <div class="select-option-toggle-group">
        <button (click)="onValChange('To')" [class.toggle-checked]="selectedVal === 'To'">
            <span>{{'TO_MAIL_LABLE' | translate}}</span>
        </button>
        <button (click)="onValChange('Cc')" [class.toggle-checked]="selectedVal === 'Cc'">
            <span>{{'CC_MAIL_LABLE' | translate}}</span>
        </button >
        <button (click)="onValChange('Bcc')" [class.toggle-checked]="selectedVal === 'Bcc'">
            <span>{{'BCC_MAIL_LABLE' | translate}}</span>
        </button>
    </div>
    <div class="search-part">
        <div class="search">
            <mat-form-field>
                <input matInput placeholder="{{'SEARCH_DOT' | translate}}" [(ngModel)]="searchText"
                    autocomplete="nope" (keydown.enter)="searchEmailAddresses()" />
                <mat-icon matSuffix (click)="searchEmailAddresses()">search</mat-icon>
            </mat-form-field>
        </div>
        <div class="dropdown">
            <mat-form-field>
                <mat-label>{{'SEARCH_IN' | translate }}:</mat-label>
                <mat-select *ngIf="!configService.useVNCdirectoryAuth" panelClass="show-name-select" (selectionChange)="resetOffset();changeShowNames($event.value)" [(ngModel)]="selectedShowOption" vpScrollList (onUserScroll)="scrollMoreContacts($event)">
                  <mat-option [value]="'contacts'">{{ 'CONTACTS' | translate }}</mat-option>
                  <mat-option [disabled]="!isOnline" [value]="'personalandshared'">{{ 'PERSONAL_AND_SHARED_CONTACTS' | translate }}</mat-option>
                  <mat-option [disabled]="!isOnline" *ngIf="iszimbraFeatureGalEnabled" [value]="'global'">{{ 'GLOBAL_ADDRESS_LIST' | translate }}</mat-option>
                </mat-select>
                <mat-select [disabled]="!isOnline" *ngIf="configService.useVNCdirectoryAuth" panelClass="show-name-select" (selectionChange)="resetOffset();getContactsFromList($event.value)" [(ngModel)]="selectedShowOption" vpScrollList (onUserScroll)="scrollMoreContacts($event)">
                  <mat-option [value]="0">{{ 'ALL' | translate }}</mat-option>
                  <mat-option *ngFor="let item of contactsList" [value]="item.id">{{ item.name }}</mat-option>
                </mat-select>
              </mat-form-field>
        </div>
    </div>
    <div *ngIf="!!finalEmailAddresses && finalEmailAddresses.length > 0" class="all-selected-item">
        <div *ngFor="let contactItem of finalEmailAddresses" class="selected-contact-item">
          <div>
            <div class="type-label">
              <span class="type-txt" *ngIf="contactItem.t === 'To'">{{'TO_MAIL_LABLE' | translate}}</span>
              <span class="type-txt" *ngIf="contactItem.t === 'Cc'">{{'CC_MAIL_LABLE' | translate}}</span>
              <span class="type-txt" *ngIf="contactItem.t === 'Bcc'">{{'BCC_MAIL_LABLE' | translate}}</span>
            </div>
            <div class="close-icon" (click)="removeSelectedAddresses(contactItem)">
              <mat-icon class="mdi-14px" fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            </div>
            <div class="avatar-part">
              <vp-avatar [type]="'mail-detail-avatar'"
                [user]="{firstLastCharacters: contactItem.name.charAt(0), color: '#317bbc', email: contactItem.email}">
              </vp-avatar>
            </div>
          </div>
          <div class="name">
              {{contactItem.name}}
          </div>
        </div>
    </div>
    <div class="contact-items">
      <mat-list [ngClass]="{'is-contact-select': finalEmailAddresses.length > 0}" vpScrollList (onUserScroll)="scrollMoreContacts($event)">
        <mat-list-item *ngFor="let contact of allEmailAddresses; index as i;" (click)="selectContactItem(contact, i, $event)">
          <span class="user-avatar">
              <vp-avatar [type]="'mail-detail-avatar'"
                [user]="{firstLastCharacters: contact.name.charAt(0), color: '#317bbc', email: contact.email}">
              </vp-avatar>
          </span>
          <div class="contact-detail-part">
            <div class="fullname disable-select">{{contact.name}}</div>
            <div class="email-address disable-select">{{ contact.email}}</div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
  <div class="contact__dialog-footer">
      <div class="desktop-footer">
          <button mat-button (click)="close()">
              {{ 'CANCEL' | translate }}
          </button>
          <button mat-button (click)="setSelected()">
              {{ 'CREATE' | translate }}
          </button>
      </div>
  </div>
</div>
