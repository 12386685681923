
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="searchfolder-header disable-select" *ngIf="!routeUrl.startsWith('/contacts')">
    <span class="subfolder-title">{{ 'SAVED_SEARCH' | translate }}</span>
    <div class="folder-action" id="folder-action">
      <mat-icon *ngIf="isRootUserFolderCollapsed" (click)="expandCollapseUserRootFolder(false)" matTooltip="{{'EXPAND' | translate}}" >expand_more
      </mat-icon>
      <mat-icon *ngIf="!isRootUserFolderCollapsed" (click)="expandCollapseUserRootFolder(true)" matTooltip="{{'COLLAPSE' | translate}}" >expand_less
      </mat-icon>
    </div>
</div>
<mat-divider class="sidebar-mat-divider"></mat-divider>

<div class="sidebar-item" *ngIf="routeUrl.startsWith('/contacts')">
    <div class="item-icon" id="folder-action">
      <mdl-icon *ngIf= "isRootUserFolderCollapsed" (click)="expandCollapseUserRootFolder(false)">expand_more</mdl-icon>
      <mdl-icon *ngIf= "!isRootUserFolderCollapsed" (click)="expandCollapseUserRootFolder(true)">expand_less</mdl-icon>
    </div>
    <div class="item-title">{{'SAVED_SEARCH'| translate}}</div>
</div>
<cdk-virtual-scroll-viewport *ngIf="folders && !isRootUserFolderCollapsed" itemSize="20" [ngStyle]="{ 'max-height': getMaxHeight()}" class="user-search-folders-viewport">
    <ng-container *cdkVirtualFor="let item of folders"></ng-container>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="sidebar-tree-subfolders">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding style="white-space:no-wrap;"
            [contextMenu]="searchFolderMenu" (contextmenu)="onContextMenu($event, node)"
            class="sub_folder_tree_node">
            <li *ngIf="node" class="sub_folder">
                <div class="mat-tree-node" cdkDropList
                    [class.selected-class]="selectedFolder && node.id === selectedFolder.id">
                    <button mat-icon-button disabled></button>
                    <div class="folder-information">
                        <div (click)="openAdvanceSearch(node)" class="folder-div disable-select">
                            <mat-icon [style.color]="node.rgb">folder</mat-icon>
                            <span class="sidebar-title" title="{{node.name}}"
                                [ngClass]="{'broken-share-folder': node.owner && !node.oname}">
                                {{node.name}}</span>
                        </div>
                        <div class="folder-information-preview" *cdkDragPreview>
                            <mat-icon [style.color]="node.rgb">folder</mat-icon>
                            <span class="sidebar-title" title="{{node.name}}"
                                [ngClass]="{'broken-share-folder': node.owner && !node.oname}">
                                {{node.name}}</span>
                        </div>
                    </div>
                </div>
            </li>
        </mat-tree-node>
    </mat-tree>
</cdk-virtual-scroll-viewport>
<context-menu #searchFolderMenu id="smartSearchMenu">
    <ng-template contextMenuItem (execute)="deleteSmartSearch($event)">
      <div class="context-item">
          <mat-icon>delete</mat-icon>
          <span>{{ 'DELETE' | translate }}</span>
      </div>
    </ng-template>
    <!-- <ng-template contextMenuItem (execute)="renameSmartSearch($event)">
      <div class="context-item">
          <mat-icon>edit</mat-icon>
          <span>{{ 'RENAME_SEARCH' | translate }}</span>
      </div>
    </ng-template> -->
  </context-menu>
