
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component,
  Inject,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  OnDestroy,
  NgZone
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserProfile } from "../../models";
import { MailRootState } from "src/app/mail/store";
import { Store } from "@ngrx/store";
import { ImageCropperComponent, CropperSettings } from "ngx-img-cropper";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { MailBroadcaster } from "../../../common/providers/mail-broadcaster.service";
import { AppState } from "../../../reducers/app";
import { StartProcessing, StopProcessing } from "../../../actions/app";
import { BroadcastKeys } from "../../../common/enums/broadcast.enum";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";

@Component({
  selector: "vp-avatar-cropper-dialog",
  templateUrl: "./avatar-cropper-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarCropperDialogComponent implements OnDestroy {
  imageData: any;
  cropperSettings: CropperSettings;
  @ViewChild("cropper")
  cropper: ImageCropperComponent;
  isFileUploded: boolean = false;
  userProfile: UserProfile = null;
  isMobileView: boolean = false;
  profileAvtarUpload = "";
  profileRemoveAvtar = "";
  globalOperationIcon = "check";
  isHandsetLandscape = false;
  private isAlive$ = new Subject<boolean>();
  cropOnly: boolean;

  constructor(
    public dialogRef: MatDialogRef<AvatarCropperDialogComponent>,
    private store: Store<MailRootState>,
    private changeDetection: ChangeDetectorRef,
    private mailBroadcaster: MailBroadcaster,
    private breakpointObserver: BreakpointObserver,
    private ngZone: NgZone,
    private appStore: Store<AppState>,
    @Inject(MAT_DIALOG_DATA) profileData: any
  ) {
    const isMobileScreen = this.breakpointObserver.isMatched(
      "(max-width: 599px)"
    );
    if (isMobileScreen) {
      this.isMobileView = true;
      this.changeDetection.markForCheck();
    }
    this.cropOnly = profileData?.cropOnly;
    this.isHandsetLandscape = this.breakpointObserver.isMatched([Breakpoints.HandsetLandscape]);
    this.imageData = {};
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.width = 100;
    this.cropperSettings.height = 100;
    this.cropperSettings.keepAspect = true;
    this.cropperSettings.croppedWidth = 100;
    this.cropperSettings.croppedHeight = 100;
    this.cropperSettings.canvasWidth = 200;
    this.cropperSettings.canvasHeight = 200;
    this.cropperSettings.minWidth = 100;
    this.cropperSettings.minHeight = 100;
    this.cropperSettings.rounded = true;
    if (
      typeof device !== "undefined" &&
      device.platform.toUpperCase() === "IOS"
    ) {
      this.changeDetection.markForCheck();
      this.cropperSettings.minWithRelativeToResolution = false;
    } else {
      this.cropperSettings.minWithRelativeToResolution = true;
    }
    this.cropperSettings.cropperDrawSettings.strokeColor =
      "rgba(255,255,255,1)";
    this.cropperSettings.cropperDrawSettings.strokeWidth = 2;
    this.cropperSettings.noFileInput = true;
    this.cropperSettings.compressRatio = 1;

    this.isMobileView = true;
    if (profileData !== "") {
      setTimeout(() => {
        this.profileAvtarUpload = "uploadAvatar";
        this.profileRemoveAvtar = "profileRemoveAvtarUpload";
        this.open(profileData.userProfile);
      }, 100);
    }
    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_AVATAR_CROPPER_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });
  }

  open(userProfile: UserProfile): void {
    this.resetCropper();
    this.userProfile = userProfile;
    if (this.userProfile && this.userProfile.imageData) {
      this.loadExistingCropperAvtar(this.userProfile.imageData);
    }
  }

  loadExistingCropperAvtar(data: any) {
    this.cropper.settings = this.cropperSettings;
    const image: HTMLImageElement = new Image();
    image.src = data;
    image.onload = () => {
      if (this.cropper) {
        this.cropper.setImage(image);
        this.isFileUploded = true;
        document.querySelector("canvas").classList.add("disable-select");
        this.changeDetection.markForCheck();
      }
    };
    this.changeDetection.markForCheck();
  }

  resetCropper() {
    this.cropper.reset();
    this.isFileUploded = false;
    this.imageData = {};
    this.changeDetection.markForCheck();
  }

  removeUserAvtar() {
    this.appStore.dispatch(new StartProcessing());
    this.cropper.reset();
    this.isFileUploded = false;
    this.imageData = {};
    this.mailBroadcaster.broadcast(this.profileRemoveAvtar);
    document.querySelector("canvas").classList.add("disable-select");
    this.changeDetection.markForCheck();
  }

  fileChangeListener($event) {
    const image: HTMLImageElement = new Image();
    if (!$event.target.files) {
      return;
    }
    if ($event.target.files.length > 1) {
      return;
    }
    const file: File = $event.target.files[0];
    this.appStore.dispatch(new StartProcessing());
    this.changeDetection.markForCheck();
    const myReader: FileReader = new FileReader();
    this.cropper.settings = this.cropperSettings;
    myReader.onloadend = (loadEvent: any) => {
      image.src = loadEvent.target.result;
      this.changeDetection.markForCheck();
    };
    image.onload = () => {
      if (this.cropper) {
        this.cropper.setImage(image);
        this.appStore.dispatch(new StopProcessing());
        this.isFileUploded = true;
        document.querySelector("canvas").classList.add("disable-select");
        this.changeDetection.markForCheck();
      }
    };
    myReader.readAsDataURL(file);
  }

  saveCropperImge() {
    const b64Data = this.imageData.image.split(",")[1];
    const blob = this.b64toBlob(b64Data, "image/png");
    this.mailBroadcaster.broadcast(this.profileAvtarUpload, {
      operation: blob
    });
    this.close();
  }

  apply() {
    if (this.imageData && this.imageData.image) {
      this.dialogRef.close({image: this.imageData.image});
    } else {
      this.dialogRef.close();
    }
  }

  urltoFile(url, filename, mimeType?) {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];
    return (fetch(url)
        .then((res) =>  res.arrayBuffer())
        .then((buf) =>  new File([buf], filename, {type: mimeType}))
    );
  }


  private b64toBlob(b64Data, contentType, sliceSize?): any {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  close(): void {
    this.isFileUploded = false;
    this.imageData = {};
    this.cropper.reset();
    this.changeDetection.markForCheck();
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  removeAvtar() {
    this.cropper.reset();
    this.isFileUploded = false;
    this.imageData = {};
    this.mailBroadcaster.broadcast("REMOVE_PROFILE_AVATAR");
    this.close();
    this.changeDetection.markForCheck();
  }
}
