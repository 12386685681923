
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "vp-equipment-conflict-dialog",
    templateUrl: "./conflict-equipment-dialog.component.html"
})
export class ConflictEquipmentDialogComponent implements OnInit, OnDestroy {
    startDate: Date;
    endDate: Date;
    users: any[] = [];
    conflictEmails: any;
    selectedAttendees: any;
    locations = [];
    constructor(
        private dialogRef: MatDialogRef<ConflictEquipmentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.conflictEmails = data.conflictEmails || {};
        this.selectedAttendees = (data.selectedAttendees || []).map(v => v.email);
        if (Object.keys(this.conflictEmails).length > 0) {
            for (let email of Object.keys(this.conflictEmails)) {
                if (!this.conflictEmails[email] && this.selectedAttendees.includes(email)) {
                    this.users.push(email);
                }
            }
        }
        if (this.data.inst)  {
            this.startDate = new Date(this.data.inst[0].s);
            this.endDate = new Date(this.data.inst[0].s + this.data.inst[0].dur);
            const usr = this.data.inst[0].usr;
            usr.map(item => {
                if (this.locations.indexOf(item.name) === -1) {
                    this.locations.push(item.name);
                }
            });
        } else {
            this.startDate = new Date(this.data.startTime);
            this.endDate = new Date(this.data.endTime);
        }
        
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    close(value: string): void {
        this.dialogRef.close({ value: value });
    }
}
