
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy, ChangeDetectorRef, AfterViewInit, ViewChild, ElementRef, NgZone } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs/internal/Subject";
import { MailRootState } from "../../../store";
import { MailFolderRepository } from "../../../repositories/mail-folder-repository";
import { DatePipe } from "@angular/common";
import { getSearchKeyword } from "../../../../reducers";
import { takeUntil, debounceTime, take } from "rxjs/operators";
import { MailUtils } from "../../../utils/mail-utils";
import { Router } from "@angular/router";
import { MailBroadcaster } from "../../../../common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "../../../../common/enums/broadcast.enum";
import { Utils } from "../../../../common";
import { FormControl } from "@angular/forms";
import { isArray } from "util";
import { ConversationRepository } from "../../../repositories/conversation.repository";
import { MailService } from "../../services/mail-service";
import { ToastService } from "../../../../common/providers/toast.service";
import { MatDialogRef } from "@angular/material/dialog";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";

@Component({
  selector: "vp-refine-search-dialog",
  templateUrl: "./refine-search-dialog.html"
})
export class RefineSearchDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  selectedApp: string = "mail";
  searchName: string = "";
  searchText: string = "";
  query: string = "";
  hasAttachment: boolean = false;
  date: Date = null;
  private isAlive$ = new Subject<boolean>();
  @ViewChild("searchTextInput", {static: false}) searchTextInput: ElementRef<HTMLInputElement>;
  emailFromCtrl = new FormControl();
  emailToCtrl = new FormControl();
  searchedFromUsers: any[] = [];
  searchedToUsers: any[] = [];
  filteredFromEmails: string[];
  filteredToEmails: string[];
  beforeDate: Date = null;
  afterDate: Date = null;

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<RefineSearchDialogComponent>,
    private store: Store<MailRootState>,
    private changeDetection: ChangeDetectorRef,
    private mailFolderRepo: MailFolderRepository,
    private datePipe: DatePipe,
    private convRepository: ConversationRepository,
    private mailService: MailService,
    private ngZone: NgZone,
    private mailBroadcaster: MailBroadcaster,
    private toastService: ToastService,
    private _hotkeysService: NgxHotkeysService
    ) {
  }

  ngOnInit() {
    this.store.select(getSearchKeyword).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      if (res) {
        const parsedQuery = MailUtils.parseSearchQuery(res);
        this.searchText = parsedQuery.searchText;
        this.date = parsedQuery.date;
        this.afterDate = parsedQuery.after;
        this.beforeDate = parsedQuery.before;
        this.emailFromCtrl.setValue(parsedQuery.mailFrom);
        this.emailToCtrl.setValue(parsedQuery.mailTo);
        this.hasAttachment = parsedQuery.hasAttchement;
        this.changeDetection.markForCheck();
      }
    });
    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_REFINE_SEARCH).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.dialogRef.close();
      });
    });
    this.emailFromCtrl.valueChanges.pipe(debounceTime(100)).subscribe(value => {
      if (Utils.isJson(value)) {
        return;
      }
      if (value !== "") {
        this.loadEmailSuggestion(value, true);
      }
    });

    this.emailToCtrl.valueChanges.pipe(debounceTime(100)).subscribe(value => {
      if (Utils.isJson(value)) {
        return;
      }
      if (value !== "") {
        this.loadEmailSuggestion(value, false);
      }
    });
    this._hotkeysService.pause(this._hotkeysService.hotkeys);
  }

  ngAfterViewInit(): void {
      setTimeout(() => {
        if (this.searchTextInput) {
          this.searchTextInput.nativeElement.focus();
        }
      }, 200);
  }

  loadEmailSuggestion(query, fromMail) {
    this.convRepository.getAutoCompleteList(query).subscribe(
      res => {
        if (fromMail) {
          this.searchedFromUsers = [];
        } else {
          this.searchedToUsers = [];
        }

        if (res && res.length > 0) {
          if (fromMail) {
            this.searchedFromUsers = res;
          } else {
            this.searchedToUsers = res;
          }
        } else if (Utils.validateEmail(query)) {
          if (fromMail) {
            this.searchedFromUsers.push({ title: query, name: query, email: query, image: "", checked: false });
          } else {
            this.searchedToUsers.push({ title: query, name: query, email: query, image: "", checked: false });
          }
        }

        this._filter(query, fromMail);
        this.changeDetection.markForCheck();
      },
      err => {
        if (Utils.isJson(err._body)) {
          this.mailService.openSnackBar(JSON.parse(err._body).msg);
        }
      }
    );
  }

  private _filter(value: string, fromMail): void {
    if (value !== null) {
      const filterValue = value.toLowerCase();
      if (fromMail) {
        this.filteredFromEmails = this.searchedFromUsers.filter(user => user.email.toLowerCase().includes(filterValue));
      } else {
        this.filteredToEmails = this.searchedToUsers.filter(user => user.email.toLowerCase().includes(filterValue));
      }
      this.changeDetection.markForCheck();
    }
  }

  getAvatar(email) {
    return this.convRepository.getAvatar(email);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.emailFromCtrl.setValue(event.option.value.email);
    this.changeDetection.markForCheck();
  }

  selectedToEmail(event: MatAutocompleteSelectedEvent): void {
    this.emailToCtrl.setValue(event.option.value.email);
    this.changeDetection.markForCheck();
  }

  search() {
    this.query = this.getQuery();
    this.router.navigate(["/mail/search"], { queryParams: { searchParams: btoa(this.query) } });
    this.close();
  }

  saveSearch() {
    let tempSearchName = "";
    if (this.searchName) {
      tempSearchName = this.searchName;
    } else if (this.searchText) {
      tempSearchName = this.searchText;
    }
    const searchParams = {
      name: tempSearchName,
      query: this.getQuery(),
      types: "conversation"
    };
    this.mailFolderRepo.saveSearchFolder(searchParams);
  }

  getQuery(): string {
    let query = "";
    if (this.searchText) {
      query = query + this.searchText;
    }
    if (this.searchText === "") {
      this.store.select(getSearchKeyword).pipe(take(1)).subscribe(res => {
        if (res) {
          this.query = "";
          const parsedQuery = MailUtils.parseSearchQuery(res);
          this.searchText = parsedQuery.searchText;
          query = query + this.searchText;
        }
      });
    }
    if (this.emailFromCtrl.value) {
      query = query + " from:" + this.emailFromCtrl.value;
    }
    if (this.emailToCtrl.value) {
      query = query + " to:" + this.emailToCtrl.value;
    }
    if (this.hasAttachment) {
      query = query + " has:attachment";
    }
    if (this.date) {
      query = query + " date:" + this.datePipe.transform(this.date, "MM/dd/yy");
    }
    if (this.afterDate) {
      query = query + " after:" + this.datePipe.transform(this.afterDate, "MM/dd/yy");
    }
    if (this.beforeDate) {
      query = query + " before:" + this.datePipe.transform(this.beforeDate, "MM/dd/yy");
    }
    return query;
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
    this._hotkeysService.unpause(this._hotkeysService.hotkeys);
  }
}
