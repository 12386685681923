// @ts-nocheck
import Quill from "quill";

let Parchment = Quill.import("parchment");
const BlockEmbed = Quill.import("blots/block/embed");
class Table extends BlockEmbed {
  static create(value) {
    const node = super.create("table");
    node.innerHTML = value.innerHTML;
    const className = (value.class || "").replace(new RegExp("vnc-table", "gi"), "");
    node.className = ("vnc-table " + className).trim();
    if (value.cellpadding) {
      node.setAttribute("cellpadding", value.cellpadding);
    }
    if (value.border) {
      node.setAttribute("border", value.border);
    }
    if (value.style) {
      node.setAttribute("style", value.style);
    }
    if (value.id) {
      node.setAttribute("id", value.id);
    }
    return node;
  }

  static value(node) {
    return {
      innerHTML: node.innerHTML,
      style: node.getAttribute("style"),
      class: node.getAttribute("class"),
      cellpadding: node.getAttribute("cellpadding"),
      border: node.getAttribute("border"),
      id: node.getAttribute("id")
    };
  }

  optimize(context) {
    super.optimize(context);
    let next = this.next;
    // if (next != null && next.prev === this &&
    //   next.statics.blotName === this.statics.blotName &&
    //   next.domNode.tagName === this.domNode.tagName &&
    //   next.domNode.getAttribute("table_id") === this.domNode.getAttribute("table_id")) {
    //   // next.moveChildren(this);
    //   // next.remove();
    // }
  }

  insertBefore(childBlot, refBlot) {
    if (this.statics.allowedChildren != null && !this.statics.allowedChildren.some((child) => {
      return childBlot instanceof child;
    })) {
      let newChild = Parchment.create(this.statics.defaultChild, TableTrick.random_id());
      newChild.appendChild(childBlot);
      childBlot = newChild;
    }
    super.insertBefore(childBlot, refBlot);
  }

}

Table.blotName = "table";
Table.tagName = "table";
// Table.scope = Scope.BLOCK_BLOT;
// Table.defaultChild = TableRow;
// Table.allowedChildren = [TableRow];

export default Table;
