 <ng-template
        #defaultTemplate
        let-contents="contents"
        let-placement="placement"
        let-event="event"
        let-currentLocal="currentLocal"
        let-isAllDayMultiple="isAllDayMultiple"
        >

        <div class="cal-tooltip" [ngClass]="'cal-tooltip-' + placement">
          <div class="cal-tooltip-inner" [ngClass]="{'cal-tooltip-inner-private-event': !event.title}">
          <div class="preview-tooltip-container" *ngIf="!isMobileView" [ngClass]="{'preview-tooltip-container-private-event': !event.title}">
            <vp-appointment-preview-common-directive ></vp-appointment-preview-common-directive></div>
            <div class="title" *ngIf="isMobileView">
              {{event.title}}
            </div>
            <div class="bottom_border"  *ngIf="isMobileView"></div>
            <div class="org" *ngIf="event?.organizer !== '' && isMobileView">
            <div class="tooltip-icon"  *ngIf="isMobileView"><mat-icon>person</mat-icon></div>
            <div class="tooltip-value"  *ngIf="isMobileView">{{event?.organizer}}</div>
          </div>
          <div class="org" *ngIf="event?.replyType !== '' &&  isMobileView">
            <div class="tooltip-icon"><mat-icon>perm_contact_calendar</mat-icon></div>
            <div class="tooltip-value" *ngIf="event?.replyType===''">{{'CALENDARS.NEW_LBL' | translate }}</div>
            <div class="tooltip-value" *ngIf="event?.replyType==='AC'">{{'CALENDARS.ACCEPT_LBL' | translate }}</div>
            <div class="tooltip-value" *ngIf="event?.replyType==='DE'">{{'CALENDARS.DECLINED_LBL' | translate }}</div>
            <div class="tooltip-value" *ngIf="event?.replyType==='TE'">{{'CALENDARS.TENTETIVE_LBL' | translate }}</div>
          </div>
            <div class="event-date" *ngIf="isMobileView">
              <div class="tooltip-icon"><mat-icon>event</mat-icon></div>
              <span class="tooltip-value" *ngIf="!event.allDay">
                <div *ngIf="currentLocal === 'en'">{{event.start | date: 'mediumDate'}}, {{event.start | date: 'shortTime'}}
                  to {{event.end | date: 'shortTime'}}
                </div>
                <div *ngIf="currentLocal === 'de'">{{event.start | date: 'dd. MMM yyyy' : '': 'de' }}
                  von {{event.start | date: 'shortTime': '': 'de'}} bis {{event.end | date: 'shortTime' : '': 'de'}}
                </div>
              </span>
              <span class="tooltip-value" *ngIf="event.allDay">
                  <div *ngIf="!isAllDayMultiple">
                    {{event.start | date: 'longDate': '': currentLocal}}
                  </div>
                  <div *ngIf="isAllDayMultiple">
                    {{event.start | date: 'longDate': '': currentLocal}}
                    <span *ngIf="currentLocal === 'de'">bis</span> <span *ngIf="currentLocal !== 'de'">to</span>
                    {{event.end | date: 'longDate': '': currentLocal}}
                  </div>
              </span>
            </div>
            <div class="location" *ngIf="event.location && event.location !== '' && isMobileView">
              <div class="tooltip-icon"><mat-icon>room</mat-icon></div>
              <div class="tooltip-value">{{event.location}}</div>
            </div>
            <div class="work" *ngIf="isMobileView">
              <div class="tooltip-icon"><mat-icon>work</mat-icon></div>
              <div class="tooltip-value">
                <span *ngIf="event.fb === 'O'">{{'CALENDARS.OUT_OF_OFFICE' | translate }}</span>
                <span *ngIf="event.fb === 'T'">{{'CALENDARS.TENTATIVE' | translate }}</span>
                <span *ngIf="event.fb === 'B'">{{'CALENDARS.BUSY' | translate }}</span>
                <span *ngIf="event.fb === 'F'">{{'CALENDARS.FREE' | translate }}</span>
              </div>
            </div>
            <div class="org" *ngIf="event?.description !== '' && isMobileView">
            <div class="tooltip-icon"  *ngIf="isMobileView"><mat-icon>event_note</mat-icon></div>
            <div class="desc-item"  *ngIf="isMobileView">{{event?.description}}</div>
          </div>
          <div class="org" *ngIf="event?.at && event?.at.length > 0 && isMobileView">
            <div class="tooltip-icon"><mat-icon>group</mat-icon></div>
            <div class="attendees">
              <span class="tooltip-value" *ngFor="let item of event?.at">
                {{item.email}}
                <ng-container *ngIf="event?.or?.a === userEmail">
                  <span *ngIf="item.ptst === 'NE'">({{'CALENDARS.NEEDS_ACTIONS' | translate}})</span>
                  <span *ngIf="item.ptst === 'AC'">({{'CALENDARS.ACCEPT_LBL' | translate}})</span>
                  <span *ngIf="item.ptst === 'DE'">({{'CALENDARS.DECLINED_LBL' | translate}})</span>
                  <span *ngIf="item.ptst === 'TE'">({{'CALENDARS.TENTETIVE_LBL' | translate}})</span>
                </ng-container>
              </span>
            </div>
          </div>
          </div>
          <!--  -->
        </div>
      </ng-template>
      <ng-template
        [ngTemplateOutlet]="customTemplate || defaultTemplate"
        [ngTemplateOutletContext]="{
          contents: contents,
          placement: placement,
          event: event,
          currentLocal: currentLocal,
          isAllDayMultiple: isAllDayMultiple
        }">
      </ng-template>