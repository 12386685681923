
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";

import { Store } from "@ngrx/store";

import { BriefcaseRootState, getSelectedFileIds, getBriefcaseFilesByIds, getHasMoreFiles, getIsFileLoading } from "../store/selectors";
import {
  LoadBriefcases,
  LoadBriefcasesSuccess,
  LoadBriefcasesFail,
  ResetBriefcaseState,
  CheckFileAction,
  UnCheckFileAction,
  SelectFileAction,
  ResetSelectedFilesAction,
  ResetCheckedFilesAction,
  SelectBriefcaseFolder,
  RemoveManyBriefcaseFiles,
  UpdateManyBriefcaseFileSuccess,
  LoadBriefcaseFilesSuccessAction,
  SetHasMoreAction,
  UnSelectFileAction,
  MultipleCheckFileAction
} from "../store/actions";
import { SearchRequest } from "src/app/mail/shared/models";
import { BriefcaseService } from "../services/briefcase.service";
import { ErrorService } from "src/app/common/providers/error-service";
import { ErrorType } from "src/app/common/enums/mail-enum";
import { Observable, Subject } from "rxjs";
import { BriefcaseFolder } from "../shared/models/briefcase-folder";
import { take } from "rxjs/operators";
import { ToastService } from "src/app/common/providers/toast.service";
import { environment } from "src/environments/environment";
import { ElectronService } from "src/app/services/electron.service";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class BriefcaseRepository {
  constructor(
    private store: Store<BriefcaseRootState>,
    private briefcaseService: BriefcaseService,
    private errorService: ErrorService,
    private toastService: ToastService,
    private electronService: ElectronService,
    private translateService: TranslateService
  ) { }

  getBriefcases(request: SearchRequest) {
    this.store.dispatch(new LoadBriefcases());
    this.briefcaseService.searchRequest(request).pipe(take(1)).subscribe(
      res => {
        this.store.dispatch(new SetHasMoreAction(res.more === true));
        res = res && res.doc || [];
        this.store.dispatch(
          new LoadBriefcasesSuccess({ files: res })
        );
      },
      err => {
        this.store.dispatch(new LoadBriefcasesFail());
        this.errorService.emit({ id: ErrorType.Generic, messages: err });
      }
    );
  }

  checkBriefcaseFile(id: string): void {
    this.store.dispatch(new CheckFileAction(id));
  }

  unCheckBriefcaseFile(id: string): void {
    this.store.dispatch(new UnCheckFileAction(id));
  }

  selectBriefcaseFile(id: string): void {
    this.store.dispatch(new SelectFileAction(id));
  }

  getSelectedBriefcaseIds(): Observable<string[]> {
    return this.store.select(getSelectedFileIds);
  }

  resetSelectedIds(): void {
    this.store.dispatch(new ResetSelectedFilesAction());
  }

  resetCheckedIds(): void {
    this.store.dispatch(new ResetCheckedFilesAction());
  }

  downloadFile(url) {
    const serverURL = this.getServerURL();
    const passport = this.getPassport();
    window.open(serverURL + "/api/downloadDocument?url=" + url + passport, "_system");
  }

  downloadZimbraDocFile(url, file: any) {
    let browserLang = this.translateService.getBrowserLang();
    const localLang = this.electronService.isElectron
        ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE)
        : localStorage.getItem(MailConstants.MAIL_LANGUAGE);
    if (localLang !== null && localLang !== undefined && localLang !== "undefined") {
      browserLang = localLang;
    }
    browserLang = browserLang.match(/en|de/) ? browserLang : "en";

    const serverURL = this.getServerURL();
    const passport = this.getPassport();
    const leb = file.leb;
    const verFile = file.ver;
    const md = file.d;
    const fname = file.name;
    window.open(
      serverURL + "/api/printZimbraDocument?url=" + url + "&leb=" + leb + "&lang=" + browserLang + "&verFile=" + verFile +
      "&md=" + md + "&fname=" + fname + passport, "_system");
  }

  downloadAllFile(url) {
    const serverURL = this.getServerURL();
    const passport = this.getPassport();
    window.open(serverURL + "/api/downloadAllDocument?url=" + url + passport, "_system");
  }

  getBriefcaseFileById(ids: string[]): Observable<any> {
    return this.store.select(state => getBriefcaseFilesByIds(state, ids));
  }

  setSelectedFolder(folder: BriefcaseFolder): void {
    this.store.dispatch(new SelectBriefcaseFolder({folder: folder}));
  }

  moveToTrash(ids: string[]): Observable<string[]> {
    const response = new Subject<string[]>();
    const body = {
      op: "trash",
      id: ids.join(",")
    };
    this.briefcaseService.itemAction(body).pipe(take(1)).subscribe( res => {
      this.store.dispatch(new RemoveManyBriefcaseFiles(ids));
      response.next(ids);
    });
    return response.asObservable();
  }

  moveToFolder(ids: string[] , folderId: string): Observable<String[]> {
    const response = new Subject<string[]>();
    const body = {
      op: "move",
      id: ids.join(","),
      l: folderId
    };
    this.briefcaseService.itemAction(body).subscribe(res => {
      response.next(ids);
    });
    return response.asObservable();
  }

  moveFileToFolder(ids: string[] , folderId: string): Observable<String[]> {
    const response = new Subject<string[]>();
    const body = {
      op: "move",
      id: ids.join(","),
      l: folderId
    };
    this.briefcaseService.itemAction(body).subscribe(res => {
      this.store.dispatch(new RemoveManyBriefcaseFiles(ids));
      response.next(ids);
    }, error => {
      if (error && error.indexOf("object with that name already exists") !== -1) {
        this.toastService.show("BRIEFCASE.ALREADY_FILE_EXISTS_ERROR_MSG", 3000);
      } else {
        this.toastService.showPlainMessage(error);
      }
    });
    return response.asObservable();
  }

  setBriefcaseFilesLoadedSuccess(briefcaseFiles: any[]) {
    this.store.dispatch(new LoadBriefcaseFilesSuccessAction(briefcaseFiles));
  }

  deleteFromTrash(briefcaseFiles: any[]): Observable<String[]> {
    const ids = briefcaseFiles.map(item => item.id);
    const response = new Subject<string[]>();
    const body = {
      op: "delete",
      id: ids.join(",")
    };
    this.briefcaseService.itemAction(body).subscribe(res => {
      this.store.dispatch(new RemoveManyBriefcaseFiles(ids));
      response.next(ids);
    });
    return response.asObservable();
  }

  renameBriefcaseFile(newName: string, id: string): Observable<String> {
    const response = new Subject<string>();
    const body = {
      op: "rename",
      id: id,
      name: newName
    };
    this.briefcaseService.itemAction(body).subscribe(res => {
      response.next(id);
    }, err => {
      if (err.indexOf("invalid name") !== -1) {
        const error = err.split("invalid name:");
        this.translateService.get(MailConstants.INVALID_NAME_ERROR_MSG, { characters: error[1]}).pipe(take(1)).subscribe((text: string) => {
          this.toastService.showPlainMessage(text);
        });
      } else {
        this.toastService.showPlainMessage(err);
      }
    });
    return response.asObservable();
  }

  getHasMoreFiles(): Observable<boolean> {
    return this.store.select(getHasMoreFiles);
  }

  getIsFileLoading(): Observable<boolean> {
    return this.store.select(getIsFileLoading);
  }

  unSelectBriefcaseFile(id: string): void {
    this.store.dispatch(new UnSelectFileAction(id));
  }

  multipleCheckConversationIds(ids: string[]): void {
    this.store.dispatch(new MultipleCheckFileAction(ids));
  }

  getServerURL(): string {
    const isCordovaOrElectron = environment.isCordova || environment.isElectron;
    let serverURL = location.origin;
    if (isCordovaOrElectron) {
      serverURL = localStorage.getItem(MailConstants.SERVER_URL).trim();
    }
     return serverURL;
   }

   getPassport(): string {
    const isCordovaOrElectron = environment.isCordova || environment.isElectron;
    let queryParams = "";
    if (isCordovaOrElectron) {
      queryParams = "&token=" + localStorage.getItem("token");
    }
    return queryParams;
   }
}
