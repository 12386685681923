
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import * as fromMail from "../actions/mail.actions";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Message } from "../../shared/models";
import { Action } from "src/app/actions";
import * as _ from "lodash";

export interface MailState extends EntityState<Message> {
    isMsgLoading: boolean;
    isMsgLoaded: boolean;
    selectedMessageIds: string[];
    checkedMessageIds: string[];
    selectedMessageId: string;
    sealedData: any;
}

export const mailAdapter: EntityAdapter<Message> = createEntityAdapter<Message>({
    selectId: (mail: Message) => mail.id
});

export const initialState: MailState = mailAdapter.getInitialState({
    isMsgLoading: false,
    isMsgLoaded: false,
    selectedMessageIds: [],
    checkedMessageIds: [],
    sealedData: {},
    selectedMessageId: null
});

export function mailReducer(
    state = initialState,
    action: Action
): MailState {
    switch (action.type) {

        case fromMail.IS_LOADING: {
            return {
                ...state,
                isMsgLoading: true
            };
        }

        case fromMail.LOAD_MAIL_SUCCESS: {
            const newState = {
                ...state,
                isMsgLoading: false,
                isMsgLoaded: true
            };
            return mailAdapter.addMany(action.payload, newState);
        }

        case fromMail.LOAD_MAIL_FAILED: {
            return {
                ...state,
                isMsgLoading: false,
                isMsgLoaded: false,
            };
        }

        case fromMail.SELECT_MESSAGE: {
            return {
                ...state,
                 selectedMessageIds: _.uniq([
                ...state.selectedMessageIds,
                action.payload
            ])
            };
        }

        case fromMail.SELECT_MESSAGE_DETAIL: {
            return {
                ...state,
                 selectedMessageId:  action.payload
            };
        }

        case fromMail.UNSELECT_MESSAGE: {
            const messageId = action.payload;
            const selectedMessageIds = state.selectedMessageIds.filter(id => messageId !== id);
            return {
              ...state,
              selectedMessageIds: selectedMessageIds
            };
        }

        case fromMail.RESET_SELECTED_MESSAGES: {
            return {
              ...state,
              selectedMessageIds: []
            };
        }

        case fromMail.CHECK_MESSAGE: {
            return {
                ...state,
                 checkedMessageIds: _.uniq([
                ...state.checkedMessageIds,
                action.payload
            ])
            };
        }

        case fromMail.UNCHECK_MESSAGE: {
            const messageId = action.payload;
            const checkedMessageIds = state.checkedMessageIds.filter(id => messageId !== id);
            return {
              ...state,
              checkedMessageIds: checkedMessageIds
            };
        }

        case fromMail.RESET_CHECKED_MESSAGES: {
            return {
              ...state,
              checkedMessageIds: []
            };
        }

        case fromMail.CHECK_ALL_MESSAGES: {
            return {
              ...state,
              checkedMessageIds: action.payload
            };
        }

        case fromMail.RESET_MESSAGES: {
            const newState =  {
                ...state
            };
            return mailAdapter.removeAll(newState);
        }

        case fromMail.RESET_MESSAGE_STATE: {
          return mailAdapter.removeAll(state);
        }

        case fromMail.REMOVE_MANY_MESSAGES: {
            const newState =  {
                ...state
            };
            return mailAdapter.removeMany(action.payload, newState);
        }

        case fromMail.REMOVE_MESSAGES_FROM_FOLDER: {
            const newState =  {
                ...state
            };
            const folderId = action.payload;
            const ids = [];
            const entities = state?.entities;
            console.log("[mail.reducer][REMOVE_MESSAGES_FROM_FOLDER] entities: ", entities);
            Object.keys(entities).forEach(id => {
                if (entities[id] && entities[id].l === folderId) {
                    ids.push(id);
                }
            });
            console.log("[REMOVE_MESSAGES_FROM_FOLDER]", folderId, ids);
            return mailAdapter.removeMany(ids, newState);
        }

        case fromMail.REMOVE_MESSAGE_SUCCESS: {
            const newState =  {
                ...state
            };
            return mailAdapter.removeOne(action.payload, newState);
        }

        case fromMail.UPDATE_MANY_MESSAGES_SUCCESS: {
            const newState =  {
                ...state,
                isMsgLoading: false,
                isMsgLoaded: true
            };
            return mailAdapter.updateMany(action.payload
              .map((message) => Object.assign({}, {id: message.id, changes: message})), newState);
          }

        case fromMail.UPDATE_MESSAGE_SUCCESS: {
            return mailAdapter.updateOne(action.payload, {
              ...state,
              isLoading: false,
              isLoaded: true,
            });
        }

        case fromMail.UPDATE_SEALED_DATA: {
            const sealedData = state.sealedData || {};
            sealedData[action.payload.id] = action.payload.data;
            return {
                ...state,
                sealedData: sealedData
            };
        }

        default: {
            return state;
        }
    }
}

export const _getIsMailsLoading = (state: MailState) => state.isMsgLoading;
export const _getIsMailsLoaded = (state: MailState) => state.isMsgLoaded;
export const _getSelectedMessageIds = (state: MailState) => state.selectedMessageIds;
export const _getCheckedMessageIds = (state: MailState) => state.checkedMessageIds;
export const _getMessageDetailId = (state: MailState) => state?.selectedMessageId;
export const _getSealedData = (state: MailState) => state.sealedData;
