import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { CalendarRepository } from "../../repositories/calendar.repository";
import { SchedulerUtils } from "./scheduler-utils";
import { Subject } from "rxjs";
import * as moment from "moment";
import { takeUntil } from "rxjs/operators";

const ROLE_REQUIRED = "REQ";
const ROLE_OPTIONAL = "OPT";
const STATUS_TENTATIVE = "t";
const STATUS_BUSY = "b";
const STATUS_OUT = "u";
const STATUS_FREE = "f";

const STATUS_WORKING_HOURS = "f";
const STATUS_NON_WORKING_HOURS = "u";
const STATUS_UNKNOWN = "n";
const MSEC_PER_DAY = 86400000;
const FREE_CLASS = "ZmScheduler-free";
const TYPE_LOCATION = "LOCATION";
const TYPE_EQUIPMENT = "EQUIPMENT";
const ZmFreeBusySchedulerView = SchedulerUtils.freeBusySchedulerView();
@Component({
  selector: "vp-scheduler",
  templateUrl: "./scheduler.component.html",
  styleUrls: ["./scheduler.component.scss"]
})
export class SchedulerComponent implements OnDestroy, AfterViewInit {
  @Input() attendees = [];
  @Input() dateInfo = {};
  @Input() attendeeTypes = {};
  @Output() conflict = new EventEmitter();
  public attendeeRows = [];
  public isComposeMode: any;
  public htmlElId: any;
  private _emailToIdx = {};
  private _schedTable = [];
  private _allAttendees = [];
  private _allAttendeesStatus = [];
  private _allAttendeesSlot = null;
  private _fbConflict = {};
  private _schedule: any = {};
  private _workingHrs: any = {};
  private _attTypes = ["PERSON", "LOCATION", "EQUIPMENT"];
  private _freeBusyRequest: any;
  private _startDate = new Date();
  private _endDate = new Date();
  private _dateInfo: any = {};
  private _appt: any = { getOrigStartTime: () => new Date(), getOrigEndTime: () => new Date() };
  private _workingHoursRequest: any;
  private _allAttendeeEmails = [];
  private _dateBorder: any = {};
  private _allAttendeesTable: any;
  private _acContactsList: any;
  private _acEquipmentList: any;
  private _attendeesTable: any;
  private _attendeesTableId: string;
  private _allAttendeesIndex: any;
  private isAlive$ = new Subject<boolean>();
  private _event$ = new Subject<any>();
  attendeeConflict: boolean;
  locationConflict: boolean;

  constructor(private calendarRepository: CalendarRepository) {
    this.htmlElId = "VNC" + new Date().getMilliseconds();
    this._allAttendeesIndex = this._addAttendeeRow(true, null, false);
    this._event$.asObservable().pipe(takeUntil(this.isAlive$)).subscribe(v => {
      console.log("[SchedulerComponent] handle event", v);
    });
  }

  setEvent(data) {
    this._event$.next(data);
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  ngAfterViewInit() {
    this._attendeesTableId = `${this.htmlElId}_attendeesTable`;
    this._attendeesTable = document.getElementById(this._attendeesTableId);
    this._allAttendeesSlot = this._schedTable[this._allAttendeesIndex];
    this._allAttendeesTable = document.getElementById(this._allAttendeesSlot.dwtTableId);
  }

  getConflicts() {
    return this._fbConflict;
  }

  getOrganizerEmail() {
    return this.calendarRepository.userProfile.email;
  }

  showConflicts() {
    let isFree;
    let attendeeConflict = false;
    let locationConflict = false;
    let conflictEmails = this.getConflicts();
    let orgEmail = this.getOrganizerEmail();
    for (let email of Object.keys(conflictEmails)) {
      isFree = orgEmail === email ? true : conflictEmails[email];
      if (!isFree) {
        if (this.attendeeTypes[email] === "LOCATION") {
          locationConflict = true;
        } else if (this.attendeeTypes[email] === "PERSON") {
          attendeeConflict = true;
        }
      }
    }
    this.attendeeConflict = attendeeConflict;
    this.locationConflict = locationConflict;
    this.conflict.emit({ isConflicted: attendeeConflict, conflictEmails, locationConflict: locationConflict });
  }

  getBoxBorderFromId(id) {
    switch (id) {
      case "F": return "ZmSchedulerApptBorder-free";
      case "PSTATUS_NEEDS_ACTION":
      case "B": return "ZmSchedulerApptBorder-busy";
      case "T": return "ZmSchedulerApptBorder-tentative";
      case "O": return "ZmSchedulerApptBorder-outOfOffice";
    }
    return "ZmSchedulerApptBorder-busy";
  }

  /**
 * Returns a list of attendees with the given role.
 *
 * @param	{array}		list		list of attendees
 * @param	{constant}	role		defines the role of the attendee (required/optional)
 *
 * @return	{array}	a list of attendees
 */
  filterAttendeesByRole(list, role) {
    let result = [];
    for (let i = 0; i < list.length; i++) {
      let attendee = list[i];
      let attRole = attendee.getParticipantRole() || ROLE_REQUIRED;
      if (attRole === role) {
        result.push(attendee);
      }
    }
    return result;
  }

  // cache free busy response in user-id -> slots hash map
  private _handleResponseFreeBusyCache(params, result) {
    if (!result) {
      return;
    }
    let args = result.usr || [];

    for (let i = 0; i < args.length; i++) {
      let usr = args[i];
      let id = usr.id;

      if (!id) {
        continue;
      }

      if (!this._schedule[id]) {
        this._schedule[id] = [];
      }

      usr.searchRange = { startTime: params.startTime, endTime: params.endTime };
      this._schedule[id].push(usr);
    }

    if (params.callback) {
      params.callback(result);
    }
  }

  private _getFreeBusyInfo(startTime?: any, endTime?: any, emails?: any, callback?: any, errorCallback?: any, excludedId?: any) {
    // call backend api
    this.calendarRepository.getFreeBusy({ startTime, endTime, uid: emails.join(","), excludeUid: excludedId }).subscribe(v => {
      if (v) {
        this._handleResponseFreeBusyCache({ startTime, endTime, emails }, v); // add response to cache
      }
      if (typeof callback === "function") {
        console.log("[_getFreeBusyInfo]callback");
        callback();
      } else {
        this._handleResponseFreeBusySV({ startTime, endTime, emails }, v); // handle response for UI
      }
    }, err => {
      if (typeof errorCallback === "function") {
        errorCallback();
      } else {
        this._handleErrorFreeBusyCache({ startTime, endTime }, err);
        this._handleErrorFreeBusy({ startTime, endTime }, err);
      }
    });

  }

  private _handleErrorFreeBusy(params, result) {
    this._freeBusyRequest = null;
    this._workingHoursRequest = null;

    if (result.code === "offline.ONLINE_ONLY_OP") {
      let emails = params.emails;
      for (let i = 0; i < emails.length; i++) {
        let e = emails[i];
        let sched = this._schedTable[this._emailToIdx[e]];
        let table: any = sched ? document.getElementById(sched.dwtTableId) : null;
        if (table) {
          table.rows[0].className = "ZmSchedulerNormalRow";
          this._clearColoredCells(sched);
          sched.uid = e;
          let now = new Date();
          let obj = [{ s: now.setHours(0, 0, 0), e: now.setHours(24, 0, 0) }];
          this._colorSchedule(ZmFreeBusySchedulerView.STATUS_UNKNOWN, obj, table, sched);
        }
      }
    }
    return false;
  }

  private _getFreeBusyInfoSV(startTime, emails, callback?: any) {
    let endTime = startTime + MSEC_PER_DAY;
    let freeBusyParams = {
      emails: emails,
      startTime: startTime,
      endTime: endTime,
      callback: callback
    };

    let params = {
      startTime: startTime,
      endTime: endTime,
      emails: emails,
      freeBusyParams,
      noBusyOverlay: true,
      account: null
    };
    this._freeBusyRequest = this.getFreeBusyInfo(params);
  }

  getFreeBusyInfo(params) {
    let requiredEmails = [], freeBusyKey, emails = params.emails, fbSlot;
    console.log("[SchedulerComponent][getFreeBusyInfo]", params, this._schedule);
    for (let i = emails.length; --i >= 0;) {
      freeBusyKey = params.startTime + "";
      // check local cache
      let entryExists = false;
      if (this._schedule[emails[i]]) {
        console.log("[SchedulerComponent][getFreeBusyInfo]check local cache", params, emails[i], this._schedule[emails[i]]);
        let fbSlots: any = this.getFreeBusySlot(params.startTime, params.endTime, emails[i]);
        if (fbSlots.f || fbSlots.u || fbSlots.b || fbSlots.t || fbSlots.n) entryExists = true;
        console.log("[SchedulerComponent][getFreeBusyInfo] from cache", this._schedule[emails[i]], fbSlots);
      }
      if (!entryExists) requiredEmails.push(emails[i]);
    }
    if (requiredEmails.length) {
      // request from backend
      console.log("[SchedulerComponent][getFreeBusyInfo] request from backend", params, requiredEmails);
      return this._getFreeBusyInfo(params.startTime,
        params.endTime,
        requiredEmails,
        params.callback);
    } else {
      if (params.callback) {
        params.callback();
      } else {
        this._handleResponseFreeBusySV(params);
      }
      return null;
    }

  }

  private _handleErrorFreeBusyCache(params, result) {
    if (params.errorCallback) {
      params.errorCallback(result);
    }
  }

  getFreeBusySlot(startTime, endTime, id, excludeTimeSlots?: boolean) {
    let slotDate = new Date(startTime);
    slotDate.setHours(0, 0, 0, 0);

    let fbSlots = this._schedule[id] || [];
    let fbResult: any = { id: id };
    // free busy response is always merged
    let usr, searchRange, newSearchIsInRange;
    for (let i = fbSlots.length; --i >= 0;) {
      usr = fbSlots[i];
      searchRange = usr.searchRange;

      if (searchRange) {
        newSearchIsInRange = (startTime >= searchRange.startTime && endTime <= searchRange.endTime);
        if (!newSearchIsInRange) continue;
      }

      if (usr.n) this._addFBInfo(usr.n, id, STATUS_UNKNOWN, startTime, endTime, fbResult, excludeTimeSlots);
      if (usr.t) this._addFBInfo(usr.t, id, STATUS_TENTATIVE, startTime, endTime, fbResult, excludeTimeSlots);
      if (usr.b) this._addFBInfo(usr.b, id, STATUS_BUSY, startTime, endTime, fbResult, excludeTimeSlots);
      if (usr.u) this._addFBInfo(usr.u, id, STATUS_OUT, startTime, endTime, fbResult, excludeTimeSlots);
      if (usr.f) this._addFBInfo(usr.f, id, STATUS_FREE, startTime, endTime, fbResult, excludeTimeSlots);
    }
    console.log("[getFreeBusySlot]", startTime, endTime, id, excludeTimeSlots, fbSlots, fbResult);
    return fbResult;
  }

  getFreeBusyKey(startTime, id) {
    return startTime + "-" + id;
  }

  getWorkingHoursKey(id, day) {
    return id + "-" + day;
  }

  private _addFBInfo(slots, id, status, startTime, endTime, fbResult, excludeTimeSlots) {

    if (!fbResult[status]) fbResult[status] = [];

    for (let i = 0; i < slots.length; i++) {
      let fbSlot;
      if (slots[i].s >= startTime && slots[i].e <= endTime) {
        fbSlot = { s: slots[i].s, e: slots[i].e };
      } else if (startTime >= slots[i].s && endTime <= slots[i].e) {
        fbSlot = { s: startTime, e: endTime };
      } else if (startTime >= slots[i].s && startTime <= slots[i].e) {
        fbSlot = { s: startTime, e: slots[i].e };
      } else if (endTime >= slots[i].s && endTime <= slots[i].e) {
        fbSlot = { s: slots[i].s, e: endTime };
      }

      if (fbSlot) {
        if (excludeTimeSlots && status !== STATUS_FREE && status !== STATUS_UNKNOWN) {
          this._addByExcludingTime(excludeTimeSlots, fbSlot, fbResult, status);
        } else {
          fbResult[status].push(fbSlot);
        }
      }
    }
    if (fbResult[status].length === 0) fbResult[status] = null;
  }

  private _addByExcludingTime(excludeTimeSlots, fbSlot, fbResult, status) {
    let startTime = excludeTimeSlots.s;
    let endTime = excludeTimeSlots.e;
    let newFBSlot;

    if (fbSlot.s === startTime && fbSlot.e === endTime) {
      return;
    }

    if (fbSlot.s < startTime && fbSlot.e > endTime) {
      fbResult[status].push({ s: fbSlot.s, e: startTime });
      newFBSlot = { s: endTime, e: fbSlot.e };
    } else if (fbSlot.s < startTime && fbSlot.e >= startTime) {
      newFBSlot = { s: fbSlot.s, e: startTime };
    } else if (fbSlot.s <= endTime && fbSlot.e > endTime) {
      newFBSlot = { s: endTime, e: fbSlot.e };
    } else if (fbSlot.s <= startTime && fbSlot.e <= startTime) {
      newFBSlot = { s: fbSlot.s, e: fbSlot.e };
    } else if (fbSlot.s >= endTime && fbSlot.e >= endTime) {
      newFBSlot = { s: fbSlot.s, e: fbSlot.e };
    }

    if (newFBSlot) {
      fbResult[status].push(newFBSlot);
    }
  }

  getAllWorkingHours() {
    return this._workingHrs;
  }

  // working hrs related code
  getWorkingHours(params) {
    let requiredEmails = [], whKey, emails = params.emails || [];
    for (let i = emails.length; --i >= 0;) {
      whKey = this.getWorkingHoursKey(emails[i], (new Date(params.startTime)).getDay());
      // check local cache
      if (!this._workingHrs[whKey]) requiredEmails.push(emails[i]);
    }
    console.log("[getWorkingHours]", params, requiredEmails, this._workingHrs);
    if (requiredEmails.length) {
      console.log("[getWorkingHours] get from backend");
      return this._getWorkingHours(params.startTime,
        params.endTime,
        requiredEmails, params.callback);
    } else {
      
      if (params.callback) {
        console.log("[getWorkingHours][callback] get from cache");
        params.callback();
      } else {
        console.log("[getWorkingHours][_handleResponseWorking] get from cache");
        this._handleResponseWorking({ startTime: this._getStartTime(), endTime: this._getStartTime() + MSEC_PER_DAY, emails }); // handle response for UI
      }
      return null;
    }

  }

  _handleResponseWorkingHrs(params, result) {
    console.log("[_handleResponseWorkingHrs]", params, result);
    let args = result.usr || [];
    for (let i = 0; i < args.length; i++) {
      let usr = args[i];
      let id = usr.id;
      if (!id) {
        continue;
      }
      this._addWorkingHrInfo(usr);
    }

    if (params.callback) {
      params.callback(result);
    }
  }

  private _addWorkingHrInfo(usr) {
    let id = usr.id;
    if (usr.f) this._addWorkingHrSlot(usr.f, id, STATUS_WORKING_HOURS);
    if (usr.u) this._addWorkingHrSlot(usr.u, id, STATUS_NON_WORKING_HOURS);
    if (usr.n) this._addWorkingHrSlot(usr.n, id, STATUS_UNKNOWN);
  }

  private _addWorkingHrSlot(slots, id, status) {
    console.log("[_addWorkingHrSlot]", slots, id, status);
    let slotTime, slotDate, whKey, whSlots;
    for (let i = 0; i < slots.length; i++) {
      slotTime = slots[i].s;
      slotDate = new Date(slotTime);
      whKey = this.getWorkingHoursKey(id, slotDate.getDay());
      whSlots = this._workingHrs[whKey];
      if (!whSlots) {
        this._workingHrs[whKey] = whSlots = { id: id };
      }

      if (!whSlots[status]) {
        whSlots[status] = [];
      }
      whSlots[status].push(slots[i]);

      // unknown working hours slots will be compressed on server response (will send one accumulated slots)
      if (status === STATUS_UNKNOWN) {
        this._workingHrs[id] = whSlots;
      }
    }
  }

  clearCache() {
    this._schedule = {};
    this._workingHrs = {};
  }

  private _getWorkingHours(startTime, endTime, emails, callback?: any) {
    console.log("[_getWorkingHours] emails ", emails);
    this.calendarRepository.getWorkingHours({ startTime, endTime, name: emails.join(",") }).subscribe(v => {
      this._handleResponseWorkingHrs({ startTime, endTime, emails }, v); // add to cache
      if (typeof callback === "function") {
        callback();
      } else {
        this._handleResponseWorking({ startTime: this._getStartTime(), endTime: this._getStartTime() + MSEC_PER_DAY, emails }, v); // handle response for UI
      }
    }, err => {
      console.log("[_getWorkingHours][getWorkingHours] err ", err);
      this._handleErrorWorkingHrs({ startTime: this._getStartTime(), endTime: this._getStartTime() + MSEC_PER_DAY }, err);
    });
  }

  private _handleErrorWorkingHrs(params, result) {
    if (params.errorCallback) {
      params.errorCallback(result);
    }
  }

  getWorkingHrsSlot(startTime, endTime, id) {
    let whKey = this.getWorkingHoursKey(id, (new Date(startTime)).getDay());
    let whSlots = this._workingHrs[whKey] || {};
    let whResult = { id: id };
    // handle the case where the working hours are not available and slot dates are accumulated
    let unknownSlots = this._workingHrs[id];
    if (unknownSlots) {
      return unknownSlots;
    }

    if (whSlots[STATUS_WORKING_HOURS]) whResult[STATUS_WORKING_HOURS] = whSlots[STATUS_WORKING_HOURS];
    if (whSlots[STATUS_NON_WORKING_HOURS]) whResult[STATUS_NON_WORKING_HOURS] = whSlots[STATUS_NON_WORKING_HOURS];
    if (whSlots[STATUS_UNKNOWN]) whResult[STATUS_UNKNOWN] = whSlots[STATUS_UNKNOWN];

    if (!whResult[STATUS_WORKING_HOURS] && !whResult[STATUS_NON_WORKING_HOURS] && !whResult[STATUS_UNKNOWN]) return null;
    return whResult;
  }

  _addWHInfo(slots, id, status, startTime, endTime, whResult) {

    if (!whResult[status]) whResult[status] = [];

    for (let i = 0; i < slots.length; i++) {
      if (startTime >= slots[i].s && endTime <= slots[i].e) {
        whResult[status].push({ s: startTime, e: endTime });
      } else if (slots[i].s >= startTime && slots[i].e <= endTime) {
        whResult[status].push({ s: slots[i].s, e: slots[i].e });
      } else if (slots[i].s >= startTime && slots[i].s <= endTime) {
        whResult[status].push({ s: slots[i].s, e: endTime });
      } else if (slots[i].e >= startTime && slots[i].e <= endTime) {
        whResult[status].push({ s: startTime, e: slots[i].e });
      }
    }

    if (whResult[status].length === 0) whResult[status] = null;
  }

  convertWorkingHours(slot, relativeDate) {
    relativeDate = relativeDate || new Date();
    let slotStartDate = new Date(slot.s);
    let slotEndDate = new Date(slot.e);
    let dur = slot.e - slot.s;
    slot.s = (new Date(relativeDate.getTime())).setHours(slotStartDate.getHours(), slotStartDate.getMinutes(), 0, 0);
    slot.e = slot.s + dur;
  }

  private _processDateInfo(dateInfo) {
    let startDate = dateInfo.startDate;
    let endDate = dateInfo.endDate;
    if (dateInfo.isAllDay) {
      startDate.setHours(0, 0, 0, 0);
      this._startDate = startDate;
      endDate.setHours(23, 59, 59, 999);
      this._endDate = endDate;
    } else {
      this._startDate = dateInfo.startTimeStr;
      this._endDate = dateInfo.endTimeStr;
    }
  }

  isBooked(slots, startTime, endTime) {
    for (let i = 0; i < slots.length; i++) {
      const startConflict = startTime >= slots[i].s && startTime < slots[i].e;
      const endConflict = endTime > slots[i].s && endTime <= slots[i].e;
      const inlineSlotConflict = slots[i].s >= startTime && slots[i].e <= endTime;
      if (startConflict || endConflict || inlineSlotConflict) {
        return false;
      }
    }
    return true;
  }

  private _detectConflict(email, startTime, endTime) {
    let sched = this.getFreeBusySlot(startTime, endTime, email);
    let isFree = true;
    if (sched.b) isFree = isFree && this.isBooked(sched.b, startTime, endTime);
    if (sched.t) isFree = isFree && this.isBooked(sched.t, startTime, endTime);
    if (sched.u) isFree = isFree && this.isBooked(sched.u, startTime, endTime);

    this._fbConflict[email] = isFree;
  }

  private _clearColoredCells(sched) {
    while (sched._coloredCells.length > 0) {
      // decrement cell count in all attendees row
      let idx = sched._coloredCells[0].cellIndex;
      if (this._allAttendees[idx] > 0) {
        this._allAttendees[idx] = this._allAttendees[idx] - 1;
      }

      sched._coloredCells[0].className = FREE_CLASS;
      sched._coloredCells.shift();
    }
    let allAttColors = this._allAttendeesSlot._coloredCells;
    while (allAttColors.length > 0) {
      let idx = allAttColors[0].cellIndex;
      // clear all attendees cell if it's now free
      if (this._allAttendees[idx] === 0) {
        allAttColors[0].className = FREE_CLASS;
      }
      allAttColors.shift();
    }
  }

  // Callbacks
  private _handleResponseFreeBusySV(params, result?: any) {
    console.log("[_handleResponseFreeBusySV]", params, result);
    if (result) {
      this._handleResponseFreeBusyCache({ startTime: params.startTime, endTime: params.endTime, emails: params.emails }, result); // add response to cache
    }
    this._freeBusyRequest = null;
    const dateInfo = this._dateInfo;
    this._processDateInfo(dateInfo);
    // Adjust start and end time by 1 msec, to avoid fencepost problems when detecting conflicts
    let apptStartTime = this._startDate.getTime(),
      apptEndTime = this._endDate.getTime(),
      apptConflictStartTime = apptStartTime + 1,
      apptConflictEndTime = apptEndTime - 1,
      appt = this._appt,
      orgEmail = appt && !appt.inviteNeverSent ? appt.organizer : null,
      apptOrigStartTime = appt ? appt.getOrigStartTime() : null,
      apptOrigEndTime = appt ? (dateInfo.isAllDay ? appt.getOrigEndTime() - 1 : appt.getOrigEndTime()) : null,
      apptTimeChanged = appt ? !(apptOrigStartTime === apptStartTime && apptOrigEndTime === apptEndTime) : false;
    for (let i = 0; i < params.emails.length; i++) {
      let email = params.emails[i];

      this._detectConflict(email, apptConflictStartTime, apptConflictEndTime);

      // first clear out the whole row for this email id
      let sched = this._schedTable[this._emailToIdx[email]],
        attendee = sched ? sched.attendee : null,
        ptst = attendee ? attendee.getParticipantStatus() : null,
        usr = this.getFreeBusySlot(params.startTime, params.endTime, email),
        table: any = sched ? document.getElementById(sched.dwtTableId) : null;

      if (usr && (ptst === SchedulerUtils.PSTATUS_ACCEPT || email === orgEmail)) {
        if (!usr.b) {
          usr.b = [];
        }
        if (apptTimeChanged) {
          usr.b.push({ s: apptOrigStartTime, e: apptOrigEndTime });
        } else {
          usr.b.push({ s: apptStartTime, e: apptEndTime });
        }
      }

      if (table) {
        table.rows[0].className = "ZmSchedulerNormalRow";
        this._clearColoredCells(sched);

        if (!usr) continue;
        sched.uid = usr.id;

        // next, for each free/busy status, color the row for given start/end times
        if (usr.n) this._colorSchedule(SchedulerUtils.freeBusySchedulerView().STATUS_UNKNOWN, usr.n, table, sched);
        if (usr.t) this._colorSchedule(SchedulerUtils.freeBusySchedulerView().STATUS_TENTATIVE, usr.t, table, sched);
        if (usr.b) this._colorSchedule(SchedulerUtils.freeBusySchedulerView().STATUS_BUSY, usr.b, table, sched);
        if (usr.u) this._colorSchedule(SchedulerUtils.freeBusySchedulerView().STATUS_OUT, usr.u, table, sched);
      } else {

        // update all attendee status - we update all attendee status correctly even if we have slight
        if (!usr) continue;

        if (usr.n) this._updateAllAttendees(SchedulerUtils.freeBusySchedulerView().STATUS_UNKNOWN, usr.n);
        if (usr.t) this._updateAllAttendees(SchedulerUtils.freeBusySchedulerView().STATUS_TENTATIVE, usr.t);
        if (usr.b) this._updateAllAttendees(SchedulerUtils.freeBusySchedulerView().STATUS_BUSY, usr.b);
        if (usr.u) this._updateAllAttendees(SchedulerUtils.freeBusySchedulerView().STATUS_OUT, usr.u);

      }
    }

    const weekStartDate = new Date(params.startTime);
    const dow = weekStartDate.getDay();
    weekStartDate.setDate(weekStartDate.getDate() - ((dow + 7)) % 7);


    const whrsParams = {
      startTime: weekStartDate.getTime(),
      endTime: weekStartDate.getTime() + 7 * MSEC_PER_DAY,
      emails: params.emails,
      noBusyOverlay: true,
      account: null
    };
    this._workingHoursRequest = this.getWorkingHours(whrsParams);
    this.showConflicts();
  }

  private _colorSchedule(status, slots, table, sched) {
    let row = table.rows[0];
    let className = this._getClassForStatus(status);

    let currentDate = this._getStartDate();
    console.log("[_colorSchedule]", status, slots, table, sched);
    if (row && className) {
      // figure out the table cell that needs to be colored
      for (let i = 0; i < slots.length; i++) {
        
        if (status === SchedulerUtils.freeBusySchedulerView().STATUS_WORKING) {
          this.convertWorkingHours(slots[i], currentDate);
        }
        let startIdx = this._getIndexFromTime(slots[i].s);
        let endIdx = this._getIndexFromTime(slots[i].e, true);

        if (slots[i].s <= currentDate.getTime()) {
          startIdx = 0;
        }

        if (slots[i].e >= currentDate.getTime() + MSEC_PER_DAY) {
          endIdx = SchedulerUtils.freeBusySchedulerView().FREEBUSY_NUM_CELLS - 1;
        }

        if (startIdx < 0) { startIdx = 0; }
        if (endIdx < 0) { continue; }

        // normalize
        if (endIdx < startIdx) {
          endIdx = SchedulerUtils.freeBusySchedulerView().FREEBUSY_NUM_CELLS - 1;
        }

        for (let j = startIdx; j <= endIdx; j++) {
          if (row.cells[j]) {

            if (status !== SchedulerUtils.freeBusySchedulerView().STATUS_UNKNOWN) {
              this._allAttendees[j] = this._allAttendees[j] + 1;
              this.updateAllAttendeeCellStatus(j, status);
            }

            if (row.cells[j].className.indexOf(SchedulerUtils.freeBusySchedulerView().FREE_CLASS) === -1 && status === SchedulerUtils.freeBusySchedulerView().STATUS_WORKING) {
              // do not update anything if the status is already changed
              continue;
            }
            sched._coloredCells.push(row.cells[j]);
            row.cells[j].className = className;
            row.cells[j]._fbStatus = status;
            sched._labelCells[j] = this.getStatusTranslationKey(status);
          }
        }

      }
    }
  }

  getStatusTranslationKey(status) {
    let key = "STATUS_FREE";
    switch (status) {
      case 2: key = "STATUS_BUSY"; break;
      case 3: key = "STATUS_TENTATIVE"; break;
      case 4: key = "STATUS_OUT"; break;
      case 5: key = "STATUS_UNKNOWN"; break;
      case 6: key = "STATUS_WORKING"; break;
    }
    return key;
  }

  private _getStartDate() {
    return this._dateInfo.startDate;
  }

  private _getStartTime() {
    return this._getStartDate().getTime();
  }

  private _getEndTime() {
    return this._getEndDate().getTime();
  }

  private _getEndDate() {
    return this._dateInfo.endDate;
  }

  private _getClassForStatus(status) {
    return SchedulerUtils.freeBusySchedulerView().STATUS_CLASSES[status];
  }

  private _colorAllAttendees() {
    if (this._allAttendeesTable) {
      let row = this._allAttendeesTable.rows[0];
      for (let i = 0; i < this._allAttendees.length; i++) {
        let status = this.getAllAttendeeStatus(i);
        row.cells[i].className = this._getClassForStatus(status);
        this._allAttendeesSlot._coloredCells.push(row.cells[i]);
      }
    }
  }

  getAllAttendeeStatus(idx) {
    return this._allAttendeesStatus[idx] ? this._allAttendeesStatus[idx] : SchedulerUtils.freeBusySchedulerView().STATUS_FREE;
  }

  private _getIndexFromTime(time, isEnd?: boolean, adjust?: any) {
    let hourmin,
      seconds;
    if (!!adjust) {
      let dayStartTime = this._getStartTime();
      let indexTime = (time instanceof Date) ? time.getTime() : time;
      hourmin = (indexTime - dayStartTime) / 60000; // 60000 = 1000(msec) * 60 (sec) - hence, dividing by 60000 means calculating the minutes and
      seconds = (indexTime - dayStartTime) % 60000; // mod by 60000 means calculating the seconds remaining

    } else {
      let d = (time instanceof Date) ? time : new Date(time);
      hourmin = d.getHours() * 60 + d.getMinutes();
      seconds = d.getSeconds();
    }
    let idx = Math.floor(hourmin / 60) * 2;
    let minutes = hourmin % 60;
    if (minutes >= 30) {
      idx++;
    }
    // end times don't mark blocks on half-hour boundary
    if (isEnd && (minutes === 0 || minutes === 30)) {
      // block even if it exceeds 1 second
      if (seconds === 0) {
        idx--;
      }
    }
    return idx;
  }

  private _updateAllAttendees(status, slots) {

    let currentDate = this._getStartDate();

    for (let i = 0; i < slots.length; i++) {
      if (status === SchedulerUtils.freeBusySchedulerView().STATUS_WORKING) {
        this.convertWorkingHours(slots[i], currentDate);
      }
      let startIdx = this._getIndexFromTime(slots[i].s);
      let endIdx = this._getIndexFromTime(slots[i].e, true);

      if (slots[i].s <= currentDate.getTime()) {
        startIdx = 0;
      }

      if (slots[i].e >= currentDate.getTime() + MSEC_PER_DAY) {
        endIdx = SchedulerUtils.freeBusySchedulerView().FREEBUSY_NUM_CELLS - 1;
      }

      // bug:45623 assume start index is zero if its negative
      if (startIdx < 0) { startIdx = 0; }
      // bug:45623 skip the slot that has negative end index.
      if (endIdx < 0) { continue; }

      // normalize
      if (endIdx < startIdx) {
        endIdx = SchedulerUtils.freeBusySchedulerView().FREEBUSY_NUM_CELLS - 1;
      }

      for (let j = startIdx; j <= endIdx; j++) {
        if (status !== SchedulerUtils.freeBusySchedulerView().STATUS_UNKNOWN) {
          this._allAttendees[j] = this._allAttendees[j] + 1;
          this.updateAllAttendeeCellStatus(j, status);
          console.log("[_updateAllAttendees][updateAllAttendeeCellStatus]", j, status);
        }
      }
    }
    console.log("[_updateAllAttendees]", status, slots);
  }

  updateAllAttendeeCellStatus(idx, status) {

    if (status === SchedulerUtils.freeBusySchedulerView().STATUS_WORKING) {
      return;
    }

    if (!this._allAttendeesStatus[idx]) {
      this._allAttendeesStatus[idx] = status;
    } else if (status !== this._allAttendeesStatus[idx]) {
      if (status !== SchedulerUtils.freeBusySchedulerView().STATUS_UNKNOWN &&
        status !== SchedulerUtils.freeBusySchedulerView().STATUS_FREE) {
        if (status === SchedulerUtils.freeBusySchedulerView().STATUS_OUT || this._allAttendeesStatus[idx] === SchedulerUtils.freeBusySchedulerView().STATUS_OUT) {
          this._allAttendeesStatus[idx] = SchedulerUtils.freeBusySchedulerView().STATUS_OUT;
        } else {
          this._allAttendeesStatus[idx] = SchedulerUtils.freeBusySchedulerView().STATUS_BUSY;
        }
      }
    }
  }

  private _resetAttendeeCount() {
    for (let i = 0; i < SchedulerUtils.freeBusySchedulerView().FREEBUSY_NUM_CELLS; i++) {
      this._allAttendees[i] = 0;
      delete this._allAttendeesStatus[i];
    }
  }

  private _setDateInfo(dateInfo) {
    this._dateInfo = dateInfo;
  }

  changeDate(dateInfo) {
    this._setDateInfo(dateInfo);
    this._updateFreeBusy();

    // finally, update the appt tab view page w/ new date(s)
  }

  handleTimeChange() {
    this._dateBorder = this._getBordersFromDateInfo();
    this._outlineAppt();
    this._updateFreeBusy();
  }

  updateFreeBusy() {
    this._updateFreeBusy();
  }

  private _updateFreeBusy() {
    // clear the schedules for existing attendees
    const keys = Object.keys(this._schedTable);
    for (let key of keys) {
      let sched = this._schedTable[key];
      if (!sched) {
        continue;
      }
      while (sched._coloredCells && sched._coloredCells.length > 0) {
        if (sched._coloredCells[0]) {
          sched._labelCells = {};
          sched._coloredCells[0].className = SchedulerUtils.freeBusySchedulerView().FREE_CLASS;
          sched._coloredCells.shift();
        }
      }
    }

    this._resetAttendeeCount();

    // Set in updateAttendees
    if (this.attendees && this.attendees.length > 0) {
      // all attendees status need to be update even for unshown attendees
      this._getFreeBusyInfoSV(this._getStartTime(), this.attendees);
    }
  }

  getAttendees() {
    let attendees = [];

    for (let key of Object.keys(this._schedTable)) {
      let sched = this._schedTable[key];
      if (!sched) {
        continue;
      }
      if (sched.attendee) {
        attendees.push(sched.attendee);
      }
    }
    return attendees;
  }

  _outlineAppt() {
    this._updateBorders(this._allAttendeesSlot, true);
    for (let key of Object.keys(this._schedTable)) {
      if (this._schedTable[key] && this._schedTable[key].uid) {
        this._updateBorders(this._schedTable[key]);
      }
    }
  }

  _updateBorders(sched, isAllAttendees?: any) {
    if (!sched) { return; }

    let td, div, curClass, newClass;

    // mark right borders of appropriate f/b table cells
    let normalClassName = "ZmSchedulerGridDiv";
    let halfHourClassName = normalClassName + "-halfHour";
    let startClassName = normalClassName + "-start";
    let endClassName = normalClassName + "-end";
    let table: any = document.getElementById(sched.dwtTableId);
    let row = table.rows[0];
    if (row) {
      for (let i = 0; i < ZmFreeBusySchedulerView.FREEBUSY_NUM_CELLS; i++) {
        td = row.cells[i];
        div = td ? td.getElementsByTagName("*")[0] : null;
        if (div) {
          curClass = div.className;
          newClass = normalClassName;
          if (i === this._dateBorder.start) {
            newClass = startClassName;
          } else if (i === this._dateBorder.end) {
            newClass = endClassName;
          } else if (i % 2 === 0) {
            newClass = halfHourClassName;
          }
          if (curClass !== newClass) {
            div.className = newClass;
          }
        }
      }
      td = row.cells[0];
      div = td ? td.getElementsByTagName("*")[0] : null;
      if (div && (this._dateBorder.start === -1)) {
        div.className += " " + normalClassName + "-leftStart";
      }
    }
  }

  isLocale24Hour() {
    return true;
  }

  _getBordersFromDateInfo() {
    // Setup the start/end for an all day appt
    let index = { start: -1, end: ZmFreeBusySchedulerView.FREEBUSY_NUM_CELLS - 1 };
    
    if (this._dateInfo.showTime) {
      // Not an all day appt, determine the appts start and end
      let idx = this.isLocale24Hour() ? 0 : 1;
      this._processDateInfo(this._dateInfo);

      // subtract 1 from index since we're marking right borders
      index.start = this._getIndexFromTime(this._startDate, null, false) - 1;
      if (moment(this._dateInfo.endDate).format("MM/DD/YYYY") === moment(this._dateInfo.startDate).format("MM/DD/YYYY")) {
        index.end = this._getIndexFromTime(this._endDate, true, false);
      }
    }
    console.log("[SchedulerComponent][_getBordersFromDateInfo]", this._dateInfo, index);
    return index;
  }

  _removeAttendeeRow(email) {
    const index = this._emailToIdx[email];
    delete this._schedTable[index];
    delete this._emailToIdx[email];
    delete this._fbConflict[email];
    delete this.attendeeTypes[email];
    this.attendeeRows = this.attendeeRows.filter(v => v?.data?.organizer !== email);
    this.attendees = this.attendees.filter(v => v !== email);
  }

  removeAttendee(email, updateFreeBusy?: boolean) {
    this._removeAttendeeRow(email);
    this._outlineAppt();
    if (updateFreeBusy) {
      this.updateFreeBusy();
      this.postUpdateHandler();
    }
  }

  cleanup() {
    // remove all but first two rows (header and All Attendees)
    while (this._attendeesTable.rows.length > 2) {
      this._attendeesTable.deleteRow(2);
      this._schedTable.splice(2, 1);
    }
    // cleanup all attendees row
    let allAttCells = this._allAttendeesSlot._coloredCells;
    while (allAttCells.length > 0) {
      allAttCells[0].className = ZmFreeBusySchedulerView.FREE_CLASS;
      allAttCells.shift();
    }

    this._resetAttendeeCount();
    this.attendees = [];
    this.attendeeRows = [];
    this._schedTable = [];
    this._emailToIdx = {};

    // reset autocomplete lists
    if (this._acContactsList) {
      this._acContactsList.reset();
      this._acContactsList.show(false);
    }
    if (this._acEquipmentList) {
      this._acEquipmentList.reset();
      this._acEquipmentList.show(false);
    }

    this._fbConflict = {};
  }

  public addAttendeeRow(isAllAttendees, organizer, drawBorder?: any, index?: any) {
    this._addAttendeeRow(isAllAttendees, organizer, drawBorder, index);
  }

  public resetAttendees(attendees) {
    for (let email of this.attendees) {
      if (email !== this.getOrganizerEmail()) {
        this.removeAttendee(email);
      }
    }
    this.setAttendees(attendees);
  }

  public updateAttendeesByType(attendees: string[], type: string) {
    for (let att of Object.keys(this.attendeeTypes)) {
      if (this.attendeeTypes[att] === type) {
        if (attendees.includes(att)) {
          this.removeAttendee(att);
        }
      }
    }
    for (let attendee of attendees) {
      if (!this.attendees.find(v => v === attendee)) {
        this._addAttendeeRow(false, attendee);
        this.attendees.push(attendee);
        this.attendeeTypes[attendee] = type;
      }
    }
    this._updateBorders(this._allAttendeesSlot, true);
    this._getFreeBusyInfoSV(this._getStartTime(), this.attendees);
  }

  public resetAttendeesByType(attendees: string[], type: string) {
    for (let att of Object.keys(this.attendeeTypes)) {
      if (this.attendeeTypes[att] === type) {
          this.removeAttendee(att);
      }
    }
    for (let attendee of attendees) {
      if (!this.attendees.find(v => v === attendee)) {
        this._addAttendeeRow(false, attendee);
        this.attendees.push(attendee);
        this.attendeeTypes[attendee] = type;
      }
    }
    this._updateBorders(this._allAttendeesSlot, true);
    this._getFreeBusyInfoSV(this._getStartTime(), this.attendees);
  }

  public setAttendees(attendees) {
    this.attendees = attendees;
    for (let attendee of this.attendees) {
      if (!this.attendeeRows.find(v => v.data.organizer === attendee)) {
        this._addAttendeeRow(false, attendee);
      }
    }
    this._updateBorders(this._allAttendeesSlot, true);
    this._getFreeBusyInfoSV(this._getStartTime(), this.attendees);
  }

  public addAttendee(attendee, type?: string) {
    if (this.attendees.indexOf(attendee) === -1) {
      this.attendees.push(attendee);
      this.attendeeTypes[attendee] = type || "PERSON";
      if (!this.attendeeRows.find(v => v.data.organizer === attendee)) {
        this._addAttendeeRow(false, attendee);
      }
      this._getFreeBusyInfoSV(this._getStartTime(), [attendee]);
    }
  }

  public updateAttendeeType(attendee, type) {
    this.attendeeTypes[attendee] = type;
  }

  _addAttendeeRow(isAllAttendees, organizer, drawBorder?: any, index?: any) {
    index = index || new Date().getTime();
    // store some meta data about this table row
    let sched: any = {};
    let dwtId = "DWT" + index;	// container for input
    sched.dwtNameId = dwtId + "_NAME_";			// TD that contains name
    sched.dwtTableId = dwtId + "_TABLE_";		// TABLE with free/busy cells
    sched.dwtSelectId = dwtId + "_SELECT_";		// TD that contains select menu
    sched.dwtInputId = dwtId + "_INPUT_";		// input field
    sched.idx = index;
    sched._coloredCells = [];
    sched._labelCells = {};
    this._schedTable[index] = sched;
    this._emailToIdx[organizer] = index;
    this._dateBorder = this._getBordersFromDateInfo();

    let data = {
      id: dwtId,
      sched: sched,
      isAllAttendees: isAllAttendees,
      organizer: organizer,
      cellCount: ZmFreeBusySchedulerView.FREEBUSY_NUM_CELLS,
      isComposeMode: this.isComposeMode,
      dateBorder: this._dateBorder
    };
    this.attendeeRows.push({ data, sched });

    if (drawBorder) {
      this._updateBorders(sched, isAllAttendees);
    }
    return index;
  }

  getClassName(data, index) {
    let normalClassName = "ZmSchedulerGridDiv", newClass = "";
    newClass = normalClassName;
    if (index === data.dateBorder.start) {
      newClass = normalClassName + "-start";
    } else if (index === data.dateBorder.end) {
      newClass = normalClassName + "-end";
    } else if (index % 2 === 0) {
      newClass = normalClassName + "-halfHour";
    }
    if ((index === 0) && (data.dateBorder.start === -1)) {
      newClass += " " + normalClassName + "-leftStart";
    }
    return newClass;
  }

  public setDateInfo(startDate, endDate, timezone, startTime, endTime, showTime?: boolean, isAllDay?: boolean) {
    console.log("[SchedulerComponent][setDateInfo]", startDate, endDate, timezone, startTime, endTime, showTime, isAllDay);
    let dateInfo: any = {};
    dateInfo.startDate = new Date(startDate.getTime());
    dateInfo.endDate = new Date(endDate.getTime());
    dateInfo.startDate.setHours(0, 0, 0, 0);
    dateInfo.endDate.setHours(0, 0, 0, 0);
    dateInfo.timezone = timezone;
    dateInfo.showTime = showTime;
    dateInfo.isAllDay = isAllDay;
    if (isAllDay) {
      dateInfo.showTime = false;
      dateInfo.startHourIdx = dateInfo.startMinuteIdx = dateInfo.startAmPmIdx =
        dateInfo.endHourIdx = dateInfo.endMinuteIdx = dateInfo.endAmPmIdx = null;
      dateInfo.startTimeStr = dateInfo.endTimeStr = null;
      dateInfo.duration = MSEC_PER_DAY;
    } else {
      startTime.setSeconds(0);
      endTime.setSeconds(0);
      dateInfo.startTimeStr = startTime;
      dateInfo.endTimeStr = endTime;
      dateInfo.showTime = true;
      dateInfo.isAllDay = false;
      dateInfo.duration  = endTime - startTime;
    }
    this._dateInfo = dateInfo;
    this._dateBorder = this._getBordersFromDateInfo();
    console.log("[SchedulerComponent][setDateInfo][dateInfo]", dateInfo);

    this._outlineAppt();
    this.updateFreeBusy();
    this.postUpdateHandler();
  }

  getDurationInfo() {
    return this._dateInfo;
  }

  _handleResponseWorking(params, result?: any) {
    console.log("[_handleResponseWorking]", params);
    this._workingHoursRequest = null;

    for (let i = 0; i < params.emails.length; i++) {
      let email = params.emails[i];
      let usr = this.getWorkingHrsSlot(params.startTime, params.endTime, email);

      if (!usr) continue;

      // first clear out the whole row for this email id
      let sched = this._schedTable[this._emailToIdx[usr.id]];
      let table = sched ? document.getElementById(sched.dwtTableId) : null;
      console.log("[_handleResponseWorking]", params, usr, sched, table);
      if (table) {
        sched.uid = usr.id;
        // next, for each free/busy status, color the row for given start/end times
        if (usr.f) {
          this._colorSchedule(ZmFreeBusySchedulerView.STATUS_WORKING, usr.f, table, sched);
        }
        // show entire day as working hours if the information is not available (e.g. external accounts)
        if (usr.n) {
          let currentDay = this._getStartDate();
          let entireDaySlot = {
            s: currentDay.getTime(),
            e: currentDay.getTime() + MSEC_PER_DAY
          };
          this._colorSchedule(ZmFreeBusySchedulerView.STATUS_WORKING, [entireDaySlot], table, sched);
        }
      }
    }

    if (params.callback) {
      params.callback();
    } else {
      this.postUpdateHandler();
    }
  }

  postUpdateHandler() {
    this._colorAllAttendees();
    this.showConflicts();
  }

}
