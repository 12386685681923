
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-advanced-search-dialog mail-dialog">
    <div class="mail__dialog-header" layout="row center-center">
        <div [fxHide.gt-sm]="true">
            <button (click)="close()" class="brand-color mat-button">
                <mat-icon class="disable-select">chevron_left</mat-icon>
            </button>
        </div>
        <div class="header-title ">
            <span>{{ 'ADVANCED_SEARCH' | translate }}</span>
        </div>
        <!-- <div class="mobile-right-close">
            <button class="clearSearchTextBtn" mat-icon-button (click)="clearSearchText()">
                <mat-icon>clear</mat-icon>
            </button>
        </div> -->
    </div>
    <div class="mail__dialog-body folder-body mail__dialog-body--pad">
        <div class="search-group">
            <div class="search-option">

                <input matInput id="mobileSearchInput" type="text" fxFlex="1 1 auto"
                    [(ngModel)]="searchKeyword" (keyup.enter)="search()"
                    placeholder="{{ (searchFor === 'mail' ? 'SEARCH_EMAILS' : (searchFor === 'contact' ? 'SEARCH_CONTACTS' :  'SEARCH_BRIEFCASES') ) | translate}}" />
            </div>
        </div>
        <div class="search-group">
            <label>{{ 'SEARCH_IN' | translate }}</label>
            <div class="search-option">
                <mat-select id="searchInOption" panelClass="searchInOption" [(ngModel)]="searchFor">
                    <mat-option (click)="setSearchFor(option.value)" *ngFor="let option of options"
                        [value]="option.value">
                        {{ option.key | translate}}
                    </mat-option>
                </mat-select>
            </div>
        </div>
        <h4 class="group-title">{{ 'BASIC_FILTERS' | translate }}</h4>

        <div class="search-group">
            <div class="search-option">
                <div>
                    <mat-checkbox (click)="$event.preventDefault();setIncludeSharedItems()"
                        [(ngModel)]="includeSharedItems"> {{ 'INCLUDE_SHARED_ITEMS' | translate }}</mat-checkbox>
                </div>
            </div>
        </div>
    </div>
</div>