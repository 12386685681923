
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "./index";
import { AppState } from "../reducers/app";
import { UserProfile } from "../shared/models/user-profile";
import { Contact } from "../mail/shared/models/contact";
import { SwipeAction } from "../common/models/swipe-action.model";

export class AppActionTypes {
  static LOGIN_REQUEST = "[App] Login Request";
  static LOGIN_SUCCESS = "[App] Login Success";
  static LOGIN_FAILED = "[App] Login Failed";
  static LOGOUT = "[App] logout";
  static SET_SIDEBAR_STATUS = "[App] Expand-Collapse Sidebar";
  static SET_USER_PROFILE = "[App] Set User Profile";
  static APP_ONLINE = "[App] App Online";
  static DEVICE_READY = "[App] Device Ready";

  static RESTORE_SAVED_STATE = "[App] App Restore saved state";
  static START_PROCESSING = "[App] Start processing action";
  static STOP_PROCESSING = "[App] Stop processing action";
  static SAVE_CONTACTS_PROFILE = "[App] Save contacts profile";
  static SET_SESSION = "[App] Set Session";
  static SET_POLLING_INTERVAL = "[App] Set Polling Interval";
  static CHANGE_SWIPE_ACTION = "[App] Change Swipe Action";
  static SET_AVAILABLE_APPS = "[App] set available apps";
  static SET_READING_PANE = "[App] set reading pane";
  static SET_VIEW_BY = "[App] set view by";
  static SET_EXPAND_CONVERSATION = "[App] set view by";
  static SET_AVAILABLE_ZIMLETS = "[APP] set available zimlets";
  static SET_CURRENT_FOLDER = "[APP] Set Current Folder";
  static SET_CURRENT_QUERY = "[APP] Set Current Query";
  static SET_FIREBASE_TOKEN = "[App] set firebase token";
  static SET_VNCMAIL_PROPS = "[App] set vncmail props";
  static SET_ZIMBRA_FEATURES = "[APP] set zimbra features";
  static SET_LAST_PHOTO_UPDATE = "[APP] Set Last Photo Update";
  static RESTORE_LAST_PHOTO_UPDATE = "[APP] Restore Last Photo Update";
  static RESET_LAST_PHOTO_UPDATE = "[APP] Reset Last Photo Update";
  static DATABASE_READY = "[App] Database Ready";
  static SET_DOMAIN_SPECIFIC_LOGO = "[APP] Set Domain specific logo";
  static SET_SEARCH_FOR = "[APP] Set Search For";
  static SET_INCLUDE_SHARED = "[APP] Set include shared items";
  static SET_WAIT_DISALLOWED = "[APP] Set waitDisallowed";
  static SET_EXPAND_MAIL_FOLDERS = "[APP] set expand mail folders";
  static SET_INCOMING_FILTERS = "[APP] Set Incoming Mail Filters";
  static SET_USER_CONTACTS = "[APP] Set User Contacts";
  static SET_GAL_CONTACTS = "[APP] Set Gal Contacts";

  static SET_USER_SIGNATURES = "[APP] Set User Signatures";
  static SET_EXPAND_CONTACT_FOLDERS = "[APP] set expand contact folders";

  static SET_EXPAND_BRIEFCASE_FOLDERS = "[APP] set expand Briefcase folders";

  static SET_FOLDER_TREE_SASH = "[APP] set sidebar folder tree sash";
  static SET_PANE_SASH_VERTICAL = "[APP] set pane sash vertical";
  static SET_PANE_SASH_HORIZONTAL = "[APP] set pane sash horizontal";
  static SET_INSECURE_LOAD_CONTENT = "[APP] set insecure load content";
  static SET_SHOW_GLOBAL_TAGS = "[APP] set show global tags";
  static SET_LAST_DRAFT_ID = "[APP] Set last draft id for compose";
  static SET_RIGHT_SIDEBAR_STATUS = "[APP] Set right sidebar status";
  static SET_ACTIVE_PROFILE = "[APP] Set active profile";
  static SET_SAVED_RECENT_SEARCH = "[APP] Set saved recent search";
}


export class Logout implements Action {
  readonly type = AppActionTypes.LOGOUT;
}

export class LoginRequest implements Action {
  readonly type = AppActionTypes.LOGIN_REQUEST;
}

export class SetSidebarStatus implements Action {
  readonly type = AppActionTypes.SET_SIDEBAR_STATUS;
  constructor(public payload: boolean) {
  }
}

export class SetLastDraftId implements Action {
  readonly type = AppActionTypes.SET_LAST_DRAFT_ID;
  constructor(public payload: string) {
  }
}
export class SetRightSidebarStatus implements Action {
  readonly type = AppActionTypes.SET_RIGHT_SIDEBAR_STATUS;
  constructor(public payload: boolean) {
  }
}
export class SetActiveProfile implements Action {
  readonly type = AppActionTypes.SET_ACTIVE_PROFILE;
  constructor(public payload: string) {
  }
}
export class SetReadingPanel implements Action {
  readonly type = AppActionTypes.SET_READING_PANE;
  constructor(public payload: string) {
  }
}

export class SetViewBy implements Action {
  readonly type = AppActionTypes.SET_VIEW_BY;
  constructor(public payload: string) {
  }
}

export class SetExpandConversation implements Action {
  readonly type = AppActionTypes.SET_EXPAND_CONVERSATION;
  constructor(public payload: string) {
  }
}

export class SetUserProfile implements Action {
  readonly type = AppActionTypes.SET_USER_PROFILE;
  constructor(public payload: UserProfile) {
  }
}

export class SetSession implements Action {
  readonly type = AppActionTypes.SET_SESSION;
  constructor(public payload: any) {
  }
}

export class SetPollingInterval implements Action {
  readonly type = AppActionTypes.SET_POLLING_INTERVAL;
  constructor(public payload: number) {
  }
}

export class LoginSuccess implements Action {
  readonly type = AppActionTypes.LOGIN_SUCCESS;
  constructor() {
  }
}

export class LoginFailed implements Action {
  readonly type = AppActionTypes.LOGIN_FAILED;
  constructor() {
  }
}

export class OnlineStatus implements Action {
  readonly type = AppActionTypes.APP_ONLINE;
  constructor(public payload: boolean) {
  }
}

export class DeviceReady implements Action {
  readonly type = AppActionTypes.DEVICE_READY;
  constructor(public payload: boolean) {
  }
}

export class ChangeSwipeAction implements Action {
  readonly type = AppActionTypes.CHANGE_SWIPE_ACTION;
  constructor(public payload: SwipeAction) {
  }
}

export class RestoreSavedState implements Action {
  readonly type = AppActionTypes.RESTORE_SAVED_STATE;
  constructor(public payload: {
    appState: AppState
  }) {
  }
}

export class StartProcessing implements Action {
  readonly type = AppActionTypes.START_PROCESSING;
  constructor() {
  }
}

export class StopProcessing implements Action {
  readonly type = AppActionTypes.STOP_PROCESSING;
  constructor() {
  }
}

export class SaveContactProfiles implements Action {
  readonly type = AppActionTypes.SAVE_CONTACTS_PROFILE;
  constructor(public payload: Contact[]) {
  }
}

export class SetAvailableApps implements Action {
  readonly type = AppActionTypes.SET_AVAILABLE_APPS;

  constructor(public payload: any[]) {
  }
}

export class SetExpandMailFolders implements Action {
  readonly type = AppActionTypes.SET_EXPAND_MAIL_FOLDERS;

  constructor(public payload: string) {
  }
}

export class SetAvailableZimlets implements Action {
  readonly type = AppActionTypes.SET_AVAILABLE_ZIMLETS;
  constructor(public payload: any[]) {
  }
}

export class SetCurrentFolder implements Action {
  readonly type = AppActionTypes.SET_CURRENT_FOLDER;
  constructor(public payload: any) {
  }
}

export class SetCurrntQuery implements Action {
  readonly type = AppActionTypes.SET_CURRENT_QUERY;
  constructor(public payload: string) {
  }
}

export class SetFirebaseToken implements Action {
  readonly type = AppActionTypes.SET_FIREBASE_TOKEN;

  constructor(public payload: string) {
  }

}
export class SetProps implements Action {
  readonly type = AppActionTypes.SET_VNCMAIL_PROPS;

  constructor(public payload: any) {
  }
}

export class SetZimbraFeatures implements Action {
  readonly type = AppActionTypes.SET_ZIMBRA_FEATURES;
  constructor(public payload: any[]) {
  }
}

export class SetLastPhotoUpdate implements Action {
  readonly type = AppActionTypes.SET_LAST_PHOTO_UPDATE;
  constructor(public payload: any) {
  }
}

export class RestoreLastPhotoUpdate implements Action {
  readonly type = AppActionTypes.RESTORE_LAST_PHOTO_UPDATE;
  constructor(public payload: any) {
  }
}

export class ResetLastPhotoUpdate implements Action {
  readonly type = AppActionTypes.RESET_LAST_PHOTO_UPDATE;
  constructor() {
  }
}

export class SetDomainSpecificLogo implements Action {
  readonly type = AppActionTypes.SET_DOMAIN_SPECIFIC_LOGO;
  constructor(public payload: any[]) {
  }
}

export class SetSearchFor implements Action {
  readonly type = AppActionTypes.SET_SEARCH_FOR;
  constructor(public payload: string) {
  }
}

export class SetIncludeShared implements Action {
  readonly type = AppActionTypes.SET_INCLUDE_SHARED;
  constructor(public payload: boolean) {
  }
}

export class SetWaitDisallowed implements Action {
  readonly type = AppActionTypes.SET_WAIT_DISALLOWED;
  constructor(public payload: boolean) {
  }
}

export class SetIncomingFilters implements Action {
  readonly type = AppActionTypes.SET_INCOMING_FILTERS;
  constructor(public payload: any[]) {
  }
}

export class SetUserContacts implements Action {
  readonly type = AppActionTypes.SET_USER_CONTACTS;
  constructor(public payload: any[]) {
  }
}

export class SetGalContacts implements Action {
  readonly type = AppActionTypes.SET_GAL_CONTACTS;
  constructor(public payload: any[]) {
  }
}

export class SetUserSignatures implements Action {
  readonly type = AppActionTypes.SET_USER_SIGNATURES;
  constructor(public payload: any[]) {
  }
}

export class SetExpandContactFolders implements Action {
  readonly type = AppActionTypes.SET_EXPAND_CONTACT_FOLDERS;
  constructor(public payload: string) {
  }
}

export class SetExpandBriefcaseFolders implements Action {
  readonly type = AppActionTypes.SET_EXPAND_BRIEFCASE_FOLDERS;

  constructor(public payload: string) {
  }
}

export class DatabaseReady implements Action {
  readonly type = AppActionTypes.DATABASE_READY;
  constructor() {

  }
}

export class SetFolderTreeSash implements Action {
  readonly type = AppActionTypes.SET_FOLDER_TREE_SASH;

  constructor(public payload: string) {
  }
}

export class SetPaneSashVertical implements Action {
  readonly type = AppActionTypes.SET_PANE_SASH_VERTICAL;

  constructor(public payload: string) {
  }
}

export class SetPaneSashHorizontal implements Action {
  readonly type = AppActionTypes.SET_PANE_SASH_HORIZONTAL;

  constructor(public payload: string) {
  }
}

export class SetSavedRecentSearch implements Action {
  readonly type = AppActionTypes.SET_SAVED_RECENT_SEARCH;

  constructor(public payload: any) {
  }
}

export class SetLoadInsecureContent implements Action {
  readonly type = AppActionTypes.SET_INSECURE_LOAD_CONTENT;

  constructor(public payload: string) {
  }
}

export class SetShowGlobalTags implements Action {
  readonly type = AppActionTypes.SET_SHOW_GLOBAL_TAGS;

  constructor(public payload: string) {
  }
}
