
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div>
    <div class="header" layout="row center-center">
        <h4>{{ 'CHANGE_PASSWORD_LBL' | translate }}</h4>
    </div>
    <div *ngIf="type==='zimbra'">
        <div class="content">
            <div *ngIf="type === 'zimbra' && urlSafe === ''">
                <div>
                    <mat-form-field>
                        <input matInput placeholder="{{'CURRENT_PASSWORD' | translate}}" type="{{ oldPassword ? 'text' : 'password' }}"
                            [(ngModel)]="oldPasswordInput">
                        <mat-icon class="mdi-16px" (click)="toggleOldPassword()" *ngIf="!oldPassword" fontSet="mdi"
                            fontIcon="mdi-eye-outline"></mat-icon>
                        <mat-icon class="mdi-16px" (click)="toggleOldPassword()" *ngIf="oldPassword" fontSet="mdi"
                            fontIcon="mdi-eye-off-outline"></mat-icon>
                    </mat-form-field>
                </div>
                <div class="new_password">
                    <mat-form-field>
                        <input matInput placeholder="{{'NEW_PASSWORD' | translate}}" type="{{ newPassword ? 'text' : 'password' }}"
                            [(ngModel)]="newPasswordInput">
                        <mat-icon class="mdi-16px" (click)="toggleNewPassword()" *ngIf="!newPassword" fontSet="mdi"
                            fontIcon="mdi-eye-outline"></mat-icon>
                        <mat-icon class="mdi-16px" (click)="toggleNewPassword()" *ngIf="newPassword" fontSet="mdi"
                            fontIcon="mdi-eye-off-outline"></mat-icon>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <input matInput placeholder="{{'RE_TYPE_PASSWORD' | translate}}" type="{{ confirmPassword ? 'text' : 'password' }}"
                            [(ngModel)]="confirmPasswordInput">
                        <mat-icon class="mdi-16px" (click)="toggleConfirmPassword()" *ngIf="!confirmPassword" fontSet="mdi"
                            fontIcon="mdi-eye-outline"></mat-icon>
                        <mat-icon class="mdi-16px" (click)="toggleConfirmPassword()" *ngIf="confirmPassword" fontSet="mdi"
                            fontIcon="mdi-eye-off-outline"></mat-icon>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="type==='directory'"></div>
    <div class="btn">
        <button mat-button (click)="close()" [disableRipple]=true>
            <div>{{ 'CANCEL' | translate }}</div>
        </button>
        <button mat-button [disableRipple]=true (click)="changePassword()">
            <div>{{ 'CHANGE_LBL' | translate }}</div>
        </button>
    </div>
</div>