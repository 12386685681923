
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { NgModule, ModuleWithProviders, Provider } from "@angular/core";
import {
  CalendarCommonModule,
  CalendarModuleConfig,
  CalendarAppointmentTitleFormatter,
  CalendarDateFormatter
} from "./common/calendar-common.module";
import { CalendarMonthModule } from "./view-month/calendar-month-view.module";
import { CalendarWeekModule } from "./view-week/calendar-week-view.module";
import { CalendarMiniModule } from "./mini-date-picker/calendar-mini-view.module";
import { CalendarUtils } from "./common/calendar-utils.provider";
import { SharedModule } from "./../../shared/shared.module";
import { SchedulerModule } from "./scheduler/scheduler.module";
import { ScheduleAssistantModule } from "./schedule-assistant/schedule-assistant.module";

export * from "./common/calendar-common.module";
export * from "./view-month/calendar-month-view.module";
export * from "./view-week/calendar-week-view.module";

@NgModule({
  imports: [
    SharedModule,
    CalendarCommonModule,
    CalendarMonthModule,
    CalendarWeekModule,
    CalendarMiniModule,
    SchedulerModule,
    ScheduleAssistantModule
  ],
  exports: [
    SharedModule,
    CalendarCommonModule,
    CalendarMonthModule,
    CalendarWeekModule,
    CalendarMiniModule,
    SchedulerModule,
    ScheduleAssistantModule
  ]
})
export class VpCalendarModule {
  static forRoot(
    dateAdapter: Provider,
    config: CalendarModuleConfig = {}
  ): ModuleWithProviders<VpCalendarModule> {
    return {
      ngModule: VpCalendarModule,
      providers: [
        dateAdapter,
        config.eventTitleFormatter || CalendarAppointmentTitleFormatter,
        config.dateFormatter || CalendarDateFormatter,
        config.utils || CalendarUtils
      ]
    };
  }
}
