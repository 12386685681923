
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { ConfigService } from "src/app/config.service";


@Injectable()
export class AvailableRoutes implements CanActivate {

  appsEnabled: any[] = [];
  constructor(private configService: ConfigService, private router: Router) {
    this.appsEnabled = this.configService.get("appsEnabled");
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const app = next.data["appName"];
    if (!this.isValidRoute(app)) {
      this.router.navigate([this.getEnabledRoute()]);
    }
    return this.isValidRoute(app);
  }

  private isValidRoute(appName: string): boolean {
    let valid: boolean;
    this.appsEnabled.forEach(app => {
      if (app.title === appName) {
        valid = app.enabled;
      }
    });
    return valid;
  }

  private getEnabledRoute(): string {
    let route = "";
    this.appsEnabled.forEach(app => {
      if (app.enabled && route.length === 0) {
        route = app.route;
      }
    });
    return route;
  }

}
