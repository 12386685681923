
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "vpFolderNameSearch"
})
export class FolderNameSearchPipe implements PipeTransform {
  matchedItem: any = [];
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    this.matchedItem = [];
    items.forEach(item => {
      if (item.name.toLowerCase().includes(searchText)) {
        this.matchedItem.push(item);
      }
      if (item.children &&  item.children.length > 0) {
        this.getMatchFolders(item.children, searchText);
      }
    });
    return this.matchedItem;
  }

  getMatchFolders(items: any[], searchText: string) {
    items.forEach(item => {
      if (item.name.toLowerCase().includes(searchText)) {
        this.matchedItem.push(item);
      }
      if (item.children &&  item.children.length > 0) {
        this.getMatchFolders(item.children, searchText);
      }
    });
  }
}
