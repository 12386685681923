
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "src/app/actions";
import { Preference, Signature, DataSource } from "../../shared/models";
import { Identity } from "../../shared/models/identity.model";
import { Attribute } from "../../shared/models/attribute.model";
export const IS_PREFERENCE_LOADING = "[PREFERENCE] Is Loading";
export const LOAD_PREFERENCE_SUCCESS = "[PREFERENCE] Load Preferences Success";
export const LOAD_PREFERENCE_FAILED = "[PREFERENCE] Load Preferences Failed";
export const UPDATE_MANY_PREFERENCES = "[PREFERENCE] Update Many Preferences";
export const UPDATE_MANY_PREFERENCES_SUCCESS = "[PREFERENCE] Update Many Preferences Success";
export const UPDATE_MANY_PREFERENCES_FAIL = "[PREFERENCE] Update Many Preferences Fail";
export const REMOVE_PREFERENCES = "[PREFERENCE] Remove Preferences";
export const UPDATE_SIGNATURES = "[PREFERENCE] Update Signatures";
export const ADD_SIGNATURE = "[PREFERENCE] Add Signature";
export const REMOVE_SIGNATURES = "[PREFERENCE] Remove Signatures";
export const RESET_SIGNATURES = "[PREFERENCE] Reset Signatures";
export const SELECT_ACCOUNT = "[PREFERENCE] Select Account";
export const SET_IDENTITY = "[PREFERENCE] Set Identity";
export const UPDATE_ATTRIBUTES = "[PREFERENCE] Update Attributes";
export const RESET_PREFERENCE_STATE = "[PREFERENCE] Update Attributes";

export class IsPreferenceLoadingAction implements Action {
    readonly type = IS_PREFERENCE_LOADING;
    constructor() { }
}

export class LoadPreferenceSuccessAction implements Action {
    readonly type = LOAD_PREFERENCE_SUCCESS;
    constructor(public payload: Preference[]) { }
}

export class LoadPreferenceFailedAction implements Action {
    readonly type = LOAD_PREFERENCE_FAILED;
    constructor() { }
}

export class RemovePreferencesAction implements Action {
    readonly type = REMOVE_PREFERENCES;
    constructor() { }
}

export class UpdateManyPreferences implements Action {
    readonly type = UPDATE_MANY_PREFERENCES;
    constructor() { }
}

export class UpdateManyPreferencesSuccess implements Action {
    readonly type = UPDATE_MANY_PREFERENCES_SUCCESS;
    constructor(public payload: Preference[]) { }
}

export class UpdateManyPreferencesFail implements Action {
    readonly type = UPDATE_MANY_PREFERENCES_FAIL;
    constructor() { }
}

export class UpdateSignatures implements Action {
    readonly type = UPDATE_SIGNATURES;
    constructor(public payload: Signature[]) { }
}

export class UpdateAttributes implements Action {
    readonly type = UPDATE_ATTRIBUTES;
    constructor(public payload: Attribute[]) { }
}

export class AddSignature implements Action {
    readonly type = ADD_SIGNATURE;
    constructor(public payload: Signature) { }
}

export class RemoveSignatures implements Action {
    readonly type = REMOVE_SIGNATURES;
    constructor(public payload: string[]) { }
}

export class ResetSignatures implements Action {
    readonly type = RESET_SIGNATURES;
    constructor() { }
}

export class SelectAccount implements Action {
    readonly type = SELECT_ACCOUNT;
    constructor(public payload: DataSource) { }
}

export class SetIdentityAction implements Action {
    readonly type = SET_IDENTITY;
    constructor(public payload: Identity) { }
}

export class ResetPreferenceState implements Action {
    readonly type = RESET_PREFERENCE_STATE;
    constructor() { }
}


export type PreferenceActions =
    | IsPreferenceLoadingAction
    | LoadPreferenceSuccessAction
    | LoadPreferenceFailedAction
    | LoadPreferenceFailedAction
    | UpdateManyPreferencesSuccess
    | UpdateManyPreferences
    | UpdateAttributes
    | UpdateSignatures
    | AddSignature
    | RemoveSignatures
    | ResetSignatures
    | SelectAccount
    | SetIdentityAction
    | ResetPreferenceState
    | UpdateManyPreferencesFail;
