
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import * as fromFeature from "../reducers";
import { contactFolderAdapter } from "../reducers/contact-list.reducer";
import { contactAdapter } from "../reducers/contacts.reducers";
import { createSelector } from "@ngrx/store";

// Folders
export const {
    selectIds: getContactFolderIds,
    selectEntities: getContactFolderEntities,
    selectAll: getAllContactFolder,
    selectTotal: getTotalContactFolder,
} = contactFolderAdapter.getSelectors(fromFeature.getContactFolderState);

// export const getContactFolders = getAllContactFolder;
export const getFolderIds = getContactFolderIds;
export const getContactFolders = createSelector(
    getAllContactFolder,
    (folders) => folders
);



// Contacts


export const {
    selectIds: getContactIds,
    selectEntities: getContactEntities,
    selectAll: getAllContact,
    selectTotal: getTotalContact,
} = contactAdapter.getSelectors(fromFeature.getContactState);

export const getContacts = getAllContact;

export const getContactsFromStore = createSelector(
    getAllContact,
    (contacts) => contacts
);
