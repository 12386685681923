
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, NgZone } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";
import { MatDialogRef } from "@angular/material/dialog";
import { MailBroadcaster } from "../../../../common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "../../../../common/enums/broadcast.enum";
import { takeUntil } from "rxjs/operators";
import { MailConstants } from "../../../../common/utils/mail-constants";
import { ElectronService } from "src/app/services/electron.service";
import { ConfigService } from "src/app/config.service";

@Component({
  selector: "vp-legal-notice-dialog",
  templateUrl: "./legal-notice-dialog.component.html"
})
export class LegalNoticeDialogComponent implements OnDestroy {
  private isAlive$ = new Subject<boolean>();
  language: string = "en";
  termsOfUse_en: string;
  termsOfUse_de: string;
  dataPrivacy_en: string;
  dataPrivacy_de: string;
  imprint_en: string;
  imprint_de: string;
  hideImprintMenuItem: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<LegalNoticeDialogComponent>,
    private mailBroadcaster: MailBroadcaster,
    private ngZone: NgZone,
    private electronService: ElectronService,
    private configService: ConfigService
  ) {
    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_LEGAL_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });

    if (!this.configService.showHideSettingsMenu.hideImprintMenuItem) {
      this.hideImprintMenuItem = this.configService.showHideSettingsMenu.hideImprintMenuItem;
    }

    const lang = this.electronService.isElectron
        ? this.electronService.getFromStorage(MailConstants.LANGUAGE_KEY)
        : localStorage.getItem(MailConstants.LANGUAGE_KEY);

    if (lang) {
      this.language = lang;
    }
    if (this.configService.URLS.termsOfUseURL) {
      this.termsOfUse_en = this.configService.URLS.termsOfUseURL;
    } else {
      this.termsOfUse_en = "https://vnclagoon.com/terms";
    }

    if (this.configService.URLS.dataPrivacyURL) {
      this.dataPrivacy_en = this.configService.URLS.dataPrivacyURL;
    } else {
      this.dataPrivacy_en = "https://vnclagoon.com/data-privacy-policy/";
    }

    if (this.configService.URLS.imprintURL) {
      this.imprint_en = this.configService.URLS.imprintURL;
    } else {
      this.imprint_en = "https://vnclagoon.com/about-vnc/legal-information/";
    }

    if (this.configService.URLS.termsOfUseURLde) {
      this.termsOfUse_de = this.configService.URLS.termsOfUseURLde;
    } else {
      this.termsOfUse_de = "https://vnclagoon.com/de/Nutzungsbedingungen/";
    }

    if (this.configService.URLS.dataPrivacyURLde) {
      this.dataPrivacy_de = this.configService.URLS.dataPrivacyURLde;
    } else {
      this.dataPrivacy_de = "https://vnclagoon.com/de/datenschutzerklaerung/";
    }

    if (this.configService.URLS.imprintURLde) {
      this.imprint_de = this.configService.URLS.imprintURLde;
    } else {
      this.imprint_de = "https://vnclagoon.com/de/uber-uns/rechtsinformationen/";
    }
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  close(): void {
    this.dialogRef.close();
  }
}
