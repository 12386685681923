
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

const TIMEZONE = {};
TIMEZONE["Etc/GMT+12"] = "GMT -12:00 Dateline";
TIMEZONE["Pacific/Midway"] = "GMT -11:00 Samoa";
TIMEZONE["Etc/GMT+11"] = "GMT -11:00 GMT-11";
TIMEZONE["Pacific/Honolulu"] = "GMT -10:00 Hawaii";
TIMEZONE["America/Anchorage"] = "GMT -09:00 Alaska";
TIMEZONE["America/Los_Angeles"] = "GMT -08:00 US/Canada Pacific";
TIMEZONE["America/Tijuana"] = "GMT -08:00 Baja California";
TIMEZONE["America/Denver"] = "GMT -07:00 US/Canada Mountain";
TIMEZONE["America/Chihuahua"] = "GMT -07:00 Chihuahua, La Paz, Mazatlan";
TIMEZONE["America/Phoenix"] = "GMT -07:00 Arizona";
TIMEZONE["America/Chicago"] = "GMT -06:00 US/Canada Central";
TIMEZONE["America/Regina"] = "GMT -06:00 Saskatchewan";
TIMEZONE["America/Mexico_City"] = "GMT -06:00 Guadalajara, Mexico City, Monterrey";
TIMEZONE["America/Guatemala"] = "GMT -06:00 Central America";
TIMEZONE["America/New_York"] = "GMT -05:00 US/Canada Eastern";
TIMEZONE["America/Indiana/Indianapolis"] = "GMT -05:00 Indiana (East)";
TIMEZONE["America/Bogota"] = "GMT -05:00 Colombia";
TIMEZONE["America/Caracas"] = "GMT -04:30 Caracas";
TIMEZONE["America/Santiago"] = "GMT -04:00 Pacific South America";
TIMEZONE["America/Manaus"] = "GMT -04:00 Manaus";
TIMEZONE["America/La_Paz"] = "GMT -04:00 La Paz";
TIMEZONE["America/Guyana"] = "GMT -04:00 Georgetown, La Paz, Manaus, San Juan";
TIMEZONE["America/Cuiaba"] = "GMT -04:00 Cuiaba";
TIMEZONE["America/Halifax"] = "GMT -04:00 Atlantic Time (Canada)";
TIMEZONE["America/Asuncion"] = "GMT -04:00 Asuncion  ";
TIMEZONE["America/St_Johns"] = "GMT -03:30 Newfoundland";
TIMEZONE["America/Montevideo"] = "GMT -03:00 Montevideo";
TIMEZONE["America/Godthab"] = "GMT -03:00 Greenland";
TIMEZONE["America/Cayenne"] = "GMT -03:00 Cayenne, Fortaleza";
TIMEZONE["America/Sao_Paulo"] = "GMT -03:00 Brasilia";
TIMEZONE["America/Argentina/Buenos_Aires"] = "GMT -03:00 Argentina";
TIMEZONE["Atlantic/South_Georgia"] = "GMT -02:00 Mid-Atlantic";
TIMEZONE["Etc/GMT+2"] = "GMT -02:00 GMT-02";
TIMEZONE["Atlantic/Cape_Verde"] = "GMT -01:00 Cape Verde Is.";
TIMEZONE["Atlantic/Azores"] = "GMT -01:00 Azores";
TIMEZONE["UTC"] = "GMT/UTC Coordinated Universal Time";
TIMEZONE["Europe/London"] = "GMT +00:00 Britain, Ireland, Portugal";
TIMEZONE["Africa/Casablanca"] = "GMT +00:00 Casablanca";
TIMEZONE["Africa/Monrovia"] = "GMT +00:00 Monrovia";
TIMEZONE["Europe/Berlin"] = "GMT +01:00 Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna";
TIMEZONE["Europe/Belgrade"] = "GMT +01:00 Belgrade, Bratislava, Budapest, Ljubljana, Prague";
TIMEZONE["Europe/Brussels"] = "GMT +01:00 Brussels, Copenhagen, Madrid, Paris";
TIMEZONE["Africa/Windhoek"] = "GMT +01:00 Namibia";
TIMEZONE["Europe/Warsaw"] = "GMT +01:00 Sarajevo, Skopje, Warsaw, Zagreb";
TIMEZONE["Africa/Algiers"] = "GMT +01:00 West Central Africa";
TIMEZONE["Europe/Athens"] = "GMT +02:00 Athens, Beirut, Bucharest, Istanbul";
TIMEZONE["Asia/Beirut"] = "GMT +02:00 Beirut";
TIMEZONE["Asia/Damascus"] = "GMT +02:00 Damascus";
TIMEZONE["Africa/Cairo"] = "GMT +02:00 Egypt";
TIMEZONE["Africa/Harare"] = "GMT +02:00 Harare, Pretoria";
TIMEZONE["Europe/Helsinki"] = "GMT +02:00 Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius";
TIMEZONE["Europe/Istanbul"] = "GMT +02:00 Istanbul";
TIMEZONE["Asia/Jerusalem"] = "GMT +02:00 Jerusalem";
TIMEZONE["Asia/Amman"] = "GMT +02:00 Jordan";
TIMEZONE["Europe/Kaliningrad"] = "GMT +02:00 Kaliningrad (RTZ 1)";
TIMEZONE["Asia/Baghdad"] = "GMT +03:00 Iraq";
TIMEZONE["Asia/Kuwait"] = "GMT +03:00 Kuwait, Riyadh";
TIMEZONE["Europe/Minsk"] = "GMT +03:00 Minsk";
TIMEZONE["Europe/Moscow"] = "GMT +03:00 Moscow, St. Petersburg, Volgograd (RTZ 2)";
TIMEZONE["Africa/Nairobi"] = "GMT +03:00 Nairobi";
TIMEZONE["Asia/Tehran"] = "GMT +03:30 Tehran";
TIMEZONE["Asia/Muscat"] = "GMT +04:00 Abu Dhabi, Muscat";
TIMEZONE["Asia/Baku"] = "GMT +04:00 Baku";
TIMEZONE["Indian/Mauritius"] = "GMT +04:00 Port Louis";
TIMEZONE["Asia/Tbilisi"] = "GMT +04:00 Tbilisi";
TIMEZONE["Asia/Yerevan"] = "GMT +04:00 Yerevan";
TIMEZONE["Asia/Kabul"] = "GMT +04:30 Kabul";
TIMEZONE["Asia/Yekaterinburg"] = "GMT +05:00 Ekaterinburg (RTZ 4)";
TIMEZONE["Asia/Karachi"] = "GMT +05:00 Islamabad, Karachi";
TIMEZONE["Asia/Tashkent"] = "GMT +05:00 Tashkent";
TIMEZONE["Asia/Kolkata"] = "GMT +05:30 Chennai, Kolkata, Mumbai, New Delhi";
TIMEZONE["Asia/Colombo"] = "GMT +05:30 Sri Jayawardenepura";
TIMEZONE["Asia/Kathmandu"] = "GMT +05:45 Kathmandu";
TIMEZONE["Asia/Katmandu"] = "GMT +05:45 Kathmandu";
TIMEZONE["Asia/Almaty"] = "GMT +06:00 Astana";
TIMEZONE["Asia/Dhaka"] = "GMT +06:00 Dhaka";
TIMEZONE["Asia/Novosibirsk"] = "GMT +06:00 Novosibirsk (RTZ 5)";
TIMEZONE["Asia/Rangoon"] = "GMT +06:30 Rangoon";
TIMEZONE["Asia/Bangkok"] = "GMT +07:00 Bangkok, Hanoi, Jakarta";
TIMEZONE["Asia/Krasnoyarsk"] = "GMT +07:00 Krasnoyarsk (RTZ 6)";
TIMEZONE["Asia/Hong_Kong"] = "GMT +08:00 Beijing, Chongqing, Hong Kong, Urumqi";
TIMEZONE["Asia/Irkutsk"] = "GMT +08:00 Irkutsk (RTZ 7)";
TIMEZONE["Asia/Kuala_Lumpur"] = "GMT +08:00 Kuala Lumpur, Singapore";
TIMEZONE["Australia/Perth"] = "GMT +08:00 Perth";
TIMEZONE["Asia/Taipei"] = "GMT +08:00 Taipei";
TIMEZONE["Asia/Ulaanbaatar"] = "GMT +08:00 Ulaanbaatar";
TIMEZONE["Asia/Tokyo"] = "GMT +09:00 Japan";
TIMEZONE["Asia/Seoul"] = "GMT +09:00 Korea";
TIMEZONE["Asia/Yakutsk"] = "GMT +09:00 Yakutsk (RTZ 8)";
TIMEZONE["Australia/Adelaide"] = "GMT +09:30 Adelaide";
TIMEZONE["Australia/Darwin"] = "GMT +09:30 Darwin";
TIMEZONE["Australia/Brisbane"] = "GMT +10:00 Brisbane";
TIMEZONE["Australia/Sydney"] = "GMT +10:00 Canberra, Melbourne, Sydney";
TIMEZONE["Pacific/Guam"] = "GMT +10:00 Guam, Port Moresby";
TIMEZONE["Australia/Hobart"] = "GMT +10:00 Hobart";
TIMEZONE["Asia/Magadan"] = "GMT +10:00 Magadan";
TIMEZONE["Asia/Vladivostok"] = "GMT +10:00 Vladivostok, Magadan (RTZ 9)";
TIMEZONE["Pacific/Guadalcanal"] = "GMT +11:00 Solomon Is. / New Caledonia";
TIMEZONE["Pacific/Fiji"] = "GMT +12:00 Fiji";
TIMEZONE["Etc/GMT-12"] = "GMT +12:00 GMT+12";
TIMEZONE["Pacific/Auckland"] = "GMT +12:00 New Zealand";
TIMEZONE["Asia/Kamchatka"] = "GMT +12:00 Petropavlovsk-Kamchatsky";
TIMEZONE["Pacific/Tongatapu"] = "GMT +13:00 Nuku'alofa";
TIMEZONE["America/Adak"] = "GMT -10:00 Adak";
TIMEZONE["Pacific/Marquesas"] = "GMT -09:30 Marquesa";
TIMEZONE["America/Fort_Nelson"] = "GMT -07:00 Fort Nelson";
TIMEZONE["Pacific/Easter"] = "GMT -06:00 Easter";
TIMEZONE["America/Cancun"] = "GMT -05:00 Cancun, Chetumal";
TIMEZONE["America/Grand_Turk"] = "GMT -05:00 Turks and Caicos Islands";
TIMEZONE["America/Havana"] = "GMT -05:00 Havana";
TIMEZONE["America/Port-au-Prince"] = "GMT -05:00 Port-au-Prince";
TIMEZONE["America/Araguaina"] = "GMT -03:00 Araguaina";
TIMEZONE["America/Bahia"] = "GMT -03:00 Salvador";
TIMEZONE["America/Miquelon"] = "GMT -03:00 Miquelon";
TIMEZONE["America/Punta_Arenas"] = "GMT -03:00 Punta_Arena";
TIMEZONE["Africa/Sao_Tome"] = "GMT +00:00 Sao Tome";
TIMEZONE["Africa/Khartoum"] = "GMT +02:00 Khartoum";
TIMEZONE["Africa/Tripoli"] = "GMT +02:00 Tripoli";
TIMEZONE["Asia/Gaza"] = "GMT +02:00 Gaza";
TIMEZONE["Europe/Bucharest"] = "GMT +02:00 Bucharest";
TIMEZONE["Europe/Chisinau"] = "GMT +02:00 Chisinau";
TIMEZONE["Europe/Helsinkiu"] = "GMT +02:00 Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius";
TIMEZONE["Europe/Astrakhan"] = "GMT +04:00 Astrakhan";
TIMEZONE["Europe/Samara"] = "GMT +04:00 Izhevsk, Samara (RTZ 3)";
TIMEZONE["Europe/Saratov"] = "GMT +04:00 Saratov";
TIMEZONE["Europe/Volgograd"] = "GMT +04:00 Volgograd";
TIMEZONE["Asia/Qyzylorda"] = "GMT +05:00 Qyzylorda";
TIMEZONE["Asia/Omsk"] = "GMT +06:00 Omsk";
TIMEZONE["Asia/Yangon"] = "GMT +06:30 Yangon";
TIMEZONE["Asia/Barnaul"] = "GMT +07:00 Barnaul";
TIMEZONE["Asia/Hovd"] = "GMT +07:00 Hovd";
TIMEZONE["Asia/Tomsk"] = "GMT +07:00 Tomsk";
TIMEZONE["Asia/Singapor"] = "GMT +08:00 Singapore";
TIMEZONE["Australia/Eucla"] = "GMT +08:45 Eucla";
TIMEZONE["Asia/Chita"] = "GMT +09:00 Chita";
TIMEZONE["Asia/Pyongyang"] = "GMT +09:00 Pyongyang";
TIMEZONE["Australia/Lord_Howe"] = "GMT +10:30 Lord_Howe";
TIMEZONE["Asia/Sakhalin"] = "GMT +11:00 Sakhalin";
TIMEZONE["Asia/Srednekolymsk"] = "GMT +11:00 Chokurdakh (RTZ 10)";
TIMEZONE["Pacific/Bougainville"] = "GMT +11:00 Bougainville Standard Time";
TIMEZONE["Pacific/Norfolk"] = "GMT +11:00 Norfolk";
TIMEZONE["Pacific/Chatham"] = "GMT +12:45 Chatham";
TIMEZONE["Pacific/Apia"] = "GMT +13:00 Samoa";
TIMEZONE["Pacific/Kiritimati"] = "GMT +14:00 Kiritimati Island";
export default TIMEZONE;
