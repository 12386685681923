
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { BriefCase } from "../../shared/models/briefcase.model";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Action } from "src/app/actions";
import { BriefcaseActionTypes } from "../actions/briefcase.actions";
import { BriefcaseFolderActionTypes } from "../actions";
import { BriefcaseFolder } from "../../shared/models/briefcase-folder";

export interface BriefCaseFolderState extends EntityState<BriefcaseFolder> {
  isLoading: boolean;
  isLoaded: boolean;
  selectedBriefcaseFolder: BriefcaseFolder;
}

export const briefcaseFolderAdapter: EntityAdapter<BriefcaseFolder> = createEntityAdapter<BriefcaseFolder>({
    selectId: (briefcase: BriefcaseFolder) => briefcase.id
});

export const initialState: BriefCaseFolderState = briefcaseFolderAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  selectedBriefcaseFolder: null,
});

export function referenceReducer(
  state = initialState,
  action: Action
): BriefCaseFolderState {
  switch (action.type) {

    case BriefcaseFolderActionTypes.SELECT_BRIEFCASE_FOLDER: {
      return {
        ...state,
        selectedBriefcaseFolder: action.payload.folder
      };
    }

    case BriefcaseFolderActionTypes.LOAD_BRIEFCASE_FOLDERS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case BriefcaseFolderActionTypes.LOAD_BRIEFCASE_FOLDERS_SUCCESS: {
      return briefcaseFolderAdapter.addAll(action.payload.folders, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case BriefcaseFolderActionTypes.LOAD_BRIEFCASE_FOLDERS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case BriefcaseFolderActionTypes.CREATE_BRIEFCASE_FOLDER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case BriefcaseFolderActionTypes.CREATE_BRIEFCASE_FOLDER_SUCCESS: {
      return briefcaseFolderAdapter.addOne(action.payload.folder, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case BriefcaseFolderActionTypes.CREATE_BRIEFCASE_FOLDER_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case BriefcaseFolderActionTypes.UPDATE_BRIEFCASE_FOLDER_SUCCESS: {
      return briefcaseFolderAdapter.updateOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case BriefcaseFolderActionTypes.UPDATE_BRIEFCASE_FOLDER_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case BriefcaseFolderActionTypes.DELETE_BRIEFCASE_FOLDER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case BriefcaseFolderActionTypes.DELETE_BRIEFCASE_FOLDER_SUCCESS: {
      return briefcaseFolderAdapter.removeOne(action.payload.folder.id, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case BriefcaseFolderActionTypes.DELETE_BRIEFCASE_FOLDER_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }


      case BriefcaseFolderActionTypes.RESET_BRIEFCASE_FOLDER_STATE: {
          return briefcaseFolderAdapter.removeAll(state);
      }

      default: {
          return state;
      }
  }
}
