
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div>
    <div class="header">
        {{ 'CONFIRMATION' | translate }}
    </div>
    <div class="messagae-content">
        <span *ngIf="type === 'general'">{{ 'CALENDARS.DELETE_THIS_APPOINTMENT_LBL' | translate }}</span>
        <span *ngIf="type === 'all'">{{ 'CALENDARS.DELETE_ALL_OCCERENCE_APPOINTMENT_LBL' | translate }}</span>
        <span *ngIf="type === 'instance'">{{ 'CALENDARS.DELETE_INSTANCE_APPOINTMENT_LBL' | translate }}</span>
    </div>
    <div>
        <mat-radio-group [(ngModel)]="selectedOption">
            <mat-radio-button value="no">
                <span>{{ 'CALENDARS.NOT_NOTIFY_ORGANIZER' | translate }}</span>
            </mat-radio-button>
            <mat-radio-button value="yes">
                <span>{{ 'CALENDARS.NOTIFY_ORGANIZER' | translate }}</span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="submit-buttons">
        <button mat-button (click)="submit()">
            {{ 'COMMON.YES'| translate }}
        </button>
        <button mat-button (click)="close()">
            {{ 'COMMON.NO'| translate }}
        </button>
    </div>
</div>