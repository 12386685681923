import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DAYS_OF_WEEK } from "calendar-utils";
import { take, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";
import { ConfigService } from "src/app/config.service";
import { CommonService } from "src/app/services/ common.service.";
import { CalendarRepository } from "../../repositories/calendar.repository";
import { SchedulerComponent } from "../scheduler/scheduler.component";
import { ToastService } from "src/app/common/providers/toast.service";
import * as moment from "moment";
import { UserProfile } from "src/app/shared/models";

const MSEC_PER_DAY = 86400000;
const MSEC_PER_HALF_HOUR = 1800000;

@Component({
  selector: "vp-schedule-assistant",
  templateUrl: "./schedule-assistant.component.html",
  styleUrls: ["./schedule-assistant.component.scss"]
})
export class ScheduleAssistantComponent implements OnInit, OnDestroy {
  @Input() currentUser: UserProfile;
  @Input() requiredAttendees;
  @Input() timezone = "";
  @Input() browserLang = "en";
  @Input() isAllDay: boolean;
  @Output() selectedSuggested = new EventEmitter();
  @Output() calendarTimeChanged = new EventEmitter();
  @Output() changeLocation = new EventEmitter();

  viewDate: Date = new Date();
  workWeekDays: number[] = [0, 0, 1, 2, 3, 4, 5, 6];
  firstDayOfWeek: number = 1;
  weekStartsOn = DAYS_OF_WEEK.MONDAY;
  currentLocale: string;
  showWeekNumberInMiniCalendar: boolean = false;
  calResource: any[];
  noAttendees: boolean;
  _sectionHeaderHtml: any;
  showTimeSuggestion: boolean;
  currentSuggestions: any;
  selectedSuggestedTime: any;
  selectedSuggestedIndex: any;
  isLoading: boolean;
  private _totalUsers: any;
  private _totalLocations: any;
  private _duration: any;
  private _workingHours = {};
  private _attendees = [];
  private _fbStatMap = {};
  private _fbStat = [];
  private _resources = [];
  private _suggestTime: boolean;
  private _fbExcludeInfo = {};
  private _workingHoursKey = {};
  private _date2CellId: any;
  private _origDayClassName: string;
  private _suggestions: boolean;
  private _suggestLocation: boolean;
  private _attendeeStatus: boolean;
  private _schedule = {};
  private _manualOverrideFlag: boolean;
  private _key: string;
  private schedulerComp: SchedulerComponent;
  private isAlive$ = new Subject<boolean>();
  private _startDate: any;
  startDate: Date;
  endDate: Date;
  startTime: Date;
  endTime: Date;
  constructor(private calendarRepository: CalendarRepository,
    private commonService: CommonService,
    private configService: ConfigService,
    private toastService: ToastService,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.schedulerComp = new SchedulerComponent(this.calendarRepository);
    this.configService.currentLanguage.pipe(takeUntil(this.isAlive$)).subscribe(res => {
      if (!!res) {
        if (res === "en") {
          this.currentLocale = "en";
        } else {
          this.currentLocale = "de";
        }
      } else {
        this.currentLocale = "en";
      }
    });
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  getResources() {
    return this._resources;
  }

  searchCalendarResourcesRequest(params?: any): void {
    const request = {
      "SearchCalendarResourcesRequest": {
        "@": {
          xmlns: "urn:zimbraAccount"
        },
        attrs: "fullName,email,zimbraCalResLocationDisplayName,zimbraCalResCapacity,zimbraCalResContactEmail,description,zimbraCalResType",
        limit: 50,
        needExp: 1,
        offset: 0,
        searchFilter: {
          conds: {
            cond: [{
              attr: "zimbraCalResType",
              op: "eq",
              value: "Location"
            }]
          }
        }
      }
    };
    this.commonService.createBatchRequest(request).pipe(take(1)).subscribe(res => {
      if (!!res && res.SearchCalendarResourcesResponse && res.SearchCalendarResourcesResponse[0].calresource) {
        const calendarResouce = res.SearchCalendarResourcesResponse[0].calresource;
        this.calResource = [];
        calendarResouce.map(cal => {
          this.calResource.push({
            ...cal,
            id: cal.id,
            name: cal.name,
            email: cal._attrs.email,
            resourceType: cal._attrs.zimbraCalResType,
            fullName: cal._attrs.fullName
          });
        });
        this._resources = this.calResource;
      } else {
        this.calResource = [];
        this._resources = [];
      }
      this._findFreeBusyInfo(params);
    }, error => {
      this.toastService.showPlainMessage(error);
    });
  }

  setSuggestionTime(params) {
    this._totalUsers = params.totalUsers;
    this._totalLocations = params.totalLocations;
    this._duration = params.duration;
    this._startDate = params.startDate;
    this._attendees = params.attendees || [];
    this._workingHours = params.workingHours || {};
    this._fbStat = params.fbStat || {};
    this._fbStatMap = params.fbStatMap || {};
    this._schedule = params.schedule || {};
  }

  cleanupScheduleAssistantView() {
    this._attendees = [];
    this._schedule = {};
    this._manualOverrideFlag = false;
    // Reset suggestion and calendar
  }

  _createMiniCalendar(date) {
    date = date ? date : new Date();
    const firstDayOfWeek = this.firstDayOfWeek || 0;
    let workingWeek = [];
    for (let i = 0; i < 7; i++) {
      let d = (i + firstDayOfWeek) % 7;
      workingWeek[i] = (d > 0 && d < 6);
    }
  }

  _showTimeSuggestions() {
    // Display the time suggestion panel.
    this._suggestions = true;
    this._suggestTime = true;
    this._suggestLocation = true;
    this._attendeeStatus = false;
    this.suggestAction(true, false);
  }

  setDateInfo(startDate, endDate, timezone, startTime, endTime, showTime?: boolean, isAllDay?: boolean) {
    startTime.setSeconds(0);
    endTime.setSeconds(0);
    this.startDate = new Date(startDate);
    this.endDate = new Date(endDate);
    this.startTime = new Date(startTime);
    this.endTime = new Date(endTime);
    this.schedulerComp.setDateInfo(startDate, endDate, timezone, startTime, endTime, showTime, isAllDay);
  }

  suggestAction(focusOnSuggestion?: boolean, showAllSuggestions?: boolean) {
    const params = {
      items: [],
      itemIndex: {},
      focus: focusOnSuggestion,
      showOnlyGreenSuggestions: !showAllSuggestions
    };
    this.isLoading = true;
    // Location information is required even for a time search, since the time display indicates locations available
    // at that time.  Use isSuggestRooms to only do so when GAL_ENABLED is true.
    if (this._resources.length === 0) {
      this.searchCalendarResourcesRequest(params);
    } else {
      this._findFreeBusyInfo(params);
    }
  }

  getLocationFBInfo(fbCallback, fbCallbackObj, endTime) {
    const params = {
      items: [],
      itemIndex: {},
      focus: false,
      fbEndTime: endTime,
      showOnlyGreenSuggestions: true
    };
    // params.fbCallback = fbCallback.bind(fbCallbackObj, params);
    if (this._resources.length === 0) {
      this.searchCalendarResourcesRequest(params);
    } else {
      this._findFreeBusyInfo(params);
    }
  }

  _getTimeFrame() {
    let startDate = new Date(this.startDate);
    let endDate = new Date(startDate);
    startDate.setHours(0, 0, 0, 0);
    endDate.setTime(startDate.getTime() + MSEC_PER_DAY);
    return { start: startDate, end: endDate };
  }

  _miniCalSelectionListener(ev) {
    // Update dateinfo and scheduler
  }

  updateTime(clearSelection, forceRefresh) {
    // Update time for mini calendar
  }

  updateAttendees(attendees) {
    this._attendees = [];
    this._attendees.push(this.currentUser.email);
    let attendee;
    for (let i = attendees.length; --i >= 0;) {
      attendee = attendees[i];
      if (attendee instanceof Array) {
        attendee = attendee[i][0];
      }
      this._attendees.push(attendee);
    }
  }

  getFormKey(startDate, attendees) {
    return startDate.getTime() + "-" + attendees.join(",");
  }

  clearCache() {
    this._workingHours = {};
  }

  getFreeBusyKey(timeFrame, id) {
    return timeFrame.start.getTime() + "-" + timeFrame.end.getTime() + "-" + id;
  }

  _copyResourcesToParams(params, emails) {
    let list = this._resources;
    for (let i = list.length; --i >= 0;) {
      let item = list[i];
      let email = item.email;
      emails.push(email);
      params.items.push(email);
      params.itemIndex[email] = params.items.length - 1;
    }
  }

  _addAttendee(attendee, params, emails, attendeeEmails) {
    if (params.items.indexOf(attendee) === -1) {
      params.items.push(attendee);
    }
    params.itemIndex[attendee] = params.items.length - 1;
    if (emails.indexOf(attendee) === -1) {
      emails.push(attendee);
    }
    if (attendeeEmails.indexOf(attendee) === -1) {
      attendeeEmails.push(attendee);
    }
    if (this._attendees.indexOf(attendee) === -1) {
      this._attendees.push(attendee);
    }
  }

  // This should only be called for time suggestions
  _findFreeBusyInfo(params) {
    let tf = this._getTimeFrame();
    if (params.fbEndTime) {
      // Override the time frame.  Used for checking location
      // recurrence collisions
      tf.end = new Date(params.fbEndTime);
    }
    let emails = [], attendeeEmails = [];
    params.itemIndex = {};
    params.items = [];
    params.timeFrame = tf;
    this._copyResourcesToParams(params, emails);

    let attendees = this.requiredAttendees.map(v => v.email);

    for (let attendee of attendees) {
      this._addAttendee(attendee, params, emails, attendeeEmails);
    }

    params._nonOrganizerAttendeeEmails = attendees;
    this._addAttendee(this.currentUser.email, params, emails, attendeeEmails);
    params.emails = emails;
    params.attendeeEmails = attendeeEmails;

    this._key = this.getFormKey(tf.start, this._attendees);

    if ((this._attendees.length === 0) && this._suggestTime) {
      // this.setNoAttendeesHtml();
      return;
    }

    let callback;
    if (params.fbCallback) {
      // Custom FB processing
      callback = params.fbCallback;
    } else {
      if (this._suggestTime) {
        callback = () => { this.getWorkingHours(params); };
      } else {
        callback = () => { this.suggestLocations(params); };
      }
    }

    const fbParams = {
      startTime: tf.start.getTime(),
      endTime: tf.end.getTime(),
      emails: emails,
      callback: callback,
      noBusyOverlay: true,
      params: params
    };
    console.log("[getFreeBusyInfo][_findFreeBusyInfo]", fbParams);
    this.schedulerComp.getFreeBusyInfo(fbParams);
  }

  suggestLocations(params) {
    let emails = [];
    this._copyResourcesToParams(params, emails);
    this._duration = this.getDurationInfo();
    params.emails = emails;
    params.duration = this._duration.duration;
    params.locationInfo = this.computeLocationAvailability(this._duration, params);
    this.renderSuggestions(params);
  }

  isOnlyMyWorkingHoursIncluded() {
    // return this.preferences.my_working_hrs_pref === "TRUE";
    return true;
  }

  isOnlyOthersWorkingHoursIncluded() {
    // return this.preferences.others_working_hrs_pref === "TRUE";
    return true;
  }

  getWorkingHours(params) {
    let onlyIncludeMyWorkingHours = params.onlyIncludeMyWorkingHours = this.isOnlyMyWorkingHoursIncluded();
    let onlyIncludeOthersWorkingHours = params.onlyIncludeOthersWorkingHours = this.isOnlyOthersWorkingHoursIncluded();

    if (!onlyIncludeMyWorkingHours && !onlyIncludeOthersWorkingHours) {
      // Non-working hours can be used for the organizer and all attendees
      this.suggestTimeSlots(params);
      return;
    }

    let emails = [];
    if (onlyIncludeOthersWorkingHours) {
      emails = params._nonOrganizerAttendeeEmails;
    }
    if (onlyIncludeMyWorkingHours) {
      emails = emails.concat([this.currentUser.email]);
    }

    // optimization: fetch working hrs for a week - wrking hrs pattern repeat everyweek
    let weekStartDate = new Date(params.timeFrame.start.getTime());
    let dow = weekStartDate.getDay();
    weekStartDate.setDate(weekStartDate.getDate() - ((dow + 7)) % 7);


    const whrsParams = {
      startTime: weekStartDate.getTime(),
      endTime: weekStartDate.getTime() + 7 * MSEC_PER_DAY,
      emails: emails,
      callback: () => this._handleWorkingHoursResponse(params),
      errorCallback: () => this._handleWorkingHoursError(params),
      noBusyOverlay: true
    };
    this.schedulerComp.getWorkingHours(whrsParams);
  }

  _handleWorkingHoursResponse(params) {
    this._workingHours = {};
    this._workingHours[this.currentUser.email] = this.schedulerComp.getWorkingHrsSlot(params.timeFrame.start.getTime(),
      params.timeFrame.end.getTime(), this.currentUser.email);
    this.suggestTimeSlots(params);
  }

  _handleWorkingHoursError(params) {
    this._workingHours = {};
    this.suggestTimeSlots(params);
  }

  getDurationInfo() {
    return this.schedulerComp.getDurationInfo();
  }

  getDuration() {
    return this.schedulerComp.getDurationInfo()?.duration || MSEC_PER_DAY;
  }

  getFreeBusyExcludeInfo(emailAddr) {
    return this._fbExcludeInfo ? this._fbExcludeInfo[emailAddr] : null;
  }

  suggestTimeSlots(params) {
    let startDate = new Date(this._getTimeFrame().start);
    startDate.setHours(0, 0, 0, 0);
    let startTime = startDate.getTime();

    const cDate = new Date();

    // ignore suggestions that are in past
    if (startTime === cDate.setHours(0, 0, 0, 0)) {
      startDate = new Date();
      startTime = startDate.setHours(startDate.getHours(), ((startDate.getMinutes() >= 30) ? 60 : 30), 0, 0);
    }

    let endDate = new Date(startTime);
    endDate.setHours(23, 59, 0, 0);
    let endTime = endDate.getTime();
    let durationInfo = this._duration = this.getDurationInfo();

    params.duration = durationInfo.duration;

    this._fbStat = [];
    this._fbStatMap = {};
    this._totalUsers = this._attendees.length;
    this._totalLocations = this._resources.length;

    while (startTime < endTime) {
      this.computeAvailability(startTime, startTime + durationInfo.duration, params);
      startTime += MSEC_PER_HALF_HOUR;
    }

    params.locationInfo = this.computeLocationAvailability(durationInfo, params);
    this._fbStat.sort(this._slotComparator);

    this.renderSuggestions(params);

    // highlight minicalendar to mark suggested days in month
    // this.highlightMiniCal();
  }

  renderSuggestions(params) {
    if (this._suggestTime) {
      params.list = this._fbStat;
    } else {
      params.list = params.locationInfo.locations;
      let warning = false;
      if (params.list.length >= 50) {
        // Problem: the locations search returned the Limit, implying there may
        // be even more - and the location suggestion pane does not have a 'Next'
        // button to get the next dollop, since large numbers of suggestions are
        // not useful. Include a warning that the user should set their location prefs.
        warning = true;
      }
      // this._locationSuggestions.setWarning(warning);
    }
    params.totalUsers = this._totalUsers;
    params.totalLocations = this._totalLocations;
    params.startDateText = moment(params.timeFrame.start).format("MMM DD");
    params.durationText = this.computeDuration(params.duration);
    this.currentSuggestions = params;
    console.log("[renderSuggestions]", this._suggestTime, params, this.currentSuggestions);
  }

  selectLocation(index) {
    const location = this.currentSuggestions.locationInfo.locations[index];
    if (location) {
      console.log("[selectLocation]", location);
      this.changeLocation.emit(location);
    }
  }

  selectSuggestedTime(data, index) {
    this.selectedSuggestedTime = data;
    this.selectedSuggestedIndex = index;
    this.startDate = new Date(data.startTime);
    this.endDate = new Date(data.endTime);
    this.startTime = new Date(data.startTime);
    this.endTime = new Date(data.endTime);
    this.setDateInfo(this.startDate, this.endDate, this.timezone, this.startTime, this.endTime, !this.isAllDay, this.isAllDay);
    this.selectedSuggested.emit(data);
  }

  _slotComparator(slot1, slot2) {
    if (slot1.availableUsers < slot2.availableUsers) {
      return 1;
    } else if (slot1.availableUsers > slot2.availableUsers) {
      return -1;
    } else {
      return slot1.startTime < slot2.startTime ? -1 : (slot1.startTime > slot2.startTime ? 1 : 0);
    }
  }


  _compareItems(item1, item2) {
    let aVal = item1.name.toLowerCase();
    let bVal = item2.name.toLowerCase();
    if (aVal < bVal) {
      return -1;
    } else if (aVal > bVal) {
      return 1;
    } else {
      return 0;
    }
  }

  isBooked(slots, startTime, endTime) {
    for (let i = 0; i < slots.length; i++) {
      let startConflict = startTime >= slots[i].s && startTime < slots[i].e;
      let endConflict = endTime > slots[i].s && endTime <= slots[i].e;
      let inlineSlotConflict = slots[i].s >= startTime && slots[i].e <= endTime;
      if (startConflict || endConflict || inlineSlotConflict) {
        return false;
      }
    }
    return true;
  }

  computeLocationAvailability(durationInfo, params) {

    let locationInfo = {
      startTime: durationInfo.startTime,
      endTime: durationInfo.endTime,
      locations: []
    };
    let sched: any = {};
    let isFree = false;
    let list = this._resources;
    for (let i = list.length; --i >= 0;) {
      let email = list[i].email;
      if (email instanceof Array) {
        email = email[0];
      }

      let excludeTimeSlots = this.getFreeBusyExcludeInfo(email);

      // Adjust start and end time by 1 msec, to avoid fencepost problems
      sched = this.schedulerComp.getFreeBusySlot(durationInfo.startTime + 1,
        durationInfo.endTime - 1, email, excludeTimeSlots);
      isFree = true;
      if (sched.b) isFree = isFree && this.isBooked(sched.b, durationInfo.startTime, durationInfo.endTime);
      if (sched.t) isFree = isFree && this.isBooked(sched.t, durationInfo.startTime, durationInfo.endTime);
      if (sched.u) isFree = isFree && this.isBooked(sched.u, durationInfo.startTime, durationInfo.endTime);

      // collect all the item indexes of the locations available at this slot
      if (isFree) {
        let displayInfo = this._createLocationDisplayInfo(email);
        locationInfo.locations.push(displayInfo);
      }
    }
    locationInfo.locations.sort(this._compareItems.bind(this));
    return locationInfo;
  }

  _createLocationDisplayInfo(email) {
    let info: any = { email: email };
    info.locationObj = this.getLocationByEmail(email);
    info.name = email;
    info.description = "";
    if (info.locationObj) {
      info.description = info.locationObj._attrs.zimbraCalResLocationDisplayName ||
        info.locationObj._attrs.fullName;
      if (info.description === info.name) {
        info.description = "";
      }
      info.contactMail = info.locationObj._attrs.zimbraCalResContactEmail;
      info.capacity = info.locationObj._attrs.zimbraCalResCapacity;
    }
    return info;
  }

  getLocationByEmail(item) {
    let locations = this._resources;
    for (let i = 0; i < locations.length; i++) {
      let value = locations[i].email;

      if (value instanceof Array) {
        for (let j = 0; j < value.length; j++) {
          if (item === value[j]) return locations[i];
        }
      }
      if (item === value) {
        return locations[i];
      }
    }
    return null;
  }

  getKey(startTime, endTime) {
    return startTime + "-" + endTime;
  }

  setMiniCalendarColor(dates, clear, color) {
    if (this._date2CellId == null) { return; }

    let cell;
    let aDate;
    if (clear) {
      for (aDate of Object.keys(this._date2CellId)) {
        cell = document.getElementById(this._date2CellId[aDate]);
        if (cell._colorCode) {
          cell._colorCode = null;
          // this._setClassName(cell, 1L);
        }
      }
    }

    let cellId;
    for (let i of Object.keys(dates)) {
      aDate = dates[i];
      cellId = this._date2CellId[aDate.getFullYear() * 10000 + aDate.getMonth() * 100 + aDate.getDate()];

      if (cellId) {
        cell = document.getElementById(cellId);
        if (color[i]) {
          cell._colorCode = color[i];
          // this._setClassName(cell, 1);
        }
      }
    }
  }

  _getSuggestionClassName(colorCode) {
    return " " + this._origDayClassName + "-" + colorCode;
  }

  isWithinWorkingHour(attendee, startTime, endTime) {
    let dayStartTime = (new Date(startTime)).setHours(0, 0, 0, 0);
    let dayEndTime = dayStartTime + MSEC_PER_DAY;

    let workingHours = this.schedulerComp.getWorkingHrsSlot(dayStartTime, dayEndTime, attendee);

    // if working hours could not be retrieved consider all time slots for suggestion
    if (workingHours && workingHours.n) {
      workingHours = this.schedulerComp.getWorkingHrsSlot(dayStartTime, dayEndTime, this.currentUser.email);
      if (workingHours && workingHours.n) return true;
    }
    if (!workingHours) return false;

    let slots = workingHours.f;
    // working hours are indicated as free slots
    if (!slots) return false;

    // convert working hrs relative to the searching time before comparing
    let slotStartDate, slotEndDate, slotStartTime, slotEndTime;
    let flag = false;
    for (let i = 0; i < slots.length; i++) {
      slotStartDate = new Date(slots[i].s);
      slotEndDate = new Date(slots[i].e);
      slotStartTime = (new Date(startTime)).setHours(slotStartDate.getHours(), slotStartDate.getMinutes(), 0, 0);
      slotEndTime = slotStartTime + (slots[i].e - slots[i].s);
      if (startTime >= slotStartTime && endTime <= slotEndTime) {
        flag = true;
        return true;
      }
    }

    return false;
  }

  computeAvailability(startTime, endTime, params) {

    let dayStartTime = (new Date(startTime)).setHours(0, 0, 0, 0);
    let dayEndTime = dayStartTime + MSEC_PER_DAY;

    let key = this.getKey(startTime, endTime);
    let fbInfo;

    if (!params.miniCalSuggestions && this._fbStatMap[key]) {
      fbInfo = this._fbStatMap[key];
    } else {
      fbInfo = {
        startTime: startTime,
        endTime: endTime,
        availableUsers: 0,
        availableLocations: 0,
        attendees: [],
        locations: []
      };
    }

    let attendee, sched, isFree;
    for (let i = this._attendees.length; --i >= 0;) {
      attendee = this._attendees[i];

      let excludeTimeSlots = this.getFreeBusyExcludeInfo(attendee);
      sched = this.schedulerComp.getFreeBusySlot(dayStartTime, dayEndTime, attendee, excludeTimeSlots);
      // First entry will be the organizer, all others are attendees
      // Organizer and Attendees have separate checkboxes indicating whether to apply non-working hours to them.
      let isOrganizer = attendee === this.currentUser.email;
      let onlyUseWorkingHours = isOrganizer ?
        params.onlyIncludeMyWorkingHours : params.onlyIncludeOthersWorkingHours;
      isFree = onlyUseWorkingHours ? this.isWithinWorkingHour(attendee, startTime, endTime) : true;
      // ignore time slots for non-working hours of this user
      if (!isFree) continue;

      if (sched.b) isFree = isFree && this.isBooked(sched.b, startTime, endTime);
      if (sched.t) isFree = isFree && this.isBooked(sched.t, startTime, endTime);
      if (sched.u) isFree = isFree && this.isBooked(sched.u, startTime, endTime);
      // collect all the item indexes of the attendees available at this slot
      if (isFree) {
        if (!params.miniCalSuggestions) fbInfo.attendees.push(params.itemIndex[attendee]);
        fbInfo.availableUsers++;
      }
    }

    if (this.isSuggestRooms()) {
      let list = this._resources, resource;
      for (let i = list.length; --i >= 0;) {
        attendee = list[i];
        resource = attendee.email;

        if (resource instanceof Array) {
          resource = resource[0];
        }

        let excludeTimeSlots = this.getFreeBusyExcludeInfo(resource);
        sched = this.schedulerComp.getFreeBusySlot(dayStartTime, dayEndTime, resource, excludeTimeSlots);
        isFree = true;
        if (sched.b) isFree = isFree && this.isBooked(sched.b, startTime, endTime);
        if (sched.t) isFree = isFree && this.isBooked(sched.t, startTime, endTime);
        if (sched.u) isFree = isFree && this.isBooked(sched.u, startTime, endTime);

        // collect all the item indexes of the locations available at this slot
        if (isFree) {
          if (!params.miniCalSuggestions) fbInfo.locations.push(params.itemIndex[resource]);
          fbInfo.availableLocations++;
        }
      }
    }

    // mini calendar suggestions should avoid collecting all computed information in array for optimiziation
    if (!params.miniCalSuggestions) {
      let showOnlyGreenSuggestions = params.showOnlyGreenSuggestions;
      if (!showOnlyGreenSuggestions || (fbInfo.availableUsers === this._totalUsers)) {
        this._fbStat.push(fbInfo);
        this._fbStatMap[key] = fbInfo;
      }
    }

    return fbInfo;
  }

  highlightMiniCal() {
    this.getMonthFreeBusyInfo();
  }

  getMiniCalendarDateRange() {
    return { start: this.startDate, end: this.endDate };
  }

  getMonthFreeBusyInfo() {
    let range = this.getMiniCalendarDateRange();
    let startDate = range.start;
    let endDate = range.end;

    let params: any = {
      items: [],
      itemIndex: {},
      focus: false,
      timeFrame: {
        start: startDate,
        end: endDate
      },
      miniCalSuggestions: true
    };

    // avoid suggestions for past date
    let currentDayTime = (new Date()).setHours(0, 0, 0, 0);
    if (currentDayTime >= startDate.getTime() && currentDayTime <= endDate.getTime()) {
      // reset start date if the current date falls within the month date range - to ignore free busy info from the past
      startDate = params.timeFrame.start = new Date(currentDayTime);
      if (endDate.getTime() === currentDayTime) {
        endDate = params.timeFrame.end = new Date(currentDayTime + MSEC_PER_DAY);
      }
    } else if (endDate.getTime() < currentDayTime) {
      // avoid fetching free busy info for dates in the past
      return;
    }

    let list = this._resources;
    let emails = [], attendeeEmails = [];
    for (let i = list.length; --i >= 0;) {
      let item = list[i];
      let email = item.email;
      if (email instanceof Array) {
        email = email[0];
      }
      emails.push(email);

      params.items.push(email);
      params.itemIndex[email] = params.items.length - 1;

    }

    let attendees = this.requiredAttendees.map(v => v.email);

    let attendee;
    for (let i = attendees.length; --i >= 0;) {
      attendee = attendees[i];
      params.items.push(attendee);
      params.itemIndex[attendee] = params.items.length - 1;
      emails.push(attendee);
      attendeeEmails.push(attendee);
    }

    params._nonOrganizerAttendeeEmails = attendeeEmails.slice();

    // include organizer in the scheduler suggestions
    let organizerEmail = this.currentUser.email;
    params.items.push(organizerEmail);
    params.itemIndex[organizerEmail] = params.items.length - 1;
    emails.push(organizerEmail);
    attendeeEmails.push(organizerEmail);

    params.emails = emails;
    params.attendeeEmails = attendeeEmails;

    let fbParams = {
      startTime: startDate.getTime(),
      endTime: endDate.getTime(),
      emails: emails,
      callback: () => { this._handleMonthFreeBusyInfo(params); },
      noBusyOverlay: true
    };
    console.log("[getFreeBusyInfo][getMonthFreeBusyInfo]", fbParams);
    this.schedulerComp.getFreeBusyInfo(fbParams);
  }

  getWorkingHoursKey() {
    let weekStartDate = new Date(this.startDate.getTime());
    let dow = weekStartDate.getDay();
    weekStartDate.setDate(weekStartDate.getDate() - ((dow + 7)) % 7);
    return [weekStartDate.getTime(), weekStartDate.getTime() + 7 * MSEC_PER_DAY, this.currentUser.email].join("-");
  }

  _handleMonthFreeBusyInfo(params) {

    // clear fb request info

    let onlyIncludeMyWorkingHours = this.isOnlyMyWorkingHoursIncluded();
    let onlyIncludeOthersWorkingHours = this.isOnlyOthersWorkingHoursIncluded();

    if (!onlyIncludeMyWorkingHours && !onlyIncludeOthersWorkingHours) {
      this.suggestMonthTimeSlots(params);
      return;
    }

    this._workingHoursKey = this.getWorkingHoursKey();

    // optimization: fetch working hrs for a week - wrking hrs pattern repeat everyweek
    let weekStartDate = new Date(params.timeFrame.start.getTime());
    let dow = weekStartDate.getDay();
    weekStartDate.setDate(weekStartDate.getDate() - ((dow + 7)) % 7);

    let emails = onlyIncludeOthersWorkingHours ? params._nonOrganizerAttendeeEmails : null;

    if (onlyIncludeMyWorkingHours) {
      emails = emails && emails.concat([this.currentUser.email]);
    }

    let whrsParams = {
      startTime: weekStartDate.getTime(),
      endTime: weekStartDate.getTime() + 7 * MSEC_PER_DAY,
      emails: emails,
      callback: () => { this._handleMonthWorkingHoursResponse(params); },
      errorCallback: () => { this._handleMonthWorkingHoursError(params); },
      noBusyOverlay: true
    };
    this.schedulerComp.getWorkingHours(whrsParams);
  }

  _handleMonthWorkingHoursError(params) {
    this.suggestMonthTimeSlots(params);
  }

  suggestMonthTimeSlots(params) {
    let startDate = params.timeFrame.start;
    startDate.setHours(0, 0, 0, 0);
    let startTime = startDate.getTime();
    let endTime = params.timeFrame.end.getTime();
    let duration = this._duration = this.getDurationInfo().duration;

    params.duration = duration;

    this._fbStat = [];
    this._fbStatMap = {};
    this._totalUsers = this._attendees.length;
    this._totalLocations = this._resources.length;

    params.dates = {};
    params.colors = {};

    let key, fbStat, freeSlotFound = false, dayStartTime, dayEndTime;

    // suggest for entire minicalendar range
    while (startTime < endTime) {

      dayStartTime = startTime;
      dayEndTime = dayStartTime + MSEC_PER_DAY;

      freeSlotFound = false;

      while (dayStartTime < dayEndTime) {
        fbStat = this.computeAvailability(dayStartTime, dayStartTime + duration, params);
        dayStartTime += MSEC_PER_HALF_HOUR;

        if (fbStat && fbStat.availableUsers === this._totalUsers) {
          this._addColorCode(params, startTime, "green");
          freeSlotFound = true;
          // found atleast one free slot that can accomodate all attendees and atleast one recources
          break;
        }
      }

      if (!freeSlotFound) {
        this._addColorCode(params, startTime, "red");
      }

      startTime += MSEC_PER_DAY;
    }
    this.setMiniCalendarColor(params.dates, true, params.colors);

  }

  _addColorCode(params, startTime, code) {
    let sd = new Date(startTime);
    let str = moment(sd).format("yyyyMMdd");
    params.dates[str] = sd;
    params.colors[str] = code;
  }

  _handleMonthWorkingHoursResponse(params) {
    this.suggestMonthTimeSlots(params);
  }

  _createItemHtml(item) {
    let id = item;
    let attendeeImage = "AttendeeOrange";
    let locationImage = "LocationRed";
    if (item.availableUsers === this._totalUsers) {
      attendeeImage = "AttendeeGreen";
    }
    if (item.availableUsers < Math.ceil(this._totalUsers / 2)) {
      attendeeImage = "AttendeeRed";
    }
    if (item.availableLocations > 0) {
      locationImage = "LocationGreen";
    }

    const params = {
      id: id,
      item: item,
      timeLabel: moment(item.startTime).format("HH:MM"),
      locationCountStr: item.availableLocations,
      attendeeImage: attendeeImage,
      locationImage: locationImage,
      totalUsers: this._totalUsers,
      totalLocations: this._totalLocations
    };
    return params;
  }

  setNoAttendeesHtml() {
    this.noAttendees = true;
  }

  setShowSuggestionsHTML(date) {
    if (this.startDate === date) {
      return;
    }
  }

  computeDuration(duration) {
    let hours = (duration / (60 * 60 * 1000));
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    let text = "";
    let translation: any = {};
    this.translateService.get(["HOURS", "HOUR", "MINUTES"]).pipe(take(1)).subscribe(v => translation = v);
    if (rhours > 0) {
      if (rhours > 1) {
        text = `${rhours} ${translation.HOURS}`;
      } else {
        text = `${rhours} ${translation.HOUR}`;
      }
      if (rminutes > 0) {
        text += ` ${rminutes} ${translation.MINUTES}`;
      }

    } else {
      text = `${rminutes} ${translation.MINUTES}`;
    }
    return text;
  }

  _getHeaderColor(item) {
    let className = (item.availableUsers === this._totalUsers) ? "GreenLight" : "OrangeLight";
    if (item.availableUsers < Math.ceil(this._totalUsers / 2)) {
      className = "RedLight";
    }
    return className;
  }

  _renderListSectionHdr(hdrKey, item) {
    if (!this._sectionHeaderHtml[hdrKey]) {
      let htmlArr = [];
      let idx = 0;
      htmlArr[idx++] = "<table width=100% class='ZmTimeSuggestionView-Column ";
      htmlArr[idx++] = this._getHeaderColor(item);
      htmlArr[idx++] = "'><tr>";
      htmlArr[idx++] = "<td><div class='DwtListHeaderItem-label'>";
      // htmlArr[idx++] = AjxMessageFormat.format(ZmMsg.availableCount, [item.availableUsers, this._totalUsers]);
      htmlArr[idx++] = "</div></td>";
      htmlArr[idx++] = "</tr></table>";
      this._sectionHeaderHtml[hdrKey] = htmlArr.join("");
    }
    return this._sectionHeaderHtml[hdrKey];
  }

  _getHeaderKey(item) {
    return item.availableUsers + "-" + this._totalUsers;
  }

  isSuggestRooms() {
    return true;
  }

  calendarTimeChangedOnDatePicker(ev: any): void {
    console.log("[calendarTimeChangedOnDatePicker]", ev);
    this.startDate = ev.day.date;
    this.endDate = ev.day.date;
    this.startTime.setDate(ev.day.date.getDate());
    this.startTime.setMonth(ev.day.date.getMonth());
    this.startTime.setFullYear(ev.day.date.getFullYear());
    this.endTime.setDate(ev.day.date.getDate());
    this.endTime.setMonth(ev.day.date.getMonth());
    this.endTime.setFullYear(ev.day.date.getFullYear());
    this.calendarTimeChanged.emit(ev);
    this._showTimeSuggestions();
  }
}
