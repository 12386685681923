
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy,
  ElementRef, ViewChild, NgZone, AfterViewInit, OnInit
} from "@angular/core";
import { takeUntil, debounceTime, skip, take } from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";
import { Store } from "@ngrx/store";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { MailFolderRepository } from "src/app/mail/repositories/mail-folder-repository";
import { ToastService } from "src/app/common/providers/toast.service";
import { getMailFolders } from "src/app/mail/store/selectors";
import { MailRootState } from "src/app/mail/store";
import { MailFolder } from "src/app/mail/models/mail-folder.model";
import { FormControl } from "@angular/forms";
import { CommonService } from "src/app/services/ common.service.";
import { Utils } from "src/app/common";
import { isArray } from "util";
import { MailService } from "src/app/mail/shared/services/mail-service";
import { TranslateService } from "@ngx-translate/core";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { ConversationRepository } from "src/app/mail/repositories/conversation.repository";

@Component({
  selector: "vp-add-delegate-dialog",
  templateUrl: "./add-delegate-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class AddDelegateDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  isEdit: boolean = false;
  private isAlive$ = new Subject<boolean>();
  searchShare: string = "";
  searchFromEmail = new FormControl();
  searchedFromUsers: any[] = [];
  filteredFromEmails: string[];
  sendAs: boolean = false;
  sendOnBehalfOf: boolean = false;
  oldSendAs: boolean = false;
  oldSendOnBehalfOf: boolean = false;
  @ViewChild(MatAutocompleteTrigger, { static: false }) autocompleteTrigger: MatAutocompleteTrigger;

  constructor(
    private dialogRef: MatDialogRef<AddDelegateDialogComponent>,
    public toastService: ToastService,
    private changeDetectionRef: ChangeDetectorRef,
    private mailBroadcaster: MailBroadcaster,
    private ngZone: NgZone,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private hotKeyService: NgxHotkeysService,
    private mailService: MailService,
    private translate: TranslateService,
    private convRepository: ConversationRepository
  ) {
    this.hotKeyService.pause(this.hotKeyService.hotkeys);
    this.isEdit = this.data.isEdit;
    if (this.isEdit) {
      const delegate = this.data.delegate;
      this.searchFromEmail.setValue(delegate[0].d);
      delegate.map(item => {
        if (item.right === "sendOnBehalfOf") {
          this.oldSendOnBehalfOf = true;
          this.sendOnBehalfOf = true;
        }
        if (item.right === "sendAs") {
          this.oldSendAs = true;
          this.sendAs = true;
        }
      });
    }
    this.changeDetectionRef.markForCheck();

    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_CREATE_FOLDER_MODAL).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });
  }

  closeDialog(): void {
    this.close();
  }

  ngOnInit() {
    this.searchFromEmail.valueChanges.pipe(debounceTime(100), skip(1)).subscribe(value => {
      if (Utils.isJson(value)) {
        return;
      }
      this.loadEmailSuggestion(value, true);
    });
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.hotKeyService.unpause(this.hotKeyService.hotkeys);
  }

  ngAfterViewInit() {
    this.changeDetectionRef.detectChanges();
  }

  close(): void {
    this.dialogRef.close();
  }

  loadEmailSuggestion(query, fromMail) {
    if (query === "" || /\s/g.test(query)) {
      this.changeDetectionRef.markForCheck();
      return;
    }
    const queryItem = {
      name: query,
      t: "all"
    };
    this.convRepository.getAutoCompleteList(queryItem).subscribe(
      res => {
        if (fromMail) {
          this.searchedFromUsers = [];
        }

        if (res && res.length > 0) {
          this.searchedFromUsers = res;
        } else if (Utils.validateEmail(query)) {
          if (fromMail) {
            this.searchedFromUsers.push({ title: query, name: query, email: query, image: "", checked: false });
          }
        }

        this._filter(query, fromMail);
        this.changeDetectionRef.markForCheck();
      },
      err => {
        if (Utils.isJson(err._body)) {
          this.toastService.showPlainMessage(JSON.parse(err._body).msg);
        }
      }
    );
  }

  private _filter(value: string, fromMail): void {
    if (value !== null) {
      const filterValue = value.toLowerCase();
      if (fromMail) {
        this.filteredFromEmails = this.searchedFromUsers.filter(user => user.email.toLowerCase().includes(filterValue));
      }
    }
  }

  selected(event: any) {
    this.searchFromEmail.setValue(event.option.value.email);
  }

  AddDelegateAction() {
    const addDelegate: any[] = [];
    const revokeDelegate: any[] = [];
    const request = {};
    if (this.isEdit) {
      if (this.oldSendAs && !this.sendAs) {
        revokeDelegate.push({
          gt: "usr",
          d: this.searchFromEmail.value,
          right: "sendAs"
        });
      }
      if (this.oldSendOnBehalfOf && !this.sendOnBehalfOf) {
        revokeDelegate.push({
          gt: "usr",
          d: this.searchFromEmail.value,
          right: "sendOnBehalfOf"
        });
      }
      if (revokeDelegate.length > 0) {
        request["RevokeRightsRequest"] = {
          "@": {
            xmlns: "urn:zimbraAccount"
          },
          ace: revokeDelegate
        };
      }
    }
    if (this.sendAs) {
      addDelegate.push({
        gt: "usr",
        d: this.searchFromEmail.value,
        right: "sendAs"
      });
    }
    if (this.sendOnBehalfOf) {
      addDelegate.push({
        gt: "usr",
        d: this.searchFromEmail.value,
        right: "sendOnBehalfOf"
      });
    }
    request["GrantRightsRequest"] = {
      "@": {
        xmlns: "urn:zimbraAccount"
      },
      ace: addDelegate
    };
    this.mailService.createBatchRequest(request).pipe(take(1)).subscribe(res => {
      const delegates = [];
      if (!!res && res.GrantRightsResponse) {
        this.dialogRef.close({isEdit: this.isEdit, addedDelegates: addDelegate, revokeDelegates: revokeDelegate});
      } else if (res.Fault) {
        if (res.Fault[0].Detail.Error && res.Fault[0].Detail.Error.Code === "account.NO_SUCH_ACCOUNT") {
          alert("account.NO_SUCH_ACCOUNT");
          this.translate.get("NO_SUCH_AACOUNT", {
            email: this.searchFromEmail.value,
          }).pipe(take(1)).subscribe((text: string) => {
            this.toastService.show(text);
          });
        }
      }
    }, error => {
      console.log("[Error]", error);
    });
  }
}
