
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

export class RandomColor {
  static "A" = "#5E35B1";
  static "B" = "#00ACC1";
  static "C" = "#827717";
  static "D" = "#7986CB";
  static "E" = "#BA68C8";
  static "F" = "#E91E63";
  static "G" = "#F48FB1";
  static "H" = "#EA80FC";
  static "I" = "#5C6BC0";
  static "J" = "#00B8D4";
  static "K" = "#66BB6A";
  static "L" = "#DCE775";
  static "M" = "#9CCC65";
  static "N" = "#546E7A";
  static "O" = "#9E9E9E";
  static "P" = "#4527A0";
  static "Q" = "#90A4AE";
  static "R" = "#FFAB91";
  static "S" = "#FFAB40";
  static "T" = "#E57373";
  static "U" = "#FFE57F";
  static "V" = "#78909C";
  static "W" = "#BCAAA4";
  static "X" = "#FF80AB";
  static "Y" = "#9CCC65";
  static "Z" = "#80CBC4";
  static defaultColor = "#03A9F4";

static getCharColor(firstChar: string) {
  if (firstChar && RandomColor[firstChar.toUpperCase()]) {
    return RandomColor[firstChar.toUpperCase()];
  } else {
    return RandomColor.defaultColor;
  }
}
}
