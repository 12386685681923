
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  Inject
} from "@angular/core";
import { Subject } from "rxjs";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { takeUntil } from "rxjs/operators";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "vp-install-app",
  templateUrl: "./install-app.html"
})
export class InstallAppDialogComponent implements OnDestroy {
  private isAlive$ = new Subject<boolean>();
  appName: string;
  appLink: string;
  constructor(private broadcaster: MailBroadcaster,
    private dialogRef: MatDialogRef<InstallAppDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public appData: any) {
    this.broadcaster.on<any>("closeDialog")
      .pipe(takeUntil(this.isAlive$))
      .subscribe(res => {
        this.dialogRef.close();
      });
    if (!!this.appData) {
      this.appName = this.appData.appName;
    }
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  installApp() {
    this.dialogRef.close();
    window.open("itms-apps://itunes.apple.com/app/id1165103905", "_system");
  }

  @HostListener("document:keydown.esc")
  cancel(): void {
    this.dialogRef.close();
  }

}
