
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-move-folder-dialog vp-sub-folder-dialog move__dialog">
    <div class="move__dialog-header desktop-header" layout="row center-center" [fxShow.xs]="false"
        [fxShow.gt-md]="true">
        <h4>{{ 'SEARCH_FOLDER' | translate}}</h4>
        <div class="desktop-close-button" [fxHide.xs]="true">
            <button class="brand-color mat-button" (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="move__dialog-body search-folder" (scroll)="onUserScroll($event)">
        <div class="sidebar-list">
            <div fxLayout="row" fxLayoutAlign="start center" class="move-search-create-bar">

                <div fxLayout="row" fxLayoutAlign="start center" class="folder-search-form">
                    <button mat-icon-button id="folder-search-btn">
                        <mat-icon>search</mat-icon>
                    </button>
                    <input matInput #folderNameSearchInput [(ngModel)]="searchText" type="text" id="folder-search-input"
                        placeholder="{{'PREFERENCES.SEARCH_FOLDERS' | translate}}" />
                </div>
            </div>
            <mat-divider></mat-divider>
            <mat-list *ngIf="!searchText  && selectedMailFolder && selectedMailFolder.children">
                <mat-list-item (click)="getParentFolder()">
                    <div class="folder-icon">
                        <mat-icon>more_horiz</mat-icon>
                    </div>
                    <div class="folder-title disable-select">
                        <span>{{'BACK_TO_ROOT' | translate}}</span>
                    </div>
                </mat-list-item>
                <mat-list-item *ngFor="let folder of selectedMailFolder.children | vpFolderNameSearch : searchText; let i = index"
                    [class.selected-folder]="currentSelectedMailFolder && currentSelectedMailFolder.id === folder.id"
                    (click)="selectFolder(folder)">
                    <div class="folder-icon" id="move-folder-list-icon">
                        <mat-icon [style.color]="folder.rgb">folder</mat-icon>
                    </div>
                    <div class="folder-details disable-select">
                        <div class="folder-title disable-select" id="folder-move-folder-name">
                            <span>{{ folder.name }}</span>
                        </div>
                        <div *ngIf="searchText.length > 0" class="folder-path disable-select" id="folder-move-folder-abspath">
                            <span matTooltip="{{ folder.absFolderPath }}" [matTooltipPosition]="'before'">{{ folder.absFolderPath }}</span>
                        </div>
                    </div>
                    <div class="folder-expander" *ngIf="folder.children" (click)="$event.preventDefault(); $event.stopPropagation();getChildrens(folder)" id="move-folder-right-arrow">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </div>
                </mat-list-item>
            </mat-list>
            <mat-list *ngIf="!searchText  && (!selectedMailFolder || selectedMailFolder && !selectedMailFolder.children)" >
                <mat-list-item [class.selected-folder]="currentSelectedMailFolder && currentSelectedMailFolder.id === folder.id"
                    *ngFor="let folder of loadedFolders; let i = index"
                    (click)="selectFolder(folder)">
                    <div class="folder-icon">
                        <mat-icon *ngIf="!folder.icon" [style.color]="folder.rgb">folder</mat-icon>
                        <mat-icon *ngIf="folder.icon">{{ folder.icon }}</mat-icon>
                    </div> 
                    <div class="folder-details disable-select">
                        <div class="folder-title disable-select" id="folder-move-folder-name">
                            <span>{{ folder.name }}</span>
                        </div>
                        <div *ngIf="searchText.length > 0" class="folder-path disable-select"
                            id="folder-move-folder-abspath">
                            <span matTooltip="{{ folder.absFolderPath }}" [matTooltipPosition]="'before'">{{ folder.absFolderPath }}</span>
                        </div>
                    </div>
                    <div class="folder-expander" *ngIf="folder.children" (click)="$event.preventDefault(); $event.stopPropagation();getChildrens(folder)" id="move-folder-right-arrow">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                    </div>
                </mat-list-item>
            </mat-list>
            <mat-list *ngIf="searchText">
                <mat-list-item [class.selected-folder]="currentSelectedMailFolder && currentSelectedMailFolder.id === folder.id"
                    *ngFor="let folder of rootUserFolders | vpFolderNameSearch : searchText; let i = index"
                    (click)="selectFolder(folder)">
                    <div class="folder-icon">
                        <mat-icon *ngIf="!folder.icon" [style.color]="folder.rgb">folder</mat-icon>
                        <mat-icon *ngIf="folder.icon">{{ folder.icon }}</mat-icon>
                    </div>
                    <div class="folder-details disable-select">
                        <div class="folder-title disable-select" id="folder-move-folder-name">
                            <span>{{ folder.name }}</span>
                        </div>
                        <div *ngIf="searchText.length > 0" class="folder-path disable-select"
                            id="folder-move-folder-abspath">
                            <span matTooltip="{{ folder.absFolderPath }}" [matTooltipPosition]="'before'">{{ folder.absFolderPath }}</span>
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="move__dialog-footer search-folder" id="move-cancel-division">
        <button (click)="close()">{{'CANCEL' | translate }}</button>
        <button (click)="select()" [disabled]="!currentSelectedMailFolder">{{'SELECT' | translate }}</button>
    </div>
</div>