
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Directive ,
  ElementRef ,
  EventEmitter ,
  HostBinding ,
  HostListener ,
  Input ,
  OnDestroy ,
  Output
} from "@angular/core";
import { Subject } from "rxjs";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { takeUntil } from "rxjs/operators";
import { throttleable } from "../../common/utils/throttle";

@Directive({
  selector: "[vpLongPress]"
})
export class VNCLongPressDirective implements OnDestroy {

  private timeoutId: number = null;
  private intervalId: number = null;

  private isLongPressing: boolean;
  private isPressing: boolean;

  private isAlive$ = new Subject<boolean>();

  constructor(private el: ElementRef , private broadcaster: MailBroadcaster) {

    this.broadcaster.on<any>("scroll")
      .pipe(takeUntil(this.isAlive$))
      .subscribe(data => {
        // console.log(" [Long Press] scrolling");
        this.endPress();
      });

  }

  @Output() onLongPress = new EventEmitter();
  @Output() onLongPressing = new EventEmitter();
  @Output() onLongPressEnd = new EventEmitter();


  @Input() timeout: number = 300;
  @Input() atComponent: string = "";

  @HostBinding("class.press")
  get press() {
    return this.isPressing;
  }

  @HostBinding("class.long-press")
  get longPress() {
    return this.isLongPressing;
  }

  @HostListener("window:scroll" , ["$event"])
  onScroll(event) {
    // console.log("window:scroll" , event);
    this.endPress();
  }

  @HostListener("touchstart" , ["$event"])
  @throttleable(200)
  public onMouseDown(event) {
    this.isPressing = true;
    this.isLongPressing = false;

    this.timeoutId = (<any>window).setTimeout(() => {
      this.isLongPressing = true;
      this.onLongPress.emit(event);

      this.intervalId = (<any>window).setInterval(() => {
        this.onLongPressing.emit(event);
      } , 30);
    } , this.timeout);
  }

  @HostListener("touchend" , ["$event"])
  @throttleable(300)
  public onMouseLeave() {
    this.endPress();
  }

  @HostListener("touchmove" , ["$event"])
  public onMouseMove() {
    this.endPress();
    this.onLongPressEnd.emit(this.atComponent);
  }

  private endPress() {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
    }

    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
    }

    if (this.isLongPressing) {
      this.isLongPressing = false;
    }

    if (this.isPressing) {
      this.isPressing = false;

    }

    setTimeout(() => this.broadcaster.broadcast("long_press_end"), 200);
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();

  }

}
