
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="tag_create_dialog create-tag-dialog">
  <div class="content create-rename-tag" *ngIf="!conversation && !message && !isMultiple">
    <div *ngIf="conversation || message" class="message">
      {{ "TAGS" | translate }}
    </div>
    <div id="create-tag-header" *ngIf="!isRename && !conversation && !message" class="message">
      {{ "CREATE_TAG" | translate }}
    </div>
    <div id="rename-tag-header" *ngIf="isRename && !conversation && !message" class="message">
      {{ 'EDIT_TAG_LBL' | translate }}
    </div>
    <div>
      <mat-form-field>
        <input id="create-tag-input" [(ngModel)]="tagName" #tagNameInput matInput placeholder="{{ 'TAG_NAME_LBL'| translate }}"
          (keyup)="changeText($event)" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
          [maxLength]="maxTagNameLength">
        <mat-hint>{{tagNameInput.value.length}}/{{maxTagNameLength}}</mat-hint>
      </mat-form-field>
    </div>
    <div class="color-action" *ngIf="!message">
      <mat-radio-group class="create-folder-color-selection with-dialog" (change)="updateDefaultColor($event)"
        [(ngModel)]="tagColor">
        <mat-radio-button class="grey-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#A8B0B9'}" value="#A8B0B9" (click)="updateDefaultColor('#A8B0B9')">
          <span *ngIf="tagColor?.toUpperCase() === '#A8B0B9'">
            <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
          </span>
        </mat-radio-button>
        <mat-radio-button class="sky-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#00B8D4'}" value="#00B8D4" (click)="updateDefaultColor('#00B8D4')">
          <span *ngIf="tagColor?.toUpperCase() === '#00B8D4'">
            <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
          </span>
        </mat-radio-button>
        <mat-radio-button class="blue-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#388EDA'}" value="#388EDA" (click)="updateDefaultColor('#388EDA')">
          <span *ngIf="tagColor?.toUpperCase() === '#388EDA'">
            <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
          </span>
        </mat-radio-button>
        <mat-radio-button class="navy-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#6358C7'}" value="#6358C7" (click)="updateDefaultColor('#6358C7')">
          <span *ngIf="tagColor?.toUpperCase() === '#6358C7'">
            <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
          </span>
        </mat-radio-button>
        <mat-radio-button class="red-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#F24336'}" value="#F24336" (click)="updateDefaultColor('#F24336')">
          <span *ngIf="tagColor?.toUpperCase() === '#F24336'">
            <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
         </span>
        </mat-radio-button>
        <mat-radio-button class="orange-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#FFAD16'}" value="#FFAD16" (click)="updateDefaultColor('#FFAD16')">
          <span *ngIf="tagColor?.toUpperCase() === '#FFAD16'">
            <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
          </span>
        </mat-radio-button>
        <mat-radio-button class="green-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#27AB7F'}" value="#27AB7F" (click)="updateDefaultColor('#27AB7F')">
          <span *ngIf="tagColor?.toUpperCase() === '#27AB7F'">
            <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
          </span>
        </mat-radio-button>
      </mat-radio-group>
      <button matTooltip="{{ 'MORE_TITLE' | translate }}" matTooltipPosition="above" id="create-tag-more-color" (click)="openColorDialog()" class="brand-color mat-button more-color-button">
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Icon/product/more-horiz" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Icon-24px" transform="translate(4.000000, 10.000000)" fill="#8B96A0">
                  <path d="M2,0 C0.9,0 0,0.9 0,2 C0,3.1 0.9,4 2,4 C3.1,4 4,3.1 4,2 C4,0.9 3.1,0 2,0 L2,0 Z M14,0 C12.9,0 12,0.9 12,2 C12,3.1 12.9,4 14,4 C15.1,4 16,3.1 16,2 C16,0.9 15.1,0 14,0 L14,0 Z M8,0 C6.9,0 6,0.9 6,2 C6,3.1 6.9,4 8,4 C9.1,4 10,3.1 10,2 C10,0.9 9.1,0 8,0 L8,0 Z" id="Shape"></path>
              </g>
          </g>
        </svg>
      </button>
    </div>
    <div class="actions">
      <a id="create-tag-cancel" (click)="closeDialog()">{{ 'CANCEL' | translate }}</a>
      <a id="create-tag-createbtn" [class.action-disabled]="!isValidTagNameNgmodule()" *ngIf="!isRename"
        (click)="save()">{{ 'CRAETE_FODLER_LBL' | translate }}</a>
      <a [class.action-disabled]="!isValidTagNameNgmodule()" *ngIf="isRename" (click)="save()">{{ 'SAVE' | translate }}</a>
    </div>
  </div>

  <div *ngIf="conversation || message || isMultiple">
    <vnc-header
    class="message"
    title="{{ 'TAGS' | translate }}"
    type="title-close"
    size="l"
    (onCloseClick)="closeDialog()"
    [showCloseIcon]="true"
    closeIconToolTip="{{ 'CLOSE' | translate }}">
  </vnc-header>
    <div class="content" [ngStyle]="{'height': selectedIndex == 1 ? '548px' : '346px' }">
      <div class="tags-tabs-section">
        <vnc-chips  text="{{ 'CALENDARS.NEW_LBL' | translate}}" type="static" [active]="selectedIndex == 1? false: true" size="m" (clickEvent)="selectedIndex = 0"></vnc-chips>
        <vnc-chips  text="{{ 'TAG_LIST' | translate}}" [active]="selectedIndex == 1? true: false" type="static" size="m" (clickEvent)="selectedIndex = 1"></vnc-chips>
      </div>
      <div *ngIf="selectedIndex === 0">
        <div class="tab-add-tag-part">
          <div class="add-input">
            <vnc-input (enter)="addTag()"
            [form]="tagControl" label="{{ 'CALENDARS.TAGS_LBL' | translate }}" placeholder="{{ 'CALENDARS.TAGS_LBL' | translate }}"
            [showLabel]="true"></vnc-input>

            <a (click)="addTag()" [class.action-disabled]="!isValidTagName()" class="add-link">{{ 'COMMON.ADD' | translate }}</a>
          </div>
        </div>
        <div class="tab-assigned-tag-part">
          <span class="assigned-tag">{{ 'SELECTED' | translate}}</span>
          <div class="mail-tags cdk-virtual-scroll-viewport">
            <ng-container *ngIf="assignedTags.length == 0">
              <div class="no-tag">
                {{ 'NO_TAG_SELECTED' | translate}}
              </div>
            </ng-container>
            <ng-container *ngIf="assignedTags.length > 0">
              <div class="mail-tag tag-section" *ngFor="let tag of assignedTags"
              [id]="'tag-selected-'+tag.id"
              [style.backgroundColor]="tag.rgb || tag.color || tagColor" [style.color]="getTagColor(tag.rgb || tag.color || tagColor)"
              (mouseover)="onMouseOver(tag)"
              (mouseout)="onMouseOut(tag)"
              >
              <span class="no-select">{{ tag.name }}</span>
              <mat-icon (click)="removeTag(tag)">close</mat-icon>
            </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div *ngIf="selectedIndex === 1">
        <div class="tab-add-tag-part">
          <div class="header-item-tag-dialog">
            <vnc-input
            leadingIconName="mdi-magnify"
            showClear="true"
            [form]="tagSearchControl" label="{{ 'CALENDARS.SEARCH' | translate }}" placeholder="{{ 'CALENDARS.SEARCH' | translate }}"
            [showLabel]="true"></vnc-input>
          </div>
          <div class="mail-tags cdk-virtual-scroll-viewport" style="margin-bottom:16px">
            <div class="mail-tag tag-section" *ngFor="let tag of tags| vpTagNameSearch : searchTag;"
              [id]="'tag-selected-'+tag.id"
              [style.backgroundColor]="tag.rgb || tag.color || tagColor" [style.color]="getTagColor(tag.rgb || tag.color || tagColor)"
              (mouseover)="onMouseOver(tag)"
              (mouseout)="onMouseOut(tag)" (click)="addTagToAssign(tag)"
              >
              <span class="no-select">{{ tag.name }}</span>
            </div>
          </div>
        </div>
        <div class="tab-assigned-tag-part">
          <span class="assigned-tag">{{ 'SELECTED' | translate}}</span>
          <div class="mail-tags cdk-virtual-scroll-viewport">
            <ng-container *ngIf="assignedTags.length == 0">
              <div class="no-tag">
                {{ 'NO_TAG_SELECTED' | translate}}
              </div>
            </ng-container>
            <ng-container *ngIf="assignedTags.length > 0">
              <div class="mail-tag tag-section" *ngFor="let tag of assignedTags" [id]="'tag-selected-'+tag.id"
                [style.backgroundColor]="tag.rgb || tag.color || tagColor"
                [style.color]="getTagColor(tag.rgb || tag.color || tagColor)" (mouseover)="onMouseOver(tag)"
                (mouseout)="onMouseOut(tag)">
                <span class="no-select">{{ tag.name }}</span>
                <mat-icon (click)="removeTag(tag)">close</mat-icon>
              </div>
            </ng-container>

          </div>
          <div >
            <vnc-checkbox label="{{ 'SHOW_GLOBAL_TAGS' | translate }}" [checked]="showGlobalTags" (change)="toggleShowGlobalTags(showGlobalTags)"></vnc-checkbox>
          </div>

        </div>
      </div>
    </div>
    <div class="actions">
      <div class="color-action" [ngStyle]="{'visibility': selectedIndex == 1 ? 'hidden' : 'visible' }">
        <mat-radio-group class="create-folder-color-selection with-dialog from-mai-detail"  (change)="updateDefaultColor($event)"
        [(ngModel)]="tagColor">
        <mat-radio-button *ngIf="clientWidth > 200" class="grey-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#A8B0B9'}" value="#A8B0B9" (click)="updateDefaultColor('#A8B0B9')">
          <span *ngIf="tagColor?.toUpperCase() === '#A8B0B9'">
            <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
          </span>
        </mat-radio-button>
        <mat-radio-button *ngIf="clientWidth > 412" class="sky-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#00B8D4'}" value="#00B8D4" (click)="updateDefaultColor('#00B8D4')">
          <span *ngIf="tagColor?.toUpperCase() === '#00B8D4'">
            <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
          </span>
        </mat-radio-button>
        <mat-radio-button *ngIf="clientWidth > 456" class="blue-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#388EDA'}" value="#388EDA" (click)="updateDefaultColor('#388EDA')">
          <span *ngIf="tagColor?.toUpperCase() === '#388EDA'">
            <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
          </span>
        </mat-radio-button>
        <mat-radio-button *ngIf="clientWidth > 502" class="navy-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#6358C7'}" value="#6358C7" (click)="updateDefaultColor('#6358C7')">
          <span *ngIf="tagColor?.toUpperCase() === '#6358C7'">
            <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
          </span>
        </mat-radio-button>
        <mat-radio-button *ngIf="clientWidth > 547" class="red-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#F24336'}" value="#F24336" (click)="updateDefaultColor('#F24336')">
          <span *ngIf="tagColor?.toUpperCase() === '#F24336'">
            <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
         </span>
        </mat-radio-button>
        <mat-radio-button *ngIf="clientWidth > 592" class="orange-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#FFAD16'}" value="#FFAD16" (click)="updateDefaultColor('#FFAD16')">
          <span *ngIf="tagColor?.toUpperCase() === '#FFAD16'">
            <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
          </span>
        </mat-radio-button>
        <mat-radio-button *ngIf="clientWidth > 538" class="green-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#27AB7F'}" value="#27AB7F" (click)="updateDefaultColor('#27AB7F')">
          <span *ngIf="tagColor?.toUpperCase() === '#27AB7F'">
            <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
          </span>
        </mat-radio-button>
        </mat-radio-group>
        <button matTooltip="{{ 'MORE_TITLE' | translate }}" matTooltipPosition="above" id="mobile_Sidebar_createfolder_morecplor" (click)="openColorDialog()" class="brand-color mat-button more-color-button">
          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Icon/product/more-horiz" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Icon-24px" transform="translate(4.000000, 10.000000)" fill="#8B96A0">
                    <path d="M2,0 C0.9,0 0,0.9 0,2 C0,3.1 0.9,4 2,4 C3.1,4 4,3.1 4,2 C4,0.9 3.1,0 2,0 L2,0 Z M14,0 C12.9,0 12,0.9 12,2 C12,3.1 12.9,4 14,4 C15.1,4 16,3.1 16,2 C16,0.9 15.1,0 14,0 L14,0 Z M8,0 C6.9,0 6,0.9 6,2 C6,3.1 6.9,4 8,4 C9.1,4 10,3.1 10,2 C10,0.9 9.1,0 8,0 L8,0 Z" id="Shape"></path>
                </g>
            </g>
          </svg>
        </button>
      </div>
      <div class="action-btn">
        <vnc-button class="no-hover" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" [type]="'secondary'" label="{{'CANCEL_MAIL_LABEL' | translate }}" (click)="closeDialog()"></vnc-button>
        <vnc-button [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" (click)="saveTags()">
            <span>{{'CALENDARS.SAVE_BTN_LBL' | translate }}</span>
        </vnc-button>

      </div>
    </div>
  </div>
</div>


<ng-template #checkIcon>
  <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/check" stroke="none" stroke-width="1" fill="#FFFF" fill-rule="evenodd">
          <polygon id="Shape" fill="#FFFF" points="9 16.17 4.83 12 3.41 13.41 9 19 21 7 19.59 5.59"></polygon>
      </g>
  </svg>
</ng-template>