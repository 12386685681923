
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { createSelector } from "@ngrx/store";
import * as fromFeature from "../reducers";
import { mailFolderAdapter } from "../reducers/mail-folder.reducer";
import { mailTagAdapter } from "../../../reducers/mail-tag.reducer";
import { conversationAdapter } from "../reducers/conversation.reducer";
import { mailAdapter } from "../reducers/mail.reducer";
import { Conversation, Message } from "../../shared/models";
import { Dictionary } from "@ngrx/entity";
import { MailFolder } from "../../models/mail-folder.model";
import * as _ from "lodash";
import { MailTag } from "../../models/mail-tag.model";
import { mailMessageAdapter } from "../reducers/message.reducer";
import { getMailTagState } from "src/app/reducers";
import { MailUtils } from "../../utils/mail-utils";
import { MailConstants } from "src/app/common/utils/mail-constants";

// import { Message } from "@angular/compiler/src/i18n/i18n_ast";

export const {
  selectIds: getMailFolderIds,
  selectEntities: getMailFolderEntities,
  selectAll: getAllMailFolder,
  selectTotal: getTotalMailFolder,
} = mailFolderAdapter.getSelectors(fromFeature.getMailFolderState);
export const getMailFolders = getAllMailFolder;

export const {
  selectIds: getMailTagIds,
  selectEntities: getMailTagEntities,
  selectAll: getAllMailTag,
  selectTotal: getTotalMailTag,
} = mailTagAdapter.getSelectors(getMailTagState);
export const getMailTags = getAllMailTag;

export const getFolderById = createSelector(
  getMailFolderEntities,
  (entities: Dictionary<MailFolder>, folderId: string) => {
    return entities[folderId];
  }
);

export const getUserFolders = createSelector(
  getAllMailFolder,
  (folders: MailFolder[]) => {
    return folders.filter(f => !f.icon && f.name !== MailConstants.EMAIL_TEMPLATE);
  }
);

export const getSystemFolders = createSelector(
  getAllMailFolder,
  (folders: MailFolder[]) => {
    return folders;
    // return folders.filter(f => f.icon || f.name === MailConstants.EMAIL_TEMPLATE);
  }
);

export const getTagById = createSelector(
  getMailTagEntities,
  (entities: Dictionary<MailTag>, tagId: string) => {
    return entities[tagId];
  }
);

function getChildFolders(folders: MailFolder[]): MailFolder[] {
  let allFolders: MailFolder[] = [];
  let childFolders: MailFolder[] = [];
  folders.filter(f => f.children && f.children.length > 0).forEach(f => {
    allFolders = getChildFolders(f.children);
    childFolders = [...childFolders, ...f.children];
  });
  return [...allFolders, ...childFolders];
}

export const getChildFolderById = createSelector(
  getAllMailFolder,
  (folders: MailFolder[], folderId: string) => {
    const childFolders = getChildFolders(folders);
    return _.find(childFolders, {id: folderId});
  }
);

export const getSharedFolderById = createSelector(
  getAllMailFolder,
  (folders: MailFolder[], sharedId: string) => {
    const childFolders = getChildFolders(folders);
    const zid = sharedId.split(":")[0];
    const rid = sharedId.split(":")[1];
    const allFolders = [...folders.filter(f => f.owner), ...childFolders];
    return _.find(allFolders, {zid: zid, rid: rid});
  }
);

export const {
  selectIds: getConversationIds,
  selectEntities: getConversationEntities,
  selectAll: getAllConversation,
  selectTotal: getTotalConversation,
} = conversationAdapter.getSelectors(fromFeature.getConversationState);

export const getConversations = getAllConversation;

export const {
  selectIds: getMailIds,
  selectEntities: getMailEntities,
  selectAll: getAllMail,
  selectTotal: getTotalMail,
} = mailAdapter.getSelectors(fromFeature.getMailState);

export const getMails = getAllMail;


export const getConversationsByQuery = (state: fromFeature.MailRootState, query: string) => {
  return getAllConversation(state).filter(c => c.query === query);
};

export const getSelectedConversations = createSelector(
  getConversationEntities,
  fromFeature.getSelectedConversationIds,
  (entities: Dictionary<Conversation>, conversationIds: string[]) => {
    return conversationIds.map(conversationId => {
      return entities[conversationId];
    });
  }
);

export const getSelectedMessages = createSelector(
  getMailEntities,
  fromFeature.getSelectedMessageIds,
  (entities: Dictionary<Message>, messageIds: string[]) => {
    return messageIds.map(messageId => {
      return entities[messageId.replace("m_", "")];
    });
  }
);

export const getCheckedConversations = createSelector(
  getConversationEntities,
  fromFeature.getCheckedConversationIds,
  (entities: Dictionary<Conversation>, conversationIds: string[]) => {
    return conversationIds.map(conversationId => {
      return entities[conversationId];
    });
  }
);

export const getCheckedMessages = createSelector(
  getMailEntities,
  fromFeature.getCheckedMessageIds,
  (entities: Dictionary<Message>, messageIds: string[]) => {
    return messageIds.map(messageId => {
      return entities[messageId];
    });
  }
);

export const getConversationsByIds = createSelector(
  getConversationEntities,
  (entities: Dictionary<Conversation>, conversationIds: string[]) => {
    return conversationIds.map(conversationId => {
      return entities[conversationId];
    });
  }
);

export const getConversationById = createSelector(
  getConversationEntities,
  (entities: Dictionary<Conversation>, conversationId: string) => {
    return entities[conversationId];
  }
);

export const getMessagesByIds = createSelector(
  getMailEntities,
  (entities: Dictionary<Message>, messageIds: string[]) => {
    return messageIds.map(messageId => {
      return entities[messageId];
    }).filter(m => !!m);
  }
);

export const getMessageById = createSelector(
  getMailEntities,
  (entities: Dictionary<Message>, messageId: string) => {
    return entities[messageId];
  }
);

export const getCurrentConversations = createSelector(
  fromFeature.getCurrentQuery,
  getAllConversation,
  (query, conversations) => {
    return conversations.filter(c => c.query === query);
  }
);

export const getConversationsByTag = createSelector(
  getAllConversation,
  (conversations: Conversation[], tagName: string) => conversations.filter(c =>
    c.tags.filter(t => t.toLowerCase() === tagName.toLowerCase()).length > 0 )
);

export const {
  selectIds: getMessageIds,
  selectEntities: getMailMessageEntities,
  selectAll: getAllMessages,
  selectTotal: getTotalMailMesssage,
} = mailMessageAdapter.getSelectors(fromFeature.getMessageState);
export const getMailMessages = getAllMessages;

export const getCurrentMessages = createSelector(
  fromFeature.getCurrentQuery,
  getMails,
  (query, messages) =>  {
    return messages.filter(m => m.query === query);
  }
);

export const getSealedDataById = createSelector(
  fromFeature.getSealedData,
  (sealedData: any, id: string) => {
    return sealedData[id];
  }
);
