
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div fxLayout="column" class="preference-dialog-form">
  <div class="mail__dialog-header desktop_view">
    <div class="header_lbl disable-select">
      {{ 'ACTIVITY_STREAM' | translate }}
    </div>
    <div class="submit">
      <button mat-button (click)="cancel()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="dialog_body mail__dialog-body mail__dialog-body--pad">
    <div class="content">
      <div class="actions">
        <div class="activity-stream-lbl">
          {{ 'ACTIVITY_STREAM_MSG' | translate }}
        </div>
        <mat-divider></mat-divider>
        <div class="keep">
          <mat-checkbox [(ngModel)]="enableActivityStream">{{ 'ACTIVITY_STREAM_ENABLE_MOVING' | translate }}</mat-checkbox>
        </div>
        <div class="activity-stream-data">
          <mat-checkbox [(ngModel)]="asListTest" [disabled]="!enableActivityStream">{{ 'ACTIVITY_STREAM_MSG_SENT' | translate }}</mat-checkbox>
          <mat-checkbox [(ngModel)]="asBulkTest" [disabled]="!enableActivityStream">{{ 'ACTIVITY_STREAM_COMMERCIAL' | translate }}</mat-checkbox>
          <div class="activity-stream-fields">
            <mat-checkbox [(ngModel)]="asMeTest" [disabled]="!enableActivityStream">{{ 'ACTIVITY_STREAM_MSG_FIELD' | translate }}</mat-checkbox>
            <mat-select [(ngModel)]="selected" [disabled]="!enableActivityStream">
              <mat-option class="show-field-select" value="to">{{ 'TO' | translate}}</mat-option>
              <mat-option class="show-field-select" value="tocc">{{ 'TO_OR_CC' | translate}}</mat-option>
            </mat-select>
            <span>{{ 'ACTIVITY_STREAM_MSG_FIELD_1' | translate }}</span>
          </div>
          <mat-checkbox [(ngModel)]="asAddressBookTest" [disabled]="!enableActivityStream">{{ 'ACTIVITY_STREAM_MSG_PEOPLE' | translate }}</mat-checkbox>
        </div>
      </div>
      <mat-divider></mat-divider>
    </div>
  </div>
  <div class="footer_div">
    <div class="advance-control">
      <a (click)="advanceControlSelect()">{{ 'ACTIVITY_STREAM_ADVANCE_CONTROL' | translate }}</a>
    </div>
    <div class="footer_save_cancel">
      <button mat-button class="cancel_btn" (click)="cancel()">{{ "CANCEL_MAIL_LABEL" | translate }}</button>
      <button mat-button class="save_btn" (click)="activityStreamSave()">{{ "COMMON.SAVE" | translate }}</button>
    </div>
  </div>
</div>