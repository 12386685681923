
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="about_dialog" [style.backgroundImage]="backgroundImage">
  <div class="mail__dialog-header mobile_header">
    <div>
      <button id="mobile_sidebar_about_version_backbtn" mat-button (click)="close()">
        <mat-icon class="disable-select">chevron_left</mat-icon>
      </button>
    </div>
    <div class="submit"></div>
  </div>
  <div class="mail__dialog-header desktop_view">
    <div class="submit">
      <button mat-button (click)="close()">
        <mat-icon class="disable-select">close</mat-icon> 
      </button>
    </div>
  </div>
  <div class="mail__dialog-body mail__dialog-body--pad">
    <div class="content">
      <img alt="" class="vncmail_icon disable-select" [src]="aboutLogo">
      <h6 class="app_verion_name">
        <div class="logo-title">
            <span class="disable-select dfb_text">{{prefixBold}}</span>
            <span class="product_name disable-select">{{suffixNormal}}</span>
        </div>
      </h6>
      <span class="app_version disable-select">
        {{ 'VERSION_LBL' | translate }} {{ app_version }}
      </span>
      <p id="mobile_sidebar_about_version_changelog" class="change_log">
        <a class="open-new-window disable-select" href="{{changeLog}}" target="_blank">{{ 'CHANGELOG'
          | translate }}</a>
      </p>
    </div>
  </div>
</div>