
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, NgZone, OnDestroy, OnChanges } from "@angular/core";
import { MailBroadcaster } from "../../../../common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "../../../../common/enums/broadcast.enum";
import { take, takeUntil } from "rxjs/operators";
import { MailRootState } from "src/app/mail/store";
import { Store } from "@ngrx/store";
import { getMailFolders } from "src/app/mail/store/selectors";
import { Subject } from "rxjs";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { MailFolder } from "src/app/mail/models/mail-folder.model";
import { NestedTreeControl } from "@angular/cdk/tree";
import { SearchRequest, Message } from "../../models";
import { MailService } from "../../services/mail-service";
import { ToastService } from "src/app/common/providers/toast.service";
import { ConversationRepository } from "src/app/mail/repositories/conversation.repository";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { TranslateService } from "@ngx-translate/core";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "vp-attach-email-dialog",
    templateUrl: "./attach-email-dialog.component.html"
})
export class AttachEmailDialogComponent implements OnInit, OnDestroy {

    MailConstants = MailConstants;
    private isAlive$ = new Subject<boolean>();
    allFolders: MailFolder[] = [];
    offset: number = 0;
    limit: number = 50;
    selectedFolder: MailFolder;
    isMoreMessage: boolean = false;
    msgs: Message[] = [];
    selectedEmails: Message[] = [];
    searchField: string = "";
    treeControl = new NestedTreeControl<MailFolder>(node => node.children);
    dataSource = new MatTreeNestedDataSource<MailFolder>();
    hasChild = (_: number, node: MailFolder) => !!node.children && node.children.length > 0;
    constructor(
        public dialogRef: MatDialogRef<AttachEmailDialogComponent>,
        private mailBroadcaster: MailBroadcaster,
        private ngZone: NgZone,
        private appStore: Store<MailRootState>,
        private ngxHotKeyService: NgxHotkeysService,
        private mailService: MailService,
        private toastService: ToastService,
        private convRepository: ConversationRepository,
        private translate: TranslateService
    ) {
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
        this.ngxHotKeyService.pause(this.ngxHotKeyService.hotkeys);
        this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_ATTACH_EMAIL_DIALOG).pipe(take(1)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
        this.appStore.select(getMailFolders).pipe(take(1)).subscribe(res => {
            if (res) {
                this.allFolders = res;
                this.dataSource.data = this.allFolders.filter( f => f.absFolderPath !== "/starred");
                this.treeControl.dataNodes = this.allFolders;
                this.treeControl.expandAll();
                this.selectedFolder = this.allFolders.filter(f => f.id === "2")[0];
                this.getMessages(this.selectedFolder);
            }
        });
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.unsubscribe();
        this.ngxHotKeyService.unpause(this.ngxHotKeyService.hotkeys);
    }

    close(ids?: any) {
        this.dialogRef.close({ ids: ids });
    }

    folderItem(node: MailFolder): void {
        this.offset = 0;
        this.limit = 50;
        this.selectedFolder = node;
        this.getMessages(node);
    }

    loadPreviousMail(): void {
        this.offset -= this.limit;
        this.getMessages(this.selectedFolder);
    }

    loadNextMail(): void {
        this.offset += this.limit;
        this.getMessages(this.selectedFolder);
    }

    getMessages(node: MailFolder): void {
        this.selectedEmails = [];
        if (node !== null) {
            if (node.id.indexOf(":") !== -1) {
                this.searchField = "in:" + "\"" + node.shareFolderSearchPath.substr(1) + "\"";
            } else {
                this.searchField = "in:" + "\"" + node.absFolderPath.substr(1) + "\"";
            }
            const query = "inid:" + "\"" + node.id + "\"";
            this.getAllMessages(query);
        } else {
            this.getAllMessages(this.searchField);
        }
    }

    getAllMessages(query: string): void {
        const searchQuery: SearchRequest = {
            fullConversation: 1,
            limit: this.limit,
            needExp: 1,
            offset: this.offset,
            query: query,
            types: "message"
        };
        this.mailService.searchRequest(searchQuery).pipe(take(1)).subscribe(messages => {
            if (messages.m) {
                this.processMessages(messages.m);
            } else {
                this.msgs = [];
            }
            if (!messages.more) {
                this.isMoreMessage = true;
            } else {
                this.isMoreMessage = false;
            }
            if (this.offset < 50) {
                if (messages.m) {
                    this.limit = messages.m.length;
                } else {
                    this.limit = 0;
                    this.offset = 0;
                }
            }
        }, error => {
            this.toastService.showPlainMessage(error);
        });
    }

    processMessages(message: Message[]): void {
        this.msgs = message;
        this.msgs.map(m => {
            m.senderEmail = m.e.reverse().find(f => f.t === "f").a;
        });
    }

    getAvatar(email) {
        return this.convRepository.getAvatar(email);
    }

    selectEmail(msg: Message, index: number, $event: MouseEvent): void {
        $event.preventDefault();
        if (this.selectedEmails.indexOf(msg) === -1) {
            if ($event.ctrlKey) {
                this.selectedEmails.push(msg);
            } else {
                this.selectedEmails = [];
                this.selectedEmails.push(msg);
            }
        } else {
            if (this.selectedEmails.length > 1) {
                this.selectedEmails.splice(this.selectedEmails.indexOf(msg), 1);
            }
        }
    }


    isSelectedEmailMessage(email: any): boolean {
        return this.selectedEmails.indexOf(email) !== -1;
    }

    attachMessages(): void {
        const ids = this.selectedEmails.map(m => m.id);
        this.close(ids);
    }

    searchMessages(): void {
        this.offset = 0;
        this.limit = 50;
        this.selectedFolder = null;
        this.getAllMessages(this.searchField);
    }
}
