
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../../actions";
import { Contact } from "../../models/contact.model";
import { Update } from "@ngrx/entity/src/models";
import { AppActionTypes } from "../../../actions/app";

export class ContactsAcTypes extends AppActionTypes {
// load Contacts
static LOAD_CONTACTS = "[Contacts] Load Contacts";
static LOAD_CONTACTS_FAIL = "[Contacts] Load Contacts Fail";
static LOAD_CONTACTS_SUCCESS = "[Contacts] Load Contacts Success";

// load nex Contacts
static NEXT_LOAD_CONTACTS = "[Contacts] Next Load Contacts";
static NEXT_LOAD_CONTACTS_FAIL = "[Contacts] Next Load Contacts Fail";
static NEXT_LOAD_CONTACTS_SUCCESS = "[Contacts] Next Load Contacts Success";

// create Contact
static CREATE_CONTACT = "[Contacts] Create Contact";
static CREATE_CONTACT_FAIL = "[Contacts] Create Contact Fail";
static CREATE_CONTACT_SUCCESS = "[Contacts] Create Contact Success";
// update Contact
static UPDATE_CONTACT = "[Contacts] Update Contact";
static UPDATE_CONTACT_FAIL = "[Contacts] Update Contact Fail";
static UPDATE_CONTACT_SUCCESS = "[Contacts] Update Contact Success";
// remove Contact
static REMOVE_CONTACT = "[Contacts] Remove Contact";
static REMOVE_CONTACT_FAIL = "[Contacts] Remove Contact Fail";
static REMOVE_CONTACT_SUCCESS = "[Contacts] Remove Contact Success";

static SEARCH_TOGGLE = "[Contacts] Toggle search mode";
static REMOVE_MANY_CONTACT_SUCCESS = "[Contacts] Remove Many Contact Success";

// remove Contact
static RESTORE_CONTACT = "[Contacts] Restore Contact";
static RESTORE_CONTACT_FAIL = "[Contacts] Restore Contact Fail";
static RESTORE_CONTACT_SUCCESS = "[Contacts] Restore Contact Success";

static START_LOADING = "[Contacts] Start Loading";
static STOP_LOADING = "[Contacts] Stop Loading";

static STORE_SEARCHPARAMS = "[Contacts] Store search parameter";

static EMPTY_CONTACTS_SUCCESS = "[Contacts] Empty Contacts";

}

export class LoadContacts implements Action {
  readonly type = ContactsAcTypes.LOAD_CONTACTS;
}

export class SetSearchMode implements Action {
  readonly type = ContactsAcTypes.SEARCH_TOGGLE;
  constructor(public payload: boolean) { }
}

export class StoreSearchParams implements Action {
  readonly type = ContactsAcTypes.STORE_SEARCHPARAMS;
  constructor(public payload: any) { }
}

export class LoadContactsFail implements Action {
  readonly type = ContactsAcTypes.LOAD_CONTACTS_FAIL;
}

export class LoadContactsSuccess implements Action {
  readonly type = ContactsAcTypes.LOAD_CONTACTS_SUCCESS;
  constructor(public payload: { currOffset: number, isMoreContacts: boolean, contact: Contact[] }) { }
}

export class NextLoadContacts implements Action {
  readonly type = ContactsAcTypes.NEXT_LOAD_CONTACTS;
}

export class NextLoadContactsFail implements Action {
  readonly type = ContactsAcTypes.NEXT_LOAD_CONTACTS_FAIL;
}

export class NextLoadContactsSuccess implements Action {
  readonly type = ContactsAcTypes.NEXT_LOAD_CONTACTS_SUCCESS;
  constructor(public payload: { currOffset: number, isMoreContacts: boolean, contacts: Contact[] }) { }
}

export class CreateContact implements Action {
  readonly type = ContactsAcTypes.CREATE_CONTACT;
}

export class CreateContactFail implements Action {
  readonly type = ContactsAcTypes.CREATE_CONTACT_FAIL;
}

export class CreateContactSuccess implements Action {
  readonly type = ContactsAcTypes.CREATE_CONTACT_SUCCESS;
  constructor(public payload: Contact) { }
}

export class UpdateContact implements Action {
  readonly type = ContactsAcTypes.UPDATE_CONTACT;
}

export class UpdateContactFail implements Action {
  readonly type = ContactsAcTypes.UPDATE_CONTACT_FAIL;
}

export class UpdateContactSuccess implements Action {
  readonly type = ContactsAcTypes.UPDATE_CONTACT_SUCCESS;
  constructor(public payload: Update<Contact>) { }
}

export class RemoveContact implements Action {
  readonly type = ContactsAcTypes.REMOVE_CONTACT;
}

export class RemoveContactFail implements Action {
  readonly type = ContactsAcTypes.REMOVE_CONTACT_FAIL;
}

export class RemoveContactSuccess implements Action {
  readonly type = ContactsAcTypes.REMOVE_CONTACT_SUCCESS;
  constructor(public payload: string) { }
}

export class RemoveMultipleContactSuccess implements Action {
  readonly type = ContactsAcTypes.REMOVE_MANY_CONTACT_SUCCESS;
  constructor(public payload: string[]) { }
}

export class EmptyContactSuccess implements Action {
  readonly type = ContactsAcTypes.EMPTY_CONTACTS_SUCCESS;
  constructor() { }
}

export class RestoreContact implements Action {
  readonly type = ContactsAcTypes.RESTORE_CONTACT;
}

export class RestoreContactFail implements Action {
  readonly type = ContactsAcTypes.RESTORE_CONTACT_FAIL;
}

export class RestoreContactSuccess implements Action {
  readonly type = ContactsAcTypes.RESTORE_CONTACT_SUCCESS;
  constructor(public payload: Contact[]) { }
}

export class StartLoading implements Action {
  readonly type = ContactsAcTypes.START_LOADING;
}

export class StopLoading implements Action {
  readonly type = ContactsAcTypes.STOP_LOADING;
}

export type ContactsAction =
  | LoadContacts
  | LoadContactsFail
  | LoadContactsSuccess
  | NextLoadContacts
  | NextLoadContactsFail
  | NextLoadContactsSuccess
  | CreateContact
  | CreateContactFail
  | CreateContactSuccess
  | UpdateContact
  | UpdateContactFail
  | UpdateContactSuccess
  | RemoveContact
  | RemoveContactFail
  | RemoveContactSuccess
  | RemoveMultipleContactSuccess
  | SetSearchMode
  | RestoreContact
  | RestoreContactFail
  | RestoreContactSuccess
  | StartLoading
  | StopLoading
  | StoreSearchParams
  | EmptyContactSuccess;
