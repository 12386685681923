
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { NgModule, Injectable } from "@angular/core";
import { RouterModule, Routes, CanLoad, Route, PreloadAllModules } from "@angular/router";
import { Observable } from "rxjs";
import { map, distinctUntilChanged } from "rxjs/operators";
import { ConfigService } from "./config.service";
import { AuthService } from "./common/providers/auth.service";
import { environment } from "src/environments/environment";
import { PageNotFoundComponent } from "./shared/components/page-not-found/page-not-found.component";
import { MailConstants } from "./common/utils/mail-constants";
import { Store, select } from "@ngrx/store";
import { MailRootState } from "./mail/store";
import { SetUserProfile } from "./actions/app";
import { getOnlineStatus } from "./reducers";
import { ElectronService } from "./services/electron.service";

@Injectable()
export class AuthGuard implements CanLoad {
  isCordovaOrElectron = environment.isCordova || environment.isElectron;
  isOnline = false;

  constructor(
    private configService: ConfigService,
    public appStore: Store<MailRootState>,
    private authService: AuthService,
    private electronService: ElectronService) {

    this.appStore.pipe(select(getOnlineStatus), distinctUntilChanged()).subscribe(isOnline => {
      console.log("[AuthGuard][getOnlineStatus]", isOnline);
      this.isOnline = isOnline;
    });
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    if (this.isCordovaOrElectron) {
      return true;
    } else {
      console.log("[AuthGuard][canLoad][getProfile], is online?", this.isOnline);

      if (!this.isOnline) {
        const profileUser = localStorage.getItem("profileUser");
        console.log("[AuthGuard][canLoad][getProfile] offline", profileUser);
        return !!profileUser;
      }

      return this.authService.getProfile().pipe(
        map(profile => {
          if (!profile) {
            if (this.electronService.isElectron) {
              this.electronService.deleteFromStorage(MailConstants.TOKEN);
            } else {
              localStorage.removeItem(MailConstants.TOKEN);
            }
            if ( window.location && window.location.href.replace(/\/$/, "") !== window.location.origin ) {
              let currentDate = new Date();
              currentDate.setSeconds(currentDate.getSeconds() + 300);
              document.cookie = "redirectUrl=" + encodeURIComponent(window.location.href) + ";expires=" + currentDate.toUTCString() + ";path=/";
            }
            window.location.href = this.configService.API_URL + "/api/login";
            return false;
          }

          const userProfile = { firstName: profile.user.firstName, lastName: profile.user.lastName, email: profile.user.email };

          localStorage.setItem("profileUser", JSON.stringify(userProfile));

          this.appStore.dispatch(new SetUserProfile(userProfile));
          if (profile) {
            let allcookies = document.cookie;
            if (allcookies) {
              let cookiearray = allcookies.split(";");
              let redirectUrl;
              for (let i = 0; i < cookiearray.length; i++) {
                let name = cookiearray[i].split("=")[0];
                if ( name && "redirectUrl" === name.trim()) {
                  redirectUrl = decodeURIComponent(cookiearray[i].split("=")[1]);
                  break;
                }
              }
              if (redirectUrl) {
                let currentDate = new Date();
                currentDate.setSeconds(currentDate.getSeconds() - 300);
                document.cookie = "redirectUrl=;expires=" + currentDate.toUTCString() + ";path=/";
                window.location.href = redirectUrl;
              }
            }
          }
          return !!profile;
        }));
    }
  }
}

let routes: Routes = [
  {
    path: "",
    redirectTo: "mail",
    pathMatch: "full"
  },
  {
    path: "mail",
    canLoad: [AuthGuard],
    data: {appName: "VNCMail", sidebar: true, fullWidth: true, path: "/mail"},
    loadChildren: () => import("./mail/mail.module").then(m => m.MailModule)
  },
  {
    path: "preferences",
    canLoad: [AuthGuard],
    data: {appName: "VNCMail", sidebar: true, fullWidth: true, path: "/preferences"},
    loadChildren: () => import("./preference/preference.module").then(m => m.PreferenceModule)
  },
  {
    path: "briefcase",
    canLoad: [AuthGuard],
    data: {appName: "VNCBriefcase", sidebar: true, fullWidth: false, path: "/briefcase"},
    loadChildren: () => import("./briefcase/briefcase.module").then(m => m.BriefcaseModule)
  },
  {
    path: "contacts",
    canLoad: [AuthGuard],
    data: {appName: "VNCContact", sidebar: true, fullWidth: true, path: "/contacts"},
    loadChildren: () => import("./contacts/contacts.module").then(m => m.ContactsModule)
  },
  {
    path: "task",
    canLoad: [AuthGuard],
    data: {appName: "VNCMail", sidebar: false, fullWidth: true, path: "/task"},
    loadChildren: () => import("./task/task.module").then(m => m.TaskModule)
  },
  {
    path: "calendar",
    canLoad: [AuthGuard],
    data: {appName: "VNCMail", sidebar: true, fullWidth: true, path: "/calendar"},
    loadChildren: () => import("./calendar/calendar.module").then(m => m.CalendarModule)
  },
  {
    path: "**",
    component: PageNotFoundComponent
  }
];
if (environment.calendarOnly) {
  routes = [
    {
      path: "",
      redirectTo: "calendar",
      pathMatch: "full"
    },
    {
      path: "calendar",
      canLoad: [AuthGuard],
      data: {appName: "VNCMail", sidebar: true, fullWidth: true, path: "/calendar"},
      loadChildren: () => import("./calendar/calendar.module").then(m => m.CalendarModule)
    },
    {
      path: "preferences",
      canLoad: [AuthGuard],
      data: {appName: "VNCMail", sidebar: true, fullWidth: true, path: "/preferences"},
      loadChildren: () => import("./preference/preference.module").then(m => m.PreferenceModule)
    },
    {
      path: "**",
      component: PageNotFoundComponent
    }
  ];
}


@NgModule({
  imports: [RouterModule.forRoot(routes,  {
    // Tell the router to use the HashLocationStrategy.
    useHash: false,
    enableTracing: false,

      // This will tell Angular to preload the lazy-loaded routes after the
      // application has been bootstrapped. This will extend to both top-level
      // and nested lazy-loaded modules.
      preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
