
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy, Inject, Renderer2 } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { take } from "rxjs/operators";
import { ToastService } from "src/app/common/providers/toast.service";
import { CommonService } from "src/app/services/ common.service.";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { ResizeEvent } from "angular-resizable-element";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "vp-calendar-equipment-dialog",
    templateUrl: "./calendar-equipment-dialog.component.html"
})
export class CalendarEquipmentDialogComponent implements OnInit, OnDestroy {
    nameField: string = "";
    descriptionField: string = "";
    contactField: string = "";
    siteField: string = "";
    buildingField: string = "";
    floorField: string = "";
    calResource: any[] = [];
    selectedCalResouce: any[] = [];
    selectedItems: any[] = [];
    selectedEquipmentItem: any[] = [];
    usersData: any[] = [];
    isMobileScreen: boolean = false;
    columns: any[] = [];
    selectedColumns: any[] = [];
    itemsById: any = {};
    dateInfo: any;

    constructor(
        public dialogRef: MatDialogRef<CalendarEquipmentDialogComponent>,
        private commonService: CommonService,
        private toastService: ToastService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private breakpointObserver: BreakpointObserver,
        private renderer: Renderer2,
        private translateService: TranslateService
    ) {
        this.translateService.get([
            "CALENDARS.NAME_LBL",
            "CALENDARS.LOCATION_EQUIPMENT_LBL",
            "CALENDARS.CONTACT_EQUIPMENT_LBL",
            "CALENDARS.CAPACITY_LBL",
            "CALENDARS.STATUS_EQUIPMENT_LBL"
        ]).pipe(take(1)).subscribe(res => {
            this.columns = [{
                width: "100px",
                title: res["CALENDARS.NAME_LBL"]
            }, {
                width: "100px",
                title: res["CALENDARS.LOCATION_EQUIPMENT_LBL"]
            }, {
                width: "100px",
                title: res["CALENDARS.CONTACT_EQUIPMENT_LBL"]
            }, {
                width: "100px",
                title: res["CALENDARS.CAPACITY_LBL"]
            }, {
                width: "100px",
                title: res["CALENDARS.STATUS_EQUIPMENT_LBL"]
            }];
            this.selectedColumns = [{
                width: "100px",
                title: res["CALENDARS.NAME_LBL"]
            }, {
                width: "100px",
                title: res["CALENDARS.LOCATION_EQUIPMENT_LBL"]
            }, {
                width: "100px",
                title: res["CALENDARS.CONTACT_EQUIPMENT_LBL"]
            }, {
                width: "100px",
                title: res["CALENDARS.CAPACITY_LBL"]
            }, {
                width: "100px",
                title: res["CALENDARS.STATUS_EQUIPMENT_LBL"]
            }];
        });
        if (this.data.usersData) {
            const uData = this.data.usersData;
            uData.map(item => {
                this.selectedCalResouce.push({
                    id: item.email,
                    name: item.name,
                    email: item.email,
                    resourceType: "equipment",
                    fullName: item.email
                });
            });
        }
        if (this.data.dateInfo) {
            this.dateInfo = this.data.dateInfo;
        }
    }

    ngOnInit() {
        this.breakpointObserver
        .observe(["(max-width: 599px)"])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.isMobileScreen = true;
          } else {
            this.isMobileScreen = false;
          }
        });
    }

    ngOnDestroy() { }

    close(): void {
        this.dialogRef.close();
    }

    searchRequest(): void {
        const request = {
            "SearchCalendarResourcesRequest": {
                "@": {
                    xmlns: "urn:zimbraAccount"
                },
                attrs: "fullName,email,zimbraCalResLocationDisplayName,zimbraCalResContactEmail,description,zimbraCalResType",
                limit: 50,
                needExp: 1,
                offset: 0,
                searchFilter: {
                    conds: {
                        cond: [{
                            attr: "zimbraCalResType",
                            op: "eq",
                            value: "Equipment"
                        }]
                    }
                }
            }
        };
        if (this.nameField !== "") {
            request.SearchCalendarResourcesRequest["name"] = {
                "_content": this.nameField
            };
        }
        if (this.descriptionField !== "") {
            request.SearchCalendarResourcesRequest.searchFilter.conds.cond.push({
                "attr": "description",
                "op": "has",
                "value": this.descriptionField
            });
        }
        if (this.contactField !== "") {
            request.SearchCalendarResourcesRequest.searchFilter.conds.cond.push({
                "attr": "zimbraCalResContactName",
                "op": "has",
                "value": this.descriptionField
            });
        }
        if (this.siteField !== "") {
            request.SearchCalendarResourcesRequest.searchFilter.conds.cond.push({
                "attr": "zimbraCalResSite",
                "op": "has",
                "value": this.siteField
            });
        }
        if (this.buildingField !== "") {
            request.SearchCalendarResourcesRequest.searchFilter.conds.cond.push({
                "attr": "zimbraCalResBuilding",
                "op": "has",
                "value": this.buildingField
            });
        }
        if (this.floorField !== "") {
            request.SearchCalendarResourcesRequest.searchFilter.conds.cond.push({
                "attr": "zimbraCalResFloor",
                "op": "has",
                "value": this.floorField
            });
        }
        this.commonService.createBatchRequest(request).pipe(take(1)).subscribe(res => {
            if (!!res && res.SearchCalendarResourcesResponse && res.SearchCalendarResourcesResponse[0].calresource) {
                const calendarResouce = res.SearchCalendarResourcesResponse[0].calresource;
                this.calResource = [];
                const emails = [];
                calendarResouce.map(cal => {
                    emails.push(cal._attrs.email);
                    this.calResource.push({
                        id: cal.id,
                        name: cal.name,
                        email: cal._attrs.email,
                        resourceType: cal._attrs.zimbraCalResType,
                        fullName: cal._attrs.fullName
                    });
                });
                this.commonService.getFreeBusy({ startTime: this.dateInfo.startTime.getTime(), endTime: this.dateInfo.endTime.getTime(), uid: emails.join(",") }).subscribe(v => {
                    console.log("[getFreeBusy]", this.dateInfo, v);
                    this._handleResponseFreeBusy(emails, v);
                });
            } else {
                this.calResource = [];
            }
        }, error => {
            this.toastService.showPlainMessage(error);
        });
    }

    selectEvent(item: any, index: number, $event: MouseEvent): void {
        $event.preventDefault();
        if (this.selectedItems.indexOf(item) === -1) {
            if ($event.ctrlKey) {
                this.selectedItems.push(item);
            } else {
                this.selectedItems = [];
                this.selectedItems.push(item);
            }
        } else {
            if (this.selectedItems.length > 1) {
                this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
            }
        }
    }

    isSelectedItem(item: any): boolean {
        return this.selectedItems.indexOf(item) !== -1;
    }

    selectAll(): void {
        this.calResource.map(item => {
            this.selectedCalResouce.push(item);
        });
        this.selectedItems = [];
        this.calResource = [];
    }

    addItem(): void {
        this.selectedItems.map(item => {
            this.selectedCalResouce.push(item);
            this.calResource.splice(this.calResource.indexOf(item), 1);
        });
        this.selectedItems = [];
    }

    selectResouceEvent(item: any, index: number, $event: MouseEvent): void {
        $event.preventDefault();
        if (this.selectedEquipmentItem.indexOf(item) === -1) {
            if ($event.ctrlKey) {
                this.selectedEquipmentItem.push(item);
            } else {
                this.selectedEquipmentItem = [];
                this.selectedEquipmentItem.push(item);
            }
        } else {
            if (this.selectedEquipmentItem.length > 1) {
                this.selectedEquipmentItem.splice(this.selectedEquipmentItem.indexOf(item), 1);
            }
        }
    }

    isSelectedEquipmentItem(item: any): boolean {
        return this.selectedEquipmentItem.indexOf(item) !== -1;
    }

    removeEquipment(): void {
        this.selectedEquipmentItem.map(item => {
            this.calResource.push(item);
            this.selectedCalResouce.splice(this.selectedCalResouce.indexOf(item), 1);
        });
        this.selectedEquipmentItem = [];
    }

    removeAll(): void {
        this.selectedCalResouce.map(item => {
            this.calResource.push(item);
        });
        this.selectedEquipmentItem = [];
        this.selectedCalResouce = [];
    }

    submit(): void {
        console.log("[Selected Equipment]", this.selectedCalResouce);
        this.dialogRef.close({ equipment: this.selectedCalResouce });
    }

    removeFromClose(item: any): void {
        this.calResource.push(item);
        this.selectedCalResouce.splice(this.selectedCalResouce.indexOf(item), 1);
        this.selectedEquipmentItem = [];
    }

    onResizeEnd(event: ResizeEvent, columnName: string): void {
        if (event.edges.right) {
            const cssValue = event.rectangle.width + "px";
            const columnElts = document.getElementsByClassName(columnName);
            for (let i = 0; i < columnElts.length; i++) {
                const currentEl = columnElts[i] as HTMLDivElement;
                currentEl.style.width = cssValue;
            }
        }
    }

    private _handleResponseFreeBusy(emails, result) {
        let itemsById = {};
        for (let email of emails) {
            let item: any = {};
            item = { txt: "STATUS_UNKNOWN", className: "unknown-status" };
            itemsById[email] = item;
        }

        let args = result.usr;
        for (let i = args.length; --i >= 0;) {
            let el = args[i];
            let id = el.id;
            if (!id) {
                continue;
            }
            let item = itemsById[id];
            if (!item) {
                continue;
            }
            let status = "STATUS_WORKING";
            item.status = 0;
            item.className = "free-status";
            if (el.b) {
                status = "STATUS_BUSY";
                item.status = 1;
                item.className = "busy-status";
            } else if (el.u) {
                status = "STATUS_OUT";
                item.status = 2;
                item.className = "outofoffice-status";
            } else if (el.t) {
                status = "STATUS_TENTATIVE";
                item.className = "tentative-status";
                item.status = 3;
            }
            item.txt = status;
            itemsById[id] = item;
        }
        this.itemsById = itemsById;
        console.log("[_handleResponseFreeBusy]", itemsById);
    }
}
