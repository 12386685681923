
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<section class="vp-refine-search-dialog advance-filter-search mail-dialog">
    <div #dialogContent class="advanced-search-body mail__dialog-body">
        <section class = "close-filter">
            <mat-icon (click) = "close()" class="disable-select">close</mat-icon>
        </section>
        <section class="section">
          <div class="section__item">
            <label class="section__item--label disable-select">{{'FILTER_SEARCHIN_LBL' | translate}}</label>
            <mat-form-field class="mat-sort-form-field">
              <mat-select class="section__item--input" [(ngModel)]="selectedApp">
                <mat-option value="mail">{{'MAIL'|translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="section__item" id="searchMdlTxtSection">
            <label class="section__item--label">{{'FILTER_SEARCH_CONTAINS' | translate}}</label>
            <mat-form-field>
              <input matInput autocomplete="off" #searchTextInput class="section__item--input keyword" [(ngModel)]="searchText" offset="90">
            </mat-form-field>
          </div>
          <div class="section__item" id="searchMdlTxtSection">
            <label class="section__item--label">{{'FROM_MAIL_LABEL' | translate}}</label>
            <mat-form-field>
              <input matInput #emailFromInput [formControl]="emailFromCtrl"  [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" class="vnc_mail_autocomplete_panel" [autoActiveFirstOption]=true>
                <mat-option *ngFor="let emailItem of filteredFromEmails" [value]="emailItem">
                  <span class="suggestion">
                    <vp-avatar [type]="'mail-detail-avatar'" [user]="{firstLastCharacters: emailItem.email?.charAt(0), color: '#317bbc', email: emailItem.email}"> </vp-avatar>
                    <div class="email_name_division">
                      <h3 class="disable-select">{{emailItem.name}}</h3>
                      <p class="disable-select">{{emailItem.email}}</p>
                    </div>
                  </span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="section__item" id="searchMdlTxtSection">
            <label class="section__item--label">{{'TO_MAIL_LABLE' | translate}}</label>
            <mat-form-field>
              <input matInput #emailToInput [formControl]="emailToCtrl"  [matAutocomplete]="autoTo">
              <mat-autocomplete #autoTo="matAutocomplete" (optionSelected)="selectedToEmail($event)" class="vnc_mail_autocomplete_panel" [autoActiveFirstOption]=true>
                <mat-option *ngFor="let emailItem of filteredToEmails" [value]="emailItem">
                  <span class="suggestion">
                    <vp-avatar [type]="'mail-detail-avatar'" [user]="{firstLastCharacters: emailItem.email?.charAt(0), color: '#317bbc', email: emailItem.email}"> </vp-avatar>
                    <div class="email_name_division">
                      <h3 class="disable-select">{{emailItem.name}}</h3>
                      <p class="disable-select">{{emailItem.email}}</p>
                    </div>
                  </span>
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="section__item" id="searchMdlTxtSection">
            <label class="section__item--label" [ngClass]="{'search-mendetory-field': searchName === ''}">{{'SAVE_SEARCH_NAME_LBL' | translate}} *</label>
            <mat-form-field>
              <input matInput autocomplete="off" class="section__item--input keyword" [(ngModel)]="searchName" offset="90">
            </mat-form-field>
          </div>
          <div class="section__item date_picker" id="searchMdlTxtSection">
            <label class="section__item--label">{{'FILTER_SEARCH_DATE' | translate}}</label>
            <mat-form-field>
              <input matInput [matDatepicker]="picker" [(ngModel)]="date" disabled>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker disabled="false"></mat-datepicker>
              <mat-icon *ngIf="date" class="clearDate" (click)="date = ''">close</mat-icon>
            </mat-form-field>
          </div>
          <div class="section__item date_picker">
            <label class="section__item--label">{{ 'BEFORE_LBL' | translate }}</label>
            <mat-form-field>
              <input matInput [matDatepicker]="Beforepicker" [(ngModel)]="beforeDate" disabled>
              <mat-datepicker-toggle matSuffix [for]="Beforepicker"></mat-datepicker-toggle>
              <mat-datepicker #Beforepicker disabled="false"></mat-datepicker>
              <mat-icon *ngIf="beforeDate" class="clearDate" (click)="beforeDate = ''">close</mat-icon>
            </mat-form-field>
          </div>
      
          <div class="section__item date_picker">
            <label class="section__item--label">{{ 'AFTER_LBL' | translate }}</label>
            <mat-form-field>
              <input matInput [matDatepicker]="Afterpicker" [(ngModel)]="afterDate" disabled>
              <mat-datepicker-toggle matSuffix [for]="Afterpicker"></mat-datepicker-toggle>
              <mat-datepicker #Afterpicker disabled="false"></mat-datepicker>
              <mat-icon *ngIf="afterDate" class="clearDate" (click)="afterDate = ''">close</mat-icon>
            </mat-form-field>
          </div>
          <mat-checkbox [(ngModel)] = "hasAttachment">{{'FILTER_SEARCH_ATTACHMENT' | translate}}</mat-checkbox>
        </section>
        <section class="section action-buttons" layout="row center-right">
          <div class="filter-actions">
            <button (click)="search();" class="mat-button search disable-select">
              {{'FILTER_SEARCH_LBL' | translate}}
            </button>
            <button (click)="saveSearch();" class="mat-button save-search disable-select" [disabled] = "!searchName">
              {{'FILTER_SAVE_SEARCH_LBL' | translate}}
            </button>
          </div>
        </section>
      </div>
</section>
