
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="send_read_receipt_dialog">
    <div class="content">
        <div class="msg">{{ 'READ_RECEIPT_MSG_1' | translate }}</div>
        <div class="msg">{{ 'READ_RECEIPT_MSG_2' | translate }} </div>
    </div>
    <div class="btn">
        <button mat-button [disableRipple]=true (click)="close(true)">
            <div>{{ 'YES_LABEL' | translate }}</div>
        </button>
        <button mat-button [disableRipple]=true (click)="close(false)">
            <div>{{ 'NO_LABEL' | translate }}</div>
        </button>
    </div>
</div>