
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ActionReducerMap, createSelector } from "@ngrx/store";
import {
    _getIsLoggedInUserLoading ,
    _getSidebarExpanderStatus ,
    appReducer ,
    AppState ,
    _getUserProfile , _IsDeviceReady,
    _getOnlineStatus,
    _getIsLoggedIn,
    _getProcessingState,
    _getContactProfiles,
    _getSession,
    _getPollingInterval,
    _getSwipeAction,
    _getFederatedApps,
    _getViewBy,
    _getExpandConversation,
    _getReadingPane,
    _getAvailableZimlets,
    _getCurrentFolder,
    _getCurrentQuery,
    _getFirebaseToken,
    _getProps,
    _getzimbraFeatures,
    _getLastPhotoUpdate,
    _getLastPhotoUpdateByEmail,
    _getdomainSpecificLogo,
    _getIncludeSharedItems,
    _getSearchFor,
    _getWaitDisallowed,
    _getExpandMailFolders,
    _getIncomingFilters,
    _getUserContacts,
    _getUserGalContacts,
    _getUserSignatures,
    _getExpandContactFolders,
    _getExpandBriefcaseFolders,
    _getFolderTreeSash,
    _getPaneSashVertical,
    _getPaneSashHorizontal,
    _getIsDatabaseReady,
    _getLoadInsecureContent,
    _getShowGlobalTags,
    _getLastDraftId,
    _getIsRightSidebarExpanded,
    _getActiveProfile,
    _getSavedRecentSearch
} from "./app";
import { AppActionTypes } from "../actions/app";
import {_getSearchKeyword, searchReducer, SearchState, searchAdapter, _getSearchFilters} from "./search";
import {_getSearchFolderKeyword, searchFolderReducer, SearchFolderState, searchFolderAdapter} from "./search-folder.reducer";
import * as fromMailTag from "../reducers/mail-tag.reducer";
import * as fromAllContact from "../reducers/all-contacts.reducers";
import * as fromAllGalContact from "../reducers/all-gal-contacts.reducers";
import * as fromCalendarState from "../reducers/calendar.reducer";
import { Dictionary } from "@ngrx/entity";
import { CalendarEvent } from "calendar-utils";
import { CalendarFolder } from "../common/models/calendar.model";
import * as _ from "lodash";
import * as fromDirectoryTag from "./directory-tag.reducers";
import { directoryTagAdapter } from "./directory-tag.reducers";
import { VNCContactState, vncContactReducer, vncContactAdapter, _getVNCDContactList } from "./contacts.reducers";

export interface RootState {
  app: AppState;
  search: SearchState;
  vncContact: VNCContactState;
  searchFolder: SearchFolderState;
  mailTag: fromMailTag.MailTagState;
  allContacts: fromAllContact.AllContactState;
  allGalContacts: fromAllGalContact.AllGalContactState;
  calendar: fromCalendarState.CalendarState;
  calendarEvent: fromCalendarState.CalendarEventState;
  calendarFolder: fromCalendarState.CalendarFolderState;
  // briefcase: fromBriefcase.BriefCaseState;
  directoryTag: fromDirectoryTag.DirectoryTagState;
}

export const reducers: ActionReducerMap<RootState> = {
  app: appReducer,
  search: searchReducer,
  vncContact: vncContactReducer,
  searchFolder: searchFolderReducer,
  mailTag: fromMailTag.MailTagReducer,
  allContacts: fromAllContact.contactReducer,
  allGalContacts: fromAllGalContact.contactReducer,
  calendar: fromCalendarState.referenceReducer,
  calendarEvent: fromCalendarState.calendarEventReducer,
  calendarFolder: fromCalendarState.calendarFolderReducer,
  // briefcase: fromBriefcase.referenceReducer
  directoryTag: fromDirectoryTag.DirectoryTagReducer
};

export function reset(reducer) {
  return function (state, action) {

    if (action.type === AppActionTypes.LOGOUT) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export const getSearchState = state => state.search;
export const getSearchFolderState = state => state.searchFolder;
export const getMailTagState = state => state.mailTag;
export const getAllContactsState =  state => state.allContacts;
export const getAllGalContactsState =  state => state.allGalContacts;
export const getCalendarState = state => state.calendar;
export const getCalendarEventState = state => state.calendarEvent;
export const getCalendarFolderState = state => state.calendarFolder;
export const getDirectoryTagState = state => state.directoryTag;
export const getVNCContactState = state => state.vncContact;

export const {
  selectIds: getSearchIds,
  selectEntities: getSearchEntities,
  selectAll: getAllSearch,
  selectTotal: getTotalSearch,
} = searchFolderAdapter.getSelectors(getSearchState);
export const getSearch = getAllSearch;

export const {
  selectIds: getSearchFolderIds,
  selectEntities: getSearchFolderEntities,
  selectAll: getAllSearchFolder,
  selectTotal: getTotalSearchFolder,
} = searchFolderAdapter.getSelectors(getSearchFolderState);
export const getSearchFolders = getAllSearchFolder;

export const {
    selectIds: getContactIds,
    selectEntities: getContactEntities,
    selectAll: getAllContact,
    selectTotal: getTotalContact,
} = fromAllContact.allContactAdapter.getSelectors(getAllContactsState);
export const getAllContacts = getAllContact;

export const {
  selectIds: getGalContactIds,
  selectEntities: getGalContactEntities,
  selectAll: getAllGalContact,
  selectTotal: getTotalGalContact,
} = fromAllGalContact.allGalContactAdapter.getSelectors(getAllGalContactsState);
export const getAllGalContacts = getAllGalContact;

// export const getCalendarState = createSelector(
//   getCalendarRootState,
//   state => state.calendar
// );

// export const getCalendarFolderState = createSelector(
//   getCalendarRootState,
//   state => state.calendarFolder
// );

// export const getCalendarEventState = createSelector(
//   getCalendarRootState,
//   state => state.calendarEvent
// );

export const {
  selectIds: getCalendarIds,
  selectEntities: getCalendarEntities,
  selectAll: getAllCalendars,
  selectTotal: getTotalCalendars,
} = fromCalendarState.calendarAdapter.getSelectors(getCalendarState);

export const {
  selectIds: getCalendarEventIds,
  selectEntities: getCalendarEventEntities,
  selectAll: getAllEvents,
  selectTotal: getTotalEvents
} = fromCalendarState.calendarEventAdapter.getSelectors(getCalendarEventState);

export const getCalendarEventsState = createSelector(
  getCalendarEventState,
  state => state.calendarEvent.event
);

export const getDeletedCalendarEvents = createSelector(
  getCalendarEventState,
  state => state.deletedAppointments
);

export const getRemovedCalendarEventState = createSelector(
  getCalendarState,
  state => state.removedEvent
);

export const getUpdateCalendarEventState = createSelector(
  getCalendarState,
  state => state.updatedEvent
);

export const getCalendarAppointmentsByIds = createSelector(
  getCalendarEventEntities, (entities: Dictionary<CalendarEvent>, calendarEventIds: string[]) => {
    return calendarEventIds.map(calendarEventId => {
      return entities[calendarEventId];
    });
  }
);

export const {
  selectIds: getCalendarFolderIds,
  selectEntities: getCalendarFolderEntities,
  selectAll: getAllCalendarFolders,
  selectTotal: getTotalFolders
} = fromCalendarState.calendarFolderAdapter.getSelectors(getCalendarFolderState);

export const getCalendarFoldersState = createSelector(
  getCalendarFolderState,
  state => state.calendarFolders
);

export const getCalendarFolders = getAllCalendarFolders;
export const getAllCalendarAppointments = getAllEvents;

export const getCalendarFolderById = createSelector(
  getCalendarFolderEntities,
  (entities: Dictionary<CalendarFolder>, folderId: string) => {
    return entities[folderId];
  }
);

export const getSharedCalendarFolderById = createSelector(
  getAllCalendarFolders,
  (folders: CalendarFolder[], sharedId: string) => {
    const childFolders = getChildFolders(folders);
    const zid = sharedId.split(":")[0];
    const rid = sharedId.split(":")[1];
    const allFolders = [...folders.filter(f => f.owner), ...childFolders];
    return _.find(allFolders, {zid: zid, rid: rid});
  }
);

function getChildFolders(folders: CalendarFolder[]): CalendarFolder[] {
  let allFolders: CalendarFolder[] = [];
  let childFolders: CalendarFolder[] = [];
  folders.filter(f => f.folder && f.folder.length > 0).forEach(f => {
    allFolders = getChildFolders(f.folder);
    childFolders = [...childFolders, ...f.folder];
  });
  return [...allFolders, ...childFolders];
}

/* Directory tag Reducers */

export const {
  selectIds: getDirectoryTagIds,
  selectEntities: getDirectoryTagEntities,
  selectAll: getAllDirectoryTag,
  selectTotal: getTotalDirectoryTag,
} = directoryTagAdapter.getSelectors(getDirectoryTagState);
export const getAllDirectoryTags = getAllDirectoryTag;


// VNCd Contacts
export const {
  selectIds: getVNCContactsIds,
  selectEntities: getVNCContactEntities,
  selectAll: getAllVNCContacts,
  selectTotal: getTotalVNCContacts,
} = vncContactAdapter.getSelectors(getVNCContactState);
export const getVNCContacts = getAllVNCContacts;

export const getContactById = (state: RootState, id: string) => {
  return getVNCContactEntities(state)[id];
};
export const getVNCDContactList = createSelector(getVNCContactState, _getVNCDContactList);

/**
 *  App Related Reducers
 */
export const getAppState =  state => state.app;
export const IsDatabaseReady = createSelector(getAppState, _getIsDatabaseReady);

export const getSearchKeyword = createSelector(getSearchState, _getSearchKeyword);
export const getSearchFilters = createSelector(getSearchState, _getSearchFilters);
export const getSearchFolderKeyword = createSelector(getSearchFolderState, _getSearchFolderKeyword);
export const getIsLoggedInUserLoading = createSelector(getAppState, _getIsLoggedInUserLoading);
export const getSidebarExpanderStatus = createSelector(getAppState, _getSidebarExpanderStatus);
export const getUserProfile = createSelector(getAppState, _getUserProfile);
export const getIsDeviceReady = createSelector(getAppState, _IsDeviceReady);
export const getOnlineStatus = createSelector(getAppState, _getOnlineStatus);
export const getIsLoggedIn = createSelector(getAppState, _getIsLoggedIn);
export const getActionProcessingState = createSelector(getAppState, _getProcessingState);
export const getContactProfiles = createSelector(getAppState, _getContactProfiles);
export const getSession = createSelector(getAppState, _getSession);
export const getPollingInterval = createSelector(getAppState, _getPollingInterval);
export const getSwipeAction = createSelector(getAppState, _getSwipeAction);
export const getFederatedApps = createSelector(getAppState, _getFederatedApps);
export const getViewBy = createSelector(getAppState, _getViewBy);
export const getExpandConversation = createSelector(getAppState, _getExpandConversation);
export const getReadingPane = createSelector(getAppState, _getReadingPane);
export const getAvailableZimlets = createSelector(getAppState, _getAvailableZimlets);
export const getCurrentFolder = createSelector(getAppState, _getCurrentFolder);
export const getCurrentQuery = createSelector(getAppState, _getCurrentQuery);
export const getFirebaseToken = createSelector(getAppState, _getFirebaseToken);
export const getProps = createSelector(getAppState, _getProps);
export const getZimbraFeatures = createSelector(getAppState, _getzimbraFeatures);
export const getLastPhotoUpdate = createSelector(getAppState, _getLastPhotoUpdate);
export const getIncludeSharedItems = createSelector(getAppState, _getIncludeSharedItems);
export const getSearchFor = createSelector(getAppState, _getSearchFor);
export const getLastPhotoUpdateByEmail = (state: AppState, email: string) => {
  return getLastPhotoUpdate(state)[email];
};
export const getDomainSpecificLogo = createSelector(getAppState, _getdomainSpecificLogo);
export const getWaitDisallowed = createSelector(getAppState, _getWaitDisallowed);
export const getExpandMailFolders = createSelector(getAppState, _getExpandMailFolders);
export const getIncomingFilters = createSelector(getAppState, _getIncomingFilters);
export const getAllUserContacts = createSelector(getAppState, _getUserContacts);
export const getAllUserGalContacts = createSelector(getAppState, _getUserGalContacts);
export const getAllUserSignatures = createSelector(getAppState, _getUserSignatures);
export const getExpandContactFolders = createSelector(getAppState, _getExpandContactFolders);
export const getExpandBriefcaseFolders = createSelector(getAppState, _getExpandBriefcaseFolders);
export const getFolderTreeSash = createSelector(getAppState, _getFolderTreeSash);
export const getPaneSashVertical = createSelector(getAppState, _getPaneSashVertical);
export const getPaneSashHorizontal = createSelector(getAppState, _getPaneSashHorizontal);
export const getLoadInsercureContent = createSelector(getAppState, _getLoadInsecureContent);
export const getShowGlobalTags = createSelector(getAppState, _getShowGlobalTags);
export const getLastDraftId = createSelector(getAppState, _getLastDraftId);
export const getIsRightSidebarExpanded = createSelector(getAppState, _getIsRightSidebarExpanded);
export const getActiveProfile = createSelector(getAppState, _getActiveProfile);
export const getSavedRecentSearch = createSelector(getAppState, _getSavedRecentSearch);
