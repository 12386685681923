
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy, Inject, ChangeDetectionStrategy, ChangeDetectorRef, NgZone } from "@angular/core";
import { MailBroadcaster } from "../../../common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "vp-mobile-briefcase-dialog",
  templateUrl: "./mobile-briefcase.component.html",
  styleUrls: ["./mobile-briefcase.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileBriefcaseComponent implements OnInit, OnDestroy {

  destroyed$ = new Subject<boolean>();

  constructor(
    private dialogRef: MatDialogRef<MobileBriefcaseComponent>,
    private mailBroadcaster: MailBroadcaster,
    private ngZone: NgZone
  ) {
    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_FOLDER_MODAL).pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.ngZone.run(() => {
        this.closeDialog();
      });
    });
  }

  ngOnInit() { }


  /**
   * @description: close dialog on header back button click
   */
  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
