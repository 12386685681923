
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div>
    <mat-list>
        <span *ngIf="signatures.length > 0">
            <mat-list-item *ngFor="let signature of signatures" (click)="selectSignature(signature)">
                <div class="signature_name">{{signature.name}}</div>
            </mat-list-item>
        </span>
        <mat-list-item>
            <div class="signature_name" (click)="selectSignature('')">{{ 'NO_SIGNATURE' | translate }}</div>
        </mat-list-item>
    </mat-list>
</div>