
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-move-folder-dialog vp-sub-folder-dialog move__dialog">
    <div class="move__dialog-header mobile-header" [fxShow.xs]="true" [fxShow.gt-md]="false" id="mobile-move-header">
        {{ 'MOVETO_FOLDERS' | translate }}
        <span></span>
        <button class="brand-color mat-button" (click)="close(null)">
          <mat-icon class="disable-select">close</mat-icon>
        </button>
    </div>
    <!-- <div class="move__dialog-header desktop-header" layout="row center-center" [fxShow.xs]="false" [fxShow.gt-md]="true">
        <h4>{{ 'MOVETO_FOLDERS' | translate}}</h4>
        <div class="desktop-close-button">
            <button class="brand-color mat-button" (click)="close(null)">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div> -->
    <vnc-header
    [fxShow.xs]="false" [fxShow.gt-md]="true"
    title="{{ 'MOVETO_FOLDERS' | translate }}"
    type="title-close"
    size="l"
    (onCloseClick)="close(null)"
    [showCloseIcon]="true"
    closeIconToolTip="{{ 'CLOSE' | translate }}">
    </vnc-header>

    <mat-divider  [fxShow.xs]="true" [fxShow.gt-md]="false"></mat-divider>
    <div class="move__dialog-body">
        <div class="sidebar-list" *ngIf="selectedMailFolder && selectedMailFolder.children">
            <!-- <div fxLayout="row" fxLayoutAlign="start center" class="move-search-create-bar">
                <div fxLayout="row" fxLayoutAlign="start center" class="folder-search-bar">
                    <input matInput #folderNameSearchInput [(ngModel)]="searchText" type="text" placeholder="{{'PREFERENCES.SEARCH_FOLDERS' | translate}}" />
                    <button mat-icon-button>
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
            </div> -->
            <div class="search-form">
                <vnc-input leadingIconName="mdi-magnify" [form]="searchControl" [showClear]="true"
                  placeholder="{{'PREFERENCES.SEARCH_FOLDERS'|translate}}" size="l" type="text" clearTooltip="{{ 'CLEAR' | translate}}">
                </vnc-input>
              </div>
            <!-- <mat-divider></mat-divider> -->
            <mat-list>
                <!-- <mat-list-item (click)="getParentFolder()">
                    <div class="folder-icon">
                        <mat-icon>subdirectory_arrow_right</mat-icon>
                    </div>
                    <div class="folder-title disable-select">
                        <span>{{'BACK_TO_ROOT' | translate}}</span>
                    </div>
                </mat-list-item> -->
                <div class="back-btn-wrapper" (click)="getParentFolder()">
                    <vnc-icon-container (click)="getParentFolder()">
                        
                        <svg width="18" height="18" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g id="Icon/product/arrow-back" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Icon-24px" transform="translate(4.000000, 4.000000)">
                                    <polygon id="Shape" points="16 7 3.83 7 9.42 1.41 8 0 0 8 8 16 9.41 14.59 3.83 9 16 9"></polygon>
                                </g>
                            </g>
                        </svg>
                    </vnc-icon-container>
                    <span>{{ 'BACK_TOROOT' | translate}}</span>
                </div>
                <cdk-virtual-scroll-viewport class="sub-folders-list-move-folder" itemSize="30">
                    <mat-list>
                        <ng-container *cdkVirtualFor="let folder of selectedMailFolder.children | vpFolderNameSearch : searchControl.value; let i = index">
                            <mat-list-item
                                [class.disable]="(sourceFolderId && folder.id === sourceFolderId) || (folderToMove && folder.id === folderToMove.id)"
                                (click)="selectFolder(folder)" [class.selected-class]="currentSelected && folder.id === currentSelected.id">
                                <div class="folder-icon" id="move-folder-list-icon">
                                    <!-- <mat-icon [style.color]="folder.rgb">folder</mat-icon> -->
                                    <svg width="18" height="18" viewBox="57 58 23 23" xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <filter x="-14.4%" y="-13%" width="128.8%" height="126.1%" filterUnits="objectBoundingBox" id="9w5lr2xrta">
                                                <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1"/>
                                                <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                                                <feColorMatrix values="0 0 0 0 0.031372549 0 0 0 0 0.176470588 0 0 0 0 0.239215686 0 0 0 0.2 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
                                                <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter2"/>
                                                <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2"/>
                                                <feColorMatrix values="0 0 0 0 0.0311856736 0 0 0 0 0.177658465 0 0 0 0 0.240432518 0 0 0 0.1 0" in="shadowBlurOuter2" result="shadowMatrixOuter2"/>
                                                <feMerge>
                                                    <feMergeNode in="shadowMatrixOuter1"/>
                                                    <feMergeNode in="shadowMatrixOuter2"/>
                                                    <feMergeNode in="SourceGraphic"/>
                                                </feMerge>
                                            </filter>
                                        </defs>
                                        <g transform="translate(32 -183)" filter="url(#9w5lr2xrta)" [style.fill]="folder.rgb" fill-rule="evenodd">
                                            <path d="M34.01 244c.49 0 1.17.263 1.585.606l.116.105.578.578c.362.362 1.022.653 1.553.703l.155.008H45c1.05 0 1.918.82 1.994 1.851L47 248v10c0 1.05-.82 1.918-1.851 1.994L45 260H29c-1.05 0-1.918-.82-1.994-1.851L27 258l.01-12c0-1.05.81-1.918 1.841-1.994L29 244h5.01zm0 2-5 .002L29 258h16v-10h-7.003c-1.012 0-2.183-.458-2.961-1.146l-.161-.15-.578-.579-.027-.015-.185-.081-.076-.029z"/>
                                        </g>
                                    </svg>
                                </div>
                                <div class="folder-details disable-select">
                                    <div class="folder-title disable-select" id="folder-move-folder-name">
                                        <span>{{ folder.name }}</span>
                                    </div>
                                    <div *ngIf="searchControl.value.length > 0" class="folder-path disable-select"
                                        id="folder-move-folder-abspath">
                                        <span matTooltip="{{ folder.absFolderPath }}" [matTooltipPosition]="'before'">{{ folder.absFolderPath }}</span>
                                    </div>
                                </div>
                                <div class="folder-expander" *ngIf="folder.children"
                                    (click)="$event.preventDefault(); $event.stopPropagation();getChildrens(folder)"
                                    id="move-folder-right-arrow">
                                    <mat-icon>keyboard_arrow_right</mat-icon>
                                </div>
                            </mat-list-item>
                        </ng-container>
                    </mat-list>
                </cdk-virtual-scroll-viewport>
            </mat-list>
        </div>
        <div class="sidebar-list" *ngIf="!selectedMailFolder && rootUserFolders">
            <!-- <div fxLayout="row" fxLayoutAlign="start center" class="move-search-create-bar">
                <div fxLayout="row" *ngIf="!isCreateFolder" fxLayoutAlign="start center" class="folder-search-bar">
                    <input matInput #folderNameSearchInput [(ngModel)]="searchText" type="text" id="folder-search-input" placeholder="{{'PREFERENCES.SEARCH_FOLDERS' | translate}}" />
                    <button mat-icon-button id="folder-search-btn">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
            </div> -->
            <div class="search-form">
                <vnc-input leadingIconName="mdi-magnify" [form]="searchControl" [showClear]="true"
                  placeholder="{{'PREFERENCES.SEARCH_FOLDERS'|translate}}" size="l" type="text" clearTooltip="{{ 'CLEAR' | translate}}">
                </vnc-input>
              </div>
            <!-- <mat-divider></mat-divider> -->
            <mat-list class="move-dialog-mat-list">
                <cdk-virtual-scroll-viewport class="user-folders-list-move-folder" itemSize="30">
                    <mat-list>
                        <ng-container *cdkVirtualFor="let folder of rootUserFolders | vpFolderNameSearch : searchControl.value; let i = index">
                            <mat-list-item [class.disable]="(sourceFolderId && folder.id === sourceFolderId) || (folderToMove && folder.id === folderToMove.id)"
                            (click)="selectFolder(folder)" [class.selected-class]="currentSelected && folder.id === currentSelected.id" id="move-folder-list-item">
                            <div class="folder-icon" id="move-folder-list-icon">
                                <!-- <mat-icon *ngIf="!folder.icon" [style.color]="folder.rgb">folder</mat-icon>
                                <mat-icon *ngIf="folder.icon">{{ folder.icon }}</mat-icon> -->
                                <svg width="18" height="18" viewBox="57 58 23 23" xmlns="http://www.w3.org/2000/svg">
                                    <defs>
                                        <filter x="-14.4%" y="-13%" width="128.8%" height="126.1%" filterUnits="objectBoundingBox" id="9w5lr2xrta">
                                            <feOffset dy="8" in="SourceAlpha" result="shadowOffsetOuter1"/>
                                            <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                                            <feColorMatrix values="0 0 0 0 0.031372549 0 0 0 0 0.176470588 0 0 0 0 0.239215686 0 0 0 0.2 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
                                            <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter2"/>
                                            <feGaussianBlur stdDeviation="6" in="shadowOffsetOuter2" result="shadowBlurOuter2"/>
                                            <feColorMatrix values="0 0 0 0 0.0311856736 0 0 0 0 0.177658465 0 0 0 0 0.240432518 0 0 0 0.1 0" in="shadowBlurOuter2" result="shadowMatrixOuter2"/>
                                            <feMerge>
                                                <feMergeNode in="shadowMatrixOuter1"/>
                                                <feMergeNode in="shadowMatrixOuter2"/>
                                                <feMergeNode in="SourceGraphic"/>
                                            </feMerge>
                                        </filter>
                                    </defs>
                                    <g transform="translate(32 -183)" filter="url(#9w5lr2xrta)" [style.fill]="folder.rgb" fill-rule="evenodd">
                                        <path d="M34.01 244c.49 0 1.17.263 1.585.606l.116.105.578.578c.362.362 1.022.653 1.553.703l.155.008H45c1.05 0 1.918.82 1.994 1.851L47 248v10c0 1.05-.82 1.918-1.851 1.994L45 260H29c-1.05 0-1.918-.82-1.994-1.851L27 258l.01-12c0-1.05.81-1.918 1.841-1.994L29 244h5.01zm0 2-5 .002L29 258h16v-10h-7.003c-1.012 0-2.183-.458-2.961-1.146l-.161-.15-.578-.579-.027-.015-.185-.081-.076-.029z"/>
                                    </g>
                                </svg>
                            </div>
                            <div class="folder-details disable-select">
                                <div class="folder-title disable-select" id="folder-move-folder-name">
                                    <span>{{ folder.name }}</span>
                                </div>
                                <div *ngIf="searchControl.value.length > 0" class="folder-path disable-select" id="folder-move-folder-abspath">
                                    <span matTooltip="{{ folder.absFolderPath }}" [matTooltipPosition]="'before'">{{ folder.absFolderPath }}</span>
                                </div>
                            </div>
                            <div class="folder-expander" *ngIf="folder.children" (click)="$event.preventDefault(); $event.stopPropagation();getChildrens(folder)" id="move-folder-right-arrow">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </div>
                        </mat-list-item>
                        </ng-container>
                    </mat-list>
                </cdk-virtual-scroll-viewport>

            </mat-list>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="move__dialog-footer" id="move-cancel-division">
        <div class="div-left" (click)="createFolder()">
            <vnc-icon-container (click)="createFolder()">
                <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <g id="Icon/product/add" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Icon-24px" transform="translate(5.000000, 5.000000)" fill="#8B96A0">
                            <polygon id="Shape" points="14 8 8 8 8 14 6 14 6 8 0 8 0 6 6 6 6 0 8 0 8 6 14 6"></polygon>
                        </g>
                    </g>
                </svg>
            </vnc-icon-container>
            <a>{{'ADD_NEW_FOLDER' | translate }}</a>
        </div>
        <div class="div-right">
            <!-- <a (click)="close(null)" [fxShow.xs]="false">{{'CANCEL' | translate }}</a>
            <a (click)="moveFolder(currentSelected)" [class.disable]="!currentSelected">{{'MOVE' | translate }}</a> -->
            <vnc-button class="no-hover" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" [type]="'secondary'" label="{{'CANCEL' | translate }}" [fxShow.xs]="false" (click)="close(null)"></vnc-button>
            <vnc-button [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" type="{{!currentSelected ? 'disabled' : 'primary'}}" (click)="moveFolder(currentSelected)">
                <span>{{'MOVE' | translate }}</span>
            </vnc-button>
        </div>
    </div>
</div>
