
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { CalendarEvent } from "calendar-utils";
/**
 * This class is responsible for displaying all event titles within the calendar.
 * You may override any of its methods via angulars DI to suit your requirements. For example:
 *
 * ```typescript
 * import { CalendarAppointmentTitleFormatter, CalendarEvent } from "angular-calendar";
 *
 * class CustomEventTitleFormatter extends CalendarAppointmentTitleFormatter {
 *
 *   month(event: CalendarEvent): string {
 *     return `Custom prefix: ${event.title}`;
 *   }
 *
 * }
 *
 * // in your component
 * providers: [{
 *  provide: CalendarAppointmentTitleFormatter,
 *  useClass: CustomEventTitleFormatter
 * }]
 * ```
 */
export class CalendarAppointmentTitleFormatter {
  /**
   * The month view event title.
   */
  month(event: CalendarEvent, title: string): string {
    return event.title;
  }

  /**
   * The month view event tooltip. Return a falsey value from this to disable the tooltip.
   */
  monthTooltip(event: CalendarEvent, title: string): string {
    return; // event.title;
  }

  /**
   * The week view event title.
   */
  week(event: CalendarEvent, title: string): string {
    return event.title;
  }

  /**
   * The week view event tooltip. Return a falsey value from this to disable the tooltip.
   */
  weekTooltip(event: CalendarEvent, title: string): string {
    return; // event.title;
  }

  /**
   * The day view event title.
   */
  day(event: CalendarEvent, title: string): string {
    return event.title;
  }

  /**
   * The day view event tooltip. Return a falsey value from this to disable the tooltip.
   */
  dayTooltip(event: CalendarEvent, title: string): string {
    return; // event.title;
  }
}
