
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, OnDestroy, Component, NgZone, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MailBroadcaster } from "../../../../common/providers/mail-broadcaster.service";
import { Message } from "../../models";
import { MailService } from "../../services/mail-service";
import { take, filter, takeUntil } from "rxjs/operators";
import { ToastService } from "../../../../common/providers/toast.service";
import { BroadcastKeys } from "../../../../common/enums/broadcast.enum";
import { MailRootState } from "../../../store";
import { Store } from "@ngrx/store";
import { StartProcessing, StopProcessing } from "../../../../actions/app";
import { getUserProfile } from "../../../../reducers";
import { Subject } from "rxjs";
import { MailUtils } from "../../../utils/mail-utils";
import { ElectronService } from "../../../../services/electron.service";
import { TranslateService } from "@ngx-translate/core";
import { MailConstants } from "src/app/common/utils/mail-constants";
import localeDE from "@angular/common/locales/de";
import localEN from "@angular/common/locales/en";
import { registerLocaleData, DatePipe } from "@angular/common";

@Component({
    selector: "vp-mail-history-dialog",
    templateUrl: "./mail-history-dialog.component.html"
})
export class MailHistoryDialogComponent implements OnInit, OnDestroy {
    msgId: string;
    message: Message;
    historyList: any[] = [];
    private isAlive$ = new Subject<boolean>();
    userEmail: string;
    fromEmail: string;
    historyViewType: string = "";
    translation: any;
    currentLocale: string = "en";
    constructor(
        public dialogRef: MatDialogRef<MailHistoryDialogComponent>,
        private mailBroadcaster: MailBroadcaster,
        private ngZone: NgZone,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private mailService: MailService,
        private toastService: ToastService,
        private store: Store<MailRootState>,
        private electronService: ElectronService,
        private translateService: TranslateService,
        private datePipe: DatePipe
    ) {
        this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_MAIL_HISTORY_DIALOG).pipe(take(1)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
        this.store.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(res => {
            if (res.email) {
                this.userEmail = MailUtils.checkEmailArray(res.email);
            } else {
                const profileUser = MailUtils.getProfileFromStorage();
                this.userEmail = MailUtils.checkEmailArray(profileUser.email);
            }
        });
        this.message = data.message;
        this.fromEmail = this.getEmailFrom(this.message);
        this.msgId = this.message.mid.replace("<", "").replace(">", "");
        this.historyViewType = "";
        this.translateService.get([
            "EMAIL_HISTORY_LBL",
            "EMAIL_HISTORY_FOR",
            "EMAIL_HISTORY_SENDER",
            "EMAIL_HISTORY_DATE",
            "EMAIL_HISTORY_RECEIVER",
            "EMAIL_HISTORY_FORWARDED_TO",
            "EMAIL_HISTORY_EVENT",
            "EMAIL_HISTORY_SENT",
            "EMAIL_HISTORY_READ",
            "EMAIL_HISTORY_DELETED",
            "EMAIL_HISTORY_FORWARD",
            "EMAIL_HISTORY_DELIVERY_FAIL"
        ]).pipe(take(1)).subscribe(res => {
            this.translation = res;
        });
    }

    ngOnInit() {
        this.store.dispatch(new StartProcessing());
        this.mailService.getMailHistory(this.msgId).pipe(take(1)).subscribe(res => {
            this.store.dispatch(new StopProcessing());
            this.historyList = res.list;
            this.historyList.map(item => {
                item.displayName = this.getDisplayNameById(item.to);
            });
            this.getHistoryViewType();
        }, error => {
            this.store.dispatch(new StopProcessing());
            this.toastService.showPlainMessage(error);
            this.historyViewType = "NO_RESULT_FOUND";
        });
        let language: string = "en";
        const localLang = this.electronService.isElectron
                ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE)
                : localStorage.getItem(MailConstants.MAIL_LANGUAGE);
        if (localLang !== null && localLang !== undefined && localLang !== "undefined") {
            language = localLang;
        }
        this.currentLocale = language.match(/en|de/) ? language : "en";
        if (this.currentLocale !== "en") {
            registerLocaleData(localeDE);
        } else {
            registerLocaleData(localEN);
        }
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.unsubscribe();
    }

    close(): void {
        this.dialogRef.close();
    }

    getEmailFrom(msg: Message): string {
        return msg.e.filter(e => e.t === "f")[0].a;
    }

    printHistory(): void {
        let historyData = "<head><style>" +
            "table {width: 100%;border: 1px solid #e0e0e0;border-spacing: 0px;text-align: left;}" +
            "table th {font-weight: normal; font-size: 15px;padding-left: 10px;background: #f1f1f1;height: 42px;}" +
            "table tr {font-size: 13px; background: #fafafa; height: 33px;}" +
            "table tr td { padding-left: 10px; }" +
            ".no_data_found {text-align: center;background: #f0f0f0;vertical-align: middle;line-height: 7;}" +
            "</style></head>" +
            "<div>" + this.translation.EMAIL_HISTORY_FOR + ": " +
            "<b>\“" + this.message.su + "\” </b></div>" +
            "<br>" +
            "<div>" + this.translation.EMAIL_HISTORY_SENDER + ": " + this.getEmailFrom(this.message) + "</div>" +
            "<br>";

        if (this.historyList.length > 0) {
            historyData += "<table>" +
                "<tr>" +
                "<th>" + this.translation.EMAIL_HISTORY_DATE + "</th>" +
                "<th>" + this.translation.EMAIL_HISTORY_RECEIVER + "</th>" +
                "<th>" + this.translation.EMAIL_HISTORY_FORWARDED_TO + "</th>" +
                "<th>" + this.translation.EMAIL_HISTORY_EVENT + "</th>" +
                "</tr>";
            this.historyList.map(item => {
                const dateTime = this.getFormatDate(item.logtime);
                historyData += "<tr>" +
                    "<td>" + dateTime + "</td>" +
                    "<td>" + item.displayName + "<br>" + item.to + "</td>" +
                    "<td>" + item.forward + "</td>" +
                    "<td>" + this.getEventType(item.event) +
                    "</td>" +
                    "</tr>";
            });
            historyData += "</table>";
        }
        if (typeof cordova !== "undefined") {
            cordova.plugins.printer.print(historyData, { duplex: "long" }, function (response) {
            });
        } else {
            const win = window.open(
                "",
                "",
                "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500,height=400"
            );
            win.document.title = this.translation.EMAIL_HISTORY_LBL;
            win.document.body.innerHTML = historyData;
            win.print();
        }
    }

    private getEventType(event): string {
        let type = "Sent";
        switch (event) {
            case "1":
                type = this.translation.EMAIL_HISTORY_SENT;
                break;
            case "2":
                type = this.translation.EMAIL_HISTORY_READ;
                break;
            case "3":
                type = this.translation.EMAIL_HISTORY_DELETED;
                break;
            case "4":
                type = this.translation.EMAIL_HISTORY_FORWARD;
                break;
            case "5":
                type = this.translation.EMAIL_HISTORY_DELIVERY_FAIL;
                break;
        }
        return type;
    }

    private getHistoryViewType() {
        if (this.fromEmail !== this.userEmail) {
            this.historyViewType = "NOT_AUTHORIZE";
        } else if (this.historyList.length === 0) {
            this.historyViewType = "NO_RESULT_FOUND";
        } else {
            this.historyViewType = "SHOW_HISTORY";
        }
    }

    private getDisplayNameById(emailId?: string): string {
        let matchItem = this.message.e.filter(e => e.a === emailId);
        if (matchItem.length > 0) {
            return matchItem[0].p ? matchItem[0].p : matchItem[0].d;
        } else {
            return "";
        }
    }

    private getFormatDate(date: string): string {
        let dateTime: string = "";
        if (this.currentLocale !== "en") {
            dateTime = this.datePipe.transform(date, "dd.MM.yyyy,", "", this.currentLocale) + " " +
            this.datePipe.transform(date, "shortTime", "", this.currentLocale);
        } else {
            dateTime = this.datePipe.transform(date, "mediumDate", "", this.currentLocale) + " " +
            this.datePipe.transform(date, "shortTime", "", this.currentLocale);
        }
        return dateTime;
    }
}
