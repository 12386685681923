
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="share_folder_revoke_dialog mail-dialog">
    <div class="header" layout="row center-center">
        <h4>{{ 'REVOKE_SHARE_LBL' | translate }}</h4>
    </div>
    <div class="content mail__dialog-body mail__dialog-body--pad">
        <div class="access_msg">
            {{ 'REVOKE_SHARE_ACCESS_MSG' | translate }} <span *ngIf="grant.gt === 'pub'"> {{'PUBLIC' | translate }} </span><span *ngIf="grant.gt !== 'pub'">{{grant.d}} </span> ?
        </div>
        <div class="message_type" *ngIf="grant.gt !== 'pub'">
            <mat-form-field class="drop_down">
                <mat-select [(value)]="messageType">
                    <mat-option value="1">{{ 'MESSAGE_OPTION2' | translate }}</mat-option>
                    <mat-option value="2">{{ 'MESSAGE_OPTION1' | translate }}</mat-option>
                    <mat-option value="3">{{ 'MESSAGE_OPTION3' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <span *ngIf="grant.gt !== 'pub'">
            <div *ngIf="messageType!=='2'" class="note">
                <b>{{ 'NOTE' | translate}}</b> : {{ 'REVOKE_SHARE_NOTE' | translate }}
            </div>
            <div *ngIf="messageType==='3'">
                <textarea matInput [(ngModel)]="note" cdkTextareaAutosize style="max-height: 50px;"></textarea>
            </div>
        </span>
    </div>
    <div class="btn">
        <button mat-button (click)="close()" [disableRipple]=true>
            <div>{{ 'CANCEL' | translate }}</div>
        </button>
        <button mat-button [disableRipple]=true (click)="sendRevoke()">
            <div>{{ 'SHARE_REVOKE' | translate }}</div>
        </button>
    </div>
</div>