
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { SearchFolderActionTypes } from "../actions/search-folder.action";
import { SearchFolder } from "../shared/models/search-folder";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Action } from "../actions/index";

export interface SearchFolderState extends EntityState<SearchFolder> {
  selectedSearchFolder: SearchFolder;
  keyword: string;
}

export const searchFolderAdapter: EntityAdapter<SearchFolder> = createEntityAdapter<SearchFolder>({
  selectId: (searchFolder: SearchFolder) => searchFolder.id
});

export const initialState: SearchFolderState = searchFolderAdapter.getInitialState({
  selectedSearchFolder: null,
  keyword: ""
});

export function searchFolderReducer(state = initialState, action: Action): SearchFolderState {
  switch (action.type) {

    case SearchFolderActionTypes.SEARCH_KEYWORD_STORE: {
      return {
        ...state,
        keyword: action.payload as string
      };
    }

    case SearchFolderActionTypes.LOAD_SEARCHFOLDERS_SUCCESS: {
      return searchFolderAdapter.addAll(action.payload.folders, {
        ...state
      });
    }

    case SearchFolderActionTypes.CREATE_SEARCHFOLDERS_SUCCESS: {
      return searchFolderAdapter.addOne(action.payload.folder, {
        ...state
      });
    }

    case SearchFolderActionTypes.DELETE_SEARCHFOLDERS_SUCCESS: {
      return searchFolderAdapter.removeOne(action.payload.folder.id, {
        ...state
      });
    }

    case SearchFolderActionTypes.UPDATE_SEARCHFOLDERS_SUCCESS: {
      return searchFolderAdapter.updateOne(action.payload.folder, {
        ...state
      });
    }

    case SearchFolderActionTypes.SET_SEARCHFOLDERS_SUCCESS: {
      return {
        ...state,
        selectedSearchFolder: action.payload.folder
      };
    }

    default: {
      return state;
    }
  }
}

export const _getSearchFolderKeyword = (state: SearchFolderState) => state.keyword;
