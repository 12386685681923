// @ts-nocheck
import Quill from "quill";
import { ContainerBlot, Scope } from "parchment";

let Block = Quill.import("blots/block");
let BlockEmbed = Quill.import("blots/block/embed");


class Container extends ContainerBlot { }
Container.allowedChildren = [Block, BlockEmbed, Container];

class ContainBlot extends Container {
    static create(value) {
        return super.create(value);
    }

    formats(domNode) {
        if (domNode) {
            return domNode.tagName;
        }
        return this.domNode.tagName;
    }

}

ContainBlot.blotName = "contain";
ContainBlot.tagName = "contain";
ContainBlot.scope = Scope.BLOCK_BLOT;
ContainBlot.defaultChild = Block;
ContainBlot.allowedChildren = [Block, BlockEmbed, Container];

export default ContainBlot;
