
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="calendar-delete-recover">
    <div class="mail__dialog-header desktop-header" layout="row center-center">
        <div class="header-title ">
            <span class="disable-select">{{ 'CALENDARS.RECOVER_DELETED_ITEM_LBL' | translate }}</span>
        </div>
    </div>
    <div class="mobile_display_header">
        <div class="disable-select">
            <span class="disable-select">{{ 'CALENDARS.RECOVER_DELETED_ITEM_LBL' | translate }}</span>
        </div>
    </div>
    <div class="mail__dialog-body">
        <div class="search-div">
            <mat-form-field class="search-form-field">
                <input type="text" [(ngModel)]="searchInput" matInput
                    placeholder="{{ 'CALENDARS.SEARCH' | translate }}">
                <mat-icon matSuffix (click)="searchItem()">search</mat-icon>
            </mat-form-field>
        </div>
        <div class="render-item">
            <table>
                <tr>
                    <th>{{ 'FILTER_SEARCH_NAME' | translate }}</th>
                    <th>{{ 'FILTER_SEARCH_DATE' | translate }}</th>
                </tr>
                <tr *ngFor="let item of recoverItem; index as i;" (click)="selectEvent(item, i, $event)"
                    [ngClass]="{'is-selected': isSelectedItem(item)}">
                    <td class="item-name">{{item.name}}</td>
                    <td>{{item.startDate | date: "MM/d/y"}}</td>
                </tr>
            </table>
            <div class="no-result-found" *ngIf="recoverItem && recoverItem.length === 0">
                {{ 'COMMON.NO_RESULT_FOUND' | translate }}
            </div>
        </div>
    </div>
    <div class="footer-button">
        <div class="operation">
            <button mat-button (click)="close()">{{'COMMON.CLOSE' | translate }}</button>
            <button mat-button (click)="openMoveDialog()"
                [disabled]="selectedItems.length === 0">{{ 'CALENDARS.RECOVER_TO_LBL' | translate }}</button>
        </div>
    </div>
</div>