
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, NgZone, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { take } from "rxjs/operators";
import { MailConstants } from "../../../../common/utils/mail-constants";
import { TranslateService } from "@ngx-translate/core";
import { ElectronService } from "src/app/services/electron.service";
import { ConfigService } from "src/app/config.service";

@Component({
  selector: "vp-help-faq-dialog",
  templateUrl: "./help-faq-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpFaqDialogComponent implements OnInit {

  faqURL: string;
  userManual: string;
  constructor(
    public dialogRef: MatDialogRef<HelpFaqDialogComponent>,
    private mailBroadcaster: MailBroadcaster,
    private ngZone: NgZone,
    private translateService: TranslateService,
    private changeDetectionRef: ChangeDetectorRef,
    private electronService: ElectronService,
    private configService: ConfigService
  ) {
    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_FAQ_DIALOG).pipe(take(1)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });
    let browserLang = this.translateService.getBrowserLang();
    const localLang = this.electronService.isElectron
        ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE)
        : localStorage.getItem(MailConstants.MAIL_LANGUAGE);
    if (localLang !== null && localLang !== undefined && localLang !== "undefined") {
      browserLang = localLang;
    }
    browserLang = browserLang.match(/en|de/) ? browserLang : "en";
    if (browserLang === "en") {
      if (this.configService.URLS.faq) {
        this.faqURL = this.configService.URLS.faq;
      } else {
        this.faqURL = "https://portal.vnc.biz/product-area/faq/vncmail-faq";
      }
      if (this.configService.URLS.userManual) {
        this.userManual = this.configService.URLS.userManual;
      } else {
        this.userManual = "https://en.docs.vnc.biz/vncmail/browsermanual/";
      }
    } else {
      if (this.configService.URLS.faq_de) {
        this.faqURL = this.configService.URLS.faq_de;
      } else {
        this.faqURL = "https://portal.vnc.biz/product-area/faq/vncmail-faq-de";
      }
      if (this.configService.URLS.userManual_de) {
        this.userManual = this.configService.URLS.userManual_de;
      } else {
        this.userManual = "https://portal.vnc.biz/product-area/faq/vncmail-faq-de";
      }
    }
    this.changeDetectionRef.markForCheck();
  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }

}
