
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";

import { Store, select } from "@ngrx/store";

import { BriefcaseRootState, getAllBriefcaseFolders, getSelectedBriefcaseFolder } from "../store/selectors";
import {
  LoadBriefcaseFolders,
  LoadBriefcaseFoldersSuccess,
  LoadBriefcaseFoldersFail,
  UpdateBriefcaseFolderSuccess,
  CreateBriefcaseFolderSuccess,
  CreateBriefcaseFolder,
  DeleteBriefcaseFolder,
  DeleteBriefcaseFolderFail,
  CreateBriefcaseFailure,
  UpdateBriefcaseFolderFail,
  RemoveAllBriefcaseFilesFromFolder
} from "../store/actions";
import { BriefcaseService } from "../services/briefcase.service";
import { ErrorService } from "src/app/common/providers/error-service";
import { ErrorType } from "src/app/common/enums/mail-enum";
import { BriefcaseFolder } from "../shared/models/briefcase-folder";
import { MailBroadcaster } from "../../common/providers/mail-broadcaster.service";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { ToastService } from "src/app/common/providers/toast.service";
import { ElectronService } from "src/app/services/electron.service";
import * as _ from "lodash";
import { MailService } from "src/app/mail/shared/services/mail-service";
import { Subject, Observable } from "rxjs";
import { StopLoading } from "src/app/contacts/store";
import { StopProcessing } from "src/app/actions/app";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { SaveSearch } from "src/app/actions/search";

@Injectable()
export class BriefcaseFolderRepository {

  briefcaseFolder: BriefcaseFolder[] = [];
  selectedFolder: BriefcaseFolder;
  constructor(
    private store: Store<BriefcaseRootState>,
    private briefcaseService: BriefcaseService,
    private errorService: ErrorService,
    private mailBroadcaster: MailBroadcaster,
    private toastService: ToastService,
    private electronService: ElectronService,
    private translate: TranslateService,
    private mailService: MailService,
    private router: Router
  ) {

    this.store.select(getAllBriefcaseFolders).subscribe(res => {
      this.briefcaseFolder = res;
    });

    this.store.select(getSelectedBriefcaseFolder).subscribe(res => {
      this.selectedFolder = res;
    });
  }

  getBriefcaseFolders(isSingleNode = false) {
    this.store.dispatch(new LoadBriefcaseFolders());
    this.briefcaseService.getBriefcaseFolders({ view: "document" }, isSingleNode).subscribe(
      res => {
        console.log("briefcase folders", res);
        this.setBriefcaseShareQuery(res);
        this.store.dispatch(
          new LoadBriefcaseFoldersSuccess({ folders: res })
        );
      },
      err => {
        this.store.dispatch(new LoadBriefcaseFoldersFail());
        this.errorService.emit({ id: ErrorType.Generic, messages: err });
      }
    );
  }

  getSavedSearchFolders() {
    this.mailService.getSavedSearchFolder().subscribe(res => {
      this.store.dispatch(new SaveSearch({folders: res}));
    });
  }

  createBriefcaseFolder(targetFolder: BriefcaseFolder, title, color?: string) {
    // this.store.dispatch(new CreateBriefcaseFolder());
    let targetFolderId = null;
    if (targetFolder) {
      targetFolderId = targetFolder.id;
    } else {
      targetFolderId = "1";
    }
    let body = null;
    if (color) {
      body = { l: targetFolderId, folderId: targetFolderId, name: title, view: "document", rgb: color };
    } else {
      body = { l: targetFolderId, folderId: targetFolderId, name: title, view: "document" };
    }
    this.briefcaseService.createFolder(body).subscribe(res => {
      if (targetFolder) {
        if (!targetFolder.children) {
          targetFolder.children = [];
        }
        if (!targetFolder.children.find(f => f.id === (res as BriefcaseFolder).id)) {
          targetFolder.children.push(res as BriefcaseFolder);
        }
        const folder: BriefcaseFolder = this.getRootFolder(targetFolder);
        this.mailBroadcaster.broadcast(MailConstants.FOLDER_CREATE_BROADCAST);
        this.getBriefcaseUpdatedRootFolder(folder, "FOLDER_CREATED_MSG");
      } else {
        this.store.dispatch(new CreateBriefcaseFolderSuccess({ folder: res as BriefcaseFolder }));
        this.toastService.show("FOLDER_CREATED_MSG");
      }
    },
      err => {
        this.store.dispatch(new CreateBriefcaseFailure());
        if (err.indexOf("invalid name") !== -1) {
          const error = err.split("invalid name:");
          this.translate.get(MailConstants.INVALID_NAME_ERROR_MSG, { characters: error[1]}).pipe(take(1)).subscribe((text: string) => {
            this.toastService.showPlainMessage(text);
          });
        } else if (err.indexOf("object with that name already exists") !== -1) {
          this.toastService.show("DUPLICATE_FOLDER_MSG");
        } else {
          this.errorService.emit({ id: ErrorType.Generic, messages: err });
        }
      });
  }

  getRootFolder(targetFolder: BriefcaseFolder): BriefcaseFolder {
    if (!targetFolder) {
      return null;
    }
    if (targetFolder.id && targetFolder.id.includes(":")) {
      const folderNames: any[] = targetFolder.absFolderPath.split("/");
      const regShareFolderIdExp = /[^:\\]+/;
      const folderZid = targetFolder.id.match(regShareFolderIdExp)[0];

      const rootFolder = this.briefcaseFolder.find(folder => (folder.zid && folder.zid === folderZid &&
        folderNames.indexOf(folder.oname) > -1));
      return rootFolder;
    } else if (targetFolder.absFolderPath) {
      const reg = /[^/\\]+/;
      const rootFolderName = targetFolder.absFolderPath.match(reg)[0];
      return this.briefcaseFolder.find(folder => folder.name.toLowerCase() === rootFolderName.toLowerCase());
    }
  }

  getBriefcaseUpdatedRootFolder(folder: BriefcaseFolder, keyMessage?) {
    if (!folder) {
      return;
    }
    const body = {
      view: "document",
      folder: {
        uuid: folder.uuid
      }
    };
    this.briefcaseService.getBriefcaseFolders(body, true).subscribe(res => {
      if (res && res.length === 1) {
        const tempFolder = res[0] as BriefcaseFolder;
        // if (!tempFolder.u) {
        //   tempFolder.u = "0";
        // }
        // if (folder.name.toLocaleLowerCase() === "spam") {
        //   tempFolder.name = "Spam";
        // }
        this.store.dispatch(new UpdateBriefcaseFolderSuccess({ id: tempFolder.id, changes: tempFolder }));
      } else {
        this.store.dispatch(new UpdateBriefcaseFolderSuccess({ id: folder.id, changes: folder }));
      }
      if (keyMessage) {
        this.toastService.show(keyMessage);
      }
    });
  }

  deleteMailFolder(targetFolder: BriefcaseFolder): Observable<any> {
    this.store.dispatch(new DeleteBriefcaseFolder());
    const response = new Subject<string>();
    this.mailService.folderAction({ id: targetFolder.id, op: "trash" }).subscribe(res => {
      this.getBriefcaseFolders();
      response.next(MailConstants.TRASH_MAIL_FOLDER_ID);
      // if (this.selectedMailFolder.name === targetFolder.name) {
      //   this.router.navigate(["/mail/briefcase"]);
      // }
    },
    err => {
      this.store.dispatch(new DeleteBriefcaseFolderFail());
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
    return response.asObservable();
  }

  moveMailFolder(sourceFolderId: string, destinationFolderId: string): Observable<any> {
    const response = new Subject<string>();
    this.mailService.folderAction({ id: sourceFolderId, l: destinationFolderId, op: "move" }).subscribe(res => {
      this.getBriefcaseFolders();
      response.next(destinationFolderId);
    });
    return response.asObservable();
  }

  permenentDeleteMailFolder(targetFolder) {
    this.store.dispatch(new DeleteBriefcaseFolder());
    this.mailService.folderAction({ id: targetFolder.id, op: "delete" }).subscribe(res => {
      const rootFolder: BriefcaseFolder = this.getRootFolder(targetFolder);
      if (targetFolder.l !== MailConstants.TRASH_MAIL_FOLDER_ID) {
        const parentFolder: BriefcaseFolder = this.getSelectedSubFolder(targetFolder.l, rootFolder);
        parentFolder.children.splice(parentFolder.children.indexOf(targetFolder), 1);
        if (parentFolder.children.length === 0) {
           parentFolder.children = null;
        }
      } else {
        if (rootFolder.children) {
          rootFolder.children.splice(rootFolder.children.indexOf(targetFolder), 1);
        } else if (rootFolder.link) {
          rootFolder.link.splice(rootFolder.link.indexOf(targetFolder), 1);
        }
        if (rootFolder.children && rootFolder.children.length === 0) {
          rootFolder.children = null;
        }
      }
      this.getMailUpdatedRootFolder(rootFolder, "FOLDER_DELETED_MSG");
      if (this.selectedFolder.name === targetFolder.name) {
        this.router.navigate(["/briefcase/list"]);
      }
    },
    err => {
      this.store.dispatch(new DeleteBriefcaseFolderFail());
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
  }

  getSelectedSubFolder(folderId: string, rootFolder: BriefcaseFolder): BriefcaseFolder {
    if (rootFolder === null || rootFolder === undefined) {
      return null;
    }
    if (rootFolder.id === folderId) {
      return rootFolder;
    }
    if (rootFolder.children) {
      for (let i = 0; i < rootFolder.children.length; i++) {
        if (folderId === rootFolder.children[i].id) {
          return rootFolder.children[i];
        } else if (rootFolder.children[i].children) {
          const f =  this.getSelectedSubFolder(folderId, rootFolder.children[i]);
          if (f) {
            return f;
          }
        }
      }
    } else {
      return null;
    }
  }

  getMailUpdatedRootFolder(folder: BriefcaseFolder, keyMessage?) {
    if (!folder) {
      return;
    }
    const body = {
      view: "document",
      folder: {
        uuid: folder.uuid
      }
    };
    this.mailService.getMailFolders(body, true).subscribe(res => {
      if (res && res.length === 1) {
        const tempFolder = res[0] as BriefcaseFolder;
        this.store.dispatch(new UpdateBriefcaseFolderSuccess({ id: tempFolder.id, changes: tempFolder }));
      } else {
        this.store.dispatch(new UpdateBriefcaseFolderSuccess({ id: folder.id, changes: folder }));
      }
      if (keyMessage) {
        this.toastService.show(keyMessage);
      }
    });
  }

  updateBriefcaseFolder(targetFolder: BriefcaseFolder, title, folderColor?: string) {
    const oldtitle: string = targetFolder.name;
    this.mailService.folderAction({ id: targetFolder.id, name: title, op: "rename"}).subscribe(res => {
      if (folderColor) {
        this.changeFolderColor(targetFolder, folderColor);
      }
      targetFolder.name = title;
      let folder: BriefcaseFolder;
      const updatedAbsPath = targetFolder.absFolderPath.replace(oldtitle, title);
      targetFolder.absFolderPath = updatedAbsPath;
      if (targetFolder.l !==  MailConstants.ROOT_MAIL_FOLDER_ID) {
        folder = this.getRootFolder(targetFolder);
      } else {
        folder = targetFolder;
      }
      this.getBriefcaseUpdatedRootFolder(folder);
      this.store.dispatch(new UpdateBriefcaseFolderSuccess({ id: folder.id, changes: folder }));
      this.mailBroadcaster.broadcast(MailConstants.CALL_NO_OP_REQUEST);
    },
    err => {
      this.store.dispatch(new CreateBriefcaseFailure());
      if (err.indexOf("invalid name") !== -1) {
        const error = err.split("invalid name:");
        this.translate.get(MailConstants.INVALID_NAME_ERROR_MSG, { characters: error[1]}).pipe(take(1)).subscribe((text: string) => {
          this.toastService.showPlainMessage(text);
        });
      } else {
        this.errorService.emit({ id: ErrorType.Generic, messages: err });
      }
    });
  }

  changeFolderColor(targetFolder: BriefcaseFolder, folderColor) {
    this.mailService.folderAction({ id: targetFolder.id, op: "color", rgb: folderColor }).subscribe(res => {
      targetFolder.rgb = folderColor;
      const rootFolder: BriefcaseFolder = this.getRootFolder(targetFolder);
      const folder: BriefcaseFolder = this.getRootFolder(targetFolder);
      this.getBriefcaseUpdatedRootFolder(folder);
    },
    err => {
      this.store.dispatch(new UpdateBriefcaseFolderFail());
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
  }

  getParentFolder(targetFolder: BriefcaseFolder, rootFolder: BriefcaseFolder) {
    return this.getSelectedSubFolder(targetFolder.l, rootFolder);
  }

  getShareFoldersQuery(folders: BriefcaseFolder[]): string {
    const ids: string [] = [];
    folders.map( f => {
      if (f.perm) {
        if (f.id.indexOf(":") !== -1 ) {
          ids.push("inid:" + "\"" + f.id + "\"");
        } else {
          ids.push("inid:" + f.id);
        }
        const children = MailUtils.getChildFolders([f]);
        if (children.length > 0) {
          children.map( c => {
            ids.push("inid:" + "\"" + c.id + "\"");
          });
        }
      }
    });
    ids.push("is:local");
    return ids.join(",").replace(/,/g, " OR ");
  }

  emptyMailFolder(targetFolder) {
    this.store.dispatch(new DeleteBriefcaseFolder());
    let folderId = "";
    if ( targetFolder.owner && targetFolder.perm ) {
      folderId = targetFolder.zid + ":" + targetFolder.rid;
    } else {
      folderId = targetFolder.id;
    }
    let currentFolder = null;
    this.store.pipe(select(getSelectedBriefcaseFolder), take(1)).subscribe(folder => {
      if (folder) {
        currentFolder = folder;
      }
    });
    this.mailService.folderAction({ id: folderId, op: "empty" , recursive: true}).subscribe(res => {
      this.getBriefcaseFolders();
      if (currentFolder.name === targetFolder.name) {
        this.removeFilesFromFolder(folderId);
      }
      this.toastService.show("EMPTY_FODLER_SUCCESS_MSG");
    },
    err => {
      this.store.dispatch(new DeleteBriefcaseFolderFail());
      this.errorService.emit({ id: ErrorType.Generic, messages: err });
    });
  }

  removeFilesFromFolder(folderId: string): void {
    this.store.dispatch(new RemoveAllBriefcaseFilesFromFolder(folderId));
  }

  setBriefcaseShareQuery(response: any): void {
    const childFolders = MailUtils.getChildFolders(response);
    const allFolders = [...response, ...childFolders];
    const searchQuery = this.getShareFoldersQuery(allFolders);
    if (this.electronService.isElectron) {
      this.electronService.setToStorage("BrifcaseSearchQuery", searchQuery);
    } else {
      localStorage.setItem("BrifcaseSearchQuery", searchQuery);
    }
  }

}
