
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit, OnDestroy } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MailBroadcaster } from "../../../common/providers/mail-broadcaster.service";
import { MailConstants } from "../../../common/utils/mail-constants";
import { takeUntil, filter, take, distinctUntilChanged } from "rxjs/operators";
import { Subject } from "rxjs";
import { BroadcastKeys } from "../../../common/enums/broadcast.enum";
import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { SetLastDraftId, SetSearchFor, SetViewBy } from "../../../actions/app";
import { Store, select } from "@ngrx/store";
import { SearchState } from "../../../reducers/search";
import { getReadingPane, getOnlineStatus, getLastDraftId, getViewBy, getIsRightSidebarExpanded } from "src/app/reducers";
import { getMailDetailId, getMailTabs, getMessageDetailId, getSelectedTab, MailRootState } from "src/app/mail/store";
import { CommonRepository } from "src/app/mail/repositories/common-repository";
import { MatDialog } from "@angular/material/dialog";
import { ConversationRepository } from "src/app/mail/repositories/conversation.repository";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { VncLibraryService } from "vnc-library";

@Component({
  selector: "vp-tabs",
  templateUrl: "./tabs.component.html"
})
export class TabsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() menu: Array<{ name: string, url: string, icon: string, badge: number }>;
  @ViewChild("mobileTab", { read: ElementRef, static: false }) mobileTab: ElementRef;
  selectedMenu: { name: string, url: string, icon: string, badge: number };
  SWIPE_ACTION = { LEFT: "swipeleft", RIGHT: "swiperight" };
  swipeLeft: number = 0;
  scrollLeft: number = 0;
  isMobileScreen: boolean = false;

  private isAlive$ = new Subject<boolean>();
  isOnline: boolean;
  isTablet: boolean;
  displayComposeTab: boolean;
  currentUrl = "";
  previewPanel = !!localStorage.getItem("readingPane") ? localStorage.getItem("readingPane") : "right";
  displayPreviewPanelTab: boolean = false;
  mailDetailSubject: string = "";
  composeAttachMsg: string = "";
  lastDraftId: string;
  mailTabs: any[] = [];
  activeTab: any;
  currentView: string;
  rightSidebarExpanded: boolean;
  messageDetailId: string;
  mailDetailId: string;
  constructor(
    private router: Router,
    private translate: TranslateService,
    private broadcaster: MailBroadcaster,
    private breakpointObserver: BreakpointObserver,
    private appStore: Store<MailRootState>,
    private commonRepository: CommonRepository,
    private store: Store<SearchState>,
    private vncLibraryService: VncLibraryService,
    private convRepository: ConversationRepository,
  ) {
    // console.log("[TabsComponent] constructor", new Date());
    this.currentUrl = this.router.routerState.snapshot.url;
    this.isTablet = this.breakpointObserver.isMatched([Breakpoints.Tablet]);
    this.handleDisplayComposeTab();
    this.appStore.select(getMailTabs).pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(tabs => {
      console.log("[tabs.component] getMailTabs; ", tabs, this.mailTabs);
      tabs = tabs.filter(v => !!v);
      this.mailTabs = tabs;
      if (tabs.length > this.mailTabs.length && tabs[tabs.length - 1]) {
        this.openTab(tabs[tabs.length - 1]);
      }
      for (let tab of tabs) {
        if (!!tab && tab.id && !tab.l) {
          this.convRepository.getMessageById(tab.id).pipe(take(1)).subscribe(tabMessage => {
            if (!!tabMessage) {
              tab = tabMessage;
            }
          });
        }
      }
      this.mailTabs = tabs;
    });
    this.appStore.select(getSelectedTab).pipe(takeUntil(this.isAlive$)).subscribe(tab => {
      console.log("[tabs.component] getSelectedTab; ", tab);
      this.activeTab = tab;
    });
    this.store.select(getIsRightSidebarExpanded).pipe(takeUntil(this.isAlive$))
    .subscribe(res => {
      this.rightSidebarExpanded = res;
    });
  }
  ngOnInit() {
    // console.log("[TabsComponent] ngOnInit", new Date());
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
    this.selectedMenu = this.menu[0];
    this.store.select(getViewBy).pipe(takeUntil(this.isAlive$)).subscribe(value => {
      this.currentView = value;
    });
    this.appStore.pipe(select(getOnlineStatus), distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(isOnline => {
      this.isOnline = isOnline;
    });

    this.store.select(getReadingPane).pipe(takeUntil(this.isAlive$)).subscribe(value => {
      console.log("[tabs.component][getReadingPane]", value);
      if (this.previewPanel !== value) {
        this.previewPanel = value;
      }
    });
    this.store.select(getMessageDetailId).pipe(takeUntil(this.isAlive$)).subscribe(v => {
      this.messageDetailId = v;
    });
    this.store.select(getMailDetailId).pipe(takeUntil(this.isAlive$)).subscribe(v => {
      this.mailDetailId = v;
    });
    this.broadcaster.on<any>(MailConstants.BROADCAST_MAIL_SELECTED_TAB).pipe(takeUntil(this.isAlive$))
      .subscribe(res => {
        this.selectedMenu = this.menu[0];
      });
    this.broadcaster.on<any>(BroadcastKeys.OPEN_PREFERENCE_TAB).pipe(takeUntil(this.isAlive$))
      .subscribe(res => {
          this.selectedMenu = this.menu.find(tab => tab.name === "PREFERENCES_LBL"); // this.menu[5];
    });
    this.broadcaster.on<any>("SELECTED_CONTACTS_TAB").pipe(takeUntil(this.isAlive$))
      .subscribe(res => {
          setTimeout(() => {
            this.selectedMenu = this.menu.find(tab => tab.url === "/contacts"); // this.menu[1];
          }, 500);
    });
    this.broadcaster.on<any>(BroadcastKeys.BRIEFCASE_TAB_SELECTED).pipe(takeUntil(this.isAlive$))
      .subscribe(res => {
          setTimeout(() => {
            this.selectedMenu = this.menu.find(tab => tab.name === "BRIEFCASE_TITLE");
          }, 500);
    });
    this.broadcaster.on<any>("SELECTED_CALENDAR_TAB").pipe(takeUntil(this.isAlive$))
      .subscribe(res => {
          setTimeout(() => {
            this.selectedMenu = this.menu.find(tab => tab.url === "/calendar"); // this.menu[3];
          }, 500);
    });
    this.broadcaster.on<any>("FILES_ATTACHMENT_COUNT").pipe(takeUntil(this.isAlive$))
      .subscribe(res => {
        this.composeAttachMsg = res.msg;
        if (res.isUploadDone) {
          setTimeout(() => {
            this.composeAttachMsg = "";
          }, 3000);
        }
    });

    this.breakpointObserver
    .observe([Breakpoints.Tablet])
    .pipe(takeUntil(this.isAlive$))
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isTablet = true;
      } else {
        this.isTablet = false;
      }
      this.handleDisplayComposeTab();
      this.handleReadinPanelOffDetailTab();
    });

    this.store.pipe(select(getLastDraftId), takeUntil(this.isAlive$)).subscribe(v => {
      console.log("[tabs.component][getLastDraftId]", v);
      this.lastDraftId = v;
      if (!!v) {
        const forceClose = v === "remove";
        this.handleDisplayComposeTab(forceClose);
      }
    });
  this.router.events.pipe(takeUntil(this.isAlive$)).pipe(filter(e => e instanceof NavigationEnd)).subscribe(data => {
    this.currentUrl = this.router.routerState.snapshot.url;
    this.handleDisplayComposeTab();
    this.handleReadinPanelOffDetailTab();
  });
  this.broadcaster.on<any>("READING_PANEL_OFF_TAB_TITLE").pipe(takeUntil(this.isAlive$)).subscribe(res => {
    const subject = res;
    if (!!subject && subject !== null && subject !== "") {
      this.mailDetailSubject = subject;
    } else {
      this.translate.get("NO_SUBJECT").pipe(take(1)).subscribe((text: string) => {
        this.mailDetailSubject = text;
      });
    }
  });
    // this.configService.getAllConfig().pipe(takeUntil(this.isAlive$)).subscribe(res => {
    //   /* Remove contact Tab if useVNCdirectoryAuth = true */
    //   if (res.useVNCdirectoryAuth && res.useVNCdirectoryAuth === true) {
    //     const index = this.menu.findIndex(m => m.url === "/contacts");
    //     if (index !== -1) {
    //       this.menu.splice(index, 1);
    //     }
    //   }
    // });
  }

  ngAfterViewInit() { }

  removePreferenceTab () {
    const prefTab = this.menu.find(tab => tab.name === "PREFERENCES_LBL");
    if (prefTab) {
      this.selectedMenu = this.menu[0];
      this.menu.splice(this.menu.indexOf(prefTab), 1);
      let lastVisitedTab = localStorage.getItem("lastVisitedTab");
      if (lastVisitedTab === "/contacts") {
        this.selectedMenu = this.menu.find(tab => tab.url === "/contacts"); // this.menu[1];
        this.router.navigate([lastVisitedTab]);
      } else if (lastVisitedTab === "/briefcase") {
        this.selectedMenu = this.menu.find(tab => tab.url === "/briefcase"); // this.menu[4];
        this.router.navigate([lastVisitedTab]);
      } else if (lastVisitedTab === "/calendar") {
        this.selectedMenu = this.menu.find(tab => tab.url === "/calendar"); // this.menu[3];
        this.router.navigate([lastVisitedTab]);
      } else {
        this.selectedMenu = this.menu.find(tab => tab.url === "/mail"); // this.menu[0];
        this.router.navigate(["/mail"]);
        if (this.previewPanel === "off") {
          this.broadcaster.broadcast("HIDE_MAIL_LIST_FROM_MAIL_DETAIL", false);
        }
      }
    }
  }

  removeComposeTab () {
    console.log("[tabs.component] setLastDraftId: remove");
    if (this.currentUrl.startsWith("/mail/compose")) {
      this.broadcaster.broadcast("removeComposeTab");
    } else {
      this.appStore.dispatch(new SetLastDraftId("remove"));
    }
  }

  activeMailMenu() {
    this.activeMenu(this.menu[0]);
  }

  activeMenu(menu) {
    if (menu.name !== "MAIL" && menu.name !== "CALENDAR") {
      if (this.commonRepository.showNoInternetToastIfRequired()) {
        return;
      }
    }
    if (!!this.selectedMenu) {
      if (menu.name === "PREFERENCES_LBL" && this.selectedMenu.name !== "PREFERENCES_LBL") {
        localStorage.setItem("lastVisitedTab", this.selectedMenu.url);
      } else if ((menu.name !== "PREFERENCES_LBL" && this.selectedMenu.name !== "PREFERENCES_LBL")
      || (menu.name !== "PREFERENCES_LBL" && this.selectedMenu.name === "PREFERENCES_LBL")) {
        localStorage.setItem("lastVisitedTab", menu.url);
      }
    }
    this.selectedMenu = menu;
    this.router.navigate([menu.url]);
    if (menu.name === "MAIL") {
      this.store.dispatch(new SetSearchFor("mail"));
    } else if (menu.name === "CONTACTS") {
      this.store.dispatch(new SetSearchFor("contact"));
    } else if (menu.name === "BRIEFCASE_TITLE") {
      this.store.dispatch(new SetSearchFor("briefcase"));
    } else if (menu.name === "CALENDAR") {
      this.store.dispatch(new SetSearchFor("calendar"));
    }
    console.log("[activeMenu]", menu);
  }

  // Action triggered when user swipes
  swipe(event, action = this.SWIPE_ACTION.RIGHT) {
    const swipeLeft = this.swipeLeft + event.deltaX;
    if (swipeLeft > 0) {
      this.swipeLeft = 0;
    } else if (swipeLeft < this.scrollLeft) {
      this.swipeLeft = this.scrollLeft;
    } else {
      this.swipeLeft = swipeLeft;
    }
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  editDraft() {
    if (!this.router.url.startsWith("mail/compose") && this.lastDraftId && this.lastDraftId !== "remove") {
      this.router.navigate(["/mail/compose/edit/" + this.lastDraftId]);
    }
  }

  handleDisplayComposeTab(close?: boolean) {
    if (!!close) {
      this.appStore.dispatch(new SetLastDraftId("remove"));
      this.displayComposeTab = false;
      console.log("[handleDisplayComposeTab] forceClose", this.isTablet, this.currentUrl, this.displayComposeTab);
      return;
    }
    if (this.currentUrl.startsWith("/mail/compose") || this.lastDraftId && this.lastDraftId !== "remove") {
      this.displayComposeTab = true;
    } else {
      this.displayComposeTab = false;
    }
    console.log("[handleDisplayComposeTab]", this.isTablet, this.currentUrl, this.displayComposeTab, this.lastDraftId);
  }

  handleReadinPanelOffDetailTab(): void {
    console.log("tabs.component handleReadinPanelOffDetailTab");
    if (this.previewPanel === "off" && this.currentUrl.indexOf("/detail/") > -1) {
      this.displayPreviewPanelTab = false;
    } else {
      this.displayPreviewPanelTab = false;
      this.mailDetailSubject = "";
    }
  }

  removeDetailTab (): void {
    console.log("[tabs.component] removeDetailTab");
    this.selectedMenu = this.menu[0];
    const currentURL = this.currentUrl;
    const navigateURL = currentURL.substr(0, currentURL.lastIndexOf("/detail/"));
    this.router.navigate([navigateURL]);
    this.broadcaster.broadcast("HIDE_MAIL_LIST_FROM_MAIL_DETAIL", false);
  }

  openTab(data) {
    console.log("[openTab]", data);
    if (!data) {
      return;
    }
    if (data.m) {
      this.openMailDetail(data);
    } else {
      this.openMessageDetail(data);
    }
    this.activeTab = data;
  }

  removeTab(data) {
    this.convRepository.removeTab(data);
    this.broadcaster.broadcast("removeTab", data);
  }

  openMailDetail(conversation: any): void {
    if (this.currentView === "message") {
      this.broadcaster.broadcast("setViewBy", "conversation");
      localStorage.setItem("currentView", "conversation");
      this.store.dispatch(new SetViewBy("conversation"));
    }
    this.selectedMenu = this.menu[0];
    this.store.dispatch(new SetSearchFor("mail"));
    this.activeTab = conversation;
    if (this.mailTabs.length === 0) {
      this.convRepository.addTab(conversation);
    }
    const path = MailUtils.getPath(conversation.query);
    const isDraftFolder: boolean = this.currentUrl.startsWith("/mail/drafts");
    if (isDraftFolder || path === "drafts") {
      this.router.navigate(["/mail/" + path + "/detail/draft/", conversation.id]);
    } else {
      this.router.navigate(["/mail/" + path + "/detail", conversation.id]);
    }
    this.broadcaster.broadcast("openMailDetail", conversation);
  }

  openMessageDetail(message: any): void {
    if (this.currentView === "conversation") {
      this.broadcaster.broadcast("setViewBy", "message");
      localStorage.setItem("currentView", "message");
      this.store.dispatch(new SetViewBy("message"));
    }
    this.selectedMenu = this.menu[0];
    this.store.dispatch(new SetSearchFor("mail"));
    this.activeTab = message;
    if (this.mailTabs.length === 0) {
      this.convRepository.addTab(message);
    }
    this.broadcaster.broadcast("openMessageDetail", message);
    const path = MailUtils.getPath(message.query || message.l.toString());
    const isDraftFolder: boolean = this.currentUrl.startsWith("/mail/drafts");
    if (isDraftFolder || path === "drafts") {
      this.router.navigate(["/mail/" + path + "/detail/draft/m", message.id]);
    } else {
      this.router.navigate(["/mail/" + path + "/detail/m", message.id]);
    }
  }

}
