
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../../actions";
import { MailFolder } from "../../models/mail-folder.model";
import { Update } from "@ngrx/entity/src/models";
import { AppActionTypes } from "../../../actions/app";


export class MailFolderActionTypes extends AppActionTypes {
  static SELECT_MAIL_FOLDER = "[FOLDERS] Select Mail Folder";
  static LOAD_FOLDERS = "[FOLDERS] Load Folders";
  static LOAD_FOLDERS_SUCCESS = "[FOLDERS] Load Folders Success";
  static LOAD_FOLDERS_FAIL = "[FOLDERS] Load Folders Fail";

  static CREATE_MAIL_FOLDER = "[FOLDERS] Create Mail Folder";
  static CREATE_MAIL_FOLDER_FAIL = "[FOLDERS] Create Mail Folder Fail";
  static CREATE_MAIL_FOLDER_SUCCESS = "[FOLDERS] Create Mail Folder Success";

  static DELETE_MAIL_FOLDER = "[FOLDERS] Delete Mail Folder";
  static DELETE_MAIL_FOLDER_FAIL = "[FOLDERS] Delete Mail Folder Fail";
  static DELETE_MAIL_FOLDER_SUCCESS = "[FOLDERS] Delete Mail Folder Success";

  static UPDATE_MAIL_FOLDER = "[FOLDERS] Update Mail Folder";
  static UPDATE_MAIL_FOLDER_FAIL = "[FOLDERS] Update Mail Folder Fail";
  static UPDATE_MAIL_FOLDER_SUCCESS = "[FOLDERS] Update Mail Folder Success";
  static RESET_MAIL_FOLDER_STATE = "[FOLDERS] Reset State";

}

export class SetSelectedMailFolder implements Action {
  readonly type = MailFolderActionTypes.SELECT_MAIL_FOLDER;
  constructor(public payload: MailFolder) {
  }
}

  export class LoadMailFoldersSuccess implements Action {
    readonly type = MailFolderActionTypes.LOAD_FOLDERS_SUCCESS;
    constructor(public payload: { folders: MailFolder[] }) {}
  }

  export class LoadMailFolders implements Action {
    readonly type = MailFolderActionTypes.LOAD_FOLDERS;
  }

  export class LoadMailFoldersFail implements Action {
    readonly type = MailFolderActionTypes.LOAD_FOLDERS_FAIL;
  }

  export class CreateMailFolder implements Action {
    readonly type = MailFolderActionTypes.CREATE_MAIL_FOLDER;
  }

  export class CreateMailFolderFail implements Action {
    readonly type = MailFolderActionTypes.CREATE_MAIL_FOLDER_FAIL;
  }

  export class CreateMailFolderSuccess implements Action {
    readonly type = MailFolderActionTypes.CREATE_MAIL_FOLDER_SUCCESS;
    constructor(public payload: { folder: MailFolder }) {}
  }

  export class DeleteMailFolder implements Action {
    readonly type = MailFolderActionTypes.DELETE_MAIL_FOLDER;
  }

  export class DeleteMailFolderFail implements Action {
    readonly type = MailFolderActionTypes.DELETE_MAIL_FOLDER_FAIL;
  }

  export class DeleteMailFolderSuccess implements Action {
    readonly type = MailFolderActionTypes.DELETE_MAIL_FOLDER_SUCCESS;
    constructor(public payload: { folder: MailFolder }) {}
  }

  export class UpdateMailFolder implements Action {
    readonly type = MailFolderActionTypes.UPDATE_MAIL_FOLDER;
  }

  export class UpdateMailFolderFail implements Action {
    readonly type = MailFolderActionTypes.UPDATE_MAIL_FOLDER_FAIL;
  }

  export class UpdateMailFolderSuccess implements Action {
    readonly type = MailFolderActionTypes.UPDATE_MAIL_FOLDER_SUCCESS;
    constructor(public payload: Update<MailFolder>) {}
  }

  export class ResetMailFolderState implements Action {
    readonly type = MailFolderActionTypes.RESET_MAIL_FOLDER_STATE;
    constructor() {}
  }
