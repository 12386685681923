
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { EventEmitter } from "@angular/core";

export interface Notification {
    id?: string;
    type: string;
    title?: string;
    content?: string;
    extraData?: any;
    html?: any;
    state?: string;
    createdOn?: Date;
    destroyedOn?: Date;
    animate?: string;
    timeOut?: number;
    maxLength?: number;
    pauseOnHover?: boolean;
    clickToClose?: boolean;
    theClass?: string;
    click?: EventEmitter<{}>;
}

export interface NotificationType {
  name: string;
  color: string;
}

export interface NotificationEvent {
  add?: boolean;
  command: string;
  id?: string;
  notification?: Notification;
}

export interface Options {
  timeOut?: number;
  showProgressBar?: boolean;
  pauseOnHover?: boolean;
  lastOnBottom?: boolean;
  clickToClose?: boolean;
  maxLength?: number;
  maxStack?: number;
  preventDuplicates?: number;
  preventLastDuplicates?: boolean | string;
  theClass?: string;
  rtl?: boolean;
  icons?: string;
  position?: ["top" | "bottom", "right" | "left"];
}
