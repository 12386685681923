import { SQLDBInterface } from "./sql.db.interface";

export class ElectronDB implements SQLDBInterface {
    private sqliteDB: any;

    constructor (dbName: string) {
        const BetterDBElectronWrapper = window.BetterDBElectronWrapper;
        console.log("[ElectronDB][init], BetterDBElectronWrapper: ", BetterDBElectronWrapper);

        if (BetterDBElectronWrapper) {
            try {
                this.sqliteDB = new BetterDBElectronWrapper(dbName);
            } catch (err) {
                console.error(`[ElectronDB][init] Error has occured - ${err}`);
            }
        } else {
            console.error(`[ElectronDB][init] Error has occured, BetterDBElectronWrapper is empty`);
        }
    }

    close(success: (result?: any) => void, error: (err?: any) => void): void {
        this.sqliteDB.close(success, error);
    }

    deleteDatabase(dbName: String, callback: (error?: any) => void): void {
        this.sqliteDB.deleteDatabase(dbName, callback);
    }

    transaction(callback: any, error?: any, success?: any): void {
        this.sqliteDB.transaction(callback, error, success);
    }

    sqlBatch(queries: String[], success: (result?: any) => void, error: (err?: any) => void): void {
        this.sqliteDB.sqlBatch(queries, success, error);
    }

    executeSql(query: String, params: any[], success: (result?: any) => void, error: (err?: any) => void): void {
        this.sqliteDB.executeSql(query, params, success, error);
    }

    readTransaction(callback: (executor: any) => void, success: (result?: any) => void, error: (err?: any) => void): void {
        this.sqliteDB.readTransaction(callback, success, error);
    }
}
