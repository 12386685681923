
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { environment } from "src/environments/environment";
import { MailRootState } from "src/app/mail/store/reducers";


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private store: Store<MailRootState>) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 403) {
                // auto logout if 403 response returned from api
                console.error("[ErrorInterceptor] call logout");
                if (!environment.isCordova && !environment.isElectron) {
                    window.location.href = "/api/call-logout";
                }
            }
            let error = "";
            if (err.error === undefined) {
                error = "";
            } else {
                error = err.error.message || err.error.msg ||  err.statusText;
            }
            console.error("[ErrorInterceptor]", error);
            return throwError(error);
        }));
    }
}
