
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { AppActionTypes } from "../../../actions/app";
import { Action } from "@ngrx/store";
import { Message } from "../../shared/models";
import { Update } from "@ngrx/entity";

export class MessageActionTypes extends AppActionTypes {
    static SELECT_MESSAGE = "[MESSAGES] Select Message";
    static LOAD_MESSAGES = "[MESSAGES] Load Messages";
    static LOAD_MESSAGES_SUCCESS = "[MESSAGES] Load Messages Success";
    static LOAD_MESSAGES_FAIL = "[MESSAGES] Load Messages Fail";

    static DELETE_MAIL_MESSAGE = "[MESSAGES] Delete Mail Message";
    static DELETE_MAIL_MESSAGE_FAIL = "[MESSAGES] Delete Mail Message Fail";
    static DELETE_MAIL_MESSAGE_SUCCESS = "[MESSAGES] Delete Mail Message Success";

    static UPDATE_MAIL_MESSAGE = "[MESSAGES] Update mail Message";
    static UPDATE_MAIL_SUCCESS = "[MESSAGES] Update mail Message Success";
    static UPDATE_MAIL_FAIL = "[MESSAGES] Update mail Message Fail";

    static ADD_MAIL_MESSAGE = "[MESSAGES] Add Mail Message";

    static RESET_MAIL_MESSAGES = "[MESSAGE] Reset Mail Messages";

}

export class LoadMailMessagesSuccess implements Action {
    readonly type = MessageActionTypes.LOAD_MESSAGES_SUCCESS;
    constructor(public payload: Message[]) { }
}

export class LoadMailMessages implements Action {
    readonly type = MessageActionTypes.LOAD_MESSAGES;
}

export class LoadMailMessageFail implements Action {
    readonly type = MessageActionTypes.LOAD_MESSAGES_FAIL;
}

export class DeleteMailMessage implements Action {
    readonly type = MessageActionTypes.DELETE_MAIL_MESSAGE;
}

export class DeleteMailMessageFail implements Action {
    readonly type = MessageActionTypes.DELETE_MAIL_MESSAGE_FAIL;
}

export class UpdateMailMessageSuccess implements Action {
    readonly type = MessageActionTypes.UPDATE_MAIL_SUCCESS;
    constructor(public payload: Update<Message>) { }
}

export class DeleteMailMessageSuccess implements Action {
    readonly type = MessageActionTypes.DELETE_MAIL_MESSAGE_SUCCESS;
    constructor(public payload: Message) { }
}

// export class ResetMessagesAction implements Action {
//     readonly type = MessageActionTypes.RESET_MAIL_MESSAGES;
//     constructor() { }
// }

export class AddMailMessageSuccess implements Action {
    readonly type = MessageActionTypes.ADD_MAIL_MESSAGE;
    constructor(public payload: Message) { }
}
