
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="mdl-dialog__header">
    <button id="closeGenSettingsMenu" class="hide-on-desktop" mdl-button mdl-button-type="icon" (click)="onEsc()">
      <mdl-icon>keyboard_arrow_left</mdl-icon>
    </button>
    <h3 class="mdl-dialog__title header-title">
      {{ 'AUTHENTICATOR_APP'| translate }}
    </h3>
    <button class="show-on-desktop close-dialog-btn" mdl-button-type="icon" mdl-button (click)="onEsc()">
      <mdl-icon>close</mdl-icon>
    </button>
  </div>
  
  <div class="mdl-dialog__content">
    <form [formGroup]="updateForm" (submit)="$event.preventDefault();updateForm.valid && submitForm()">
        <div class="tfa__body__div">
          <div>{{ 'AUTHENTICATOR_APP_TITLE'| translate }}</div>
          <div class="content-div">
              <div><span>1.</span><span class="content-span">{{ 'AUTHENTICATOR_APP_SUB_TITLE1'| translate }}</span></div>
              <div><span>2.</span><span class="content-span">{{ 'AUTHENTICATOR_APP_SUB_TITLE2'| translate }}</span></div>
              <div><img [src]=qrCode/></div>
              <div class="barcode_text margin-left-20 margin-bottom-15" (click)="isManualEnabled ? isManualEnabled=false : isManualEnabled=true">{{ 'CANT_BARCODE_TEXT' | translate }}</div>
	            <div *ngIf="isManualEnabled" class="margin-left-20 margin-top-15">{{ 'QR_CODE_MANUAL_TEXT' | translate }}</div>
              <div *ngIf="isManualEnabled" class="margin-left-20 margin-top-15"><span>{{ 'ACCOUNT_NAME' | translate }}:</span><span class="detail_value">{{ email }}</span></div>
              <div *ngIf="isManualEnabled" class="margin-left-20"><span>{{ 'SECRET_KEY' | translate }}:</span><span class="detail_value">{{ secret }}</span></div>
              <div *ngIf="isManualEnabled" class="margin-left-20 margin-bottom-15"><span>{{ 'TYPE' | translate }}:</span><span class="detail_value">{{ 'OTP_TYPE_VALUE' | translate }}</span></div>
              <div><span>3.</span><span class="content-span">{{ 'AUTHENTICATOR_APP_SUB_TITLE3'| translate }}</span></div>
              <div><input type="text" maxlength="6" required
                placeholder="{{ 'ENTER_OTP'|translate }} (*)" name="otpCode" [formControl]="otpCode"
                id="otpCode"></div>
          </div>
        </div>
    </form>
  </div>
  <div class="form-footer" *ngIf="updateForm.valid && updateForm.dirty">
    <span>{{ 'SAVE_CHANGES'|translate }}</span>
  <button class="save-profile-btn" mdl-button mdl-button-type="mini-fab" mdl-colored="primary" mdl-ripple
          type="submit" [disabled]="!updateForm.valid" (click)="$event.preventDefault();updateForm.valid && submitForm()">
    <mdl-icon>check</mdl-icon>
  </button>
</div>