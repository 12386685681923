
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Output, EventEmitter, NgZone } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { take } from "rxjs/operators";

@Component({
  selector: "vp-apps-dialog",
  templateUrl: "./apps-dialog.html"
})
export class AppsDialogComponent {

  @Output() closeSwitch = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<AppsDialogComponent>,
    private mailBroadcaster: MailBroadcaster,
    private ngZone: NgZone,
  ) {
    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_APP_DIALOG).pipe(take(1)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });
  }

  close(): void {
    this.dialogRef.close();
    this.closeSwitch.emit(false);
  }
}
