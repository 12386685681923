
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

export class MailConstants {
  static MAIL_SERVER_DOWN = "MAIL_SERVER_DOWN";
  static UPDATE_APP_NAME = "UPDATE_APP_NAME";
  static LANGUAGE = "Language";
  static TOKENERROR = "no valid authtoken present";
  static AUTH_CREDENTIALS_HAVE_EXPIRED = "auth credentials have expired";
  static ZM_AUTH_TOKEN = "ZM_AUTH_TOKEN";
  static TOKEN = "token";
  static ZIMBRA_USER = "zimbraUser";
  static LAST_TOKEN_GENERATION_TIME = "lastTokenGenerationTime";
  static LANGUAGE_KEY = "mailLanguage";
  static INBOX_FOLDER_TITLE = "Inbox";
  static DRAFTS_FOLDER_TITLE = "Drafts";
  static SENT_FOLDER_TITLE = "Sent";
  static TRASH_FOLDER_TITLE = "Trash";
  static SPAM_FOLDER_TITLE = "Spam";
  static FORCE_SYNC_DATA = "FORCE_SYNC_DATA";
  static CHATS_FOLDER_TITLE = "Chats";
  static STARRED_FOLDER_TITLE = "Starred";
  static TO_MAIL_LABLE = "TO_MAIL_LABLE";
  static CC_MAIL_LABLE = "CC_MAIL_LABLE";
  static BCC_MAIL_LABLE = "BCC_MAIL_LABLE";
  static SUBJECT_MAIL_LABEL = "SUBJECT_MAIL_LABEL";
  static FROM_MAIL_LABEL = "FROM_MAIL_LABEL";
  static SAVE_DRAFT_MAIL_LABEL = "SAVE_DRAFT_MAIL_LABEL";
  static SEND_MAIL_LABEL = "SEND_MAIL_LABEL";
  static SENT_SUCCESS_MSG = "SENT_SUCCESS_MSG";
  static SAVE_TO_DRAFT_MSG = "SAVE_TO_DRAFT_MSG";
  static SUBJECT_REQUIRE_MSG = "SUBJECT_REQUIRE_MSG";
  static MAIL_INFO_REQUIRE = "MAIL_INFO_REQUIRE";
  static MAIL_REQUIRE = "MAIL_REQUIRE";
  static MAIL_DISCARD_BROADCAST = "MAIL_DISCARD_BROADCAST";
  static MAIL_SAVE_BROADCAST = "MAIL_SAVE_BROADCAST";
  static FOLDER_CREATE_BROADCAST = "FOLDER_CREATE_BROADCAST";
  static TEXT_EDITOR_PLACEHOLDER = "TEXT_EDITOR_PLACEHOLDER";
  static PROFILE_CONTACT_FOLDER_ID = "7";
  static DUPLICATE_FOLDER_MSG: "DUPLICATE_FOLDER_MSG";
  static FOLDER_CREATED_MSG: "FOLDER_CREATED_MSG";
  static FOLDER_RENAMED_MSG: "FOLDER_CREATED_MSG";
  static FOLDER_DELETE_BROADCAST: "FOLDER_DELETE_BROADCAST";
  static FOLDER_TRASH_BROADCAST: "FOLDER_TRASH_BROADCAST";
  static UPDATE_SELECTED_CONVERSATION_MESSAGE: "UPDATE_SELECTED_CONVERSATION_MESSAGE";
  static REFRESH_CONVS_LIST_SYNC: "REFRESH_CONVS_LIST_SYNC";
  static FOLDER_SHARED: "FOLDER_SHARED";
  static ROOT_MAIL_FOLDER_ID = "1";
  static TRASH_MAIL_FOLDER_ID = "3";
  static DEFAULT_COLOR = "#a2aab3";
  static COLOR_LIST = [MailConstants.DEFAULT_COLOR, "blue", "cyan", "green", "purple", "red", "yellow", "pink", "gray", "orange"];
  static SPAM_MAIL_FOLDER_ID = "4";
  static FOLDER_ID = {
    BRIEFCASE: 16,
    CALENDAR: 10,
    CHATS: 14,
    CONTACTS: 7,
    DRAFTS: 6,
    EMAILED_CONTACTS: 13,
    INBOX: 2,
    JUNK: 4,
    SENT: 5,
    TASKS: 15,
    TRASH: 3,
    STARRED: 9999
  };
  static SEARCH_CRITERIA = {
    HAS_ATTACHMENT: "has:attachment",
    IS_FLAGGED: "is:flagged",
    IS_UNREAD: "is:unread",
    IN_INBOX: "in:inbox",
    IN_JUNK: "in:junk",
    IN_TRASH: "in:trash",
    IN_DRAFTS: "in:drafts",
    IN_SENT: "in:sent",
    ATTACHMENT_PDF: "type:application/pdf",
    ATTACHMENT_APPLICATION: "type:application/*",
    ATTACHMENT_HTML: "type:text/html",
    ATTACHMENT_IMAGE: "type:image/*",
    ATTACHMENT_MAIL: "type:message/rfc822",
    ATTACHMENT_TEXT: "type:text",
    SIZE_LARGER: "larger:",
    SIZE_SMALLER: "smaller:",
    SUBJECT: "has:subject",
    TAG: "tag:",
    FOLDER: "is:"
  };
  static CONVERSATIONS_PER_PAGE = 50;
  static FILES_PER_PAGE = 20;
  static EMPTY_FOLDER_ACTION = "EMPTY_FOLDER_ACTION";
  static EMPTY_TRASH_ACTION = "EMPTY_TRASH_ACTION";
  static MAIL_COMPOSE_FORWARD = "Forward";
  static MAIL_COMPOSE_FORWARD_CONVERSATION = "Forward_Conversation";
  static MAIL_COMPOSE_REPLY = "Reply";
  static MAIL_COMPOSE_REPLY_ALL = "ReplyAll";
  static MAIL_COMPOSE_REDIRECT = "Redirect";
  static MAIL_COMPOSE_EDIT_NEW = "EditAsNew";
  static MAIL_DEFAULT_SIGNATURE_PREF_NAME = "zimbraPrefDefaultSignatureId";
  static MAIL_FWD_REPLY_SIGNATURE_PREF_NAME = "zimbraPrefForwardReplySignatureId";
  static DELETE_ATTACHMENT_BROADCAST = "DELETE_ATTACHMENT_BROADCAST";
  static ADD_EMAIL = "ADD_EMAIL";
  static REDIRECT_MAIL_VALIDATION_MSG = "REDIRECT_MAIL_VALIDATION_MSG";
  static ORIGINAL_MSG_LBL = "ORIGINAL_MSG_LBL";
  static FORWARD_MSG_LBL = "FORWARD_MSG_LBL";
  static REPLY_PREFIX_LBL = "Re:";
  static FORWARD_PREFIX_LBL = "Fwd:";
  static ATTACHMENT_NAME = "attachment";
  static SAVE_SEARCH_EXIT = "object with that name already exists";
  static SEARCH_ALREADY_EXIST_MSG = "SEARCH_ALREADY_EXIST_MSG";
  static SHARE_DOCUMENT_BROADCAST = "SHARE_DOCUMENT_BROADCAST";
  static SHARE_IMAGE_BROADCAST = "SHARE_IMAGE_BROADCAST";
  static SENT_FOLDER_NAME = "Sent";
  static SEARCH_BROADCAST = "SEARCH_BROADCAST";
  static SHARE_ITEM_LBL = "SHARE_ITEM_LBL";
  static OWNER_LBL = "OWNER_LBL";
  static ROLE_LBL = "ROLE_LBL";
  static VIEWER_LBL = "VIEWER_LBL";
  static DICLINED_LBL = "DICLINED_LBL";
  static DICLINED_SHARING_OF = "DICLINED_SHARING_OF";
  static HAS_SHARED = "HAS_SHARED";
  static WITH_LBL = "WITH_LBL";
  static HAS_MODIFIED = "HAS_MODIFIED";
  static ACCEPTED_LBL = "ACCEPTED_LBL";
  static HAS_ACCEPTED_SHARING = "HAS_ACCEPTED_SHARING";
  static EXPAND_FOLDER = "EXPAND_FOLDER";
  static SELECTED_FOLDER_ABSPATH = "selectedFolderAbsPath";
  static MAIL_LANGUAGE = "mailLanguage";
  static SERVER_URL = "serverURL";
  static WRONG_SERVER_URL_ERR_MSG = "WRONG_SERVER_URL_ERR_MSG";
  static TOOLTIP_COMPOSE = "tooltipCompose";
  static MAIL_FOLDER_LBL = "MAIL_FOLDER_LBL";
  static CONTACT_GROUP_LBL = "CONTACT_GROUP_LBL";
  static TASK_LIST_LBL = "TASK_LIST_LBL";
  static CALENDAR_LBL = "CALENDAR_LBL";
  static AVTAR_REMOVED_MSG = "AVTAR_REMOVED_MSG";
  static AVTAR_UPLOADED_MSG = "AVTAR_UPLOADED_MSG";
  static FAIL_AVTAR_UPLOADED_MSG = "FAIL_AVTAR_UPLOADED_MSG";
  static FAIL_AVTAR_REMOVE_MSG = "FAIL_AVTAR_REMOVE_MSG";
  static CLOSE_MAIL_DETAIL = "closeMailDetail";
  static REFRESH_BROADCAST = "REFRESH_BROADCAST";
  static SEARCH_IMAGES_BROADCAST = "SEARCH_IMAGES_BROADCAST";
  static SEARCH_DOCUMENT_BROADCAST = "SEARCH_DOCUMENT_BROADCAST";
  static SEARCH_SAVED_BROADCAST = "SEARCH_SAVED_BROADCAST";
  static UPDATE_UNREAD_COUNT = "UPDATE_UNREAD_COUNT";
  static UPDATE_SEARCH_HEIGHT = "UPDATE_SEARCH_HEIGHT";
  static IS_LIST_VIEW = "IS_LIST_VIEW";
  static ATTACHMENT_EXCEED_LIMIT_MSG = "ATTACHMENT_EXCEED_LIMIT_MSG";
  static SAVED_SEARCH_ROUTE = "SAVED_SEARCH_ROUTE";
  static BROADCAST_MOVE = "BROADCAST_MOVE";
  static BROADCAST_UNDO_MOVE = "BROADCAST_UNDO_MOVE";
  static BROADCAST_SAVE_SEARCH = "BROADCAST_SAVE_SEARCH";
  static BROADCAST_MAIL_SELECTED_TAB = "BROADCAST_MAIL_SELECTED_TAB";
  static OPEN_SHARED_FOLDER = "OPEN_SHARED_FOLDER";
  static BROADCAST_OVERFLOW_REDIRECT_DIALOG = "BROADCAST_OVERFLOW_REDIRECT_DIALOG";
  static UPDATE_TARGET_FOLDER = "UPDATE_TARGET_FOLDER";
  static UPDATE_SELECTED_CONVERSATION = "UPDATE_SELECTED_CONVERSATION";
  static UPDATE_SELECTED_MESSAGE = "UPDATE_SELECTED_MESSAGE";
  static CLOSE_AUTO_COMPLETE = "CLOSE_AUTO_COMPLETE";
  static STAR_UNSTAR_MAIL = "STAR_UNSTAR";
  static STAR_UNSTAR_MESSAGE = "STAR_UNSTAR_MESSAGE";
  static INVITE_ACCEPTED = "INVITE_ACCEPTED";
  static INVITE_TENTATIVE = "INVITE_TENTATIVE";
  static INVITE_DECLINE = "INVITE_DECLINE";
  static BOLD_LBL = "BOLD_LBL";
  static ITALIC_LBL = "ITALIC_LBL";
  static UNDERLINE_LBL = "UNDERLINE_LBL";
  static STRIKE_LBL = "STRIKE_LBL";
  static TEXT_COLOR_LBL = "TEXT_COLOR_LBL";
  static BACKGROUND_COLOR_LBL = "BACKGROUND_COLOR_LBL";
  static BULLETS_LIST_LBL = "BULLETS_LIST_LBL";
  static NUMBERED_LIST_LBL = "NUMBERED_LIST_LBL";
  static LINK_UNLINK_LBL = "LINK_UNLINK_LBL";
  static INLINE_IMAGE_LBL = "INLINE_IMAGE_LBL";
  static TAG_CREATED_LBL = "TAG_CREATED_LBL";
  static TAG_REMOVE_LBL = "TAG_REMOVE_LBL";
  static TAG_NAME_RENAME_LBL = "TAG_NAME_RENAME_LBL";
  static COMING_SOON_LBL = "COMING_SOON_LBL";
  static READ_RECEIPT_SENT = "READ_RECEIPT_SENT";
  static EMAIL_TEMPLATE = "Email Vorlagen";
  static THEME = "theme";
  static ZIMBRA_TYPE = "zimbra";
  static DIRECTORY_TYPE = "directory";
  static BLANK_FIELD = "BLANK_FIELD";
  static CURRENT_PASSWORD_WRONG = "CURRENT_PASSWORD_WRONG";
  static MATCH_PASSWORD_ERROR = "MATCH_PASSWORD_ERROR";
  static PASSWORD_CHANGE_SUCCESS = "PASSWORD_CHANGE_SUCCESS";
  static PASSWORD_CHANGE_FEATURE_NOT_ENABLE = "PASSWORD_CHANGE_FEATURE_NOT_ENABLE";
  static MULTIPLE_SELECTION_FORWARD = "MULTIPLE_SELECTION_FORWARD";
  static TEMPLATE_MAIL = "TEMPLATE_MAIL";
  static SAVE_TO_EMAIL_TEMPLATE_MSG = "SAVE_TO_EMAIL_TEMPLATE_MSG";
  static HISTORY_ZIMLET_AVAILABLE = "HISTORY_ZIMLET_AVAILABLE";
  static CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
  static QUICK_REPLY_AND_REPLY_ALL = "QUICK_REPLY_AND_REPLY_ALL";
  static EDIT_EMAIL_TEMPLATE = "EDIT_EMAIL_TEMPLATE";
  static DFB = "dfb";
  static DEFAULT = "default";
  static DFB_BLUE = "dfb_blue";
  static ZIMBRA_THEME_DFB = "dfb";
  static ZIMBRA_THEME_HARMONY = "harmony";
  static SYSTEM_FOLDERS = ["INBOX_FOLDER", "SENT_FOLDER", "DRAFTS_FOLDER", "STARRED_FOLDER", "SPAM_FOLDER", "TRASH_FOLDER",
  "BRIEFCASE_FOLDER"];
  static REMOVED_TAG = "REMOVED_TAG";
  static MOVE_MAIL_TIMEOUT = 2000;
  static UNDO_MAIL_TIMEOUT = 2000;
  static FONT_FAMILY_LBL = "FONT_FAMILY_LBL";
  static BLOCK_QUOTE_LBL = "BLOCK_QUOTE_LBL";
  static BLOCK_CODE_LBL = "BLOCK_CODE_LBL";
  static HEADER_1_LBL = "HEADER_1_LBL";
  static HEADER_2_LBL = "HEADER_2_LBL";
  static SUB_LBL = "SUB_LBL";
  static SUPER_LBL = "SUPER_LBL";
  static DECREASE_INDENT_LBL = "DECREASE_INDENT_LBL";
  static INCREASE_INDENT_LBL = "INCREASE_INDENT_LBL";
  static CLEAN_FORMATTING = "CLEAN_FORMATTING";
  static CLOSE_COMPOSE = "CLOSE_NEW_COMPOSE";
  static SORT_ORDER = "2:SORT_ORDER,BDLV:,CAL:,CLV:,CLV-SR-1:dateDesc,CLV-main:SORT_ORDER,CNS:,CNSRC:,CNTGT:,CV:,TKL:,TV:,TV-main:sizeDesc";
  static UPDATE_THEME_DFB = "UPDATE_THEME_DFB";
  static TIME_TO_REFRESH_ONE_PAGE = 1 * 60 * 1000; // 1 min
  static TIME_TO_REFRESH_ALL = 3 * 60 * 1000; // 3 mins
  static TIME_TO_REFRESH_TOKEN = 60 * 60 * 1000; // 60 mins
  static BRIEFCASE_DOCUMENT_PER_PAGE = 20;
  static MIN_POLLING_INTERVAL = 30000;
  static TIME_TO_SAVE_LOG = 1; // 1 s
  static CALL_NO_OP_REQUEST = "CALL_NO_OP_REQUEST";
  static FOLDERS_PER_PAGE = 20;
  static MIN_INTERVAL = 10; // 10 s
  static INCLUDE_SHARED_ITEM = "includeSharedItems";
  static UPLOAD_FILE_PERMISSION_MSG = "UPLOAD_FILE_PERMISSION_MSG";
  static INVALID_NAME_ERROR_MSG = "INVALID_NAME_ERROR_MSG";
  static BRIEFCASE_FOLDERS_LBL = "BRIEFCASE_FOLDERS_LBL";
  static INVALID_CHARACTERS_IN_FILENAME = "INVALID_CHARACTERS_IN_FILENAME";
  static CANCELED_LBL = "Cancelled";
  static CALCEL_MEETING_LBL = "The following meeting has been cancelled:";
  static  PROPOSED_NEW_TIME = "New Time Proposed";
  static DEFAULT_TAG_COLOR = "#20ae80";
  static SORT_CONTACTS_OPTION = "sortContactsOption";
  static ALLOWED_ACTIONS = "ALLOWED_ACTIONS";
  static MOVE_MAIL_SHORTCUT = "MOVE_MAIL_SHORTCUT";
  static READING_PANE_SASH_VERTICAL = "ReadingPaneSashVertical";
  static READING_PANE_SASH_HORIZONTAL = "ReadingPaneSashHorizontal";
  static FOLDER_TREE_SASH = "FolderTreeSash";
  static SENTRY_DSN = "https://db5574e723da4e969f9096c5ba6db2e7@sentry3.vnc.biz/2";
  static NOT_LOAD_INSECURE_CONTENT = "notLoadInsecureContent";
  static SHOW_GLOBAL_TAGS = "showGlobalTags";
  static SET_SOLR_COUNT = "SET_SOLR_COUNT";
  static ADD_TO_AUTOCOMPLETE_FIELD = "ADD_TO_AUTOCOMPLETE_FIELD";
  static MAX_MAIL_TAB = 5;
  static CALENDAR = "calendar";
  static BRIEFCASE = "briefcase";
  static PREFERENCES = "preferences";
  static MAIL_DETAIL_ROUTE = "detail";
  static SEARCH_ADVANCE_SEARCH = "search_advance_search";

}
