
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { IM } from "./im.model";
import { Other } from "./other.model";
import { UserURL } from "./userurl.model";
import { Address } from "./address.model";
import { Phone } from "./phone.model";
import { ContactRequest } from "./contact-request";

export class Contact {
  id: string;
  parentId: string;
  fullName: string;
  firstName: string;
  lastName: string;
  nickname: string;
  jobTitle: string;
  company: string;
  image: string;
  email: string[];
  phone: Phone[];
  address: Address[];
  userURL: UserURL[];
  notes: string;
  other: Other[];
  tags: string[];
  im: IM[];
  isSelected: boolean = false;
  isChecked: boolean = false;
  firstLastCharacters: string;
  isFavourite: boolean = false;
  isHavingFirstCharacter = false;
  imageData: any;
  isOfflineCreate: boolean = false;
  isOfflineModify: boolean = false;
  offlineRequest: ContactRequest = null;
  type: string;
  m: any[];
  contact_list_ids?: any[];
  name?: string;
  middleName?: string;
  is_global?: boolean;
}

export class Distribution {
  id: string;
  d?: string;
  name: string;
}
