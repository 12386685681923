
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component, Input, TemplateRef, EventEmitter, Output,
  ElementRef, ViewChild, OnInit, OnDestroy
} from "@angular/core";
import { WeekDay } from "calendar-utils";
import { trackByWeekDayHeaderDate } from "../common/util";
import { BreakpointObserver } from "@angular/cdk/layout";
import { ConfigService } from "src/app/config.service";

@Component({
  selector: "vp-calendar-month-view-header",
  template: `
    <ng-template
      #defaultTemplate
      let-days="days"
      let-locale="locale"
      let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate">
      <div id="calMonthHeader" class="cal-cell-row cal-header">
      <div class="cal-cell week-view-header" *ngIf="showCalendarWeekNumber">{{ 'CALENDARS.MONTH_WEEK_LBL' | translate}}</div>
        <div
          class="cal-cell"
          *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
          [class.cal-past]="day.isPast"
          [class.cal-today]="day.isToday"
          [class.cal-future]="day.isFuture"
          [class.cal-weekend]="day.isWeekend"
          (click)="columnHeaderClicked.emit(day.day)"
          [ngClass]="day.cssClass"
        >
          {{ day.date | vpCalendarDate: isMobileScreen
            ? "monthViewColumnHeaderInMobile"
            : "monthViewColumnHeader" :locale }}
        </div>
      </div>
    </ng-template>
    <ng-template
      [ngTemplateOutlet]="customTemplate || defaultTemplate"
      [ngTemplateOutletContext]="{
        days: days,
        locale: locale,
        trackByWeekDayHeaderDate: trackByWeekDayHeaderDate
      }"
    >
    </ng-template>
  `
})
export class CalendarMonthViewHeaderComponent implements OnInit, OnDestroy {
  @Input() days: WeekDay[];
  @Input() locale: string;
  @Input() customTemplate: TemplateRef<any>;
  @Output() columnHeaderClicked = new EventEmitter<number>();
  trackByWeekDayHeaderDate = trackByWeekDayHeaderDate;
  isMobileScreen: boolean = false;
  showCalendarWeekNumber: boolean = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private configService: ConfigService
  ) {
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
    this.showCalendarWeekNumber = this.isShowWeekNumberEnabled();
  }

  ngOnInit() { }

  ngOnDestroy() { }

  isShowWeekNumberEnabled(): boolean {
    let showCalendarWeek: boolean = false;
    const showWeek = this.configService.prefs.zimbraPrefShowCalendarWeek;
    if (!!showWeek && showWeek === "TRUE") {
      showCalendarWeek = true;
    }
    return showCalendarWeek;
  }

}
