<div class="scheduler-component">
    <div id="{{htmlElId}}" class="ZmFreeBusySchedulerView" tabindex="0"
        style="position: relative; overflow: hidden auto; display: block;">
        <div class="ZmScheduler">
            <!-- free/busy section -->
            <table role="presentation" width="100%" id="{{htmlElId}}_attendeesTable">
                <colgroup>
                    <col style="width:250px">
                    <col style="width:710px">
                </colgroup>
                <tbody>
                    <tr>
                        <td id="{{htmlElId}}_navToolbar">&nbsp;</td>
                        <td valign="bottom">
                            <table role="presentation" width="100%" class="ZmSchedulerGridHeaderTable" style="">
                                <tbody>
                                    <tr>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">12</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">1</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">2</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">3</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">4</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">5</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">6</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">7</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">8</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">9</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">10</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">11</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">12</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">1</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">2</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">3</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">4</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">5</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">6</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">7</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">8</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">9</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">10</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">11</div>
                                        </td>
                                        <td width="2%">
                                            <div class="ZmSchedulerGridHeaderCell">12</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>

                    <tr *ngFor="let attendeeRow of attendeeRows">
                        <td>
                            <ng-container [ngTemplateOutlet]="attendeeName" [ngTemplateOutletContext]="{data: attendeeRow.data, sched: attendeeRow.sched}"></ng-container>

                        </td>
                        <td style="padding: 0px;">
                            <div >
                                <ng-container [ngTemplateOutlet]="attendeeFreeBusy" [ngTemplateOutletContext]="{data: attendeeRow.data, sched: attendeeRow.sched}"></ng-container>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table role="presentation" class="ZPropertySheet" cellspacing="6">
                <tbody>
                    <tr>
                        <td id="{{htmlElId}}_graphKeySpacer" style="width: 473px;"><span id="{{htmlElId}}_showMoreLink"
                                class="FakeAnchor" style="display:none;">0 more attendee</span></td>
                        <td>
                            <div class="ZmGraphKeyColorBox ZmScheduler-working"></div>
                        </td>
                        <td class="ZmGraphKeyColorText" style="padding-right:1em;">{{ 'STATUS_WORKING' | translate}}</td>
                        <td>
                            <div class="ZmGraphKeyColorBox ZmScheduler-free"></div>
                        </td>
                        <td class="ZmGraphKeyColorText" style="padding-right:1em;">{{ 'STATUS_FREE' | translate}}</td>
                        <td>
                            <div class="ZmGraphKeyColorBox ZmScheduler-busy"></div>
                        </td>
                        <td class="ZmGraphKeyColorText" style="padding-right:1em;">{{ 'STATUS_BUSY' | translate}}</td>
                        <td>
                            <div class="ZmGraphKeyColorBox ZmScheduler-tentative"></div>
                        </td>
                        <td class="ZmGraphKeyColorText" style="padding-right:1em;">{{ 'STATUS_TENTATIVE' | translate}}</td>
                        <td>
                            <div class="ZmGraphKeyColorBox ZmScheduler-unknown"></div>
                        </td>
                        <td class="ZmGraphKeyColorText" style="padding-right:1em;">{{ 'STATUS_UNKNOWN' | translate}}</td>
                        <td>
                            <div class="ZmGraphKeyColorBox ZmScheduler-outOfOffice"></div>
                        </td>
                        <td class="ZmGraphKeyColorText" style="padding-right:1em;">{{ 'OUT_OF_OFFICE' | translate}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- <div *ngFor="let attendeeRow of attendeeRows">
        <ng-container [ngTemplateOutlet]="attendeeFreeBusy" [ngTemplateOutletContext]="{data: attendeeRow.data, sched: attendeeRow.sched}"></ng-container>
    </div> -->

</div>


<ng-template #attendeeName let-data="data" let-sched="sched">
    <table role="presentation" style="width: 100%">
        <tr>
            <td *ngIf="data.isAllAttendees" class="ZmSchedulerAllLabel">{{ 'ALL_ATTENDEES' | translate }}</td>
            <ng-container *ngIf="!data.isAllAttendees">
                <td class="{{ !data.organizer ? 'ZmSchedulerNameTd' : 'ZmSchedulerInputDisabledTd' }}"
                    id="{{sched.dwtNameId}}">

                    <div *ngIf="data.organizer" class="ZmSchedulerInputDisabled">
                        <mat-icon class="icon-type" *ngIf="attendeeTypes[data.organizer] !== 'LOCATION' && attendeeTypes[data.organizer] !== 'EQUIPMENT'">group</mat-icon>
                        <mat-icon class="icon-type" *ngIf="attendeeTypes[data.organizer] === 'LOCATION'">room</mat-icon>
                        <mat-icon class="icon-type" *ngIf="attendeeTypes[data.organizer] === 'EQUIPMENT'">tv</mat-icon>
                        <div class="attendee-email">{{ data.organizer }}</div>
                    </div>
                    <div *ngIf="!data.organizer" class="DwtInputField" parentid="DWT281"
                        style="position: static; overflow: visible; display: inline;"><input type="text"
                            autocomplete="off" maxlength="256" tabindex="0" class="ZmSchedulerInput" id="DWT295_INPUT_"
                            style="height: 2rem; border: 0px;"></div>
                </td>
            </ng-container>
        </tr>
    </table>
</ng-template>

<ng-template #attendeeFreeBusy let-sched="sched" let-data="data">
    <table id="{{sched.dwtTableId}}" class="ZmSchedulerGridTable{{data.isAllAttendees ? '-allAttendees' : '' }}"
        style="width: 100%">
        <tr>
            <td *ngFor="let e of [].constructor(data.cellCount); let i = index" class='ZmScheduler-free'
                style="width: 2%">
                <div *ngIf="data.isAllAttendees" id="{{sched.dwtTableId}}_{{i}}" class="{{getClassName(data, i)}}">
                </div>
                <div *ngIf="!data.isAllAttendees" matTooltip="{{ 'STATUS' | translate }}: {{ (sched._labelCells[i] || 'STATUS_FREE') | translate }}" id="{{sched.dwtTableId}}_{{i}}" class="{{getClassName(data, i)}}">
                </div>
            </td>
        </tr>
    </table>
</ng-template>
