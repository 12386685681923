
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, HostListener, ChangeDetectionStrategy, ChangeDetectorRef, Inject, OnDestroy, NgZone } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subject } from "rxjs/internal/Subject";
import { TranslateService } from "@ngx-translate/core";
import { MailOperations } from "../../common/enums/mail-enum";
import { CommonUtils } from "../../common/utils/common-util";
import { ColorControlDialogComponent } from "../color-control-dialog/color-control-dialog.component";
import { MailBroadcaster } from "../../common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "../../common/enums/broadcast.enum";
import { takeUntil } from "rxjs/operators";
import { PreferenceService } from "../../preference/shared/services/preference.service";
import { DataSource } from "../../preference/shared/models";
import { Store } from "@ngrx/store";
import { MailRootState } from "../store";
import { getAvailableZimlets, getZimbraFeatures } from "../../reducers";
import { MailUtils } from "../utils/mail-utils";
import { MailConstants } from "../../common/utils/mail-constants";
import { ZimbraFeatures } from "src/app/common/utils/zimbra-features";
import { CommonRepository } from "../repositories/common-repository";

@Component({
  selector: "vp-mail-folder-operation-dialog",
  templateUrl: "./mail-folder-operation-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class MailFolderOprationDialogComponent implements OnDestroy {

  private isAlive$ = new Subject<boolean>();
  mailOperations = MailOperations;
  iscontextMenuActionsDisabled: boolean = true;
  isEmailFormsZimletEnabled: boolean = false;
  isSharingFeatureEnabled: boolean = true;
  contextMenuActions = {
    "isEnableNewFolder": true,
    "isEnableMarkRead": true,
    "isEnableShare": true,
    "isEnableMove": true,
    "isEnableRename": true,
    "isEnableDelete": true,
    "isEnableEmpty": true,
    "isEnableEmptyTrash": true,
    "isEnableColorControl": true,
    "isEnableExternalSync": false,
    "isEditFolder": true,
  };

  constructor(
    private dialogRef: MatDialogRef<MailFolderOprationDialogComponent>,
    private changeDetection: ChangeDetectorRef,
    private matDialog: MatDialog,
    private translate: TranslateService,
    private ngZone: NgZone,
    private mailBroadcaster: MailBroadcaster,
    @Inject(MAT_DIALOG_DATA) public folderData: any,
    private commonRepository: CommonRepository,
    private preferenceService: PreferenceService,
    private store: Store<MailRootState>
  ) {
    this.store.select(getAvailableZimlets).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      if (res && res.length > 0) {
        this.isEmailFormsZimletEnabled = MailUtils.isZimletEnabledOrMendatory(res, "biz_vnc_email_forms");
        this.changeDetection.markForCheck();
      }
    });
    this.setActions();
    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_FOLDER_OPERATION_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });
    if (CommonUtils.isOnIOS()) {
      setTimeout(() => {
        this.iscontextMenuActionsDisabled = false;
        this.changeDetection.markForCheck();
      }, 1000);
    } else {
      this.iscontextMenuActionsDisabled = false;
    }
    this.store.select(getZimbraFeatures).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.isSharingFeatureEnabled = MailUtils.isZimbraFeatureEnabled(res, ZimbraFeatures.ZIMBRA_FEATURE_SHARING_ENABLED);
      this.changeDetection.markForCheck();
    });
  }

  public hideDialog(): void {
    this.dialogRef.close({ targetFolder: null, operationType: MailOperations.CloseOperationDialog});
  }

  folderOpration(operationType: any) {
    console.log("[MailFolderOprationDialogComponent][folderOpration]", operationType);

    if (this.commonRepository.showNoInternetToastIfRequired()) {
      if (operationType !== MailOperations.CloseOperationDialog) {
        return;
      }
    }

    if (operationType !== MailOperations.ChangeColor) {
      this.dialogRef.close({ targetFolder: this.folderData.targetFolder, operationType: operationType});
    } else {
      const folderColor = (this.folderData.targetFolder.rgb !== undefined) ? this.folderData.targetFolder.rgb : null;
      const changeColorDialogRef = this.matDialog.open(ColorControlDialogComponent, {
        height: "auto",
        maxHeight: "70%",
        width: "99%",
        maxWidth: "244px",
        autoFocus: false,
        panelClass: "vp-color-control-dialog",
        data: { folderColor : folderColor }
      });
      changeColorDialogRef.afterClosed().subscribe(operation => {
        if (!!operation && operation !== null && operation.selectedColor) {
          this.dialogRef.close({ targetFolder: this.folderData.targetFolder, operationType: operationType,
            selectedColor: operation.selectedColor });
        }
      });
    }
  }

  setActions() {
    this.contextMenuActions.isEditFolder = true;
    if (this.folderData.targetFolder.perm && this.folderData.targetFolder.perm === "r") {
      this.contextMenuActions.isEnableNewFolder = false;
      this.contextMenuActions.isEnableEmpty = false;
      this.contextMenuActions.isEnableMarkRead = false;
      this.contextMenuActions.isEnableShare = false;
      this.contextMenuActions.isEnableMove = false;
      this.contextMenuActions.isEnableDelete = false;
      this.contextMenuActions.isEnableRename = false;
      this.contextMenuActions.isEnableColorControl = false;
      this.changeDetection.markForCheck();
      return ;
    }
    this.contextMenuActions.isEnableNewFolder = true;
    this.contextMenuActions.isEnableShare = true;
    this.contextMenuActions.isEnableDelete = true;
    this.contextMenuActions.isEnableRename = true;
    this.contextMenuActions.isEnableColorControl = true;
    if (this.folderData.targetFolder.u && this.folderData.targetFolder.u !== "0") {
      this.contextMenuActions.isEnableMarkRead = true;
    } else {
      this.contextMenuActions.isEnableMarkRead = false;
    }
    if (this.folderData.targetFolder.children || (this.folderData.targetFolder.n && this.folderData.targetFolder.n !== "0")) {
      this.contextMenuActions.isEnableEmpty = true;
    } else {
      this.contextMenuActions.isEnableEmpty = false;
    }
    this.setExternalAccountValidation();
    if (this.isEmailFormsZimletEnabled && this.folderData.targetFolder.name === MailConstants.EMAIL_TEMPLATE) {
      this.contextMenuActions.isEnableMove = false;
      this.contextMenuActions.isEnableDelete = false;
    }
  }

  close(): void {
    this.dialogRef.close({ targetFolder: this.folderData.targetFolder});
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  setExternalAccountValidation(): void {
    const node = this.folderData.targetFolder;
    const externalAccount = this.getExternalAccount(node);
    switch (node.name) {
      case MailConstants.INBOX_FOLDER_TITLE:
      case MailConstants.SENT_FOLDER_TITLE:
          if (node.id === "2" || node.id === "7") {
              this.resetAction(true, true, true, true, false, false, false);
          } else {
              this.resetAction(true, true, true, false, false, false, false);
          }
          break;
      case MailConstants.DRAFTS_FOLDER_TITLE:
          if (node.id === "6") {
              this.resetAction(false, false, false, true, false, false, false);
          } else {
              this.resetAction(true, true, true, true, true, true, true);
          }
          break;
      case MailConstants.TRASH_FOLDER_TITLE:
          if (node.id === "3") {
              this.contextMenuActions.isEditFolder = false;
              this.resetAction(true, true, true, false, false, false, false);
          } else {
              this.resetAction(true, true, true, true, true, true, true);
          }
          break;
      case MailConstants.SPAM_FOLDER_TITLE:
          if (node.id === "4") {
              this.contextMenuActions.isEditFolder = false;
              this.resetAction(false, false, false, false, false, false, false);
          } else {
              this.contextMenuActions.isEnableEmptyTrash = false;
              this.resetAction(true, true, true, true, true, true, true);
          }
          break;
      case MailConstants.CHATS_FOLDER_TITLE:
          this.contextMenuActions.isEditFolder = false;
          this.resetAction(true, true, node.n && node.n > 0, true, false, false, false);
          break;
      case MailConstants.STARRED_FOLDER_TITLE:
          this.contextMenuActions.isEditFolder = false;
          this.resetAction(false, true, false, false, false, false, false);
          break;
      default:
          if ( externalAccount !== null && externalAccount !== undefined ) {
              if (externalAccount.l.toString() === node.id.toString() ) {
                  this.resetAction(true, true, true, true, false, false, false, true);
              } else if ( externalAccount.l.toString() === node.id.toString() && node.f ) {
                  this.resetAction(true, false, false, false, false, false, false, true);
              } else {
                  this.resetAction(true, true, true, true, true, true, true);
              }
          } else {
              this.resetAction(true, true, true, true, true, true, true);
          }
          break;
    }
    if ( node.f && node.f.indexOf("y") !== -1 && node.name.toLowerCase() === "inbox" ) {
      this.resetAction(true, true, false, true, false, false, false);
    } else if ( node.f && node.f.indexOf("u") !== -1 && node.name.toLowerCase() === "chats" ) {
      this.resetAction(false, true, true, false, false, false, false);
    } else if ( node.f && node.f.indexOf("y") !== -1 && node.name.toLowerCase() === "drafts" ) {
      this.resetAction(false, false, false, true, false, false, false);
    } else if ( node.f && node.f.indexOf("y") !== -1 && node.name.toLowerCase() === "junk" ) {
      this.resetAction(false, true, true, false, false, false, false);
    } else if ( node.f && node.f.indexOf("y") !== -1 && node.name.toLowerCase() === "sent" ) {
      this.resetAction(true, true, false, true, false, false, false);
    }

  }

  getExternalAccount(node: any): DataSource {
    if (this.preferenceService.dataSource$ !== undefined && this.preferenceService.dataSource$.length > 0 ) {
        return this.preferenceService.dataSource$.filter(source => source.l.toString() === node.id.toString())[0];
    }
    return null;
  }

  resetAction ( isEnableNewFolder, isEnableMarkRead, isEnableEmpty, isEnableShare ,
    isEnableMove, isEnableDelete, isEnableRename, isEnableExternalSync? ) {
      this.contextMenuActions.isEnableExternalSync = false;
      this.contextMenuActions.isEnableNewFolder = isEnableNewFolder;
      this.contextMenuActions.isEnableMarkRead = isEnableMarkRead;
      this.contextMenuActions.isEnableEmpty = isEnableEmpty;
      this.contextMenuActions.isEnableShare = isEnableShare;
      this.contextMenuActions.isEnableMove = isEnableMove;
      this.contextMenuActions.isEnableDelete = isEnableDelete;
      this.contextMenuActions.isEnableRename = isEnableRename;
      this.contextMenuActions.isEnableColorControl = false;
      if ( isEnableExternalSync === true ) {
        this.contextMenuActions.isEnableExternalSync = true;
      }
  }
}
