
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Message } from "../../shared/models";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Action } from "../../../actions";
import { MessageActionTypes } from "../actions";

export interface MailMessageState extends EntityState<Message> {
    selectedMailMessage: Message;
}

export const mailMessageAdapter: EntityAdapter<Message> = createEntityAdapter<Message>({
});

export const initialState: MailMessageState = mailMessageAdapter.getInitialState({
    selectedMailMessage: null
});

export function mailMessageReducer(state = initialState, action: Action): MailMessageState {
    switch (action.type) {
        case MessageActionTypes.SELECT_MESSAGE: {
            return {
                ...state,
                selectedMailMessage: action.payload
            };
        }

        case MessageActionTypes.LOAD_MESSAGES: {
            return {
                ...state
            };
        }

        case MessageActionTypes.LOAD_MESSAGES_SUCCESS: {
            const newState = {
                ...state
            };
            return mailMessageAdapter.addAll(action.payload, newState);
        }

        case MessageActionTypes.LOAD_MESSAGES_FAIL: {
            return {
                ...state
            };
        }

        case MessageActionTypes.DELETE_MAIL_MESSAGE: {
            return {
                ...state
            };
        }

        case MessageActionTypes.UPDATE_MAIL_SUCCESS: {
            return mailMessageAdapter.updateOne(action.payload, {
                ...state
            });
        }

        case MessageActionTypes.DELETE_MAIL_MESSAGE_SUCCESS: {
            return mailMessageAdapter.removeOne(action.payload.id, {
                ...state
            });
        }

        case MessageActionTypes.DELETE_MAIL_MESSAGE_FAIL: {
            return {
                ...state
            };
        }

        case MessageActionTypes.RESET_MAIL_MESSAGES: {
            return mailMessageAdapter.removeAll(state);
        }

        case MessageActionTypes.ADD_MAIL_MESSAGE: {
            const newState = {
                ...state
            };
            return mailMessageAdapter.addOne(action.payload, newState);
        }

        default: {
            return state;
        }
    }
}

export const _getSelectedMessage = (state: MailMessageState) => state.selectedMailMessage;
