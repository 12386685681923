
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component, Input, Output, EventEmitter, ChangeDetectorRef,
  OnChanges, OnInit, OnDestroy, LOCALE_ID, Inject, TemplateRef,
  ChangeDetectionStrategy, ElementRef, ViewChild, AfterViewInit,
  HostListener
} from "@angular/core";
import { Subject, Subscription, takeUntil, timer } from "rxjs";
import {
  WeekDay, CalendarEvent, WeekViewAllDayEvent as WeekViewAllDayAppointment,
  WeekView, ViewPeriod, WeekViewHourColumn,
  DayViewEvent, DayViewHourSegment, DayViewHour, WeekViewAllDayEventRow as WeekViewAllDayAppointmentRow
} from "calendar-utils";
import { ResizeEvent } from "angular-resizable-element";
import { CalendarDragHelper } from "../common/calendar-drag-helper.provider";
import { CalendarResizeHelper } from "../common/calendar-resize-helper.provider";
import { CalendarAppointmentTimesChangedEvent, CalendarAppointmentTimesChangedEventType } from "../common/calendar-appointment-times-changed-event.interface";
import { CalendarUtils } from "../common/calendar-utils.provider";
import {
  validateEvents, roundToNearest, trackByWeekDayHeaderDate,
  trackByHourSegment, trackByHour, getMinutesMoved,
  getDefaultEventEnd, getMinimumEventHeightInMinutes, addDaysWithExclusions,
  trackByDayOrWeekEvent, isDraggedWithinPeriod, shouldFireDroppedEvent,
  getWeekViewPeriod
} from "../common/util";
import { DateAdapter } from "../date-adapters/date-adapter";
import { DragEndEvent, DropEvent, DragMoveEvent, ValidateDrag } from "angular-draggable-droppable";
import { PlacementArray } from "positioning";
import { CalendarRepository } from "../../repositories/calendar.repository";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { CalenderUtils } from "../../utils/calender-utils";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import * as moment from "moment";

export interface WeekViewAllDayEventResize {
  originalOffset: number;
  originalSpan: number;
  edge: string;
}

export interface CalendarWeekViewBeforeRenderEvent extends WeekView {
  header: WeekDay[];
}

@Component({
  selector: "vp-calendar-week-view",
  templateUrl: "./calendar-week-view.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarWeekViewComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {

  constructor(
    protected cdr: ChangeDetectorRef,
    protected utils: CalendarUtils,
    @Inject(LOCALE_ID) locale: string,
    protected dateAdapter: DateAdapter,
    private broadcaster: MailBroadcaster,
    private breakpointObserver: BreakpointObserver,
    public calendarRepository: CalendarRepository
  ) {
    this.locale = locale;
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
    this.hourSegmentHeight = this.isMobileScreen ? 42 : 20;
    this.snapDraggedEvents = false;
  }
  @Input() viewDate: Date;
  @Input() events: CalendarEvent[] = [];
  @Input() excludeDays: number[] = [];
  @Input() refresh: Subject<any>;
  @Input() locale: string;
  @Input() tooltipPlacement: PlacementArray = "auto";
  @Input() tooltipTemplate: TemplateRef<any>;
  @Input() tooltipAppendToBody: boolean = true;
  @Input() tooltipDelay: number | null = null;
  @Input() weekStartsOn: number;
  @Input() headerTemplate: TemplateRef<any>;
  @Input() eventTemplate: TemplateRef<any>;
  @Input() eventTitleTemplate: TemplateRef<any>;
  @Input() eventActionsTemplate: TemplateRef<any>;
  @Input() precision: "days" | "minutes" = "days";
  @Input() weekendDays: number[];
  @Input() snapDraggedEvents: boolean = true;
  @Input() hourSegments: number = 4;
  @Input() hourSegmentHeight: number = 30;
  @Input() dayStartHour: number = 0;
  @Input() dayStartMinute: number = 0;
  @Input() dayEndHour: number = 23;
  @Input() dayEndMinute: number = 59;
  @Input() hourSegmentTemplate: TemplateRef<any>;
  @Input() eventSnapSize: number;
  @Input() daysInWeek: number;
  @Output() dayHeaderClicked = new EventEmitter<{ day: WeekDay; }>();
  @Output() eventClicked = new EventEmitter<{ event: CalendarEvent; isSelecting: boolean; }>();
  @Output() appointmentTimesChanging = new EventEmitter<CalendarAppointmentTimesChangedEvent>();
  @Output() appointmentTimesChanged = new EventEmitter<CalendarAppointmentTimesChangedEvent>();
  @Output() beforeViewRender = new EventEmitter<CalendarWeekViewBeforeRenderEvent>();
  @Output() hourSegmentClicked = new EventEmitter<{ date: Date; }>();
  @Output() onViewSwiped = new EventEmitter<any>();
  @Output() onContextMenuClicked = new EventEmitter<any>();
  @ViewChild("weekCalTimeline", {static: false}) weekCalTimeline: ElementRef;
  @ViewChild("weekCalTimeline1Day", {static: false}) weekCalTimeline1Day: ElementRef;

  @Output() handleDoubleClicked = new EventEmitter<{ event: CalendarEvent; }>();
  @Output() hourSegmentContextMenuClicked = new EventEmitter<any>();
  @Output() longPressClicked = new EventEmitter<any>();

  isMobileScreen: boolean = false;
  private isAlive$ = new Subject<boolean>();
  days: WeekDay[];
  view: WeekView;
  eventRefresh: Subject<any> = new Subject();
  refreshSubscription: Subscription;
  allDayEventResizes: Map<WeekViewAllDayAppointment, WeekViewAllDayEventResize> = new Map();
  timeEventResizes: Map<CalendarEvent, ResizeEvent> = new Map();
  eventDragEnter = 0;
  dragActive = false;
  dragAlreadyMoved = false;
  setupHammer: any;
  validateDrag: ValidateDrag;
  validateResize: (args: any) => boolean;
  dayColumnWidth: number;
  calendarId = Symbol("vp calendar week view id");

  trackByWeekDayHeaderDate = trackByWeekDayHeaderDate;
  trackByHourSegment = trackByHourSegment;
  trackByHour = trackByHour;
  trackByDayOrWeekEvent = trackByDayOrWeekEvent;
  timelineInterval: Subscription;

  currentDayView: number = 0;
  /**
   * @hidden
   */

  currentDayInView: number = 0;

sameTimeEvents: any = [];

extraCellAddInBottom: Array<any> = [];


showAppointment: boolean = false;

counting = 0;



moreHrs: Array<any> = [];

  trackByHourColumn = (index: number, column: WeekViewHourColumn) =>
    column.hours[0] ? column.hours[0].segments[0].date.toISOString() : column

  trackById = (index: number, row: WeekViewAllDayAppointmentRow) => row.id;

  @HostListener("window:resize", ["$event"])
  onWindowResize() {
      this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
      this.hourSegmentHeight = this.isMobileScreen ? 42 : 20;
      this.snapDraggedEvents = false;
  }

  ngOnInit(): void {
      this.timelineInterval = timer(0, 2000).subscribe(result => {
        const calTimelinePosition: any = this.calendarRepository.getCalendarTimelinePosition(this.hourSegmentHeight);
        if (this.weekCalTimeline) {
          if (this.calendarRepository.daysInWeek === 1) {
            this.reCalculateTimeShow();
          } else {
            this.weekCalTimeline.nativeElement.style.top = calTimelinePosition.position + "px";
          }
        }
      });


    if (this.refresh) {
      this.refreshSubscription = this.refresh.subscribe(() => {
        this.refreshAll();
        this.cdr.markForCheck();
      });
    }

    this.breakpointObserver
    .observe(["(max-width: 599px)"])
    .pipe(takeUntil(this.isAlive$))
    .subscribe((state: BreakpointState) => {
      if (state.matches && !this.setupHammer) {
        if (!!document.querySelector("#weekViewCalTimeEvents")) {
          this.setupHammer = true;
          new Hammer(<HTMLElement> document.querySelector("#weekViewCalTimeEvents")).on("swipeleft", () => {
            this.onViewSwiped.emit("swipeleft");
          });
          new Hammer(<HTMLElement> document.querySelector("#weekViewCalTimeEvents")).on("swiperight", () => {
              this.onViewSwiped.emit("swiperight");
          });
        }

        if (!!document.querySelector("#weekViewCalTimeEventsD1")) {
          this.setupHammer = true;
          new Hammer(<HTMLElement> document.querySelector("#weekViewCalTimeEventsD1")).on("swipeleft", () => {
            this.onViewSwiped.emit("swipeleft");
          });
          new Hammer(<HTMLElement> document.querySelector("#weekViewCalTimeEventsD1")).on("swiperight", () => {
              this.onViewSwiped.emit("swiperight");
          });
        }
      }
    });
  }
  togetView() {
    console.log("currentDayInView", this.currentDayInView);
  }
  ngOnChanges(changes: any): void {
    this.calendarRepository.daysInWeek = 0;
    if (changes.daysInWeek && changes.daysInWeek.currentValue) {
      this.currentDayView = changes.daysInWeek.currentValue;
      this.calendarRepository.daysInWeek = changes.daysInWeek.currentValue;
      if (this.currentDayView === 1) {
        this.hourSegmentHeight = 42;
      }
      this.cdr.markForCheck();
    } else {
      this.currentDayView = this.daysInWeek;
      this.calendarRepository.daysInWeek = this.daysInWeek;
      this.hourSegmentHeight = 20;
      this.cdr.markForCheck();
    }

    console.log("currentDayView days in", this.currentDayView, this.hourSegmentHeight, this.calendarRepository.daysInWeek, this.daysInWeek);
    if (this.daysInWeek === 1) {
      setTimeout(() => {
        CalenderUtils.calendarTimeLineScrollForDayOne();
      }, 500);
    }
    this.cdr.markForCheck();
    const refreshHeader =
      changes.viewDate ||
      changes.excludeDays ||
      changes.weekendDays ||
      changes.daysInWeek ||
      changes.weekStartsOn;

    const refreshBody =
      changes.viewDate ||
      changes.dayStartHour ||
      changes.dayStartMinute ||
      changes.dayEndHour ||
      changes.dayEndMinute ||
      changes.hourSegments ||
      changes.weekStartsOn ||
      changes.weekendDays ||
      changes.excludeDays ||
      changes.hourSegmentHeight ||
      changes.events ||
      changes.daysInWeek;

    if (refreshHeader) {
      this.refreshHeader();
    }

    if (changes.events) {
      validateEvents(this.events);
    }

    if (refreshBody) {
      this.refreshBody();
    }

    if (refreshHeader || refreshBody) {
      this.emitBeforeViewRender();
    }
  }

  /**
   * @hidden
   */
  ngOnDestroy(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
      this.isAlive$.next(false);
      this.isAlive$.unsubscribe();
    }
  }

  protected resizeStarted(eventsContainer: HTMLElement, minWidth?: number) {
    this.dayColumnWidth = this.getDayColumnWidth(eventsContainer);
    const resizeHelper: CalendarResizeHelper = new CalendarResizeHelper(
      eventsContainer,
      minWidth
    );
    this.validateResize = ({ rectangle }) =>
      resizeHelper.validateResize({ rectangle });
    this.cdr.markForCheck();
  }

  /**
   * @hidden
   */
  timeEventResizeStarted(
    eventsContainer: HTMLElement,
    timeEvent: DayViewEvent,
    resizeEvent: ResizeEvent
  ): void {
    this.timeEventResizes.set(timeEvent.event, resizeEvent);
    this.resizeStarted(eventsContainer);
  }

  /**
   * @hidden
   */
  timeEventResizing(timeEvent: DayViewEvent, resizeEvent: ResizeEvent) {
    this.timeEventResizes.set(timeEvent.event, resizeEvent);
    const adjustedEvents = new Map<CalendarEvent, CalendarEvent>();
    const tempEvents = [...this.events];

    this.timeEventResizes.forEach((lastResizeEvent, event) => {
      const newEventDates = this.getTimeEventResizedDates(
        event,
        lastResizeEvent
      );
      const adjustedEvent = { ...event, ...newEventDates };
      adjustedEvents.set(adjustedEvent, event);
      const eventIndex = tempEvents.indexOf(event);
      tempEvents[eventIndex] = adjustedEvent;

      this.appointmentTimesChanging.emit({
        newStart: newEventDates.start,
        newEnd: newEventDates.end,
        event: timeEvent.event,
        type: CalendarAppointmentTimesChangedEventType.Resize
      });
      this.broadcaster.broadcast("appointmentTimeChanged", {
        newStart: newEventDates.start,
        newEnd: newEventDates.end,
        event: timeEvent.event,
        type: CalendarAppointmentTimesChangedEventType.Resize
      });
    });

    this.restoreOriginalEvents(tempEvents, adjustedEvents);
  }

  /**
   * @hidden
   */
  timeEventResizeEnded(timeEvent: DayViewEvent) {
    this.view = this.getWeekView(this.events);
    const lastResizeEvent = this.timeEventResizes.get(timeEvent.event);
    if (lastResizeEvent) {
      // this.timeEventResizes.delete(timeEvent.event);
      const newEventDates = this.getTimeEventResizedDates(
        timeEvent.event,
        lastResizeEvent
      );
      this.appointmentTimesChanged.emit({
        newStart: newEventDates.start,
        newEnd: newEventDates.end,
        event: timeEvent.event,
        type: CalendarAppointmentTimesChangedEventType.Resize
      });
      this.broadcaster.broadcast("appointmentTimeEnded", {
        newStart: newEventDates.start,
        newEnd: newEventDates.end,
        event: timeEvent.event,
        type: CalendarAppointmentTimesChangedEventType.Resize
      });
    }

    setTimeout(() => {
      this.timeEventResizes = new Map();
    }, 500);
  }

  /**
   * @hidden
   */
  allDayEventResizeStarted(
    allDayEventsContainer: HTMLElement,
    allDayEvent: WeekViewAllDayAppointment,
    resizeEvent: ResizeEvent
  ): void {
    this.allDayEventResizes.set(allDayEvent, {
      originalOffset: allDayEvent.offset,
      originalSpan: allDayEvent.span,
      edge: typeof resizeEvent.edges.left !== "undefined" ? "left" : "right"
    });
    this.resizeStarted(
      allDayEventsContainer,
      this.getDayColumnWidth(allDayEventsContainer)
    );
  }

  checkCallData(timeEvent) {
    console.log("allDayEventResizes", this.allDayEventResizes, timeEvent, this.validateDrag);
  }

  /**
   * @hidden
   */
  allDayEventResizing(
    allDayEvent: WeekViewAllDayAppointment,
    resizeEvent: ResizeEvent,
    dayWidth: number
  ): void {
    const currentResize: WeekViewAllDayEventResize = this.allDayEventResizes.get(
      allDayEvent
    );

    if (typeof resizeEvent.edges.left !== "undefined") {
      const diff: number = Math.round(+resizeEvent.edges.left / dayWidth);
      allDayEvent.offset = currentResize.originalOffset + diff;
      allDayEvent.span = currentResize.originalSpan - diff;
    } else if (typeof resizeEvent.edges.right !== "undefined") {
      const diff: number = Math.round(+resizeEvent.edges.right / dayWidth);
      allDayEvent.span = currentResize.originalSpan + diff;
    }
  }

  /**
   * @hidden
   */
  allDayEventResizeEnded(allDayEvent: WeekViewAllDayAppointment): void {
    const currentResize: WeekViewAllDayEventResize = this.allDayEventResizes.get(
      allDayEvent
    );

    if (currentResize) {
      const allDayEventResizingBeforeStart = currentResize.edge === "left";
      let daysDiff: number;
      if (allDayEventResizingBeforeStart) {
        daysDiff = allDayEvent.offset - currentResize.originalOffset;
      } else {
        daysDiff = allDayEvent.span - currentResize.originalSpan;
      }

      allDayEvent.offset = currentResize.originalOffset;
      allDayEvent.span = currentResize.originalSpan;

      let newStart: Date = allDayEvent.event.start;
      let newEnd: Date = allDayEvent.event.end || allDayEvent.event.start;
      if (allDayEventResizingBeforeStart) {
        newStart = addDaysWithExclusions(
          this.dateAdapter,
          newStart,
          daysDiff,
          this.excludeDays
        );
      } else {
        newEnd = addDaysWithExclusions(
          this.dateAdapter,
          newEnd,
          daysDiff,
          this.excludeDays
        );
      }

      this.appointmentTimesChanged.emit({
        newStart,
        newEnd,
        event: allDayEvent.event,
        type: CalendarAppointmentTimesChangedEventType.Resize
      });
      this.allDayEventResizes.delete(allDayEvent);
    }
  }

  /**
   * @hidden
   */
  getDayColumnWidth(eventRowContainer: HTMLElement): number {
    return Math.floor(eventRowContainer.offsetWidth / this.days.length);
  }

  /**
   * @hidden
   */
  eventDropped(
    dropEvent: DropEvent<{ event?: CalendarEvent; calendarId?: symbol }>,
    date: Date,
    allDay: boolean
  ): void {
    if (shouldFireDroppedEvent(dropEvent, date, allDay, this.calendarId)) {
      this.appointmentTimesChanged.emit({
        type: CalendarAppointmentTimesChangedEventType.Drop,
        event: dropEvent.dropData.event,
        newStart: date,
        allDay
      });
    }
  }

  /**
   * @hidden
   */
  dragStarted(
    eventsContainer: HTMLElement,
    event: HTMLElement,
    dayEvent?: DayViewEvent
  ): void {
    this.dayColumnWidth = this.getDayColumnWidth(eventsContainer);
    const dragHelper: CalendarDragHelper = new CalendarDragHelper(
      eventsContainer,
      event
    );
    this.validateDrag = ({ x, y, transform }) =>
      this.allDayEventResizes.size === 0 &&
      this.timeEventResizes.size === 0 &&
      dragHelper.validateDrag({
        x,
        y,
        snapDraggedEvents: this.snapDraggedEvents,
        dragAlreadyMoved: this.dragAlreadyMoved,
        transform
      });
    this.dragActive = true;
    this.dragAlreadyMoved = false;
    this.eventDragEnter = 0;
    if (!this.snapDraggedEvents && dayEvent) {
      this.view.hourColumns.forEach(column => {
        const linkedEvent = column.events.find(
          columnEvent =>
            columnEvent.event === dayEvent.event && columnEvent !== dayEvent
        );
        // hide any linked events while dragging
        if (linkedEvent) {
          linkedEvent.width = 0;
          linkedEvent.height = 0;
        }
      });
    }
    this.cdr.markForCheck();
  }

  /**
   * @hidden
   */
  dragMove(dayEvent: DayViewEvent, dragEvent: DragMoveEvent) {
    if (this.snapDraggedEvents) {
      const newEventTimes = this.getDragMovedEventTimes(
        dayEvent,
        dragEvent,
        this.dayColumnWidth,
        true
      );
      const originalEvent = dayEvent.event;
      const adjustedEvent = { ...originalEvent, ...newEventTimes };
      const tempEvents = this.events.map(event => {
        if (event === originalEvent) {
          return adjustedEvent;
        }
        return event;
      });
      this.restoreOriginalEvents(
        tempEvents,
        new Map([[adjustedEvent, originalEvent]])
      );
    }
    this.dragAlreadyMoved = true;
  }

  /**
   * @hidden
   */
  allDayEventDragMove() {
    this.dragAlreadyMoved = true;
  }

  /**
   * @hidden
   */
  dragEnded(
    weekEvent: WeekViewAllDayAppointment | DayViewEvent,
    dragEndEvent: DragEndEvent,
    dayWidth: number,
    useY = false
  ): void {
    this.view = this.getWeekView(this.events);
    this.dragActive = false;
    const { start, end } = this.getDragMovedEventTimes(
      weekEvent,
      dragEndEvent,
      dayWidth,
      useY
    );
    if (
      this.eventDragEnter > 0 &&
      isDraggedWithinPeriod(start, end, this.view.period)
    ) {
      this.appointmentTimesChanged.emit({
        newStart: start,
        newEnd: end,
        event: weekEvent.event,
        type: CalendarAppointmentTimesChangedEventType.Drag,
        allDay: !useY
      });
    }
  }

  handleAppointmentClicked(event, isSelecting: boolean) {
    this.eventRefresh.next({ event: event, isSelecting: isSelecting });
    this.eventClicked.emit({ event: event, isSelecting: isSelecting });
  }

  onViewSwipe(event) {
    console.log("onViewSwipe data", event);
    if (this.isMobileScreen) {
      this.onViewSwiped.emit(event.type);
    }
  }

  isToday(date: Date): boolean {
    const today = new Date();

    return this.dateAdapter.isSameYear(date, today)
      && this.dateAdapter.isSameMonth(date, today)
      && this.dateAdapter.isSameDay(date, today);
  }

  isWeekend(date: Date): boolean {
    return date.getDay() === 6 || date.getDay() === 0;
  }

  protected refreshHeader(): void {
    this.days = this.utils.getWeekViewHeader({
      viewDate: this.viewDate,
      weekStartsOn: this.weekStartsOn,
      excluded: this.excludeDays,
      weekendDays: this.weekendDays,
      ...getWeekViewPeriod(
        this.dateAdapter,
        this.viewDate,
        this.weekStartsOn,
        this.excludeDays,
        this.daysInWeek
      )
    });
  }

  protected refreshBody(): void {
    this.view = this.getWeekView(this.events);
  }

  protected refreshAll(): void {
    this.refreshHeader();
    this.refreshBody();
    this.emitBeforeViewRender();
    this.getDataDisplayView();
  }

  protected emitBeforeViewRender(): void {
    if (this.days && this.view) {
      this.beforeViewRender.emit({
        header: this.days,
        ...this.view
      });
    }
  }

  protected getWeekView(events: CalendarEvent[]) {
    return this.utils.getWeekView({
      events,
      viewDate: this.viewDate,
      weekStartsOn: this.weekStartsOn,
      excluded: this.excludeDays,
      precision: this.precision,
      absolutePositionedEvents: true,
      hourSegments: this.hourSegments,
      dayStart: {
        hour: this.dayStartHour,
        minute: this.dayStartMinute
      },
      dayEnd: {
        hour: this.dayEndHour,
        minute: this.dayEndMinute
      },
      segmentHeight: this.hourSegmentHeight,
      weekendDays: this.weekendDays,
      ...getWeekViewPeriod(
        this.dateAdapter,
        this.viewDate,
        this.weekStartsOn,
        this.excludeDays,
        this.daysInWeek
      )
    });
  }

  protected getDragMovedEventTimes(
    weekEvent: WeekViewAllDayAppointment | DayViewEvent,
    dragEndEvent: DragEndEvent | DragMoveEvent,
    dayWidth: number,
    useY: boolean
  ) {
    const daysDragged = roundToNearest(dragEndEvent.x, dayWidth) / dayWidth;
    const minutesMoved = useY
      ? getMinutesMoved(
        dragEndEvent.y,
        this.hourSegments,
        this.hourSegmentHeight,
        this.eventSnapSize
      )
      : 0;

    const start = this.dateAdapter.addMinutes(
      addDaysWithExclusions(
        this.dateAdapter,
        weekEvent.event.start,
        daysDragged,
        this.excludeDays
      ),
      minutesMoved
    );
    let end: Date;
    if (weekEvent.event.end) {
      end = this.dateAdapter.addMinutes(
        addDaysWithExclusions(
          this.dateAdapter,
          weekEvent.event.end,
          daysDragged,
          this.excludeDays
        ),
        minutesMoved
      );
    }

    return { start, end };
  }

  protected restoreOriginalEvents(
    tempEvents: CalendarEvent[],
    adjustedEvents: Map<CalendarEvent, CalendarEvent>
  ) {
    const previousView = this.view;
    this.view = this.getWeekView(tempEvents);
    const adjustedEventsArray = tempEvents.filter(event =>
      adjustedEvents.has(event)
    );
    this.view.hourColumns.forEach((column, columnIndex) => {
      previousView.hourColumns[columnIndex].hours.forEach((hour, hourIndex) => {
        hour.segments.forEach((segment, segmentIndex) => {
          column.hours[hourIndex].segments[segmentIndex].cssClass =
            segment.cssClass;
        });
      });
      adjustedEventsArray.forEach(adjustedEvent => {
        const originalEvent = adjustedEvents.get(adjustedEvent);
        const existingColumnEvent = column.events.find(
          columnEvent => columnEvent.event === adjustedEvent
        );
        if (existingColumnEvent) {
          // restore the original event so trackBy kicks in and the dom isn"t changed
          existingColumnEvent.event = originalEvent;
        } else {
          // add a dummy event to the drop so if the event was removed from the original column the drag doesn"t end early
          column.events.push({
            event: originalEvent,
            left: 0,
            top: 0,
            height: 0,
            width: 0,
            startsBeforeDay: false,
            endsAfterDay: false
          });
        }
      });
    });
    adjustedEvents.clear();
  }

  protected getTimeEventResizedDates(
    calendarEvent: CalendarEvent,
    resizeEvent: ResizeEvent
  ) {
    const minimumEventHeight = getMinimumEventHeightInMinutes(
      this.hourSegments,
      this.hourSegmentHeight
    );
    const newEventDates = {
      start: calendarEvent.start,
      end: getDefaultEventEnd(
        this.dateAdapter,
        calendarEvent,
        minimumEventHeight
      )
    };
    const { end, ...eventWithoutEnd } = calendarEvent;
    const smallestResizes = {
      start: this.dateAdapter.addMinutes(
        newEventDates.end,
        minimumEventHeight * -1
      ),
      end: getDefaultEventEnd(
        this.dateAdapter,
        eventWithoutEnd,
        minimumEventHeight
      )
    };

    if (typeof resizeEvent.edges.left !== "undefined") {
      const daysDiff = Math.round(
        +resizeEvent.edges.left / this.dayColumnWidth
      );
      const newStart = addDaysWithExclusions(
        this.dateAdapter,
        newEventDates.start,
        daysDiff,
        this.excludeDays
      );
      if (newStart < smallestResizes.start) {
        newEventDates.start = newStart;
      } else {
        newEventDates.start = smallestResizes.start;
      }
    } else if (typeof resizeEvent.edges.right !== "undefined") {
      const daysDiff = Math.round(
        +resizeEvent.edges.right / this.dayColumnWidth
      );
      const newEnd = addDaysWithExclusions(
        this.dateAdapter,
        newEventDates.end,
        daysDiff,
        this.excludeDays
      );
      if (newEnd > smallestResizes.end) {
        newEventDates.end = newEnd;
      } else {
        newEventDates.end = smallestResizes.end;
      }
    }

    if (typeof resizeEvent.edges.top !== "undefined") {
      const minutesMoved = getMinutesMoved(
        resizeEvent.edges.top as number,
        this.hourSegments,
        this.hourSegmentHeight,
        this.eventSnapSize
      );
      const newStart = this.dateAdapter.addMinutes(
        newEventDates.start,
        minutesMoved
      );
      if (newStart < smallestResizes.start) {
        newEventDates.start = newStart;
      } else {
        newEventDates.start = smallestResizes.start;
      }
    } else if (typeof resizeEvent.edges.bottom !== "undefined") {
      const minutesMoved = getMinutesMoved(
        resizeEvent.edges.bottom as number,
        this.hourSegments,
        this.hourSegmentHeight,
        this.eventSnapSize
      );
      const newEnd = this.dateAdapter.addMinutes(
        newEventDates.end,
        minutesMoved
      );
      if (newEnd > smallestResizes.end) {
        newEventDates.end = newEnd;
      } else {
        newEventDates.end = smallestResizes.end;
      }
    }

    return newEventDates;
  }

  handleDoubleClickedHandled(ev): void {
    this.handleDoubleClicked.emit({event: ev});
  }

  ngAfterViewInit(): void {
    CalenderUtils.calendarTimeLineScroll();
  }

  checkWidthData(index, totalEvent) {
    if (this.calendarRepository.daysInWeek === 1) {
      let widthInPercentage: number = 0;
      if (totalEvent[index].left === 0) {
        widthInPercentage = 100;
      } else if (totalEvent[index].left > 0) {
        let anotherLeft = totalEvent[index].left / 2;
        widthInPercentage = 100 - anotherLeft;
      }
      return widthInPercentage;
    } else {
      return totalEvent[index].width;
    }

  }

  checkLeftData(index, totalEvent) {
    if (this.calendarRepository.daysInWeek === 1) {
      let leftInPercentage: number = 0;
      if (totalEvent[index].left === 0) {
        leftInPercentage = 0;
      } else if (totalEvent[index].left === 50) {
        let anotherLeft = totalEvent[index].left + (totalEvent[index].left / 2);
        leftInPercentage = 100 - anotherLeft;
      } else {
        leftInPercentage = totalEvent[index].left;
      }
      return leftInPercentage;
    } else {
      return totalEvent[index].left;
    }
  }


getTimeEventTop(top, length) {
  if (this.calendarRepository.daysInWeek === 1) {
    return  top + (length * 168);
  } else {
    return  top;
  }
}

// new day view custom start
getDataDisplayView() {
  this.getSameTimeEvents();
}

getSameTimeEvents() {
  this.sameTimeEvents = [];
  let events = this.view.hourColumns[0].events;
  events.map((ev, index) => {
    let time = this.getHourFromDateString(ev.event.start);
    this.getSameTimeEventsCount(time, ev);
  });

  this.getNewTop();
  this.calculateExtraCellAddInBottom();

}

  checkIsSameStartDate(date) {
    let viewDate = new Date(this.viewDate);
    let start = new Date(date);
    if (viewDate.getDate() === start.getDate()) {
      return true;
    }
    return false;
  }

calculateExtraCellAddInBottom() {
  this.extraCellAddInBottom = [];
  let events = this.view.hourColumns[0].events;
  events.map((ev, index) => {
    if (ev["count"] !== 0) {
      this.extraCellAddInBottom.push("count");
    }
  });

}

getNewTop() {
  let events = this.view.hourColumns[0].events;
  let count = 0;
  let length = this.view.allDayEventRows.length;
  events.map((ev, index) => {
    let time = this.getHourFromDateString(ev.event.start);
      if (index === 0) {
        ev["newTop"] = (time * 168) + (length * 168);
      } else {
        if (ev["count"]) {
          count = count + 1;
        }
        ev["newTop"] = (time * 168) + (length * 168) + (count * 168);
      }
  });
}

getNTop(index) {
  if (this.calendarRepository.daysInWeek === 1) {
    let events = this.view.hourColumns[0].events;
    this.getDataDisplayView();
    console.log("getDataDisplay view sameTimeEvents Top value:",   events[index]);
    return events[index]["newTop"];
  }

}
getTimeEventTopNew(top, length, timeEvent, indexItem) {
  setTimeout(() => {
    let time = this.getHourFromDateString(timeEvent.event.start);
    let topValue = 0;
    topValue = (time * 168);
    let count = 0;
    if (this.calendarRepository.daysInWeek === 1) {
      if (indexItem === 0) {
        timeEvent["newTop"] =  topValue + (length * 168);
      } else {
        let events = this.view.hourColumns[0].events;
        events.map((ev, index) => {
          if (indexItem <= index) {
            if (ev && ev["count"]) {
              count = count + ev["count"];
            }
          }
        });
        timeEvent["newTop"] = topValue + (length * 168) + (count * 168);
      }
    }
  }, 200);

}
getSameTimeEventsCount(hrs, ev) {
  if (this.sameTimeEvents.length === 0) {
    ev["count"] = 0;
    this.sameTimeEvents.push({time: hrs, count: 1});
  } else if (this.sameTimeEvents.length > 0) {
    let isExist = false;
    this.sameTimeEvents.map((hr) => {
      if (hr.time === hrs) {
        isExist = true;
        ev.count = hr.count;
        hr.count = hr.count + 1;
      }
    });
    if (!isExist) {
      this.sameTimeEvents.push({time: hrs, count: 1});
      ev["count"] = 0;
    }
  }
}

getDataToLoadMoreRow(index) {
  let arrayToRender = [];
  this.sameTimeEvents.map((ev) => {
    if (ev.time === index) {
      if (ev.count > 1) {
        for (let i = 1 ; i < ev.count; i++) {
          arrayToRender.push(i);
        }
      }
    }
  });
  console.log("getDataToLoadMoreRow", index, arrayToRender);

  return arrayToRender;
}
getEventDataWhichLessWidth(event: Array<any> = []) {
  this.moreHrs = [];
    event.forEach((ev, index) => {
      if (ev.width === 50) {
        let hrs = this.getHourFromDateString(ev.event.start);
        this.moreHrs.push({time: hrs, count: 1});
      }
      if (ev.width === 33.333333333333336) {
        let hrs = this.getHourFromDateString(ev.event.start);
        this.moreHrs.push({time: hrs, count: 2});
      }
    });

    console.log("event less this.moreHrs", event, this.moreHrs);
}

checkTimeCount(date) {
  let hrs = this.getHourFromDateString(date);
  let dateCount = 0;
  let rowCombo = [];
  this.moreHrs.map((hr) => {
    if (hr.time === hrs) {
      dateCount = dateCount + 1;
    }
  });
  if (dateCount > 0) {
    if (dateCount === 2) {
      rowCombo = [1];
    }
    if (dateCount === 3) {
      rowCombo = [1, 2];
    }
  }
  return rowCombo;
}

isfifty(date) {
  let hrs = this.getHourFromDateString(date);
  let isFifty = false;
  this.moreHrs.map((hr) => {
    if (hr.time === hrs && hr.count === 1) {
      isFifty = true;
    }
  });
  return isFifty;
}

isthirtyThree(date) {
  let hrs = this.getHourFromDateString(date);
  let isThirtyThree = false;
  this.moreHrs.map((hr) => {
    if (hr.time === hrs && hr.count === 2) {
      isThirtyThree = true;
    }
  });
  return isThirtyThree;
}

getHourFromDateString(dateString) {
  let date = new Date(dateString);
  return date.getHours();
}

findOverlappingSlots(appointments) {
  // Convert event start and end times to Date objects
  appointments.forEach(app => {
      app.start = new Date(app.event.start);
      app.end = new Date(app.event.end);
  });

  // Sort appointments by start time
  appointments.sort((a, b) => a.start - b.start);

  let overlappingSlots = [];
  let currentSlot = appointments[0];

  for (let i = 1; i < appointments.length; i++) {
      let nextSlot = appointments[i];

      // Check if current slot overlaps with the next one
      if (currentSlot.end > nextSlot.start) {
          overlappingSlots.push({
              start: nextSlot.start,
              end: currentSlot.end < nextSlot.end ? currentSlot.end : nextSlot.end,
              appointments: [currentSlot, nextSlot]
          });
          // Merge the intervals
          currentSlot.end = new Date(Math.max(currentSlot.end, nextSlot.end));
      } else {
          currentSlot = nextSlot;
      }
  }

  // Return overlapping time slots with the number of overlapping events
  return overlappingSlots.map(slot => ({
      start: slot.start,
      end: slot.end,
      startDateTime: this.formatDateTime(slot.start),
      endDateTime: this.formatDateTime(slot.end),
      overlappingEvents: slot.appointments.length
  }));
}

formatDateTime(dateString) {
  let date = new Date(dateString);

  // Extracting day, hours, and minutes in local time
  let day = String(date.getDate()).padStart(2, "0");
  let hours = String(date.getHours()).padStart(2, "0");
  let minutes = String(date.getMinutes()).padStart(2, "0");

  // Format as DD HH:ss
  return `${day} ${hours}:${minutes}`;
}

reCalculateTimeShow() {
  const now = moment();
  let hour = now.hour();
  let extraSegment = 0;

  this.sameTimeEvents.map((ev) => {
    if (ev.time < hour) {
      extraSegment = extraSegment + (ev.count - 1);
    }
  });

  const calTimelinePosition: any = this.calendarRepository.getCalendarTimelinePosition(42);
  if (this.weekCalTimeline1Day) {
    if (this.calendarRepository.daysInWeek === 1) {
      if (this.view.allDayEventRows.length > 0) {
        this.weekCalTimeline1Day.nativeElement.style.top = (extraSegment * 168) + calTimelinePosition.position + (this.view.allDayEventRows.length * 168) + "px";
      } else {
        this.weekCalTimeline1Day.nativeElement.style.top = (extraSegment * 168) + calTimelinePosition.position + "px";
      }
    }
  }
  console.log("reCalculateTimeShow heigh", ((extraSegment * 168) + calTimelinePosition.position + (this.view.allDayEventRows.length * 168)), now.hour(), extraSegment);
}

}
