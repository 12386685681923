
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfigService } from "src/app/config.service";
import { VncLibraryService } from "vnc-library";

@Injectable()
export class ToastService {

  toasterMessageDisplayTime: number = 3000;

  constructor(
    private matSnackBar: MatSnackBar,
    private translate: TranslateService,
    private configService: ConfigService,
    private vncLibraryService: VncLibraryService,
  ) {
    this.configService.getAllConfig().subscribe(config => {
      if (!!config && config.toasterMessageDisplayTime) {
        this.toasterMessageDisplayTime = config.toasterMessageDisplayTime;
      }
    });
  }

  show(translationKey: string, timeout = 4000, actionIcon?: string) {
    this.translate.get(translationKey).subscribe(msg => {
      console.log("show toast", translationKey, msg);
      let icon = actionIcon || "";
      let duration = timeout || this.toasterMessageDisplayTime;
      this.vncLibraryService.openSnackBar(msg, icon,
      "", "", duration, "bottom", "left").subscribe(res => {
      });
    });
  }

  showPlainMessage(message: string, timeout = 4000) {
    this.vncLibraryService.openSnackBar(message, "",
    "", "", this.toasterMessageDisplayTime, "bottom", "left").subscribe(res => {
    });
  }
}
