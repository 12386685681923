
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Pipe, PipeTransform } from "@angular/core";
import { CalendarEvent } from "calendar-utils";
import { CalendarAppointmentTitleFormatter } from "./calendar-appointment-title-formatter.provider";

@Pipe({
  name: "vpCalendarAppointmentTitle"
})
export class CalendarAppointmentTitlePipe implements PipeTransform {
  constructor(private calendarAppointmentTitle: CalendarAppointmentTitleFormatter) {}

  transform(title: string, titleType: string, appointment: CalendarEvent): string {
    return this.calendarAppointmentTitle[titleType](appointment, title);
  }
}
