<div class="schedule-assistant-component">
    <vp-sidebar-calendar-mini-view #calendarDatePicker [viewDate]="viewDate" [noFloating]="true"
        [locale]="currentLocale" (dayCellClicked)="calendarTimeChangedOnDatePicker($event)"
        [showWeekNumberInMiniCalendar]="showWeekNumberInMiniCalendar" [weekStartsOn]="firstDayOfWeek">
    </vp-sidebar-calendar-mini-view>
    <ng-container *ngIf="currentSuggestions">
        <ng-container *ngIf="currentSuggestions?.list?.length === 0">
            <div class="no-suggestions"
                [innerHTML]="'NO_SUGGESTIONS_FOUND' | translate:{'startDate': currentSuggestions?.startDateText, 'duration': currentSuggestions?.durationText}">
            </div>
        </ng-container>
        <ng-container *ngIf="currentSuggestions?.list?.length > 0">
            <div class="time-suggestions-list">
                <div *ngFor="let item of currentSuggestions.list; let i = index" class="suggestion-item">
                    <ng-container [ngTemplateOutlet]="timeSuggestion"
                        [ngTemplateOutletContext]="{data: item, index: i}"></ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
    

<ng-template #timeSuggestion let-data="data" let-index="index">
    <div [class.selected]="selectedSuggestedTime?.startTime === data.startTime && selectedSuggestedTime?.endTime === data.endTime" class="time-suggestion-item" (click)="selectSuggestedTime(data, index)">
        <div class="left-part">
            <mat-icon class="icon-type">group</mat-icon>
            <span *ngIf="browserLang === 'en'">{{ data.startTime | date: "hh:mm a" }}</span>
            <span *ngIf="browserLang === 'de'">{{ data.startTime | date: "HH:mm" }}</span> 
        </div>
        <span></span>
        <div  class="right-part" [matMenuTriggerFor]="locationMenu" (click)="$event.stopPropagation();" *ngIf="data.availableLocations > 0">
            <span class="FakeAnchor">{{ data.availableLocations }}</span>
            <mat-icon class="icon-type">room</mat-icon>
            <mat-menu #locationMenu="matMenu" class="briefcase-header-move-mat-menu">
                <button *ngFor="let locationIndex of data.locations" mat-menu-item (click)="selectLocation(locationIndex)">
                    <span>{{ currentSuggestions.locationInfo.locations[locationIndex]?.locationObj?.fullName }}</span>
                </button>
            </mat-menu>
        </div>
    </div>
</ng-template>