
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, TemplateRef, Output, EventEmitter } from "@angular/core";
import { WeekViewHourColumn } from "calendar-utils";

@Component({
  selector: "vp-calendar-week-view-hour-segment",
  template: `
    <ng-template
      #defaultTemplate
      let-segment="segment"
      let-locale="locale"
      let-segmentHeight="segmentHeight"
      let-isTimeLabel="isTimeLabel"
      let-hourSegmentContextMenuClicked="hourSegmentContextMenuClicked"
    >
      <div
        class="cal-hour-segment"
        [style.height.px]="segmentHeight"
        [class.cal-hour-start]="segment.isStart"
        [class.cal-after-hour-start]="!segment.isStart"
        [ngClass]="segment.cssClass"
        (contextmenu)="hourSegmentContextMenuClicked.emit({ $event: $event, segment: segment })"
      >
        <div class="cal-time" *ngIf="isTimeLabel">
          <span *ngIf="locale !== 'en'">{{ segment.date | date: "HH:mm": "":locale }}</span>
          <span *ngIf="locale === 'en'">{{ segment.date | date: "h a": "":locale }}</span>
        </div>
      </div>
    </ng-template>
    <ng-template
      [ngTemplateOutlet]="customTemplate || defaultTemplate"
      [ngTemplateOutletContext]="{
        segment: segment,
        locale: locale,
        segmentHeight: segmentHeight,
        isTimeLabel: isTimeLabel,
        hourSegmentContextMenuClicked: hourSegmentContextMenuClicked
      }"
    >
    </ng-template>
  `
})
export class CalendarWeekViewHourSegmentComponent {
  @Input() segment: WeekViewHourColumn;

  @Input() segmentHeight: number;

  @Input() locale: string;

  @Input() isTimeLabel: boolean;

  @Input() customTemplate: TemplateRef<any>;

  @Output() hourSegmentContextMenuClicked = new EventEmitter<any>();

}
