
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CalendarMiniViewComponent } from "./calendar-mini-view.component";
import { CalendarMiniViewHeaderComponent } from "./calendar-mini-view-header.component";
import { CalendarMiniCellComponent } from "./calendar-mini-cell.component";
import { CalendarCommonModule } from "../common/calendar-common.module";
import { SidebarCalendarMiniViewComponent } from "./sidebar-mini-calendar.component";
import { SharedModule } from "src/app/shared/shared.module";

export {
  CalendarMiniViewComponent,
  CalendarMonthViewBeforeRenderEvent,
  CalendarMonthViewAppointmentTimesChangedEvent
} from "./calendar-mini-view.component";
export { MonthViewDay as CalendarMonthViewDay } from "calendar-utils";

@NgModule({
  imports: [SharedModule, CommonModule, CalendarCommonModule],
  declarations: [
    CalendarMiniViewComponent,
    CalendarMiniCellComponent,
    CalendarMiniViewHeaderComponent,
    SidebarCalendarMiniViewComponent
  ],
  exports: [
    CalendarMiniViewComponent,
    CalendarMiniCellComponent,
    CalendarMiniViewHeaderComponent,
    SidebarCalendarMiniViewComponent
  ]
})
export class CalendarMiniModule {}
