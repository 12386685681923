import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SchedulerComponent } from "./scheduler.component";
import { SharedModule } from "src/app/shared/shared.module";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [SchedulerComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatTooltipModule
  ],
  exports: [SchedulerComponent]
})
export class SchedulerModule { }
