
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
    Component,
    ChangeDetectionStrategy,
    ElementRef,
    ViewContainerRef,
    TemplateRef,
    ContentChild,
    Input,
    OnInit,
    HostListener
} from "@angular/core";
import { ViewPort, Iterator } from "src/app/common/models/view-port.model";

const VIEW_PORT_SIZE_MULTIPLIER = 1.5;
const VIEW_PORT_MOVE_BOUNDARY_MULTIPLIER = 0.4;
const SCROLL_CONTAINER_ATTRIBUTE_NAME = "vp-virtual-scrolling-container";
const MILLISECONDS_TO_WAIT_ON_SCROLLING_BEFORE_RENDERING = 10;

@Component({
    selector: "vp-virtual-scrolling",
    template: "<div class=\"vp-virtual-scrolling\"></div>",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [`
          :host-context([vp-virtual-scrolling-container]) {
              overflow-y: auto;
              overflow-x: hidden;
          }
          :host {
              display: block;
              position: relative;
          }
      `]
})
export class VirtualScrollingComponent implements OnInit {
    private scrollContainer;

    private currentElementHeight: number = 0;

    private itemsPerRow: number;
    private rowsPerViewPort: number;
    private itemsPerViewPort: number;
    private viewPortHeight: number;

    private topViewPort: ViewPort;
    private middleViewPort: ViewPort;
    private bottomViewPort: ViewPort;

    private moveTopBoundary: number;
    private moveBottomBoundary: number;

    @ContentChild(TemplateRef, {static: false})
    private template: TemplateRef<Object>;

    @Input()
    public itemIterator: Iterator<any>;

    @Input()
    public itemWidth: any;

    @Input()
    public itemHeight: any;

    @Input()
    public itemSpace: any;

    private plannedViewPortScrollTop: number;

    constructor(private element: ElementRef, private viewContainer: ViewContainerRef) {
    }

    @HostListener("window:scroll")
    private scrollHandler() {
        this.onContainerScroll();
    }

    @HostListener("window:resize")
    private resizeHandler() {
        this.onWindowResize();
    }

    public async ngOnInit(): Promise<any> {
        const self = this;

        checkInjectedParameters();

        this.scrollContainer = getScrollContainer();

        this.calculateParameters();

        this.moveTopBoundary = 0;
        this.moveBottomBoundary = this.viewPortHeight * (1 - VIEW_PORT_MOVE_BOUNDARY_MULTIPLIER);

        await this.initialRenderAsync();

        function checkInjectedParameters() {
            if (!self.itemIterator) {
                throw new Error("Binding the [itemIterator] input is mandatory!");
            }

            if (self.itemWidth === undefined) {
                throw new Error("Binding the [itemWidth] input is mandatory!");
            }

            if (self.itemHeight === undefined) {
                throw new Error("Binding the [itemHeight] input is mandatory!");
            }

            if (self.itemSpace === undefined) {
                throw new Error("Binding the [itemSpace] input is mandatory!");
            }
        }

        function getScrollContainer() {
            let currentNode = self.element.nativeElement.parentNode;

            while (currentNode) {
                if (currentNode.attributes && currentNode.attributes[SCROLL_CONTAINER_ATTRIBUTE_NAME]) {
                    break;
                }

                currentNode = currentNode.parentNode;
            }

            if (currentNode) {
                return currentNode;
            }

            const parent = self.element.nativeElement.parentNode;

            parent.setAttribute(SCROLL_CONTAINER_ATTRIBUTE_NAME, "true");

            return parent;
        }
    }

    public async reRenderAsync(): Promise<void> {
        await this.reRenderFromScrollAsync(this.scrollContainer.scrollTop);
    }

    private get visibleItemHeight() {
        return (this.itemHeight || 0) + (2 * (this.itemSpace || 0));
    }

    private get visibleItemWidth() {
        return (this.itemWidth || 0) + (2 * (this.itemSpace || 0));
    }

    private onWindowResize() {
        this.calculateParameters();

        this.reRenderFromScrollAsync(this.scrollContainer.scrollTop);
    }

    private async getItems(fromIndex: number, numberOfItems: number): Promise<any[]> {
        try {
            const result = this.itemIterator.next(fromIndex, numberOfItems);

            if (!result) {
                return [];
            }

            if (typeof (result.value.then) === "function") {
                return await result.value;
            } else if (result.value instanceof Array) {
                return result.value;
            }

            return [];
        } catch (e) {
            console.log(e);
        }

        return [];
    }

    private calculateParameters() {
        const self = this;

        const availableWidth = calculateAvailableWidth();
        const availableHeight = calculateAvailableHeight();

        this.viewPortHeight = calculateViewPortHeight();
        this.rowsPerViewPort = calculateRowsPerViewPort();
        this.itemsPerRow = calculateItemsPerRow();
        this.itemsPerViewPort = this.rowsPerViewPort * this.itemsPerRow;

        function calculateAvailableWidth() {
            return self.scrollContainer.offsetWidth;
        }

        function calculateAvailableHeight() {
            return self.scrollContainer.offsetHeight;
        }

        function calculateViewPortHeight() {
            const bareHeight = availableHeight * VIEW_PORT_SIZE_MULTIPLIER;

            const rowsFitInHeight = Math.floor(bareHeight / self.visibleItemHeight);

            return rowsFitInHeight * self.visibleItemHeight;
        }

        function calculateRowsPerViewPort() {
            return self.viewPortHeight / self.visibleItemHeight;
        }

        function calculateItemsPerRow() {
            return Math.floor(availableWidth / self.visibleItemWidth);
        }
    }

    private onContainerScroll() {
        const currentScrollTop = this.scrollContainer.scrollTop;

        setTimeout(() => {
            const latestScrollTop = this.scrollContainer.scrollTop;

            if (currentScrollTop !== latestScrollTop) {
                return;
            }

            this.handleCurrentScroll(latestScrollTop);

        }, MILLISECONDS_TO_WAIT_ON_SCROLLING_BEFORE_RENDERING);
    }

    private async handleCurrentScroll(scrollTop: number): Promise<any> {
        if (scrollTop > this.bottomViewPort.bottomScrollTop && this.isLastViewPortRendered) {
            return;
        }
        if (scrollTop < this.topViewPort.scrollTop || scrollTop > this.bottomViewPort.bottomScrollTop) {
            await this.reRenderFromScrollAsync(scrollTop);
        } else if (scrollTop < this.moveTopBoundary) {
            await this.moveUpAsync(scrollTop);
        } else if (scrollTop > this.moveBottomBoundary) {
            await this.moveDownAsync(scrollTop);
        } else {
            return;
        }

        this.calculateMoveBoundaries();
    }

    private calculateMoveBoundaries() {
        this.moveTopBoundary = this.topViewPort.scrollTop + (this.viewPortHeight * (1 - VIEW_PORT_MOVE_BOUNDARY_MULTIPLIER));

        if (this.moveTopBoundary < 0) {
            this.moveTopBoundary = 0;
        }

        if (this.isLastViewPortRendered) {
            this.moveBottomBoundary = Infinity;
        } else {
            this.moveBottomBoundary = this.middleViewPort.scrollTop + (this.viewPortHeight * (1 - VIEW_PORT_MOVE_BOUNDARY_MULTIPLIER));
        }
    }

    private get isLastViewPortRendered() {
        return this.topViewPort.isLastViewPort || this.middleViewPort.isLastViewPort || this.bottomViewPort.isLastViewPort;
    }

    private async moveUpAsync(scrollTop: number): Promise<void> {
        const viewPortScrollTop = this.topViewPort.scrollTop - this.viewPortHeight;
        const fromIndex = this.topViewPort.itemsFromIndex - this.itemsPerViewPort;

        this.plannedViewPortScrollTop = viewPortScrollTop;

        const viewPortItems = await this.getItems(fromIndex, this.itemsPerViewPort);

        if (this.plannedViewPortScrollTop !== viewPortScrollTop) {
            return;
        }

        if (!viewPortItems || !viewPortItems.length) {
            return;
        }

        const newViewPort = new ViewPort();
        newViewPort.scrollTop = viewPortScrollTop;
        newViewPort.items = viewPortItems;
        newViewPort.itemsFromIndex = fromIndex;
        newViewPort.isLastViewPort = false;
        newViewPort.height = this.calculateViewPortHeight(newViewPort.numberOfItems);

        this.renderViewPort(newViewPort);

        const oldBottomViewPort = this.bottomViewPort;

        this.bottomViewPort = this.middleViewPort;
        this.middleViewPort = this.topViewPort;
        this.topViewPort = newViewPort;

        this.destroyViewPort(oldBottomViewPort);
    }

    private async moveDownAsync(scrollTop: number): Promise<void> {
        const viewPortScrollTop = this.bottomViewPort.bottomScrollTop;
        const fromIndex = this.bottomViewPort.itemsFromIndex + this.itemsPerViewPort;

        this.plannedViewPortScrollTop = viewPortScrollTop;

        const viewPortItems = await this.getItems(fromIndex, this.itemsPerViewPort);

        if (this.plannedViewPortScrollTop !== viewPortScrollTop) {
            return;
        }

        if (!viewPortItems || !viewPortItems.length) {
            this.bottomViewPort.isLastViewPort = true;

            return;
        }

        const newViewPort = new ViewPort();
        newViewPort.scrollTop = viewPortScrollTop;
        newViewPort.items = viewPortItems;
        newViewPort.itemsFromIndex = fromIndex;
        newViewPort.isLastViewPort = viewPortItems.length < this.itemsPerViewPort;
        newViewPort.height = this.calculateViewPortHeight(newViewPort.numberOfItems);

        this.renderViewPort(newViewPort);

        const oldTopViewPort = this.topViewPort;

        this.topViewPort = this.middleViewPort;
        this.middleViewPort = this.bottomViewPort;
        this.bottomViewPort = newViewPort;

        this.destroyViewPort(oldTopViewPort);
    }

    private renderViewPort(viewPort: ViewPort) {
        const viewPortElement = document.createElement("div");

        viewPortElement.classList.add("vp-virtual-scrolling-view-port");
        viewPortElement.style.top = `${viewPort.scrollTop}px`;
        viewPortElement.style.height = `${viewPort}px`;
        viewPortElement.style.position = "absolute";

        viewPort.nativeElement = viewPortElement;

        const minimumElementHeight = viewPort.bottomScrollTop;
        if (minimumElementHeight > this.currentElementHeight) {
            this.currentElementHeight = minimumElementHeight;

            this.element.nativeElement.style.height = `${this.currentElementHeight}px`;
        }

        for (const item of viewPort.items) {
            const embeddedView = this.template.createEmbeddedView({
                $implicit: item
            });

            viewPort.renderedItems.push(embeddedView);

            this.viewContainer.insert(embeddedView);

            const itemNode = document.createElement("div");
            itemNode.classList.add("vp-virtual-scrolling-item");
            itemNode.style.display = "inline-block";
            itemNode.style.verticalAlign = "top";
            itemNode.style.height = `${this.itemHeight}px`;
            itemNode.style.width = `${this.itemWidth}px`;
            itemNode.style.margin = `${this.itemSpace}px`;

            for (const viewNode of embeddedView.rootNodes) {
                itemNode.appendChild(viewNode);
            }

            viewPort.nativeElement.appendChild(itemNode);
        }

        this.element.nativeElement.appendChild(viewPort.nativeElement);
    }

    private destroyViewPort(viewPort: ViewPort) {
        for (const item of viewPort.renderedItems) {
            item.destroy();
        }

        this.element.nativeElement.removeChild(viewPort.nativeElement);
    }

    private async initialRenderAsync(): Promise<any> {
        await this.reRenderFromScrollAsync(0);

        this.calculateMoveBoundaries();
    }

    private async reRenderFromScrollAsync(scrollTop: number): Promise<void> {
        const currentViewPortIndex = Math.floor(scrollTop / this.viewPortHeight);
        const topScrollTop = currentViewPortIndex * this.viewPortHeight;

        const fromIndex = currentViewPortIndex * this.itemsPerViewPort;

        const viewPortItems = await this.getItems(fromIndex, 3 * this.itemsPerViewPort);

        if (this.topViewPort) {
            this.destroyViewPort(this.topViewPort);
        }

        this.topViewPort = new ViewPort();

        this.topViewPort.scrollTop = topScrollTop;
        this.topViewPort.itemsFromIndex = fromIndex;
        this.topViewPort.items = viewPortItems.slice(0, this.itemsPerViewPort);
        this.topViewPort.isLastViewPort = this.topViewPort.numberOfItems < this.itemsPerViewPort;
        this.topViewPort.height = this.calculateViewPortHeight(this.topViewPort.numberOfItems);

        this.renderViewPort(this.topViewPort);

        if (this.middleViewPort) {
            this.destroyViewPort(this.middleViewPort);
        }

        this.middleViewPort = new ViewPort();

        this.middleViewPort.scrollTop = topScrollTop + this.viewPortHeight;
        this.middleViewPort.itemsFromIndex = fromIndex + this.itemsPerViewPort;
        this.middleViewPort.items = viewPortItems.slice(this.itemsPerViewPort, 2 * this.itemsPerViewPort);
        this.middleViewPort.isLastViewPort = this.middleViewPort.numberOfItems < this.itemsPerViewPort;
        this.middleViewPort.height = this.calculateViewPortHeight(this.middleViewPort.numberOfItems);

        this.renderViewPort(this.middleViewPort);

        if (this.bottomViewPort) {
            this.destroyViewPort(this.bottomViewPort);
        }

        this.bottomViewPort = new ViewPort();

        this.bottomViewPort.scrollTop = topScrollTop + (2 * this.viewPortHeight);
        this.bottomViewPort.itemsFromIndex = fromIndex + (2 * this.itemsPerViewPort);
        this.bottomViewPort.items = viewPortItems.slice(2 * this.itemsPerViewPort, 3 * this.itemsPerViewPort);
        this.bottomViewPort.isLastViewPort = this.bottomViewPort.numberOfItems < this.itemsPerViewPort;
        this.bottomViewPort.height = this.calculateViewPortHeight(this.bottomViewPort.numberOfItems);

        this.renderViewPort(this.bottomViewPort);
    }

    private calculateViewPortHeight(numberOfItems: number): number {
        if (numberOfItems === this.itemsPerViewPort) {
            return this.viewPortHeight;
        }

        const rowCount = Math.ceil(numberOfItems / this.itemsPerRow);

        return rowCount * this.visibleItemHeight;
    }
}
