
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { ConfigService } from "../../../app/config.service";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { SearchRequest } from "./../../mail/shared/models/search-request.model";
import { CommonService } from "src/app/services/ common.service.";
import { AuthService } from "src/app/common/providers/auth.service";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";

@Injectable({ providedIn: "root" })

export class BriefcaseService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private commonService: CommonService,
    private mailBroadcaster: MailBroadcaster,
    private authService: AuthService
  ) { }

  isCordovaOrElectron = environment.isCordova || environment.isElectron;

  getBriefcaseFolders(body: any, isSingleNode: boolean): Observable<any> {
    return this.http
      .post(this.configService.API_URL + "/api/getFolderList", body, { headers: this.commonService.getZimbraHeader() })
      .pipe(
        map(res => {
          return this.commonService.parseFolders(isSingleNode, res, "document");
        }),
        catchError(this.authService.handleErrorObservable.bind(this))
      );
  }

  createFolder(body): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/createFolder", body, { headers: this.commonService.getZimbraHeader() }).
      pipe(map((res: any) => {
        return res.folder[0];
      }), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  uploadAttachment(file: any): Observable<any> {
    const formData = new FormData();
    formData.append("file", file);
    return this.http.post(
      this.configService.API_URL + "/api/upload",
      formData,
      {
        headers: this.commonService.getZimbraHeader().delete("Content-Type").delete("Accept"),
        responseType: "text"
      });
  }

  createBatchRequest (request: any ): Observable<any> {
    return this.http
      .post(this.configService.API_URL + "/api/batchRequest", request, { headers: this.commonService.getZimbraHeader() })
      .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }

  searchRequest(body: SearchRequest): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/searchRequest", body, { headers: this.commonService.getZimbraHeader() });
  }

  printDocument(body: any): Observable<any> {
    return this.http.get(this.configService.API_URL + "/api/printDocument?url=" + body.url,
    { headers: this.commonService.getZimbraHeader() });
  }

  itemAction(body: any): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/itemAction", body, { headers: this.commonService.getZimbraHeader() })
    .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getBriefcaseNotificationFolderIds(): Observable<any> {
    return this.http.get(
      this.configService.API_URL + "/api/getBriefcaseNotificationFolderIds", {
        headers: this.getAuthorizationToken(),
        responseType: "text"
      });
  }

  setBriefcaseNotificationFolderIds(ids: string): Observable<any> {
    return this.http.get(
      this.configService.API_URL + "/api/setBriefcaseNotificationFolderIds?id=" + ids, {
        headers: this.getAuthorizationToken(),
        responseType: "text"
      });
  }

  private getAuthorizationToken(): HttpHeaders {
    const headers: HttpHeaders = new HttpHeaders();
    if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        return headers.set("Authorization", token);
    }
    return headers;
  }

  getZimbraDocument(body: any): Observable<any> {
    return this.http.get(this.configService.API_URL + "/api/printDocument?url=" + body.url,
    {headers: this.commonService.getZimbraHeader().delete("Content-Type").delete("Accept"),
    responseType: "text" });
  }

}
