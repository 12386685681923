
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy, Inject, ChangeDetectionStrategy, ChangeDetectorRef, NgZone } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MailFolder } from "../../../models/mail-folder.model";
import { Subject } from "rxjs/internal/Subject";
import { takeUntil } from "rxjs/operators";
import { MailTag } from "src/app/mail/models/mail-tag.model";
import { NavigationExtras, Router } from "@angular/router";
import { MailConstants } from "../../../../common/utils/mail-constants";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { CommonRepository } from "../../../repositories/common-repository";
import { TranslateService } from "@ngx-translate/core";
import { CreateTagComponent } from "../../../create-tag/create-tag.component";
import { MobileTagListOperationComponent } from "../tag-list-operation/tag-list-operation.component";
import { CommonUtils } from "src/app/common/utils/common-util";
import { ConfigService } from "src/app/config.service";

const DEFAULT_COLOR = "#a2aab3";
const COLOR_LIST = [DEFAULT_COLOR, "blue", "cyan", "green", "purple", "red", "yellow", "pink", "gray", "orange"];

@Component({
  selector: "vp-mobile-tags-dialog",
  templateUrl: "./mobile-tags.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileTagsComponent implements OnInit, OnDestroy {

  mailFolders: MailFolder[] = [];
  private isAlive$ = new Subject<boolean>();
  rootUserFolders: MailFolder[] = [];
  globalOperationIcon = "add";
  tags: MailTag[] = [];
  searchText: string = "";
  constructor(
    private dialogRef: MatDialogRef<MobileTagsComponent>,
    private router: Router,
    private mailBroadcaster: MailBroadcaster,
    private commonRepository: CommonRepository,
    private changeDetectionRef: ChangeDetectorRef,
    private translate: TranslateService,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private configService: ConfigService
  ) {
    this.commonRepository.getTagsList().pipe(takeUntil(this.isAlive$))
      .subscribe(tags => {
        this.tags = tags.map(tag => {
          if (tag.color && COLOR_LIST[tag.color]) {
            tag.color = COLOR_LIST[tag.color];
          }
          if (tag.rgb) {
            tag.rgb = tag.rgb.toLowerCase();
            if (tag.rgb === "#999") {
              tag.rgb = "#999999";
            }
          }
          return tag;
        });
        this.changeDetectionRef.markForCheck();
      });
    this.changeDetectionRef.markForCheck();
  }

  ngOnInit() {
    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_TAG_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });
    if (CommonUtils.isOnIOS()) {
      setTimeout(() => {
        document.querySelector(".cdk-global-overlay-wrapper").removeAttribute("style");
      }, 50);
    }
  }

  openMailList(tag: MailTag): void {
    this.mailBroadcaster.broadcast(BroadcastKeys.HIDE_SIDEBAR_DRAWER);
    if (this.router.url === "/mail/tag/" + tag.name) {
      this.mailBroadcaster.broadcast(MailConstants.REFRESH_BROADCAST);
    }
    this.router.navigate(["/mail/tag", tag.name]);
    this.close();
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }

  createNewTag(): void {
      this.dialog.open(CreateTagComponent, {
          width: "325px",
          height: "212px",
          autoFocus: true,
          panelClass: "tag_create_dialog",
          data: {isRename: false }
      });
  }

  openTagListOperation(tag: MailTag): void {
    if (this.configService.useVNCdirectoryAuth) {
      return;
    }
    this.dialog.open(MobileTagListOperationComponent, {
      width: "325px",
      autoFocus: true,
      maxWidth: "70vw",
      panelClass: "tag_list_operation",
      data: { tag: tag }
    });
  }
}
