
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild, OnInit } from "@angular/core";
import { Http, Headers } from "@angular/http";
import { ConfigService } from "../../../config.service";
import { environment } from "../../../../environments/environment";
import { map, take } from "rxjs/operators";
import { ElectronService } from "src/app/services/electron.service";
import { TranslateService } from "@ngx-translate/core";
import { CommonUtils } from "src/app/common/utils/common-util";

@Component({
  selector: "vp-authentication",
  templateUrl: "./vnc-authentication.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class VNCAuthenticationComponent implements OnInit, AfterViewInit {
  backgroundImage = "url('assets/img/login-background.png')";
  serverURL = "https://mail.vnclagoon-live.com";
  @ViewChild("urlInput", {static: false}) urlInput: ElementRef;
  language: string = "en";
  constructor(private http: Http,
              private configService: ConfigService,
              private translate: TranslateService,
              private electronService: ElectronService) {
    const serverUrl = localStorage.getItem("serverURL");
    if (serverUrl !== null && serverUrl !== undefined ) {
      this.serverURL = serverUrl;
    }
    this.language = navigator.language.split("-")[0];
    if (["en", "de", "fr"].indexOf(this.language) === -1) {
      this.language = "en";
    }
    setTimeout(() => {
      this.setLanguage();
    }, 2000);
  }

  setLanguage() {
    this.translate.use(this.language);
    this.translate.reloadLang(this.language);
  }

  ngAfterViewInit() {
    this.setLanguage();
    console.log("[VNCAuthenticationComponent] ngAfterViewInit", new Date());
    if (typeof navigator !== "undefined" && navigator.splashscreen) {
      navigator.splashscreen.hide();
    }
    setTimeout(() => {
      this.urlInput.nativeElement.focus();
    }, 200);
  }


  getFullUrl(url: string) {
    const baseUrl = window.location.href;

    if (environment.isCordova) {
      return CommonUtils.getBaseUrl() + url;
    } else if (environment.isElectron) {
      return (baseUrl.includes("/index.html") ? baseUrl.split("/index.html")[0] : baseUrl.split("/mail")[0]) + url;
    } else {
      return url;
    }
  }

  ngOnInit() {
    console.log("[VNCAuthenticationComponent] ngOnInit", new Date());
  }

  loginNewServer() {
    if (!navigator.onLine) {
      this.translate.get("NO_INTERNET_CONNECTION").pipe(take(1)).subscribe(text => {
        alert(text);
      });
      return;
    }
    const headers = new Headers({ "Content-Type": "application/json" });
    if (this.serverURL.endsWith("/")) {
      this.serverURL = this.serverURL.substring(0, this.serverURL.lastIndexOf("/")).trim();
    }
    console.log("[Server URL] Click : ", this.serverURL);
    this.http.get(this.serverURL + "/api/config", { headers: headers }).pipe(map(response => response.json()))
      .subscribe(data => {
        if (data.boshURL && data.zimbraURL) {
          this.configService.API_URL = this.serverURL;
          this.setServerURL(this.configService.API_URL);
          if (!!data && data.avatarURL) {
            this.configService.set("avatarURL", data.avatarURL);
            this.configService.avatarURL = data.avatarURL;
            this.configService.updateConfig(data);
            localStorage.setItem("avatarURL", data.avatarURL);
          }

        } else {
          alert("The Server URL entered by you is wrong. Please provide correct URL");
        }
      }, () => {
        alert("The Server URL entered by you is wrong. Please provide correct URL");
      });
  }

  loginDemoServer() {
    // this.setServerURL(this.configService.DEMO_URL);
  }

  backToLogin() {
    this.configService.selectedServer = true;
    this.configService.loginIframe();
  }

  setServerURL(url) {
    if (url) {
      if (!url.startsWith("http")) {
        url = "";
      }
      this.configService.setAPIURL(url);
      let serverURL;
      // serverURL = localStorage.getItem("serverURL");
      localStorage.clear();
      if (this.electronService.isElectron) {
        this.electronService.clearStorage();
      }

      localStorage.setItem("serverURL", url.trim());
      serverURL = localStorage.getItem("serverURL");

      if (!!serverURL && serverURL !== null) {
        console.log("Server URL config redirect to Login screen");
        this.configService.selectedServer = true;
        this.configService.loginIframe();
      } else {
        console.log("Server URL config fail");
        window.location.href = CommonUtils.getBaseUrl() + "/index.html";
      }
    }
  }

}
