
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, Inject, NgZone, ChangeDetectorRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MailTag } from "../../../models/mail-tag.model";
import { CreateTagComponent } from "../../../create-tag/create-tag.component";
import {
    ConfirmationData,
    ConfirmationDialogComponent
} from "../../../../shared/components/confirmation-dialog/confirmation-dialog.component";
import { CommonRepository } from "../../../repositories/common-repository";
import { CommonUtils } from "../../../../common/utils/common-util";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { MailBroadcaster } from "../../../../common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "../../../../common/enums/broadcast.enum";
import { MailConstants } from "src/app/common/utils/mail-constants";

@Component({
    selector: "vp-tag-list-operation-dialog",
    templateUrl: "./tag-list-operation.component.html"
})
export class MobileTagListOperationComponent implements OnInit, OnDestroy {

    tag: MailTag;
    private isAlive$ = new Subject<boolean>();
    iscontextMenuActionsDisabled: boolean = true;

    constructor(
        private dialogRef: MatDialogRef<MobileTagListOperationComponent>,
        @Inject(MAT_DIALOG_DATA) public tagItem: any,
        private changeDetection: ChangeDetectorRef,
        private dialog: MatDialog,
        private commonRepository: CommonRepository,
        private mailBroadcaster: MailBroadcaster,
        private ngZone: NgZone
    ) {
        this.tag = tagItem.tag;
        if (CommonUtils.isOnIOS()) {
            setTimeout(() => {
                this.iscontextMenuActionsDisabled = false;
                this.changeDetection.markForCheck();
            }, 1000);
        } else {
            this.iscontextMenuActionsDisabled = false;
        }
    }

    ngOnInit() {
        this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_TAG_OPERATION_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.ngZone.run(() => {
                this.close();
            });
        });
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.unsubscribe();
    }

    close() {
        this.dialogRef.close();
    }

    renameTag() {
        this.dialog.open(CreateTagComponent, {
            width: "325px",
            height: "212px",
            autoFocus: true,
            panelClass: "tag_create_dialog",
            data: { tag: this.tag, isRename: true }
        });
        this.close();
    }

    deleteTag(): void {
        if (this.commonRepository.showNoInternetToastIfRequired()) {
            return;
        }

        const data: ConfirmationData = {
            action: "delete",
            titleKey: "WARNING",
            contentKey: "DELETE_TAG_CONFIRMATION",
            contentParam: { tagName: this.tag.name },
            actionKey: "YES"
        };
        this.openConfirmationDialog(data, { width: "300px" }).pipe(take(1)).subscribe(result => {
            if (result && result.confirmed) {
                this.commonRepository.deleteTag(this.tag.id).subscribe(tagId => {
                    console.log("[deleteTag]", tagId);
                    if (tagId !== null) {
                        this.mailBroadcaster.broadcast(MailConstants.REMOVED_TAG, this.tag);
                    }
                });
            }
        });
        this.close();
    }

    private openConfirmationDialog(data: ConfirmationData, style: any): Observable<any> {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: data,
            ...style
        });
        return dialogRef.afterClosed();
    }
}
