
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "src/app/actions";
import { CalendarEvent } from "calendar-utils";
import { Update } from "@ngrx/entity";
import { CalendarFolder } from "../common/models/calendar.model";

export enum CalendarActionTypes {
  LOAD_CALENDARS = "[Calendar] Load Calendars",
  UPDATE_CALENDAR = "[Calendar] Update Calendar",
  RESET_CALENDAR_STATE = "[Calendar] Reset Calendar State",
  SET_CALENDAR_FOLDERS = "[Calendar] Set Calendar Folders",
  SET_CALENDAR_APPOINTMENTS = "[Calendar] Set Calendar Appointments",
  SET_CALENDAR_APPOINTMENT = "[Calendar] Set Calendar Appointment",
  REMOVE_CALENDAR_APPOINTMENT = "[Calendar] Remove Calendar Appointment",
  UPDATE_CALENDAR_APPOINTMENT = "[Calendar] Update Calendar Appointment",
  UPDATE_CALENDAR_FOLDER = "[Calendar] Update Calendar Folder",
  CREATE_CALENDAR_FOLDER_SUCCESS = "[FOLDERS] Create Calendar Folder Success",
  UPDATE_CALENDAR_FOLDER_SUCCESS = "[FOLDERS] Update Calendar Folder Success",
  DELETE_CALENDAR_FOLDER_SUCCESS = "[FOLDERS] Delete Calendar Folder Success"
}

export enum CalendarAppointmentActionTypes {
  SELECT_CALENDAR_APPOINTMENT = "[CALENDAR APPOINTMENTS] Select Calendar Appointment",
  LOAD_CALENDAR_APPOINTMENTS = "[CALENDAR APPOINTMENTS] Load Calendar Appointments",
  LOAD_CALENDAR_APPOINTMENTS_SUCCESS = "[CALENDAR APPOINTMENTS] Load Calendar Appointments Success",
  LOAD_CALENDAR_APPOINTMENTS_FAIL = "[CALENDAR APPOINTMENTS] Load Calendar Appointments Fail",

  LOAD_MORE_CALENDAR_APPOINTMENTS = "[CALENDAR APPOINTMENTS] Load More Calendar Appointments",
  LOAD_MORE_CALENDAR_APPOINTMENTS_SUCCESS = "[CALENDAR APPOINTMENTS] Load More Calendar Appointments Success",
  LOAD_MORE_CALENDAR_APPOINTMENTS_FAIL = "[CALENDAR APPOINTMENTS] Load More Calendar Appointments Fail",

  CREATE_CALENDAR_APPOINTMENT = "[CALENDAR APPOINTMENTS] Create Calendar Appointment",
  CREATE_CALENDAR_APPOINTMENT_FAIL = "[CALENDAR APPOINTMENTS] Create Calendar Appointment Fail",
  CREATE_CALENDAR_APPOINTMENT_SUCCESS = "[CALENDAR APPOINTMENTS] Create Calendar Appointment Success",

  DELETE_CALENDAR_APPOINTMENTS = "[CALENDAR APPOINTMENTS] Delete Calendar Appointments",
  DELETE_CALENDAR_APPOINTMENTS_FAIL = "[CALENDAR APPOINTMENTS] Delete Calendar Appointments Fail",
  DELETE_CALENDAR_APPOINTMENTS_SUCCESS = "[CALENDAR APPOINTMENTS] Delete Calendar Appointments Success",

  DELETE_CALENDAR_APPOINTMENT = "[CALENDAR APPOINTMENTS] Delete Calendar Appointment",
  DELETE_CALENDAR_APPOINTMENT_FAIL = "[CALENDAR APPOINTMENTS] Delete Calendar Appointment Fail",
  DELETE_CALENDAR_APPOINTMENT_SUCCESS = "[CALENDAR APPOINTMENTS] Delete Calendar Appointment Success",

  UPDATE_CALENDAR_APPOINTMENT = "[CALENDAR APPOINTMENTS] Update Calendar Appointment",
  UPDATE_CALENDAR_APPOINTMENT_FAIL = "[CALENDAR APPOINTMENTS] Update Calendar Appointment Fail",
  UPDATE_CALENDAR_APPOINTMENT_SUCCESS = "[CALENDAR APPOINTMENTS] Update Calendar Appointment Success",

  UPDATE_CALENDAR_APPOINTMENTS = "[CALENDAR APPOINTMENTS] Update Calendar Appointments",
  UPDATE_CALENDAR_APPOINTMENTS_FAIL = "[CALENDAR APPOINTMENTS] Update Calendar Appointments Fail",
  UPDATE_CALENDAR_APPOINTMENTS_SUCCESS = "[CALENDAR APPOINTMENTS] Update Calendar Appointments Success",

  RESET_CALENDAR_APPOINTMENTS = "[CALENDAR APPOINTMENTS] RESET Calendar Appointments",
  RESET_CALENDAR_APPOINTMENT_STATE = "[CALENDAR APPOINTMENTS] Reset State",

  LOAD_DELETED_CALENDAR_APPOINTMENTS_SUCCESS = "[CALENDAR APPOINTMENTS] Load Deleted Calendar Appointments Success",
}

export class LoadCalendarAppointmentsAction implements Action {
  readonly type = CalendarAppointmentActionTypes.LOAD_CALENDAR_APPOINTMENTS;
}

export class LoadCalendarAppointmentsSuccessAction implements Action {
  readonly type = CalendarAppointmentActionTypes.LOAD_CALENDAR_APPOINTMENTS_SUCCESS;
  constructor(public payload: { appointments: CalendarEvent[] }) { }
}

export class LoadCalendarAppointmentsFailedAction implements Action {
  readonly type = CalendarAppointmentActionTypes.LOAD_CALENDAR_APPOINTMENTS_FAIL;
}

export class LoadMoreCalendarAppointmentsAction implements Action {
  readonly type = CalendarAppointmentActionTypes.LOAD_MORE_CALENDAR_APPOINTMENTS;
}

export class LoadMoreCalendarAppointmentsSuccessAction implements Action {
  readonly type = CalendarAppointmentActionTypes.LOAD_MORE_CALENDAR_APPOINTMENTS_SUCCESS;
  constructor(public payload: { appointments: CalendarEvent[] }) { }
}

export class LoadMoreCalendarAppointmentsFailedAction implements Action {
  readonly type = CalendarAppointmentActionTypes.LOAD_MORE_CALENDAR_APPOINTMENTS_FAIL;
}

export class CreateCalendarAppointmentAction implements Action {
  readonly type = CalendarAppointmentActionTypes.CREATE_CALENDAR_APPOINTMENT;
}

export class CreateCalendarAppointmentSuccessAction implements Action {
  readonly type = CalendarAppointmentActionTypes.CREATE_CALENDAR_APPOINTMENT_SUCCESS;
  constructor(public payload: { appointment: CalendarEvent }) { }
}

export class CreateCalendarAppointmentFailAction implements Action {
  readonly type = CalendarAppointmentActionTypes.CREATE_CALENDAR_APPOINTMENT_FAIL;
}

export class UpdateCalendarAppointmentAction implements Action {
  readonly type = CalendarAppointmentActionTypes.UPDATE_CALENDAR_APPOINTMENT;
}

export class UpdateCalendarAppointmentSuccessAction implements Action {
  readonly type = CalendarAppointmentActionTypes.UPDATE_CALENDAR_APPOINTMENT_SUCCESS;
  constructor(public payload: Update<CalendarEvent>) { }
}

export class UpdateCalendarAppointmentFailAction implements Action {
  readonly type = CalendarAppointmentActionTypes.UPDATE_CALENDAR_APPOINTMENT_FAIL;
}

export class UpdateCalendarAppointmentsAction implements Action {
  readonly type = CalendarAppointmentActionTypes.UPDATE_CALENDAR_APPOINTMENTS;
}

export class UpdateCalendarAppointmentsSuccessAction implements Action {
  readonly type = CalendarAppointmentActionTypes.UPDATE_CALENDAR_APPOINTMENTS_SUCCESS;
  constructor(public payload: CalendarEvent[]) { }
}

export class UpdateCalendarAppointmentsFailAction implements Action {
  readonly type = CalendarAppointmentActionTypes.UPDATE_CALENDAR_APPOINTMENTS_FAIL;
}

export class DeleteCalendarAppointmentsAction implements Action {
  readonly type = CalendarAppointmentActionTypes.DELETE_CALENDAR_APPOINTMENTS;
}

export class DeleteCalendarAppointmentsSuccessAction implements Action {
  readonly type = CalendarAppointmentActionTypes.DELETE_CALENDAR_APPOINTMENTS_SUCCESS;
  constructor(public payload: { appointmentIds: string[] }) { }
}

export class DeleteCalendarAppointmentsFailAction implements Action {
  readonly type = CalendarAppointmentActionTypes.DELETE_CALENDAR_APPOINTMENTS_FAIL;
}

export class DeleteCalendarAppointmentAction implements Action {
  readonly type = CalendarAppointmentActionTypes.DELETE_CALENDAR_APPOINTMENT;
}

export class DeleteCalendarAppointmentSuccessAction implements Action {
  readonly type = CalendarAppointmentActionTypes.DELETE_CALENDAR_APPOINTMENT_SUCCESS;
  constructor(public payload: { appointment: CalendarEvent }) { }
}

export class DeleteCalendarAppointmentFailAction implements Action {
  readonly type = CalendarAppointmentActionTypes.DELETE_CALENDAR_APPOINTMENT_FAIL;
}

export class ResetCalendarAppointmentsAction implements Action {
  readonly type = CalendarAppointmentActionTypes.RESET_CALENDAR_APPOINTMENTS;
  constructor() {}
}

export class SetCalendarFolders implements Action {
  readonly type = CalendarActionTypes.SET_CALENDAR_FOLDERS;
  constructor(public payload: CalendarFolder[]) { }
}

export class UpdateCalendarFolder implements Action {
  readonly type = CalendarActionTypes.UPDATE_CALENDAR_FOLDER;
  constructor(public payload: Update<CalendarFolder>) { }
}

export class CreateCalendarFolderSuccess implements Action {
  readonly type = CalendarActionTypes.CREATE_CALENDAR_FOLDER_SUCCESS;
  constructor(public payload: { folder: CalendarFolder }) {}
}

export class UpdateCalendarFolderSuccess implements Action {
  readonly type = CalendarActionTypes.UPDATE_CALENDAR_FOLDER_SUCCESS;
  constructor(public payload: Update<CalendarFolder>) {}
}

export class DeleteCalendarFolderSuccess implements Action {
  readonly type = CalendarActionTypes.DELETE_CALENDAR_FOLDER_SUCCESS;
  constructor(public payload: { folder: CalendarFolder }) {}
}

export class SetCalendarAppointments implements Action {
  readonly type = CalendarActionTypes.SET_CALENDAR_APPOINTMENTS;
  constructor(public payload: CalendarEvent[]) { }
}

export class SetCalendarAppointment implements Action {
  readonly type = CalendarActionTypes.SET_CALENDAR_APPOINTMENT;
  constructor(public payload: CalendarEvent) { }
}

export class RemoveCalendarAppointment implements Action {
  readonly type = CalendarActionTypes.REMOVE_CALENDAR_APPOINTMENT;
  constructor(public payload: CalendarEvent) { }
}

export class UpdateCalendarAppointment implements Action {
  readonly type = CalendarActionTypes.UPDATE_CALENDAR_APPOINTMENT;
  constructor(public payload: CalendarEvent) { }
}

export class LoadCalendars implements Action {
  readonly type = CalendarActionTypes.LOAD_CALENDARS;
  constructor() { }
}

export class UpdateCalendar implements Action {
  readonly type = CalendarActionTypes.UPDATE_CALENDAR;
  constructor() { }
}

export class LoadDeletedCalendarAppointmentsSuccessAction implements Action {
  readonly type = CalendarAppointmentActionTypes.LOAD_DELETED_CALENDAR_APPOINTMENTS_SUCCESS;
  constructor(public payload: CalendarEvent[] ) { }
}


export type CalendarActions = LoadCalendars | UpdateCalendar;

