import { SQLDBInterface } from "./sql.db.interface";

export class CordovaDB implements SQLDBInterface {
    private sqliteDB: any;

    constructor(dbName: string) {
        const params = {
            name: dbName,
            location: "default"
        };

        try {
            this.sqliteDB = window.sqlitePlugin.openDatabase(params);
        } catch (error) {
            console.error("[CordovaDB] Open database ERROR: " + JSON.stringify(error));
        }

        console.log("[CordovaDB] constructor done, sqliteDB = ", this.sqliteDB);
    }

    close(success: (result?: any) => void, error: (err?: any) => void): void {
        this.sqliteDB.close(success, error);
    }

    deleteDatabase(dbName: String, callback: (error?: any) => void): void {
        this.sqliteDB.deleteDatabase(dbName, callback);
    }

    transaction(callback: any, error?: any, success?: any): void {
        this.sqliteDB.transaction(callback, error, success);
    }

    sqlBatch(queries: String[], success: (result?: any) => void, error: (err?: any) => void): void {
        this.sqliteDB.sqlBatch(queries, success, error);
    }

    executeSql(query: String, params: any[], success: (result?: any) => void, error: (err?: any) => void): void {
        this.sqliteDB.executeSql(query, params, success, error);
    }

    readTransaction(callback: (executor: any) => void, success: (result?: any) => void, error: (err?: any) => void): void {
        this.sqliteDB.readTransaction(callback, success, error);
    }
}
