
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vnc-authentication" [style.backgroundImage]="backgroundImage">

  <form novalidate>
    <h5>{{ 'CHANGE_SERVER_URL' | translate }}</h5>
    <div class="form-control">
      <input required type="url" #urlInput pattern="(http|https)://[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?" placeholder="{{ 'CHANGE_SERVER_URL' | translate }}" #url="ngModel" name="serverURL" value="https://groupware.vnclagoon.com" [(ngModel)]="serverURL">    </div>
    <div class="form-control">
      <button [disabled]="!url.valid" class="login-server" (click)="loginNewServer()">{{ 'CHANGE' | translate }}</button>
    </div>
    <div class="back-to-login">
      <button (click)="backToLogin()">{{ 'BACK_TO_LOGIN' | translate }}</button>
    </div>
  </form>
</div>
