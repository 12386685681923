
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject, NgZone, OnInit, OnDestroy, Component, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import * as moment from "moment";
import { CalenderUtils } from "src/app/calendar/utils/calender-utils";
import { environment } from "src/environments/environment";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { AppService } from "src/app/services/app.service";
import { take, takeUntil } from "rxjs/operators";
import { ToastService } from "src/app/common/providers/toast.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { Subject } from "rxjs";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { CommonService } from "src/app/services/ common.service.";

@Component({
    selector: "vp-appointment-invite-operation-dialog",
    templateUrl: "./event-invite-operation-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppointmentInviteReplyDialogComponent implements OnInit, OnDestroy {
    appointment: any;
    startDate: Date;
    endDate: Date;
    description: string = "";
    locale: string = "en";
    replyType: string = "";
    private isAlive$ = new Subject<boolean>();
    constructor(
        private dialogRef: MatDialogRef<AppointmentInviteReplyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private ngZone: NgZone,
        private changeDetector: ChangeDetectorRef,
        private appService: AppService,
        private toastService: ToastService,
        private broadcaster: MailBroadcaster,
        private commonService: CommonService
    ) {
        if (this.data.message.inv) {
            this.appointment = this.getInviteInformation(this.data.message);
            this.appointment.id = this.data.message.id;
            this.changeDetector.markForCheck();
            this.setAppointmentData();
        } else {
            this.close();
        }
    }
    ngOnInit() {
        const language = environment.isElectron
            ? localStorage.getItem(MailConstants.MAIL_LANGUAGE) : localStorage.getItem(MailConstants.MAIL_LANGUAGE);
        if (!!language && language !== null) {
            this.locale = language;
        } else {
            this.locale = "en";
        }
        this.broadcaster.on<any>(BroadcastKeys.HIDE_APPOINTMENT_INVITE_DIALOG_OPERATION).pipe(takeUntil(this.isAlive$)).subscribe(data => {
            this.ngZone.run(() => {
                this.close();
            });
        });
        this.changeDetector.markForCheck();
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    close(): void {
        this.dialogRef.close();
    }

    getInviteInformation(appointmentResponse: any): any {
        const appointMent: any = {};
        if (appointmentResponse.inv && appointmentResponse.inv[0].comp[0]) {
            const component = appointmentResponse.inv[0].comp[0];
            appointMent.alarmData = component.alarm;
            appointMent.apptId = component.apptId;
            if (component.at) {
                appointMent.at = component.at;
            }
            appointMent.calItemId = component.calItemId;
            appointMent.ciFolder = component.ciFolder;
            appointMent.class = component.class;
            appointMent.compNum = component.compNum;
            appointMent.desc = "";
            appointMent.descHTML = "";
            if (component.desc) {
                appointMent.desc = component.desc[0]._content;
            }
            if (component.descHtml) {
                appointMent.descHTML = component.descHtml[0]._content;
            }
            if (component.draft) {
                appointMent.draft = component.draft;
            }
            appointMent.fb = component.fb;
            appointMent.fba = component.fba;
            appointMent.name = component.name;
            if (component.or) {
                appointMent.or = component.or;
            }
            if (component.s) {
                appointMent.startDateData = component.s;
            }
            if (component.e) {
                appointMent.endDateData = component.e;
            }
            appointMent.seq = component.seq;
            appointMent.status = component.status;
            appointMent.transp = component.transp;
            appointMent.uid = component.uid;
            appointMent.x_uid = component.x_uid;
            appointMent.xprop = component.xprop;
            appointMent.url = component.url;
            appointMent.isOrg = component.isOrg;
            appointMent.allDay = false;
            if (component.allDay) {
                appointMent.allDay = component.allDay;
            }
            appointMent.loc = "";
            if (component.loc) {
                appointMent.loc = component.loc;
            }
            if (component.recur) {
                appointMent.recur = component.recur;
            }
            if (component.neverSent) {
                appointMent.neverSent = component.neverSent;
            }
            appointMent.isOrg = component.isOrg;
        }
        if (appointmentResponse.inv && appointmentResponse.inv[0].replies) {
            const reply = appointmentResponse.inv[0].replies[0];
            appointMent.reply = reply.reply;
        }
        return appointMent;
    }

    getAvatar(email): any {
        return null;
    }

    setAppointmentData(): void {
        if (this.appointment.reply) {
            this.replyType = this.appointment.reply[0].ptst;
        }
        const startDT = this.appointment.startDateData[0].d;
        this.startDate = moment(startDT).toDate();
        const endDT = this.appointment.endDateData[0].d;
        this.endDate = moment(endDT).toDate();
        let body: string = "";
        if (this.appointment.descHTML) {
            body = this.appointment.descHTML;
        } else if (this.appointment.desc) {
            body = CalenderUtils.plainTextToHTML(this.appointment.desc);
        }
        if (body.indexOf("~*~*~*~*~*~*~*~*") > -1 || body.indexOf("~ ~ ~ ~ ~ ~ ~ ~ ~") > -1) {
            if (body.indexOf("*~*~*~*~*~*~*~*~*~*") !== -1) {
                body = body.replace("</body></html>", "").split("<div>*~*~*~*~*~*~*~*~*~*</div><br>")[1] ||
                    body.split("*~*~*~*~*~*~*~*~*~*")[1] || "";
            }
            if (body.indexOf("~ ~ ~ ~ ~ ~ ~ ~ ~") !== -1) {
                body = body.replace("</body></html>", "").split("~ ~ ~ ~ ~ ~ ~ ~ ~")[1] || "";
            }
        }
        this.description = MailUtils.linkifyHtml(body);
    }

    sendReplyRequest(verb: string): void {
        const body: any = {
            id: this.appointment.id,
            verb: verb,
            updateOrganizer: "TRUE"
        };
        this.commonService.sendInviteReply(body).pipe(take(1)).subscribe(res => {
            if (verb === "ACCEPT") {
                this.toastService.show("CALENDARS.ACCEPT_APPOINTMENT_MSG");
            } else if (verb === "DECLINE") {
                this.toastService.show("CALENDARS.DECLINE_APPOINTMENT_MSG");
            } else if (verb === "TENTATIVE") {
                this.toastService.show("CALENDARS.TENTETIVE_APPOINTMENT_MSG");
            }
            this.close();
        }, error => {
            this.toastService.showPlainMessage(error);
        });
    }
}
