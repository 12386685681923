
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import * as fromBriefcase from "../reducers";
import * as fromBriefcaseFolder from "../reducers/briefcase-folder.reducers";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { Dictionary } from "@ngrx/entity";
import { BriefCase } from "../../shared/models/briefcase.model";
import { BriefcaseFolder } from "../../shared/models/briefcase-folder";
import * as _ from "lodash";

export interface BriefcaseRootState {
    briefcase: fromBriefcase.BriefCaseState;
    briefcaseFolder: fromBriefcaseFolder.BriefCaseFolderState;
}

export const getBriefcaseRootState = createFeatureSelector<BriefcaseRootState>("briefcase");

export const briefcaseRootReducer = {
    briefcase: fromBriefcase.referenceReducer,
    briefcaseFolder: fromBriefcaseFolder.referenceReducer,
};

export const getBriefcaseState = createSelector(
    getBriefcaseRootState,
    state => state.briefcase
);

export const {
    selectIds: getBriefcaseIds,
    selectEntities: getBriefcaseEntities,
    selectAll: getAllBriefcases,
    selectTotal: getTotalBriefcases,
} = fromBriefcase.briefcaseAdapter.getSelectors(getBriefcaseState);

export const getSelectedFileIds = createSelector(
    getBriefcaseState,
    fromBriefcase._getSelectedFileIds
);

export const getCheckFileIds = createSelector(
    getBriefcaseState,
    fromBriefcase._getCheckedFileIds
);

export const getBriefcaseFilesByIds = createSelector(
    getBriefcaseEntities,
    (entities: Dictionary<BriefCase>, briefcaseFileIds: string[]) => {
      return briefcaseFileIds.map(fileId => {
        return entities[fileId];
      });
    }
  );

export const getHasMoreFiles = createSelector(
  getBriefcaseState,
  fromBriefcase._getHasMoreFiles
);

export const getIsFileLoading = createSelector(
  getBriefcaseState,
  fromBriefcase._getIsFileLoading
);

export const getBriefcases = getAllBriefcases;

// BRIEFCASE FOLDERS
export const getBriefcaseFolderState = createSelector(
    getBriefcaseRootState,
    (state: BriefcaseRootState) => state.briefcaseFolder
);

export const getSelectedBriefcaseFolder = createSelector(
    getBriefcaseFolderState,
    (state: fromBriefcaseFolder.BriefCaseFolderState) => state.selectedBriefcaseFolder
);

export const getLoader = createSelector(
    getBriefcaseFolderState,
    (state: fromBriefcaseFolder.BriefCaseFolderState) => state.isLoading
);


export const {
    selectIds: getBriefcaseFolderIds,
    selectEntities: getBriefcaseFolderEntities,
    selectAll: getAllBriefcaseFolders,
    selectTotal: getTotalBriefcaseFolders,
} = fromBriefcaseFolder.briefcaseFolderAdapter.getSelectors(getBriefcaseFolderState);


export const getBriefcaseFolderById = createSelector(
    getBriefcaseFolderEntities,
    (entities: Dictionary<BriefcaseFolder>, folderId: string) => {
        return entities[folderId];
    }
);

export const getBriefcaseFolders = getAllBriefcaseFolders;

function getBriefcaseChildFolders(folders: BriefcaseFolder[]): BriefcaseFolder[] {
    let allFolders: BriefcaseFolder[] = [];
    let childFolders: BriefcaseFolder[] = [];
    folders.filter(f => f.children && f.children.length > 0).forEach(f => {
      childFolders = [...childFolders, ...f.children];
      allFolders = getBriefcaseChildFolders(childFolders);
    });
    return [...allFolders, ...childFolders];
  }

  export const getBriefcaseChildFolderById = createSelector(
    getAllBriefcaseFolders,
    (folders: BriefcaseFolder[], folderId: string) => {
      const childFolders = getBriefcaseChildFolders(folders);
      return _.find(childFolders, {id: folderId});
    }
  );

  export const getBriefcaseSharedFolderById = createSelector(
    getAllBriefcaseFolders,
    (folders: BriefcaseFolder[], sharedId: string) => {
      const childFolders = getBriefcaseChildFolders(folders);
      const zid = sharedId.split(":")[0];
      const rid = sharedId.split(":")[1];
      const allFolders = [...folders.filter(f => f.owner), ...childFolders];
      return _.find(allFolders, {zid: zid, rid: rid});
    }
  );
