
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { EventEmitter, Injectable } from "@angular/core";
import { Notification, NotificationEvent } from "./notifications.model";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs/operators";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { Router } from "@angular/router";
import { ElectronService } from "src/app/services/electron.service";
import { MailBroadcaster } from "../../../common/providers/mail-broadcaster.service";
import { Store, select } from "@ngrx/store";
import { MailRootState } from "../../../mail/store";
import { getSharedFolderById, getMailFolders } from "../../../mail/store/selectors";
import { MailUtils } from "src/app/mail/utils/mail-utils";

@Injectable()
export class VNCNotificationsService {
  id: string;
  emitter: Subject<NotificationEvent> = new Subject<NotificationEvent>();
  private receiveMessage: HTMLAudioElement;
  flashTitleInterval: any;
  constructor(
    private titleService: Title,
    private translate: TranslateService,
    private router: Router,
    private electronService: ElectronService,
    private broadcaster: MailBroadcaster,
    private store: Store<MailRootState>) {
    if (environment.isCordova) {
      document.addEventListener("deviceready", this.deviceReady.bind(this), false);
    } else {
      this.initAudioFilesForBrowser();
    }
  }


  set(notification: Notification, to: boolean) {
    notification.id = Math.random().toString(36).substring(3);
    if (notification.extraData && notification.extraData.id) {
      notification.id = notification.extraData.id;
    }
    notification.click = new EventEmitter<{}>();
    this.emitter.next({ command: "set", notification: notification, add: to });
    return notification;
  }

  getChangeEmitter() {
    return this.emitter;
  }

  flashTitle(key: string): void {
    this.translate.get(key).pipe(take(1)).subscribe(val => {
      if (this.flashTitleInterval) {
        clearInterval(this.flashTitleInterval);
      }
      const originalTitle = document.querySelector("title").innerText;
      this.flashTitleInterval = setInterval(() => {
        this.titleService.setTitle(val);
        setTimeout(() => {
          this.titleService.setTitle(originalTitle);
        }, 1000);
      }, 2000);
      setTimeout(() => {
        if (this.flashTitleInterval) {
          clearInterval(this.flashTitleInterval);
        }
        this.titleService.setTitle(originalTitle);
      }, 10000);
    });
  }

  //// Access methods
  newConversation(title: string, content: string, extraData?: any): void {
    if (!environment.isCordova && typeof ChromeNotification !== "undefined") {
      const _createChromeNotification = () => {
        const sticky = false;
        const notification = new ChromeNotification(title, {
          body: content,
          data: extraData,
          requireInteraction: sticky
        });
        notification.onclick = () => {
          if (this.electronService.isElectron) {
            this.electronService.showApp();
          } else {
            window.focus();
          }
          notification.close();
          if (extraData) {
            if (extraData.cid) {
              this.openMessageDetail(extraData);
            } else {
              this.openMailDetail(extraData);
            }
          }
        };
        setTimeout(() => notification.close(), 5000);
      };
      if (ChromeNotification.permission !== "granted") {
        ChromeNotification.requestPermission(permission => {
          if (permission === "granted") {
            _createChromeNotification();
          }
        });
      } else {
        _createChromeNotification();
      }
    }
    if (environment.isCordova && MailUtils.isOnAndroid()) {
      console.log("[scheduleLocalNotification]", extraData);
      // const mid = extraData.id;
      // const type = extraData.cid ? "message" : "conversation";
      // this.scheduleLocalMailNotification(extraData.fromDisplay, mid, title, content, extraData.l, type);
    } else {
      this.set({
        title: title,
        content: content,
        type: !!extraData && extraData.cid ? "message" : "conversation",
        extraData: extraData
      }, true);
    }
  }

  openMessageDetail(message: any): void {
    this.broadcaster.broadcast(MailConstants.BROADCAST_MAIL_SELECTED_TAB);
    if (environment.isCordova || this.electronService.isElectron) {
      this.broadcaster.broadcast("RESET_SELECTION");
    }
    let folderId = message.l, messageId = message.id;

    if (folderId.toString().indexOf(":") !== -1) {
      this.store.pipe(select(getMailFolders), take(1)).subscribe(f => {
        if (!!f) {
          const zid = folderId.split(":")[0];
          const rid = folderId.split(":")[1];
          f.map( folder => {
            if (folder.rid && !!folder.rid) {
              if (folder.zid === zid && folder.rid.toString() === rid) {
                folderId = folder.id;
              }
            }
          });
        }
      });
    } else {
      folderId =  +folderId;
      messageId = +messageId;
    }
    this.broadcaster.broadcast("MARK_AS_READ", {isMessage: true, id: messageId});
    const url = MailUtils.getMessageDetailRoute(folderId, messageId);
    this.router.navigate([url]);

  }
  openMailDetail(conversation: any): void {
    this.broadcaster.broadcast(MailConstants.BROADCAST_MAIL_SELECTED_TAB);
    if (environment.isCordova || this.electronService.isElectron) {
      this.broadcaster.broadcast("RESET_SELECTION");
    }
    let folderId = conversation.l || conversation.m[0].l;
    if (folderId.toString().indexOf(":") !== -1) {
      this.store.pipe(select(getMailFolders), take(1)).subscribe(f => {
        if (!!f) {
          const zid = folderId.split(":")[0];
          const rid = folderId.split(":")[1];
          f.map( folder => {
            if (folder.rid && !!folder.rid) {
              if (folder.zid === zid && folder.rid.toString() === rid) {
                folderId = folder.id;
              }
            }
          });
        }
      });
    } else {
      folderId =  +folderId;
    }
    const conversationId = conversation.cid ? conversation.cid : conversation.id;
    this.broadcaster.broadcast("MARK_AS_READ", {isMessage: false, id: conversationId});
    const url = MailUtils.getConversationDetailRoute(folderId, conversationId);
    this.router.navigate([url]);
  }
  // With type method
  create(title: string, content: string, type: string, extraData?: any) {
    return this.set({ title: title, content: content, type: type, extraData: extraData }, true);
  }

  // HTML Notification method
  html(title: string, html: any, type: string, extraData?: any) {
    return this.set({ title: title, html: html, type: type, extraData: extraData }, true);
  }

  // Remove all notifications method
  remove(id?: string) {
    if (id) {
      this.emitter.next({ command: "clean", id: id });
    } else {
      this.emitter.next({ command: "cleanAll" });
    }
  }

  deviceReady() {
    // for Cordova only env
    if (cordova.plugins && cordova.plugins.NativeAudio) {
      this.initAudioFilesForCordova();
    } else {
      console.log("NativeAudio plugin API is not available");
      this.initAudioFilesForBrowser();
    }
  }

  public playReceiveMessage() {
    console.log("NativeAudio playReceiveMessage " + environment.isCordova + " " + window.appInBackground);
    if (environment.isCordova) {
      if (!window.appInBackground) {
        this.playAudioCordova("alert");
      }
    } else {
      this.playAudioBrowser(this.receiveMessage);
    }
  }

  private initAudioFilesForBrowser() {
    this.receiveMessage = this.loadAudio("assets/sounds/alert.mp3");
  }

  private initAudioFilesForCordova() {
    console.log("NativeAudio NativeAudio preloadSimple alert");
    cordova.plugins.NativeAudio.preloadSimple("alert", "assets/sounds/alert.mp3", function (res) {
      console.log("NativeAudio preloadSimple alert result: " + res);
    }, function (error) {
      console.log("NativeAudio preloadSimple alert error: " + error);
    });
  }

  private loadAudio(path: string): HTMLAudioElement {
    const audio = new Audio(path);
    audio.preload = "auto";
    audio.load();
    return audio;
  }

  private playAudioBrowser(audioElement: HTMLAudioElement) {
    const audio = audioElement.cloneNode() as HTMLAudioElement;
    audio.addEventListener("ended", () => {
      audio.pause();
      audio.currentTime = 0;
      audio.remove();
    }, false);
    audio.play();
  }

  private playAudioCordova(type) {
    console.log("NativeAudio playAudioCordova " + type);
    cordova.plugins.NativeAudio.play(type);
  }

  scheduleLocalMailNotification(fromDisplay: string, mid: string, title: string, message: string,
    folderId: string, type: string, cid: string, fromAddress: string): void {
    if (window.FirebasePlugin && window.FirebasePlugin.scheduleLocalMailNotification) {
      const options = {
        mid,
        fromDisplay,
        fromAddress,
        title,
        body: message,
        subject: title,
        folderId: folderId,
        type,
        cid
      };
      window.FirebasePlugin.scheduleLocalMailNotification(options, () => {
        console.log("[scheduleLocalMailNotification] success", options);
      }, error => {
        console.log("[scheduleLocalMailNotification] error", error);
      });
    }
  }

  scheduleCalendarNotification(fromDisplay: string, mid: string, title: string, message: string,
    folderId: string, type: string, cid: string, fromAddress: string, notificationType: string, appointmentId: string): void {
    if (window.FirebasePlugin && window.FirebasePlugin.scheduleCalendarNotification) {
      const options = {
        mid: mid,
        fromDisplay: fromDisplay,
        fromAddress: fromAddress,
        title: title,
        body: message,
        subject: title,
        folderId: folderId,
        cid: cid,
        ntype: type,
        type: notificationType,
        appointmentId: appointmentId,
        notificationType: notificationType
      };
      console.log("[scheduleCalendarNotification] options: ", options);
      window.FirebasePlugin.scheduleCalendarNotification(options, () => {
        console.log("[scheduleCalendarNotification] success", options);
      }, error => {
        console.log("[scheduleCalendarNotification] error", error);
      });
    }
  }

}
