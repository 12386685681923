
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { ContactConstants } from "../shared/contacts-constants";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { ConfigService } from "src/app/config.service";

@Injectable()
export class MessageTranslatorService {
    private translationMessage: any = {};
    tarnslatedMessagesd$: Subject<any> = new Subject();

    constructor(
        private configService: ConfigService,
        private translate: TranslateService,
    ) {
        console.log("[MessageTranslatorService]");

        this.updateTranslatedMessages();
        this.configService.currentLanguage.subscribe((lang: string) => {
            setTimeout(() => { this.updateTranslatedMessages(); }, 1000);
        });
    }

    getTranslation(key: string): any {
        return this.translationMessage[key];
    }

    getTranslations(): any {
        return this.translationMessage;
    }

    getTranslateByKey(key: string) {
        return this.translate.get(key);
    }

    getTranslateByKeys(keys: string[]) {
        return this.translate.get(keys);
    }

    private updateTranslatedMessages() {
        console.log("[updateTranslatedMessages]");
        this.translate.get([
            ContactConstants.SOME_UNKNOWN_ERROR,
            ContactConstants.SEARCH_NOT_ALLOWED_CHARACTER,
            ContactConstants.EDIT_CONTACT,
            ContactConstants.DELETE,
            ContactConstants.CONFIRM_DELETE_CONTACT,
            ContactConstants.UNDO,
            ContactConstants.SOME_UNKNOWN_ERROR,
            ContactConstants.CONFIRM_DELETE_CONTACT_PERMANENTLY,
            ContactConstants.DELETE_CONTACT_HEADER,
            ContactConstants.SEARCH_EXIST_MSG,
            ContactConstants.SEARCH_SAVED_MSG,
            ContactConstants.EMPTY_SEARCH_ERROR,
            ContactConstants.A_Z_FIRST_NAME,
            ContactConstants.Z_A_FIRST_NAME,
            ContactConstants.A_Z_LAST_NAME,
            ContactConstants.Z_A_LAST_NAME,
            ContactConstants.CONTACT_FAVORITE_SUCCESS_MSG,
            ContactConstants.CONTACT_FAVOURITE_REMOVE_SUCCESS_MSG,
            ContactConstants.PHONE_TYPE,
            ContactConstants.ADDRESS_TYPE,
            ContactConstants.IM_TYPE,
            ContactConstants.CONTACT_EVENT_TYPE,
            ContactConstants.URL_TYPE,
            ContactConstants.IMAGE_UPLOAD_MSG,
            ContactConstants.MORE_TITLE,
            ContactConstants.LESS_TITLE,
            "AVTAR_SIZE_ERR",
            "FIRST_NAME_REQUIRED",
            ContactConstants.CONTACT_LIST_CREATED_MSG,
            ContactConstants.CONTACT_LIST_UPDATED_MSG,
            ContactConstants.SOME_UNKNOWN_ERROR,
            ContactConstants.UNDO_ITEM_MSG,
            ContactConstants.CONTACT_CREATED_MSG,
            ContactConstants.CONTACT_UPDATED_MSG,
            ContactConstants.CONTACT_MOVED_TO,
            ContactConstants.SOME_UNKNOWN_ERROR,
            ContactConstants.DELETE_ITEM_MSG,
            ContactConstants.DELETE_FOLDER,
            ContactConstants.CONFIRM_DELETE_FOLDER,
            ContactConstants.DELETE_CONTACT_LIST_HEADER,
            ContactConstants.MOBILE,
            ContactConstants.WORK,
            ContactConstants.WORKFAX,
            ContactConstants.HOME,
            ContactConstants.HOMEFAX,
            ContactConstants.PAGER,
            ContactConstants.CALLBACK,
            ContactConstants.ASSISTANT,
            ContactConstants.CAR,
            ContactConstants.OTHER,
            ContactConstants.OTHERFAX,
            ContactConstants.COMPANY,
            ContactConstants.CONTACT_IMPORT_SUCCESS_MSG,
            ContactConstants.CONTACT_IMPORT_ERROR_MSG,
            ContactConstants.IMPORT_CONTACT_START,
            ContactConstants.HEAVY_SIZE_CSV_ERR,
            ContactConstants.EXCEED_CONTACTS_SIZE_ERR,
            ContactConstants.INVALID_CSV_FILE,
            ContactConstants.CSV_FILE_ALLOWED_EXTENSION,
            ContactConstants.VALIDIMAGEFILEMSG,
            ContactConstants.NO_LOCATION_FOUND,
            ContactConstants.CONTACT_EXPORT_ERROR,
            ContactConstants.DOWNLOAD_FILE_IN,
            ContactConstants.FILE_DOWNLOAD_SUCCESS_MSG,
            ContactConstants.NETWORK_CONNECTION_LOST,
            ContactConstants.CONTACT_SERVER_DOWN_ERROR,
            ContactConstants.SHOW_LESS_LBL,
            ContactConstants.SHOW_LESS_MORE_LBL,
            ContactConstants.CONFIRM_EMPTY_FOLDER,
            ContactConstants.EMPTY_ITEM_MSG,
            ContactConstants.FOLDER_ALREADY_EMPTY,
            ContactConstants.CONTACT_NOT_SAVE_INFO_MSG,
            ContactConstants.CONTACT_NOT_SAVE_EDIT_INFO_MSG,
            ContactConstants.CONTACT_FORM_FIELD_INCORRECT_DATA,
            "APP_OFFLINE",
            "FILE_LENGTH_ERROR",
            ContactConstants.IMAGE_REMOVE_MSG,
            ContactConstants.MOVE_CONTACT_RESTRICTION_MSG,
            ContactConstants.FEATURE_NEXT_VERSION_LBL,
            ContactConstants.SEARCH_STRING_ERR_MSG,
            ContactConstants.ENTER_GROUP_NAME,
            ContactConstants.ENTER_GROUP_NAME_AND_MEMBER,
            ContactConstants.CONTACT_LIST_ALREADY_EXISTS_MSG,
            ContactConstants.DUPLICATE_CONTACT_GROUP_NAME,
            ContactConstants.CONTACTS_LABLE,
            "CONTACT_PERMANENTLY_DELETE"
        ])
            .subscribe(res => {
                this.translationMessage = res;
                this.tarnslatedMessagesd$.next(this.translationMessage);
            });
    }
}
