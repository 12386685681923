
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

export class ZimbraFeatures {
    static ZIMBRA_FEATURE_PREFERENCE_ENABLED = "zimbraFeatureOptionsEnabled"; // Zimbra Preferences Enable/Disabled
    static ZIMBRA_FEATURE_MAIL_FORWARDING_ENABLED = "zimbraFeatureMailForwardingEnabled"; // Mail forwarding enabled/disabled from pref
    static ZIMBRA_FEATURE_SHARING_ENABLED = "zimbraFeatureSharingEnabled"; // Mail forwarding enabled/disabled from pref
    static ZIMBRA_FEATURE_FILTER_ENABLED = "zimbraFeatureFiltersEnabled"; // Mail filter feature enabled / disabled
    static ZIMBRA_FEATURE_OUT_OF_OFFICE_ENABLED = "zimbraFeatureOutOfOfficeReplyEnabled"; // Out of office feature enabled/disaabled
    static ZIMBRA_FEATURE_IMAP_DATA_SOURCE_ENABLED = "zimbraFeatureImapDataSourceEnabled"; // Imap Data source feature enabled/disaabled
    static ZIMBRA_FEATURE_POP3_DATA_SOURCE_ENABLED = "zimbraFeaturePop3DataSourceEnabled"; // Pop3 Data source feature enabled/disaabled
    static ZIMBRA_FEATURE_GAL_ENABLED = "zimbraFeatureGalEnabled"; // zimbra Gal Enabled/Disable
    static ZIMBRA_FEATURE_BRIEFCASE_ENABLED = "zimbraFeatureBriefcasesEnabled";
}
