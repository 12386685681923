
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "./index";
import { SearchFolder } from "../shared/models/search-folder";

export class SearchActionTypes {
  static SEARCH_KEYWORD_STORE = "[SEARCH] Store Keyword";
  static LOAD_SEARCHES_SUCCESS = "[SEARCH] Load Search Folder Success";
  static DELETE_SEARCHES_SUCCESS = "[SEARCH] Delete Search Folder Success";
  static CREATE_SEARCHES_SUCCESS = "[SEARCH] Create Search Folder Success";
  static UPDATE_SEARCHES_SUCCESS = "[SEARCH] Update Search Folder Success";
  static SET_SEARCHES_SUCCESS = "[SEARCH] Set Search Folder Success";
  static SET_SEARCH_FILTERS = "[SEARCH] Set Search Filters";
}

export class SearchKeywordStore implements Action {
  readonly type = SearchActionTypes.SEARCH_KEYWORD_STORE;

  constructor(public payload: string) {
  }
}

export class SetSearchFilters implements Action {
  readonly type = SearchActionTypes.SET_SEARCH_FILTERS;

  constructor(public payload: any) {
  }
}

export class SaveSearch implements Action {
  readonly type = SearchActionTypes.LOAD_SEARCHES_SUCCESS;
  constructor(public payload: { folders: SearchFolder[]; }) {}
}

export class DeleteSearch implements Action {
  readonly type = SearchActionTypes.DELETE_SEARCHES_SUCCESS;
  constructor(public payload: { folder: SearchFolder; }) {}
}

export class CreateSearch implements Action {
  readonly type = SearchActionTypes.CREATE_SEARCHES_SUCCESS;
  constructor(public payload: { folder: SearchFolder; }) {}
}

export class UpdateSearch implements Action {
  readonly type = SearchActionTypes.UPDATE_SEARCHES_SUCCESS;
  constructor(public payload: { folder: SearchFolder; }) {}
}

export class SetSearch implements Action {
  readonly type = SearchActionTypes.SET_SEARCHES_SUCCESS;
  constructor(public payload: { folder: SearchFolder; }) {}
}
