
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="mobile_Sidebar_createfolder_popup" class="mail_folder-create_dialog">
  <div class="content">
    <ng-container *ngIf="isContactList">
      <!-- <div id="mobile_Sidebar_createfolder_header"class="message">
        {{ "CREATE_LIST" | translate }}
      </div> -->
      <vnc-header
      class="message"
      title="{{ 'CREATE_LIST' | translate }}"
      type="title-close"
      size="l"
      (onCloseClick)="closeDialog()"
      [showCloseIcon]="true"
      closeIconToolTip="{{ 'CLOSE' | translate }}">
      </vnc-header>
    </ng-container>
    <ng-container *ngIf="!isContactList">
      <!-- <div id="mobile_Sidebar_createfolder_header" *ngIf = "!isRename" class="message">
        {{ "ADD_FOLDER" | translate }}
      </div> -->
      <vnc-header
      *ngIf = "!isRename"
      class="message"
      title="{{ 'ADD_NEW_FOLDER' | translate }}"
      type="title-close"
      size="l"
      (onCloseClick)="closeDialog()"
      [showCloseIcon]="true"
      closeIconToolTip="{{ 'CLOSE' | translate }}">
      </vnc-header>
      <!-- <div id="mobile_Sidebar_createfolder_header" *ngIf = "isRename"  class="message">
        {{ "RENAME_FOLDER" | translate }} "{{oldFlderName}}"
      </div> -->
      <vnc-header
      *ngIf = "isRename"
      class="message"
      title="{{ 'RENAME_FOLDER' | translate }}"
      type="title-close"
      size="l"
      (onCloseClick)="closeDialog()"
      [showCloseIcon]="true"
      closeIconToolTip="{{ 'CLOSE' | translate }}">
      </vnc-header>
    </ng-container>

    <div>
      <!-- <mat-form-field>
        <input id="mobile_Sidebar_createfolder_name" [(ngModel)]="folderTitle" #folderNameInput matInput placeholder="{{  (isContactList ? 'CONTACT_LIST_NAME_LBL': 'FOLDER_NAME_LBL') | translate }}" (keyup)="changeText($event)" autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false" [maxLength]="maxFolderTitleLength">
        <mat-hint>{{folderNameInput.value.length}}/{{maxFolderTitleLength}}</mat-hint>
      </mat-form-field> -->
      <vnc-input #folderNameInput placeholder="{{  (isContactList ? 'CONTACT_LIST_NAME_LBL': 'FOLDER_NAME_LBL') | translate }}" [showLabel]="true" [form]="folderTitleControl" ></vnc-input>
    </div>
    <div class="actions">
      <div class="color-action">
        <mat-radio-group class = "create-folder-color-selection with-dialog" (change) = "updateDefaultColor($event)" [(ngModel)]="folderColor">
          <mat-radio-button class="grey-radio" [ngClass]="{'active-radio' : folderColor?.toUpperCase() === '#A8B0B9'}" value="#A8B0B9" (click)="updateDefaultColor('#A8B0B9')">
            <span *ngIf="folderColor?.toUpperCase() === '#A8B0B9'">
              <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
            </span>
          </mat-radio-button>
          <mat-radio-button class="sky-radio" [ngClass]="{'active-radio' : folderColor?.toUpperCase() === '#00B8D4'}" value="#00B8D4" (click)="updateDefaultColor('#00B8D4')">
            <span *ngIf="folderColor?.toUpperCase() === '#00B8D4'">
              <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
            </span>
          </mat-radio-button>
          <mat-radio-button class="blue-radio" [ngClass]="{'active-radio' : folderColor?.toUpperCase() === '#388EDA'}" value="#388EDA" (click)="updateDefaultColor('#388EDA')">
            <span *ngIf="folderColor?.toUpperCase() === '#388EDA'">
              <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
            </span>
          </mat-radio-button>
          <mat-radio-button class="navy-radio" [ngClass]="{'active-radio' : folderColor?.toUpperCase() === '#6358C7'}" value="#6358C7" (click)="updateDefaultColor('#6358C7')">
            <span *ngIf="folderColor?.toUpperCase() === '#6358C7'">
              <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
            </span>
          </mat-radio-button>
          <mat-radio-button class="red-radio" [ngClass]="{'active-radio' : folderColor?.toUpperCase() === '#F24336'}" value="#F24336" (click)="updateDefaultColor('#F24336')">
            <span *ngIf="folderColor?.toUpperCase() === '#F24336'">
              <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
           </span>
          </mat-radio-button>
          <mat-radio-button class="orange-radio" [ngClass]="{'active-radio' : folderColor?.toUpperCase() === '#FFAD16'}" value="#FFAD16" (click)="updateDefaultColor('#FFAD16')">
            <span *ngIf="folderColor?.toUpperCase() === '#FFAD16'">
              <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
            </span>
          </mat-radio-button>
          <mat-radio-button class="green-radio" [ngClass]="{'active-radio' : folderColor?.toUpperCase() === '#27AB7F'}" value="#27AB7F" (click)="updateDefaultColor('#27AB7F')">
            <span *ngIf="folderColor?.toUpperCase() === '#27AB7F'">
              <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
            </span>
          </mat-radio-button>
        </mat-radio-group>
        <button matTooltip="{{ 'MORE_TITLE' | translate }}" matTooltipPosition="above" id="mobile_Sidebar_createfolder_morecplor" (click)="openColorDialog()" class="brand-color mat-button more-color-button">
          <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Icon/product/more-horiz" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Icon-24px" transform="translate(4.000000, 10.000000)" fill="#8B96A0">
                    <path d="M2,0 C0.9,0 0,0.9 0,2 C0,3.1 0.9,4 2,4 C3.1,4 4,3.1 4,2 C4,0.9 3.1,0 2,0 L2,0 Z M14,0 C12.9,0 12,0.9 12,2 C12,3.1 12.9,4 14,4 C15.1,4 16,3.1 16,2 C16,0.9 15.1,0 14,0 L14,0 Z M8,0 C6.9,0 6,0.9 6,2 C6,3.1 6.9,4 8,4 C9.1,4 10,3.1 10,2 C10,0.9 9.1,0 8,0 L8,0 Z" id="Shape"></path>
                </g>
            </g>
          </svg>
        </button>
      </div>
      <div class="action-btn">
        <!-- <a id="mobile_Sidebar_createfolder_cancelbtn" (click)="closeDialog()">{{ 'CANCEL_MAIL_LABEL' | translate }}</a>
        <a id="mobile_Sidebar_createfolder_createbrn" *ngIf="!isRename" [class.action-disabled]="folderTitleControl.value.length < 1" (click)="folderAction()">{{ 'CRAETE_FODLER_LBL' | translate }}</a>
        <a id="mobile_Sidebar_createfolder_renamebrn" *ngIf="isRename" [class.action-disabled]="folderTitleControl.value.length < 1" (click)="folderAction()">{{ 'RENAME_FODLER_LBL' | translate }}</a> -->

        <vnc-button class="no-hover vnc-cancel-btn" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" [type]="'secondary'" label="{{'CANCEL_MAIL_LABEL' | translate }}" (click)="closeDialog()"></vnc-button>

        <vnc-button *ngIf="!isRename" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" type="{{folderTitleControl.value.length < 1 ? 'disabled' : 'primary'}}" (click)="folderAction()">
            <span>{{'CRAETE_FODLER_LBL' | translate }}</span>
        </vnc-button>
        <vnc-button *ngIf="isRename" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" type="{{folderTitleControl.value.length < 1 ? 'disabled' : 'primary'}}" (click)="folderAction()">
            <span>{{'RENAME_FODLER_LBL' | translate }}</span>
        </vnc-button>
      </div>
    </div>
  </div>
</div>

<ng-template #checkIcon>
  <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/check" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon id="Shape" fill="#ffffff" points="9 16.17 4.83 12 3.41 13.41 9 19 21 7 19.59 5.59"></polygon>
    </g>
</svg>
</ng-template>
