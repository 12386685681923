
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
    Directive,
    Component,
    HostListener,
    OnDestroy,
    Input,
    ComponentRef,
    Injector,
    ComponentFactoryResolver,
    ViewContainerRef,
    ElementRef,
    ComponentFactory,
    Inject,
    Renderer2,
    TemplateRef,
    ChangeDetectorRef
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { PlacementArray, positionElements } from "positioning";
import { Observable, Subject, timer } from "rxjs";
import { takeUntil, take } from "rxjs/operators";
import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { ElectronService } from "src/app/services/electron.service";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { MailService } from "src/app/mail/shared/services/mail-service";
import { MailRootState } from "src/app/mail/store";
import { Store } from "@ngrx/store";
import { getAllUserContacts } from "src/app/reducers";
import { ContactUtils } from "src/app/common/utils/contact-utils";
import { isArray } from "util";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";

@Component({
    selector: "vp-contact-detail-tooltip-window",
    template: `
      <ng-template
        #defaultTemplate
        let-contents="contents"
        let-placement="placement"
        let-event="event"
        let-currentLocal="currentLocal"
        let-isAllDayMultiple="isAllDayMultiple"
        let-eventItem="eventItem"
        let-contactItemData="contactItemData"
        >
        <div class="contact-tooltip" [ngClass]="'cal-tooltip-' + placement">
          <div class="email"><mat-icon>email</mat-icon>{{contactItemData.email}}</div>
          <div class="email" *ngIf="contactItemData.fullName !== ''"><mat-icon>person</mat-icon>{{contactItemData.fullName}}</div>
          <div class="email" *ngIf="contactItemData.company !== ''"><mat-icon>business</mat-icon>{{contactItemData.company}}</div>
          <div class="email" *ngIf="contactItemData.jobTitle !== ''"><mat-icon>work</mat-icon>{{contactItemData.jobTitle}}</div>
          <div class="email" *ngIf="contactItemData.workPhone !== ''"><mat-icon>call</mat-icon>{{contactItemData.workPhone}}</div>
          <div class="email" *ngIf="contactItemData.mobilePhone !== ''"><mat-icon>call</mat-icon>{{contactItemData.mobilePhone}}</div>
          <div class="email" *ngIf="contactItemData.address !== ''"><mat-icon>room</mat-icon>{{contactItemData.address}}</div>
        </div>
      </ng-template>
      <ng-template
        [ngTemplateOutlet]="customTemplate || defaultTemplate"
        [ngTemplateOutletContext]="{
          contents: contents,
          placement: placement,
          currentLocal: currentLocal,
          event: event,
          isAllDayMultiple: isAllDayMultiple,
          eventItem:eventItem,
          contactItemData:contactItemData
        }">
      </ng-template>
    `
})
export class ContactTooltipWindowComponent {
    @Input() contents: string;
    @Input() placement: string;
    @Input() event: any;
    @Input() customTemplate: TemplateRef<any>;
    currentLocal: string = "en";
    isAllDayMultiple: boolean = false;
    contactItemData: any;
    @Input() eventItem: any[] = [];
}

@Directive({
    selector: "[vpContactDetailTooltip]"
})
export class ContactDetailTooltipDirective implements OnDestroy {
    @Input("vpContactDetailTooltip") contents: string; // tslint:disable-line no-input-rename
    @Input("tooltipPlacement") placement: PlacementArray = "auto"; // tslint:disable-line no-input-rename
    @Input("tooltipTemplate") customTemplate: TemplateRef<any>; // tslint:disable-line no-input-rename
    @Input("tooltipItem") event: any; // tslint:disable-line no-input-rename
    @Input("tooltipAppendToBody") appendToBody: boolean = true; // tslint:disable-line no-input-rename
    @Input("tooltipDelay") delay: number | null = null; // tslint:disable-line no-input-rename
    @Input() eventItem: any[] = [];

    private tooltipFactory: ComponentFactory<ContactTooltipWindowComponent>;
    private tooltipRef: ComponentRef<ContactTooltipWindowComponent>;
    private cancelTooltipDelay$ = new Subject();
    private isAlive$ = new Subject<boolean>();
    isMobileView: boolean = false;
    currentLanguage: string = "en";
    contactItemData: any;
    requestSubscription$: any;

    constructor(
        private elementRef: ElementRef,
        private injector: Injector,
        private renderer: Renderer2,
        componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        private breakpointObserver: BreakpointObserver,
        private changeDetectorRef: ChangeDetectorRef,
        private electronService: ElectronService,
        private mailService: MailService,
        private store: Store<MailRootState>,
        private broadCaster: MailBroadcaster,
        @Inject(DOCUMENT) private document //tslint:disable-line
    ) {
        this.tooltipFactory = componentFactoryResolver.resolveComponentFactory(
            ContactTooltipWindowComponent
        );
        this.breakpointObserver
            .observe([Breakpoints.Small, Breakpoints.HandsetPortrait]).pipe(takeUntil(this.isAlive$))
            .subscribe((state: BreakpointState) => {
                if (state.matches) {
                    this.isMobileView = true;
                } else {
                    this.isMobileView = false;
                }
            });
        this.currentLanguage = this.electronService.isElectron
            ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE) : localStorage.getItem(MailConstants.MAIL_LANGUAGE);

        this.broadCaster.on<any>("AUTO_COMPLETE_REMOVE_ITEM").subscribe(res => {
            this.onMouseOut();
        });
    }

    ngOnDestroy(): void {
        this.hide();
    }

    @HostListener("mouseenter")
    onMouseOver(): void {
        if (this.isMobileView) {
            return;
        }
        const delay$: Observable<any> =
            this.delay === null ? timer(1000) : timer(this.delay);
        let allUserContacts: any[] = [];
        this.store.select(getAllUserContacts).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            allUserContacts = res;
        });
        delay$.pipe(takeUntil(this.cancelTooltipDelay$)).subscribe(() => {
            if (!!this.event && this.event !== null && this.event !== "") {
                const emailItem = this.event;
                this.contactItemData = {
                    email: emailItem,
                    fullName: "",
                    workPhone: "",
                    address: "",
                    company: "",
                    jobTitle: "",
                    mobilePhone: ""
                };
                const query = {
                    name: emailItem,
                    limit: 3,
                    offset: 0
                };
                this.requestSubscription$ = this.mailService.searchGalRequest(query).pipe(take(1)).subscribe(res => {
                    if (!!res && res.cn) {
                        const contact = res.cn[0];
                        if (!!contact) {
                            this.setContactDetail(contact, emailItem);
                        }
                    } else {
                        if (!!allUserContacts && allUserContacts.length > 0) {
                            const contactExists = allUserContacts.filter(
                                c => !!c._attrs && !!c._attrs.email && c._attrs.email === emailItem
                            )[0];
                            if (!!contactExists) {
                                this.setContactDetail(contactExists, emailItem);
                            }
                        }
                    }
                    this.show();
                });
            }
        });
    }

    @HostListener("mouseleave")
    onMouseOut(): void {
        if (this.requestSubscription$) {
            this.requestSubscription$.unsubscribe();
        }
        this.hide();
    }

    private show(): void {
        if (!this.tooltipRef) {
            this.tooltipRef = this.viewContainerRef.createComponent(
                this.tooltipFactory,
                0,
                this.injector,
                []
            );
            // this.tooltipRef.instance.event = this.event;
            this.tooltipRef.instance.contactItemData = this.contactItemData;
            if (!!this.currentLanguage && this.currentLanguage !== null) {
                this.tooltipRef.instance.currentLocal = this.currentLanguage;
            } else {
                this.tooltipRef.instance.currentLocal = "en";
            }
            this.changeDetectorRef.markForCheck();
            if (this.appendToBody) {
                this.document.body.appendChild(this.tooltipRef.location.nativeElement);
            }
            requestAnimationFrame(() => {
                this.positionTooltip();
            });
        }
    }

    private hide(): void {
        if (this.tooltipRef) {
            this.viewContainerRef.remove(
                this.viewContainerRef.indexOf(this.tooltipRef.hostView)
            );
            this.tooltipRef = null;
        }
        this.cancelTooltipDelay$.next(true);
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    private positionTooltip(previousPosition?: string): void {
        if (this.tooltipRef) {
            this.tooltipRef.changeDetectorRef.detectChanges();
            this.tooltipRef.instance.placement = positionElements(
                this.elementRef.nativeElement,
                this.tooltipRef.location.nativeElement.children[0],
                this.placement,
                this.appendToBody
            );
            if (previousPosition !== this.tooltipRef.instance.placement) {
                this.positionTooltip(this.tooltipRef.instance.placement);
            }
        }
    }

    setContactDetail(contact: any, emailItem: string): void {
        this.contactItemData = {
            email: emailItem,
            fullName: "",
            workPhone: "",
            address: "",
            company: "",
            jobTitle: "",
            mobilePhone: ""
        };
        const attributes = contact._attrs;
        if (attributes) {
            const firstName = attributes.firstName ? isArray(attributes.firstName) ? attributes.firstName[0] : attributes.firstName : "";
            const lastName = attributes.lastName ? isArray(attributes.lastName) ? attributes.lastName[0] : attributes.lastName : "";
            this.contactItemData.fullName = ContactUtils.getFullName(firstName, lastName);
            if (attributes.workPhone) {
                this.contactItemData.workPhone = attributes.workPhone;
            }
            if (attributes.mobilePhone) {
                this.contactItemData.mobilePhone = attributes.mobilePhone;
            }
            if (attributes.company) {
                this.contactItemData.company = attributes.company;
            }
            if (attributes.jobTitle) {
                this.contactItemData.jobTitle = attributes.jobTitle;
            }
            if (attributes.workStreet || attributes.workPostalCode || attributes.workCity || attributes.workCountry) {
                let address: string = "";
                if (attributes.workStreet) {
                    address += attributes.workStreet + " ";
                }
                if (attributes.workCity) {
                    address += attributes.workCity + " ";
                }
                if (attributes.workCountry) {
                    address += attributes.workCountry + " ";
                }
                if (attributes.workPostalCode) {
                    address += attributes.workPostalCode;
                }
                this.contactItemData.address = address;
            }
        }
    }
}
