
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Preference } from "../../../../preference/shared/models";
import { PreferenceService } from "../../../../preference/shared/services/preference.service";
import { ToastService } from "../../../../common/providers/toast.service";

@Component({
    selector: "vp-out-of-office-alert-dialog",
    templateUrl: "./out-of-office-alert.component.html"
})
export class OutOfOfficeAlertComponent implements OnInit {
    remind: boolean = false;
    constructor(
        public dialogRef: MatDialogRef<OutOfOfficeAlertComponent>,
        private preferenceService: PreferenceService,
        private toastService: ToastService
    ) {
        this.dialogRef.disableClose = true;
    }

    ngOnInit() { }

    close(): void {
        this.dialogRef.close();
    }

    yes(): void {
        this.savaRemindStatus(true);
    }

    no(): void {
        this.savaRemindStatus(false);
    }

    private savaRemindStatus(turnOff: boolean): void {
        const changes: Preference[] = [];
        if (turnOff) {
            changes.push({
                key: "zimbraPrefOutOfOfficeReplyEnabled",
                value: "FALSE"
            });
        }
        if (this.remind) {
            changes.push({
                key: "zimbraPrefOutOfOfficeStatusAlertOnLogin",
                value: "FALSE"
            });
        }
        if (changes.length > 0) {
            this.preferenceService.modifyPrefs(changes).subscribe(res => {
                this.close();
            }, error => {
                this.toastService.showPlainMessage(error);
            });
        } else {
            this.close();
        }
    }
}
