
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ModuleWithProviders, NgModule, Provider } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CalendarAppointmentActionsComponent } from "./calendar-appointment-actions.component";
import { CalendarAppointmentTitleComponent } from "./calendar-appointment-title.component";
import {
  CalendarTooltipDirective,
  CalendarTooltipWindowComponent
} from "./calendar-tooltip.directive";
import { CalendarPreviousViewDirective } from "./calendar-previous-view.directive";
import { CalendarNextViewDirective } from "./calendar-next-view.directive";
import { CalendarTodayDirective } from "./calendar-today.directive";
import { CalendarDatePipe } from "./calendar-date.pipe";
import { CalendarAppointmentTitlePipe } from "./calendar-event-title.pipe";
import { ClickDirective } from "./click.directive";
import { CalendarAppointmentTitleFormatter } from "./calendar-appointment-title-formatter.provider";
import { CalendarDateFormatter } from "./calendar-date-formatter.provider";
import { CalendarUtils } from "./calendar-utils.provider";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { WeekNumberPipe } from "./calendar-week-number.pipe";
import { MiniCalendarTooltipWindowComponent, MiniCalendarTooltipDirective } from "./mini-calendar-tooltip.directive";
import { AppointmentPreviewCommonDirectiveComponent } from "./components/appointment-preview-common-dialog/appointment-preview-common-directive.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { SharedModule } from "src/app/shared/shared.module";

export interface CalendarModuleConfig {
  eventTitleFormatter?: Provider;
  dateFormatter?: Provider;
  utils?: Provider;
}

export * from "./calendar-appointment-title-formatter.provider";
export * from "./calendar-moment-date-formatter.provider";
export * from "./calendar-native-date-formatter.provider";
export * from "./calendar-angular-date-formatter.provider";
export * from "./calendar-date-formatter.provider";
export * from "./calendar-utils.provider";
export * from "./calendar-date-formatter.interface";
export * from "./calendar-appointment-times-changed-event.interface";
export * from "../date-adapters/date-adapter";
export * from "./calendar-view.enum";

export {
  CalendarEvent,
  EventAction as CalendarAppointmentAction,
  DAYS_OF_WEEK,
  ViewPeriod as CalendarViewPeriod
} from "calendar-utils";

/**
 * Import this module to if you"re just using a singular view and want to save on bundle size. Example usage:
 *
 * ```typescript
 * import { CalendarCommonModule, CalendarMonthModule } from "angular-calendar";
 *
 * @NgModule({
 *   imports: [
 *     CalendarCommonModule.forRoot(),
 *     CalendarMonthModule
 *   ]
 * })
 * class MyModule {}
 * ```
 *
 */
@NgModule({
    declarations: [
        CalendarAppointmentActionsComponent,
        CalendarAppointmentTitleComponent,
        CalendarTooltipWindowComponent,
        CalendarTooltipDirective,
        CalendarPreviousViewDirective,
        CalendarNextViewDirective,
        CalendarTodayDirective,
        CalendarDatePipe,
        CalendarAppointmentTitlePipe,
        ClickDirective,
        WeekNumberPipe,
        MiniCalendarTooltipWindowComponent,
        MiniCalendarTooltipDirective,
        AppointmentPreviewCommonDirectiveComponent
    ],
    imports: [CommonModule, MatIconModule, TranslateModule, MatTooltipModule,
      MatButtonModule,
      MatDividerModule,
      SharedModule
    ],
    exports: [
        CalendarAppointmentActionsComponent,
        CalendarAppointmentTitleComponent,
        CalendarTooltipWindowComponent,
        CalendarTooltipDirective,
        CalendarPreviousViewDirective,
        CalendarNextViewDirective,
        CalendarTodayDirective,
        CalendarDatePipe,
        CalendarAppointmentTitlePipe,
        ClickDirective,
        WeekNumberPipe,
        MiniCalendarTooltipWindowComponent,
        MiniCalendarTooltipDirective
    ]
})
export class CalendarCommonModule {
  static forRoot(
    dateAdapter: Provider,
    config: CalendarModuleConfig = {}
  ): ModuleWithProviders<CalendarCommonModule> {
    return {
      ngModule: CalendarCommonModule,
      providers: [
        dateAdapter,
        config.eventTitleFormatter || CalendarAppointmentTitleFormatter,
        config.dateFormatter || CalendarDateFormatter,
        config.utils || CalendarUtils
      ]
    };
  }
}
