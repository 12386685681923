
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="profile_dialog" class="vp-avatar-dialog mail-dialog">
  <div id="profile_header" class="mail__dialog-header" layout="row center-center ">
      <div>
          <button id="profile_backbtn" (click)="close()" class="brand-color mat-button show-in-mobile">
              <mat-icon class="disable-select">chevron_left</mat-icon>
          </button>
          <button id="profile_account" class="brand-color mat-button hide-in-mobile">
              <mat-icon class="disable-select">account_circle</mat-icon>
          </button>
      </div>
      <div id="profile_header_title" class="header-title ">
          <span class="disable-select">{{ 'PROFILE' | translate }}</span>
      </div>
      <div class= "desktop-close hide-in-mobile">
          <button id="profile-close" class="brand-color mat-button" (click) = "close()">
              <mat-icon class="disable-select">close</mat-icon>
          </button>
      </div>
  </div>
  <div class="mail__dialog-body">
      <div class = "user-avtar" *ngIf="currentUser">
          <vp-avatar [type] = "'profile-dialog'" [user] = "currentUser"> </vp-avatar>
      </div>
      <div *ngIf="canUpdateAvatar" class="avatar-upload">
      <button id="profile-photo" *ngIf="currentUser" class="brand-color mat-button" (click) = "openCropperDialog()">
          <mat-icon class="disable-select">photo_camera</mat-icon>
      </button>
      </div>
      <div id="profile-user-name" class = "user-name">
          <span *ngIf="currentUser"> {{ currentUser?.fullName || currentUser?.firstName + " " + currentUser?.lastName | titlecase}}</span>
      </div>
      <div class = "contact-mail-section">
        <span id="profile-contact-detail" class = "contact-detail" >{{'CONTACT_DETAIL' | translate}}</span>
        <div id="profile-email" class = "contact-mail">
          <mat-icon>email</mat-icon>
          <span class = "email" *ngIf="currentUser" >{{currentUser?.email}}</span>
        </div>
      </div>
  </div>
</div>
