
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div *ngIf="isActionInProgress" class="action-processing">
  <vp-custom-spinner></vp-custom-spinner>
</div>
<div>
  <mat-progress-bar id="topSpinner" mode="indeterminate" *ngIf="showSpinner"></mat-progress-bar>
</div>
<vp-header *ngIf="isLoggedIn && !hideHeader" id="layoutHeader"></vp-header>
<div class="vp-module" [class.is-iframe]="hideHeader">
    <router-outlet></router-outlet>
</div>
<vp-notifications [options]="options"></vp-notifications>
<vnc-action-wheel-menu
middleLabel="{{ 'PROFILE_INFO' | translate }}"
label1="{{ 'START_AUDIO_CALL' | translate }}"
label2="{{ 'START_CHAT' | translate }}"
label3="{{ 'START_VIDEO_CALL' | translate }}"
label4="{{ 'COMPOSE_MAIL' | translate }}"
label5="{{ 'CREATE_TICKET' | translate }}"
label6="{{ 'CREATE_TASK' | translate }}"
(actionClicked)="handleActionClick($event)"
 style="display: none"></vnc-action-wheel-menu>
<vp-authentication *ngIf="!configService.selectedServer"></vp-authentication>
<vp-calender-appointment-reminder-dialog *ngIf="commonService.isEventReminderActivated && !commonService.isOpenedAppointment"></vp-calender-appointment-reminder-dialog>
<dialog-outlet></dialog-outlet>
