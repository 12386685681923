
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";
import { MailFolder } from "src/app/mail/models/mail-folder.model";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { NestedTreeControl } from "@angular/cdk/tree";
import { of, Observable } from "rxjs";
import { MailService } from "src/app/mail/shared/services/mail-service";
import { take, takeUntil } from "rxjs/operators";
import { ElectronService } from "src/app/services/electron.service";
import { IdbUtils } from "src/app/services/idb.service";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "vp-mail-folder-dialog",
    templateUrl: "./mail-folder-dialog.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MailFolderComponent implements OnInit, OnDestroy {
    nestedTreeControl: NestedTreeControl<MailFolder>;
    nestedDataSource: MatTreeNestedDataSource<MailFolder>;
    nestedSubFoldersTreeControl: NestedTreeControl<MailFolder>;
    nestedDataSourceSubFolders: MatTreeNestedDataSource<MailFolder>;
    mailFolders: MailFolder[] = [];
    isRootUserFolderCollapsed: boolean = false;
    selectedMailFolder: MailFolder;
    rootUserFolders: MailFolder[] = [];
    selectedFolder: MailFolder;
    private isAlive$ = new Subject<boolean>();
    constructor(
        private dialogRef: MatDialogRef<MailFolderComponent>,
        private mailBroadcaster: MailBroadcaster,
        private mailService: MailService,
        private changeDetectionRef: ChangeDetectorRef,
        private electronService: ElectronService,
        private translateService: TranslateService
    ) {
        this.mailBroadcaster.on("BroadcastKeys.HIDE_MAIL_FOLDERS_DIALOG").pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.close();
        });
        this.nestedTreeControl = new NestedTreeControl<MailFolder>(
            this._getChildren
        );

        this.nestedSubFoldersTreeControl = new NestedTreeControl<MailFolder>(
            this._getChildren
        );

        this.nestedDataSource = new MatTreeNestedDataSource();
        this.nestedDataSourceSubFolders = new MatTreeNestedDataSource();
    }

    ngOnInit() {
        IdbUtils.getValueByKey("mailFolders").then(mailFolders => {
            if (mailFolders) {
                this.mailFolders = mailFolders.filter( f => f.absFolderPath !== "/Chats");
                this.bindData();
            }
        });

        this.getFolders();
    }

    bindData(): void {
        this.nestedDataSource.data = this.mailFolders.filter(f => f.icon);
        this.nestedDataSourceSubFolders.data = this.mailFolders.filter(f => !f.icon);
        this.changeDetectionRef.markForCheck();
    }

    getFolders() {
        this.mailService.getMailFolders({ view: "message, conversation" }, false).pipe(take(1)).subscribe(folders => {
            console.log("[getMailFolders]", folders);
            this.mailFolders = folders.filter( f => f.absFolderPath !== "/Chats");
            this.mailFolders = this.mailFolders.filter(f => f.view && f.view === "message");
            this.bindData();
        });
    }

    hasNestedChild(_: number, nodeData: MailFolder): boolean {
        return nodeData.children && nodeData.children.length > 0;
    }

    private _getChildren(node: MailFolder): Observable<MailFolder[]> {
        return of(node.children);
    }

    close() {
        this.dialogRef.close();
    }

    selectFolder(folder: MailFolder) {
        this.dialogRef.close(folder);
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.unsubscribe();
    }

    getFolderName(node: any): string {
        let fName: string = node.name;
        if (node.id === "2") {
            this.translateService.get("INBOX_FOLDER").pipe(take(1)).subscribe((text: string) => {
                fName = text;
            });
        } else if (node.id === "6") {
            this.translateService.get("DRAFTS_FOLDER").pipe(take(1)).subscribe((text: string) => {
                fName = text;
            });
        } else if (node.id === "5") {
            this.translateService.get("SENT_FOLDER").pipe(take(1)).subscribe((text: string) => {
                fName = text;
            });
        } else if (node.id === "4") {
            this.translateService.get("SPAM_FOLDER").pipe(take(1)).subscribe((text: string) => {
                fName = text;
            });
        } else if (node.id === "3") {
            this.translateService.get("TRASH_FOLDER").pipe(take(1)).subscribe((text: string) => {
                fName = text;
            });
        } else if (node.id === "13") {
            this.translateService.get("EMAILED_CONTACTS_TITLE").pipe(take(1)).subscribe((text: string) => {
                fName = text;
            });
        } else if (node.id === "7") {
            this.translateService.get("CONTACTS_TITLE").pipe(take(1)).subscribe((text: string) => {
                fName = text;
            });
        } else if (node.id === "10") {
            this.translateService.get("CALENDAR").pipe(take(1)).subscribe((text: string) => {
                fName = text;
            });
        } else if (node.id === "16") {
            this.translateService.get("BRIEFCASE_LBL").pipe(take(1)).subscribe((text: string) => {
                fName = text;
            });
        } else if (node.id === "15") {
            this.translateService.get("TASKS").pipe(take(1)).subscribe((text: string) => {
                fName = text;
            });
        }
        return fName;
    }
}
