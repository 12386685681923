
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div>
    <div id="change-pwd-header" class="mail__dialog-header mobile_header">
        <div>
            <button id="change-pwd-backbtn" mat-button (click)="close()">
                <mat-icon class="disable-select">chevron_left</mat-icon>
            </button>
        </div>
        <div id="change-pwd-header-title" class="header_lbl disable-select">
            {{ 'CHANGE_PASSWORD_LBL' | translate }}
        </div>
        <div class="submit"></div>
    </div>
    <div *ngIf="type==='zimbra'">
        <div class="mail__dialog-body mail__dialog-body--pad">
            <div *ngIf="type === 'zimbra' && urlSafe === '' && !showRedirect">
                <div>
                    <mat-form-field>
                        <input id="change-pwd-current-pwd" matInput placeholder="{{'CURRENT_PASSWORD' | translate}}" type="{{ oldPassword ? 'text' : 'password' }}"
                            [(ngModel)]="oldPasswordInput">
                        <mat-icon id="change-pwd-current-eyeopen" class="mdi-16px" (click)="toggleOldPassword()" *ngIf="!oldPassword && oldPasswordInput.length > 0" fontSet="mdi"
                            fontIcon="mdi-eye-outline"></mat-icon>
                        <mat-icon id="change-pwd-current-eyeoff" class="mdi-16px" (click)="toggleOldPassword()" *ngIf="oldPassword && oldPasswordInput.length > 0" fontSet="mdi"
                            fontIcon="mdi-eye-off-outline"></mat-icon>
                    </mat-form-field>
                </div>
                <div class="new_password">
                    <mat-form-field>
                        <input id="change-pwd-new-pwd" matInput placeholder="{{'NEW_PASSWORD' | translate}}" type="{{ newPassword ? 'text' : 'password' }}"
                            [(ngModel)]="newPasswordInput">
                        <mat-icon id="change-pwd-new-pwd-eyeopen" class="mdi-16px" (click)="toggleNewPassword()" *ngIf="!newPassword && newPasswordInput.length > 0" fontSet="mdi"
                            fontIcon="mdi-eye-outline"></mat-icon>
                        <mat-icon id="change-pwd-new-pwd-eyeoff" class="mdi-16px" (click)="toggleNewPassword()" *ngIf="newPassword && newPasswordInput.length > 0" fontSet="mdi"
                            fontIcon="mdi-eye-off-outline"></mat-icon>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field>
                        <input id="change-pwd-conf-pwd" matInput placeholder="{{'RE_TYPE_PASSWORD' | translate}}" type="{{ confirmPassword ? 'text' : 'password' }}"
                            [(ngModel)]="confirmPasswordInput">
                        <mat-icon id="change-pwd-conf-pwd-eyeopen" class="mdi-16px" (click)="toggleConfirmPassword()" *ngIf="!confirmPassword && confirmPasswordInput.length > 0" fontSet="mdi"
                            fontIcon="mdi-eye-outline"></mat-icon>
                        <mat-icon id="change-pwd-conf-pwd-eyeoff" class="mdi-16px" (click)="toggleConfirmPassword()" *ngIf="confirmPassword && confirmPasswordInput.length > 0" fontSet="mdi"
                            fontIcon="mdi-eye-off-outline"></mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="showRedirect" class="redirect">
                <div id="change-pwd-success" class="success">{{ 'PASSWORD_SUCCESS_CHANGE' | translate }}</div>
                <div id="change-pwd-redirect" class="redirecting">{{ 'REDIRECTIONG_LBL' | translate }}</div>
            </div>
        </div>
    </div>
    <div *ngIf="type==='directory'"></div>
    <div class="submit_password" *ngIf="oldPasswordInput!=='' && newPasswordInput!=='' && confirmPasswordInput!=='' && newPasswordInput === confirmPasswordInput && !showRedirect">
        <span>{{ 'CHANGE_PASSWORD_LBL' | translate }}</span>
      <button id="change-pwd-change-pwdbtn" mat-button (click)="changePassword()">
        <mat-icon class="disable-select">check</mat-icon>
      </button>
    </div>
</div>