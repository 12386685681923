
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-sub-folder-dialog mail-dialog">
    <div class="mail__dialog-header" layout="row center-center">
        <div>
            <button (click)="closeDialog()" class="brand-color mat-button hamburger-Menu">
                <mat-icon>chevron_left</mat-icon>
            </button>
        </div>
        <div class="header-title disable-select briefcase-title">
            <span class="disable-select">{{ 'BRIEFCASE_TITLE' | translate}}</span>
        </div>
        <div class="mobile-right-close disable-select">
            <button class="brand-color mat-button">
                <mat-icon class="disable-select">more_vert</mat-icon>
            </button>
        </div>
    </div>
    <div class="mail__dialog-body mail__dialog-body--pad disable-select">

    </div>
</div>