
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { RootState } from "../../../reducers";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromContactFolder from "./contact-list.reducer";
import * as fromContact from "./contacts.reducers";

export interface ContactRootState extends RootState {
  contact: State;
}

export interface State {
  contactFolder: fromContactFolder.ContactFolderState;
  contact: fromContact.ContactState;
}

export const getContactRootState = createFeatureSelector<State>("contact");

export const contactRootReducer = {
  contactFolder: fromContactFolder.contactFolderReducer,
  contact: fromContact.contactReducer
};

export const getContactFolderState = createSelector(
  getContactRootState,
  state => state?.contactFolder
);

export const getSelectedContactFolder = createSelector(
   getContactFolderState,
   fromContactFolder._getSelectedContactFolder
);

export const getTrashFolder = createSelector(
  getContactFolderState,
  fromContactFolder._getTrashFolder
);

export const getRoutedFolderTitle = createSelector(
  getContactFolderState,
  fromContactFolder._getRotedFolderTitle
);

export const getIsFolderLoading = createSelector(
  getContactFolderState,
  fromContactFolder._getIsLoading
);

export const getContactsList = createSelector(
  getContactFolderState,
  fromContactFolder._getContactsList
);

export const getIsFolderLoaded = createSelector(
  getContactFolderState,
  fromContactFolder._getIsLoaded
);

export const getContactState = createSelector(
  getContactRootState,
  state => state.contact
);

export const {
  selectIds: getContactIds,
  selectEntities: getContactEntities,
  selectAll: getAllContact,
  selectTotal: getTotalContact,
} = fromContact.contactAdapter.getSelectors(getContactState);

export const getContacts = getAllContact;

export const getIsContactsLoading = createSelector(
  getContactState,
  fromContact._getIsContactsLoading
);

export const getIsContactsLoaded = createSelector(
  getContactState,
  fromContact._getIsContactsLoaded
);

export const getIsContactsNextPageLoading = createSelector(
  getContactState,
  fromContact._getIsNextPageLoading
);

export const getIsContactssNextPageLoaded  = createSelector(
  getContactState,
  fromContact._getIsNextPageLoaded
);

export const getContactsCurrentPageOffset = createSelector(
  getContactState,
  fromContact._getCurrentOffset
);

export const getIsMoreContacts = createSelector(
  getContactState,
  fromContact._getIsMoreTasks
);

export const getSearchMode = createSelector(
  getContactState,
  fromContact._getIsSearchMode
);

export const getSearchParams = createSelector(
  getContactState,
  fromContact._getSearchParams
);




