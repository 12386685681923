
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
    Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, AfterViewInit,
    ElementRef, ViewChild
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { Subject } from "rxjs";
import { Store } from "@ngrx/store";
import { takeUntil, filter, take, skip } from "rxjs/operators";
import { isArray } from "util";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { MailFolder } from "src/app/mail/models/mail-folder.model";
import { MailService } from "src/app/mail/shared/services/mail-service";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { ToastService } from "src/app/common/providers/toast.service";
import { MailRootState } from "src/app/mail/store";
import { ColorControlDialogComponent } from "src/app/mail/color-control-dialog/color-control-dialog.component";
import { getUserProfile, getAllSearch } from "src/app/reducers";
import { UpdateSearch } from "src/app/actions/search";
import { TranslateService } from "@ngx-translate/core";
import { UpdateSearchFolder } from "../../../actions/search-folder.action";

@Component({
    selector: "vp-search-folder-properties",
    templateUrl: "./search-edit-properties-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditSearchPropertiesComponent implements OnDestroy, AfterViewInit {
    searchTitle: string = "";
    searchQuery: string = "";
    maxSearchTitleLength: number = 128;
    private isAlive$ = new Subject<boolean>();
    mailRootFolders: MailFolder[] = null;
    folderColor: string;
    fromAddress: string;
    searchFolders: any[] = null;
    oldSearchName: string = "";
    @ViewChild("folderNameInput", { static: false }) folderNameInput: ElementRef<HTMLInputElement>;
    @ViewChild("folderQueryNameInput", { static: false }) folderQueryInput: ElementRef<HTMLInputElement>;
    constructor(
        private dialogRef: MatDialogRef<EditSearchPropertiesComponent>,
        public toastService: ToastService,
        private changeDetectionRef: ChangeDetectorRef,
        private mailBroadcaster: MailBroadcaster,
        private store: Store<MailRootState>,
        private matDialog: MatDialog,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private hotKeyService: NgxHotkeysService,
        private mailService: MailService,
    ) {
        setTimeout(() => {
            this.changeDetectionRef.detectChanges();
        }, 50);
        this.hotKeyService.pause(this.hotKeyService.hotkeys);
        this.mailBroadcaster.broadcast(MailConstants.CALL_NO_OP_REQUEST);
        this.store.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(res => {
            if (res.email) {
                this.checkEmailArray(res.email);
            } else {
                this.checkEmailArray(MailUtils.getEmailFromStorage());
            }
        });
        this.searchTitle = this.data.targetFolder.name;
        this.searchQuery = this.data.targetFolder.query;
        this.oldSearchName = this.data.targetFolder.name;
        console.log(this.data);
        if (this.data.targetFolder.color) {
            this.folderColor = this.data.targetFolder.color;
            this.changeDetectionRef.markForCheck();
        }

        this.store.select(getAllSearch).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.searchFolders = res;
            this.changeDetectionRef.markForCheck();
        });
        this.changeDetectionRef.markForCheck();

        // this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_EDIT_FOLDER_PROPERTIES_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
        //     this.ngZone.run(() => {
        //         this.close();
        //     });
        // });

        setTimeout(() => {
            const selector = <HTMLElement>document.querySelector(".mail_edit_search_properties_dialog");
            if (!!selector && selector !== null) {
                selector.click();
            }
            if (!!this.folderNameInput && this.folderNameInput !== null) {
                this.folderNameInput.nativeElement.focus();
            }
        }, 200);
    }

    changeText(event) {
        if (this.searchTitle.length > this.maxSearchTitleLength) {
            this.searchTitle = this.searchTitle.substring(0, this.maxSearchTitleLength);
            this.changeDetectionRef.markForCheck();
        }
    }

    closeDialog(): void {
        this.close();
    }

    updateDefaultColor(event) {
        this.folderColor = event.value;
        this.changeDetectionRef.markForCheck();
    }

    folderAction(): void {
        if (this.searchTitle.length < 1 || this.searchQuery.length < 1) {
            return;
        }

        if (this.isFolderAlreadyExist()) {
            this.translate.get("SEARCH_ALREADY_EXIST_MSG", {
                searchName: this.searchTitle,
            }).pipe(take(1)).subscribe((text: string) => {
                this.toastService.show(text);
            });
            return;
        }

        let index = 0;
        const request = {
            "@": {
                onerror: "continue"
            }
        };
        const folderAction: any[] = [];
        if (this.searchTitle !== this.data.targetFolder.name) {
            const data = {
                "@": {
                    xmlns: "urn:zimbraMail"
                },
                action: {
                    "@": {
                        id: this.data.targetFolder.id,
                        op: "rename",
                        name: this.searchTitle
                    }
                },
                requestId: index
            };
            folderAction.push(data);
            index++;

        }
        if (this.folderColor !== this.data.targetFolder.color) {
            const data = {
                "@": {
                    xmlns: "urn:zimbraMail"
                },
                action: {
                    "@": {
                        id: this.data.targetFolder.id,
                        op: "color",
                        rgb: this.folderColor
                    }
                },
                requestId: index
            };
            folderAction.push(data);
            index++;
        }
        if (index > 0) {
            request["FolderActionRequest"] = folderAction;
        }
        if (this.searchQuery !== this.data.targetFolder.query) {
            const data = {
                "@": {
                    xmlns: "urn:zimbraMail"
                },
                search: {
                    "@": {
                        query: this.searchQuery,
                        id: this.data.targetFolder.id,
                        types: this.data.targetFolder.types
                    }
                },
                requestId: index
            };
            request["ModifySearchFolderRequest"] = data;
        }
        this.mailService.createBatchRequest(request).pipe(take(1)).subscribe(res => {
            if (res.Fault) {
                const fault = res.Fault[0];
                if (fault.Reason && fault.Reason.Text) {
                    console.log("[Error]", fault);
                }
            } else {
                this.data.targetFolder.name = this.searchTitle;
                this.data.targetFolder.rgb = this.folderColor;
                this.data.targetFolder.query = this.searchQuery;
                this.store.dispatch(new UpdateSearchFolder({ folder: this.data.targetFolder }));
                this.changeDetectionRef.markForCheck();
            }
        }, error => {
            console.log("[Error]", error);
        });
        this.close();
    }

    openColorDialog() {
        const changeColorDialogRef = this.matDialog.open(ColorControlDialogComponent, {
            height: "auto",
            maxHeight: "70%",
            width: "99%",
            maxWidth: "244px",
            autoFocus: false,
            panelClass: "vp-color-control-dialog",
            data: { folderColor: this.folderColor }
        });
        changeColorDialogRef.afterClosed().subscribe(operation => {
            if (!!operation && operation !== null && operation.selectedColor) {
                this.folderColor = operation.selectedColor;
            }
        });
    }

    isFolderAlreadyExist() {
        if (this.oldSearchName === this.searchTitle) {
            this.close();
            return;
        }
        if (this.searchFolders.find(folder => folder.name.toLowerCase() === this.searchTitle.toLowerCase().trim() )) {
            return true;
        }
        return false;
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.complete();
        this.hotKeyService.unpause(this.hotKeyService.hotkeys);
    }

    close(): void {
        this.dialogRef.close();
    }

    checkEmailArray(emails): void {
        if (isArray(emails)) {
            this.fromAddress = emails[0];
        } else if (emails) {
            this.fromAddress = emails;
        }
    }

    ngAfterViewInit() {
        console.log("[EditSearchPropertiesComponent]");
    }
}
