
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy,
  ElementRef, ViewChild, NgZone, AfterViewInit
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { takeUntil, take } from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";
import { Store } from "@ngrx/store";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { getAllSearch } from "src/app/reducers";
import { MailService } from "src/app/mail/shared/services/mail-service";
import { UpdateSearch } from "src/app/actions/search";
import { ToastService } from "src/app/common/providers/toast.service";
import { MailRootState } from "src/app/mail/store";
import { getMailFolders } from "src/app/mail/store/selectors";
import { TranslateService } from "@ngx-translate/core";
import { UpdateSearchFolder } from "../../../actions/search-folder.action";

@Component({
  selector: "vp-rename-search",
  templateUrl: "./rename-search.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class RenameSearchComponent implements OnDestroy, AfterViewInit {

  searchTitle: string = "";
  maxSearchTitleLength: number = 128;
  oldSearchName: string = "";
  private isAlive$ = new Subject<boolean>();
  searchFolders: any[] = null;
  folderColor: string;
  @ViewChild("searchNameInput", { static: false }) searchNameInput: ElementRef<HTMLInputElement>;

  constructor(
    private mailService: MailService,
    private dialogRef: MatDialogRef<RenameSearchComponent>,
    public toastService: ToastService,
    private changeDetectionRef: ChangeDetectorRef,
    private store: Store<MailRootState>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private hotKeyService: NgxHotkeysService
  ) {
    this.hotKeyService.pause(this.hotKeyService.hotkeys);
    this.searchTitle = this.data.targetSearch.name;
    this.oldSearchName = this.data.targetSearch.name;
    this.store.select(getAllSearch).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.searchFolders = res;
      this.changeDetectionRef.markForCheck();
    });
    this.changeDetectionRef.markForCheck();

    // this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_CREATE_FOLDER_MODAL).pipe(takeUntil(this.isAlive$)).subscribe(res => {
    //   this.ngZone.run(() => {
    //     this.close();
    //   });
    // });

    setTimeout(() => {
      if (this.searchNameInput) {
        this.searchNameInput.nativeElement.focus();
      }
    }, 500);
  }

  changeText(event) {
    if (this.searchTitle.length > this.maxSearchTitleLength) {
      this.searchTitle = this.searchTitle.substring(0, this.maxSearchTitleLength);
      this.changeDetectionRef.markForCheck();
    }
  }

  closeDialog(): void {
    this.close();
  }

  folderAction(): void {
    if (this.searchTitle.length < 1) {
      return;
    }

    if (this.oldSearchName === this.searchTitle) {
      this.close();
      return;
    }

    if (this.isFolderAlreadyExist()) {
      this.translate.get("SEARCH_ALREADY_EXIST_MSG", {
        searchName: this.searchTitle,
      }).pipe(take(1)).subscribe((text: string) => {
        this.toastService.show(text);
      });
      return;
    }
    this.mailService.folderAction({ id: this.data.targetSearch.id, name: this.searchTitle, op: "rename" }).subscribe(res => {
      this.data.targetSearch.name = this.searchTitle;
      this.store.dispatch(new UpdateSearchFolder({ folder: this.data.targetSearch }));
      this.changeDetectionRef.markForCheck();
    });
    this.close();
  }

  isFolderAlreadyExist() {
    if (this.searchFolders.find(folder => folder.name.toLowerCase() === this.searchTitle.toLowerCase().trim())) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.hotKeyService.unpause(this.hotKeyService.hotkeys);
  }

  ngAfterViewInit() {
    this.changeDetectionRef.detectChanges();
  }

  close(): void {
    this.dialogRef.close();
  }
}
