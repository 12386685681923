
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { SearchRequest, Conversation } from "../../shared/models";
import { Action } from "src/app/actions";
import { Update } from "@ngrx/entity";
export const IS_CONVERSATION_LOADING = "[CONVERSATION] Is Loading";
export const LOAD_CONVERSATION_SUCCESS = "[CONVERSATION] Load Conversations Success";
export const LOAD_CONVERSATION_FAILED = "[CONVERSATION] Load Conversations Failed";
export const UPDATE_LAST_CONVERSATION_DATA = "[CONVERSATION] Update Last Conversation Data";
export const SET_QUERY = "[CONVERSATION] Set Search Request";
export const SET_HAS_MORE = "[CONVERSATION] Set Has more";
export const SELECT_CONVERSATION = "[CONVERSATION] Select Conversation";
export const SELECT_MAIL_DETAIL = "[CONVERSATION] Select Mail Detail";
export const CHECK_CONVERSATION = "[CONVERSATION] Check Conversation";
export const UNSELECT_CONVERSATION = "[CONVERSATION] Unselect Conversation";
export const UNSELECT_CONVERSATIONS = "[CONVERSATION] Unselect Conversations";
export const UNCHECK_CONVERSATION = "[CONVERSATION] Uncheck Conversation";
export const RESET_SELECTED_CONVERSATIONS = "[CONVERSATION] Reset Selected Conversations";
export const RESET_CHECKED_CONVERSATIONS = "[CONVERSATION] Reset Checked Conversations";
export const RESET_CONVERSATIONS_BY_FOLDER = "[CONVERSATION] Reset Conversations By Folder";
export const RESET_CONVERSATIONS = "[CONVERSATION] Reset Conversations";
export const MULTIPLE_CHECK_CONVERSATIONS = "[CONVERSATION] Multiple Check Conversations";
export const UPDATE_MANY_CONVERSATIONS = "[CONVERSATION] Update Many Conversations";
export const UPDATE_MANY_CONVERSATIONS_SUCCESS = "[CONVERSATION] Update Many Conversations Success";
export const UPDATE_MANY_CONVERSATIONS_FAIL = "[CONVERSATION] Update Many Conversations Fail";
export const REMOVE_CONVERSATION = "[CONVERSATION] Remove ConversationS";
export const REMOVE_CONVERSATION_FAIL = "[CONVERSATION] Remove Conversations Fail";
export const REMOVE_ALL_CONVERSATIONS_SUCCESS = "[CONVERSATION] Remove All Conversations Success";
export const REMOVE_ALL_CONVERSATIONS_FROM_FOLDER = "[CONVERSATION] Remove All Conversations From Folder";
export const REMOVE_CONVERSATION_SUCCESS = "[CONVERSATION] Remove Conversation Success";
export const REMOVE_MANY_CONVERSATIONS = "[CONVERSATION] Remove Many Conversations";
export const RESET_CONVERSATION_STATE = "[CONVERSATION] Reset State";
export const SET_CURRENT_QUERY = "[CONVERSATION] Set Query";
export const UPDATE_CONVERSATION_SUCCESS = "[CONVERSATION] Update Conversation";
export const ADD_TAB = "[CONVERSATION] Add Tab";
export const ADD_FIRST_TAB = "[CONVERSATION] Add First Tab";
export const REMOVE_TAB = "[CONVERSATION] Remove Tab";
export const REMOVE_MULTIPLE_TABS = "[CONVERSATION] Remove Multiple Tabs";
export const REMOVE_ALL_TABS = "[CONVERSATION] Remove Tabs";

export class IsConversationLoadingAction implements Action {
  readonly type = IS_CONVERSATION_LOADING;
  constructor(public payload: boolean) {}
}

export class LoadConversationSuccessAction implements Action {
  readonly type = LOAD_CONVERSATION_SUCCESS;
  constructor(public payload: Conversation[]) {}
}

export class LoadConversationFailedAction implements Action {
  readonly type = LOAD_CONVERSATION_FAILED;
  constructor() {}
}
export class UpdateLastConversationData implements Action {
  readonly type = UPDATE_LAST_CONVERSATION_DATA;
  constructor(public payload: any) {}
}

export class SetQueryAction implements Action {
  readonly type = SET_QUERY;
  constructor(public payload: {query: SearchRequest}) {}
}

export class SetHasMoreAction implements Action {
  readonly type = SET_HAS_MORE;
  constructor(public payload: boolean) {}
}

export class SelectConversationAction implements Action {
  readonly type = SELECT_CONVERSATION;
  constructor(public payload: string) {}
}

export class SetMailDetailId implements Action {
  readonly type = SELECT_MAIL_DETAIL;
  constructor(public payload: string) {}
}

export class UnSelectConversationAction implements Action {
  readonly type = UNSELECT_CONVERSATION;
  constructor(public payload: string) {}
}

export class UnSelectConversationsAction implements Action {
  readonly type = UNSELECT_CONVERSATIONS;
  constructor(public payload: string[]) {}
}

export class ResetSelectedConversationsAction implements Action {
  readonly type = RESET_SELECTED_CONVERSATIONS;
  constructor() {}
}

export class ResetConversationsByFolderAction implements Action {
  readonly type = RESET_CONVERSATIONS_BY_FOLDER;
  constructor(public payload: string) {}
}

export class ResetConversationsAction implements Action {
  readonly type = RESET_CONVERSATIONS;
  constructor() {}
}

export class CheckConversationAction implements Action {
  readonly type = CHECK_CONVERSATION;
  constructor(public payload: string) {}
}

export class UnCheckConversationAction implements Action {
  readonly type = UNCHECK_CONVERSATION;
  constructor(public payload: string) {}
}

export class ResetCheckedConversationsAction implements Action {
  readonly type = RESET_CHECKED_CONVERSATIONS;
  constructor() {}
}

export class MultipleCheckConversationsAction implements Action {
  readonly type = MULTIPLE_CHECK_CONVERSATIONS;
  constructor(public payload: string[]) {}
}

export class UpdateManyConversations implements Action {
  readonly type = UPDATE_MANY_CONVERSATIONS;
  constructor() {}
}

export class UpdateManyConversationsSuccess implements Action {
  readonly type = UPDATE_MANY_CONVERSATIONS_SUCCESS;
  constructor(public payload: Conversation[]) {}
}

export class UpdateConversationSuccess implements Action {
  readonly type = UPDATE_CONVERSATION_SUCCESS;
  constructor(public payload: Update<Conversation>) { }
}

export class UpdateManyConversationsFail implements Action {
  readonly type = UPDATE_MANY_CONVERSATIONS_FAIL;
  constructor() {}
}

export class RemoveConversation implements Action {
  readonly type = REMOVE_CONVERSATION;
  constructor() {}
}

export class RemoveAllConversationsSuccess implements Action {
  readonly type = REMOVE_ALL_CONVERSATIONS_SUCCESS;
  constructor() { }
}

export class RemoveAllConversationsFromFolder implements Action {
  readonly type = REMOVE_ALL_CONVERSATIONS_FROM_FOLDER;
  constructor(public payload: string) { }
}

export class RemoveConversationsSuccess implements Action {
  readonly type = REMOVE_CONVERSATION_SUCCESS;
  constructor(public payload: string) { }
}

export class RemoveManyConversations implements Action {
  readonly type = REMOVE_MANY_CONVERSATIONS;

  constructor(public payload: string[]) {
  }
}

export class SetCurrentQuery implements Action {
  readonly type = SET_CURRENT_QUERY;

  constructor(public payload: string) {
  }
}

export class AddTab implements Action {
  readonly type = ADD_TAB;
  constructor(public payload: any) {
  }
}

export class AddFirstTab implements Action {
  readonly type = ADD_FIRST_TAB;
  constructor(public payload: any) {
  }
}

export class RemoveTab implements Action {
  readonly type = REMOVE_TAB;
  constructor(public payload: any) {
  }
}

export class RemoveMultipleTabs implements Action {
  readonly type = REMOVE_MULTIPLE_TABS;
  constructor(public payload: string[]) {
  }
}
export class RemoveAllTabs implements Action {
  readonly type = REMOVE_ALL_TABS;
  constructor() {
  }
}

export class RemoveConversationFail implements Action {
  readonly type = REMOVE_CONVERSATION_FAIL;
  constructor() {}
}

export class ResetConversationState implements Action {
  readonly type = RESET_CONVERSATION_STATE;
  constructor() {}
}

export type ConversationActions =
  | IsConversationLoadingAction
  | LoadConversationSuccessAction
  | SetQueryAction
  | SetHasMoreAction
  | SelectConversationAction
  | UnSelectConversationAction
  | ResetSelectedConversationsAction
  | CheckConversationAction
  | UnCheckConversationAction
  | ResetCheckedConversationsAction
  | MultipleCheckConversationsAction
  | LoadConversationFailedAction
  | LoadConversationFailedAction
  | UpdateManyConversationsSuccess
  | UpdateConversationSuccess
  | UpdateManyConversations
  | UpdateManyConversationsFail
  | RemoveAllConversationsSuccess
  | SetMailDetailId
  | ResetConversationState
  | SetCurrentQuery
  | RemoveAllConversationsFromFolder
  | AddTab
  | RemoveTab
  | RemoveAllTabs
  | RemoveMultipleTabs
  | UpdateLastConversationData
  | RemoveConversationsSuccess;
