
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy, Inject, NgZone, ElementRef, ViewChild, ChangeDetectorRef } from "@angular/core";
import { MailFolder } from "../../../models/mail-folder.model";
import { Store } from "@ngrx/store";
import { MailRootState } from "../../../store";
import { Subject } from "rxjs/internal/Subject";
import { takeUntil, take } from "rxjs/operators";
import { MailFolderRepository } from "../../../repositories/mail-folder-repository";
import { getMailFolders } from "../../../store/selectors";
import { MailConstants } from "../../../../common/utils/mail-constants";
import { CommonUtils } from "../../../../common/utils/common-util";
import { TranslateService } from "@ngx-translate/core";
import { MailBroadcaster } from "../../../../common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "../../../../common/enums/broadcast.enum";
import { CommonRepository } from "../../../repositories/common-repository";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { ToastService } from "../../../../common/providers/toast.service";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CreateFolderComponent } from "src/app/mail/create-folder/create-folder.component";
import { FormControl } from "@angular/forms";
import { VncLibraryService } from "vnc-library";


@Component({
  selector: "vp-move-folder-dialog",
  templateUrl: "./move-folder-dialog.component.html"
})
export class MoveFolderDialogComponent implements OnInit, OnDestroy {
  mailFolders: MailFolder[] = [];
  private isAlive$ = new Subject<boolean>();
  folderToMove: MailFolder;
  selectedMailFolder: MailFolder;
  rootUserFolders: MailFolder[] = [];
  isFolderMoveOperation: boolean = false;
  sourceFolderId: string;
  searchText: string = "";
  createFolderName: string = "";
  isCreateFolder: boolean = false;
  isMobileScreen = false;
  currentSelected: MailFolder;
  @ViewChild("folderNameSearchInput", {static: false}) folderNameSearchInput: ElementRef<HTMLInputElement>;
  @ViewChild("folderNameCreateInput", {static: false}) folderNameCreateInput: ElementRef<HTMLInputElement>;
  searchControl: FormControl = new FormControl("", []);
  constructor(
    private vncLibraryService: VncLibraryService,
    private dialogRef: MatDialogRef<MoveFolderDialogComponent>,
    private store: Store<MailRootState>,
    private mailFolderRepository: MailFolderRepository,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private breakpointObserver: BreakpointObserver,
    private mailBroadcaster: MailBroadcaster,
    private commonRepository: CommonRepository,
    private ngZone: NgZone,
    private hotKeyService: NgxHotkeysService,
    private changeDetectionRef: ChangeDetectorRef,
    private matDialog: MatDialog,
    public toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.hotKeyService.pause(this.hotKeyService.hotkeys);
    this.isFolderMoveOperation = data.isFolderMoveOperation;
    if (this.isFolderMoveOperation) {
      this.folderToMove = data.folderToMove;
    } else {
      this.sourceFolderId = data.sourceFolderId;
    }
    setTimeout(() => {
      this.changeDetectionRef.detectChanges();
    }, 50);
    this.setFocus();
  }

  ngOnInit() {
    this.store
      .select(getMailFolders)
      .pipe(takeUntil(this.isAlive$))
      .subscribe(res => {
        if (res) {
          this.rootUserFolders = res.filter(
            folder =>
              (!folder.perm &&
                folder.name !== MailConstants.STARRED_FOLDER_TITLE &&
                folder.name !== MailConstants.DRAFTS_FOLDER_TITLE &&
                folder.name !== MailConstants.SPAM_FOLDER_TITLE) ||
              (folder.perm && folder.perm !== "r")
          );

          this.translate.get(MailConstants.SYSTEM_FOLDERS)
          .pipe(take(1)).subscribe(result => {
            this.rootUserFolders = this.rootUserFolders.map(folder => {
              if (folder.icon) {
                folder.name = result[`${folder.name.toUpperCase()}_FOLDER`] || folder.name;
              }
              return folder;
            });
          });
        }
      });
    if (CommonUtils.isOnIOS) {
      this.mailBroadcaster.broadcast(BroadcastKeys.HANDLE_MAIL_LIST_SCROLL, true);
    }

    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_MOVE_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.dialogRef.close();
      });
    });
  }

  getChildrens(folder: MailFolder) {
    this.selectedMailFolder = folder;
    this.setFocus();
  }

  getParentFolder() {
    const rootFolder: MailFolder = this.mailFolderRepository.getRootFolder(
      this.selectedMailFolder
    );
    this.selectedMailFolder = this.commonRepository.getSelectedSubFolder(
      this.selectedMailFolder.l,
      rootFolder
    );
    this.setFocus();
  }

  selectFolder(selectedFolder: MailFolder) {
    if (this.sourceFolderId && selectedFolder.id === this.sourceFolderId
      || this.folderToMove && selectedFolder.id === this.folderToMove.id) {
        return false;
    }
    this.currentSelected = selectedFolder;
  }

  moveFolder(destinationFolder: MailFolder) {
    if ((this.isFolderMoveOperation && destinationFolder.id === this.folderToMove.id) ||
      (!this.isFolderMoveOperation && destinationFolder.id === this.sourceFolderId)) {
      return;
    }
    if (!this.isFolderMoveOperation) {
      this.close(destinationFolder);
      return;
    }
    if (this.folderToMove) {
      if (
        destinationFolder.children &&
        destinationFolder.children.find(f => f.name === this.folderToMove.name)
      ) {
        let translationText = "";
        this.translate
          .get("DUPLICATE_FOLDER_MOVE_MSG")
          .pipe(take(1))
          .subscribe((text: string) => {
            translationText = text;
          });
          this.vncLibraryService.openSnackBar(translationText, "checkmark",
          "", "", 2000, "bottom", "left").subscribe(res => {

          });
        return;
      }
      this.mailFolderRepository
        .moveMailFolder(this.folderToMove.id, destinationFolder.id)
        .subscribe(res => {
          this.mailBroadcaster.broadcast(MailConstants.BROADCAST_MOVE, this.folderToMove);
          const rootFolder: MailFolder = this.mailFolderRepository.getRootFolder(this.folderToMove);
          this.mailBroadcaster.broadcast(MailConstants.UPDATE_TARGET_FOLDER,
            this.mailFolderRepository.getParentFolder(this.folderToMove, rootFolder));
          this.folderSuccessAction(
            this.folderToMove,
            destinationFolder,
            "FOLDER_MOVED_MSG",
            true
          );
          this.dialogRef.close("success");
        });
    }
  }

  private folderSuccessAction(
    sourceFolder: MailFolder,
    targetFolder: MailFolder,
    translationKey: string,
    canUndo: boolean
  ): void {
    let translationText = "";
    let undoText = "";
    this.translate
      .get(translationKey, { folderName: sourceFolder.name })
      .pipe(take(1))
      .subscribe((text: string) => {
        translationText = text;
      });

    if (canUndo) {
      this.translate
        .get("UNDO")
        .pipe(take(1))
        .subscribe((text: string) => {
          undoText = text.toUpperCase();
        });
    }
    this.vncLibraryService.openSnackBar(translationText, "action", undoText, "", 2000, "bottom", "left").subscribe((r) => {
      if (r === "Action") {
        this.performUndo(sourceFolder, sourceFolder.l, sourceFolder.name);
      }
    });
  }

  performUndo(sourceFolder, targetFolderId, originalFolderName) {
    this.mailFolderRepository
      .moveMailFolder(sourceFolder.id, targetFolderId)
      .subscribe(res => {
        this.mailBroadcaster.broadcast(MailConstants.BROADCAST_UNDO_MOVE, sourceFolder);
        const rootFolder: MailFolder = this.mailFolderRepository.getRootFolder(sourceFolder);
        this.mailBroadcaster.broadcast(MailConstants.UPDATE_TARGET_FOLDER,
          this.mailFolderRepository.getParentFolder(sourceFolder, rootFolder));
        let translationText = "";
        this.translate
          .get("FOLDER_MOVEDBACK_MSG", { folderName: originalFolderName })
          .pipe(take(1))
          .subscribe((text: string) => {
            translationText = text;
          });
          this.vncLibraryService.openSnackBar(translationText, "checkmark",
          "", "", 2000, "bottom", "left").subscribe(res => {
          });
      });
  }

  toggleCreateSearch() {
    this.createFolderName = "";
    this.searchText = "";
    this.searchControl.patchValue("");
    if (this.isCreateFolder) {
      this.isCreateFolder = false;
      this.setFocus();
    } else {
      this.isCreateFolder = true;
      this.setFocus();
    }
  }

  closeCreateFolder() {
    this.createFolderName = "";
    this.isCreateFolder = false;
    this.setFocus();
  }

  createFolder() {
    if (this.commonRepository.showNoInternetToastIfRequired()) {
        return;
    }

    this.matDialog.open(CreateFolderComponent, {
      width: "480px",
      height: "200px",
      autoFocus: true,
      panelClass: "mail_folder-create_dialog",
      data: { targetFolder: this.selectedMailFolder, isRename: false }
    });
    let objDiv = document.getElementsByClassName("user-folders-list-move-folder");
    if (objDiv[0]) {
      objDiv[0].scrollTop = objDiv[0].scrollHeight;
    }
  }

  isFolderAlreadyExist() {
    if (this.selectedMailFolder && this.selectedMailFolder.children) {
      if (this.selectedMailFolder.children.find(folder => folder.name === this.createFolderName)) {
        return true;
      }
    } else if (!this.selectedMailFolder && this.rootUserFolders) {
      if (this.rootUserFolders.find(folder => folder.name === this.createFolderName)) {
        return true;
      }
    }
    return false;
  }

  setFocus() {
    setTimeout(() => {
      let vncinput = <HTMLInputElement>document.querySelector("vnc-input input");
      if (this.isCreateFolder && this.folderNameCreateInput) {
        this.folderNameCreateInput.nativeElement.focus();
      } else if (vncinput) {
        vncinput.focus();
      }
    }, 200);
  }

  close(folder) {
    this.dialogRef.close({ destinationFolder: folder });
  }

  ngOnDestroy() {
    this.hotKeyService.unpause(this.hotKeyService.hotkeys);
    if (CommonUtils.isOnIOS) {
      this.mailBroadcaster.broadcast(BroadcastKeys.HANDLE_MAIL_LIST_SCROLL, false);
    }
    this.isAlive$.next(false);
    this.isAlive$.unsubscribe();
  }
}
