
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { MailRootState } from "src/app/mail/store";
import { getIncomingFilters, getAllUserContacts } from "src/app/reducers";
import { take } from "rxjs/operators";

@Component({
    selector: "vp-mail-detail-email-operation-dialog",
    templateUrl: "./mail-detail-email-operation.component.html"
})
export class MailDetailEmailOperationDialogComponent implements OnInit, OnDestroy {
    mainMenu: boolean = true;
    findContactMenu: boolean = false;
    filterMenu: boolean = false;
    incomingFilters: any[] = [];
    isEditContact: boolean = false;
    userContacts: any[] = [];
    email: string = "";
    constructor(
        public dialogRef: MatDialogRef<MailDetailEmailOperationDialogComponent>,
        private store: Store<MailRootState>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.store.select(getIncomingFilters).pipe(take(1)).subscribe(res => {
            this.incomingFilters = res;
        });
        this.store.select(getAllUserContacts).pipe(take(1)).subscribe(res => {
            this.userContacts = res;
        });
        if (this.data.item) {
            this.email = this.data.item.a;
        }
        const contactExists = this.userContacts.filter(c => !!c._attrs && !!c._attrs.email && c._attrs.email === this.email)[0];
        if (!!contactExists && contactExists !== null) {
            this.isEditContact = true;
        }
    }

    ngOnInit() { }

    ngOnDestroy() { }

    close(): void {
        this.dialogRef.close();
    }

    findContactMenuEnable(): void {
        this.mainMenu = false;
        this.findContactMenu = true;
        this.filterMenu = false;
    }

    filterMenuEnable(): void {
        this.mainMenu = false;
        this.findContactMenu = false;
        this.filterMenu = true;
    }

    operationEmail(operation: string, isEdit?: boolean, filter?: any): void {
        this.dialogRef.close({ operation: operation, isEdit: isEdit, filter: filter });
    }
}
