
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

export class ContactConstants {
  static DELETE_FOLDER = "DELETE_FOLDER";
  static EMPTY_FOLDER = "EMPTY_FOLDER";
  static EMPTY_TRASH = "EMPTY_TRASH";
  static CONFIRM_DELETE_FOLDER = "CONFIRM_DELETE_FOLDER";
  static CONFIRM_EMPTY_FOLDER = "CONFIRM_EMPTY_FOLDER";
  static UNDO = "UNDO";
  static MOVE_ITEM_MSG = "MOVE_ITEM_MSG";
  static COPY_ITEM_MSG = "COPY_ITEM_MSG";
  static SOME_UNKNOWN_ERROR = "SOME_UNKNOWN_ERROR";
  static CONTACT_MOVED_TO = "CONTACT_MOVED_TO";
  static FOLDER_ALREADY_EMPTY = "FOLDER_ALREADY_EMPTY";
  static DELETE_CONTACT_HEADER = "DELETE_CONTACT_HEADER";
  static DELETE_CONTACT_LIST_HEADER = "DELETE_CONTACT_LIST_HEADER";
  static CONTACT_IMPORT_SUCCESS_MSG = "CONTACT_IMPORT_SUCCESS_MSG";
  static CONTACT_IMPORT_ERROR_MSG = "CONTACT_IMPORT_ERROR_MSG";
  static CONTACT_EXPORT_ERROR = "CONTACT_EXPORT_ERROR";
  static CONTACT_LIST_CREATED_MSG = "CONTACT_LIST_CREATED_MSG";
  static CONTACT_LIST_UPDATED_MSG = "CONTACT_LIST_UPDATED_MSG";
  static A_Z_FIRST_NAME = "A_Z_FIRST_NAME";
  static Z_A_FIRST_NAME = "Z_A_FIRST_NAME";
  static A_Z_LAST_NAME = "A_Z_LAST_NAME";
  static Z_A_LAST_NAME = "Z_A_LAST_NAME";
  static EDIT_CONTACT = "EDIT_CONTACT";
  static DELETE = "DELETE";
  static CONFIRM_DELETE_CONTACT = "CONFIRM_DELETE_CONTACT";
  static CONFIRM_DELETE_CONTACT_PERMANENTLY = "CONFIRM_DELETE_CONTACT_PERMANENTLY";
  static SEARCH_EXIST_MSG = "SEARCH_EXIST_MSG";
  static SEARCH_SAVED_MSG = "SEARCH_SAVED_MSG";
  static EMPTY_SEARCH_ERROR = "EMPTY_SEARCH_ERROR";
  static DESCENDING = "desc";
  static ASCENDING = "asc";
  static DELETE_ITEM_MSG = "DELETE_ITEM_MSG";
  static UNDO_ITEM_MSG = "UNDO_ITEM_MSG";
  static FAVORITE_TAG = "contact_favourite";
  static CONTACT_FAVORITE_SUCCESS_MSG = "CONTACT_FAVORITE_SUCCESS_MSG";
  static PRINT_CONTACT_NOT_FOUND = "PRINT_CONTACT_NOT_FOUND";
  static EMAILED_CONTACTS = "Emailed Contacts";
  static CONTACTS = "Contacts";
  static TRASHFOLDERNAME = "Trash";
  static CONTACT_CREATED_MSG = "CONTACT_CREATED_MSG";
  static CONTACT_UPDATED_MSG = "CONTACT_UPDATED_MSG";
  static PERMISSION_DENIED_MSG = "PERMISSION_DENIED_MSG";
  static CONTACT_PERMANENTLY_DELETE = "CONTACT_PERMANENTLY_DELETE";
  static TRASH_TITLE = "TRASH_TITLE";
  static CONTACT_SEARCH_TITLE = "search";
  static DEFAULTROUTE = "all";
  static SEARCH_STRING_ERR_MSG = "SEARCH_STRING_ERR_MSG";
  static CONTACT_FAVOURITE_REMOVE_SUCCESS_MSG = "CONTACT_FAVOURITE_REMOVE_SUCCESS_MSG";
  static MORE_TITLE = "MORE_TITLE";
  static LESS_TITLE = "LESS_TITLE";
  static MOBILE = "MOBILE";
  static WORK = "WORK";
  static WORKFAX = "WORKFAX";
  static HOME = "HOME";
  static HOMEFAX = "HOMEFAX";
  static PAGER = "PAGER";
  static CALLBACK = "CALLBACK";
  static ASSISTANT = "ASSISTANT";
  static CAR = "CAR";
  static OTHER = "OTHER";
  static OTHERFAX = "OTHERFAX";
  static COMPANY = "COMPANY";
  static LESS = "less";
  static MORE = "more";
  static PHONE_TYPE = "PHONE_TYPE";
  static ADDRESS_TYPE = "ADDRESS_TYPE";
  static IM_TYPE = "IM_TYPE";
  static CONTACT_EVENT_TYPE = "CONTACT_EVENT_TYPE";
  static URL_TYPE = "URL_TYPE";
  static IMAGE_UPLOAD_MSG = "IMAGE_UPLOAD_MSG";
  static CONTACT_LANGUAGE = "contactLanguage";
  static DOWNLOAD_FILE_IN = "DOWNLOAD_FILE_IN";
  static IMPORT_CONTACT_START = "IMPORT_CONTACT_START";
  static HEAVY_SIZE_CSV_ERR = "HEAVY_SIZE_CSV_ERR";
  static EXCEED_CONTACTS_SIZE_ERR = "EXCEED_CONTACTS_SIZE_ERR";
  static PROFILEADDRESSBOOK = "current-user-addbook";
  static FILE_DOWNLOAD_SUCCESS_MSG = "FILE_DOWNLOAD_SUCCESS_MSG";
  static ENGLISH_LABEL: "ENGLISH_LABEL";
  static DEUTSCH_LABEL: "DEUTSCH_LABEL";
  static AVTAR_SIZE_ERR: "AVTAR_SIZE_ERR";
  static FIRST_NAME_REQUIRED: "FIRST_NAME_REQUIRED";
  static INVALID_CSV_FILE = "INVALID_CSV_FILE";
  static CSV_FILE_ALLOWED_EXTENSION = "CSV_FILE_ALLOWED_EXTENSION";
  static SEARCH_NOT_ALLOWED_CHARACTER = "SEARCH_NOT_ALLOWED_CHARACTER";
  static VALIDIMAGEFILEMSG = "VALIDIMAGEFILEMSG";
  static NO_LOCATION_FOUND = "NO_LOCATION_FOUND";
  static NETWORK_CONNECTION_LOST = "NETWORK_CONNECTION_LOST";
  static CONTACT_SERVER_DOWN_ERROR = "CONTACT_SERVER_DOWN_ERROR";
  static SHOW_LESS_LBL = "SHOW_LESS_LBL";
  static SHOW_LESS_MORE_LBL = "SHOW_LESS_MORE_LBL";
  static EMPTY_ITEM_MSG = "EMPTY_ITEM_MSG";
  static CONTACT_NOT_SAVE_INFO_MSG = "CONTACT_NOT_SAVE_INFO_MSG";
  static CONTACT_NOT_SAVE_EDIT_INFO_MSG = "CONTACT_NOT_SAVE_EDIT_INFO_MSG";
  static CONTACT_FORM_FIELD_INCORRECT_DATA = "CONTACT_FORM_FIELD_INCORRECT_DATA";
  static APP_OFFLINE: "APP_OFFLINE";
  static TOAST_TIMEOUT = 1000;
  static CONTACT_FOLDER_ID = "7";
  static MOVE_CONTACT_RESTRICTION_MSG = "MOVE_CONTACT_RESTRICTION_MSG";
  static FEATURE_NEXT_VERSION_LBL = "FEATURE_NEXT_VERSION_LBL";
  static FILE_LENGTH_ERROR = "FILE_LENGTH_ERROR";
  static IMAGE_REMOVE_MSG = "IMAGE_REMOVE_MSG";
  static DISTRIBUTION_LIST = "distribution_list";
  static ENTER_GROUP_NAME = "ENTER_GROUP_NAME";
  static ENTER_GROUP_NAME_AND_MEMBER = "ENTER_GROUP_NAME_AND_MEMBER";
  static CONTACT_LIST_ALREADY_EXISTS_MSG = "CONTACT_LIST_ALREADY_EXISTS_MSG";
  static DUPLICATE_CONTACT_GROUP_NAME = "DUPLICATE_CONTACT_GROUP_NAME";
  static CONTACT_ALREADY_SELECTED = "CONTACT_ALREADY_SELECTED";
  static CONTACTS_LABLE = "CONTACTS";
  static ROOT_FOLDER_ID = "1";
  static CONTACT_FOLDER_CREATE_BROADCAST = "CONTACT_FOLDER_CREATE_BROADCAST";
  static CREATE_CONTACT_FOLDER = "CREATE_CONTACT_FOLDER";
  static RENAME_CONTACT_FOLDER = "RENAME_CONTACT_FOLDER";
  static DELETE_CONTACT_FOLDER = "DELETE_CONTACT_FOLDER";
}
