export class SchedulerUtils {  

    /**
     * Defines the "person" resource type.
     */
    static PERSON = "PERSON";
    /**
     * Defines the "optional person" resource type.
     */
    static OPTIONAL_PERSON = "OPT_PERSON";
    /**
     * Defines the "group" resource type.
     */
    static GROUP = "GROUP";
    /**
     * Defines the "location" resource type.
     */
    static LOCATION = "LOCATION";
    /**
     * Defines the "equipment" resource type.
     */
    static EQUIPMENT = "EQUIPMENT";
    static FORWARD = "FORWARD";

    /**
     * Defines the "accept" participant status.
     */
    static PSTATUS_ACCEPT = "AC";			// vevent, vtodo
    /**
     * Defines the "declined" participant status.
     */
    static PSTATUS_DECLINED = "DE";			// vevent, vtodo
    /**
     * Defines the "deferred" participant status.
     */
    static PSTATUS_DEFERRED = "DF";			// vtodo					[outlook]
    /**
     * Defines the "delegated" participant status.
     */
    static PSTATUS_DELEGATED = "DG";			// vevent, vtodo
    /**
     * Defines the "needs action" participant status.
     */
    static PSTATUS_NEEDS_ACTION = "NE";			// vevent, vtodo
    /**
     * Defines the "completed" participant status.
     */
    static PSTATUS_COMPLETED = "CO";			// vtodo
    /**
     * Defines the "tentative" participant status.
     */
    static PSTATUS_TENTATIVE = "TE";			// vevent, vtodo
    /**
     * Defines the "waiting" participant status.
     */
    static PSTATUS_WAITING = "WA";			// vtodo					[outlook]

    static FBA_TO_PTST = {
        B: SchedulerUtils.PSTATUS_ACCEPT,
        F: SchedulerUtils.PSTATUS_DECLINED,
        T: SchedulerUtils.PSTATUS_TENTATIVE
    };

    /**
     * Compares two appointments by start time and duration.
     */
    static compareByTimeAndDuration(a, b) {
        if (a.getStartTime() > b.getStartTime()) return 1;
        if (a.getStartTime() < b.getStartTime()) return -1;
        if (a.getDuration() < b.getDuration()) return 1;
        if (a.getDuration() > b.getDuration()) return -1;
        return 0;
    }

    static getLabelForParticipationStatus(status) {
        switch (status) {
            case this.PSTATUS_ACCEPT: return "PSTATUS_ACCEPT";
            case this.PSTATUS_DECLINED: return "PSTATUS_DECLINED";
            case this.PSTATUS_DEFERRED: return "PSTATUS_DEFERRED";
            case this.PSTATUS_DELEGATED: return "PSTATUS_DELEGATED";
            case this.PSTATUS_NEEDS_ACTION: return "PSTATUS_NEEDS_ACTION";
            case this.PSTATUS_COMPLETED: return "PSTATUS_COMPLETED";
            case this.PSTATUS_TENTATIVE: return "PSTATUS_TENTATIVE";
            case this.PSTATUS_WAITING: return "PSTATUS_WAITING";
        }
        return "";
    }

    static getParticipationStatusIcon(status) {
        switch (status) {
            case this.PSTATUS_ACCEPT: return "Check";
            case this.PSTATUS_DECLINED: return "Cancel";
            case this.PSTATUS_DEFERRED: return "QuestionMark";
            case this.PSTATUS_DELEGATED: return "Plus";
            case this.PSTATUS_NEEDS_ACTION: return "NeedsAction";
            case this.PSTATUS_COMPLETED: return "Completed";
            case this.PSTATUS_TENTATIVE: return "QuestionMark";
            case this.PSTATUS_WAITING: return "Minus";
        }
        return "";
    }

    static getClassForStatus(status) {
        return SchedulerUtils.freeBusySchedulerView().STATUS_CLASSES[status];
    }

    static getClassForParticipationStatus(status) {
        return SchedulerUtils.freeBusySchedulerView().PSTATUS_CLASSES[status];
    }

    static _getClassForStatus(status) {
        return SchedulerUtils.freeBusySchedulerView().STATUS_CLASSES[status];
    }

    static freeBusySchedulerView() {
        const freeBusySchedulerView: any = {};
        freeBusySchedulerView.FREEBUSY_NUM_CELLS = 48;

        /**
         * Defines the "free" status.
         */
        freeBusySchedulerView.STATUS_FREE = 1;
        /**
         * Defines the "busy" status.
         */
        freeBusySchedulerView.STATUS_BUSY = 2;
        /**
         * Defines the "tentative" status.
         */
        freeBusySchedulerView.STATUS_TENTATIVE = 3;
        /**
         * Defines the "out" status.
         */
        freeBusySchedulerView.STATUS_OUT = 4;
        /**
         * Defines the "unknown" status.
         */
        freeBusySchedulerView.STATUS_UNKNOWN = 5;
        freeBusySchedulerView.STATUS_WORKING = 6;
        // Pre-cache the status css class names
        freeBusySchedulerView.STATUS_CLASSES = [];
        freeBusySchedulerView.STATUS_CLASSES[freeBusySchedulerView.STATUS_FREE] = "ZmScheduler-free";
        freeBusySchedulerView.STATUS_CLASSES[freeBusySchedulerView.STATUS_BUSY] = "ZmScheduler-busy";
        freeBusySchedulerView.STATUS_CLASSES[freeBusySchedulerView.STATUS_TENTATIVE] = "ZmScheduler-tentative";
        freeBusySchedulerView.STATUS_CLASSES[freeBusySchedulerView.STATUS_OUT] = "ZmScheduler-outOfOffice";
        freeBusySchedulerView.STATUS_CLASSES[freeBusySchedulerView.STATUS_UNKNOWN] = "ZmScheduler-unknown";
        freeBusySchedulerView.STATUS_CLASSES[freeBusySchedulerView.STATUS_WORKING] = "ZmScheduler-working";

        freeBusySchedulerView.PSTATUS_CLASSES = [];
        freeBusySchedulerView.PSTATUS_CLASSES[SchedulerUtils.PSTATUS_DECLINED] = "ZmSchedulerPTST-declined";
        freeBusySchedulerView.PSTATUS_CLASSES[SchedulerUtils.PSTATUS_DEFERRED] = "ZmSchedulerPTST-deferred";
        freeBusySchedulerView.PSTATUS_CLASSES[SchedulerUtils.PSTATUS_DELEGATED] = "ZmSchedulerPTST-delegated";
        freeBusySchedulerView.PSTATUS_CLASSES[SchedulerUtils.PSTATUS_NEEDS_ACTION] = "ZmSchedulerPTST-needsaction";
        freeBusySchedulerView.PSTATUS_CLASSES[SchedulerUtils.PSTATUS_TENTATIVE] = "ZmSchedulerPTST-tentative";
        freeBusySchedulerView.PSTATUS_CLASSES[SchedulerUtils.PSTATUS_WAITING] = "ZmSchedulerPTST-waiting";
        freeBusySchedulerView.ROLE_OPTIONS = {};

        freeBusySchedulerView.FREE_CLASS = freeBusySchedulerView.STATUS_CLASSES[freeBusySchedulerView.STATUS_FREE];

        return freeBusySchedulerView;
    }

}

