
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */


import { Action } from "../actions";
import { AppActionTypes } from "../actions/app";
import { UserProfile } from "../shared/models/user-profile";
import { Contact } from "../mail/shared/models/contact";
import { SwipeAction } from "../common/models/swipe-action.model";
import { Preference } from "../preference/shared/models";

export interface AppState {
  loggedInUserLoading: boolean;
  isSidebarCollapsed: boolean;
  isRightSidebarExpanded: boolean;
  activeProfile: string;
  userProfile: UserProfile;
  isOnline: boolean;
  isLoggedIn: boolean;
  isDeviceReady: boolean;
  isActionProcessing: boolean;
  contactProfiles: Contact[];
  session: any;
  pollingInterval: number;
  swipeAction: SwipeAction;
  federatedApps: any[];
  savedRecentSearch: any[];
  viewBy: string;
  readingPane: string;
  expandConversation: string;
  availableZimlets: any[];
  currentFolder: any;
  query: string;
  firebaseToken?: string;
  props?: any;
  zimbraFeatures: Preference[];
  photoUpdate: any;
  domainSpecificLogo: any[];
  searchFor: string;
  includeShared: boolean;
  waitDisallowed: boolean;
  expandMailFolders: string;
  incomingFilters: any[];
  userContacts: any[];
  userAllGalContacts: any[];
  userSignatures: any[];
  expandContactFolders: string;
  expandBriefcaseFolders: string;
  folderTreeSash: string;
  paneSashVertical: string;
  paneSashHorizontal: string;
  isDatabaseReady: boolean;
  loadInsecureContent: string;
  showGlobalTags: string;
  lastDraftId: string;
}

export const initialState: AppState = {
  loggedInUserLoading: false,
  isSidebarCollapsed: false,
  isRightSidebarExpanded: false,
  userProfile: null,
  activeProfile: null,
  isOnline: false,
  isDeviceReady: false,
  isLoggedIn: false,
  isActionProcessing: false,
  contactProfiles: [],
  session: {},
  pollingInterval: 10000,
  swipeAction : {
    leftActionKey: "MAIL_DELETE_ACTION",
    leftActionIcon: "delete",
    leftActionType: "delete",
    rightactionKey: "MAIL_MOVE_ACTION",
    rightActionIcon: "folder",
    rightActionType: "move",
  },
  federatedApps: [],
  savedRecentSearch: [],
  viewBy: "conversation",
  readingPane: "right",
  expandConversation: "new",
  availableZimlets: [],
  currentFolder: 2,
  query: "",
  firebaseToken: "",
  props: null,
  zimbraFeatures: [],
  photoUpdate: {},
  domainSpecificLogo: [],
  searchFor: "mail",
  includeShared: false,
  waitDisallowed: false,
  expandMailFolders: "",
  incomingFilters: [],
  userContacts: [],
  userAllGalContacts: [],
  userSignatures: [],
  expandContactFolders: "",
  expandBriefcaseFolders: "",
  folderTreeSash: "",
  paneSashVertical: "",
  paneSashHorizontal: "",
  isDatabaseReady: false,
  loadInsecureContent: "false",
  showGlobalTags: "false",
  lastDraftId: "",
};

export function appReducer(state: AppState = initialState, action: Action): AppState {
  switch (action.type) {
    case AppActionTypes.SET_AVAILABLE_APPS: {
      return {
        ...state,
        federatedApps: action.payload
      };
    }

    case AppActionTypes.SET_SESSION: {
      return {
        ...state,
        session: action.payload
      };
    }

    case AppActionTypes.SET_READING_PANE: {
      return {
        ...state,
        readingPane: action.payload
      };
    }

    case AppActionTypes.SET_SAVED_RECENT_SEARCH: {
      return {
        ...state,
        savedRecentSearch: action.payload
      };
    }

    case AppActionTypes.SET_LAST_DRAFT_ID: {
      return {
        ...state,
        lastDraftId: action.payload
      };
    }

    case AppActionTypes.SET_VIEW_BY: {
      return {
        ...state,
        viewBy: action.payload
      };
    }

    case AppActionTypes.SET_EXPAND_CONVERSATION: {
      return {
        ...state,
        expandConversation: action.payload
      };
    }

    case AppActionTypes.SET_POLLING_INTERVAL: {
      return {
        ...state,
        pollingInterval: action.payload
      };
    }

    case AppActionTypes.LOGIN_REQUEST: {
      return {
        ...state,
        loggedInUserLoading: true
      };
    }

    case AppActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true
      };
    }

    case AppActionTypes.LOGIN_FAILED: {
      return {
        ...state,
        isLoggedIn: false
      };
    }

    case AppActionTypes.SET_SIDEBAR_STATUS: {
      return {
        ...state,
        isSidebarCollapsed: action.payload
      };
    }

    case AppActionTypes.SET_RIGHT_SIDEBAR_STATUS: {
      return {
        ...state,
        isRightSidebarExpanded: action.payload
      };
    }
    case AppActionTypes.SET_ACTIVE_PROFILE: {
      return {
        ...state,
        activeProfile: action.payload
      };
    }

    case AppActionTypes.APP_ONLINE: {
      return {
        ...state,
        isOnline: action.payload
      };
    }

    case AppActionTypes.SET_USER_PROFILE: {
      return {
        ...state,
        userProfile: action.payload
      };
    }

    case AppActionTypes.DEVICE_READY: {
      return {
         ...state,
          isDeviceReady: true
      };
    }


    case AppActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.appState;
      return savedState ? {...state, ...savedState} : state;
    }

    case AppActionTypes.START_PROCESSING: {
      return {
         ...state,
         isActionProcessing: true
      };
    }

    case AppActionTypes.STOP_PROCESSING: {
      return {
         ...state,
         isActionProcessing: false
      };
    }

    case AppActionTypes.SAVE_CONTACTS_PROFILE: {
      return {
         ...state,
         contactProfiles: action.payload
      };
    }

    case AppActionTypes.CHANGE_SWIPE_ACTION: {
      return {
        ...state,
        swipeAction: action.payload
      };
    }

    case AppActionTypes.SET_AVAILABLE_ZIMLETS: {
      return {
        ...state,
        availableZimlets: action.payload
      };
    }

    case AppActionTypes.SET_CURRENT_FOLDER: {
      return {
        ...state,
        currentFolder: action.payload
      };
    }

    case AppActionTypes.SET_CURRENT_QUERY: {
      return {
        ...state,
        query: action.payload
      };
    }

    case AppActionTypes.SET_FIREBASE_TOKEN: {
      return {
        ...state,
        firebaseToken: action.payload
      };
    }

    case AppActionTypes.SET_VNCMAIL_PROPS: {
      return {
        ...state,
        props: action.payload
      };
    }

    case AppActionTypes.SET_ZIMBRA_FEATURES: {
      return {
        ...state,
        zimbraFeatures: action.payload
      };
    }

    case AppActionTypes.SET_EXPAND_MAIL_FOLDERS: {
      return {
        ...state,
        expandMailFolders: action.payload
      };
    }

    case AppActionTypes.SET_LAST_PHOTO_UPDATE: {
      const photoUpdate = state.photoUpdate || {};
      photoUpdate[action.payload.email] = action.payload.timestamp;
      localStorage.setItem("lastPhotoUpdate", JSON.stringify(photoUpdate));
      return {
        ...state,
        photoUpdate: photoUpdate
      };
    }

    case AppActionTypes.RESET_LAST_PHOTO_UPDATE: {
      return {
        ...state,
        photoUpdate: {}
      };
    }

    case AppActionTypes.DATABASE_READY: {
      return {
        ...state,
        isDatabaseReady: true
      };
    }

    case AppActionTypes.RESTORE_LAST_PHOTO_UPDATE: {
      return {
        ...state,
        photoUpdate: action.payload
      };
    }

    case AppActionTypes.SET_SEARCH_FOR: {
      return {
        ...state,
        searchFor: action.payload
      };
    }

    case AppActionTypes.SET_INCLUDE_SHARED: {
      return {
        ...state,
        includeShared: action.payload
      };
    }

    case AppActionTypes.SET_DOMAIN_SPECIFIC_LOGO: {
      return {
        ...state,
        domainSpecificLogo: action.payload
      };
    }
    case AppActionTypes.SET_WAIT_DISALLOWED: {
      return {
        ...state,
        waitDisallowed: action.payload
      };
    }

    case AppActionTypes.SET_INCOMING_FILTERS: {
      return {
        ...state,
        incomingFilters: action.payload
      };
    }

    case AppActionTypes.SET_USER_CONTACTS: {
      return {
        ...state,
        userContacts: action.payload
      };
    }

    case AppActionTypes.SET_GAL_CONTACTS: {
      return {
        ...state,
        userAllGalContacts: action.payload
      };
    }

    case AppActionTypes.SET_USER_SIGNATURES: {
      return {
        ...state,
        userSignatures: action.payload
      };
    }

    case AppActionTypes.SET_EXPAND_CONTACT_FOLDERS: {
      return {
        ...state,
        expandContactFolders: action.payload
      };
    }

    case AppActionTypes.SET_EXPAND_BRIEFCASE_FOLDERS: {
      return {
        ...state,
        expandBriefcaseFolders: action.payload
      };
    }

    case AppActionTypes.SET_FOLDER_TREE_SASH: {
      return {
        ...state,
        folderTreeSash: action.payload
      };
    }

    case AppActionTypes.SET_PANE_SASH_VERTICAL: {
      return {
        ...state,
        paneSashVertical: action.payload
      };
    }

    case AppActionTypes.SET_PANE_SASH_HORIZONTAL: {
      return {
        ...state,
        paneSashHorizontal: action.payload
      };
    }

    case AppActionTypes.SET_INSECURE_LOAD_CONTENT: {
      return {
        ...state,
        loadInsecureContent: action.payload
      };
    }

    case AppActionTypes.SET_SHOW_GLOBAL_TAGS: {
      return {
        ...state,
        showGlobalTags: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const _getIsDatabaseReady = (state: AppState) => state.isDatabaseReady;
export const _getIsLoggedInUserLoading = (state: AppState) => state.loggedInUserLoading;
export const _getSidebarExpanderStatus = (state: AppState) => state.isSidebarCollapsed;
export const _getIsRightSidebarExpanded = (state: AppState) => state.isRightSidebarExpanded;
export const _getUserProfile = (state: AppState) => state.userProfile;
export const _IsDeviceReady = (state: AppState) => state.isDeviceReady;
export const _getOnlineStatus = (state: AppState) => state.isOnline;
export const _getIsLoggedIn = (state: AppState) => state.isLoggedIn;
export const _getProcessingState = (state: AppState) => state.isActionProcessing;
export const _getContactProfiles = (state: AppState) => state.contactProfiles;
export const _getSession = (state: AppState) => state.session;
export const _getPollingInterval = (state: AppState) => state.pollingInterval;
export const _getSwipeAction = (state: AppState) => state.swipeAction;
export const _getFederatedApps = (state: AppState) => state.federatedApps;
export const _getViewBy = (state: AppState) => state.viewBy;
export const _getExpandConversation = (state: AppState) => state.expandConversation;
export const _getReadingPane = (state: AppState) => state.readingPane;
export const _getAvailableZimlets = (state: AppState) => state.availableZimlets;
export const _getCurrentFolder = (state: AppState) => state.currentFolder;
export const _getCurrentQuery = (state: AppState) => state.query;
export const _getFirebaseToken = (state: AppState) => state.firebaseToken;
export const _getProps = (state: AppState) => state.props;
export const _getzimbraFeatures = (state: AppState) => state.zimbraFeatures;
export const _getLastPhotoUpdate = (state: AppState) => state.photoUpdate;
export const _getLastPhotoUpdateByEmail = (state: AppState, email: string) => state.photoUpdate[email];
export const _getdomainSpecificLogo = (state: AppState) => state.domainSpecificLogo;
export const _getSearchFor = (state: AppState) => state.searchFor;
export const _getIncludeSharedItems = (state: AppState) => state.includeShared;
export const _getWaitDisallowed = (state: AppState) => state.waitDisallowed;
export const _getExpandMailFolders = (state: AppState) => state.expandMailFolders;
export const _getIncomingFilters = (state: AppState) => state.incomingFilters;
export const _getUserContacts = (state: AppState) => state.userContacts;
export const _getUserGalContacts = (state: AppState) => state.userAllGalContacts;
export const _getUserSignatures = (state: AppState) => state.userSignatures;
export const _getExpandContactFolders = (state: AppState) => state.expandContactFolders;
export const _getExpandBriefcaseFolders = (state: AppState) => state.expandBriefcaseFolders;
export const _getFolderTreeSash = (state: AppState) => state.folderTreeSash;
export const _getPaneSashVertical = (state: AppState) => state.paneSashVertical;
export const _getPaneSashHorizontal = (state: AppState) => state.paneSashHorizontal;
export const _getLoadInsecureContent = (state: AppState) => state.loadInsecureContent;
export const _getShowGlobalTags = (state: AppState) => state.showGlobalTags;
export const _getLastDraftId = (state: AppState) => state.lastDraftId;
export const _getActiveProfile = (state: AppState) => state.activeProfile;
export const _getSavedRecentSearch = (state: AppState) => state.savedRecentSearch;
