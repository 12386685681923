
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import * as fromPreference from "../reducers";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { Dictionary } from "@ngrx/entity";
import { Preference } from "../../shared/models";

export interface PreferenceRootState {
    preference: fromPreference.PreferenceState;
}

export const getPreferenceRootState = createFeatureSelector<PreferenceRootState>("preference");

export const preferenceRootReducer = {
    preference: fromPreference.referenceReducer
};

export const getPreferenceState = createSelector(
    getPreferenceRootState,
    state => state.preference
);

export const getIsPreferencesLoading = createSelector(
    getPreferenceState,
    fromPreference._getIsPreferencesLoading
);

export const getSignatures = createSelector(
    getPreferenceState,
    fromPreference._getSignatures
);

export const getAttributes = createSelector(
    getPreferenceState,
    fromPreference._getAttributes
);

export const getSelectedAccount = createSelector(
    getPreferenceState,
    fromPreference._getSelectedAccount
);

export const getIdentity = createSelector(
    getPreferenceState,
    fromPreference._getIdentity
);


export const {
    selectIds: getPreferenceIds,
    selectEntities: getPreferenceEntities,
    selectAll: getAllPreferences,
    selectTotal: getTotalPreferences,
} = fromPreference.preferenceAdapter.getSelectors(getPreferenceState);

export const getPreferences = getAllPreferences;

export const getPreferencesByIds = createSelector(
    getPreferenceEntities,
    (entities: Dictionary<Preference>, preferenceIds: string[]) => {
        return preferenceIds.map(preferenceId => {
            return entities[preferenceId];
        }).filter(v => !!v);
    }
);

export const getPreferenceById = createSelector(
    getPreferenceEntities,
    (entities: Dictionary<Preference>, preferenceId: string) => {
        return entities[preferenceId];
    }
);
