
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div fxLayout="column" class="preference-dialog-form" *ngIf="!isMobileView">
  <vnc-header
    class="message"
    [title]="(action !== 'edit' ? 'ADD_FILTER_HEADING' : 'EDIT_FILTER_HEADING') | translate"
    type="title-close"
    size="l"
    (onCloseClick)="cancel()"
    [showCloseIcon]="true"
    closeIconToolTip="{{ 'CLOSE' | translate }}">
  </vnc-header>

  <div class="add-edit-dialog-body" *ngIf="dataIsReady && !isMobileView">
    <form method="post" [formGroup]="form" (ngSubmit)="onSubmit()" [class.updated]="form.valid && form.dirty">
      <!-- filter name input -->
      <vnc-input class="filter-name-input" [form]="name" placeholder="{{ 'FILTER_NAME' | translate}}" [showLabel]="true" [required]="true"></vnc-input>
      <!-- process checkbox input -->
      <vnc-checkbox label="{{ 'DO_NOT_PROCESS_ADDITIONAL_FILTER' | translate}}" [checked]="doNotProcessAdditionFilter" (change)="changeAdditionalFilter($event)"></vnc-checkbox>
      <!-- condition met input -->
      <div class="filter-form-option">
        <mat-form-field class="setting-component-form-field condition-met-input">
            <mat-label class="option-label">{{ 'CONDITIONS_MET_LBL' | translate }}</mat-label>
            <mat-select formControlName="condition">
              <mat-select-trigger *ngIf="'all' === condition?.value">
                <div class="mat-trigger-block">
                  <div class="mat-trigger-text-style">{{ 'ALL_TEXT' | translate }}</div>
                  <span class="mat-trigger-icon-style">
                    <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                    </ng-template>
                  </span>
                </div>
              </mat-select-trigger>
              <mat-select-trigger *ngIf="'any' === condition?.value">
                <div class="mat-trigger-block">
                  <div class="mat-trigger-text-style">{{ 'ANY_TEXT' | translate }}</div>
                  <span class="mat-trigger-icon-style">
                    <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                    </ng-template>
                  </span>
                </div>
              </mat-select-trigger>
            <mat-option class="mat-option-filter-form" [value]="'any'">
              <span>{{ 'ANY_TEXT' | translate }}</span>
              <mat-icon *ngIf="'any' === condition?.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
            </mat-option>
            <mat-option class="mat-option-filter-form" [value]="'all'">
              <span>{{ 'ALL_TEXT' | translate }}</span>
              <mat-icon *ngIf="'all' === condition?.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
            </mat-option>
            </mat-select>
        </mat-form-field>
      </div>
      <div class="sub-label-text">
        {{ 'CONDITION' | translate | uppercase}}
      </div>
      <!-- condition inputs -->
      <div class="condition-inputs" formArrayName="filterTests">
        <div [formGroupName]="i" *ngFor="let filterTest of form['controls'].filterTests['controls']; let i=index">
          <div class="sub-condition-input">
            <!-- condition rule 1 -->
            <div class="filter-form-option half-div condition-rule-1">
              <mat-form-field class="setting-component-form-field">
                  <mat-select formControlName="rule" (selectionChange)="setDefaultValue(filterTest)">
                    <mat-select-trigger *ngFor="let option of filterConstants.TESTS">
                      <div *ngIf="option === filterTest.value.rule" class="mat-trigger-block">
                        <div class="mat-trigger-text-style">{{ getTranslateKey(option) | translate }}</div>
                        <span class="mat-trigger-icon-style">
                          <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                          </ng-template>
                        </span>
                      </div>
                    </mat-select-trigger>
                    <mat-option class="mat-option-filter-form" *ngFor="let option of filterConstants.TESTS" [value]="option">
                      {{ getTranslateKey(option) | translate }}
                      <mat-icon *ngIf="option === filterTest.value.rule" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                    </mat-option>
                  </mat-select>
              </mat-form-field>
            </div>

            <!-- condition rule 2 -->
            <vnc-input class="half-div condition-rule-2" *ngIf="filterConstants.MENU2[filterTest.value.rule] && filterConstants.MENU2[filterTest.value.rule] === 'input'" [form]="rule2" [showLabel]="true" [required]="true"></vnc-input>
            <div class="filter-form-option half-div condition-rule-2" *ngIf="filterConstants.MENU2[filterTest.value.rule] && filterConstants.MENU2[filterTest.value.rule] !== 'input' && filterConstants.MENU2[filterTest.value.rule] !== 'datetime'">
              <mat-form-field class="setting-component-form-field">
                  <mat-select formControlName="rule2">
                    <mat-select-trigger *ngFor="let data of filterConstants.MENU2[filterTest.value.rule]">
                      <div *ngIf="data === filterTest.value.rule2" class="mat-trigger-block">
                        <div class="mat-trigger-text-style">{{ getTranslateKey(data) | translate }}</div>
                        <span class="mat-trigger-icon-style">
                          <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                          </ng-template>
                        </span>
                      </div>
                    </mat-select-trigger>
                    <mat-option class="mat-option-filter-form" *ngFor="let data of filterConstants.MENU2[filterTest.value.rule]" [value]="data">
                      {{ getTranslateKey(data) | translate }}
                      <mat-icon *ngIf="data === filterTest.value.rule2" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                    </mat-option>
                  </mat-select>
              </mat-form-field>
            </div>
            <span matTooltip="{{'COMMON.REMOVE' | translate}}" *ngIf="i > 0" class="delete-icon-style" (click)="$event.preventDefault();removeFilterTests(i)">
              <ng-container [ngTemplateOutlet]="deleteIcon"></ng-container>
            </span>
          </div>
          <!-- condition rule 3 -->
          <vnc-input class="full-div condition-rule-3" placeholder="{{ 'TEXT_LBL' | translate}}" *ngIf="filterConstants.MENU3[filterTest?.value?.rule] && filterConstants.MENU3[filterTest?.value?.rule] === 'input'" [form]="form['controls'].filterTests['controls'][i].controls.rule3" [showLabel]="true" [required]="true"></vnc-input>
          <div class="filter-form-option full-div condition-rule-3" *ngIf="filterConstants.MENU3[filterTest.value.rule] && filterConstants.MENU3[filterTest.value.rule] !== 'input' && filterConstants.MENU3[filterTest.value.rule] !== 'datetime'">
            <mat-form-field class="setting-component-form-field">
              <mat-select formControlName="rule3">
                <mat-select-trigger *ngFor="let data of filterConstants.MENU3[filterTest.value.rule]">
                  <div *ngIf="data === filterTest.value.rule3" class="mat-trigger-block">
                    <div class="mat-trigger-text-style">{{ getTranslateKey(data) | translate }}</div>
                    <span class="mat-trigger-icon-style">
                      <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                      </ng-template>
                    </span>
                  </div>
                </mat-select-trigger>
                <mat-option class="mat-option-filter-form" *ngFor="let data of filterConstants.MENU3[filterTest.value.rule]" [value]="data">
                  {{ getTranslateKey(data) | translate }}
                  <mat-icon *ngIf="data === filterTest.value.rule3" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <mat-form-field class="date-picker-input condition-rule-3" *ngIf="filterConstants.MENU3[filterTest.value.rule] && filterConstants.MENU3[filterTest.value.rule] === 'datetime'">
            <input matInput formControlName="rule3" [matDatepicker]="picker" placeholder="Choose date" required>
            <span (click)="picker.open()" class="datepicker-icon-trigger">
              <ng-template [ngTemplateOutlet]="datePickerIcon">
              </ng-template>
          </span>
            <!-- <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle> -->
            <mat-datepicker #picker panelClass="create-filter-date-picker"></mat-datepicker>
          </mat-form-field>
          <!-- condition rule 4 -->
          <vnc-input class="full-div condition-rule-4" *ngIf="filterConstants.MENU4[filterTest.value.rule] && filterConstants.MENU4[filterTest.value.rule] === 'input'
          && (filterTest.value.rule !== 'Header Named' || (filterTest.value.rule === 'Header Named' && !['exists', 'does not exist'].includes(filterTest.value.rule3)))
          && (filterTest.value.rule !== 'Address in' || (filterTest.value.rule === 'Address in' && !['exists', 'does not exist', 'is me', 'is not me'].includes(filterTest.value.rule3)))" [form]="form['controls'].filterTests['controls'][i].controls.rule4" [showLabel]="true" [required]="true"></vnc-input>
          <div class="filter-form-option full-div condition-rule-4" *ngIf="filterConstants.MENU4[filterTest.value.rule] && filterConstants.MENU4[filterTest.value.rule] !== 'datetime'
          && filterConstants.MENU4[filterTest.value.rule] !== 'input'
          && ((filterTest.value.rule === 'Message' && filterTest.value.rule3 === 'marked as') || filterTest.value.rule !== 'Message')
          && (filterTest.value.rule !== 'Address in' || (filterTest.value.rule === 'Address in' && !['exists', 'does not exist', 'is me', 'is not me'].includes(filterTest.value.rule3)))
          ">
           <mat-form-field class="setting-component-form-field">
               <mat-select formControlName="rule4">
                 <mat-select-trigger *ngFor="let data of filterConstants.MENU4[filterTest.value.rule]" [value]="data">
                   <div *ngIf="data === filterTest.value.rule4" class="mat-trigger-block">
                     <div class="mat-trigger-text-style">{{ getTranslateKey(data) | translate }}</div>
                     <span class="mat-trigger-icon-style">
                       <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                       </ng-template>
                     </span>
                   </div>
                 </mat-select-trigger>
                 <mat-option class="mat-option-filter-form" *ngFor="let data of filterConstants.MENU4[filterTest.value.rule]" [value]="data">
                   {{ getTranslateKey(data) | translate }}
                   <mat-icon *ngIf="data === filterTest.value.rule4" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                 </mat-option>
               </mat-select>
           </mat-form-field>
          </div>
        </div>
      </div>
      <div>

      </div>
      <div class="add-condition-btn">
        <vnc-link-button [leadingIconName]="'mdi-plus'" [type]="'primary'" [size]="'xs'" [label]="'ADD_CONDITION' | translate" (click)="$event.preventDefault();addFilterTests()"></vnc-link-button>
      </div>
      <div class="sub-label-text" style="margin-top: 24px;">
          {{ 'ACTION' | translate | uppercase }}
      </div>

      <!-- action inputs -->

      <div class="action-inputs" formArrayName="filterActions">
        <div [formGroupName]="i"  *ngFor="let filterAction of form['controls'].filterActions['controls']; let i=index">
          <div class="filter-form-option">
            <mat-form-field class="setting-component-form-field">
                <mat-select formControlName="action" (selectionChange)="setDefaultActionValue(filterAction)">
                  <mat-select-trigger *ngFor="let action of filterConstants.ACTIONS" [value]="action">
                    <div *ngIf="action === filterAction.value.action" class="mat-trigger-block">
                      <div class="mat-trigger-text-style">{{ getTranslateKey(action) | translate }}</div>
                      <span class="mat-trigger-icon-style">
                        <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                        </ng-template>
                      </span>
                    </div>
                  </mat-select-trigger>
                  <mat-option class="mat-option-filter-form" *ngFor="let action of filterConstants.ACTIONS" [value]="action">
                    {{ getTranslateKey(action) | translate }}
                    <mat-icon *ngIf="data === filterAction.value.action" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                  </mat-option>
                </mat-select>
            </mat-form-field>
            <span *ngIf="i > 0" matTooltip="{{'COMMON.REMOVE' | translate}}" class="delete-icon-style" (click)="$event.preventDefault();removeFilterActions(i)">
              <ng-container [ngTemplateOutlet]="deleteIcon"></ng-container>
            </span>
          </div>
          <vnc-input *ngIf="filterAction.value.action === 'Redirect to Address'" [form]="form['controls'].filterActions['controls'][i].controls?.value" [showLabel]="true" [required]="true"></vnc-input>
          <div class="filter-form-option" *ngIf="filterAction.value.action === 'Mark as'">
            <mat-form-field class="setting-component-form-field">
                <mat-select formControlName="value">
                  <mat-select-trigger *ngIf="'read' === filterAction?.value?.value">
                    <div *ngIf="'read' === filterAction.value.value" class="mat-trigger-block">
                      <div class="mat-trigger-text-style">{{ 'READ' | translate}}</div>
                      <span class="mat-trigger-icon-style">
                        <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                        </ng-template>
                      </span>
                    </div>
                  </mat-select-trigger>
                  <mat-select-trigger *ngIf="'flagged' === filterAction?.value?.value">
                    <div *ngIf="'flagged' === filterAction.value.value" class="mat-trigger-block">
                      <div class="mat-trigger-text-style">{{ 'FLAGGED' | translate}}</div>
                      <span class="mat-trigger-icon-style">
                        <ng-template [ngTemplateOutlet]="matSelectTriggerIcon">
                        </ng-template>
                      </span>
                    </div>
                  </mat-select-trigger>
                  <mat-option class="mat-option-filter-form" value="read">{{ 'READ' | translate}}
                    <mat-icon *ngIf="'read' === filterAction.value.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                  </mat-option>
                  <mat-option class="mat-option-filter-form" value="flagged">
                    {{ 'FLAGGED' | translate}}
                    <mat-icon *ngIf="'flagged' === filterAction.value.value" matSuffix fontSet="mdi" fontIcon="mdi-check"></mat-icon>
                  </mat-option>
                </mat-select>
            </mat-form-field>
          </div>
          <div id="filter-action-move" class="full-width" *ngIf="filterAction.value.action === 'Move into folder'">
            <input type="text" matInput style="display: none" formControlName="value" required>
            <input type="text" matInput style="display: none" formControlName="name">
            <button class="browse-button" (click)="openMailFolders(filterAction)" mat-button color="primary">{{ mailService?.getFolderNameKey(filterAction.value.name, MailConstants?.SYSTEM_FOLDERS) || ('BROWSE_BUTTON' | translate) }}</button>
          </div>
        </div>
      </div>
      <div class="add-action-btn" (click)="$event.preventDefault();addFilterActions()">
        <vnc-link-button [leadingIconName]="'mdi-plus'" [type]="'primary'" [size]="'xs'" [label]="'ADD_ACTION' | translate"></vnc-link-button>
      </div>
    </form>
  </div>

  <vnc-footer [secondaryLabel]="'CANCEL_MAIL_LABEL' | translate | uppercase"
  [primaryLabel]="'ADD' | translate | uppercase"
  [isPrimaryDisabled]="!(!invalid && !isExists &&!invalidAction)"
  [primaryLabel]="(action !== 'edit' ? 'ADD_FILTER_HEADING' : 'EDIT_FILTER_HEADING') | translate"
  [type]="'secondary-primary'"
  [color]="'white'"
  (onSecondaryClick)="cancel()"
  (onPrimaryClick)="save()"></vnc-footer>
</div>

<div  class="vp-filter-create-dialog mail-dialog" *ngIf="isMobileView">
  <div class="mail__dialog-header" layout="row center-center ">
      <div>
          <button (click)="cancel()" class="brand-color mat-button show-in-mobile">
              <mat-icon class="disable-select">chevron_left</mat-icon>
          </button>
          <button class="brand-color mat-button hide-in-mobile">
              <mat-icon class="disable-select">account_circle</mat-icon>
          </button>
      </div>
      <div *ngIf="action !== 'edit'" class="header-title ">
          <span class="disable-select">{{ 'ADD_FILTER_HEADING' | translate }}</span>
      </div>
      <div *ngIf="action === 'edit'" class="header_lbl disable-select">
        {{ 'EDIT_FILTER_HEADING' | translate }}
      </div>
  </div>
  <div class="mail__dialog-body">
    <div *ngIf="dataIsReady">
      <div class="process-additional-filter">
        <mat-checkbox [(ngModel)]="doNotProcessAdditionFilter" (change)="changeAdditionalFilter($event)">{{ 'DO_NOT_PROCESS_ADDITIONAL_FILTER' | translate }}</mat-checkbox>
      </div>
      <form method="post" [formGroup]="form" (ngSubmit)="onSubmit()" [class.updated]="form.valid && form.dirty">
        <div class="filter-dialog-content">
          <div class="filter-control">
            <p *ngIf="errorText" class="error existing-name ">
              {{ errorText }}
            </p>
            <mat-form-field>
              <input matInput formControlName="name" placeholder="{{ 'FILTER_NAME' | translate}}" autofocus required>
            </mat-form-field>
            <p *ngIf="isExists" class="error">{{ 'THERE_IS_ALREADY_A_FILTER_BY_THAT_NAME' |translate }}</p>

          </div>
          <div class="filter-control">
            <div class="condition-control">
                <span>{{ 'IF' | translate}}</span>
                <span>
                  <mat-form-field class="condition-form-control">
                    <mat-select formControlName="condition">
                      <mat-option [value]="'any'">{{ 'ANY_TEXT' | translate}}</mat-option>
                      <mat-option [value]="'all'">{{ 'ALL_TEXT' | translate}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </span>
            </div>

            <span>
                {{ 'OF_THE_FOLLOWING_CONDITIONS_ARE_MET' | translate}}</span>
          </div>
          <mat-divider></mat-divider>
          <div fxLayout="column" class="filter-tests-section" formArrayName="filterTests">
            <span class="section-header">{{ 'FILTER_CONDITION' | translate }}</span>
            <div [formGroupName]="i" fxLayout="row" fxLayout.xs="column" class="rule-section"
              *ngFor="let filterTest of form['controls'].filterTests['controls']; let i=index">
              <div fxLayout="column" fxFlex="20" fxFlex.xs="100">
                <mat-form-field class="full-width">
                  <mat-select formControlName="rule" (selectionChange)="setDefaultValue(filterTest)">
                    <mat-option *ngFor="let test of filterConstants.TESTS" [value]="test">{{ getTranslateKey(test) | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="30" fxFlex.xs="100">
                <mat-form-field *ngIf="filterConstants.MENU2[filterTest.value.rule] && filterConstants.MENU2[filterTest.value.rule] === 'input'">
                  <input matInput formControlName="rule2" required>
                </mat-form-field>
                <mat-form-field *ngIf="filterConstants.MENU2[filterTest.value.rule] && filterConstants.MENU2[filterTest.value.rule] !== 'input' && filterConstants.MENU2[filterTest.value.rule] !== 'datetime'"
                  class="full-width">
                  <mat-select formControlName="rule2">
                    <mat-option *ngFor="let data of filterConstants.MENU2[filterTest.value.rule]" [value]="data">{{
                        getTranslateKey(data) | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="30" fxFlex.xs="100">
                <mat-form-field *ngIf="filterConstants.MENU3[filterTest.value.rule] && filterConstants.MENU3[filterTest.value.rule] === 'input'">
                  <input matInput formControlName="rule3" required>
                </mat-form-field>
                <mat-form-field *ngIf="filterConstants.MENU3[filterTest.value.rule] && filterConstants.MENU3[filterTest.value.rule] === 'datetime'">
                  <input matInput formControlName="rule3" [matDatepicker]="picker" placeholder="Choose date" required>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker panelClass="create-filter-date-picker"></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="full-width" *ngIf="filterConstants.MENU3[filterTest.value.rule] && filterConstants.MENU3[filterTest.value.rule] !== 'input' && filterConstants.MENU3[filterTest.value.rule] !== 'datetime'">
                  <mat-select formControlName="rule3">
                    <mat-option *ngFor="let data of filterConstants.MENU3[filterTest.value.rule]" [value]="data">{{
                        getTranslateKey(data) | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="20" fxFlex.xs="100">
                <mat-form-field *ngIf="filterConstants.MENU4[filterTest.value.rule] && filterConstants.MENU4[filterTest.value.rule] === 'input' && (filterTest.value.rule !== 'Header Named' || (filterTest.value.rule === 'Header Named' && !['exists', 'does not exist'].includes(filterTest.value.rule3)))">
                  <input matInput formControlName="rule4" required>
                </mat-form-field>
                <mat-form-field class="full-width" *ngIf="filterConstants.MENU4[filterTest.value.rule] && filterConstants.MENU4[filterTest.value.rule] !== 'datetime' && filterConstants.MENU4[filterTest.value.rule] !== 'input' && ((filterTest.value.rule === 'Message' &&filterTest.value.rule3 === 'marked as') || filterTest.value.rule !== 'Message')">
                  <mat-select formControlName="rule4">
                    <mat-option *ngFor="let data of filterConstants.MENU4[filterTest.value.rule]" [value]="data">{{
                        getTranslateKey(data) | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="10" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="center end" *ngIf="form.controls.filterTests.controls.length > 1">
                <div fxLayout="row">
                  <button mat-button aria-label="remove" mat-button-icon class="remove-filter" (click)="$event.preventDefault();removeFilterTests(i)"
                    [disabled]="form.controls.filterTests.controls.length < 2">
                    {{ 'REMOVE' | translate }}
                  </button>
                </div>
              </div>
            </div>
            <div fxFlex="10" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="center end" class="add-filter-part">
                <div fxLayout="row">
                  <button mat-button aria-label="add" mat-button-icon class="remove-filter" (click)="$event.preventDefault();addFilterTests()">
                    {{ 'ADD_CONDITION' | translate }}
                  </button>
                </div>
              </div>
          </div>
          <!-- filter-actions-section -->
          <mat-divider></mat-divider>
          <div fxLayout="column" class="filter-actions-section" formArrayName="filterActions">
            <span class="section-header">{{ 'ACTION' | translate }}</span>
            <div [formGroupName]="i" fxLayout="row" fxLayout.xs="column" class="rule-section"
              *ngFor="let filterAction of form['controls'].filterActions['controls']; let i=index">
              <div fxFlex="20" fxFlex.xs="100">
                <mat-form-field class="full-width">
                  <mat-select formControlName="action" (selectionChange)="setDefaultActionValue(filterAction)">
                    <mat-option *ngFor="let action of filterConstants.ACTIONS" [value]="action">{{ getTranslateKey(action) | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex="30" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="center start">
                <mat-form-field *ngIf="filterAction.value.action === 'Redirect to Address'" class="full-width">
                  <input type="email" matInput formControlName="value" email
                  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" required>
                </mat-form-field>
                <mat-form-field *ngIf="filterAction.value.action === 'Mark as'" class="full-width">
                  <mat-select formControlName="value">
                    <mat-option value="read">{{ 'READ' | translate}}</mat-option>
                    <mat-option value="flagged">{{ 'FLAGGED' | translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div *ngIf="filterAction.value.action === 'Move into folder'">
                  <input type="text" style="display: none" matInput formControlName="value" required>
                  <input type="text" style="display: none" matInput formControlName="name">
                  <button class="browse-button" (click)="openMailFolders(filterAction)" mat-button color="primary">{{ mailService?.getFolderNameKey(filterAction.value.name, MailConstants?.SYSTEM_FOLDERS)|| ('BROWSE_BUTTON' | translate) }}</button>
                </div>
              </div>
              <div fxFlex="30">

              </div>
              <div fxFlex="20" fxFlex.xs="100" fxLayout="row" fxLayoutAlign="end center" *ngIf="form['controls'].filterActions['controls'].length > 1">
                <button mat-button aria-label="remove" mat-button-icon class="remove-filter" (click)="removeFilterActions(i)"
                [disabled]="form['controls'].filterActions['controls'].length < 2">
                {{ 'REMOVE' | translate }}
                </button>
              </div>
            </div>

            <div fxFlex="10" fxFlex.xs="100" fxLayout="column" fxLayoutAlign="center end" class="add-filter-part">
                <div fxLayout="row">
                  <button mat-button aria-label="add" mat-button-icon class="remove-filter" (click)="addFilterActions()">
                    {{ 'ADD_ACTION' | translate }}
                  </button>
                </div>
              </div>
          </div>
          <mat-divider></mat-divider>
          <div *ngIf="!invalid && !isExists && !invalidAction" style="height: 45px;"></div>
          <div class="action-notifier disable-select" *ngIf="!invalid && !isExists && !invalidAction">
              <div class="notifiler-title disable-select" *ngIf="action !== 'edit'">
                  {{ 'ADD_FILTER_HEADING' | translate }}
              </div>
              <div class="notifiler-title disable-select" *ngIf="action === 'edit'">
                  {{ 'EDIT_FILTER_HEADING' | translate }}
              </div>
          </div>
          <vp-mail-operation-button (click)="save()" [iconType]="globalOperationIcon" *ngIf="!invalid && !isExists && !invalidAction"></vp-mail-operation-button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="mail-loading-spinner" *ngIf="!dataIsReady">
  <vp-custom-spinner></vp-custom-spinner>
</div>

<ng-template #deleteIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Icon/product/delete-new</title>
    <g id="Icon/product/delete-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M18,7 L6,7 L6,18.2857143 C6,19.7746499 7.19131256,21 8.66666667,21 L15.3333333,21 C16.8086874,21 18,19.7746499 18,18.2857143 L18,7 Z M8,9 L16,9 L16,18.2857143 C16,18.6824929 15.6913126,19 15.3333333,19 L8.66666667,19 L8.57834889,18.9935231 C8.25938319,18.9465317 8,18.649428 8,18.2857143 L8,9 Z M13,3 C13.7404864,3 14.3869825,3.40242038 14.7327215,4.00049436 L18,4 C18.5522847,4 19,4.44771525 19,5 C19,5.55228475 18.5522847,6 18,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L9.26760632,3.99992752 C9.61342606,3.40216612 10.2597476,3 11,3 L13,3 Z" id="Combined-Shape" fill="#8B96A0"></path>
    </g>
  </svg>
</ng-template>

<ng-template #matSelectTriggerIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icon/product/keyboard-arrow-down-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M7.25735931,9.79289322 C7.6478836,9.40236893 8.28104858,9.40236893 8.67157288,9.79289322 L12.5,13.6213203 L16.3284271,9.79289322 C16.7189514,9.40236893 17.3521164,9.40236893 17.7426407,9.79289322 C18.133165,10.1834175 18.133165,10.8165825 17.7426407,11.2071068 L12.5,16.4497475 L7.25735931,11.2071068 C6.86683502,10.8165825 6.86683502,10.1834175 7.25735931,9.79289322 Z" id="Path" fill="#8B96A0"></path>
    </g>
  </svg>
</ng-template>
<ng-template #datePickerIcon>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/date-today-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M15.5,3 C16.3284271,3 17,3.67157288 17,4.5 L17,5 L18,5 C19.5976809,5 20.9036609,6.24891996 20.9949073,7.82372721 L21,8 L21,18 C21,19.5976809 19.75108,20.9036609 18.1762728,20.9949073 L6,21 C4.40231912,21 3.09633912,19.75108 3.00509269,18.1762728 L3,18 L3,8 C3,6.40231912 4.24891996,5.09633912 5.82372721,5.00509269 L7,5.005 L7,4.5 C7,3.67157288 7.67157288,3 8.5,3 C9.32842712,3 10,3.67157288 10,4.5 L10,5.003 L14,5.002 L14,4.5 C14,3.67157288 14.6715729,3 15.5,3 Z M18,7 L6,7 C5.48716416,7 5.06449284,7.38604019 5.00672773,7.88337887 L5,8 L5,18 C5,18.5128358 5.38604019,18.9355072 5.88337887,18.9932723 L6,19 L18,19 C18.5128358,19 18.9355072,18.6139598 18.9932723,18.1166211 L19,18 L19,8 C19,7.44771525 18.5522847,7 18,7 Z M7.5,9 C8.32842712,9 9,9.67157288 9,10.5 C9,11.3284271 8.32842712,12 7.5,12 C6.67157288,12 6,11.3284271 6,10.5 C6,9.67157288 6.67157288,9 7.5,9 Z" id="Combined-Shape" fill="#8B96A0"></path>
      </g>
  </svg>
</ng-template>