
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="general_settings_dialog mail-dialog">
  <div class="mail__dialog-header mobile_header">
    <div>
      <button mat-button (click)="close()">
        <mat-icon class="disable-select">chevron_left</mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
      {{ 'GENERAL_SETTINGS_LBL' | translate }}
    </div>
    <div class="submit">
      <button mat-button (click)="updateSettings()">
        <mat-icon class="disable-select">check</mat-icon>
      </button>
    </div>
  </div>
  <div class="mail__dialog-header desktop_view">
    <div>
      <button mat-button>
        <mat-icon class="disable-select">settings</mat-icon>
      </button>
    </div>
    <div class="header_lbl disable-select">
      {{ 'GENERAL_SETTINGS_LBL' | translate }}
    </div>
    <div class="submit">
      <button mat-button (click)="close()">
        <mat-icon class="disable-select">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="mail__dialog-body mail__dialog-body--pad">
    <div class="content">
      <div class="server_url" *ngIf="isCordovaOrElectron">
        <mat-form-field>
          <input matInput placeholder="{{'SERVER_URL_LBL'|translate}}" [(ngModel)]="serverURL">
        </mat-form-field>
      </div>
      <div class="footer">
        <button mat-button class="desktop_view disable-select" (click)="updateSettings()">
          {{ 'COMMON.SAVE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
