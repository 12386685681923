
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="appt-reminder-dlg"></div>
<div class="dialog desktop-dialog">
    <div class="header">
        {{'CALENDARS.APPOINTMENT_REMINDER_HEADER_LBL' | translate}}
    </div>
    <div class="content">
        <div *ngFor="let app of commonService.alarmEventsActive" class="cal-item">
            <div class="sub-item" (click)="openEditEvent(app)">
                <div>{{app.title}}</div>
                <div>
                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-open-in-new"></mat-icon>
                </div>
            </div>
            <div class="snooz-all-item">
                <div class="start-end-date" *ngIf="currentLocale==='de'">
                    {{app.start | date: 'dd. MMM yyyy' : '': 'de' }} von {{app.start | date: 'shortTime': '': 'de'}} bis {{app.end | date: 'shortTime' : '': 'de'}}
            </div>
            <div class="start-end-date" *ngIf="currentLocale!=='de'">
                    {{app.start | date :'medium' }} to {{app.end | date :'shortTime' }}
            </div>
                <div class="time-picker">
                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-alarm"></mat-icon>
                    <mat-form-field>
                        <mat-select [(ngModel)]="app.alarmItem">
                            <mat-option *ngFor="let opt of listoptions" [value]="opt.text + '-' + opt.group">
                                {{opt.text}} {{ "CALENDARS." + opt.group | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="snooz-all-dismiss">
                    <span (click)="snoozAppointment(app)">
                        {{'CALENDARS.SNOOZ_LBL' | translate}}
                    </span>
                    <span mat-button (click)="dismissAppointmentReminder(app)">
                        {{'CALENDARS.DISMISS_LBL' | translate}}
                    </span>
                </div>
            </div>
            <div class="overdue">
                {{app.reminderTimeCount}}
            </div>
        </div>
    </div>
    <div class="divider" *ngIf="commonService.alarmEventsActive.length > 1">
        <mat-divider></mat-divider>
    </div>
    <div class="snooz-dismiss-all" *ngIf="commonService.alarmEventsActive.length > 1">
        <div>
            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-alarm"></mat-icon>
        </div>
        <div class="selection-all-time">
            <mat-form-field>
                <mat-select [(ngModel)]="snoozeAllItem">
                    <mat-option *ngFor="let opt of listoptions" [value]="opt.text + '-' + opt.group">
                        {{opt.text}} {{ "CALENDARS." + opt.group | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="buttons">
            <button mat-button (click)="snoozAllAppointment()">
                {{ 'CALENDARS.SNOOZE_ALL' | translate }}
            </button>
            <button mat-button (click)="dismissAllAppointmentReminder()">
                {{ 'CALENDARS.DISMISS_ALL' | translate }}
            </button>
        </div>
    </div>
</div>

<div class="mobile-diallog" [ngClass]="{'more-appointment': commonService.alarmEventsActive.length > 1}">
    <div class="header">
        {{'CALENDARS.APPOINTMENT_REMINDER_MOBILE_HEADER_LBL' | translate}}
    </div>
    <div class="content">
        <div *ngFor="let app of commonService.alarmEventsActive" class="cal-item">
            <div class="sub-item">
                <span  (click)="openEditEvent(app)">{{app.title?.length > 80 ? app.title.substr(0,80) + '...' : app.title }} <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-open-in-new"></mat-icon></span>
            </div>
            <div class="snooz-all-item">
                <div class="start-end-date" *ngIf="currentLocale==='de'">
                    <div class="row-1">
                            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon> {{app.start | date: 'longDate' : '': 'de' }}
                    </div>
                    <div class="row-2">
                            {{ 'FROM_MAIL_LABEL' | translate }} {{app.start | date: 'shortTime': '': 'de'}} bis {{app.end | date: 'shortTime' : '': 'de'}}
                    </div>
                </div>
                <div class="start-end-date" *ngIf="currentLocale!=='de'">
                    <div class="row-1">
                            <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon> {{app.start | date :'longDate' }}
                    </div>
                    <div class="row-2">
                            From {{app.start | date :'shortTime' }} To {{app.end | date :'shortTime' }}
                    </div>
                </div>
                <div class="time-picker">
                    <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-alarm"></mat-icon>
                    <mat-form-field>
                        <mat-select [(ngModel)]="app.alarmItem">
                            <mat-option *ngFor="let opt of listoptions" [value]="opt.text + '-' + opt.group">
                                {{opt.text}} {{ "CALENDARS." + opt.group | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="overdue">
                        {{app.reminderTimeCount}}
                </div>
                <div class="snooz-all-dismiss">
                    <span (click)="snoozAppointment(app)">
                        {{'CALENDARS.SNOOZ_LBL' | translate}}
                    </span>
                    <span mat-button (click)="dismissAppointmentReminder(app)">
                            {{'CALENDARS.DISMISS_LBL' | translate}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="divider" *ngIf="commonService.alarmEventsActive.length > 1">
            <mat-divider></mat-divider>
        </div>
        <div class="snooz-dismiss-mobile-all" *ngIf="commonService.alarmEventsActive.length > 1">
                    <div>

                    </div>
                    <div class="selection-all-time">
                        <mat-icon class="mdi-18px" fontSet="mdi" fontIcon="mdi-alarm"></mat-icon>
                        <mat-form-field>
                            <mat-select [(ngModel)]="snoozeAllItem">
                                <mat-option *ngFor="let opt of listoptions" [value]="opt.text + '-' + opt.group">
                                    {{opt.text}} {{ "CALENDARS." + opt.group | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="buttons">
                        <button mat-button (click)="snoozAllAppointment()">
                            {{ 'CALENDARS.SNOOZE_ALL' | translate }}
                        </button>
                        <button mat-button (click)="dismissAllAppointmentReminder()">
                                {{ 'CALENDARS.DISMISS_ALL' | translate }}
                        </button>
                    </div>
            </div>
        </div>
