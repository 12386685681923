
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import * as fromContacts from "../actions/all-gal-contacts.action";
import { Contact } from "../contacts/models/contact.model";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Action } from "../actions";

export interface AllGalContactState extends EntityState<Contact> {
  isLoaded: boolean;
  isLoading: boolean;

  currentOffset: number;
  isMoreContacts: boolean;

  selectedContactsIds: number[];
  queryParams: any;
}

export const allGalContactAdapter: EntityAdapter<Contact> = createEntityAdapter<Contact>({
  selectId: (contact: Contact) => contact.id,
});

export function sortByFirstCharacter(contact1: Contact, contact2: Contact): number {
  return contact1.fullName.charCodeAt(0) - contact2.fullName.charCodeAt(0);
}

export const initialState: AllGalContactState = allGalContactAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  isNextPageLoading: false,
  isNextPageLoaded: false,
  currentOffset: 0,
  isMoreContacts: false,

  selectedContactsIds: [],
  sortBy: null,
  isSearchMode: false,
  queryParams : null
});

export function contactReducer(
  state = initialState,
  action: Action
): AllGalContactState {
  switch (action.type) {
    case fromContacts.AllGalContactsActionTypes.LOAD_ALL_GAL_CONTACTS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case fromContacts.AllGalContactsActionTypes.LOAD_ALL_GAL_CONTACTS_SUCCESS: {
      return allGalContactAdapter.addAll(action.payload.contact, {
        ...state,
        isLoading: false,
        isLoaded: true,
        currentOffset: action.payload.currOffset,
        isMoreContacts: action.payload.isMoreContacts
      });
    }

    case fromContacts.AllGalContactsActionTypes.LOAD_ALL_GAL_CONTACTS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }
  }
  return state;
}
export const _getIsContactsLoading = (state: AllGalContactState) => state.isLoading;
export const _getIsContactsLoaded = (state: AllGalContactState) => state.isLoaded;
export const _getCurrentOffset = (state: AllGalContactState) => state.currentOffset;
