
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<mat-list [ngClass]="{'mat-list-disabled':iscontextMenuActionsDisabled}">
    <mat-list-item (click)="folderOpration(mailOperations.CreateFolder)" *ngIf = "contextMenuActions.isEnableNewFolder" class="disable-select">
        <mat-icon class="disable-select">add</mat-icon>
        {{'NEW_FOLDER_ACTION'| translate }}
    </mat-list-item>
    <mat-list-item (click)="folderOpration(mailOperations.MarkAllRead)" *ngIf = "contextMenuActions.isEnableMarkRead" class="disable-select">
        <mat-icon class="disable-select">markunread</mat-icon>
        {{'ALL_READ_FODER_ACTION'| translate }}
    </mat-list-item>
    <mat-list-item (click)="folderOpration(mailOperations.EmptyFolder)" *ngIf = "contextMenuActions.isEnableEmpty" class="disable-select">
      <mat-icon class="disable-select">delete_forever</mat-icon>
      {{'EMPTY_FOLDER_ACTION'| translate }}
    </mat-list-item>
    <mat-list-item (click)="folderOpration(mailOperations.ShareFolder)" *ngIf = "contextMenuActions.isEnableShare && isSharingFeatureEnabled" class="disable-select">
      <mat-icon class="disable-select">screen_share</mat-icon>
        {{'SHARE_FOLDER_ACTION'| translate }}
    </mat-list-item>
    <mat-list-item (click)="folderOpration(mailOperations.MoveFolder)" *ngIf = "contextMenuActions.isEnableMove" class="disable-select">
      <mat-icon class="disable-select">folder</mat-icon>
        {{'MOVE_FOLDER_ACTION'| translate }}
    </mat-list-item>
    <mat-list-item (click)="folderOpration(mailOperations.TrashFolder)" *ngIf = "contextMenuActions.isEnableDelete" class="disable-select">
      <mat-icon class="disable-select">delete</mat-icon>
        {{'DELETE_FOLDER_ACTION'| translate }}
    </mat-list-item>
    <mat-list-item (click)="folderOpration(mailOperations.RenameFolder)" *ngIf = "contextMenuActions.isEnableRename" class="disable-select">
      <mat-icon class="disable-select">edit</mat-icon>
        {{'RENAME_FOLDER_ACTION'| translate }}
    </mat-list-item>
    <mat-list-item (click)="folderOpration(mailOperations.ChangeColor)" *ngIf = "contextMenuActions.isEnableColorControl" class="disable-select">
      <mat-icon class="disable-select">color_lens</mat-icon>
        {{'CHANGE_FOLDER_COLOR_ACTION'| translate }}
    </mat-list-item>
    <mat-list-item (click)="folderOpration(mailOperations.ExternalFolderSync)" *ngIf = "contextMenuActions.isEnableExternalSync" class="disable-select">
        <mat-icon class="disable-select">sync</mat-icon>
          {{'GET_EXTERNAL_MAIL'| translate }}
    </mat-list-item>
    <mat-list-item (click)="folderOpration(mailOperations.EditFolder)" *ngIf = "contextMenuActions.isEditFolder" class="disable-select">
        <mat-icon class="disable-select">edit</mat-icon>
          {{'EDIT_PROPERTIES_LBL'| translate }}
    </mat-list-item>
    <mat-list-item (click)="folderOpration(mailOperations.CloseOperationDialog)" class="disable-select">
        <mat-icon class="disable-select">close</mat-icon>
        {{'CANCEL'| translate }}
    </mat-list-item>
</mat-list>

