
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef,
  ViewChild, HostListener, ElementRef, AfterViewInit } from "@angular/core";
import { MailBroadcaster } from "../../../common/providers/mail-broadcaster.service";
import { MailConstants } from "../../../common/utils/mail-constants";
import { Subject } from "rxjs/internal/Subject";
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import { Router } from "@angular/router";
import { MatTabGroup, MatTabChangeEvent } from "@angular/material/tabs";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";

@Component({
  selector: "vp-search-tabs",
  templateUrl: "./search-tabs.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchTabsComponent implements OnInit, OnDestroy {

  private isAlive$ = new Subject<boolean>();
  selectedIndex = 0;
  @ViewChild(MatTabGroup, {static: false}) tabGroup: MatTabGroup;
  @ViewChild("searchTab", { read: ElementRef, static: false }) mobileTab: ElementRef;

  constructor(
    private broadcaster: MailBroadcaster,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router
  ) { }

  ngOnInit() {
    this.broadcaster.on<any>(MailConstants.SAVED_SEARCH_ROUTE).pipe(takeUntil(this.isAlive$))
      .subscribe(res => {
        this.tabGroup.selectedIndex = 0;
        this.changeDetectorRef.markForCheck();
      });
      this.broadcaster.on<any>(BroadcastKeys.HIDE_SEARCH_TAB).pipe(takeUntil(this.isAlive$))
      .subscribe(() => {
        this.backToInbox();
      });
      this.broadcaster.on<any>("SEARCH_TAB_MESSAGE").pipe(takeUntil(this.isAlive$))
      .subscribe(res => {
        this.tabGroup.selectedIndex = 0;
        this.changeDetectorRef.markForCheck();
      });
  }

  backToInbox() {
    this.router.navigate(["/mail/inbox"]);
  }


  tabChange(event: MatTabChangeEvent) {
    if (event.index === 0) {
      this.broadcaster.broadcast(MailConstants.SEARCH_BROADCAST);
    } else if (event.index === 1) {
      this.broadcaster.broadcast(MailConstants.SEARCH_IMAGES_BROADCAST);
    } else if (event.index === 2) {
      this.broadcaster.broadcast(MailConstants.SEARCH_DOCUMENT_BROADCAST);
    } else if (event.index === 3) {
      this.broadcaster.broadcast(MailConstants.SEARCH_SAVED_BROADCAST);
    }
  }


  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }
}
