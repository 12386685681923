
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, ChangeDetectorRef, Inject, OnDestroy, NgZone } from "@angular/core";
import { takeUntil, take } from "rxjs/operators";
import { Subject } from "rxjs";
import { Filter } from "../../../preference/shared/models";
import { PreferenceService } from "../../../preference/shared/services/preference.service";
import { MailBroadcaster } from "../../../common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "../../../common/enums/broadcast.enum";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { BreakpointObserver, BreakpointState, Breakpoints } from "@angular/cdk/layout";
import { ToastService } from "src/app/common/providers/toast.service";
import { CreateFilterComponent } from "src/app/shared/components/create-filter/create-filter.component";
import { CommonService } from "src/app/services/ common.service.";
import { TranslateService } from "@ngx-translate/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { VncLibraryService } from "vnc-library";

export interface CreateFilterData {
  filters: { incomingFilters: Filter[], outgoingFilters: Filter[] };
  type: "incoming" | "outgoing";
  subject?: string;
  action?: string;
  filter?: Filter;
  mail?: string;
}

@Component({
  selector: "vp-activity-stream",
  templateUrl: "./activity-stream.component.html"
})
export class ActivityStreamComponent implements OnInit, OnDestroy {
  private isAlive$ = new Subject<boolean>();
  isMobileView = false;
  incomingFilters: Filter[] = [];
  outgoingFilters: Filter[] = [];
  allFilters: Filter[] = [];
  activityStreamFilter: Filter;
  enableActivityStream: boolean = false;
  asListTest: boolean = false;
  asBulkTest: boolean = false;
  asMeTest: boolean = false;
  asAddressBookTest: boolean = false;
  selected: any = "to";
  constructor(
    private vncLibraryService: VncLibraryService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private preferenceService: PreferenceService,
    private dialogRef: MatDialogRef<ActivityStreamComponent>,
    private ngZone: NgZone,
    private mailBroadcaster: MailBroadcaster,
    private breakpointObserver: BreakpointObserver,
    public toastService: ToastService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: CreateFilterData,
    private hotKeyService: NgxHotkeysService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
    this.hotKeyService.pause(this.hotKeyService.hotkeys);
    if (!!this.data.filters) {
    } else {
      this.preferenceService.getFilters("all").subscribe(filters => {
        console.log("[getFilters]", filters);
      });
    }

    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_FILTER_CREATE_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.cancel();
      });
    });
    this.isMobileView = this.breakpointObserver.isMatched("(max-width: 1023px)");
    this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.HandsetPortrait])
      .pipe(takeUntil(this.isAlive$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileView = true;
          console.log("Matches small viewport or handset in portrait mode");
        } else {
          console.log("is desktop view");
          this.isMobileView = false;
        }
        this.changeDetectorRef.markForCheck();
      });
  }

  advanceControlSelect() {
    const filters = { incomingFilters: this.incomingFilters, outgoingFilters: this.outgoingFilters };
    this.dialog.open(CreateFilterComponent, {
      autoFocus: true,
      panelClass: "create-filter-form",
      data: { filters: filters, type: "incoming", filter: this.activityStreamFilter, action: "edit" },
    }).afterClosed().subscribe(data => {
      if (data && data.saved) {
        this.preferenceService.getFilters("all").subscribe(filters => {
          this.incomingFilters = filters.incomingFilters;
          this.outgoingFilters = filters.outgoingFilters;
          this.changeDetectorRef.markForCheck();
          this.incomingFilters.map(filter => {
            if (filter.name === "Activity Stream") {
              this.activityStreamFilter = filter;
              this.enableActivityStream = true;
            }
          });
        });
      }
    });
  }

  createActivityStreamFolder() {
    let folderName = "Activity Stream";
    let lang = localStorage.getItem("mailLanguage");
    if (lang === "de") {
      folderName = "Aktivitätenstream";
    }
    this.createFolder(folderName);
  }

  createFolder(name) {
    const request = {
      CreateFolderRequest: {
        "@": {
          xmlns: "urn:zimbraMail",
          requestId: 0
        },
        folder: {
          "@": {
            name: name,
            l: 1,
            fie: 1
          }
        }
      }
    };
    this.commonService.createBatchRequest(request).pipe(take(1)).subscribe(res => {
    }, error => {
      this.toastService.showPlainMessage(error);
    });
  }

  activityStreamSave() {
    this.createActivityStreamFolder();
    let filterName = "Activity Stream";
    let lang = localStorage.getItem("mailLanguage");
    if (lang === "de") {
      filterName = "Aktivitätenstream";
    }
    let newFilter: Filter = {
      name: filterName,
      active: true,
      filterActions: [this.processFilterActions(filterName)],
      filterTests: [{ condition: "anyof" }],
    };
    if (this.asListTest) {
      newFilter.filterTests[0]["listTest"] = [{}];
    }

    if (this.asBulkTest) {
      newFilter.filterTests[0]["bulkTest"] = [{}];
    }
    if (this.asMeTest) {
      let header = "to";
      if (this.selected === "tocc") {
        header = "to,cc";
      }
      newFilter.filterTests[0]["meTest"] = [{
        header: header,
        negative: "1"
      }];
    }
    if (this.asAddressBookTest) {
      newFilter.filterTests[0]["addressBookTest"] = [{
        "header": "from",
        "negative": "1",
        "type": "contacts"
      }];
    }
    let isMatched = false;
    this.incomingFilters = this.incomingFilters.map(item => {
      if (item.name === newFilter.name) {
        item = newFilter;
        isMatched = true;
      }
      return item;
    });

    if (!isMatched) {
      this.incomingFilters.push(newFilter);
    }
    this.preferenceService.modifyFilterRules(this.incomingFilters).subscribe((res: any) => {
      if (!res.fault) {
        this.translate.get("FILTERS_SAVED").pipe(take(1)).subscribe((text: string) => {
          this.vncLibraryService.openSnackBar(text, "checkmark",
          "", "", 2000, "bottom", "left").subscribe(res => {
          });
        });
      }
    });
    this.dialogRef.close({ saved: false });
  }

  processFilterActions(folderName) {
    const result: any = {};
    result.actionFileInto = [];
    result.actionFileInto.push({
      "folderPath": folderName,
      "index": 0
    });
    result.actionStop = [];
    return result;
  }

  ngOnInit() {
    this.preferenceService.getFilters("all").subscribe(filters => {
      this.incomingFilters = filters.incomingFilters;
      this.outgoingFilters = filters.outgoingFilters;
      let lang = localStorage.getItem("mailLanguage");
      this.incomingFilters.map(filter => {
        if (filter.name === "Activity Stream" && lang === "en") {
          this.activityStreamFilter = filter;
          this.setActivityStreamOptions(filter);
          this.enableActivityStream = true;
        } else if (filter.name === "Aktivitätenstream" && lang === "de") {
          this.activityStreamFilter = filter;
          this.setActivityStreamOptions(filter);
          this.enableActivityStream = true;
        }
      });
    });
  }

  ngOnDestroy() {
    this.hotKeyService.unpause(this.hotKeyService.hotkeys);
    this.changeDetectorRef.detach();
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  setActivityStreamOptions(filter) {
    this.asListTest = false;
    this.asBulkTest = false;
    this.asMeTest = false;
    this.asAddressBookTest = false;
    const filterTest = filter.filterTests[0];
    if (filterTest.listTest) {
      this.asListTest = true;
    }
    if (filterTest.bulkTest) {
      this.asBulkTest = true;
    }
    if (filterTest.meTest) {
      this.asMeTest = true;
      if (filterTest.meTest[0].header === "to") {
        this.selected = "to";
      } else {
        this.selected = "tocc";
      }
    }
    if (filterTest.addressBookTest) {
      this.asAddressBookTest = true;
    }
  }

  cancel(): void {
    this.dialogRef.close({ saved: false });
  }

  getTranslateKey(text: string): string {
    return text.replace(/\s+/g, "_").replace(/\(|\)/g, "").toUpperCase();
  }
}
