
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "src/app/actions";
import { BriefCase } from "../../shared/models/briefcase.model";

export enum BriefcaseActionTypes {

  SELECT_BRIEFCASE = "[Briefcase] Select Briefcase",

  LOAD_BRIEFCASES = "[Briefcase] Load Briefcases",
  LOAD_BRIEFCASES_SUCCESS = "[Briefcase] Load Briefcases Success",
  LOAD_BRIEFCASES_FAIL = "[Briefcase] Load Briefcases Fail",

  CREATE_BRIEFCASE = "[Briefcase] Create Briefcase",
  CREATE_BRIEFCASE_SUCCESS = "[Briefcase] Create Briefcase Success",
  CREATE_BRIEFCASE_FAIL = "[Briefcase] Create Briefcase Fail",

  UPDATE_BRIEFCASE = "[Briefcase] Update Briefcase",
  UPDATE_BRIEFCASE_SUCCESS = "[Briefcase] Update Briefcase Success",
  UPDATE_BRIEFCASE_FAIL = "[Briefcase] Update Briefcase Fail",
  UPDATE_MANY_BRIEFCASE_FILE_SUCCESS = "[Briefcase] Update Many Briefcase file Success",

  RESET_BRIEFCASE_STATE = "[Briefcase] Reset Briefcase State",

  SELECT_FILES = "[Briefcase] Select files",
  UNSELECT_FILE = "[Briefcase] Unselect file",
  UNSELECT_FILES = "[Briefcase] Unselect files",
  CHECK_FILES = "[Briefcase] Check files",
  UNCHECK_FILES = "[Briefcase] Uncheck files",
  RESET_SELECTED_FILES = "[Briefcase] Reset Selected files",
  RESET_CHECKED_FILES = "[Briefcase] Reset Checked files",

  REMOVE_MANY_BRIEFCASE_FILES = "[Briefcase] Remove many briefcase files",
  LOAD_BRIEFCASE_FILES_SUCCESS = "[Briefcase] Load Briefcase files success",
  SET_HAS_MORE = "[Briefcase] Set has more list",

  MULTIPLE_CHECK_FILE_IDS = "[Briefcase] Multple check file ids",
  REMOVE_ALL_FILES_FROM_FOLDER = "[Briefcase] Remove all files from folder"

}

export class LoadBriefcases implements Action {
  readonly type = BriefcaseActionTypes.LOAD_BRIEFCASES;
  constructor() { }
}

export class LoadBriefcasesSuccess implements Action {
  readonly type = BriefcaseActionTypes.LOAD_BRIEFCASES_SUCCESS;
  constructor(public payload: { files: BriefCase[] }) {}
}

export class LoadBriefcasesFail implements Action {
  readonly type = BriefcaseActionTypes.LOAD_BRIEFCASES_FAIL;
}

export class SelectBriefcase implements Action {
  readonly type = BriefcaseActionTypes.SELECT_BRIEFCASE;
  constructor(public payload: { file: BriefCase }) { }
}

export class CreateBriefcase implements Action {
  readonly type = BriefcaseActionTypes.CREATE_BRIEFCASE;
}

export class CreateBriefcaseSuccess implements Action {
  readonly type = BriefcaseActionTypes.CREATE_BRIEFCASE_SUCCESS;
  constructor(public payload: { files: BriefCase[] }) { }
}

export class CreateBriefcaseFailure implements Action {
  readonly type = BriefcaseActionTypes.CREATE_BRIEFCASE_FAIL;
}

export class UpdateBriefcase implements Action {
  readonly type = BriefcaseActionTypes.UPDATE_BRIEFCASE;
  constructor(public payload: { file: BriefCase }) { }
}

export class UpdateBriefcaseSuccess implements Action {
  readonly type = BriefcaseActionTypes.UPDATE_BRIEFCASE_SUCCESS;
  constructor(public payload: { file: BriefCase }) { }
}

export class UpdateBriefcaseFailure implements Action {
  readonly type = BriefcaseActionTypes.UPDATE_BRIEFCASE_FAIL;
}


export class ResetBriefcaseState implements Action {
  readonly type = BriefcaseActionTypes.RESET_BRIEFCASE_STATE;
}

export class SelectFileAction implements Action {
  readonly type = BriefcaseActionTypes.SELECT_FILES;
  constructor(public payload: string) {}
}

export class UnSelectFilesAction implements Action {
  readonly type = BriefcaseActionTypes.UNSELECT_FILES;
  constructor(public payload: string[]) {}
}

export class UnSelectFileAction implements Action {
  readonly type = BriefcaseActionTypes.UNSELECT_FILE;
  constructor(public payload: string) {}
}

export class CheckFileAction implements Action {
  readonly type = BriefcaseActionTypes.CHECK_FILES;
  constructor(public payload: string) {}
}

export class UnCheckFileAction implements Action {
  readonly type = BriefcaseActionTypes.UNCHECK_FILES;
  constructor(public payload: string) {}
}

export class ResetSelectedFilesAction implements Action {
  readonly type = BriefcaseActionTypes.RESET_SELECTED_FILES;
  constructor() {}
}

export class ResetCheckedFilesAction implements Action {
  readonly type = BriefcaseActionTypes.RESET_CHECKED_FILES;
  constructor() {}
}

export class RemoveManyBriefcaseFiles implements Action {
  readonly type = BriefcaseActionTypes.REMOVE_MANY_BRIEFCASE_FILES;

  constructor(public payload: string[]) {
  }
}

export class UpdateManyBriefcaseFileSuccess implements Action {
  readonly type = BriefcaseActionTypes.UPDATE_MANY_BRIEFCASE_FILE_SUCCESS;
  constructor(public payload: BriefCase[]) {}
}

export class LoadBriefcaseFilesSuccessAction implements Action {
  readonly type = BriefcaseActionTypes.LOAD_BRIEFCASE_FILES_SUCCESS;
  constructor(public payload: BriefCase[]) {}
}

export class SetHasMoreAction implements Action {
  readonly type = BriefcaseActionTypes.SET_HAS_MORE;
  constructor(public payload: boolean) {}
}

export class RemoveAllBriefcaseFilesFromFolder implements Action {
  readonly type = BriefcaseActionTypes.REMOVE_ALL_FILES_FROM_FOLDER;
  constructor(public payload: string) { }
}

export class MultipleCheckFileAction implements Action {
  readonly type = BriefcaseActionTypes.MULTIPLE_CHECK_FILE_IDS;
  constructor(public payload: string[]) {}
}

export type BriefcaseActions = LoadBriefcases | UpdateBriefcase;
