
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy,
  ElementRef, ViewChild, NgZone, AfterViewInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MailFolderRepository } from "../repositories/mail-folder-repository";
import { ToastService } from "../../common/providers/toast.service";
import { takeUntil, take } from "rxjs/operators";
import { getMailFolders } from "../store/selectors";
import { Subject } from "rxjs/internal/Subject";
import { Store } from "@ngrx/store";
import { MailRootState } from "../store";
import { MailFolder } from "../models/mail-folder.model";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { ColorControlDialogComponent } from "../color-control-dialog/color-control-dialog.component";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { FormControl } from "@angular/forms";
import { I } from "@angular/cdk/keycodes";
import { TranslateService } from "@ngx-translate/core";
import { VNCInputComponent } from "vnc-library";

@Component({
  selector: "vp-create-folder",
  templateUrl: "./create-folder.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class CreateFolderComponent implements OnDestroy, AfterViewInit {

  folderTitle: string = "";
  folderTitleControl: FormControl = new FormControl("", []);
  maxFolderTitleLength: number = 128;
  isRename: boolean = false;
  isContactList: boolean = false;
  oldFlderName: string = "";
  private isAlive$ = new Subject<boolean>();
  mailRootFolders: MailFolder[] = null;
  folderColor: string;
  @ViewChild("folderNameInput", {static: false}) folderNameInput: VNCInputComponent;

  constructor(
    private dialogRef: MatDialogRef<CreateFolderComponent>,
    private mailFolderRepository: MailFolderRepository,
    public toastService: ToastService,
    private changeDetectionRef: ChangeDetectorRef,
    private mailBroadcaster: MailBroadcaster,
    private store: Store<MailRootState>,
    private ngZone: NgZone,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private hotKeyService: NgxHotkeysService,
    private translate: TranslateService
  ) {
    this.hotKeyService.pause(this.hotKeyService.hotkeys);
    this.isRename = this.data.isRename;
    this.isContactList = this.data.isContactList;

    if (this.isRename) {
      this.folderTitleControl.patchValue(this.data.targetFolder.name);
      this.oldFlderName = this.data.targetFolder.name;
      if (this.data.targetFolder.rgb) {
        this.folderColor = this.data.targetFolder.rgb.toLowerCase();
        this.changeDetectionRef.markForCheck();
      }
    }
    if (!this.data.targetFolder && !this.isContactList) {
      this.store.select(getMailFolders).pipe(takeUntil(this.isAlive$)).subscribe(res => {
        if (res) {
          this.mailRootFolders = res;
          this.getNewFolderName();
        }
      });
    } else {
      this.getNewFolderName();
    }
    this.changeDetectionRef.markForCheck();

    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_CREATE_FOLDER_MODAL).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.close();
      });
    });

    setTimeout(() => {
      if (this.folderNameInput) {
        this.folderNameInput.textInput.nativeElement.setSelectionRange(0, this.folderNameInput.textInput.nativeElement.value.length);
        this.folderNameInput.textInput.nativeElement.focus();
      }
    }, 500);

    console.log("[CreateFolderComponent]");
    setTimeout(() => {
      this.changeDetectionRef.detectChanges();
    }, 50);
  }

  getNewFolderName () {
    if (this.isRename) {
      return;
    }
    let newFolders = [];
    if (!this.data.targetFolder && !this.isContactList) {
      newFolders = this.mailRootFolders.filter(folder => folder.name.toLocaleLowerCase().includes("new folder") || folder.name.toLocaleLowerCase().includes("neuer ordner")).map(folder => {
        let splitName = folder.name.split(" ");
        return parseInt(splitName?.[2], 10) ? parseInt(splitName?.[2], 10) : 0;
      });
    } else if (this.data.targetFolder && this.data.targetFolder.children) {
      newFolders = this.data.targetFolder.children.filter(folder => folder.name.toLocaleLowerCase().includes("new folder") || folder.name.toLocaleLowerCase().includes("neuer ordner")).map(folder => {
        let splitName = folder.name.split(" ");
        return parseInt(splitName?.[2], 10) ? parseInt(splitName?.[2], 10) : 0;
      });
    }

    let largest = Math.max(...newFolders);
    largest = largest !== -Infinity ? largest : 1;
    const smallestMissing = this.getMissingNumberArray(newFolders, largest);
    const folderCount = smallestMissing === 0 ? largest + 1 : smallestMissing;
    this.translate.get("NEW_FOLDER").pipe(take(1)).subscribe(result => {
      this.folderTitleControl.patchValue(result + " " + folderCount);
    });
  }

  getMissingNumberArray (arr, largest) {
    let smallestMissing = 0;

    for (let i = 1; i <= largest; i++) {
      if (arr.indexOf(i) === -1) {
        smallestMissing = i;
        break;
      }
    }
    return smallestMissing;
  }

  changeText(event) {
    if (this.folderTitleControl.value.length > this.maxFolderTitleLength) {
      this.folderTitleControl.patchValue(this.folderTitleControl.value.substring(0, this.maxFolderTitleLength));
      this.changeDetectionRef.markForCheck();
    }
  }

  closeDialog(): void {
    this.close();
  }

  updateDefaultColor(event) {
    this.folderColor = event.value;
    this.changeDetectionRef.markForCheck();
  }

  folderAction(): void {
    if (this.folderTitleControl.value.length < 1) {
      return;
    }
    if (this.isFolderAlreadyExist()) {
      this.toastService.show("DUPLICATE_FOLDER_MSG");
      return;
    }

    if (this.isContactList) {
      this.dialogRef.close({contact_list: {name: this.folderTitleControl.value, color_hex: this.folderColor}});
    } else {
      if (this.isRename) {
        this.mailFolderRepository.updateMailFolder(this.data.targetFolder, this.folderTitleControl.value, this.folderColor);
      } else {
        this.mailFolderRepository.createMailFolder(this.data.targetFolder, this.folderTitleControl.value, this.folderColor);
      }
      this.close();
    }
    
  }

  openColorDialog() {
    const changeColorDialogRef = this.matDialog.open(ColorControlDialogComponent, {
      height: "auto",
      maxHeight: "70%",
      width: "99%",
      maxWidth: "244px",
      autoFocus: false,
      panelClass: "vp-color-control-dialog",
      data: { folderColor : this.folderColor }
    });
    changeColorDialogRef.afterClosed().subscribe(operation => {
      if (!!operation && operation !== null && operation.selectedColor) {
        this.folderColor = operation.selectedColor;
      }
    });
  }

  isFolderAlreadyExist() {
    if (this.isContactList) {
      return this.data.contactsList.find(f => f.name.toLowerCase() === this.folderTitleControl.value.toLowerCase());
    } else {
      if (!this.data.targetFolder && this.mailRootFolders.find(folder => folder.name.toLowerCase() === this.folderTitleControl.value.toLowerCase())) {
        return true;
      }
      if (this.data.targetFolder && this.data.targetFolder.children) {
        if (this.data.targetFolder.children.find(f => f.name.toLowerCase() === this.folderTitleControl.value.toLowerCase())) {
          return true;
        }
      }
    }
    
    return false;
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.hotKeyService.unpause(this.hotKeyService.hotkeys);
  }

  ngAfterViewInit() {
    this.changeDetectionRef.detectChanges();
  }

  close(): void {
    this.dialogRef.close();
  }
}
