<vnc-dialog [classes]="['create-contact-dialog-component']" closeText="{{'CLOSE' | translate }}"
headerText="{{  (contact?.item?.id ? 'EDIT_CONTACT' : 'NEW_CONTACT_LBL') | translate }}"
  classes="fixed-size with-border with-border-radius" [footerTemplate]="footerTemplate" [bodyTemplate]="bodyTemplate">
</vnc-dialog>
<ng-template #bodyTemplate>
  <vnc-create-contact [isSelectedDefaultList]="false" [contactList]="contactList" [allItems]="tags" [locale]="lang" [hideEvent]="true" (editAvatar)="uploadAvatar()"></vnc-create-contact>
</ng-template>
<ng-template #footerTemplate>
  <div class="footer-actions">
    <vnc-action-link (actionClicked)="addCustomField()" label="{{ 'ADD_A_FIELD' | translate }}"></vnc-action-link>
    <div class="right-buttons">
      <vnc-secondary-button (onClick)="cancel()" size="m" label="{{ 'CANCEL' | translate }}"></vnc-secondary-button>
      <vnc-primary-button size="m" (onClick)="apply()" [isDisabled]="disabled"><span>{{ 'SAVE' | translate }}</span>
      </vnc-primary-button>
    </div>
  </div>
</ng-template>


<ng-template #saveIconTemplate>
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Icon-24px" fill="#337cbd" transform="translate(5.000000, 5.000000)">
              <polygon id="Shape" points="14 8 8 8 8 14 6 14 6 8 0 8 0 6 6 6 6 0 8 0 8 6 14 6"></polygon>
          </g>
      </g>
  </svg>
</ng-template>
