
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../../actions";
import { ContactFolder } from "../../models/contact-folder.model";
import { Update } from "@ngrx/entity/src/models";
import { AppActionTypes } from "../../../actions/app";


export class ContactsActionTypes extends AppActionTypes {
  static SELECT_CONTACT_LIST = "[FOLDERS] Select contact list";
  static LOAD_FOLDERS = "[FOLDERS] Load Folders";
  static LOAD_FOLDERS_SUCCESS = "[FOLDERS] Load Folders Success";
  static LOAD_FOLDERS_FAIL = "[FOLDERS] Load Folders Fail";
  static SET_ROUTED_FOLDER_TITLE = "[FOLDERS] Set Routed folder name";

  static CREATE_CONTACT_FOLDER = "[FOLDERS] Create Contact Folder";
  static CREATE_CONTACT_FOLDER_FAIL = "[FOLDERS] Create Contact Folder Fail";
  static CREATE_CONTACT_FOLDER_SUCCESS = "[FOLDERS] Create Contact Folder Success";

  static DELETE_CONTACT_FOLDER = "[FOLDERS] Delete Contact Folder";
  static DELETE_CONTACT_FOLDER_FAIL = "[FOLDERS] Delete Contact Folder Fail";
  static DELETE_CONTACT_FOLDER_SUCCESS = "[FOLDERS] Delete Contact Folder Success";

  static UPDATE_CONTACT_FOLDER = "[FOLDERS] Update Contact Folder";
  static UPDATE_CONTACT_FOLDER_FAIL = "[FOLDERS] Update Contact Folder Fail";
  static UPDATE_CONTACT_FOLDER_SUCCESS = "[FOLDERS] Update Contact Folder Success";

  static SET_TRASH_FOLDER = "[FOLDERS] Set Trash Folder";
  static SET_CONTACTS_LIST  = "[FOLDERS] Set Contacts List";
}

export class SetSelectedContactFolder implements Action {
  readonly type = ContactsActionTypes.SELECT_CONTACT_LIST;
  constructor(public payload: ContactFolder) {
  }
}

export class SetTrashFolder implements Action {
  readonly type = ContactsActionTypes.SET_TRASH_FOLDER;
  constructor(public payload: ContactFolder) {
  }
}

export class SetContactsList implements Action {
  readonly type = ContactsActionTypes.SET_CONTACTS_LIST;
  constructor(public payload: any[]) {
  }
}

  export class LoadContactFoldersSuccess implements Action {
    readonly type = ContactsActionTypes.LOAD_FOLDERS_SUCCESS;
    constructor(public payload: { folders: ContactFolder[] }) {}
  }

  export class LoadContactFolders implements Action {
    readonly type = ContactsActionTypes.LOAD_FOLDERS;
  }

  export class LoadContactFoldersFail implements Action {
    readonly type = ContactsActionTypes.LOAD_FOLDERS_FAIL;
  }

  export class SetRoutedFolderTitle implements Action {
    readonly type = ContactsActionTypes.SET_ROUTED_FOLDER_TITLE;
    constructor(public payload: string) {}
  }

  export class CreateContactFolder implements Action {
    readonly type = ContactsActionTypes.CREATE_CONTACT_FOLDER;
  }

  export class CreateContactFolderFail implements Action {
    readonly type = ContactsActionTypes.CREATE_CONTACT_FOLDER_FAIL;
  }

  export class CreateContactFolderSuccess implements Action {
    readonly type = ContactsActionTypes.CREATE_CONTACT_FOLDER_SUCCESS;
    constructor(public payload: { folder: ContactFolder }) {}
  }

  export class DeleteContactFolder implements Action {
    readonly type = ContactsActionTypes.DELETE_CONTACT_FOLDER;
  }

  export class DeleteContactFolderFail implements Action {
    readonly type = ContactsActionTypes.DELETE_CONTACT_FOLDER_FAIL;
  }

  export class DeleteContactFolderSuccess implements Action {
    readonly type = ContactsActionTypes.DELETE_CONTACT_FOLDER_SUCCESS;
    constructor(public payload: { folder: ContactFolder }) {}
  }

  export class UpdateContactFolder implements Action {
    readonly type = ContactsActionTypes.UPDATE_CONTACT_FOLDER;
  }

  export class UpdateContactFolderFail implements Action {
    readonly type = ContactsActionTypes.UPDATE_CONTACT_FOLDER_FAIL;
  }

  export class UpdateContactFolderSuccess implements Action {
    readonly type = ContactsActionTypes.UPDATE_CONTACT_FOLDER_SUCCESS;
    constructor(public payload: Update<ContactFolder>) {}
  }
