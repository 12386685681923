
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { MailFolder } from "../models/mail-folder.model";
import { isArray } from "util";
import { SearchFolder } from "src/app/shared/models/search-folder";
import * as _ from "lodash";

export class SharedUtils {
    static slice(obj, keys) {
      return Object.keys(obj)
        .filter(key => {
          return keys.indexOf(key) >= 0;
        })
        .reduce((acc, key) => {
          acc[key] = obj[key];
          return acc;
        }, {});
    }

    static sliceExcept(obj, keys) {
      return Object.keys(obj)
        .filter(key => {
          return keys.indexOf(key) === -1;
        })
        .reduce((acc, key) => {
          acc[key] = obj[key];
          return acc;
        }, {});
    }

    static parseMailFoders(res: any, type = "mail"): MailFolder[] {
        const mailSubFolder: MailFolder[] = [];
        if (res.folder) {
            if (res.folder.folder) {
                res.folder.folder.map(contact => {
                    let c: any = {};
                    c = contact;
                    if (c) {
                        const newFolder: MailFolder = c as MailFolder;
                        this.checkHasChildFolder(c, newFolder);
                        mailSubFolder.push(newFolder);
                    }
                });
            } else {
                let sf: any = {};
                sf = res.folder;
                const newFolder: MailFolder = sf as MailFolder;
                this.checkHasChildFolder(sf, newFolder);
                mailSubFolder.push(newFolder);
            }
            if (res.folder.link) {
                if (isArray(res.folder.link)) {
                    res.folder.link.map(contact => {
                        let s: any = {};
                        s = contact;
                        if (s) {
                            const newFolder: MailFolder = s as MailFolder;
                            this.checkHasChildFolder(s, newFolder);
                            mailSubFolder.push(newFolder);
                        }
                    });
                } else {
                    let s: any = {};
                    s = res.folder.link;
                    const newFolder: MailFolder = s as MailFolder;
                    this.checkHasChildFolder(s, newFolder);
                    mailSubFolder.push(newFolder);
                }
            }
        }
        mailSubFolder.map(folderItem => {
            folderItem.rgb = this.setFolderColor(folderItem);
        });
        return this.adjustFolderSequence(mailSubFolder, type);
    }

    static parseSearchMailFoders(res: any): SearchFolder[] {
        const searchSubFolder: SearchFolder[] = [];
        if (res.search) {
            if (res.search.search) {
                res.search.search.map(contact => {
                    let c: any = {};
                    c = contact;
                    if (c) {
                        const newFolder: SearchFolder = c as SearchFolder;
                        this.checkHasChildSearchFolder(c, newFolder);
                        searchSubFolder.push(newFolder);
                    }
                });
            } else {
                let sf: any = {};
                sf = res.search;
                const newFolder: SearchFolder = sf as SearchFolder;
                this.checkHasChildSearchFolder(sf, newFolder);
                searchSubFolder.push(newFolder);
            }
        }
        searchSubFolder.map(folderItem => {
            folderItem.rgb = this.setFolderColor(folderItem);
        });
        return searchSubFolder;
    }

    static setFolderColor(mailFolder: any): string {
        if (mailFolder?.color) {
            if (mailFolder?.color === 0) {
                return "#c8c8c8";
            } if (mailFolder?.color === 1) {
                return "#0000FF";
            } if (mailFolder?.color === 2) {
                return "#00FFFF";
            } if (mailFolder?.color === 3) {
                return "#008000";
            } if (mailFolder?.color === 4) {
                return "#800080";
            } if (mailFolder?.color === 5) {
                return "#FF0000";
            } if (mailFolder?.color === 6) {
                return "#FFFF00";
            } if (mailFolder?.color === 7) {
                return "#FFC0CB";
            } if (mailFolder?.color === 8) {
                return "#808080";
            } if (mailFolder?.color === 9) {
                return "#FFA500";
            }
            return "#808080";
        } else if (mailFolder?.rgb) {
            return mailFolder?.rgb;
        } else {
            return "#808080";
        }
    }

    static parseFolders(folders, isSingleNode?: boolean): MailFolder[] {
        if (isSingleNode) {
            return SharedUtils.parseSingleFolder(folders);
        }
        if (folders.folder[0].folder && folders.folder[0].link) {
            folders = {
                folder: {
                    folder: folders.folder[0].folder,
                    link: folders.folder[0].link
                }
            };
        } else if (folders.folder[0].folder) {
            folders = {
                folder: {
                    folder: folders.folder[0].folder
                }
            };
        } else if (folders.folder[0].link) {
            folders = {
                folder: {
                    link: folders.folder[0].link
                }
            };
        }
        const mailFolderResponse = SharedUtils.parseMailFoders(folders);
        return mailFolderResponse;
    }

    static parseSingleFolder(res) {
        const mailSubFolder: MailFolder[] = [];
        if (res.folder) {
            let c: any = {};
            c = res.folder[0];
            if (c) {
                const newFolder: MailFolder = c as MailFolder;
                this.checkHasChildFolder(c, newFolder);
                mailSubFolder.push(newFolder);
            }
        } else if (res.link) {
            let c: any = {};
            c = res.link;
            if (c) {
                const newFolder: MailFolder = c as MailFolder;
                this.checkHasChildFolder(c, newFolder);
                if (newFolder.children) {
                    newFolder.children = _.uniqBy(newFolder.children, "id");
                }
                mailSubFolder.push(newFolder);
            }
        }
        mailSubFolder.map(folderItem => {
            folderItem.rgb = this.setFolderColor(folderItem);
        });
        return mailSubFolder;
    }

    static checkHasChildFolder(c: any, folder: MailFolder) {
        if (c.hasOwnProperty("folder") && isArray(c.folder)) {
            folder.children = [];
            for (let i = 0; i < c.folder.length; i++) {
                folder.children.push(this.generateTreeStructure(c.folder[i], folder));
            }
        } else if (c.hasOwnProperty("folder") && c.folder) {
            folder.children = [];
            folder.children.push(this.generateTreeStructure(c.folder));
        }
    }

    static generateTreeStructure(c: any, parentFolder?: MailFolder): MailFolder {
        const folder: MailFolder = c as MailFolder;
        folder.rgb = this.setFolderColor(folder);
        if (folder.id.indexOf(":") !== -1) {
            const absPath = folder.absFolderPath;
            if (!!absPath && !!folder) {
                folder.shareFolderSearchPath = parentFolder.name + absPath.substr(absPath.indexOf("/", 2), absPath.length);
            }
        }
        if (c.hasOwnProperty("folder") && isArray(c.folder)) {
            folder.children = [];
            for (let i = 0; i < c.folder.length; i++) {
                folder.children.push(this.generateTreeStructure(c.folder[i], parentFolder));
            }
        } else if (c.hasOwnProperty("folder") && c.folder) {
            folder.children = [];
            folder.children.push(this.generateTreeStructure(c.folder, parentFolder));
        }
        return folder;
    }

    static parseSearchFolders(searches, isSingleNode?: boolean): SearchFolder[] {
        if (isSingleNode) {
            return SharedUtils.parseSearchSingleFolder(searches);
        }
        if (searches.folder[0].search) {
            searches = {
                search: {
                    search: searches.folder[0].search
                }
            };
        }
        const searchFolderResponse = SharedUtils.parseSearchMailFoders(searches);
        return searchFolderResponse;
    }

    static parseSearchSingleFolder(res) {
        const searchSubFolder: SearchFolder[] = [];
        if (res.search) {
            let c: any = {};
            c = res.search[0];
            if (c) {
                const newFolder: SearchFolder = c as SearchFolder;
                this.checkHasChildSearchFolder(c, newFolder);
                searchSubFolder.push(newFolder);
            }
        }
        searchSubFolder.map(folderItem => {
            folderItem.rgb = this.setFolderColor(folderItem);
        });
        return searchSubFolder;
    }

    static checkHasChildSearchFolder(c: any, search: SearchFolder) {
        if (c.hasOwnProperty("search") && isArray(c.search)) {
            search.children = [];
            for (let i = 0; i < c.search.length; i++) {
                search.children.push(this.generateSearchTreeStructure(c.search[i], search));
            }
        } else if (c.hasOwnProperty("search") && c.search) {
            search.children = [];
            search.children.push(this.generateSearchTreeStructure(c.search));
        }
    }

    static generateSearchTreeStructure(c: any, parentFolder?: SearchFolder): SearchFolder {
        const search: SearchFolder = c as SearchFolder;
        search.rgb = this.setFolderColor(search);
        if (c.hasOwnProperty("search") && isArray(c.search)) {
            search.children = [];
            for (let i = 0; i < c.search.length; i++) {
                search.children.push(this.generateSearchTreeStructure(c.search[i], parentFolder));
            }
        } else if (c.hasOwnProperty("search") && c.search) {
            search.children = [];
            search.children.push(this.generateSearchTreeStructure(c.search, parentFolder));
        }
        return search;
    }

    static parseSearchFoders(res): SearchFolder[] {
        const searchFolders: SearchFolder[] = [];
        if (res && res.GetSearchFolderResponse && res.GetSearchFolderResponse[0].search) {
            if (isArray(res.GetSearchFolderResponse[0].search)) {
                res.GetSearchFolderResponse[0].search.forEach(element => {
                    if (element.types === "conversation") {
                        searchFolders.push(element as SearchFolder);
                    }
                });
            } else {
                searchFolders.push(res.GetSearchFolderResponse[0].search as SearchFolder);
            }
        }
        return searchFolders;
    }

    static parseCreateSearchResponse(res) {
        if (res && res.CreateSearchFolderResponse) {
            return res.CreateSearchFolderResponse[0].search[0] as SearchFolder;
        } else if (res.Fault && res.Fault[0].Reason) {
            throw Error(res.Fault[0].Reason.Text);
        }
        return null;
    }

    static adjustFolderSequence(folders: MailFolder[], type: string): MailFolder[] {
        const tempFolders: MailFolder[] = [];

        if (type === "mail") {
            tempFolders.push(
                folders.find(a => {
                    if (a.name === "Inbox") {
                        a.icon = "inbox";
                        return true;
                    } else {
                        return false;
                    }
                })
            );

            tempFolders.push(
                folders.find(a => {
                    if (a.name === "Drafts") {
                        a.icon = "drafts";
                        return true;
                    }
                })
            );

            tempFolders.push(
                folders.find(a => {
                    if (a.name === "Sent") {
                        a.icon = "send";
                        return true;
                    }
                })
            );

            const newFolder: MailFolder = {
                id: "starred-folder-id",
                name: "Starred",
                icon: "star",
                absFolderPath: "/starred",
                originalFolder: null
            };
            tempFolders.push(newFolder);
            console.log("[adjustFolderSequence]", tempFolders);
            // Update junk to spam folder name
            const jukFolder = folders.find(a => a.name === "Junk");
            if (jukFolder) {
                jukFolder.name = "Spam";
                jukFolder.icon = "report";
                tempFolders.push(jukFolder);
            }
        } else {
            tempFolders.push(
                folders.find(a => {
                    if (a.name === "Briefcase") {
                        a.icon = "folder";
                        return true;
                    }
                })
            );
        }



        tempFolders.push(
            folders.find(a => {
                if (a.name === "Trash") {
                    a.icon = "delete";
                    return true;
                }
            })
        );

        tempFolders.push(folders.find(a => a.name === "Chats"));

        folders.forEach(f => {
            if (
                f.name !== "Inbox" &&
                f.name !== "Drafts" &&
                f.name !== "Sent" &&
                f.name !== "Junk" &&
                f.name !== "Trash" &&
                f.name !== "Spam" &&
                f.name !== "Chats"
            ) {
                tempFolders.push(f);
            }
        });
        return tempFolders;
    }

    static parseAllMailFoders(res: any): MailFolder[] {
        const mailSubFolder: MailFolder[] = [];
        if (res.folder) {
            if (res.folder.folder) {
                res.folder.folder.map(contact => {
                    let c: any = {};
                    c = contact;
                    if (c) {
                        const newFolder: MailFolder = c as MailFolder;
                        this.checkHasChildFolder(c, newFolder);
                        mailSubFolder.push(newFolder);
                    }
                });
            } else {
                let sf: any = {};
                sf = res.folder;
                const newFolder: MailFolder = sf as MailFolder;
                this.checkHasChildFolder(sf, newFolder);
                mailSubFolder.push(newFolder);
            }
            if (res.folder.link) {
                if (isArray(res.folder.link)) {
                    res.folder.link.map(contact => {
                        let s: any = {};
                        s = contact;
                        if (s) {
                            const newFolder: MailFolder = s as MailFolder;
                            this.checkHasChildFolder(s, newFolder);
                            mailSubFolder.push(newFolder);
                        }
                    });
                } else {
                    let s: any = {};
                    s = res.folder.link;
                    const newFolder: MailFolder = s as MailFolder;
                    this.checkHasChildFolder(s, newFolder);
                    mailSubFolder.push(newFolder);
                }
            }
        }
        mailSubFolder.map(folderItem => {
            folderItem.rgb = this.setFolderColor(folderItem);
        });
        return mailSubFolder;
    }
}
