
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
    Component, OnChanges, Input, Output, EventEmitter, ChangeDetectorRef,
    OnInit, OnDestroy, LOCALE_ID, Inject, TemplateRef, ViewChild, ElementRef
} from "@angular/core";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { CalendarEvent, WeekDay, MonthView, MonthViewDay, ViewPeriod } from "calendar-utils";
import { Subject, Subscription, takeUntil } from "rxjs";
import {
    CalendarAppointmentTimesChangedEvent,
    CalendarAppointmentTimesChangedEventType
} from "../common/calendar-appointment-times-changed-event.interface";
import { CalendarUtils } from "../common/calendar-utils.provider";
import { validateEvents } from "../common/util";
import { DateAdapter } from "../date-adapters/date-adapter";
import { PlacementArray } from "positioning";
import { MaildragService } from "src/app/mail/shared/services/maildrag.service";
import { MouseflowFolderService } from "src/app/mail/shared/services/mouseflow-folder.service";

export interface CalendarMonthViewBeforeRenderEvent {
    header: WeekDay[];
    body: MonthViewDay[];
    period: ViewPeriod;
}

export interface CalendarMonthViewAppointmentTimesChangedEvent<
    EventMetaType = any, DayMetaType = any
    > extends CalendarAppointmentTimesChangedEvent<EventMetaType> {
    day: MonthViewDay<DayMetaType>;
}

@Component({
    selector: "vp-sidebar-calendar-mini-view",
    template: `
      <div class="cal-mini-bg-frame" [class.no-floating]="noFloating" [class.display]="true">
        <div class="cal-mini-view" [class.display]="true"
        (click)="calViewClick($event)"
        (swipeleft)="swipeLeftToChangeMonth()" (swiperight)="swipeRightToChangeMonth()"
        (panleft)="onPanningLeft($event)" (panright)="onPanningRight($event)">
            <!-- <div class="cal-mini-view-title">
                <span class="left-double-chevron" vpCalendarPreviousView [view]="defaultYearView"  matTooltipPosition="above" matTooltip="{{'PREVIOUS_YEAR' | translate}}"
                [(viewDate)]="viewDate" (viewDateChange)="viewDateChange($event)">&laquo;</span>
                <span #miniCalPreMonth class="left-single-chevron" matTooltipPosition="above" matTooltip="{{'PREVIOUS_MONTH' | translate}}"  vpCalendarPreviousView [view]="defaultMonthView"
                [(viewDate)]="viewDate" (viewDateChange)="viewDateChange($event)"> &lsaquo;</span>
                <span class="date-title" vpCalendarToday [(viewDate)]="viewDate"
                (viewDateChange)="viewDateChange($event)">{{viewDate | vpCalendarDate:("yearViewTitle"):locale}}</span>
                <span #miniCalNextMonth class="right-single-chevron" matTooltipPosition="above" matTooltip="{{'NEXT_MONTH' | translate}}"  vpCalendarNextView [view]="defaultMonthView"
                [(viewDate)]="viewDate" (viewDateChange)="viewDateChange($event)">&rsaquo;</span>
                <span class="right-double-chevron" vpCalendarNextView [view]="defaultYearView"  matTooltipPosition="above" matTooltip="{{'NEXT_YEAR' | translate}}"
                [(viewDate)]="viewDate" (viewDateChange)="viewDateChange($event)">&raquo;</span>
            </div> -->
            <vp-calendar-mini-view-header
            [days]="columnHeaders"
            [locale]="locale"
            (columnHeaderClicked)="columnHeaderClicked.emit($event)"
            [customTemplate]="headerTemplate"
            [showWeekNumberInMiniCalendar]="showWeekNumberInMiniCalendar"
            >
            </vp-calendar-mini-view-header>
            <div class="border-header-bottom"></div>
            <div class="cal-days">
                <div *ngFor="let rowIndex of view.rowOffsets; trackBy: trackByRowOffset">
                    <div class="cal-cell-row">
                    <div class="cal-cell cal-cell-top week-num-cell" *ngIf="showWeekNumberInMiniCalendar">
                        <div class="cal-day-number week-number">{{ view.days[rowIndex].date | vpWeekNum }}</div>
                    </div>
                    <vp-calendar-mini-cell
                        *ngFor="
                        let day of view.days
                            | slice: rowIndex:rowIndex + view.totalDaysVisibleInWeek;
                        trackBy: trackByDate
                        "
                        [refresh]="miniCellRefresh"
                        [ngClass]="day?.cssClass"
                        [day]="day"
                        [openDay]="openDay"
                        [locale]="locale"
                        [tooltipPlacement]="tooltipPlacement"
                        [tooltipAppendToBody]="tooltipAppendToBody"
                        [tooltipTemplate]="tooltipTemplate"
                        [tooltipDelay]="tooltipDelay"
                        [customTemplate]="cellTemplate"
                        [ngStyle]="{ backgroundColor: day.backgroundColor }"
                        (vpCalClick)="miniCellClick(day)"
                        [clickListenerDisabled]="false"
                        (highlightDay)="toggleDayHighlight($event.event, true)"
                        (unhighlightDay)="toggleDayHighlight($event.event, false)"
                        [vpMiniCalendarTooltip]=""
                        [tooltipEvent]="day"
                        (contextmenu)="miniCalendarContextMenu.emit({ $event: $event, date: day})"
                        (mouseover)="mouseEnter(day, $event)" (mouseout)="mouseOut(day)"
                        ></vp-calendar-mini-cell>
                    </div>
                </div>
            </div>
        </div>
      </div>
    `
})
export class SidebarCalendarMiniViewComponent
    implements OnChanges, OnInit, OnDestroy {
    private isAlive$ = new Subject<boolean>();

    /**
     * The current view date
     */
    @Input() viewDate: Date;

    @Input() noFloating: boolean;

    /**
     * An array of events to display on view.
     * The schema is available here: https://github.com/mattlewis92/calendar-utils/blob
     * /c51689985f59a271940e30bc4e2c4e1fee3fcb5c/src/calendarUtils.ts#L49-L63
     */
    @Input() events: CalendarEvent[] = [];

    /**
     * An array of day indexes (0 = sunday, 1 = monday etc) that will be hidden on the view
     */
    @Input() excludeDays: number[] = [];

    /**
     * Whether the events list for the day of the `viewDate` option is visible or not
     */
    @Input() activeDayIsOpen: boolean = false;

    /**
     * If set will be used to determine the day that should be open. If not set, the `viewDate` is used
     */
    @Input() activeDay: Date;

    /**
     * An observable that when emitted on will re-render the current view
     */
    @Input() refresh: Subject<any>;

    /**
     * The locale used to format dates
     */
    @Input() locale: string;

    /**
     * The placement of the event tooltip
     */
    @Input() tooltipPlacement: PlacementArray = "auto";

    /**
     * A custom template to use for the event tooltips
     */
    @Input() tooltipTemplate: TemplateRef<any>;

    /**
     * Whether to append tooltips to the body or next to the trigger element
     */
    @Input() tooltipAppendToBody: boolean = true;

    /**
     * The delay in milliseconds before the tooltip should be displayed. If not provided the tooltip
     * will be displayed immediately.
     */
    @Input() tooltipDelay: number | null = null;

    /**
     * The start number of the week
     */
    @Input() weekStartsOn: number;

    /**
     * A custom template to use to replace the header
     */
    @Input() headerTemplate: TemplateRef<any>;

    /**
     * A custom template to use to replace the day cell
     */
    @Input() cellTemplate: TemplateRef<any>;

    /**
     * A custom template to use for the slide down box of events for the active day
     */
    @Input() openDayEventsTemplate: TemplateRef<any>;

    /**
     * A custom template to use for event titles
     */
    @Input() eventTitleTemplate: TemplateRef<any>;

    /**
     * A custom template to use for event actions
     */
    @Input() eventActionsTemplate: TemplateRef<any>;

    /**
     * An array of day indexes (0 = sunday, 1 = monday etc) that indicate which days are weekends
     */
    @Input() weekendDays: number[];

    @Input() showWeekNumberInMiniCalendar: boolean = false;

    /**
     * An output that will be called before the view is rendered for the current month.
     * If you add the `cssClass` property to a day in the body it will add that class to the cell element in the template
     */
    @Output()
    beforeViewRender = new EventEmitter<CalendarMonthViewBeforeRenderEvent>();

    /**
     * Called when the day cell is clicked
     */
    @Output()
    dayClicked = new EventEmitter<{
        day: MonthViewDay;
    }>();

    @Output()
    dayCellClicked = new EventEmitter<{
        day: MonthViewDay;
    }>();

    /**
     * Called when the event title is clicked
     */
    @Output()
    eventClicked = new EventEmitter<{
        event: CalendarEvent;
    }>();

    /**
     * Called when a header week day is clicked. Returns ISO day number.
     */
    @Output() columnHeaderClicked = new EventEmitter<number>();

    /**
     * Called when an event is dragged and dropped
     */
    @Output()
    appointmentTimesChanged = new EventEmitter<CalendarMonthViewAppointmentTimesChangedEvent>();

    @Output() miniCalendarContextMenu: EventEmitter<any> = new EventEmitter();

    @ViewChild("miniCalPreMonth", {static: false}) miniCalPreMonth: ElementRef<HTMLElement>;
    @ViewChild("miniCalNextMonth", {static: false}) miniCalNextMonth: ElementRef<HTMLElement>;

    /**
     * @hidden
     */
    columnHeaders: WeekDay[];

    /**
     * @hidden
     */
    view: MonthView;

    /**
     * @hidden
     */
    openRowIndex: number;

    /**
     * @hidden
     */
    openDay: MonthViewDay;

    /**
     * @hidden
     */
    refreshSubscription: Subscription;

    miniCellRefresh: Subject<Date> = new Subject();

    defaultMonthView = "month";
    defaultYearView = "year";
    isOpened: boolean = true;
    positionX: number = 0;
    positionY: number = 0;
    isMobileScreen: boolean = false;

    /**
     * @hidden
     */
    trackByRowOffset = (index: number, offset: number) =>
        this.view.days
            .slice(offset, this.view.totalDaysVisibleInWeek)
            .map(day => day.date.toISOString())
            .join("-")
    /**
     * @hidden
     */
    trackByDate = (index: number, day: MonthViewDay) => day.date.toISOString();
    /**
     * @hidden
     */
    constructor(
        private breakpointObserver: BreakpointObserver,
        protected cdr: ChangeDetectorRef,
        protected utils: CalendarUtils,
        @Inject(LOCALE_ID) locale: string,
        protected dateAdapter: DateAdapter,
        public maildragService: MaildragService,
        private mouseflowFolderService: MouseflowFolderService,
    ) {
        // this.locale = locale;
        this.breakpointObserver
        .observe(["(max-width: 599px)"])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
           this.isMobileScreen = true;
          } else {
            this.isMobileScreen = false;
          }
        });
    }

    /**
     * @hidden
     */
    ngOnInit(): void {
        if (this.refresh) {
            this.refreshSubscription = this.refresh.subscribe(() => {
                this.refreshAll();
                this.cdr.markForCheck();
            });
        }
        this.breakpointObserver
        .observe(["(max-width: 599px)"])
        .pipe(takeUntil(this.isAlive$))
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            if (!!document.querySelector(".cal-mini-bg-frame")) {
              new Hammer(<HTMLElement> document.querySelector(".cal-mini-bg-frame")).on("swipeleft", () => {
                this.swipeLeftToChangeMonth();
              });
              new Hammer(<HTMLElement> document.querySelector(".cal-mini-bg-frame")).on("swiperight", () => {
                 this.swipeRightToChangeMonth();
              });
            }
          }
        });
    }

    /**
     * @hidden
     */
    ngOnChanges(changes: any): void {
        const refreshHeader =
            changes.viewDate || changes.excludeDays || changes.weekendDays;
        const refreshBody =
            changes.viewDate ||
            changes.events ||
            changes.excludeDays ||
            changes.weekendDays;

        if (refreshHeader) {
            this.refreshHeader();
        }

        if (changes.events) {
            validateEvents(this.events);
        }

        if (refreshBody) {
            this.refreshBody();
        }

        if (refreshHeader || refreshBody) {
            this.emitBeforeViewRender();
        }

        if (
            changes.activeDayIsOpen ||
            changes.viewDate ||
            changes.events ||
            changes.excludeDays ||
            changes.activeDay
        ) {
            this.checkActiveDayIsOpen();
        }
    }

    /**
     * @hidden
     */
    ngOnDestroy(): void {
        if (this.refreshSubscription) {
            this.refreshSubscription.unsubscribe();
        }
        this.isAlive$.next(false);
        this.isAlive$.complete();
        if (this.isMobileScreen && !!document.querySelector(".cal-mini-bg-frame")) {
          new Hammer(<HTMLElement> document.querySelector(".cal-mini-bg-frame")).off("swipeleft", () => {
            this.swipeLeftToChangeMonth();
          });
          new Hammer(<HTMLElement> document.querySelector(".cal-mini-bg-frame")).off("swiperight", () => {
             this.swipeRightToChangeMonth();
          });
        }
    }

    /**
     * @hidden
     */
    toggleDayHighlight(event: CalendarEvent, isHighlighted: boolean): void {
        this.view.days.forEach(day => {
            if (isHighlighted && day.events.indexOf(event) > -1) {
                day.backgroundColor =
                    (event.color && event.color.secondary) || "#D1E8FF";
            } else {
                delete day.backgroundColor;
            }
        });
    }

    /**
     * @hidden
     */
    eventDropped(
        droppedOn: MonthViewDay,
        event: CalendarEvent,
        draggedFrom?: MonthViewDay
    ): void {
        if (droppedOn !== draggedFrom) {
            const year: number = this.dateAdapter.getYear(droppedOn.date);
            const month: number = this.dateAdapter.getMonth(droppedOn.date);
            const date: number = this.dateAdapter.getDate(droppedOn.date);
            const newStart: Date = this.dateAdapter.setDate(
                this.dateAdapter.setMonth(
                    this.dateAdapter.setYear(event.start, year),
                    month
                ),
                date
            );
            let newEnd: Date;
            if (event.end) {
                const secondsDiff: number = this.dateAdapter.differenceInSeconds(
                    newStart,
                    event.start
                );
                newEnd = this.dateAdapter.addSeconds(event.end, secondsDiff);
            }
            this.appointmentTimesChanged.emit({
                event,
                newStart,
                newEnd,
                day: droppedOn,
                type: CalendarAppointmentTimesChangedEventType.Drop
            });
        }
    }

    viewDateChange(event: Date) {
        const day = <MonthViewDay>{};
        day.date = event;
        this.viewDate = new Date(day.date);
        const selectedDate = this.viewDate;
        selectedDate.setHours(0, 0, 0, 0);
        this.miniCellRefresh.next(selectedDate);
        this.dayClicked.emit({ day: day });
        this.refreshBody();
    }

    miniCellClick(day) {
        if (!!day && day.date) {
            this.viewDate = new Date(day.date);
            const selectedDate = this.viewDate;
            selectedDate.setHours(0, 0, 0, 0);
            this.miniCellRefresh.next(selectedDate);
            this.isOpened = true;
        }
        this.dayClicked.emit({ day: day });
        this.dayCellClicked.emit({ day: day });
    }

    calViewClick(event) {
        event.stopPropagation();
    }

    open(event) {
        if (this.isMobileScreen) {
            this.positionY = 0;
            this.positionX = 0;
        } else {
            this.positionY = event.currentTarget.offsetTop + event.currentTarget.offsetHeight + 10;
            this.positionX = event.currentTarget.offsetLeft + (event.currentTarget.offsetWidth / 2) - 140;
        }

        const selectedDate = this.viewDate;
        selectedDate.setHours(0, 0, 0, 0);
        this.miniCellRefresh.next(selectedDate);
        this.isOpened = true;
    }

    closeView(event?) {
        this.isOpened = false;
    }

    swipeLeftToChangeMonth() {
        const nextElement: HTMLElement = this.miniCalNextMonth.nativeElement;
        nextElement.click();
    }

    swipeRightToChangeMonth() {
        const preElement: HTMLElement = this.miniCalPreMonth.nativeElement;
        preElement.click();
    }

    onPanningLeft(event) {
        // TODO: Implement feature on swipe/panning on mini calendar in mobile view
    }

    onPanningRight(event) {
        // TODO: Implement feature on swipe/panning on mini calendar in mobile view
    }

    protected refreshHeader(): void {
        this.columnHeaders = this.utils.getWeekViewHeader({
            viewDate: this.viewDate,
            weekStartsOn: this.weekStartsOn,
            excluded: this.excludeDays,
            weekendDays: this.weekendDays
        });
    }

    protected refreshBody(): void {
        this.view = this.utils.getMonthView({
            events: this.events,
            viewDate: this.viewDate,
            weekStartsOn: this.weekStartsOn,
            excluded: this.excludeDays,
            weekendDays: this.weekendDays
        });
    }

    protected checkActiveDayIsOpen(): void {
        const selectedDate = this.viewDate;
        selectedDate.setHours(0, 0, 0, 0);

        const selectedDay = this.view.days.find(day =>
            this.dateAdapter.isEqual(day.date, selectedDate)
        );

        if (selectedDay) {
            const index: number = this.view.days.indexOf(selectedDay);
            this.view.days[index]["isSelected"] = true;
            this.miniCellRefresh.next(selectedDate);
        }

        if (this.activeDayIsOpen === true) {
            const activeDay = this.activeDay || this.viewDate;

            this.openDay = this.view.days.find(day =>
                this.dateAdapter.isSameDay(day.date, activeDay)
            );
            const index: number = this.view.days.indexOf(this.openDay);

            this.openRowIndex =
                Math.floor(index / this.view.totalDaysVisibleInWeek) *
                this.view.totalDaysVisibleInWeek;
        } else {
            this.openRowIndex = null;
            this.openDay = null;
        }
    }

    protected refreshAll(): void {
        this.refreshHeader();
        this.refreshBody();
        this.emitBeforeViewRender();
        this.checkActiveDayIsOpen();
    }

    protected emitBeforeViewRender(): void {
        if (this.columnHeaders && this.view) {
            this.beforeViewRender.emit({
                header: this.columnHeaders,
                body: this.view.days,
                period: this.view.period
            });
        }
    }

    mouseEnter(node, event): void {
        setTimeout(() => {
            if (this.maildragService.dragStatus) {
                this.mouseflowFolderService.mouseEnter(node, this.maildragService.dragSelected);
            }
        }, 200);
    }

    mouseOut(node): void {
        // console.log("[mouseOut]", node);
        setTimeout(() => {
            if (this.maildragService.dragStatus) {
                this.mouseflowFolderService.mouseOut(node, this.maildragService.dragSelected);
            }
        }, 200);
    }
}
