
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-sub-folder-dialog mail-dialog">
    <div  *ngIf="!showSearch" id="mobile_sidebar_folder_header" class="mail__dialog-header disable-select" layout="row center-center">
        <div>
            <button (click)="getParentFolder()" id="mobile_sidebar_folder_backbtn"
                class="brand-color mat-button disable-select">
                <mat-icon class="disable-select">chevron_left</mat-icon>
            </button>
        </div>
        <div class="header-title disable-select">
            <span class="disable-select"
                *ngIf="selectedMailFolder">{{ getFolderNameKey(selectedMailFolder.name, MailConstants?.SYSTEM_FOLDERS) }}</span>
            <span class="disable-select" *ngIf="!selectedMailFolder">{{ 'FOLDERS' | translate}}</span>
        </div>
        <div class="mobile-right-close disable-select">
            <button class="brand-color mat-button" (click)="close()" id="mobile_sidebar_folder_closebtn">
                <mat-icon class="disable-select">close</mat-icon>
            </button>

            <button class="brand-color mat-button" (click)="showSearchForm()" id="mobile_sidebar_folder_searchbtn">
                    <mat-icon class="disable-select">search</mat-icon>
                </button>
        </div>
    </div>
    <div *ngIf="showSearch" id="mobile_sidebar_folder_header" class="search-folder-form mail__dialog-header search-form" layout="row center-center">
        <div>
            <button (click)="showSearch = false" id="mobile_sidebar_folder_backbtn"
                class="brand-color mat-button disable-select">
                <mat-icon class="disable-select">chevron_left</mat-icon>
            </button>
        </div>
        <div class="header-title search-form">
            <input autofocus matInput #folderSearchInput [(ngModel)]="searchText" type="text" id="folder-search-input"
                placeholder="{{'SEARCH' | translate}}" />
        </div>
        <div class="disable-select">
            <button class="brand-color mat-button" (click)="searchText = ''" id="mobile_sidebar_folder_closebtn">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="mail__dialog-body mail__dialog-body--pad disable-select" (scroll)="onUserScroll($event)">
        <ng-container *ngIf="!searchText">
            <div id="mobile_sidebar_folder_folderlist" class="sidebar-list disable-select"
                *ngIf="selectedMailFolder && selectedMailFolder.children">
                <mat-list>
                    <mat-list-item *ngFor="let folder of selectedMailFolder.children" (click)="routeToFolder(folder)"
                        vpLongPress [timeout]="500" (onLongPress)="$event.stopPropagation();openActionList(folder)">
                        <div class="folder-icon disable-select">
                            <mat-icon [style.color]="folder.rgb" class="disable-select">folder</mat-icon>
                        </div>
                        <div class="folder-title disable-select"><span class="disable-select"
                                [ngClass]="{'broken-share-folder': folder.owner && !folder.oname}">{{ getFolderNameKey(folder.name, MailConstants?.SYSTEM_FOLDERS) }}</span>
                        </div>
                        <div class="folder-expander disable-select" *ngIf="folder.children"
                            (click)="$event.preventDefault(); $event.stopPropagation();getChildrens(folder)">
                            <mat-icon id="mobile_sidebar_folder_expand" class="disable-select">keyboard_arrow_right
                            </mat-icon>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
            <div class="no-folder-division" *ngIf="selectedMailFolder && !selectedMailFolder.children">
                {{ 'EMPTY_SUB_FOLDER_CREATE_MSG' | translate }}
            </div>
            <div id="mobile_sidebar_folder_rootfolderlist" class="sidebar-list disable-select user-folders"
                *ngIf="!selectedMailFolder && loadedFolders">
                <mat-list>
                    <mat-list-item *ngFor="let folder of loadedFolders" (click)="routeToFolder(folder)" vpLongPress
                        [timeout]="500" (onLongPress)="$event.stopPropagation();openActionList(folder)">
                        <div class="folder-icon disable-select">
                            <mat-icon [style.color]="folder.rgb" class="disable-select">folder</mat-icon>
                        </div>
                        <div class="folder-title disable-select"><span class="disable-select"
                                [ngClass]="{'broken-share-folder': folder.owner && !folder.oname}">{{ getFolderNameKey(folder.name, MailConstants?.SYSTEM_FOLDERS) }}</span>
                        </div>
                        <div class="folder-expander disable-select" *ngIf="folder.children"
                            (click)="$event.preventDefault(); $event.stopPropagation();getChildrens(folder)">
                            <mat-icon id="mobile_sidebar_folder_expand" class="disable-select">keyboard_arrow_right
                            </mat-icon>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
        </ng-container>
        <ng-container *ngIf="searchText">
            <div id="mobile_sidebar_folder_folderlist" class="sidebar-list disable-select"
                *ngIf="selectedMailFolder && selectedMailFolder.children">
                <mat-list>
                    <mat-list-item *ngFor="let folder of selectedMailFolder.children  | vpFolderNameSearch : searchText" (click)="routeToFolder(folder)"
                        vpLongPress [timeout]="500" (onLongPress)="$event.stopPropagation();openActionList(folder)">
                        <div class="folder-icon disable-select">
                            <mat-icon [style.color]="folder.rgb" class="disable-select">folder</mat-icon>
                        </div>
                        <div class="folder-title disable-select"><span class="disable-select"
                                [ngClass]="{'broken-share-folder': folder.owner && !folder.oname}">{{ getFolderNameKey(folder.name, MailConstants?.SYSTEM_FOLDERS) }}</span>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
            <div class="no-folder-division" *ngIf="selectedMailFolder && !selectedMailFolder.children">
                {{ 'EMPTY_SUB_FOLDER_CREATE_MSG' | translate }}
            </div>
            <div id="mobile_sidebar_folder_rootfolderlist" class="sidebar-list disable-select user-folders"
                *ngIf="!selectedMailFolder && rootUserFolders">
                <mat-list>
                    <mat-list-item *ngFor="let folder of rootUserFolders | vpFolderNameSearch : searchText"
                        (click)="routeToFolder(folder)" vpLongPress [timeout]="500"
                        (onLongPress)="$event.stopPropagation();openActionList(folder)">
                        <div class="folder-icon disable-select">
                            <mat-icon [style.color]="folder.rgb" class="disable-select">folder</mat-icon>
                        </div>
                        <div class="folder-title disable-select"><span class="disable-select"
                                [ngClass]="{'broken-share-folder': folder.owner && !folder.oname}">{{ getFolderNameKey(folder.name, MailConstants?.SYSTEM_FOLDERS) }}</span>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
        </ng-container>


    </div>
</div>
<vp-mail-operation-button (click)="createNewFolder(selectedMailFolder)" [iconType]="globalOperationIcon">
</vp-mail-operation-button>