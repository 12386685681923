import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IndexedDBService } from "./indexedDB.service";
import { SqliteDBService } from "./sqliteDB.service";
import { DumbDBService } from "./dumbDB.service";
import { DatabaseInterface } from "./database.interface";
import { Conversation } from "../../mail/shared/models/conversation.model";
import { Message } from "../../mail/shared/models/message.model";
import { Signature } from "../../preference/shared/models/signature.model";
import { CommonUtils } from "src/app/common/utils/common-util";
import { User } from "src/app/shared/models/user";
import { MailFolder } from "src/app/mail/models/mail-folder.model";
import { Store } from "@ngrx/store";
import { RootState, IsDatabaseReady } from "src/app/reducers";
import { filter, take } from "rxjs/operators";
import { DatabaseReady } from "src/app/actions/app";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class DatabaseService implements DatabaseInterface {
  isIndexedDBSupported: boolean = false;
  dbServiceImpl: DatabaseInterface = new DumbDBService();
  avatar = {};
  avatarLastUpdated = {};
  storedAvatars = [];
  dbReady = false;
  dataBaseReadyCallback?: any;
  constructor(private store: Store<RootState>) {
      console.log("[DatabaseService][constructor1]");
      if (CommonUtils.isSQLSupported()) {
        console.log("[DatabaseService][constructor] init SQL");
        this.dbServiceImpl = new SqliteDBService(store);
      } else {

        if (CommonUtils.isOfflineModeSupported()) {
          console.log("[DatabaseService][constructor1] using indexedDB from cached value");
          this.isIndexedDBSupported  = true;
          this.dbServiceImpl = new IndexedDBService();
            this.fetchAllAvatarFromDatabase().subscribe(v => {
              console.log("[fetchAllAvatarFromDatabase]", v);
              v.forEach(av => {
                if (this.storedAvatars.indexOf(av.id) === -1) {
                  this.storedAvatars.push(av.id);
                }
                this.avatar[av.id] = av.data;
                this.avatarLastUpdated[av.id] = av.updated;
              });
            });

          this.setDatabaseReady();
        } else {
          const isCordovaOrElectron = (environment.isCordova || environment.isElectron);
          if ((window.location !== window.parent.location) && !isCordovaOrElectron) {
            this.dbServiceImpl = new DumbDBService();
            localStorage.setItem("idbsupport", "no");
            this.dbServiceImpl.getCurrentDBUser().pipe(take(1)).subscribe(res => {
              console.log("[DatabaseService][constructor] getCurrentDBUser: ", res);
              this.setDatabaseReady();
            });

          } else {

            const idb = require("idb-keyval/dist/idb-keyval-cjs-compat.min.js");

            const testStore = new idb.Store("vncmail-database", "keyval");

            idb.set("testIdb", "test", testStore).then(() => {

              this.isIndexedDBSupported = true;

              console.log("[DatabaseService][constructor1] using indexedDB");
              localStorage.setItem("idbsupport", "yes");
              this.dbServiceImpl = new IndexedDBService();
              this.dbServiceImpl.getCurrentDBUser().pipe(take(1)).subscribe(res => {
                console.log("[DatabaseService][constructor] getCurrentDBUser: ", res);
                this.fetchAllAvatarFromDatabase().subscribe(v => {
                  console.log("[fetchAllAvatarFromDatabase]", v);
                  v.forEach(av => {
                    this.avatar[av.id] = av.data;
                  });
                });
                this.setDatabaseReady();
              });
              this.setDatabaseReady();
            }).catch((error) => {
              console.log("[DatabaseService][constructor] error attempting to store: ", error);
              console.log("[DatabaseService][constructor] using dumbDBService");
              this.dbServiceImpl = new DumbDBService();
              localStorage.setItem("idbsupport", "no");
              this.dbServiceImpl.getCurrentDBUser().pipe(take(1)).subscribe(res => {
                console.log("[DatabaseService][constructor] getCurrentDBUser: ", res);
                this.setDatabaseReady();
              });

            });
          }
        }
      }

  }

  isUsingIndexedDb() {
    return this.isIndexedDBSupported;
  }

  updateConversationMessages(convId: string, messages: any[]): Observable<any> {
    return this.dbServiceImpl.updateConversationMessages(convId, messages);
  }


  storeAvatar(avatarB64Url: string, email: string): Observable<any> {
    this.avatar[email] = avatarB64Url;
    return this.dbServiceImpl.storeAvatar(avatarB64Url, email);
  }

  deleteAvatar(email: string): Observable<any> {
    delete this.avatar[email];
    return this.dbServiceImpl.deleteAvatar(email);
  }

  getAvatarByEmail(email: string): Observable<any> {
    return this.dbServiceImpl.getAvatarByEmail(email);
  }

  fetchAllAvatarFromDatabase(): Observable<any> {
    return this.dbServiceImpl.fetchAllAvatarFromDatabase();
  }

  getDatabaseReady(): Observable<boolean> {
    return this.store.select(IsDatabaseReady).pipe(filter(ready => !!ready), take(1));
  }

  setDatabaseReady() {
    console.log("[DatabaseService] set DatabaseReady");
    this.dbReady = true;
    this.store.dispatch(new DatabaseReady());

    if (this.getDatabaseVersion() === 11) {
      this.performDatabaseMigration();
    }

  }

  deleteDB (): Observable<any> {
    return this.dbServiceImpl.deleteDB();
  }

  clearDB(): Observable<any> {
    return this.dbServiceImpl.clearDB();
  }

  addAppointments(appointments: any[], query?: any): Observable<any> {
    return this.dbServiceImpl.addAppointments(appointments, query);
  }

  addAttachment(attachment: any): Observable<any> {
    return this.dbServiceImpl.addAttachment(attachment);
  }

  addMessages(messages: Message[], query?: any): Observable<any> {
    return this.dbServiceImpl.addMessages(messages, query);
  }

  getMessagesByConversationId(conversationId: string): Observable<Message[]> {
    return this.dbServiceImpl.getMessagesByConversationId(conversationId);
  }

  getAppointmentsById(id: string): Observable<any[]> {
    return this.dbServiceImpl.getAppointmentsById(id);
  }

  fetchAttachmentById(id: string): Observable<any> {
    return this.dbServiceImpl.fetchAttachmentById(id);
  }

  fetchAttachmentsBefore(ts: any): Observable<any> {
    return this.dbServiceImpl.fetchAttachmentsBefore(ts);
  }

  getMessagesByFolder(folderName: string, query?: any, includingId?: string, quickFilterActive?: boolean): Observable<Message[]> {
    return this.dbServiceImpl.getMessagesByFolder(folderName, query, includingId);
  }

  getMessagesByTag(tagName: string[]): Observable<Message[]> {
    return this.dbServiceImpl.getMessagesByTag(tagName);
  }

  getLatestMessage(): Observable<Message[]> {
    return this.dbServiceImpl.getLatestMessage();
  }

  getFirstMessageByFolder(folderName, order): Observable<Message[]> {
    return this.dbServiceImpl.getFirstMessageByFolder(folderName, order);
  }

  getMessageCountInDatabaseByFolder(folderName): Observable<any> {
    return this.dbServiceImpl.getMessageCountInDatabaseByFolder(folderName);
  }

  getMessageById(id: string): Observable<Message> {
    return this.dbServiceImpl.getMessageById(id);
  }

  getMessagesStarred(): Observable<Message[]> {
    return this.dbServiceImpl.getMessagesStarred();
  }

  getMessagesSent(): Observable<Message[]> {
    return this.dbServiceImpl.getMessagesSent();
  }

  updateMessages(messages: Message[]): Observable<any> {
    return this.dbServiceImpl.updateMessages(messages);
  }

  updateMessagesAsStarred(messages: Message[], isStared: boolean): Observable<any> {
    return this.dbServiceImpl.updateMessagesAsStarred(messages, isStared);
  }

  moveMessagesBetweenFolders(messagesIds: string[], newFolder: string): Observable<any> {
    return this.dbServiceImpl.moveMessagesBetweenFolders(messagesIds, newFolder);
  }

  deleteMessage(messageId: string): Observable<any> {
    return this.dbServiceImpl.deleteMessage(messageId);
  }

  deleteMessages(ids: string[]): Observable<any> {
    return this.dbServiceImpl.deleteMessages(ids);
  }

  deleteAppointments(ids: string[], type?: string): Observable<any> {
    return this.dbServiceImpl.deleteAppointments(ids, type);
  }

  deleteAttachment(ids: string[], type?: string): Observable<any> {
    return this.dbServiceImpl.deleteAttachment(ids, type);
  }

  ////

  addConversations(conversations: Conversation[]): Observable<any> {
    return this.dbServiceImpl.addConversations(conversations);
  }

  getConversationsByFolder(folderName: string): Observable<Conversation[]> {
    return this.dbServiceImpl.getConversationsByFolder(folderName);
  }

  getAllAppointments(): Observable<any[]> {
    return this.dbServiceImpl.getAllAppointments();
  }

  getConversationsByTag(tagName: string[]): Observable<Conversation[]> {
    return this.dbServiceImpl.getConversationsByTag(tagName);
  }

  getConversationById(id: string): Observable<Conversation> {
    return this.dbServiceImpl.getConversationById(id);
  }

  getConversationsStarred(): Observable<Conversation[]> {
    return this.dbServiceImpl.getConversationsStarred();
  }

  getConversationsSent(): Observable<Conversation[]> {
    return this.dbServiceImpl.getConversationsSent();
  }

  updateConversations(convs: Conversation[]): Observable<any> {
    return this.dbServiceImpl.updateConversations(convs);
  }

  updateConversationsAsStarred(convs: Conversation[], isStared: boolean): Observable<any> {
    return this.dbServiceImpl.updateConversationsAsStarred(convs, isStared);
  }

  addMessageToConversation(convId: string, message: Message): Observable<any> {
    return this.dbServiceImpl.addMessageToConversation(convId, message);
  }

  moveConversationsBetweenFolders(conversationsIds: string[], newFolder: string) {
    return this.dbServiceImpl.moveConversationsBetweenFolders(conversationsIds, newFolder);
  }

  deleteConversation(conversationId: string, keepMessages?: boolean): Observable<any> {
    return this.dbServiceImpl.deleteConversation(conversationId, keepMessages);
  }

  deleteConversations(ids: string[], keepMessages?: boolean): Observable<any> {
    return this.dbServiceImpl.deleteConversations(ids, keepMessages);
  }

  ////

  addPendingOperation(objectId: string, op: string, request: any): Observable<any> {
    return this.dbServiceImpl.addPendingOperation(objectId, op, request);
  }

  getAllPendingOperations(): Observable<any> {
    return this.dbServiceImpl.getAllPendingOperations();
  }

  deletePendingOperation(key: string): Observable<any> {
    return this.dbServiceImpl.deletePendingOperation(key);
  }

  deletePendingOperations(keys: string[]): Observable<any> {
    return this.dbServiceImpl.deletePendingOperations(keys);
  }

  deleteAllPendingOperations(): Observable<any> {
    return this.dbServiceImpl.deleteAllPendingOperations();
  }

  getPendingOperationKey(objectId: string, op: string) {
    return this.dbServiceImpl.getPendingOperationKey(objectId, op);
  }

  ////

  addFolders(folders: MailFolder[]): Observable<any> {
    return this.dbServiceImpl.addFolders(folders);
  }
  addCalendarFolders(folders: any[]): Observable<any> {
    return this.dbServiceImpl.addCalendarFolders(folders);
  }

  getFolders(): Observable<MailFolder[]> {
    return this.dbServiceImpl.getFolders();
  }

  getCalendarFolders(): Observable<any[]> {
      return this.dbServiceImpl.getCalendarFolders();
  }
  ////

  getSignatures(): Observable<Signature[]> {
    return this.dbServiceImpl.getSignatures();
  }

  setSignatures(signatures: Signature[]): Observable<any> {
    return this.dbServiceImpl.setSignatures(signatures);
  }

  deleteSignatures(ids: string[]): Observable<any> {
    return this.dbServiceImpl.deleteSignatures(ids);
  }

  createSignature(signature: Signature): Observable<any> {
    return this.dbServiceImpl.createSignature(signature);
  }

  updateSignature(signature: Signature): Observable<any> {
    return this.dbServiceImpl.updateSignature(signature);
  }

  ////

  addUsers(users: User[]): Observable<any> {
    users = users.map(v => {
      v.updated = new Date().getTime();
      return v;
    });
    return this.dbServiceImpl.addUsers(users);
  }

  getUsers(mailPart: string, fullMail?: boolean): Observable<User[]> {
    return this.dbServiceImpl.getUsers(mailPart, fullMail);
  }

  ///

  addTags(tags: any[]): Observable<any> {
    return this.dbServiceImpl.addTags(tags);
  }

  getTags(): Observable<any[]> {
    return this.dbServiceImpl.getTags();
  }

  getCurrentDBUser(): Observable<any> {
    return this.dbServiceImpl.getCurrentDBUser();
  }

  setCurrentDBUser(email): Observable<any> {
    return this.dbServiceImpl.setCurrentDBUser(email);
  }

  getDatabaseVersion() {
    return this.dbServiceImpl.getDatabaseVersion();
  }

  performDatabaseMigration(): Observable<any> {
    return this.dbServiceImpl.performDatabaseMigration();
  }

  updateConvMessagesinDB(messages: Array<Message>, toFolderId: any): Observable<any> {
    return this.dbServiceImpl.updateConvMessagesinDB(messages, toFolderId);
  }

  createOrUpdateMessages(messages: Message[]): Observable<any> {
    return this.dbServiceImpl.createOrUpdateMessages(messages);
  }

  addNewMessagesOnly(messages: Message[]): Observable<any> {
    return this.dbServiceImpl.addNewMessagesOnly(messages);
  }

  getInvalidMsgIds() {
    return this.dbServiceImpl.getInvalidMsgIds();
  }
  clearInvalidMessageIds() {
    return this.dbServiceImpl.clearInvalidMessageIds();
  }

  deleteAllMessagesFromDB(): Observable<any> {
    return this.dbServiceImpl.deleteAllMessagesFromDB();
  }
  deleteAllConvsFromDB(): Observable<any> {
    return this.dbServiceImpl.deleteAllConvsFromDB();
  }
  createOrUpdateContacts(contacts: any): Observable<any> {
    return this.dbServiceImpl.createOrUpdateContacts(contacts);
  }
  searchContacts(searchText: string): Observable<any> {
    return this.dbServiceImpl.searchContacts(searchText);
  }

  searchContactsByMail(email: string): Observable<any> {
    return this.dbServiceImpl.searchContactsByMail(email);
  }

  fetchAllUsersFromDatabase(email: string): Observable<string> {
    return this.dbServiceImpl.fetchAllUsersFromDatabase(email);
  }

  searchContactsCalendar(searchText: string): Observable<any> {
    return this.dbServiceImpl.searchContactsCalendar(searchText);
  }

}
