<ng-template
#defaultTemplate
let-weekEvent="weekEvent"
let-tooltipPlacement="tooltipPlacement"
let-eventClicked="eventClicked"
let-onContextMenuClicked="onContextMenuClicked"
let-tooltipTemplate="tooltipTemplate"
let-tooltipAppendToBody="tooltipAppendToBody"
let-tooltipDisabled="tooltipDisabled"
let-tooltipDelay="tooltipDelay"
let-onDoubleClicked="onDoubleClicked"
let-isSelecting="isSelecting">
<div #eventDialog class="event-data-info-box" (mouseleave)="hideEventData(true)" (mouseover)="showEventData()" *ngIf="infoToolTipData">
   <vp-appointment-preview-common-dialog ></vp-appointment-preview-common-dialog>
</div>
<!-- (mouseover)="openEventData($event, 'participant')" (mouseout)="hideEventData()" -->
<div *ngIf="!weekEvent.event.isNewInMobile || (weekEvent.event.isNewInMobile && weekEvent.event.allDay)"
  class="cal-event"
  [class.cal-event-selecting]="isSelecting"
  [class.cal-allday-event]="weekEvent.event.allDay"
  [hidden]="!isCalendarCheck(weekEvent.event.folderId)"
  [ngStyle]="{ background: getBackgroundColor(weekEvent.event.bgcolor), borderColor: weekEvent.event.color?.primary }"
  [vpCalendarTooltip]="!tooltipDisabled ? (weekEvent.event.title | vpCalendarAppointmentTitle: 'weekTooltip':weekEvent.event) : ''"
  [tooltipPlacement]="tooltipPlacement"
  [tooltipEvent]="weekEvent.event"
  [tooltipTemplate]="tooltipTemplate"
  [tooltipAppendToBody]="tooltipAppendToBody"
  [tooltipDelay]="tooltipDelay"
  (vpCalClick)="handleAppointmentClicked($event)"
  (dblclick)="handleDoubleClicked(weekEvent.event)"
  (contextmenu)="onContextMenuClicked.emit({ $event: $event, calendarEvent: weekEvent })"
  vpLongPress [timeout]="700" (onLongPress)="longPressEvent(weekEvent.event);"

  >
  <span class="cal-visibility"
  [class.cal-visibility-F]="checkInst(weekEvent) ? weekEvent?.event?.inst[0]?.fba === 'F' : weekEvent?.event?.fba === 'F'"
  [class.cal-visibility-T]="checkInst(weekEvent) ? weekEvent?.event?.inst[0]?.fba === 'T' : weekEvent?.event?.fba === 'T'"
  [class.cal-visibility-B]="checkInst(weekEvent) ? weekEvent?.event?.inst[0]?.fba === 'B' : weekEvent?.event?.fba === 'B'"
  [class.cal-visibility-O]="checkInst(weekEvent) ? weekEvent?.event?.inst[0]?.fba === 'O' : weekEvent?.event?.fba === 'O'"
></span>
  <vp-calendar-appointment-actions
    [event]="weekEvent.event"
    [customTemplate]="eventActionsTemplate">
  </vp-calendar-appointment-actions>&ngsp;
  <vp-calendar-appointment-title
    [event]="weekEvent.event"
    [customTemplate]="eventTitleTemplate"
    view="week">
  </vp-calendar-appointment-title>
</div>
<div *ngIf="weekEvent.event.isNewInMobile && !weekEvent.event.allDay"
  class="cal-event cal-mobile-event-new"
  [class.cal-allday-event]="weekEvent.event.allDay"
  [ngStyle]="{
    borderColor: weekEvent.event.bgcolor
  }">
  <span class="cal-mobile-event-start-dot" [ngStyle]="{ backgroundColor: weekEvent.event.bgcolor }"></span>
  <span class="cal-mobile-event-end-dot" [ngStyle]="{ backgroundColor: weekEvent.event.bgcolor }"></span>
</div>
</ng-template>
<ng-template
[ngTemplateOutlet]="customTemplate || defaultTemplate"
[ngTemplateOutletContext]="{
  weekEvent: weekEvent,
  tooltipPlacement: tooltipPlacement,
  eventClicked: eventClicked,
  onContextMenuClicked: onContextMenuClicked,
  tooltipTemplate: tooltipTemplate,
  tooltipAppendToBody: tooltipAppendToBody,
  tooltipDisabled: tooltipDisabled,
  tooltipDelay: tooltipDelay,
  isSelecting: isSelecting
}"
>
</ng-template>