import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { CommonUtils } from "src/app/common/utils/common-util";

@Component({
    selector: "vp-seal-send-snackbar",
    templateUrl: "./seal-send-snackbar.component.html",
    styleUrls: ["./seal-send-snackbar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class SealAndSendSnackbarComponent implements OnInit, OnDestroy {
    vereignMobileIcon = CommonUtils.getFullUrl("/assets/images/Image-seal-24-24.png");
    isSealMessage: boolean = false;
    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        private changeDetector: ChangeDetectorRef
    ) {
        if (this.data && this.data.isSealMessage) {
            this.isSealMessage = true;
            this.changeDetector.markForCheck();
        }
    }
    ngOnInit() { }
    ngOnDestroy() { }
}
