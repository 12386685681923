
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="mail_edit_search_properties_dialog mail-dialog">
    <div class="search_properties__dialog-header mail__dialog-header mobile_header" layout="row center-center">
        <h4 class="heading_header">{{ 'EDIT_FOLDER' | translate}}</h4>
        <div class="desktop-close-button" [fxHide.xs]="true">
            <button class="brand-color mat-button" (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="mobile_heading">
        <h4 class="header"> {{ 'EDIT_FOLDER' | translate}}</h4>
    </div>
    <div class="dialog_body mail__dialog-body mail__dialog-body--pad">
        <div class="content">
            <div>
                <mat-form-field>
                    <input [(ngModel)]="searchTitle" #folderNameInput matInput placeholder="{{ 'NAME'| translate }}" (keyup)="changeText($event)"
                        autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" [maxLength]="maxSearchTitleLength">
                    <mat-hint>{{folderNameInput.value.length}}/{{maxSearchTitleLength}}</mat-hint>
                </mat-form-field>
            </div>
            <ng-container>
                <div class="info">
                    <div class="lbl"> {{ 'PREFERENCES.TYPE' | translate }} :</div>
                    <div class="item"> {{ 'SAVED_SEARCH' | translate }} </div>
                </div>
                <div>
                    <mat-form-field>
                        <input [(ngModel)]="searchQuery" #folderQueryInput matInput placeholder="{{ 'PREFERENCES.SEARCH'| translate }}" autocomplete="off"
                            autocorrect="off" autocapitalize="off" spellcheck="false">
                    </mat-form-field>
                </div>
            </ng-container>

            <div class="color-action">
                <mat-radio-group class="create-folder-color-selection" (change)="updateDefaultColor($event)" [(ngModel)]="folderColor">
                    <mat-radio-button class="grey-radio" value="#607d8b"></mat-radio-button>
                    <mat-radio-button class="sky-radio" value="#00b8d4"></mat-radio-button>
                    <mat-radio-button class="blue-radio" value="#0071bb"></mat-radio-button>
                    <mat-radio-button class="navy-radio" value="#6200ea"></mat-radio-button>
                    <mat-radio-button class="red-radio" value="#d50000"></mat-radio-button>
                    <mat-radio-button class="orange-radio" value="#fd8100"></mat-radio-button>
                    <mat-radio-button class="green-radio" value="#39b54a"></mat-radio-button>
                </mat-radio-group>
                <button (click)="openColorDialog()" class="brand-color mat-button">
                    <mat-icon class="disable-select">more_horiz</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="footer_save_cancel">
        <span>{{ 'EDIT_FOLDER_LBL' | translate }}</span>
        <button mat-button (click)="folderAction()" [class.action-disabled]="searchTitle.length < 1 && searchQuery.length < 1">
            <mat-icon class="disable-select">check</mat-icon>
        </button>
    </div>
    <div class="footer_save_cancel_mobile">
        <button mat-button class="cancel_btn" (click)="closeDialog()">{{ "CANCEL_MAIL_LABEL" | translate }}</button>
        <button mat-button class="save_btn" [class.action-disabled]="searchTitle.length < 1 && searchQuery.length < 1" (click)="folderAction()">{{ "COMMON.SAVE" | translate }}</button>
    </div>
</div>