import { Subject, Observable } from "rxjs";
import { take } from "rxjs/operators";

import { DatabaseInterface } from "./database.interface";
import { Conversation } from "../../mail/shared/models/conversation.model";
import { Message } from "../../mail/shared/models/message.model";
import { User } from "../../shared/models/user";

import { SQLDBInterface } from "./sql/sql.db.interface";
import { ElectronDB } from "./sql/electron-db";

import { CordovaDB } from "./sql/cordova-db";
import { environment } from "src/environments/environment";
import { Signature } from "../../preference/shared/models/signature.model";
import { MailFolder } from "src/app/mail/models/mail-folder.model";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { Store } from "@ngrx/store";
import { RootState, IsDatabaseReady } from "src/app/reducers";
import { DatabaseReady } from "src/app/actions/app";

const DB_NAME: string = "vnc_mail.db";

const MESSAGES_TABLE_NAME = "MessagesStore";
const MESSAGES_TAGS_TABLE_NAME = "MessagesTagsStore";
const CONVERSATIONS_TAGS_TABLE_NAME = "ConversationsTagsStore";
const CONVERSATIONS_TABLE_NAME = "ConversationsStore";
const PENDING_OPERATIONS_TABLE_NAME = "PendingOperationsStore";
const FOLDERS_TABLE_NAME = "FoldersStore";
const TAGS_TABLE_NAME = "TagsStore";
const SIGNATURES_TABLE_NAME = "SignaturesStore";
const USERS_TABLE_NAME = "UsersStore";
const CURRENT_USER_TABLE_NAME = "CurrentUserStore";
const AVATAR_TABLE_NAME = "AvatarStore";
const APPOINTMENT_TABLE_NAME = "AppointmentStore";
const ATTACHMENT_TABLE_NAME = "AttachmentStore";
// Messages
const CREATE_MESSAGES_TABLE_SQL = `CREATE TABLE IF NOT EXISTS ${MESSAGES_TABLE_NAME} (id TEXT not null PRIMARY KEY, message TEXT not null, date TEXT, last_updated TEXT, cid TEXT, query TEXT, mp TEXT, folder TEXT, e TEXT, flag TEXT, tags TEXT, md5 TEXT, isStarred INTEGER)`;
const CREATE_MESSAGES_TAGS_TABLE_SQL = `CREATE TABLE IF NOT EXISTS ${MESSAGES_TAGS_TABLE_NAME} (_id INTEGER PRIMARY KEY AUTOINCREMENT, id TEXT not null, tag TEXT not null, CONSTRAINT fk_msg_tag_id FOREIGN KEY (id) REFERENCES ${MESSAGES_TABLE_NAME} (id) ON DELETE CASCADE)`;

const DELETE_MESSAGE_SQL = `DELETE FROM ${MESSAGES_TABLE_NAME} WHERE id = ?`;
const DELETE_MESSAGES_SQL = `DELETE FROM ${MESSAGES_TABLE_NAME} WHERE id IN `;
const DELETE_APPOINTMENTS_SQL = `DELETE FROM ${APPOINTMENT_TABLE_NAME} WHERE id IN `;
const DELETE_ATTACHMENTS_SQL = `DELETE FROM ${ATTACHMENT_TABLE_NAME} WHERE id IN `;
const DELETE_MESSAGES_BY_CONVERSATION_SQL = `DELETE FROM ${MESSAGES_TABLE_NAME} WHERE cid = ?`;
const DELETE_MESSAGES_BY_CONVERSATIONS_SQL = `DELETE FROM ${MESSAGES_TABLE_NAME} WHERE cid IN `;
const FETCH_MESSAGES_FROM_FOLDER_SQL = `SELECT message FROM ${MESSAGES_TABLE_NAME} WHERE folder = ? ORDER BY date DESC`;
const FETCH_MESSAGES_BY_IDS_SQL = `SELECT * FROM ${MESSAGES_TABLE_NAME} WHERE id IN `;
const FETCH_MESSAGE_BY_ID_SQL = `SELECT message, mp FROM ${MESSAGES_TABLE_NAME} WHERE id = ?`;
const FETCH_MESSAGES_BY_CONVERSATION_SQL = `SELECT message FROM ${MESSAGES_TABLE_NAME} WHERE cid = ? ORDER BY date DESC`;
const FETCH_MESSAGES_STARRED_SQL = `SELECT message FROM ${MESSAGES_TABLE_NAME} WHERE isStarred = 1 ORDER BY date DESC`;
const FETCH_MESSAGES_BY_TAGS_SQL = `SELECT DISTINCT message FROM ${MESSAGES_TABLE_NAME} JOIN ${MESSAGES_TAGS_TABLE_NAME} USING (id) WHERE tag IN `;
const UPDATE_MESSAGES_FOLDER_SQL = `UPDATE ${MESSAGES_TABLE_NAME} SET folder = ?, message = ?, query = ? WHERE id = ?`;
const UPDATE_MESSAGE_SQL = `UPDATE ${MESSAGES_TABLE_NAME} SET message = ?, folder = ?, tags = ?,  date = ?, isStarred = ?, e = ?, flag = ?, query = ?, mp = ?, last_updated=?`;
const UPDATE_MESSAGE_WITH_CID_SQL = `${UPDATE_MESSAGE_SQL} , cid = ? WHERE id = ?`;
const UPDATE_MESSAGES_STARS_SQL = `UPDATE ${MESSAGES_TABLE_NAME} SET message = ?, md5 = ?, flag = ?, isStarred = ? WHERE id = ?`;
const CREATE_MESSAGES_MULTIPLE_SQL = `INSERT INTO MessagesStore (id, message, date, last_updated, cid, "query", mp, folder, e, flag, tags, md5, isStarred) VALUES`;
const CREATE_MESSAGE_SQL = CREATE_MESSAGES_MULTIPLE_SQL + "(?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?, ?)";
const UPSERT_MESSAGE_SQL = CREATE_MESSAGE_SQL + ` ON CONFLICT(id) DO UPDATE SET`;
const UPSERT_MESSAGE_ALL_SQL = UPSERT_MESSAGE_SQL + ` message=?,last_updated=?,query=?,mp=?,folder=?,e=?,flag=?,tags=?,isStarred=?`;
const UPSERT_MESSAGE_WITHOUT_MP_SQL = UPSERT_MESSAGE_SQL + ` message=?,last_updated=?,query=?,folder=?,e=?,flag=?,tags=?,isStarred=?`;

const ADD_MESSAGES_TAGS_SQL = `INSERT INTO ${MESSAGES_TAGS_TABLE_NAME} (id, tag) VALUES `;
const ADD_MESSAGE_TAG_SQL = ADD_MESSAGES_TAGS_SQL + " (?, ?)";
const DELETE_TAGS_BY_MESSAGE_SQL = `DELETE FROM ${MESSAGES_TAGS_TABLE_NAME} WHERE id = ?`;
const DELETE_MESSAGES_TAGS_MULTIPLE_SQL = `DELETE FROM ${MESSAGES_TAGS_TABLE_NAME} WHERE id IN `;

const CLEAR_MESSAGES_TABLE_SQL = `DELETE FROM ${MESSAGES_TABLE_NAME}`;
const CLEAR_MESSAGES_TAGS_TABLE_SQL = `DELETE FROM ${MESSAGES_TAGS_TABLE_NAME}`;

// Conversations
const CREATE_CONVERSATIONS_TABLE_SQL = `CREATE TABLE IF NOT EXISTS ${CONVERSATIONS_TABLE_NAME} (id TEXT not null PRIMARY KEY, conversation TEXT not null, date TEXT last_updated TEXT, query TEXT, mp TEXT, flag TEXT, folder TEXT, tags TEXT, md5 TEXT, isStarred INTEGER)`;
const CREATE_CONVERSATIONS_TAGS_TABLE_SQL = `CREATE TABLE IF NOT EXISTS ${CONVERSATIONS_TAGS_TABLE_NAME} (_id INTEGER PRIMARY KEY AUTOINCREMENT, id TEXT not null, tag TEXT not null, CONSTRAINT fk_conv_tag_id FOREIGN KEY (id) REFERENCES ${CONVERSATIONS_TABLE_NAME} (id) ON DELETE CASCADE)`;

const FETCH_CONVERSATION_BY_ID_SQL = `SELECT conversation FROM ${CONVERSATIONS_TABLE_NAME} WHERE id = ?`;
const DELETE_CONVERSATION_SQL = `DELETE FROM ${CONVERSATIONS_TABLE_NAME} WHERE id = ?`;
const DELETE_CONVERSATIONS_SQL = `DELETE FROM ${CONVERSATIONS_TABLE_NAME} WHERE id IN `;
const FETCH_CONVERSATIONS_BY_IDS_SQL = `SELECT * FROM ${CONVERSATIONS_TABLE_NAME} WHERE id IN `;
const FETCH_CONVERSATIONS_FROM_FOLDER_SQL = `SELECT conversation FROM ${CONVERSATIONS_TABLE_NAME} WHERE folder = ? OR query = ? ORDER BY date DESC`;
const FETCH_CONVERSATIONS_BY_TAGS_SQL = `SELECT DISTINCT conversation FROM ${CONVERSATIONS_TABLE_NAME} JOIN ${CONVERSATIONS_TAGS_TABLE_NAME} USING (id) WHERE tag IN `;
const FETCH_CONVERSATIONS_STARRED_SQL = `SELECT conversation FROM ${CONVERSATIONS_TABLE_NAME} WHERE isStarred = 1 ORDER BY date DESC`;
const UPDATE_CONVERSATIONS_FOLDER_SQL = `UPDATE ${CONVERSATIONS_TABLE_NAME} SET folder = ?, query = ?, conversation = ?, md5 = ? WHERE id = ?`;
const UPDATE_CONVERSATION_SQL = `UPDATE ${CONVERSATIONS_TABLE_NAME} SET conversation = ?, query=?, folder = ?, flag = ?, tags = ?, md5 = ?, date = ?, isStarred = ? WHERE id = ?`;
const UPDATE_CONVERSATIONS_STARS_SQL = `UPDATE ${CONVERSATIONS_TABLE_NAME} SET conversation = ?, md5 = ?, isStarred = ? WHERE id = ?`;
const CREATE_CONVERSATIONS_MULTIPLE_SQL = `INSERT INTO ${CONVERSATIONS_TABLE_NAME} (id, conversation, date, query, folder, flag, tags, md5, isStarred) VALUES `;
const CREATE_CONVERSATION_SQL = CREATE_CONVERSATIONS_MULTIPLE_SQL + "(?, ?, ?, ?, ?, ?, ?, ?, ?)";
const DELETE_TAGS_FOR_CONVERSATION_SQL = `DELETE FROM ${CONVERSATIONS_TAGS_TABLE_NAME} WHERE id = ?`;
const ADD_CONVERSATIONS_TAGS_SQL = `INSERT INTO ${CONVERSATIONS_TAGS_TABLE_NAME} (id, tag) VALUES `;
const ADD_CONVERSATION_TAG_SQL = ADD_CONVERSATIONS_TAGS_SQL + " (?, ?)";
const DELETE_TAGS_BY_CONVERSATION_SQL = `DELETE FROM ${CONVERSATIONS_TAGS_TABLE_NAME} WHERE id = ?`;
const DELETE_CONVERSATIONS_TAGS_MULTIPLE_SQL = `DELETE FROM ${CONVERSATIONS_TAGS_TABLE_NAME} WHERE id IN `;

const CLEAR_CONVERSATIONS_TABLE_SQL = `DELETE FROM ${CONVERSATIONS_TABLE_NAME}`;
const CLEAR_CONVERSATIONS_TAGS_TABLE_SQL = `DELETE FROM ${CONVERSATIONS_TAGS_TABLE_NAME}`;

// Users
const CREATE_USERS_TABLE_SQL = `CREATE TABLE IF NOT EXISTS ${USERS_TABLE_NAME} (id TEXT not null, email TEXT not null, title TEXT, name TEXT, isGroup INTEGER, display INTEGER, PRIMARY KEY (id))`;

const CREATE_USERS_MULTIPLE_SQL = `INSERT INTO ${USERS_TABLE_NAME} (id, email, title, name, isGroup, display) VALUES `;
const CREATE_USER_SQL = CREATE_USERS_MULTIPLE_SQL + " (?, ?, ?, ?, ?, ?)";
const FETCH_USERS_BY_EMAIL_PART_SQL = `SELECT * FROM ${USERS_TABLE_NAME} WHERE email LIKE '%?%'`;
const FETCH_USERS_SQL = `SELECT * FROM ${USERS_TABLE_NAME}`;
const FETCH_USERS_BY_IDS_SQL = `SELECT * FROM ${USERS_TABLE_NAME} WHERE id IN `;
const UPDATE_USER_SQL = `UPDATE ${USERS_TABLE_NAME} SET email = ?, title = ?, name = ?, isGroup = ?, display = ? WHERE id = ?`;

const CLEAR_USERS_TABLE_SQL = `DELETE FROM ${USERS_TABLE_NAME}`;

// Signatures
const CREATE_SIGNATURES_TABLE_SQL = `CREATE TABLE IF NOT EXISTS ${SIGNATURES_TABLE_NAME} (id TEXT not null, name TEXT, type TEXT, content TEXT not null, PRIMARY KEY (id))`;

const CREATE_SIGNATURES_MULTIPLE_SQL = `INSERT INTO ${SIGNATURES_TABLE_NAME} (id, name, type, content) VALUES `;
const CREATE_SIGNATURE_SQL = `INSERT INTO ${SIGNATURES_TABLE_NAME} (id, name, type, content) VALUES (?, ?, ?, ?)`;
const FETCH_ALL_SIGNATURES_SQL = `SELECT * FROM ${SIGNATURES_TABLE_NAME}`;
const UPDATE_SIGNATURE_SQL = `UPDATE ${SIGNATURES_TABLE_NAME} SET name = ?, type = ?, content = ? WHERE id = ?`;
const DELETE_SIGNATURES_BY_IDS_SQL = `DELETE FROM ${SIGNATURES_TABLE_NAME} WHERE id IN `;

const CLEAR_SIGNATURES_TABLE_SQL = `DELETE FROM ${SIGNATURES_TABLE_NAME}`;

// Folders
const CREATE_FOLDERS_TABLE_SQL = `CREATE TABLE IF NOT EXISTS ${FOLDERS_TABLE_NAME} (id TEXT not null, name TEXT, uuid TEXT, folder TEXT not null, PRIMARY KEY (id))`;

const CREATE_FOLDERS_MULTIPLE_SQL = `INSERT INTO ${FOLDERS_TABLE_NAME} (id, name, uuid, folder) VALUES `;
const CREATE_FOLDER_SQL = CREATE_FOLDERS_MULTIPLE_SQL + " (?, ?, ?, ?)";
const FETCH_ALL_FOLDERS_SQL = `SELECT folder FROM ${FOLDERS_TABLE_NAME}`;
const FETCH_FOLDERS_BY_IDS_SQL = `SELECT * FROM ${FOLDERS_TABLE_NAME} WHERE id IN `;
const UPDATE_FOLDER_SQL = `UPDATE ${FOLDERS_TABLE_NAME} SET name = ?, uuid = ?, folder = ? WHERE id = ?`;

const CLEAR_FOLDERS_TABLE_SQL = `DELETE FROM ${FOLDERS_TABLE_NAME}`;

// Tags
const CREATE_TAGS_TABLE_SQL = `CREATE TABLE IF NOT EXISTS ${TAGS_TABLE_NAME} (id TEXT not null, name TEXT not null, rgb TEXT, PRIMARY KEY (id))`;

const CREATE_TAGS_MULTIPLE_SQL = `INSERT INTO ${TAGS_TABLE_NAME} (id, name, rgb) VALUES `;
const CREATE_TAG_SQL = CREATE_TAGS_MULTIPLE_SQL + " (?, ?, ?)";
const FETCH_ALL_TAGS_SQL = `SELECT * FROM ${TAGS_TABLE_NAME}`;
const FETCH_TAGS_BY_IDS_SQL = `SELECT * FROM ${TAGS_TABLE_NAME} WHERE id IN `;
const UPDATE_TAG_SQL = `UPDATE ${TAGS_TABLE_NAME} SET name = ?, rgb = ? WHERE id = ?`;

const CLEAR_TAGS_TABLE_SQL = `DELETE FROM ${TAGS_TABLE_NAME}`;

// Pending operations
const CREATE_PENDING_OPERATIONS_TABLE_SQL = `CREATE TABLE IF NOT EXISTS ${PENDING_OPERATIONS_TABLE_NAME} (id TEXT not null, op TEXT not null, request TEXT not null, PRIMARY KEY (id))`;

const CREATE_PENDING_OPERATION_SQL = `INSERT INTO ${PENDING_OPERATIONS_TABLE_NAME} (id, op, request) VALUES (?, ?, ?)`;
const FETCH_ALL_PENDING_OPERATION_SQL = `SELECT * FROM ${PENDING_OPERATIONS_TABLE_NAME}`;
const FETCH_PENDING_OPERATION_BY_ID_SQL = `SELECT * FROM ${PENDING_OPERATIONS_TABLE_NAME} WHERE id = ?`;
const UPDATE_PENDING_OPPERATION_SQL = `UPDATE ${PENDING_OPERATIONS_TABLE_NAME} SET op = ?, request = ? WHERE id = ?`;
const DELETE_ALL_PENDING_OPERATION_SQL = `DELETE FROM ${PENDING_OPERATIONS_TABLE_NAME}`;
const DELETE_PENDING_OPERATION_BY_ID_SQL = `DELETE FROM ${PENDING_OPERATIONS_TABLE_NAME} WHERE id = ?`;
const DELETE_PENDING_OPERATIONS_BY_IDS_SQL = `DELETE FROM ${PENDING_OPERATIONS_TABLE_NAME} WHERE id IN `;

const CLEAR_PENDING_OPERATIONS_TABLE_SQL = `DELETE FROM ${PENDING_OPERATIONS_TABLE_NAME}`;

// Current user
const CREATE_CURRENT_USER_TABLE_SQL = `CREATE TABLE IF NOT EXISTS ${CURRENT_USER_TABLE_NAME} (email TEXT not null, PRIMARY KEY (email))`;
const CREATE_CURRENT_USER_SQL = `INSERT INTO ${CURRENT_USER_TABLE_NAME} (email) VALUES (?)`;
const FETCH_CURRENT_USER_SQL = `SELECT * FROM ${CURRENT_USER_TABLE_NAME}`;
const CLEAR_CURRENT_USER_TABLE_SQL = `DELETE FROM ${CURRENT_USER_TABLE_NAME}`;

// Avatar
const CREATE_AVATAR_TABLE_SQL = `CREATE TABLE IF NOT EXISTS ${AVATAR_TABLE_NAME} (id TEXT not null, data TEXT not null, PRIMARY KEY (id))`;
const DELETE_AVATAR_SQL = `DELETE FROM ${AVATAR_TABLE_NAME} WHERE id = ?`;
const CREATE_AVATAR_SQL = `INSERT INTO ${AVATAR_TABLE_NAME} (id, data) VALUES (?,?) ON CONFLICT(id) DO UPDATE SET data = ?`;
const FETCH_AVATAR_SQL = `SELECT * FROM ${AVATAR_TABLE_NAME}`;
const SELECT_AVATAR_BY_ID_SQL = `SELECT * FROM ${AVATAR_TABLE_NAME} WHERE id = ?`;
const CLEAR_AVATAR_TABLE_SQL = `DELETE FROM ${AVATAR_TABLE_NAME}`;
const FETCH_ALL_APPOINTMENTS = `SELECT * FROM ${APPOINTMENT_TABLE_NAME} `;

const MESSAGES_TABLE_COLUMNS_MIGRATIONS = ["last_updated TEXT", "mp TEXT", "e TEXT", "flag TEXT", "query TEXT"];
export class SqliteDBService implements DatabaseInterface {
  private db: SQLDBInterface;
  private isDBInited: boolean = false;
  private initDBPromise: Promise<any>;

  public dataBaseReadyCallback: any;

  flatFolders: MailFolder[] = [];

  constructor(private store: Store<RootState>) {
    console.log("[SqliteDBService][constructor]");

    if (environment.isElectron) {
      console.log("[SqliteDBService][constructor] for electron");
      this.db = new ElectronDB(DB_NAME);
      setTimeout(() => {
        this.setDatabaseReady();
      }, 200);
    } else if (environment.isCordova) {
      console.log("[SqliteDBService][constructor] for cordova");
      document.addEventListener("deviceready", () => {
        console.log("[SqliteDBService][constructor] isDeviceReady");
        this.db = new CordovaDB(DB_NAME);
        setTimeout(() => {
          this.setDatabaseReady();
        }, 200);
      });
    } else {
      console.error("[SqliteDBService][constructor] unsupported platform for SQL");
      throw new Error("Unsupported platform for SQL");
    }
  }

  updateConversationMessages(convId: string, messages: any[]): Observable<any> {
    const response = new Subject<any>();
    this.getDB().then(db => {
      db.executeSql(FETCH_CONVERSATION_BY_ID_SQL, [convId], (result) => {
        console.info("[SqliteDBService][updateConversationMessages] FETCH_CONVERSATION_BY_ID_SQL result = ", result);
        const existConvsCount = result.rows.length;
        if (existConvsCount > 0) {
          const row = result.rows.item(0);
          const existConv: Conversation = JSON.parse(row.conversation);
          existConv.m.forEach(m => {
            const updatedMessage = messages.find(v => v.id === m.id);
            if (updatedMessage) {
              m = {...m, ...updatedMessage};
            }
          });
          existConv.n = existConv.m.length.toString();

          this.updateConversations([existConv]).subscribe((result2) => {
            console.info("[SqliteDBService][updateConversationMessages] update conversation result = ", result2);
            response.next(true);
          }, (error) => {
            console.error("[SqliteDBService][updateConversationMessages] update conversation error = ", error);
            response.error(error);
          });
        } else {
          response.next(true);
        }
      }, (error) => {
        console.error("[SqliteDBService][updateConversationMessages] FETCH_CONVERSATION_BY_ID_SQL error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  private async getDB(): Promise<any> {
    console.info("[SqliteDBService][getDB]", !!this.initDBPromise);

    return new Promise((resolve, reject) => {
      if (!this.db) {
        console.error("[SqliteDBService][getDB] this.db is undefined");
        reject("DB is not created yet");
      } else if (this.isDBInited) {
        // console.info("[SqliteDBService][getDB] return exist DB");
        resolve(this.db);
      } else {
        if (this.initDBPromise) {
          this.initDBPromise.then(result => {
            console.info("[SqliteDBService][getDB] initDB, result1 = ", result);
            resolve(this.db);
          }).catch(error => {
            console.error("[SqliteDBService][getDB] initDB, error1 = ", error);
            reject(error);
          });
        } else {
          this.initDBPromise = this.initDB(this.db).then(result => {
            console.info("[SqliteDBService][getDB] initDB, result2 = ", result);
            this.initDBPromise = null;

            resolve(this.db);

            if (this.dataBaseReadyCallback) {
              this.dataBaseReadyCallback();
            }
          }).catch(error => {
            console.error("[SqliteDBService][getDB] initDB, error2 = ", error);
            reject(error);
          });
        }
      }
    });
  }

  private async initDB(db: SQLDBInterface): Promise<any> {
    console.info("[SqliteDBService][initDB], db = ", db);

    const queries = [
      CREATE_CONVERSATIONS_TABLE_SQL,
      CREATE_CONVERSATIONS_TAGS_TABLE_SQL,
      CREATE_MESSAGES_TAGS_TABLE_SQL,
      CREATE_MESSAGES_TABLE_SQL,
      CREATE_USERS_TABLE_SQL,
      CREATE_SIGNATURES_TABLE_SQL,
      CREATE_FOLDERS_TABLE_SQL,
      CREATE_TAGS_TABLE_SQL,
      CREATE_PENDING_OPERATIONS_TABLE_SQL,
      CREATE_CURRENT_USER_TABLE_SQL,
      CREATE_AVATAR_TABLE_SQL
    ];

    return new Promise((resolve, reject) => {
      db.sqlBatch(queries, (result) => {
        console.info("[SqliteDBService][initDB]");

        this.isDBInited = true;
        console.info("[SqliteDBService][initDB] result = ", result);
        resolve(result);
      }, (error) => {
        this.isDBInited = false;
        console.error("[SqliteDBService][initDB] error = ", error);
        reject(error);
      });
    });
  }

  private async addMissingColumns(db: SQLDBInterface, tableName: string, columns: string[]): Promise<any> {
    console.info("[SqliteDBService][addMissingColumns], db = ", tableName, columns);
    const queries = columns.map(v => {
      return `ALTER TABLE ${tableName} ADD COLUMN ${v}`;
    });

    return new Promise((resolve, reject) => {
      db.sqlBatch(queries, (result) => {
        console.info("[SqliteDBService][addMissingColumns] result = ", result);
        resolve(result);
      }, (error) => {
        console.error("[SqliteDBService][addMissingColumns] error = ", error);
        reject(error);
      });
    });
  }

  deleteDB(): Observable<any> {
    const response = new Subject<any>();

    this.getDB().then((db) => {
      db.deleteDatabase(DB_NAME, (error) => {
        if (error) {
          response.error(error);
        } else {
          response.next(true);
        }
      });
    });

    return response.asObservable().pipe(take(1));
  }

  clearDB(): Observable<any> {
    console.info("[SqliteDBService][clearDB]", this.isDBInited);

    if (this.isDBInited) {
      return this._clearDB();
    } else {
      return null;
    }
  }

  private _clearDB(): Observable<any> {
    console.info("[SqliteDBService][_clearDB]");

    const response = new Subject<any>();

    this.getDB().then(db => {
      console.info("[SqliteDBService][_clearDB]", "db");

      const queries = [
        CLEAR_CONVERSATIONS_TABLE_SQL,
        CLEAR_CONVERSATIONS_TAGS_TABLE_SQL,
        CLEAR_MESSAGES_TAGS_TABLE_SQL,
        CLEAR_MESSAGES_TABLE_SQL,
        CLEAR_USERS_TABLE_SQL,
        CLEAR_SIGNATURES_TABLE_SQL,
        CLEAR_FOLDERS_TABLE_SQL,
        CLEAR_TAGS_TABLE_SQL,
        CLEAR_PENDING_OPERATIONS_TABLE_SQL,
        CLEAR_CURRENT_USER_TABLE_SQL,
        CLEAR_AVATAR_TABLE_SQL
      ];

      db.sqlBatch(queries, (result) => {
        console.info("[SqliteDBService][_clearDB] result = ", result);
      }, (error: any) => {
        console.error("[SqliteDBService][_clearDB] error1 = ", error);
        response.error(error);
      });
    }).catch(error => {
      console.error("[SqliteDBService][_clearDB] error2 = ", error);
      response.error(error);
    });

    return response.asObservable().pipe(take(1));
  }

  setDatabaseReady() {
    console.log("[DatabaseService] set DatabaseReady");
    this.store.dispatch(new DatabaseReady());
  }


  addUsers(users: Array<User>): Observable<any> {
    console.info("[SqliteDBService][addUsers]");
    const response = new Subject<any>();

    if (!(users && users.length)) {
      response.next(true);
    } else {
      this.getDB().then((db) => {
        const usersIds = users.map(user => user.id);
        const usersMap = users.reduce((result, item) => {
          if (!result[item.id]) {
            result[item.id] = item;
          }
          return result;
        }, {} as { [key: string]: User });

        const query = FETCH_USERS_BY_IDS_SQL + "('" + usersIds.join("','") + "')";
        db.executeSql(query, [], (result) => {
          console.info("[SqliteDBService][addUsers] FETCH_USERS_BY_IDS_SQL result = ", result);
          const existUsersCount = result.rows.length;
          let existUsersIds = [];
          if (existUsersCount > 0) {
            console.info(`[SqliteDBService][addUsers] first of all update exist ${existUsersCount} users`);
            for (let i = 0; i < result.rows.length; i++) {
              const row = result.rows.item(i);
              const forUpdateUser = usersMap[row.id];
              const isGroup = forUpdateUser.isGroup ? 1 : 0;
              const display = forUpdateUser.display ? 1 : 0;
              // `UPDATE ${USERS_TABLE_NAME} SET email = ?, title = ?, name = ?, isGroup = ?, display = ? WHERE id = ?`
              db.executeSql(UPDATE_USER_SQL, [forUpdateUser.email, forUpdateUser.title, forUpdateUser.name, isGroup, display, forUpdateUser.id], (result2) => {
                console.info("[SqliteDBService][addUsers] UPDATE_USER_SQL result = ", result2);
                existUsersIds.push(row.id);

                if (existUsersCount === existUsersIds.length) {
                  if (users.length - existUsersCount > 0) {
                    let totalUsers = users.length - existUsersCount;
                    let processedUsers = 0;

                    Object.keys(usersMap).forEach((id) => {
                      if (!existUsersIds.includes(id)) {
                        const forCreateUser = usersMap[id];
                        const isGroup = forCreateUser.isGroup ? 1 : 0;
                        const display = forCreateUser.display ? 1 : 0;
                        db.executeSql(CREATE_USER_SQL, [forCreateUser.id, forCreateUser.email, forCreateUser.title, forCreateUser.name, isGroup, display], (result3) => {
                          console.info("[SqliteDBService][addUsers] INSERT user success, id = ", forCreateUser.id);
                          processedUsers++;

                          if (totalUsers === processedUsers) {
                            response.next(true);
                          }
                        }, (error) => {
                          console.error(`[SqliteDBService][addUsers] INSERT user error, id = ${forCreateUser.id}`, error);
                          response.error(error);
                        });
                      }
                    });
                  } else {
                    response.next(true);
                  }
                }
              }, (error) => {
                console.error("[SqliteDBService][addUsers] UPDATE_USER_SQL error = ", error);
                response.error(error);
              });
            }
          } else {
            console.info("[SqliteDBService][addUsers] no exist users, so create them");
            let totalUsers = users.length;
            let processedItems = 0;

            Object.keys(usersMap).forEach((id) => {
              const forCreateUser = usersMap[id];
              const isGroup = forCreateUser.isGroup ? 1 : 0;
              const display = forCreateUser.display ? 1 : 0;
              db.executeSql(CREATE_USER_SQL, [forCreateUser.id, forCreateUser.email, forCreateUser.title, forCreateUser.name, isGroup, display], (result) => {
                console.info("[SqliteDBService][addUsers] INSERT user success, id = ", forCreateUser.id);
                processedItems++;

                if (totalUsers === processedItems) {
                  response.next(true);
                }
              }, (error) => {
                console.error(`[SqliteDBService][addUsers] INSERT user error, id = ${forCreateUser.id}`, error);
                response.error(error);
              });
            });
          }
        }, (error) => {
          console.error("[SqliteDBService][addUsers] FETCH_USERS_BY_IDS_SQL error = ", error);
          response.error(error);
        });
      }).catch((error) => {
        console.error("[SqliteDBService][addUsers] getting DB error = ", error);
        response.error(error);
      });
    }

    return response.asObservable().pipe(take(1));
  }

  getUsers(mailPart: string): Observable<User[]> {
    console.info("[SqliteDBService][getUsers] mailPart = ", mailPart);
    const response = new Subject<User[]>();

    this.getDB().then((db) => {
      db.executeSql(FETCH_USERS_SQL, [], (result) => {
        console.info("[SqliteDBService][getUsers] result = ", result);

        const users = [];
        for (let i = 0; i < result.rows.length; i++) {
          const row = result.rows.item(i);
          if (row.email.includes(mailPart)) {
            row.isGroup = Boolean(row.isGroup);
            row.display = Boolean(row.display);
            const user: User = { ...row };
            users.push(user);
          }
        }

        console.info("[SqliteDBService][getUsers] users to return = ", users);
        response.next(users);
      }, (error) => {
        console.error("[SqliteDBService][getUsers] error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getSignatures(): Observable<Signature[]> {
    console.info("[SqliteDBService][getSignatures]");
    const response = new Subject<Signature[]>();

    this.getDB().then((db) => {
      db.executeSql(FETCH_ALL_SIGNATURES_SQL, [], (result) => {
        console.info("[SqliteDBService][getSignatures] result = ", result);
        const signatures = [];
        for (let i = 0; i < result.rows.length; i++) {
          const row = result.rows.item(i);
          const signature: Signature = { ...row };
          signatures.push(signature);
        }

        console.info("[SqliteDBService][getSignatures] signatures to return = ", signatures);
        response.next(signatures);
      }, (error) => {
        console.error("[SqliteDBService][getSignatures] error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  setSignatures(signatures: Signature[]): Observable<any> {
    console.info("[SqliteDBService][setSignatures]");
    const response = new Subject<any>();

    if (!(signatures && signatures.length)) {
      response.next(true);
    } else {
      this.getDB().then((db) => {

        db.executeSql(CLEAR_SIGNATURES_TABLE_SQL, [], (result) => {
          console.info("[SqliteDBService][setSignatures](clear) result = ", result);

          let processedItems = 0;

          signatures.forEach((signature) => {
            db.executeSql(CREATE_SIGNATURE_SQL, [signature.id, signature.name, signature.type, signature.content], (result) => {
              console.info("[SqliteDBService][setSignatures] result = ", result);

              processedItems++;
              if (processedItems === signatures.length) {
                response.next(true);
              }
            }, (error) => {
              console.error("[SqliteDBService][setSignatures] error = ", error);
              response.error(error);
            });
          });
        }, (error) => {
          console.error("[SqliteDBService][setSignatures](clear) error = ", error);
          response.error(error);
        });
      });
    }

    return response.asObservable().pipe(take(1));
  }

  deleteSignatures(ids: string[]): Observable<any> {
    console.info("[SqliteDBService][deleteSignatures]");
    const query = DELETE_SIGNATURES_BY_IDS_SQL + "('" + ids.join("','") + "')";
    console.info(`[SqliteDBService][deleteSignatures] query = ${query}`);

    const response = new Subject<any>();
    this.getDB().then((db) => {
      db.executeSql(query, [], (result) => {
        console.info("[SqliteDBService][deleteSignatures] result = ", result);
        response.next(result);
      }, (error) => {
        console.error("[SqliteDBService][deleteSignatures] error = ", error);
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  createSignature(signature: Signature): Observable<any> {
    console.info("[SqliteDBService][createSignature]");
    const response = new Subject<any>();
    this.getDB().then((db) => {
      db.executeSql(CREATE_SIGNATURE_SQL, [signature.id, signature.name, signature.type, signature.content], (result) => {
        console.info("[SqliteDBService][createSignature] result = ", result);
        response.next(result);
      }, (error) => {
        console.error("[SqliteDBService][createSignature] error = ", error);
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  updateSignature(signature: Signature): Observable<any> {
    console.info("[SqliteDBService][updateSignature]");
    const response = new Subject<any>();
    this.getDB().then((db) => {
      db.executeSql(UPDATE_SIGNATURE_SQL, [signature.name, signature.type, signature.content, signature.id], (result) => {
        console.info("[SqliteDBService][updateSignature] result = ", result);
        response.next(result);
      }, (error) => {
        console.error("[SqliteDBService][updateSignature] error = ", error);
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }
  createFlatFolder(folders: MailFolder[]): void {
    for (let i = 0; i < folders.length; i++) {
      const folder = folders[i];
      this.flatFolders.push(folder);
      if (folder.children) {
        this.createFlatFolder(folder.children);
      }
    }
  }

  addFolders(folders: MailFolder[]): Observable<any> {
    console.info("[SqliteDBService][addFolders] folders", folders);
    this.createFlatFolder(folders);

    const response = new Subject<any>();

    if (!(this.flatFolders && this.flatFolders.length)) {
      response.next(true);
    } else {
      this.getDB().then((db) => {
        const foldersIds = this.flatFolders.map(folder => folder.id);
        const foldersMap = this.flatFolders.reduce((result, item) => {
          if (!result[item.id]) {
            result[item.id] = item;
          }
          return result;
        }, {} as { [key: string]: MailFolder });

        const query = FETCH_FOLDERS_BY_IDS_SQL + "('" + foldersIds.join("','") + "')";
        db.executeSql(query, [], (result) => {
          console.info("[SqliteDBService][addFolders] FETCH_FOLDERS_BY_IDS_SQL result = ", result);
          const existFoldersCount = result.rows.length;
          let existFoldersIds = [];
          if (existFoldersCount > 0) {
            console.info(`[SqliteDBService][addFolders] first of all update exist ${existFoldersCount} folders`);
            for (let i = 0; i < existFoldersCount; i++) {
              const row = result.rows.item(i);
              const forUpdateFolder = foldersMap[row.id];
              // `UPDATE ${FOLDERS_TABLE_NAME} SET name = ?, uuid = ?, folder = ? WHERE id = ?`
              db.executeSql(UPDATE_FOLDER_SQL, [forUpdateFolder.name, forUpdateFolder.uuid, JSON.stringify(forUpdateFolder), forUpdateFolder.id], (result2) => {
                console.info("[SqliteDBService][addFolders] UPDATE_FOLDER_SQL result = ", result2);
                existFoldersIds.push(row.id);

                if (existFoldersCount === existFoldersIds.length) {
                  if (folders.length - existFoldersCount > 0) {
                    let totalFolders = folders.length - existFoldersCount;
                    let processedItems = 0;

                    Object.keys(foldersMap).forEach((id) => {
                      if (!existFoldersIds.includes(id)) {
                        const forCreateFolder = foldersMap[id];

                        db.executeSql(CREATE_FOLDER_SQL, [forCreateFolder.id, forCreateFolder.name, forCreateFolder.uuid, JSON.stringify(forCreateFolder)], (result3) => {
                          console.info("[SqliteDBService][addFolders] INSERT folder success, id = ", forCreateFolder.id);
                          processedItems++;

                          if (totalFolders === processedItems) {
                            response.next(true);
                          }
                        }, (error) => {
                          console.error(`[SqliteDBService][addFolders] INSERT folder error, id = ${forCreateFolder.id}`, error);
                          response.error(error);
                        });
                      }
                    });
                  } else {
                    response.next(true);
                  }
                }
              }, (error) => {
                console.error("[SqliteDBService][addFolders] UPDATE_FOLDER_SQL error = ", error);
                response.error(error);
              });
            }
          } else {
            console.info("[SqliteDBService][addFolders] no exist folders, so create them");
            let totalFolders = this.flatFolders.length;
            let processedItems = 0;

            Object.keys(foldersMap).forEach((id) => {
              const forCreateFolder = foldersMap[id];

              db.executeSql(CREATE_FOLDER_SQL, [forCreateFolder.id, forCreateFolder.name, forCreateFolder.uuid, JSON.stringify(forCreateFolder)], (result3) => {
                console.info("[SqliteDBService][addFolders] INSERT folder success, id = ", forCreateFolder.id);
                processedItems++;

                if (totalFolders === processedItems) {
                  response.next(true);
                }
              }, (error) => {
                console.error(`[SqliteDBService][addFolders] INSERT folder error, id = ${forCreateFolder.id}`, error);
                response.error(error);
              });
            });
          }
        }, (error) => {
          console.error("[SqliteDBService][addFolders] FETCH_FOLDERS_BY_IDS_SQL error = ", error);
          response.error(error);
        });
      }).catch((error) => {
        console.error("[SqliteDBService][addFolders] getting DB error = ", error);
        response.error(error);
      });
    }

    return response.asObservable().pipe(take(1));
  }

  addCalendarFolders(folders: any[]): Observable<any> {
    console.log("[dumbDBService][addFolders]", folders);

    const response = new Subject<any>();

    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }


  getFolders(): Observable<MailFolder[]> {
    console.info("[SqliteDBService][getFolders]");
    const response = new Subject<any[]>();

    this.getDB().then((db) => {
      db.executeSql(FETCH_ALL_FOLDERS_SQL, [], (result) => {
        console.info("[SqliteDBService][getFolders] result = ", result);

        const folders = [];
        for (let i = 0; i < result.rows.length; i++) {
          const row = result.rows.item(i);
          const folder: MailFolder = JSON.parse(row.folder);
          folders.push(folder);
        }

        console.info("[SqliteDBService][getFolders] folders to return = ", folders);
        response.next(folders);
      }, (error) => {
        console.error("[SqliteDBService][getFolders] error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getCalendarFolders(): Observable<any[]> {
    console.log("[dumbDBService][getFolders]");

    const response = new Subject<any>();
    const messagesMap = {};
    setTimeout(() => {
      response.next(messagesMap);
    }, 10);

    return response.asObservable().pipe(take(1));
  }


  addAppointments(appointments: any[], query?: any): Observable<any> {
    const response = new Subject<any>();
    let totalMsgs = appointments.length;
    let processedConv = 0;
    if (!(appointments && appointments.length)) {
      response.next(true);
    } else {
      const msgsMap: {[key: string]: any} = {};
      const msgIds = appointments.map(message => {
        if (!msgsMap[message.id]) {
          msgsMap[message.id] = message;
        }
        return message.id;
      });

      this.getDB().then(db => {
        msgIds.forEach((id) => {
            const forCreateMsg = msgsMap[id];
            const stringMsg = JSON.stringify(forCreateMsg);
            // const tags = forCreateMsg.tags ? forCreateMsg.tags.join(", ") : "";
            const flag = forCreateMsg.f || "";
            // const e = forCreateMsg.e ? JSON.stringify(forCreateMsg.e) : "";
            const mp = forCreateMsg.mp ? JSON.stringify(forCreateMsg.mp) : "";
            const isMsgStarred = forCreateMsg.f && forCreateMsg.f.includes("f");
            const isStarredInt = isMsgStarred ? 1 : 0;
            const query = MailUtils.getQueryByFolderId(+forCreateMsg.l);
            const folder = forCreateMsg.l || "";
            const insertValues = [id, stringMsg, forCreateMsg.d, new Date().getTime(), query, mp, folder, flag, "", isStarredInt];
            const updateValues = [stringMsg, new Date().getTime(), query, mp, folder, flag, isStarredInt];
            const updateValuesWithoutMp = [stringMsg, new Date().getTime(), query, folder, flag, isStarredInt];
            let params = [...insertValues, ...updateValues];
            let sql = UPSERT_MESSAGE_ALL_SQL;
            if (!mp) {
              sql = UPSERT_MESSAGE_WITHOUT_MP_SQL;
              params = [...insertValues, ...updateValuesWithoutMp];
            }
            console.info("[SqliteDBService][addMessages] INSERT message query ", sql);
            console.info("[SqliteDBService][addMessages] INSERT message params ", params);
            db.executeSql(sql, params, (res) => {
              console.info("[SqliteDBService][addMessages] INSERT message success, id = ", id, res);
              processedConv++;
              if (totalMsgs === processedConv) {
                // this.setTagsForMessages(messages);
                response.next(true);
              }
              }, (error) => {
                console.error(`[SqliteDBService][addMessages] INSERT message error, id = ${forCreateMsg.id}`, error);
                response.error(error);
            });
        });
      });
    }

    return response.asObservable().pipe(take(1));
  }

  addMessages(messages: Message[], query?: any): Observable<any> {
    console.info(`[SqliteDBService][addMessages]`, messages);
    const response = new Subject<any>();
    let totalMsgs = messages.length;
    let processedConv = 0;
    if (!(messages && messages.length)) {
      response.next(true);
    } else {
      const msgsMap: {[key: string]: Message} = {};
      const msgIds = messages.map(message => {
        if (!msgsMap[message.id]) {
          msgsMap[message.id] = message;
        }
        return message.id;
      });

      this.getDB().then(db => {
        msgIds.forEach((id) => {
            const forCreateMsg = msgsMap[id];
            const stringMsg = JSON.stringify(forCreateMsg);
            const tags = forCreateMsg.tags ? forCreateMsg.tags.join(", ") : "";
            const flag = forCreateMsg.f || "";
            const e = forCreateMsg.e ? JSON.stringify(forCreateMsg.e) : "";
            const mp = forCreateMsg.mp ? JSON.stringify(forCreateMsg.mp) : "";
            const isMsgStarred = forCreateMsg.f && forCreateMsg.f.includes("f");
            const isStarredInt = isMsgStarred ? 1 : 0;
            const query = MailUtils.getQueryByFolderId(+forCreateMsg.l);
            const folder = forCreateMsg.l || "";
            const insertValues = [id, stringMsg, forCreateMsg.d, new Date().getTime(), forCreateMsg.cid, query, mp, folder, e, flag, tags, "", isStarredInt];
            const updateValues = [stringMsg, new Date().getTime(), query, mp, folder, e, flag, tags, isStarredInt];
            const updateValuesWithoutMp = [stringMsg, new Date().getTime(), query, folder, e, flag, tags, isStarredInt];
            let params = [...insertValues, ...updateValues];
            let sql = UPSERT_MESSAGE_ALL_SQL;
            if (!mp) {
              sql = UPSERT_MESSAGE_WITHOUT_MP_SQL;
              params = [...insertValues, ...updateValuesWithoutMp];
            }
            console.info("[SqliteDBService][addMessages] INSERT message query ", sql);
            console.info("[SqliteDBService][addMessages] INSERT message params ", params);
            db.executeSql(sql, params, (res) => {
              console.info("[SqliteDBService][addMessages] INSERT message success, id = ", id, res);
              processedConv++;
              if (totalMsgs === processedConv) {
                this.setTagsForMessages(messages);
                response.next(true);
              }
              }, (error) => {
                console.error(`[SqliteDBService][addMessages] INSERT message error, id = ${forCreateMsg.id}`, error);
                response.error(error);
            });
        });
      });
    }

    return response.asObservable().pipe(take(1));
  }

  private setTagsForMessage(message: Message) {
    console.info("[SqliteDBService][setTagsForMessage]", message);
    this.getDB().then(db => {
      db.executeSql(DELETE_TAGS_BY_MESSAGE_SQL, [message.id], (result) => {
        console.info("[SqliteDBService][setTagsForMessage] DELETE_TAGS_BY_MESSAGE_SQL result = ", result);
        if (message.tags && message.tags.length > 0) {
          for (let tag of message.tags) {
            db.executeSql(ADD_MESSAGE_TAG_SQL, [message.id, tag], (result2) => {
              console.info("[SqliteDBService][setTagsForMessage] insert tag result = ", result2);
            }, (error) => {
              console.error("[SqliteDBService][setTagsForMessage] insert tag error = ", error);
            });
          }
        }
      }, (error) => {
        console.error("[SqliteDBService][setTagsForMessage] DELETE_TAGS_BY_MESSAGE_SQL error = ", error);
        if (message.tags && message.tags.length > 0) {
          for (let tag of message.tags) {
            db.executeSql(ADD_MESSAGE_TAG_SQL, [message.id, tag], (result2) => {
              console.info("[SqliteDBService][setTagsForMessage] insert tag result = ", result2);
            }, (error) => {
              console.error("[SqliteDBService][setTagsForMessage] insert tag error = ", error);
            });
          }
        }
      });
    }).catch(error => {
      console.error("[SqliteDBService][setTagsForMessage] getting DB error = ", error);
    });
  }

  private addTagsForMessage(message: Message) {
    console.info(`[SqliteDBService][addTagsForMessage] message.id = ${message.id}`);
    if (message.tags && message.tags.length > 0) {
      this.getDB().then(db => {
        for (let tag of message.tags) {
          db.executeSql(ADD_MESSAGE_TAG_SQL, [message.id, tag], (result2) => {
            console.info("[SqliteDBService][addTagsForMessage] insert tag result = ", result2);
          }, (error) => {
            console.error("[SqliteDBService][addTagsForMessage] insert tag error = ", error);
          });
        }
      });
    }
  }

  private setTagsForMessages(messages: Message[]) {
    console.info("[SqliteDBService][setTagsForMessages] messages", messages);
    const msgsMap = messages.reduce((result, item) => {
      if (!result[item.id]) {
        result[item.id] = item;
      }
      return result;
    }, {} as { [key: string]: Message });

    const msgsIds = Object.keys(msgsMap);
    if (msgsIds.length > 0) {
      this.getDB().then(db => {
        const query = DELETE_MESSAGES_TAGS_MULTIPLE_SQL + "('" + msgsIds.join("','") + "')";
        db.executeSql(query, [], (result) => {
          console.info("[SqliteDBService][setTagsForMessages] DELETE_MESSAGES_TAGS_MULTIPLE_SQL result", result);
          messages.forEach(message => {
            if (message.tags && message.tags.length > 0) {
              for (let tag of message.tags) {
                db.executeSql(ADD_MESSAGE_TAG_SQL, [message.id, tag], (result2) => {
                  console.info("[SqliteDBService][setTagsForMessages] insert tag result = ", result2);
                }, (error) => {
                  console.error("[SqliteDBService][setTagsForMessages] insert tag error = ", error);
                });
              }
            }
          });
        }, (error) => {
          console.error("[SqliteDBService][setTagsForMessages] error = ", error);
        });
      }).catch(error => {
        console.error("[SqliteDBService][setTagsForMessages] getting DB error = ", error);
      });
    }
  }


  getLatestMessage(): Observable<Message[]> {
    const response = new Subject<Message[]>();

    const messagesMap = [];
    response.next(messagesMap);

    return response.asObservable().pipe(take(1));
  }

  getFirstMessageByFolder(folderName, order): Observable<Message[]> {
    const response = new Subject<Message[]>();

    const messagesMap = [];
    setTimeout(() => {
      response.next(messagesMap);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  getMessageCountInDatabaseByFolder(folderName): Observable<any> {
    const response = new Subject<any>();
    setTimeout(() => {
      response.error(null);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  getMessageById(id: string): Observable<Message> {
    console.info(`[SqliteDBService][getMessageById] id = ${id}`);
    const response = new Subject<Message>();

    this.getDB().then((db) => {
      db.executeSql(FETCH_MESSAGE_BY_ID_SQL, [id], (result) => {
        console.info("[SqliteDBService][getMessageById] result = ", result);
        if (result.rows.length > 0) {
          const row = result.rows.item(0);
          const message: Message = JSON.parse(row.message);
          if (!!row.mp) {
            try {
              console.info(`[SqliteDBService][getMessageById] add mp to message from database for message ${id}`);
              message.mp = JSON.parse(row.mp);
            } catch (error) {
              console.error(`[SqliteDBService][getMessageById] cannot add mp to message from database for message ${id}`);
            }
            message.mp = JSON.parse(row.mp);
          }
          console.info("[SqliteDBService][getMessageById] message to return = ", message);
          // console.info("[SqliteDBService][getMessageById] message to return2 = ", JSON.stringify(message));
          response.next(message);
        } else {
          response.next(null);
        }
      }, (error) => {
        console.error("[SqliteDBService][getMessageById] error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getMessagesByConversationId(conversationId: string): Observable<Message[]> {
    console.info(`[SqliteDBService][getMessagesByConversationId] conversationId = ${conversationId}`);
    const response = new Subject<Message[]>();

    this.getDB().then((db) => {
      db.executeSql(FETCH_MESSAGES_BY_CONVERSATION_SQL, [conversationId], (result) => {
        console.info("[SqliteDBService][getMessagesByConversationId] result = ", result);

        const messages = [];
        for (let i = 0; i < result.rows.length; i++) {
          const row = result.rows.item(i);
          const message: Message = JSON.parse(row.message);
          messages.push(message);
        }

        console.info("[SqliteDBService][getMessagesByConversationId] messages to return = ", messages);
        response.next(messages);
      }, (error) => {
        console.error("[SqliteDBService][getMessagesByConversationId] error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getAppointmentsById(id: string): Observable<any[]> {
    console.info(`[SqliteDBService][getAppointmentsById] id = ${id}`);
    const response = new Subject<any[]>();

    this.getDB().then((db) => {
      db.executeSql(FETCH_MESSAGES_BY_CONVERSATION_SQL, [id], (result) => {
        console.info("[SqliteDBService][getAppointmentsById] result = ", result);

        const messages = [];
        for (let i = 0; i < result.rows.length; i++) {
          const row = result.rows.item(i);
          const message: any = JSON.parse(row.message);
          messages.push(message);
        }

        console.info("[SqliteDBService][getAppointmentsById] messages to return = ", messages);
        response.next(messages);
      }, (error) => {
        console.error("[SqliteDBService][getAppointmentsById] error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  fetchAttachmentById(id: string): Observable<any> {
    console.info(`[SqliteDBService][fetchAttachmentById] id = ${id}`);
    const response = new Subject<any[]>();

    this.getDB().then((db) => {
      db.executeSql(FETCH_MESSAGES_BY_CONVERSATION_SQL, [id], (result) => {
        console.info("[SqliteDBService][fetchAttachmentById] result = ", result);

        const messages = [];
        for (let i = 0; i < result.rows.length; i++) {
          const row = result.rows.item(i);
          const message: any = JSON.parse(row.message);
          messages.push(message);
        }

        console.info("[SqliteDBService][fetchAttachmentById] messages to return = ", messages);
        response.next(messages);
      }, (error) => {
        console.error("[SqliteDBService][fetchAttachmentById] error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getMessagesByFolder(folderName: string, query?: any, includingId?: string): Observable<Message[]> {
    console.info(`[SqliteDBService][getMessagesByFolder]`, folderName);
    let fname = folderName;
    if (folderName.indexOf("in:") > -1) {
      fname = MailUtils.getFolderId(folderName).toString();
    }
    if (folderName.indexOf("inid:") > -1) {
      fname = folderName.split("\"")[1];
    }


    const response = new Subject<Message[]>();

    this.getDB().then((db) => {
      db.executeSql(FETCH_MESSAGES_FROM_FOLDER_SQL, [fname], (result) => {
        console.info("[SqliteDBService][getMessagesByFolder] result = ", result);

        const messages = [];
        for (let i = 0; i < result.rows.length; i++) {
          const row = result.rows.item(i);
          const message: Message = JSON.parse(row.message);
          messages.push(message);
        }

        console.info("[SqliteDBService][getMessagesByFolder] messages to return = ", messages);
        response.next(messages);
      }, (error) => {
        console.error("[SqliteDBService][getMessagesByFolder] result = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getMessagesByTag(tagName: string[]): Observable<Message[]> {
    console.info(`[SqliteDBService][getMessagesByTag] tagName = ${tagName}`);
    const query = FETCH_MESSAGES_BY_TAGS_SQL + "('" + tagName.join("','") + "') ORDER BY date DESC";
    console.info("[SqliteDBService][getMessagesByTag] query = ", query);

    const response = new Subject<Message[]>();

    this.getDB().then((db) => {
      db.executeSql(query, [], (result) => {
        console.info("[SqliteDBService][getMessagesByTag] result = ", result);
        const messages = [];
        for (let i = 0; i < result.rows.length; i++) {
          const row = result.rows.item(i);
          const message: Message = JSON.parse(row.message);
          messages.push(message);
        }

        console.info("[SqliteDBService][getMessagesByTag] messages to return = ", messages);
        response.next(messages);
      }, (error) => {
        console.error("[SqliteDBService][getMessagesByTag] error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getMessagesStarred(): Observable<Message[]> {
    console.info(`[SqliteDBService][getMessagesStarred]`);
    const response = new Subject<Message[]>();

    this.getDB().then((db) => {
      db.executeSql(FETCH_MESSAGES_STARRED_SQL, [], (result) => {
        console.info("[SqliteDBService][getMessagesStarred] result = ", result);

        const messages = [];
        for (let i = 0; i < result.rows.length; i++) {
          const row = result.rows.item(i);
          const message: Message = JSON.parse(row.message);
          messages.push(message);
        }

        console.info("[SqliteDBService][getMessagesStarred] messages to return = ", messages);
        response.next(messages);
      }, (error) => {
        console.error("[SqliteDBService][getMessagesStarred] error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getMessagesSent(): Observable<Message[]> {
    return this.getMessagesByFolder("in:sent");
  }

  updateMessages(messages: Message[]): Observable<any> {
    console.info(`[SqliteDBService][updateMessages]`, messages);
    const response = new Subject<any>();

    this.getDB().then(db => {

      // 1. Fetch messages by ids
      const msgIds = messages.map(m => m.id);
      const query = FETCH_MESSAGES_BY_IDS_SQL + "('" + msgIds.join("','") + "')";
      console.info(`[SqliteDBService][updateMessages] query`, query);

      db.executeSql(query, [], (result) => {
        console.info("[SqliteDBService][updateMessages] FETCH_MESSAGES_BY_IDS_SQL result = ", result);

        const existingMessagesMap = {};
        for (let i = 0; i < result.rows.length; i++) {
          const row = result.rows.item(i);
          row.parsedMessage = JSON.parse(row.message);
          existingMessagesMap[row.parsedMessage.id] = row; // mapping message from database
        }

        console.info(`[SqliteDBService][updateMessages] existingMessagesMap`, existingMessagesMap);

        // 2. Update
        let updatedMessagesCount = 0;

        messages.forEach(message => { // message from backend
          const messageFromDb = existingMessagesMap[message.id];
          console.info(`[SqliteDBService][updateMessages] new message`, message);
          if (messageFromDb) {
            // keep 'message.mp' value
            const existingMessage: Message = messageFromDb.parsedMessage;
            if (messageFromDb.mp && !message.mp) {
              try {
                console.info(`[SqliteDBService][updateMessages] set mp from database for message ${existingMessage.id}`);
                message.mp = JSON.parse(messageFromDb.mp);
              } catch (error) {
                console.info(`[SqliteDBService][updateMessages] cannot parse mp for messsage ${existingMessage.id}`);
              }
            }
            if (existingMessage && existingMessage.l && !message.l) {
              message.l = existingMessage.l;
            }
            if (existingMessage && existingMessage.e && !message.e) {
              message.e = existingMessage.e;
            }
            if (existingMessage && existingMessage.d && !message.d) {
              message.d = existingMessage.d;
            }

            const tags = message.tags ? message.tags.join(", ") : "";
            const query = MailUtils.getQueryByFolderId(+message.l);
            const stringMessage = JSON.stringify(message);
            const isMsgStarred = message.f && message.f.includes("f");
            const isStarredInt = isMsgStarred ? 1 : 0;
            const flag = message.f || "";
            const e = message.e ? JSON.stringify(message.e) : "";
            const mp = message.mp ? JSON.stringify(message.mp) : "";
            const folder = message.l || "";
            let params = [stringMessage, folder, tags, message.d, isStarredInt, e, flag, query, mp, new Date().getTime()];
            let sql = "";
            if (message.cid) {
              sql = UPDATE_MESSAGE_WITH_CID_SQL;
              params = [...params, ...[message.cid, message.id]];
            } else {
              sql = `${UPDATE_MESSAGE_SQL} WHERE id = ?`;
              params = [...params, ...[message.id]];
            }

            console.info(`[SqliteDBService][updateMessages] UPDATE, params`, sql, params);
            db.executeSql(sql, params, (result) => {
              console.info(`[SqliteDBService][updateMessages] UPDATE_MESSAGE_SQL ${message.id} result = `, result);
              updatedMessagesCount++;

              if (updatedMessagesCount === messages.length) {
                this.setTagsForMessages(messages);
                response.next(true);
              }
            }, (error) => {
              console.error("[SqliteDBService][updateMessages] UPDATE_MESSAGE_SQL error = ", error);
              response.error(error);
            });
          }

        });

      }, (error) => {
        console.error("[SqliteDBService][updateMessages] FETCH_MESSAGES_BY_IDS_SQL error = ", error);
        response.error(error);
      });

    });

    return response.asObservable().pipe(take(1));
  }

  updateMessagesAsStarred(messages: Message[], isStared: boolean): Observable<any> {
    console.info(`[SqliteDBService][updateMessagesAsStarred] isStarred = ${isStared}, messages =`, messages);
    const response = new Subject<any>();

    this.getDB().then(db => {
      let updatedMessagesCount = 0;

      messages.forEach(message => {
        const isStarredInt = isStared ? 1 : 0;
        const stringMessage = JSON.stringify(message);
        const md5 = MailUtils.md5(stringMessage);
        db.executeSql(UPDATE_MESSAGES_STARS_SQL, [stringMessage, md5, message.f, isStarredInt, message.id], (result) => {
          console.info("[SqliteDBService][updateMessagesAsStarred] UPDATE_MESSAGES_STARS_SQL result = ", result, message.id, isStared);
          updatedMessagesCount++;

          if (updatedMessagesCount === messages.length) {
            response.next(true);
          }
        }, (error) => {
          console.error("[SqliteDBService][updateMessagesAsStarred] UPDATE_MESSAGES_STARS_SQL error = ", error);
          response.error(error);
        });
      });
    });

    return response.asObservable().pipe(take(1));
  }

  moveMessagesBetweenFolders(ids: string[], newFolder: string): Observable<any> {
    console.info(`[SqliteDBService][moveMessagesBetweenFolders] messagesId = ${ids}, newFolder = ${newFolder}, `);
    const response = new Subject<any>();

    this.getDB().then(db => {
      const query = FETCH_MESSAGES_BY_IDS_SQL + "('" + ids.join("','") + "')";

      db.executeSql(query, [], (result) => {
        console.info("[SqliteDBService][moveMessagesBetweenFolders] FETCH_MESSAGES_BY_IDS_SQL result = ", result);
        const existMsgsCount = result.rows.length;
        let existMsgsIds = [];

        if (result.rows.length > 0) {
          for (let i = 0; i < result.rows.length; i++) {
            const row = result.rows.item(i);
            const forUpdateMsg: Message = JSON.parse(row.message);
            if (newFolder.indexOf("in:") > -1) {
              forUpdateMsg.l = MailUtils.getFolderId(newFolder).toString();
            } else if (newFolder.indexOf("inid:") > -1) {
              forUpdateMsg.l = newFolder.split("\"")[1];
            } else {
              forUpdateMsg.l = newFolder;
            }
            console.log("[SqliteDBService][moveMessagesBetweenFolders] newFolder: ", newFolder, forUpdateMsg.l);
            const messageString = JSON.stringify(forUpdateMsg);
            const query = MailUtils.getQueryByFolderId(+newFolder);
            console.log("[SqliteDBService][moveMessagesBetweenFolders] query: ", query, forUpdateMsg.l);
            db.executeSql(UPDATE_MESSAGES_FOLDER_SQL, [forUpdateMsg.l, messageString, query, forUpdateMsg.id], (result) => {
              console.info("[SqliteDBService][moveMessagesBetweenFolders] UPDATE_MESSAGE_FOLDER_SQL result = ", result);
              existMsgsIds.push(row.id);

              if (existMsgsCount === existMsgsIds.length) {
                response.next(true);
              }
            }, (err) => {
              console.error("[SqliteDBService][moveMessagesBetweenFolders] UPDATE_MESSAGE_FOLDER_SQL error = ", err);
              response.error(err);
            });
          }
        } else {
          response.next(false);
        }
      }, (error) => {
        console.error("[SqliteDBService][moveMessagesBetweenFolders] FETCH_MESSAGES_BY_IDS_SQL error = ", error);
        response.error(error);
      });
    }).catch(error => {
      console.error("[SqliteDBService][moveMessagesBetweenFolders] request DB error = ", error);
      response.error(error);
    });

    return response.asObservable().pipe(take(1));
  }

  deleteMessage(messageId: string): Observable<any> {
    console.info(`[SqliteDBService][deleteMessage] messageId = ${messageId}`);

    const response = new Subject<any>();

    this.getDB().then((db) => {
      db.executeSql(DELETE_MESSAGE_SQL, [messageId], (result) => {
        console.info("[SqliteDBService][deleteMessage] DELETE_MESSAGE_SQL result = ", result);
        response.next(result);
      }, (error) => {
        console.error("[SqliteDBService][deleteMessage] DELETE_MESSAGE_SQL error = ", error);
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  deleteMessages(ids: string[]): Observable<any> {
    console.info(`[SqliteDBService][deleteMessages] ids = ${ids}`);

    const response = new Subject<any>();

    this.getDB().then((db) => {
      const query = DELETE_MESSAGES_SQL + "('" + ids.join("','") + "')";
      db.executeSql(query, [], (result) => {
        console.info("[SqliteDBService][deleteMessages] DELETE_MESSAGES_SQL result = ", result);
        response.next(result);
      }, (error) => {
        console.error("[SqliteDBService][deleteMessages] DELETE_MESSAGES_SQL error = ", error);
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  deleteAppointments(ids: string[], type?: string): Observable<any> {
    console.info(`[SqliteDBService][deleteAppointments] ids = ${ids}`);

    const response = new Subject<any>();

    this.getDB().then((db) => {
      const query = DELETE_APPOINTMENTS_SQL + "('" + ids.join("','") + "')";
      db.executeSql(query, [], (result) => {
        console.info("[SqliteDBService][deleteAppointments] DELETE_APPOINTMENTS_SQL result = ", result);
        response.next(result);
      }, (error) => {
        console.error("[SqliteDBService][deleteAppointments] DELETE_APPOINTMENTS_SQL error = ", error);
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  deleteAttachment(ids: string[], type?: string): Observable<any> {
    console.info(`[SqliteDBService][deleteAttachment] ids = ${ids}`);

    const response = new Subject<any>();

    this.getDB().then((db) => {
      const query = DELETE_ATTACHMENTS_SQL + "('" + ids.join("','") + "')";
      db.executeSql(query, [], (result) => {
        console.info("[SqliteDBService][deleteAttachment] DELETE_ATTACHMENTS_SQL result = ", result);
        response.next(result);
      }, (error) => {
        console.error("[SqliteDBService][deleteAttachment] DELETE_ATTACHMENTS_SQL error = ", error);
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  addConversations(conversations: Conversation[]): Observable<any> {
    console.info(`[SqliteDBService][addConversations]`, conversations);
    const response = new Subject<any>();

    if (!(conversations && conversations.length)) {
      response.next(true);
    } else {
      const convIds = conversations.map(conversation => conversation.id);
      let convSqlIds = [];
      for (let i = 0; i < convIds.length; i++) {
        if (convIds[i].startsWith("-")) {
          convSqlIds.push(convIds[i]);
          convSqlIds.push(convIds[i].slice(1));
        } else {
          convSqlIds.push(convIds[i]);
          convSqlIds.push("-" + convIds[i]);
        }
      }
      const convMap = conversations.reduce((result, item) => {
        if (!result[item.id]) {
          result[item.id] = item;
        }
        return result;
      }, {} as { [key: string]: Conversation });

      this.getDB().then(db => {
        const query = FETCH_CONVERSATIONS_BY_IDS_SQL + "('" + convSqlIds.join("','") + "')";
        db.executeSql(query, [], (result) => {
          console.info("[SqliteDBService][addConversations] FETCH_CONVERSATIONS_BY_IDS_SQL in " + convSqlIds.join("','") + " - result: ", result.rows.length);
          const existConvCount = result.rows.length;
          let existConvIds = [];

          if (existConvCount > 0) {
            for (let i = 0; i < result.rows.length; i++) {
              const row = result.rows.item(i);
              const md5Exist = row.md5;
              const forUpdateConv = convMap[row.id];
              const stringConv = JSON.stringify(forUpdateConv);
              const md5New = MailUtils.md5(stringConv);

              if (md5Exist === md5New) {
                console.info(`[SqliteDBService][addConversations] SKIP IDENTICAL conversations ${forUpdateConv.id}`);
                existConvIds.push(row.id);

                if (existConvCount === existConvIds.length) {
                  if (conversations.length - existConvCount > 0) {
                    let totalConv = conversations.length - existConvCount;
                    let processedConv = 0;

                    Object.keys(convMap).forEach((id) => {
                      if (!existConvIds.includes(id)) {
                        const forCreateConv = convMap[id];
                        const stringConv = JSON.stringify(forCreateConv);
                        const md5 = MailUtils.md5(stringConv);
                        const tags = forCreateConv.tags ? forCreateConv.tags.join(", ") : "";
                        const isConvStarred = forCreateConv.f && forCreateConv.f.includes("f");
                        const flag = forCreateConv.f  || "";
                        const folder = forCreateConv.l  || "2";
                        const isStarredInt = isConvStarred ? 1 : 0;
                        db.executeSql(CREATE_CONVERSATION_SQL, [forCreateConv.id, stringConv, forCreateConv.d, forCreateConv.query, folder, flag, tags, md5, isStarredInt], (result) => {
                          console.info("[SqliteDBService][addConversations] INSERT conversation success, id = ", forCreateConv.id);

                          processedConv++;

                          if (totalConv === processedConv) {
                            this.setTagsForConversations(conversations);
                            response.next(true);
                          }
                        }, (error) => {
                          console.error(`[SqliteDBService][addConversations] INSERT conversation error, id = ${forCreateConv.id}`, error);
                          response.error(error);
                        });
                      }
                    });
                  } else {
                    this.setTagsForConversations(conversations);
                    response.next(true);
                  }
                }
              } else {
                const isConvStarred = forUpdateConv.f && forUpdateConv.f.includes("f");
                const flag = forUpdateConv.f  || "";
                const folder = forUpdateConv.l  || "2";
                const isStarredInt = isConvStarred ? 1 : 0;
                const tags = forUpdateConv.tags ? forUpdateConv.tags.join(", ") : "";
                const params = [stringConv, forUpdateConv.query, folder, flag, tags, md5New, forUpdateConv.d, isStarredInt, forUpdateConv.id];
                db.executeSql(UPDATE_CONVERSATION_SQL, params, (result2) => {
                  console.info("[SqliteDBService][addConversations] UPDATE_CONVERSATION_SQL result = ", result2);
                  existConvIds.push(row.id);

                  if (existConvCount === existConvIds.length) {
                    if (conversations.length - existConvCount > 0) {
                      let totalConv = conversations.length - existConvCount;
                      let processedConv = 0;

                      Object.keys(convMap).forEach((id) => {
                        if (!existConvIds.includes(id)) {
                          const forCreateConv = convMap[id];
                          const stringConv = JSON.stringify(forCreateConv);
                          const md5 = MailUtils.md5(stringConv);
                          const isConvStarred = forCreateConv.f && forCreateConv.f.includes("f");
                          const isStarredInt = isConvStarred ? 1 : 0;
                          const flag = forCreateConv.f  || "";
                          const folder = forCreateConv.l  || "2";
                          const tags = forCreateConv.tags ? forCreateConv.tags.join(", ") : "";
                          db.executeSql(CREATE_CONVERSATION_SQL, [forCreateConv.id, stringConv, forCreateConv.d, forCreateConv.query, folder, flag, tags, md5, isStarredInt], (result) => {
                            console.info("[SqliteDBService][addConversations] INSERT conversation success, id = ", forCreateConv.id);

                            processedConv++;

                            if (totalConv === processedConv) {
                              this.setTagsForConversations(conversations);
                              response.next(true);
                            }
                          }, (error) => {
                            console.error(`[SqliteDBService][addConversations] INSERT conversation error2, id = ${forCreateConv.id}`, error);
                            response.error(error);
                          });
                        }
                      });
                    } else {
                      this.setTagsForConversations(conversations);
                      response.next(true);
                    }
                  }
                }, (error) => {
                  console.error("[SqliteDBService][addConversations] UPDATE_CONVERSATION_SQL error = ", error, UPDATE_CONVERSATION_SQL, params);
                  response.error(error);
                });
              }
            }
          } else {
            let totalConv = conversations.length;
            let processedConv = 0;

            Object.keys(convMap).forEach((id) => {
              const forCreateConv = convMap[id];
              const stringConv = JSON.stringify(forCreateConv);
              const md5 = MailUtils.md5(stringConv);
              const isConvStarred = forCreateConv.f && forCreateConv.f.includes("f");
              const isStarredInt = isConvStarred ? 1 : 0;
              const flag = forCreateConv.f  || "";
              const folder = forCreateConv.l  || "2";
              const tags = forCreateConv.tags ? forCreateConv.tags.join(", ") : "";
              db.executeSql(CREATE_CONVERSATION_SQL, [forCreateConv.id, stringConv, forCreateConv.d, forCreateConv.query, folder, flag, tags, md5, isStarredInt], (result) => {
                console.info("[SqliteDBService][addConversations] INSERT conversation success, id = ", forCreateConv.id);
                processedConv++;

                if (totalConv === processedConv) {
                  this.setTagsForConversations(conversations);
                  response.next(true);
                }
              }, (error) => {
                console.error(`[SqliteDBService][addConversations] INSERT conversations error2, id = ${forCreateConv.id}`, error);
                response.error(error);
              });
            });
          }
        }, (error) => {
          console.error("[SqliteDBService][addConversations] FETCH_CONVERSATIONS_BY_IDS_SQL error = ", error);
          response.error(error);
        });
      });
    }

    return response.asObservable().pipe(take(1));
  }

  private setTagsForConversation(conversation: Conversation) {
    console.info("[SqliteDBService][setTagsForConversation]", conversation);
    this.getDB().then(db => {
      db.executeSql(DELETE_TAGS_BY_CONVERSATION_SQL, [conversation.id], (result) => {
        console.info("[SqliteDBService][setTagsForConversation] DELETE_TAGS_BY_CONVERSATION_SQL result = ", result);
        if (conversation.tags && conversation.tags.length > 0) {
          for (let tag of conversation.tags) {
            this.getDB().then((db) => {
              db.executeSql(ADD_CONVERSATION_TAG_SQL, [conversation.id, tag], (result2) => {
                console.info("[SqliteDBService][setTagsForConversation] insert tags after delete result = ", result2);
              }, (error) => {
                console.error("[SqliteDBService][setTagsForConversation] insert tags after delete error = ", error);
              });
            });
          }
        }
      }, (error) => {
        console.error("[SqliteDBService][setTagsForConversation] DELETE_TAGS_BY_CONVERSATION_SQL error = ", error);
        if (conversation.tags && conversation.tags.length > 0) {
          for (let tag of conversation.tags) {
            this.getDB().then((db) => {
              db.executeSql(ADD_CONVERSATION_TAG_SQL, [conversation.id, tag], (result) => {
                console.info("[SqliteDBService][setTagsForConversation] insert tag result = ", result);
              }, (error) => {
                console.error("[SqliteDBService][setTagsForConversation] insert tag error = ", error);
              });
            });
          }
        }
      });
    }).catch(error => {
      console.error("[SqliteDBService][setTagsForConversation] getting DB error = ", error);
    });
  }

  private addTagsForConversation(conversation: Conversation) {
    console.info("[SqliteDBService][addTagsForConversation]");
    if (conversation.tags && conversation.tags.length > 0) {
      for (let tag of conversation.tags) {
        this.getDB().then((db) => {
          db.executeSql(ADD_CONVERSATION_TAG_SQL, [conversation.id, tag], (result) => {
            console.info("[SqliteDBService][addTagsForConversation] insert tag result = ", result);
          }, (error) => {
            console.error("[SqliteDBService][addTagsForConversation] insert tag error = ", error);
          });
        });
      }
    }
  }

  private setTagsForConversations(conversations: Conversation[]) {
    console.info("[SqliteDBService][setTagsForConversations] conversations = ", conversations);
    const convMap = conversations.reduce((result, item) => {
      if (!result[item.id]) {
        result[item.id] = item;
      }
      return result;
    }, {} as { [key: string]: Conversation });

    const convIds = Object.keys(convMap);
    if (convIds.length > 0) {
      this.getDB().then(db => {
        const query = DELETE_CONVERSATIONS_TAGS_MULTIPLE_SQL + "('" + convIds.join("','") + "')";
        db.executeSql(query, [], (result) => {
          console.info("[SqliteDBService][setTagsForConversations] DELETE_TAGS_MULTIPLE_SQL result", result);

          conversations.forEach(conversation => {
            if (conversation.tags && conversation.tags.length > 0) {
              for (let tag of conversation.tags) {
                this.getDB().then((db) => {
                  db.executeSql(ADD_CONVERSATION_TAG_SQL, [conversation.id, tag], (result) => {
                    console.info("[SqliteDBService][setTagsForConversations] insert tag result = ", result);
                  }, (error) => {
                    console.error("[SqliteDBService][setTagsForConversations] insert tag error = ", error);
                  });
                });
              }
            }
          });
        }, (error) => {
          console.error("[SqliteDBService][setTagsForConversations] DELETE_TAGS_MULTIPLE_SQL error = ", error);
          conversations.forEach(conversation => {
            if (conversation.tags && conversation.tags.length > 0) {
              for (let tag of conversation.tags) {
                this.getDB().then((db) => {
                  db.executeSql(ADD_CONVERSATION_TAG_SQL, [conversation.id, tag], (result) => {
                    console.info("[SqliteDBService][setTagsForConversations] insert tag result = ", result);
                  }, (error) => {
                    console.error("[SqliteDBService][setTagsForConversations] insert tag error = ", error);
                  });
                });
              }
            }
          });
        });
      }).catch(error => {
        console.error("[SqliteDBService][setTagsForConversations] getting DB error = ", error);
      });
    }
  }

  getConversationById(id: string): Observable<Conversation> {
    console.info(`[SqliteDBService][getConversationById] id = ${id}`);
    const response = new Subject<Conversation>();

    this.getDB().then((db) => {
      db.executeSql(FETCH_CONVERSATION_BY_ID_SQL, [id], (result) => {
        console.info("[SqliteDBService][getConversationById] result = ", result);
        if (result.rows.length > 0) {
          const row = result.rows.item(0);
          const conversation: Conversation = JSON.parse(row.conversation);
          console.info("[SqliteDBService][getConversationById] conversation to return = ", conversation);
          response.next(conversation);
        } else {
          response.next(null);
        }
      }, (error) => {
        console.error("[SqliteDBService][getConversationById] error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getConversationsByFolder(folderName: string): Observable<Conversation[]> {
    console.info(`[SqliteDBService][getConversationsByFolder] folderName = ${folderName}`);

    const response = new Subject<Conversation[]>();

    this.getDB().then((db) => {
      console.info(`[SqliteDBService][getConversationsByFolder] db ready`);
      db.executeSql(FETCH_CONVERSATIONS_FROM_FOLDER_SQL, [folderName, folderName], (result) => {
        console.info("[SqliteDBService][getConversationsByFolder] result = ", result);
        if (result.rows.length > 0) {
          const conversations = [];

          for (let i = 0; i < result.rows.length; i++) {
            const item = result.rows.item(i);
            const conversation: Conversation = JSON.parse(item.conversation);
            conversations.push(conversation);
          }

          console.info("[SqliteDBService][getConversationsByFolder] conversations to return = ", conversations);
          response.next(conversations);
        } else {
          response.next([]);
        }
      }, (error) => {
        console.error("[SqliteDBService][getConversationsByFolder] error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getAllAppointments(): Observable<any[]> {
    console.info(`[SqliteDBService][getAllAppointments] `);

    const response = new Subject<any[]>();

    this.getDB().then((db) => {
      console.info(`[SqliteDBService][getAllAppointments] db ready`);
      db.executeSql(FETCH_ALL_APPOINTMENTS, [], (result) => {
        console.info("[SqliteDBService][getAllAppointments] result = ", result);
        if (result.rows.length > 0) {
          const appointments = [];

          for (let i = 0; i < result.rows.length; i++) {
            const item = result.rows.item(i);
            const appointment: any
             = JSON.parse(item.appointment);
            appointments.push(appointment);
          }

          console.info("[SqliteDBService][getAllAppointments] appointments to return = ", appointments);
          response.next(appointments);
        } else {
          response.next([]);
        }
      }, (error) => {
        console.error("[SqliteDBService][getAllAppointments] error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getConversationsByTag(tagName: string[]): Observable<Conversation[]> {
    console.info(`[SqliteDBService][getConversationsByTag] tagName = ${tagName}`);
    const query = FETCH_CONVERSATIONS_BY_TAGS_SQL + "('" + tagName.join("','") + "') ORDER BY date DESC";
    console.info("[SqliteDBService][getConversationsByTag] query = ", query);

    const response = new Subject<Conversation[]>();

    this.getDB().then((db) => {
      db.executeSql(query, [], (result) => {
        console.info("[SqliteDBService][getConversationsByTag] result = ", result);
        if (result.rows.length > 0) {
          const conversations = [];

          for (let i = 0; i < result.rows.length; i++) {
            const item = result.rows.item(i);
            const conversation: Conversation = JSON.parse(item.conversation);
            conversations.push(conversation);
          }

          console.info("[SqliteDBService][getConversationsByFolder] conversations to return = ", conversations);
          response.next(conversations);
        } else {
          response.next([]);
        }
      }, (error) => {
        console.error("[SqliteDBService][getConversationsByTag] error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getConversationsStarred(): Observable<Conversation[]> {
    console.info(`[SqliteDBService][getConversationsStarred]`);
    const response = new Subject<Conversation[]>();

    this.getDB().then((db) => {
      db.executeSql(FETCH_CONVERSATIONS_STARRED_SQL, [], (result) => {
        console.info("[SqliteDBService][getConversationsStarred] result = ", result);

        const conversations = [];
        if (result.rows.length > 0) {
          for (let i = 0; i < result.rows.length; i++) {
            const item = result.rows.item(i);
            const conversation: Conversation = JSON.parse(item.conversation);
            conversations.push(conversation);
          }
        }

        console.info("[SqliteDBService][getConversationsStarred] conversations to return = ", conversations);
        response.next(conversations);
      }, (error) => {
        console.error("[SqliteDBService][getConversationsStarred] error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getConversationsSent(): Observable<Conversation[]> {
    return this.getConversationsByFolder("in:sent");
  }

  updateConversations(convs: Conversation[]): Observable<any> {
    console.info(`[SqliteDBService][updateConversations] conversations = 1`, convs);
    const response = new Subject<any>();

    this.getDB().then(db => {
      let updatedMessagesCount = 0;

      convs.forEach(conv => {
        const stringConv = JSON.stringify(conv);
        const md5 = MailUtils.md5(stringConv);
        const isConvStarred = conv.f && conv.f.includes("f");
        const isStarredInt = isConvStarred ? 1 : 0;
        const flag = conv.f  || "";
        const folder = conv.l  || "2";
        const tags = conv.tags ? conv.tags.join(", ") : "";
        db.executeSql(UPDATE_CONVERSATION_SQL, [stringConv, conv.query, folder, flag, tags, md5, conv.d, isStarredInt, conv.id], (result) => {
          console.info("[SqliteDBService][updateConversations] UPDATE_CONVERSATION_SQL result = ", result);
          updatedMessagesCount++;

          if (updatedMessagesCount === convs.length) {
            this.setTagsForConversations(convs);
            response.next(true);
          }
        }, (error) => {
          console.error("[SqliteDBService][updateConversations] UPDATE_CONVERSATION_SQL error = ", error);
          response.error(error);
        });
      });
    });

    return response.asObservable().pipe(take(1));
  }

  updateConversationsAsStarred(convs: Conversation[], isStared: boolean): Observable<any> {
    console.info(`[SqliteDBService][updateConversationsAsStarred] isStarred = ${isStared}, convs =`, convs);
    const response = new Subject<any>();

    this.getDB().then(db => {
      let updatedMessagesCount = 0;

      convs.forEach(conv => {
        const isStarredInt = isStared ? 1 : 0;
        const stringConv = JSON.stringify(conv);
        const md5 = MailUtils.md5(stringConv);
        db.executeSql(UPDATE_CONVERSATIONS_STARS_SQL, [stringConv, md5, isStarredInt, conv.id], (result) => {
          console.info("[SqliteDBService][updateConversationsAsStarred] UPDATE_CONVERSATIONS_STARS_SQL result = ", result, conv.id, isStared);
          updatedMessagesCount++;

          if (updatedMessagesCount === convs.length) {
            response.next(true);
          }
        }, (error) => {
          console.error("[SqliteDBService][updateConversationsAsStarred] UPDATE_CONVERSATIONS_STARS_SQL error = ", error);
          response.error(error);
        });
      });
    });

    return response.asObservable().pipe(take(1));
  }

  addMessageToConversation(convId: string, message: Message): Observable<any> {
    console.info(`[SqliteDBService][addMessageToConversation] convId = ${convId}, message = ${JSON.stringify(message)}`);
    const response = new Subject<any>();
    this.getDB().then(db => {
      db.executeSql(FETCH_CONVERSATION_BY_ID_SQL, [convId], (result) => {
        console.info("[SqliteDBService][addMessageToConversation] FETCH_CONVERSATION_BY_ID_SQL result = ", result);
        const existConvsCount = result.rows.length;
        if (existConvsCount > 0) {
          const row = result.rows.item(0);
          const existConv: Conversation = JSON.parse(row.conversation);
          existConv.m.unshift(message);
          existConv.n = existConv.m.length.toString();

          this.updateConversations([existConv]).subscribe((result2) => {
            console.info("[SqliteDBService][addMessageToConversation] update conversation result = ", result2);
            response.next(true);
          }, (error) => {
            console.error("[SqliteDBService][addMessageToConversation] update conversation error = ", error);
            response.error(error);
          });
        } else {
          response.next(true);
        }
      }, (error) => {
        console.error("[SqliteDBService][addMessageToConversation] FETCH_CONVERSATION_BY_ID_SQL error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  moveConversationsBetweenFolders(ids: string[], newFolder: string): Observable<any> {
    console.info(`[SqliteDBService][moveMessagesBetweenFolders] conversationsIds = ${ids}, newFolder = ${newFolder}, `);
    const response = new Subject<any>();

    this.getDB().then(db => {
      const query = FETCH_CONVERSATIONS_BY_IDS_SQL + "('" + ids.join("','") + "')";

      db.executeSql(query, [], (result) => {
        console.info("[SqliteDBService][moveConversationsBetweenFolders] FETCH_CONVERSATIONS_BY_IDS_SQL result = ", result);
        const existConvsCount = result.rows.length;
        let existConvsIds = [];

        for (let i = 0; i < result.rows.length; i++) {
          const row = result.rows.item(i);
          const forUpdateConv: Conversation = JSON.parse(row.conversation);
          if (newFolder.indexOf("in:") > -1) {
            forUpdateConv.l = MailUtils.getFolderId(newFolder).toString();
          } else if (newFolder.indexOf("inid:") > -1) {
            forUpdateConv.l = newFolder.split("\"")[1];
          } else {
            forUpdateConv.l = newFolder;
          }
          const convString = JSON.stringify(forUpdateConv);
          // const md5 = MailUtils.md5(convString);
          const query = MailUtils.getQueryByFolderId(+forUpdateConv.l);
          console.info("[SqliteDBService][moveConversationsBetweenFolders] id, folder, query: ", forUpdateConv.id, forUpdateConv.l, query);

          db.executeSql(UPDATE_CONVERSATIONS_FOLDER_SQL, [forUpdateConv.l, query, convString, md5, forUpdateConv.id], (result2) => {
            console.info("[SqliteDBService][moveConversationsBetweenFolders] UPDATE_CONVERSATION_FOLDER_SQL result = ", result2);
            existConvsIds.push(row.id);

            if (existConvsCount === existConvsIds.length) {
              response.next(true);
            }
          }, (err) => {
            console.error("[SqliteDBService][moveConversationsBetweenFolders] UPDATE_CONVERSATION_FOLDER_SQL error = ", err);
            response.error(err);
          });
        }
      }, (error) => {
        console.error("[SqliteDBService][moveConversationsBetweenFolders] FETCH_CONVERSATIONS_BY_IDS_SQL error = ", error);
        response.error(error);
      });
    }).catch(error => {
      console.error("[SqliteDBService][moveConversationsBetweenFolders] request DB error = ", error);
      response.error(error);
    });

    return response.asObservable().pipe(take(1));
  }

  deleteConversation(conversationId: string, keepMessages?: boolean): Observable<any> {
    console.info(`[SqliteDBService][deleteConversation]`, conversationId, keepMessages);

    const response = new Subject<any>();

    this.getDB().then((db) => {
      db.executeSql(DELETE_CONVERSATION_SQL, [conversationId], (result) => {
        console.info("[SqliteDBService][deleteConversation] DELETE_CONVERSATIONS_SQL result = ", result);

        if (!keepMessages) {
          db.executeSql(DELETE_MESSAGES_BY_CONVERSATION_SQL, [conversationId], (result2) => {
            console.info("[SqliteDBService][deleteConversation] DELETE_MESSAGES_BY_CONVERSATION_SQL result = ", result2);
            response.next(true);
          }, (error) => {
            console.error("[SqliteDBService][deleteConversation] DELETE_MESSAGES_BY_CONVERSATION_SQL error = ", error);
            response.error(error);
          });
        } else {
          response.next(true);
        }
      }, (error) => {
        console.error("[SqliteDBService][deleteConversation] DELETE_CONVERSATIONS_SQL error = ", error);
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  deleteConversations(ids: string[], keepMessages?: boolean): Observable<any> {
    console.info(`[SqliteDBService][deleteConversations]`, ids, keepMessages);

    const response = new Subject<any>();

    if (!(ids && ids.length)) {
      response.next(true);
    } else {
      this.getDB().then((db) => {
        const query = DELETE_CONVERSATIONS_SQL + "('" + ids.join("','") + "')";
        db.executeSql(query, [], (result) => {
          console.info("[SqliteDBService][deleteConversations] DELETE_CONVERSATIONS_SQL result = ", result);

          if (!keepMessages) {
            const query2 = DELETE_MESSAGES_BY_CONVERSATIONS_SQL + "('" + ids.join("','") + "')";
            db.executeSql(query2, [], (result2) => {
              console.info("[SqliteDBService][deleteConversations] DELETE_MESSAGES_BY_CONVERSATION_MULTIPLE_SQL result = ", result2);
              response.next(true);
            }, (error) => {
              console.error("[SqliteDBService][deleteConversations] DELETE_MESSAGES_BY_CONVERSATION_MULTIPLE_SQL error = ", error);
              response.error(error);
            });
          } else {
            response.next(true);
          }
        }, (error) => {
          console.error("[SqliteDBService][deleteConversations] DELETE_CONVERSATIONS_SQL error = ", error);
          response.error(error);
        });
      });
    }

    return response.asObservable().pipe(take(1));
  }

  addPendingOperation(objectId: string, op: string, request: any): Observable<any> {
    const id = this.getPendingOperationKey(objectId, op);

    console.info(`[SqliteDBService][addPendingOperation] id = ${id}, op = ${op}, request = ${JSON.stringify(request)}`);

    const response = new Subject<any>();

    this.getDB().then((db) => {
      db.executeSql(FETCH_PENDING_OPERATION_BY_ID_SQL, [id], (result) => {
        console.info("[SqliteDBService][addPendingOperation] FETCH_PENDING_OPERATION_BY_ID_SQL result = ", result);
        if (result.rows.length > 0) {
          db.executeSql(UPDATE_PENDING_OPPERATION_SQL, [op, JSON.stringify(request), id], (result2) => {
            console.info("[SqliteDBService][addPendingOperation] UPDATE_PENDING_OPPERATION_SQL result = ", result2);
            response.next(result);
          }, (err) => {
            console.error("[SqliteDBService][addPendingOperation] UPDATE_PENDING_OPPERATION_SQL error = ", err);
            response.error(err);
          });
        } else {
          db.executeSql(CREATE_PENDING_OPERATION_SQL, [id, op, JSON.stringify(request)], (result3) => {
            console.info("[SqliteDBService][addPendingOperation] CREATE_PENDING_OPERATION_SQL result = ", result3);

            // delete prev draft if any
            if (op === "sendEmail") {
              this.deletePendingOperation(this.getPendingOperationKey(objectId, "saveDraft")).subscribe();
            }

            response.next(result);
          }, (err) => {
            console.error("[SqliteDBService][addPendingOperation] CREATE_PENDING_OPERATION_SQL error = ", err);
            response.error(err);
          });
        }
      }, (err) => {
        console.error("[SqliteDBService][addPendingOperation] FETCH_PENDING_OPERATION_BY_ID_SQL error = ", err);
        response.error(err);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  private _getAllPendingOperations(): Observable<any> {
    console.info("[SqliteDBService][_getAllPendingOperations]");

    const response = new Subject<any>();



      this.getDB().then((db) => {
        db.executeSql(FETCH_ALL_PENDING_OPERATION_SQL, [], (result) => {
          console.info("[SqliteDBService][getAllPendingOperations] result = ", result, result.rows);
          const pendingOperations = [];
          for (let i = 0; i < result.rows.length; i++) {
            const row = result.rows.item(i);
            console.info("[SqliteDBService][getAllPendingOperations] row ", i, row);
            const pendingOperation = {
              objectId: row.id.split("_")[0],
              id: row.id,
              op: row.op,
              request: JSON.parse(row.request)
            };
            console.info("[SqliteDBService][getAllPendingOperations] op ", pendingOperation);
            pendingOperations.push({ ...pendingOperation });
          }
          console.info("[SqliteDBService][getAllPendingOperations] returning ", pendingOperations);
          response.next([...pendingOperations]);
        }, (err) => {
          console.error("[SqliteDBService][getAllPendingOperations] err = ", err);
          response.error(err);
        });
      });

    return response.asObservable().pipe(take(1));
  }



  getAllPendingOperations(): Observable<any> {
    console.info("[SqliteDBService][_getAllPendingOperations]");

    const response = new Subject<any>();

      this.getDB().then((db) => {
        db.executeSql(FETCH_ALL_PENDING_OPERATION_SQL, [], (result) => {
          console.info("[SqliteDBService][getAllPendingOperations] result = ", result, result.rows);
          const pendingOperations = [];
          for (let i = 0; i < result.rows.length; i++) {
            const row = result.rows.item(i);
            console.info("[SqliteDBService][getAllPendingOperations] row ", i, row);
            const pendingOperation = {
              objectId: row.id.split("_")[0],
              id: row.id,
              op: row.op,
              request: JSON.parse(row.request)
            };
            console.info("[SqliteDBService][getAllPendingOperations] op ", pendingOperation);
            pendingOperations.push({ ...pendingOperation });
          }
          console.info("[SqliteDBService][getAllPendingOperations] returning ", pendingOperations);
          response.next([...pendingOperations]);
        }, (err) => {
          console.error("[SqliteDBService][getAllPendingOperations] err = ", err);
          response.error(err);
        });
      });

    return response.asObservable().pipe(take(1));
  }

/*
  getAllPendingOperations(): Observable<any> {
    if (environment.isCordova) {
      console.info("[SqliteDBService][getAllPendingOperations] for cordova");
      document.addEventListener("deviceready", () => {
        console.log("[SqliteDBService][getAllPendingOperations] isDeviceReady");
        return this._getAllPendingOperations();
      });
    } else {
      console.log("[SqliteDBService][getAllPendingOperations]");
      return this._getAllPendingOperations();
    }
  }
*/

  deleteAllPendingOperations(): Observable<any> {
    console.info("[SqliteDBService][deleteAllPendingOperations]");

    const response = new Subject<any>();

    this.getDB().then((db) => {
      db.executeSql(DELETE_ALL_PENDING_OPERATION_SQL, [], (result) => {
        console.info("[SqliteDBService][deleteAllPendingOperations] result = ", result);
        response.next(result);
      }, (err) => {
        console.error("[SqliteDBService][deleteAllPendingOperations] err = ", err);
        response.error(err);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  deletePendingOperation(key: string): Observable<any> {
    console.info(`[SqliteDBService][deletePendingOperation] key = ${key}`);
    const response = new Subject<any>();
    this.getDB().then((db) => {
      db.executeSql(DELETE_PENDING_OPERATION_BY_ID_SQL, [key], (result) => {
        console.info("[SqliteDBService][deletePendingOperation] result = ", result);
        response.next(result);
      }, (err) => {
        console.error("[SqliteDBService][deletePendingOperation] err = ", err);
        response.error(err);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  deletePendingOperations(keys: string[]): Observable<any> {
    console.info(`[SqliteDBService][deletePendingOperations] keys = ${keys}`);
    const response = new Subject<any>();

    this.getDB().then((db) => {
      const query = DELETE_PENDING_OPERATIONS_BY_IDS_SQL + "('" + keys.join("','") + "')";
      db.executeSql(query, [], (result) => {
        console.info("[SqliteDBService][deletePendingOperations] result = ", result);
        response.next(result);
      }, (err) => {
        console.error("[SqliteDBService][deletePendingOperations] err = ", err);
        response.error(err);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getPendingOperationKey(objectId: string, op: string) {
    return `${objectId}_${op}`;
  }

  addTags(tags: any[]): Observable<any> {
    console.info("[SqliteDBService][addTags]");
    const response = new Subject<any>();

    if (tags === null || tags.length === 0) {
      response.next(true);
    } else {
      this.getDB().then((db) => {
        const tagsIds = tags.map(tag => tag.id);
        const tagsMap = tags.reduce((result, item) => {
          if (!result[item.id]) {
            result[item.id] = item;
          }
          return result;
        }, {} as { [key: string]: any });

        const query = FETCH_TAGS_BY_IDS_SQL + "('" + tagsIds.join("','") + "')";
        db.executeSql(query, [], (result) => {
          console.info("[SqliteDBService][addTags] FETCH_TAGS_BY_IDS_SQL result = ", result);
          const existTagsCount = result.rows.length;
          let existTagsIds = [];
          if (existTagsCount > 0) {
            console.info(`[SqliteDBService][addTags] first of all update exist ${existTagsCount} tags`);
            for (let i = 0; i < result.rows.length; i++) {
              const row = result.rows.item(i);
              const forUpdateTag = tagsMap[row.id];
              db.executeSql(UPDATE_TAG_SQL, [forUpdateTag.name, forUpdateTag.rgb, forUpdateTag.id], (result2) => {
                console.info("[SqliteDBService][addTags] UPDATE_TAG_SQL result = ", result2);
                existTagsIds.push(row.id);

                if (existTagsCount === existTagsIds.length) {
                  if (tags.length - existTagsCount > 0) {
                    let totalTags = tags.length - existTagsCount;
                    let processedItems = 0;

                    Object.keys(tagsMap).forEach((id) => {
                      if (!existTagsIds.includes(id)) {
                        const forCreateTag = tagsMap[id];

                        db.executeSql(CREATE_TAG_SQL, [forCreateTag.id, forCreateTag.name, forCreateTag.rgb], (result3) => {
                          console.info("[SqliteDBService][addTags] INSERT tag success, id = ", forCreateTag.id);
                          processedItems++;

                          if (totalTags === processedItems) {
                            response.next(true);
                          }
                        }, (error) => {
                          console.error(`[SqliteDBService][addTags] INSERT tag error, id = ${forCreateTag.id}`, error);
                          response.error(error);
                        });
                      }
                    });
                  } else {
                    response.next(true);
                  }
                }
              }, (error) => {
                console.error("[SqliteDBService][addTags] UPDATE_TAG_SQL error = ", error);
                response.error(error);
              });
            }
          } else {
            console.info("[SqliteDBService][addTags] no exist tags, so create them");
            let totalTags = tags.length;
            let processedItems = 0;

            Object.keys(tagsMap).forEach((id) => {
              const forCreateTag = tagsMap[id];

              db.executeSql(CREATE_TAG_SQL, [forCreateTag.id, forCreateTag.name, forCreateTag.rgb], (result) => {
                console.info("[SqliteDBService][addTags] INSERT tag success, id = ", forCreateTag.id);
                processedItems++;

                if (totalTags === processedItems) {
                  response.next(true);
                }
              }, (error) => {
                console.error(`[SqliteDBService][addTags] INSERT tag error, id = ${forCreateTag.id}`, error);
                response.error(error);
              });
            });
          }
        }, (error) => {
          console.error("[SqliteDBService][addTags] FETCH_TAGS_BY_IDS_SQL error = ", error);
          response.error(error);
        });
      }).catch((error) => {
        console.error("[SqliteDBService][addTags] getting DB error = ", error);
        response.error(error);
      });
    }

    return response.asObservable().pipe(take(1));
  }

  getTags(): Observable<any[]> {
    console.info("[SqliteDBService][getTags]");
    const response = new Subject<any[]>();

    this.getDB().then((db) => {
      db.executeSql(FETCH_ALL_TAGS_SQL, [], (result) => {
        console.info("[SqliteDBService][getTags] result = ", result);

        const tags = [];
        for (let i = 0; i < result.rows.length; i++) {
          const row = result.rows.item(i);
          tags.push({ ...row });
        }

        response.next(tags);
      }, (error) => {
        console.error("[SqliteDBService][getTags] error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getCurrentDBUser(): Observable<any> {
    const response = new Subject<any>();
    const email = localStorage.getItem("currentUserEmail");
    response.next(email);

    return response.asObservable().pipe(take(1));
  }

  setCurrentDBUser(email): Observable<any> {
    const response = new Subject<any>();

    console.log("[dumbDBService][setCurrentDBUser]", email);

    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  // AVATAR

  storeAvatar(avatarB64Url: string, email: string): Observable<any> {
    const response = new Subject<any>();
    this.getDB().then((db) => {
      db.executeSql(CREATE_AVATAR_SQL, [email, avatarB64Url], (result) => {
        console.info("[SqliteDBService][storeAvatar] CREATE_AVATAR_SQL result = ", result);
        response.next(true);
      }, (error) => {
        console.error("[SqliteDBService][storeAvatar] CREATE_AVATAR_SQL error = ", error);
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  deleteAvatar(email: string): Observable<any> {
    const response = new Subject<any>();
    this.getDB().then((db) => {
      db.executeSql(DELETE_AVATAR_SQL, [email], (result) => {
        console.info("[SqliteDBService][deleteConversation] DELETE_CONVERSATIONS_SQL result = ", result);
        response.next(true);
      }, (error) => {
        console.error("[SqliteDBService][deleteConversation] DELETE_CONVERSATIONS_SQL error = ", error);
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  getAvatarByEmail(id: string): Observable<any> {
    console.info(`[SqliteDBService][getAvatarByEmail] id = ${id}`);
    const response = new Subject<any>();

    this.getDB().then((db) => {
      db.executeSql(SELECT_AVATAR_BY_ID_SQL, [id], (result) => {
        console.info("[SqliteDBService][getAvatarByEmail] result = ", result);
        if (result.rows.length > 0) {
          const row = result.rows.item(0);
          response.next({id: id, data: row.data});
        } else {
          response.next(null);
        }
      }, (error) => {
        console.error("[SqliteDBService][getAvatarByEmail] error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  fetchAllAvatarFromDatabase(): Observable<any> {
    const response = new Subject<User[]>();
    this.getDB().then((db) => {
      db.executeSql(FETCH_AVATAR_SQL, [], (result) => {
        console.info("[SqliteDBService][fetchAllAvatarFromDatabase] result = ", result);
        const data = [];
        for (let i = 0; i < result.rows.length; i++) {
          const row = result.rows.item(i);
          data.push({id: row.id, data: row.data});
        }
        console.info("[SqliteDBService][fetchAllAvatarFromDatabase] = ", data);
        response.next(data);
      }, (error) => {
        console.error("[SqliteDBService][getUsers] error = ", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getDatabaseVersion() {
    return 0;
  }

  performDatabaseMigration(): Observable<any> {
    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  updateConvMessagesinDB(messages: Array<Message>, toFolderId: any): Observable<any> {
    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  createOrUpdateMessages(messages: Message[]): Observable<any> {
    return this.addMessages(messages);
  }

  addNewMessagesOnly(messages: Message[]): Observable<any> {
    return this.addMessages(messages);
  }

  getInvalidMsgIds() {
    return [];
  }

  clearInvalidMessageIds() {
    return true;
  }

  // ToDo: sqlite implementation
  deleteAllMessagesFromDB(): Observable<any> {
    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }
  deleteAllConvsFromDB(): Observable<any> {
    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  createOrUpdateContacts(contacts: any): Observable<any> {
    const response = new Subject<any>();
    setTimeout(() => {
      response.next(true);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  searchContacts(searchText: string): Observable<any> {
    const response = new Subject<any>();
    const result = [];
    setTimeout(() => {
      response.next(result);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  searchContactsByMail(email: string): Observable<any> {
    const response = new Subject<any>();
    const result = [];
    setTimeout(() => {
      response.next(result);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  fetchAllUsersFromDatabase(email: string): Observable<string> {
    const response = new Subject<any>();
    const result = [];
    setTimeout(() => {
      response.next(result);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  addAttachment(attachment: any): Observable<any> {
    const response = new Subject<any>();
    this.getDB().then(db => {
            let params = [];
            let sql = "";

            db.executeSql(sql, params, (res) => {
              console.info("[SqliteDBService][addMessages] INSERT message success, id = ", res);

                response.next(true);
              }, (error) => {
                console.error(`[SqliteDBService][addMessages] INSERT message error`, error);
                response.error(error);
            });
        });
    return response.asObservable().pipe(take(1));
  }

  searchContactsCalendar(searchText: string): Observable<any> {
    const response = new Subject<any>();
    const result = [];
    setTimeout(() => {
      response.next(result);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

  fetchAttachmentsBefore(ts): Observable<any> {
    const response = new Subject<any>();
    const result = [];
    setTimeout(() => {
      response.next(result);
    }, 10);

    return response.asObservable().pipe(take(1));
  }

}
