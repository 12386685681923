
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="delete-series-confirm-dialog">
    <div class="confirm-header" fxLayout="row" fxLayoutAlign="start center">
        <span>{{ "CALENDARS.DELETE_RECURRING_ITEM_TITLE" | translate }}</span>
    </div>
    <div class="confirm-message" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="center center">
            <span>
                {{ "CALENDARS.DELETE_SERIES_CONTENT_MSG" | translate }}
            </span>
        </div>
        <div class="radio-btns">
            <mat-radio-group [(ngModel)]="deleteType">
                <mat-radio-button value="occurence">
                    <span class="radio-span">{{ "CALENDARS.DELETE_ALL_OCCURENCE_MSG" | translate }}</span>
                </mat-radio-button>
                <mat-radio-button value="instance">
                    <span class="radio-span">{{ "CALENDARS.DELETE_THIS_INSTANCE_FUTURE_OCCURENCE" | translate }}</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="note">
            {{ 'CALENDARS.DELETE_OCCURENCE_NOTE' | translate }}
        </div>
    </div>
    <div class="confirm-btns">
        <button class="yes-btn" mat-button (click)="closeDialog('yes')">{{ 'COMMON.YES' | translate }}</button>
        <button class="no-btn" mat-button (click)="closeDialog('no')">{{ 'COMMON.NO' | translate }}</button>
    </div>
</div>
