
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Subject, Observable, filter, map } from "rxjs";

interface ContactBroadcastEvent {
  key: any;
  data?: any;
}

export class ContactBroadcaster {
  private _eventBus: Subject<ContactBroadcastEvent>;

  constructor() {
    this._eventBus = new Subject<ContactBroadcastEvent>();
  }

  broadcast(key: any, data?: any) {
    console.debug("[Broadcaster.broadcast]", key, data);
    this._eventBus.next({key, data});
  }

  on<T>(key: any): Observable<T> {
    return this._eventBus.asObservable()
      .pipe(filter(event => event.key === key)
      , map(event => <T>event.data));
  }
}

export class Events {
  static SESSION_EXPIRED = "SESSION_EXPIRED_BROADCAST";
  static USER_LOGGED_OUT = "USER_LOGGED_OUT";
}
