
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import * as fromConversation from "../actions/conversation.action";
import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Conversation, SearchRequest } from "../../shared/models";
import { Action } from "src/app/actions";
import * as _ from "lodash";

export interface ConversationState extends EntityState<Conversation> {
    isLoading: boolean;
    isLoaded: boolean;
    hasMore: boolean;
    query: SearchRequest;
    selectedConversationIds: string[];
    checkedConversationIds: string[];
    mailTabs: any[];
    selectedTab: any;
    selectedMailId: string;
    currentQuery: string;
    lastConversationData: any;
}

export const conversationAdapter: EntityAdapter<Conversation> = createEntityAdapter<Conversation>({
    selectId: (conversation: Conversation) => conversation.id
});

export const initialState: ConversationState = conversationAdapter.getInitialState({
    isLoading: false,
    isLoaded: false,
    hasMore: false,
    query: null,
    selectedConversationIds: [],
    checkedConversationIds: [],
    mailTabs: [],
    selectedTab: null,
    selectedMailId: null,
    currentQuery: "",
    lastConversationData: {}
});

export function conversationReducer(
    state = initialState,
    action: Action
): ConversationState {
    switch (action.type) {

        case fromConversation.IS_CONVERSATION_LOADING: {
            return {
                ...state,
                isLoading: action.payload
            };
        }

        case fromConversation.LOAD_CONVERSATION_SUCCESS: {
            const newState = {
                ...state,
                isLoading: false,
                isLoaded: true
            };
            return conversationAdapter.upsertMany(action.payload, newState);
        }

        case fromConversation.LOAD_CONVERSATION_FAILED: {
            return {
                ...state,
                isLoading: false,
                hasMore: false,
                isLoaded: false,
            };
        }

        case fromConversation.UPDATE_LAST_CONVERSATION_DATA: {
            const lastConversationData = state.lastConversationData;
            lastConversationData[action.payload.id] = action.payload;
            return {
                ...state,
                lastConversationData: lastConversationData
            };
        }

        case fromConversation.SET_QUERY: {
            return {
                ...state,
                query: action.payload.query
            };
        }

        case fromConversation.SET_HAS_MORE: {
            return {
                ...state,
                hasMore: action.payload
            };
        }

        case fromConversation.SELECT_CONVERSATION: {
            return {
                ...state,
                 selectedConversationIds: _.uniq([
                ...state.selectedConversationIds,
                action.payload
            ])
            };
        }

        case fromConversation.SELECT_MAIL_DETAIL: {
            return {
                ...state,
                 selectedMailId:  action.payload
            };
        }

        case fromConversation.UNSELECT_CONVERSATION: {
            const conversationId = action.payload;
            const selectedConversationIds = state.selectedConversationIds.filter(id => conversationId !== id);
            return {
              ...state,
              selectedConversationIds: selectedConversationIds
            };
        }

        case fromConversation.UNSELECT_CONVERSATIONS: {
            const conversationIds = action.payload;
            const selectedConversationIds = state.selectedConversationIds.filter(id => conversationIds.indexOf(id) === -1 );
            return {
              ...state,
              selectedConversationIds: selectedConversationIds
            };
        }

        case fromConversation.RESET_SELECTED_CONVERSATIONS: {
            return {
              ...state,
              selectedConversationIds: []
            };
        }

        case fromConversation.CHECK_CONVERSATION: {
            return {
                ...state,
                 checkedConversationIds: _.uniq([
                ...state.checkedConversationIds,
                action.payload
            ])
            };
        }

        case fromConversation.UNCHECK_CONVERSATION: {
            const conversationId = action.payload;
            const checkedConversationIds = state.checkedConversationIds.filter(id => conversationId !== id);
            return {
              ...state,
              checkedConversationIds: checkedConversationIds
            };
        }

        case fromConversation.RESET_CHECKED_CONVERSATIONS: {
            return {
              ...state,
              checkedConversationIds: []
            };
        }

        case fromConversation.REMOVE_TAB: {
            const mailTabs = state.mailTabs.filter(v => (!!v && !!v.id && (v.id !== action?.payload?.id)));
            let selectedTab = state.selectedTab;
            if (selectedTab?.id === action?.payload?.id) {
              selectedTab = null;
            }
            return {
              ...state,
              mailTabs: mailTabs,
              selectedTab: selectedTab
            };
        }

        case fromConversation.REMOVE_ALL_TABS: {
            return {
              ...state,
              mailTabs: []
            };
        }

        case fromConversation.ADD_TAB: {
            let mailTabs = state.mailTabs;
            if (!state.mailTabs.find(v => v && v.id === action?.payload?.id)) {
              mailTabs = [...state.mailTabs, action.payload];
            }
            return {
              ...state,
              selectedTab: action.payload,
              mailTabs: mailTabs
            };
        }

        case fromConversation.ADD_FIRST_TAB: {
            let mailTabs = state.mailTabs;
            if (!state.mailTabs.find(v => (!!v && !!v.id && (v.id === action.payload.id)))) {
              mailTabs[0] = action.payload;
            }
            return {
              ...state,
              selectedTab: action.payload,
              mailTabs: mailTabs
            };
        }

        case fromConversation.REMOVE_MULTIPLE_TABS: {
          console.log("REMOVE_MULTIPLE_TABS", action.payload);
            return {
              ...state,
              mailTabs: state.mailTabs.filter(v => (!!v && !!v.id && !action.payload.includes(v.id)))
            };
        }

        case fromConversation.SET_CURRENT_QUERY: {
            return {
              ...state,
              currentQuery: action.payload
            };
        }

        case fromConversation.RESET_CONVERSATIONS_BY_FOLDER: {
            const newState =  {
                ...state
            };
            return conversationAdapter.removeAll(newState);
        }

        case fromConversation.RESET_CONVERSATIONS: {
            const query = action.payload;
            const newState =  {
                ...state
            };
            return conversationAdapter.removeAll(newState);
        }

        case fromConversation.MULTIPLE_CHECK_CONVERSATIONS: {
            return {
              ...state,
              checkedConversationIds: action.payload
            };
        }
        case fromConversation.UPDATE_MANY_CONVERSATIONS_SUCCESS: {
          const newState =  {
              ...state,
              isLoading: false,
              isLoaded: true
          };
          return conversationAdapter.updateMany(action.payload
            .map((conversation) => Object.assign({}, {id: conversation.id, changes: conversation})), newState);
        }

        case fromConversation.UPDATE_CONVERSATION_SUCCESS: {
            return conversationAdapter.updateOne(action.payload, {
              ...state,
              isLoading: false,
              isLoaded: true,
            });
          }

        case fromConversation.REMOVE_ALL_CONVERSATIONS_SUCCESS: {
          const newState =  {
              ...state
          };
          return conversationAdapter.removeAll(newState);
        }

        case fromConversation.REMOVE_ALL_CONVERSATIONS_FROM_FOLDER: {
          const newState =  {
              ...state
          };
          const folderId = action.payload;
          const ids = [];
          const entities = state?.entities;
          Object.keys(entities).forEach(id => {
            if (entities[id] && entities[id].m && entities[id].m.find(m => m.l === folderId)) {
                ids.push(id);
            }
          });
          return conversationAdapter.removeMany(ids, newState);
        }

        case fromConversation.REMOVE_CONVERSATION_SUCCESS: {
          const newState =  {
              ...state
          };
          return conversationAdapter.removeOne(action.payload, newState);
        }

        case fromConversation.REMOVE_MANY_CONVERSATIONS: {
          const newState =  {
              ...state
          };
          return conversationAdapter.removeMany(action.payload, newState);
        }

        case fromConversation.RESET_CONVERSATION_STATE: {
          return conversationAdapter.removeAll(state);
        }

        default: {
            return state;
        }
    }
}

export const _getIsConversationLoading = (state: ConversationState) => state.isLoading;
export const _getHasMoreConversations = (state: ConversationState) => state.hasMore;
export const _getIsConversationLoaded = (state: ConversationState) => state.isLoaded;
export const _getSelectedConversationIds = (state: ConversationState) => state.selectedConversationIds;
export const _getCheckedConversationIds = (state: ConversationState) => state.checkedConversationIds;
export const _getMailDetailId = (state: ConversationState) => !!state ? state.selectedMailId : null;
export const _getCurrentQuery = (state: ConversationState) => state.currentQuery;
export const _getMailTabs = (state: ConversationState) => state?.mailTabs || [];
export const _getSelectedTab = (state: ConversationState) => state?.selectedTab;
export const _getLastConversationData = (state: ConversationState) => state?.lastConversationData || {};
export const _getLastConversationDataById = (state: ConversationState, id: string) => state.lastConversationData[id];
