
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild, NgZone, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject, forkJoin, Observable } from "rxjs";
import { ConfirmationDialogComponent, ConfirmationData } from "src/app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { Store } from "@ngrx/store";
import { take, takeUntil } from "rxjs/operators";
import * as _ from "lodash";
import { ToastService } from "../../../../common/providers/toast.service";
import { MailFolderRepository } from "../../../repositories/mail-folder-repository";
import { MailService } from "../../services/mail-service";
import { MailRootState } from "../../../store";
import { MailBroadcaster } from "../../../../common/providers/mail-broadcaster.service";
import { getUserProfile, getZimbraFeatures } from "../../../../reducers";
import { BroadcastKeys } from "../../../../common/enums/broadcast.enum";
import { EmailInformation } from "../../models";
import { AutocompleteComponent } from "../../../../shared/components/autocomplete/autocomplete.component";
import { ElectronService } from "src/app/services/electron.service";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { NgxHotkeysService } from "ngx-hotkeys-vnc";
import { ConfigService } from "src/app/config.service";
import { CalendarRepository } from "src/app/calendar/repositories/calendar.repository";
import { Router } from "@angular/router";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AppState } from "src/app/reducers/app";
import { environment } from "src/environments/environment";
import { FormControl } from "@angular/forms";


@Component({
  selector: "vp-share-folder",
  templateUrl: "./share-folder.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ShareFolderComponent implements OnInit, OnDestroy {
  folderTitleControl: FormControl = new FormControl("", []);

  folderTitle: string = "";
  maxFolderTitleLength: number = 128;
  isRename: boolean = false;
  oldFlderName: string = "";
  shareReply = "1";
  addEmailLabel: string = "";
  folderName: string = "";
  shareWithOption: string = "usr";
  shareRole: string = "r";
  notes: string = "";
  emailInfo: any = [];
  folderId: string = "";
  fromAddress: string = "";
  private isAlive$ = new Subject<boolean>();
  duplicateEmail: boolean = false;
  reqPending: boolean = false;
  duplicateAddress: string = "";
  dialogName: string = "";
  labelName: string = "";
  shareMessage: string = "";
  errorMessage: string = "";
  briefcaseShareURL: string = "";
  prefixBold: string = "";
  suffixNormal: string = "";
  isCalendarShare: boolean = false;
  calendarFolderAbsPath: string = "";
  calendarShareURL: string = "";
  url: string = "";
  folderPlaceHolder: string = "";
  isDisabledExternalSharingFeature: boolean = false;
  isDisabledPublicSharingFeature: boolean = true;
  @ViewChild("addEmailAutoComplete", {static: false}) addEmailAutoComplete: AutocompleteComponent;

  constructor(
    private dialogRef: MatDialogRef<ShareFolderComponent>,
    private mailFolderRepository: MailFolderRepository,
    public toastService: ToastService,
    private changeDetectionRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private mailService: MailService,
    private appStore: Store<MailRootState | AppState>,
    private dialog: MatDialog,
    private mailBroadcaster: MailBroadcaster,
    private ngZone: NgZone,
    private configService: ConfigService,
    private electronService: ElectronService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private hotKeyService: NgxHotkeysService,
    private calendarRepository: CalendarRepository,
    private router: Router
  ) {
    this.url = this.router.url;
    let key: string = "";
    if (this.url.startsWith("/calendar")) {
      key = "CALENDARS.SHARE_CALENDAR_NAME_LBL";
    } else {
      key = "FOLDER_NAME_LBL";
    }
    this.translateService.get(key).pipe(take(1)).subscribe(res => {
      this.folderPlaceHolder = res;
      this.changeDetectionRef.markForCheck();
    });
    setTimeout(() => {
      this.changeDetectionRef.detectChanges();
    }, 50);
    this.prefixBold = this.configService.get("prefixBold");
    this.suffixNormal = this.configService.get("suffixNormal");
    this.hotKeyService.pause(this.hotKeyService.hotkeys);
    this.appStore.select(getUserProfile).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      if (!!res && res.email) {
        this.fromAddress = res.email;
        this.changeDetectionRef.markForCheck();
      } else {
        const userEmail = MailUtils.getProfileFromStorage();
        this.fromAddress = userEmail;
        this.changeDetectionRef.markForCheck();
      }
    });

    this.isRename = this.data.isRename;
    if (this.isRename) {
      this.folderTitle = this.data.targetFolder.name;
      this.oldFlderName = this.data.targetFolder.name;
    }
    // this.folderName = this.data.folderName;
    this.folderTitleControl.patchValue(this.data.folderName);

    if (this.url.startsWith("/calendar")) {
      if (this.folderTitleControl.value === "Calendar") {
        this.translateService.get("CALENDARS.CALENDAR_FOLDER").pipe(take(1)).subscribe(
          text => {
              this.folderTitleControl.patchValue(text);
            // this.folderName = text;
          }
        );
      }
    } else if (this.url.startsWith("/mail")) {
      if (this.data.targetFolder && this.data.targetFolder.id === "2") {
        this.translateService.get("INBOX").pipe(take(1)).subscribe(text => {
          this.folderName = text;
          this.folderTitleControl.patchValue(text);

        });
      } else if (this.data.targetFolder && this.data.targetFolder.id === "6") {
        this.translateService.get("DRAFTS_FOLDER").pipe(take(1)).subscribe(text => {
          this.folderName = text;
          this.folderTitleControl.patchValue(text);

        });
      }
    } else if (this.url.startsWith("/briefcase")) {
      if (this.data.targetFolder && this.data.targetFolder.id === "16") {
        this.translateService.get("BRIEFCASE_LBL").pipe(take(1)).subscribe(text => {
          this.folderName = text;
          this.folderTitleControl.patchValue(text);

        });
      }
    }
    this.folderId = this.data.targetFolder.id;

    this.dialogName = "Share Folder";
    this.labelName = "Folder Name";
    this.shareMessage = "Folder shared.";
    /*if ($state.current.name.includes('task')) {
      this.dialogName = 'Share Task List';
      this.labelName = 'Task List';
      this.shareMessage = 'Task List shared.';
    } else if ($state.current.name.includes('contacts')) {
      this.dialogName = 'Share Contact List';
      this.labelName = 'Contact List';
      this.shareMessage = 'Contact List shared.';
    } else if ($state.current.name.includes('calendar')) {
      this.dialogName = 'Share Calendar';
      this.labelName = 'Calendar';
      this.shareMessage = 'Calendar shared.';
    }*/
    this.initializeShare();
    if ( this.data.grant ) {
      this.shareWithOption = this.data.grant.gt;
      this.shareRole = this.data.grant.perm;
      setTimeout(() => {
        const grantee = this.data.grant.gt === "guest" ? this.data.grant.zid : this.data.grant.d;
         const email: EmailInformation = { a: grantee, d: grantee } as EmailInformation;
         if (!!this.addEmailAutoComplete) {
           this.addEmailAutoComplete.setEmailField(email);
         }
      }, 500);
      this.changeDetectionRef.markForCheck();
    }
    this.mailService.getAllFolders().subscribe((res) => {
      if (res.GetFolderResponse && res.GetFolderResponse.folder) {
        this.mailService.allFolders = [];
        for ( const folder of res.GetFolderResponse.folder.folder) {
          this.mailService.allFolders.push(folder);
          if (folder.folder) {
            if (Array.isArray(folder.folder)) {
              for ( const child of folder.folder) {
                this.mailService.allFolders.push(child);
              }
            } else {
              this.mailService.allFolders.push(folder.folder);
            }
          }
        }
      }
    });

    this.mailBroadcaster.on<any>(BroadcastKeys.HIDE_SHARE_FOLDER_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      this.ngZone.run(() => {
        this.dialogRef.close({ perm: this.shareRole });
      });
    });

    const serverUrl = localStorage.getItem(MailConstants.SERVER_URL);
    const baseUrl = (environment.isCordova || environment.isElectron) ? serverUrl + "/zassets" : window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/zassets";

    console.log("windowlocation: ", window.location);

    if (this.data.briefcaseShareURL) {
      this.briefcaseShareURL = this.data.briefcaseShareURL.replace(this.configService.zimbraURL, baseUrl);
      this.isDisabledPublicSharingFeature = false;
      this.changeDetectionRef.markForCheck();
    }

    if (this.data.isCalendarShare) {
      this.isCalendarShare = true;
      this.isDisabledPublicSharingFeature = false;
      if (this.data.targetFolder.perm) {
        if (this.data.targetFolder.owner) {
          const rest = this.data.targetFolder.rest;
          this.calendarShareURL = rest.replace(/ /g, "%20").replace(this.configService.zimbraURL, baseUrl);
        } else {
          const rootFolder = this.calendarRepository.getRootFolder(this.data.targetFolder);
          const ownerName = rootFolder.owner;
          let originalPath = this.data.targetFolder.absFolderPath;
          originalPath = originalPath.substr(originalPath.indexOf(rootFolder.oname));
          const absPath = "/" + originalPath.replace(/ /g, "%20");
          this.calendarFolderAbsPath = absPath;
          this.calendarShareURL = baseUrl + "/service/user/" + ownerName + this.calendarFolderAbsPath;
        }
      } else {
        this.calendarFolderAbsPath = this.data.targetFolder.absFolderPath.replace(/ /g, "%20");
        this.calendarShareURL = baseUrl + "/home/" + this.fromAddress + this.calendarFolderAbsPath;
      }
    }
  }

  ngOnInit(): void {
    this.appStore.select(getZimbraFeatures).pipe(takeUntil(this.isAlive$)).subscribe(res => {
      if (!!res && res.length > 0) {
        const externalSharing = res.filter( p => p.key && p.key === "zimbraExternalSharingEnabled")[0];
        if (!!externalSharing) {
          if (externalSharing.value === "FALSE") {
            this.isDisabledExternalSharingFeature = true;
            this.changeDetectionRef.markForCheck();
          }
        }
      }
    });
  }

  initializeShare() {
    this.translateService.get(MailConstants.ADD_EMAIL).subscribe(
      message => {
        this.addEmailLabel = message;
      }
    );

    this.changeDetectionRef.markForCheck();
  }

  getEmails(): any[] {
    return this.addEmailAutoComplete.getSelectedEmail();
  }

  closeDialog(): void {
    this.close();
  }

  folderAction(): void {
    if (this.isFolderAlreadyExist()) {
      this.toastService.show("DUPLICATE_FOLDER_MSG");
      return;
    }
    if (this.isRename) {
      this.mailFolderRepository.updateMailFolder(this.data.targetFolder, this.folderTitle);
    } else {
      this.mailFolderRepository.createMailFolder(this.data.targetFolder, this.folderTitle);
    }
    this.close();
  }

  isFolderAlreadyExist() {
    if (this.data.targetFolder.children) {
      if (this.data.targetFolder.children.find(f => f.name === this.folderTitle)) {
        return true;
      }
    }
    return false;
  }

  changeshareReply(value) {
    console.log(value);
  }

  close(): void {
    this.dialogRef.close({ perm: this.shareRole });
  }

  shareFolder() {
    this.emailInfo = [];
    if (this.shareWithOption !== "pub") {
      if (this.getEmails().length === 0) {
        this.toastService.show(MailConstants.MAIL_REQUIRE);
        return;
      }
      this.getEmails().forEach(emailItem => {
        // this.emailInfo.push({ "t": "t", "a": emailItem.email, "d": emailItem.name });
        this.emailInfo.push(emailItem.email);
      });
    }

    console.log(this.fromAddress);
    const request = {
      id: this.folderId,
      emailInfo: this.fromAddress,
      op: "grant",
      gt: this.shareWithOption,
      d:  this.emailInfo,
      perm:  this.shareRole !== "0" ? this.shareRole : "",
      pw: ""
    };

    this.mailService.shareFolder(request, this.emailInfo).subscribe(
      res => {
        if (res.FolderActionResponse) {
          if (this.shareWithOption === "pub") {
            this.dialogRef.close();
          } else {
            if (this.shareReply === "0") {
              this.dialogRef.close({ perm: this.shareRole, folderResponse: res.FolderActionResponse });
            } else {
              const requests = [];
              let sendAction = "new";
              if ( this.data.grant ) {
                sendAction = "edit";
              }
              for ( const mail of this.emailInfo ) {
                const options = {
                  id: this.folderId,
                  emailInfo: mail,
                  notes: this.notes,
                  action: sendAction
                };
                requests.push(
                  this.mailService.sendShareFolderRequest(options)
                );
              }

              forkJoin(requests).subscribe(res2 => {
                console.log(res2);
                this.toastService.show("FOLDER_SHARED");
                this.mailBroadcaster.broadcast("CALL_NO_OP_FORCE_REQUEST");
                this.dialogRef.close({ perm: this.shareRole , folderResponse: res.FolderActionResponse});
              });
            }
          }
        } else {
          console.log(res.Fault);
          // this.reqPending = false;
          const duplicateError = this.translateService.instant("GRANT_EXIST");
          const selfShareError = this.translateService.instant("CANNOT_GRANT_ACCESS");
          let msg = "";
          const duplicateAddresses = [];
          if (Array.isArray(res.Fault)) {
            for (const error of res.Fault) {
              msg = error.Reason.Text.toLowerCase();
              if ( msg.indexOf(selfShareError) > -1 ) {
                this.toastService.show("CANNOT_SHARE_YOURSELF");
                return;
              } else if (msg.indexOf(duplicateError) !== -1 ) {
                const duplicateAddress = msg.split("exists ")[1];
                this.duplicateEmail = true;
                console.log(this.mailService.allFolders);
                if (this.emailInfo.length === 1) {
                  duplicateAddresses.push(this.emailInfo[0]);
                } else if (duplicateAddress.indexOf("@") === -1) {
                  const folder = _.find(this.mailService.allFolders, (f) => {
                    return f.id === this.folderId;
                  });
                  if (folder && folder.acl && folder.acl.grant) {
                    const grant = folder.acl.grant;
                    if (Array.isArray(grant)) {
                      const found = _.find(grant, (g) => g.zid === duplicateAddress.trim());
                      if (found) {
                        duplicateAddresses.push(found.d);
                      }
                    } else {
                      if (grant.zid === duplicateAddress.trim()) {
                        duplicateAddresses.push(grant.d);
                      }
                    }
                  }
                }
                this.duplicateAddress = duplicateAddresses.join(", ");
              }  else if (msg.indexOf("permission denied: external sharing not allowed") !== -1) {
                this.toastService.show("PERMISSION_DENIED_LBL");
                return;
              }
            }

          } else {
            msg = res.Fault.Reason.Text;
            if ( msg.indexOf(selfShareError) > -1 ) {
              // vm.sidebarFoldersService._openCriticalErrorModel('You cannot share a folder with yourself.');
              this.toastService.show("CANNOT_SHARE_YOURSELF");
              return;
            } else if ( msg.indexOf(duplicateError) !== -1 ) {
              const duplicateAddress = msg.split("exists ")[1];
              this.duplicateEmail = true;

              if ( this.emailInfo.length === 1 ) {
                duplicateAddresses.push(this.emailInfo[0]);
              } else if ( duplicateAddress.indexOf("@") === -1 ) {
                const folder = _.find(this.mailService.allFolders, (f) => {
                  return f.id === this.folderId;
                });
                if ( folder && folder.acl && folder.acl.grant ) {
                  const grant = folder.acl.grant;
                  if ( Array.isArray(grant) ) {
                    const found = _.find(grant, (g) => g.zid === duplicateAddress.trim());
                    if ( found ) {
                      duplicateAddresses.push(found.d);
                    }
                  } else {
                    if ( grant.zid === duplicateAddress.trim() ) {
                      duplicateAddresses.push(grant.d);
                    }
                  }
                }
              }
              this.duplicateAddress = duplicateAddresses.join(", ");
            }
          }

          if ( msg && msg.indexOf(duplicateError) > -1 ) {
            this.errorMessage = this.translateService.instant("ALREDAY_SHARED", {email: this.duplicateAddress});
            const data: ConfirmationData = {
              action: "purge",
              titleKey: "SHARE_FOLDER",
              contentKey: this.errorMessage,
              actionKey: "OK"
            };

            this.openConfirmationDialog(data, { width: "400px" }).pipe(take(1)).subscribe(result => {
              if (result && result.confirmed) {
                const requestList = [];
                let sendAction = "new";
                if ( this.data.grant ) {
                  sendAction = "edit";
                }
                for ( const mail of this.emailInfo ) {
                  const options = {
                    id: this.folderId,
                    emailInfo: mail,
                    notes: this.notes,
                    action: sendAction
                  };
                  requestList.push(
                    this.mailService.sendShareFolderRequest(options)
                  );
                }

                forkJoin(requestList).subscribe(results => {
                  console.log(results);
                  this.toastService.show("FOLDER_SHARED");
                  this.dialogRef.close({ perm: this.shareRole });
                });
              } else {
                this.dialogRef.close({ perm: this.shareRole , folderResponse: res.FolderActionResponse });
              }
            });

            console.log(this.errorMessage);
          } else {
            console.log(msg);
          }
          this.dialogRef.close({ perm: this.shareRole });
        }
      },
      error => {
        console.log(error);
        // this.errorService.emit({ id: ErrorType.Generic, messages: error });
      }
    );
  }


  private openConfirmationDialog(data: ConfirmationData, style: any): Observable<any> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: data,
      ...style
    });

    return dialogRef.afterClosed();
  }

  ngOnDestroy() {
    this.hotKeyService.unpause(this.hotKeyService.hotkeys);
  }

  copyShareURL(): void {
    MailUtils.copyToClipboard([this.briefcaseShareURL]);
  }
}
