
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, HostBinding } from "@angular/core";
import { trigger, state, style, transition, animate } from "@angular/animations";

import { FilePreviewOverlayRef } from "../file-preview-overlay-ref";

@Component({
  selector: "vp-file-preview-overlay-toolbar",
  templateUrl: "./file-preview-overlay-toolbar.component.html",
  animations: [
    trigger("slideDown", [
      state("void", style({ transform: "translateY(-100%)" })),
      state("enter", style({ transform: "translateY(0)" })),
      state("leave", style({ transform: "translateY(-100%)" })),
      transition("* => *", animate("400ms cubic-bezier(0.25, 0.8, 0.25, 1)"))
    ])
  ]
})
export class FilePreviewOverlayToolbarComponent implements OnInit {

  // Apply animation to the host element
  @HostBinding("@slideDown") slideDown = "enter";

  // Inject remote control
  constructor(private dialogRef: FilePreviewOverlayRef) { }

  ngOnInit() {
    // Animate toolbar out before overlay is closed
    this.dialogRef.beforeClose().subscribe(() => this.slideDown = "leave");
  }
}
