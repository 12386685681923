
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ContactTag } from "src/app/mail/shared/models/contact-tag.model";

export class ContactInfo {
    address: any[];
    avatar: string;
    company: string;
    created_at: Date;
    deleted_at: Date;
    emails: Emails[];
    firstName: string;
    groups: any[];
    id: string;
    is_company: boolean;
    is_global: boolean = false;
    jid: string;
    jobTitle: string;
    lastName: string;
    middleName: string;
    phones: any[];
    updated_at: Date;
    fullName: string;
    isHavingFirstCharacter = false;
    isSelected: boolean = false;
    isChecked: boolean = false;
    isGroup?: boolean = false;
    isHovered?: boolean = false;
    notes: string;
    im_accounts: any[];
    urls: any[];
    custom_fields: any[];
    events: any[];
    contact_list: any;
    tags: ContactTag[];
    timezone?: string;
    language?: string;
    skills?: any[];
    interests?: any[];
    birthday?: Date;
    gender?: string;
    marital_status?: string;
    private_email?: string;
    start_date?: Date;
    end_date?: Date;
    per_week_availability?: string;
    hourly_rate?: string;
    vnc_employee?: string;
    payment_mode?: string;
    passport_number?: string;
    passport_expiry?: Date;
    rfc_limit?: string;
    username?: string;
    admin?: string;
    agb_accepted?: string;
    video_bridge?: string;
    omemo?: string;
    national_id_number?: string;
    national_id_expiry?: Date;
    products?: any[];
    favorite: boolean = false;
    bgAvatarColor: string;
  }

export class Emails {
    created_at: string;
    email: string;
    id: string;
    updated_at: string;
}
