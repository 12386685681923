
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { BriefCase } from "../../shared/models/briefcase.model";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { Action } from "src/app/actions";
import { BriefcaseActionTypes } from "../actions/briefcase.actions";
import * as _ from "lodash";

export interface BriefCaseState extends EntityState<BriefCase> {
  isLoading: boolean;
  isLoaded: boolean;
  selectedBriefcase: BriefCase;
  selectedFileIds: string[];
  checkFileIds: string[];
  hasMore: boolean;
}

export const briefcaseAdapter: EntityAdapter<BriefCase> = createEntityAdapter<BriefCase>({
  selectId: (briefcase: BriefCase) => briefcase.id
});

export const initialState: BriefCaseState = briefcaseAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  selectedBriefcase: null,
  selectedFileIds: [],
  checkFileIds: [],
  hasMore: false
});

export function referenceReducer(
  state = initialState,
  action: Action
): BriefCaseState {
  switch (action.type) {

    case BriefcaseActionTypes.SELECT_BRIEFCASE: {
      return {
        ...state,
        selectedBriefcase: action.payload.file
      };
    }

    case BriefcaseActionTypes.LOAD_BRIEFCASES: {
      return {
        ...state,
        isLoading: true,
        isLoaded: false
      };
    }

    case BriefcaseActionTypes.LOAD_BRIEFCASES_SUCCESS: {
      return briefcaseAdapter.addMany(action.payload.files, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case BriefcaseActionTypes.LOAD_BRIEFCASES_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case BriefcaseActionTypes.CREATE_BRIEFCASE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case BriefcaseActionTypes.CREATE_BRIEFCASE_SUCCESS: {
      return briefcaseAdapter.addMany(action.payload.files, {
        ...state,
        isLoading: false
      });
    }

    case BriefcaseActionTypes.CREATE_BRIEFCASE_FAIL: {
      return {
        ...state,
        isLoading: false
      };
    }

    case BriefcaseActionTypes.UPDATE_BRIEFCASE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case BriefcaseActionTypes.UPDATE_BRIEFCASE_SUCCESS: {
      return briefcaseAdapter.updateOne(action.payload.file, {
        ...state,
        isLoading: false
      });
    }

    case BriefcaseActionTypes.UPDATE_BRIEFCASE_FAIL: {
      return {
        ...state,
        isLoading: false
      };
    }

    case BriefcaseActionTypes.RESET_BRIEFCASE_STATE: {
      return briefcaseAdapter.removeAll(state);
    }

    case BriefcaseActionTypes.SELECT_FILES: {
      return {
          ...state,
          selectedFileIds: _.uniq([
          ...state.selectedFileIds,
          action.payload
      ])
      };
  }

  case BriefcaseActionTypes.UNSELECT_FILE: {
    const fileId = action.payload;
    const selectedFileIds = state.selectedFileIds.filter(id => fileId !== id);
    return {
      ...state,
      selectedFileIds: selectedFileIds
    };
  }

  case BriefcaseActionTypes.UNSELECT_FILES: {
    const fileIds = action.payload;
    const selectedFileIds = state.selectedFileIds.filter(id => fileIds.indexOf(id) === -1 );
    return {
      ...state,
      selectedFileIds: selectedFileIds
    };
  }

  case BriefcaseActionTypes.CHECK_FILES: {
    return {
        ...state,
         checkFileIds: _.uniq([
          ...state.checkFileIds,
          action.payload
      ])
    };
  }

  case BriefcaseActionTypes.UNCHECK_FILES: {
    const conversationId = action.payload;
    const checkedFileIds = state.checkFileIds.filter(id => conversationId !== id);
    return {
      ...state,
      checkFileIds: checkedFileIds
    };
  }

  case BriefcaseActionTypes.RESET_SELECTED_FILES: {
    return {
      ...state,
      selectedFileIds: []
    };
  }

  case BriefcaseActionTypes.RESET_CHECKED_FILES: {
    return {
      ...state,
      checkFileIds: []
    };
 }

 case BriefcaseActionTypes.REMOVE_MANY_BRIEFCASE_FILES: {
    const newState =  {
      ...state
    };
    return briefcaseAdapter.removeMany(action.payload, newState);
  }

  case BriefcaseActionTypes.UPDATE_MANY_BRIEFCASE_FILE_SUCCESS: {
    const newState =  {
        ...state,
        isLoading: false,
        isLoaded: true
    };
    return briefcaseAdapter.updateMany(action.payload
      .map((briefcase) => Object.assign({}, {id: briefcase.id, changes: briefcase})), newState);
  }

  case BriefcaseActionTypes.LOAD_BRIEFCASE_FILES_SUCCESS: {
    const newState = {
        ...state,
        isLoading: false,
        isLoaded: true
    };
    return briefcaseAdapter.addMany(action.payload, newState);
  }

  case BriefcaseActionTypes.SET_HAS_MORE: {
    return {
        ...state,
        hasMore: action.payload
    };
  }

  case BriefcaseActionTypes.REMOVE_ALL_FILES_FROM_FOLDER: {
    const newState =  {
        ...state
    };
    const folderId = action.payload;
    const ids = [];
    const entities = state.entities;
    Object.keys(entities).forEach(id => {
          ids.push(id);
    });
    return briefcaseAdapter.removeMany(ids, newState);
  }

  case BriefcaseActionTypes.MULTIPLE_CHECK_FILE_IDS: {
    return {
      ...state,
      checkFileIds: action.payload
    };
  }

    default: {
      return state;
    }
  }
}

export const _getSelectedFileIds = (state: BriefCaseState) => state.selectedFileIds;
export const _getCheckedFileIds = (state: BriefCaseState) => state.checkFileIds;
export const _getHasMoreFiles = (state: BriefCaseState) => state.hasMore;
export const _getIsFileLoading = (state: BriefCaseState) => state.isLoading;
