
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { ConfigService } from "src/app/config.service";
import { Observable, of, forkJoin, Subject } from "rxjs";
import { CommonUtils } from "src/app/common/utils/common-util";
import { HttpClient } from "@angular/common/http";
import { map, take, tap } from "rxjs/operators";
import { Filter } from "../models/filter.model";
import * as _ from "lodash";
import { Signature, Preference, DataSource } from "../models";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { Identity, IdentityAttribute } from "../models/identity.model";
import { Attribute } from "../models/attribute.model";
import { MailBroadcaster } from "../../../common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { BreakpointObserver } from "@angular/cdk/layout";
import { MailConstants } from "../../../common/utils/mail-constants";
import { environment } from "src/environments/environment";
import { ElectronService } from "src/app/services/electron.service";
import { VncLibraryService } from "vnc-library";


const ZIMBRA_ACCOUNT = "urn:zimbraAccount";
@Injectable()
export class PreferenceService {

    public allFolders: any = [];
    public attachmentInfo: any;
    public uploadAttachmentSizeLimit: number;
    private preferenceChanges$ = new Subject<boolean>();
    private saveChanges$ = new Subject<number>();
    private preferenceTitle: string;
    isMobileScreen: boolean = false;
    public dataSource$: DataSource[];
    public emailUserDisplayType: string = "firstname";
    public personas: any [] = [];
    public sendAsAndBehalf: any [] = [];
    alwaysShowQuotedText: boolean;
    useQuickReply: boolean = true;
    showGlobalTags: boolean = false;
    isCordovaOrElectron = environment.isCordova || environment.isElectron;
    constructor(
        private vncLibraryService: VncLibraryService,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private mailBroadcaster: MailBroadcaster,
        private route: Router,
        private translate: TranslateService,
        private breakpointObserver: BreakpointObserver,
        private configService: ConfigService,
        private electronService: ElectronService) {
          this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
        }

    setPreferenceChanges(value: boolean): void {
        this.preferenceChanges$.next(value);
    }

    showOfflineMessage() {
      if (!navigator.onLine) {
        this.translate.get("NO_INTERNET_CONNECTION")
        .pipe(take(1))
        .subscribe(text => {
            this.vncLibraryService.openSnackBar(text, "close",
            "", "", 2000, "bottom", "left").subscribe(res => {
            });
        });
      }
      return !navigator.onLine;
    }

    setOnSaveChanges(): void {
        this.saveChanges$.next(new Date().getTime());
    }

    onPreferenceChanges(): Observable<boolean> {
        return this.preferenceChanges$;
    }

    onSaveChanges(): Observable<number> {
        return this.saveChanges$;
    }

    setPreferenceTitle(title: string): void {
        this.preferenceTitle = title;
    }

    getPreferenceTitle(): string {
        return this.preferenceTitle;
    }

    modifyFilterRulesRequest(request: any): Observable<any> {
        return this.http.post(this.configService.API_URL + "/api/ModifyFilterRules", request,
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1));
    }

    batchRequest(request: any): Observable<any> {
        return this.http.post(this.configService.API_URL + "/api/batchRequest", request,
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1));
    }

    batchRequest2(request: any): Observable<any> {
        return this.http.post(this.configService.API_URL + "/api/batchRequest2", request,
            { headers: CommonUtils.getZimbraHeader() }).pipe(tap(res => {
                if (res["Fault"] && res["Fault"]["Reason"]) {
                    return of({ error: res["Fault"]["Reason"]["Text"] });
                }
            }), take(1));
    }

    getFilters(option: "all" | "incoming" | "outgoing"): Observable<{ incomingFilters: Filter[], outgoingFilters: Filter[] }> {
        let request: any = {};
        if (option === "incoming") {
            request = {
                "GetFilterRulesRequest": {
                    "@": { "xmlns": "urn:zimbraMail" }
                }
            };
        } else if (option === "outgoing") {
            request = {
                "GetOutgoingFilterRulesRequest": {
                    "@": { "xmlns": "urn:zimbraMail" }
                }
            };
        } else {
            request = {
                "GetFilterRulesRequest": {
                    "@": { "xmlns": "urn:zimbraMail" }
                },
                "GetOutgoingFilterRulesRequest": {
                    "@": {
                        "xmlns": "urn:zimbraMail"
                    }
                }
            };
        }
        return this.batchRequest2(request)
            .pipe(map((res: any) => {
                let incomingFilters: Filter[] = [];
                let outgoingFilters: Filter[] = [];
                if (res.GetFilterRulesResponse && res.GetFilterRulesResponse[0].filterRules
                    && res.GetFilterRulesResponse[0].filterRules[0].filterRule) {
                    let _incomingFilters = res.GetFilterRulesResponse[0].filterRules[0].filterRule;
                    _incomingFilters = (Array.isArray(_incomingFilters) ? _incomingFilters : [_incomingFilters]);
                    incomingFilters = _incomingFilters.map(f => {
                        f.active = f.active;
                        return f;
                    }) as Filter[];
                }
                if (res.GetOutgoingFilterRulesResponse && res.GetOutgoingFilterRulesResponse[0].filterRules
                    && res.GetOutgoingFilterRulesResponse[0].filterRules[0].filterRule) {
                    let _outgoingFilters = res.GetOutgoingFilterRulesResponse[0].filterRules[0].filterRule;
                    _outgoingFilters = (Array.isArray(_outgoingFilters) ? _outgoingFilters : [_outgoingFilters]);
                    outgoingFilters = _outgoingFilters.map(f => {
                        f.active = f.active;
                        return f;
                    }) as Filter[];
                }
                return { incomingFilters, outgoingFilters };
            }, take(1)));
    }

    getAttributes(): Observable<Attribute[]> {
        return this.http.get(this.configService.API_URL + "/api/getInfo?sections=attrs",
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1), map((res: any) => {
                return this.getAllAttributes(res.attrs._attrs);
            }));
    }

    getAllAttributes(_attrs: any): Attribute[] {
        const attr: Attribute[] = [];
        for (const key in _attrs) {
          if (_attrs.hasOwnProperty(key)) {
            const atribute: Attribute = {
              key: key ,
              value: _attrs[key]
            };
            attr.push(atribute);
          }
        }
        return attr;
    }

    getPreferences(): Observable<Preference[]> {
        return this.http.post(this.configService.API_URL + "/api/getPrefs", {},
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1), map((res: any) => {
                return res.pref.map(pref => {
                    const preference: Preference = {
                        key: pref.$.name,
                        value: pref._
                    };
                    return preference;
                });
            }));
    }

    getPreference(prefName): Observable<any> {
        return this.http.post(this.configService.API_URL + "/api/getPrefs", { prefName: prefName },
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1));
    }

    private setPreference(preference: Preference) {
        return {
            "@": {
                "name": preference.key
            },
            "#": preference.value
        };
    }

    private getPref(data) {
        return { key: data.$.name, value: data._ };
    }

    savePreferences(preferences): Observable<any> {
        const pref = _.map(preferences, this.setPreference.bind(this));
        const request = {
            ModifyPrefsRequest: {
                "@": {
                    "xmlns": ZIMBRA_ACCOUNT,
                    "requestId": "0"
                },
                "pref": pref
            }
        };
        return this.batchRequest(request);
    }

    modifyPrefs(changes: Preference[]): Observable<any> {
      console.log("[PreferenceService][modifyPrefs]", changes);

      return this.http.post(this.configService.API_URL + "/api/modifyPrefs", { prefs: changes },
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1));
    }

    setZimletProperties(props: {key: string, val: string}[]) {
        const subject = new Subject<any>();
        if (!navigator.onLine) {
          subject.next(null);
          return subject.asObservable();
        }
        const newProps = props.map(prop => {
          return {
            "@": { "zimlet": "vncmail", "name": prop.key },
            "#": prop.val
          };
        });
        const request = {
            "ModifyPropertiesRequest": {
                "@": { "xmlns": "urn:zimbraAccount" },
                "prop": newProps
            }
        };
        this.http.post(this.configService.API_URL + "/api/batchRequest", request,
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1)).subscribe((res: any) => {
                if (res.ModifyPropertiesResponse) {
                    props.forEach(v => {
                      localStorage.setItem(v.key, v.val.toString());
                      if (v.key === "useQuickReply") {
                        this.useQuickReply = !!v.val;
                      } else if (v.key === "alwaysShowQuotedText") {
                        this.alwaysShowQuotedText = !!v.val;
                      }
                    });
                    subject.next(props);
                } else if (res.Fault && res.Fault[0]) {
                    subject.error(res.Fault[0].Reason.Text);
                } else {
                    subject.error(null);
                }
            }, () => {
                subject.error(null);
            });
        return subject.asObservable();
    }

    modifyFilterRules(filters: Filter[]): Observable<any> {
        const request = {
            ModifyFilterRulesRequest: {
                "_jsns" :
                    "urn:zimbraMail",
                "filterRules": [
                    {
                        filterRule: filters
                    }
                ]
            }
        };
        return this.modifyFilterRulesRequest(request);
    }

    modifyOutgoingFilterRules(filters: Filter[]) {
        const request = {
            ModifyOutgoingFilterRulesRequest: {
                "@": {
                    xmlns: "urn:zimbraMail"
                },
                "filterRules": [
                    {
                        filterRule: filters
                    }
                ]
            }
        };
        return this.http.post(this.configService.API_URL + "/api/batchRequest", request,
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1));
    }

    getDataSources(): Observable<DataSource[]> {
        console.log("[PreferenceService][getDataSources]");
        if (!navigator.onLine) {
          return of([]);
        }
        const request = {
            GetDataSourcesRequest: {
                "@": {
                    "xmlns": "urn:zimbraMail",
                    "requestId": "0"
                }
            }
        };
        return this.batchRequest(request).pipe(map(res => {
            let accounts: DataSource[] = [];
            if (!!res.GetDataSourcesResponse) {
                const datasources = res.GetDataSourcesResponse[0];
                if (datasources && !!datasources.pop3) {
                    const pop3 = Array.isArray(datasources.pop3) ? datasources.pop3 : [datasources.pop3];
                    const temp: DataSource[] = pop3.map((p) => {
                        const o = p;
                        o.accountType = "POP";
                        o.selected = false;
                        return o as DataSource;
                    });
                    accounts = [...accounts, ...temp];
                }
                if (datasources && !!datasources.imap) {
                    const imap = Array.isArray(datasources.imap) ? datasources.imap : [datasources.imap];
                    const temp: DataSource[] = imap.map((p) => {
                        const o = p;
                        o.accountType = "IMAP";
                        o.selected = false;
                        return o as DataSource;
                    });
                    accounts = [...accounts, ...temp];
                }
                for (const account of accounts) {
                    if (!account.defaultSignature || account.defaultSignature === "11111111-1111-1111-1111-111111111111") {
                        account.defaultSignature = "";
                    }
                    if (!account.forwardReplySignature || account.forwardReplySignature === "11111111-1111-1111-1111-111111111111") {
                        account.forwardReplySignature = "";
                    }
                }
            }
            // accounts.reverse();
            return accounts;
        }));
    }

    createDataSource(accounts): Observable<any> {
        const request = {
            CreateDataSourceRequest: accounts,
            NoOpRequest: {
                "@": {
                    xmlns: "urn:zimbraMail",
                    requestId: (accounts.length + 1)
                }
            }
        };
        return this.batchRequest2(request);
    }

    modifyDataSource(accounts): Observable<any> {
        const request = {
            ModifyDataSourceRequest: accounts
        };
        return this.batchRequest2(request);
    }

    getImportStatus(): Observable<any> {
        const request = {
            GetImportStatusRequest: {
                "@": {
                    xmlns: "urn:zimbraMail"
                }
            }
        };
        return this.batchRequest2(request);
    }

    importData(account: any): Observable<any> {
        let request: any;
        if (account.accountType === "POP") {
            request = {
                ImportDataRequest: {
                    "@": {
                        xmlns: "urn:zimbraMail"
                    },
                    pop3: {
                        "@": {
                            id: account.id
                        }
                    }
                }
            };
        } else {
            request = {
                ImportDataRequest: {
                    "@": {
                        xmlns: "urn:zimbraMail"
                    },
                    imap: {
                        "@": {
                            id: account.id
                        }
                    }
                }
            };
        }
        if (account.accountType === "IMAP") {
            delete request.ImportDataRequest.pop3;
            request.ImportDataRequest.imap = {
                "@": {
                    id: account.id
                }
            };
        }
        return this.batchRequest2(request);
    }


    /// Signature

    getSignatureId(prefrenceName: string): Observable<any> {
      console.log("[PreferenceService][getSignatureId]", prefrenceName);

      const body = { prefName: prefrenceName };
      return this.http.post(
        this.configService.API_URL + "/api/getPrefs",
        body,
        {
          headers: CommonUtils.getZimbraHeader()
        }).pipe(take(1));
    }

    modifySignaturePrefs(changes: Preference[]): Observable<any> {
      console.log("[PreferenceService][modifySignatureIdPrefs]", changes);

      return this.modifyPrefs(changes);
    }

    modifySignature(signature, htmlContent: string, plainContent: string, contactcid: string) {
      console.log("[PreferenceService][modifySignature]", htmlContent, plainContent, contactcid);

      const signatureRequest = {
        "@": {
            name: signature.name,
            id: signature.id
        },
        content: [
          {
            "@": {
                type: "text/html"
            },
            "#": htmlContent
        },
        {
            "@": {
                type: "text/plain"
            },
            "#": plainContent
        }
        ]
        };
        if (!!contactcid && contactcid !== "") {
            signatureRequest["cid"] = contactcid;
        } else {
            signatureRequest["cid"] = "";
        }
        const request = {
            ModifySignatureRequest: {
                "@": {
                    xmlns: ZIMBRA_ACCOUNT
                },
                signature: signatureRequest
            }
        };
        return this.batchRequest2(request);
    }

    deleteSignatures(ids: string[]) {
        console.log("[PreferenceService][deleteSignatures]", ids);

        const signatures = ids.map(id => {
            const signature = {
                "@": { "xmlns": ZIMBRA_ACCOUNT },
                "signature": { "@": { "id": id } }
            };
            return signature;
        });
        const request = {
            DeleteSignatureRequest: signatures
        };
        return this.batchRequest2(request);
    }

    createSignature(name: string, htmlContent: string, plainContent: string, contactcid: string): Observable<any> {
        console.log("[PreferenceService][createSignature]", name, htmlContent, plainContent, contactcid);

        const signatureRequest = {
            "@": {
                name: name
            },
            content: [
                {
                    "@": {
                        type: "text/html"
                    },
                    "#": htmlContent
                },
                {
                    "@": {
                        type: "text/plain"
                    },
                    "#": plainContent
                }
            ]
        };
        if (!!contactcid && contactcid !== "") {
            signatureRequest["cid"] = contactcid;
        }
        const request = {
            CreateSignatureRequest: {
                "@": {
                    xmlns: ZIMBRA_ACCOUNT
                },
                signature: signatureRequest
            }
        };
        return this.batchRequest2(request);
    }

    getSignatures(): Observable<Signature[]> {
        console.log("[PreferenceService][getSignatures]");

        const request = {
            GetSignaturesRequest: {
                "@": {
                    "xmlns": ZIMBRA_ACCOUNT,
                    "requestId": "0"
                }
            }
        };
        const t1 = new Date().getTime();
        return this.batchRequest(request).pipe(tap(res => {
            const t2 = new Date().getTime();
            const performance = (t2 - t1) / 1000;
            if (!!this.configService.get("logSentryPerf")) {
              // console.log(`[PreferenceService][API][getSignatures] took ${performance} s for API call`);
              CommonUtils.sentryLog(`[API][getSignatures] took ${performance} s for API call`, performance);
            }
          }),
          map(res => {
            if (res.GetSignaturesResponse && res.GetSignaturesResponse[0].signature) {
                let signatures = res.GetSignaturesResponse[0].signature;
                signatures = Array.isArray(signatures) ? signatures : [signatures];
                console.log("[PreferenceService][getSignatures] signatures", signatures);

                return signatures.map(signature => {
                    const data: Signature = {
                        id: signature.id,
                        name: signature.name,
                        content: signature.content ? signature.content[0]._content.replace(/(?:\r\n|\r|\n)/g, "<br />")
                        .replace(/\t/g, "    ").replace(/                    /ig, "") : "",
                        type: signature.content ? signature.content[0].type : "text/html",
                    };
                    if (signature.cid) {
                        data.cid = signature.cid[0]._content;
                    }
                    return data;
                });
            } else {
                return of([]);
            }
        }));
    }

    ////


    showMessage(translationKey: string, duration: number = 2000): void {
        if (translationKey && translationKey !== "") {
            this.translate.get(translationKey).pipe(take(1)).subscribe((text: string) => {
                this.vncLibraryService.openSnackBar(text, "simple",
                "", "", duration, "bottom", "left").subscribe(res => {
                });
            });
        }
    }

    navigateTo(title?: string): void {
    //   if (this.showOfflineMessage()) {
    //     return;
    //   }
      if (title && title === "PREFERENCES_LBL") {
        this.route.navigate(["/"]);
        this.mailBroadcaster.broadcast("CALL_FROM_USER_LIST");
      } else {
        if (this.isMobileScreen) {
        this.route.navigate(["/preferences/", "prefOptions"]);
        } else {
          this.navigateToLastTab();
        }
      }
    }

    navigateToLastTab() {
      if (this.showOfflineMessage()) {
        return;
      }
      let lastVisitedTab = localStorage.getItem("lastVisitedTab");
      if (lastVisitedTab === "/contacts") {
          this.route.navigate([lastVisitedTab]);
      } else if (lastVisitedTab === "/briefcase") {
          this.route.navigate([lastVisitedTab]);
      } else if (lastVisitedTab === "/calendar") {
          this.route.navigate([lastVisitedTab]);
      } else {
          this.route.navigate(["/"]);
          this.mailBroadcaster.broadcast("CALL_FROM_USER_LIST");
          this.mailBroadcaster.broadcast(MailConstants.BROADCAST_MAIL_SELECTED_TAB);
      }
    }

    deleteDataSources(accounts): Observable<any> {
        const request: any = {};
        request.DeleteDataSourceRequest = [];
        let i = 0;
        for (const account of accounts) {
            let r: any = {
                "@": {
                    xmlns: "urn:zimbraMail",
                    requestId: i
                },
                pop3: {
                    "@": {
                        id: account.id
                    }
                }
            };
            if (account.connectionType === "IMAP") {
                r = {
                    "@": {
                        xmlns: "urn:zimbraMail",
                        requestId: i
                    },
                    imap: {
                        "@": {
                            id: account.id
                        }
                    }
                };
            }
            request.DeleteDataSourceRequest.push(r);
            i++;
        }
        request.NoOpRequest = {
            "@": {
                xmlns: "urn:zimbraMail",
                requestId: i
            }
        };
        return this.batchRequest2(request);
    }

    testDataSource(data): Observable<any> {
        let request: any;
        if (data.accountType === "POP") {
            request = {
                TestDataSourceRequest: {
                    "@": {
                        xmlns: "urn:zimbraMail"
                    },
                    pop3: {
                        "@": data
                    }
                }
            };
        } else {
            request = {
                TestDataSourceRequest: {
                    "@": {
                        xmlns: "urn:zimbraMail"
                    },
                    imap: {
                        "@": data
                    }
                }
            };
        }
        if (data.connectionType === "IMAP") {
            delete request.TestDataSourceRequest.pop3;
            request.TestDataSourceRequest["imap"] = data;
        }
        return this.batchRequest2(request);
    }

    moveFolderToTrash(ids: string): Observable<any> {
        const request = {
            FolderActionRequest: {
                "@": {
                    xmlns: "urn:zimbraMail"
                },
                action: {
                    "@": {
                        id: ids,
                        op: "trash"
                    }
                }
            }
        };
        return this.batchRequest2(request);
    }

    modifyIdentity(identity): Observable<any> {
        const request = {
            ModifyIdentityRequest: {
                "@": {
                    xmlns: "urn:zimbraAccount",
                    requestId: 0
                },
                identity: {
                    "@": {
                        id: identity.id
                    },
                    a: identity.a
                }
            },
            NoOpRequest: {
                "@": {
                    xmlns: "urn:zimbraMail",
                    requestId: 1
                }
            }
        };
        return this.batchRequest2(request);
    }

    getIdentities(): Observable<Identity> {
        const request = {
            GetIdentitiesRequest: {
                "@": {
                    xmlns: "urn:zimbraAccount"
                }
            }
        };
        return this.batchRequest2(request).pipe(map(res => {
            let identity: Identity = null;
            if (Array.isArray(res.GetIdentitiesResponse) &&
                Array.isArray(res.GetIdentitiesResponse[0].identity)) {
                identity = {
                    id: res.GetIdentitiesResponse[0].identity[0].id,
                    name: res.GetIdentitiesResponse[0].identity[0].name,
                    attributes: this.getIdentityAttribute(res.GetIdentitiesResponse[0].identity[0]._attrs)
                };
            }
            return identity;
        }));

    }

    getIdentityAttribute(identity: any): IdentityAttribute[] {
        const identityAttribute: IdentityAttribute[] = [];
        for (const key in identity) {
            if (identity.hasOwnProperty(key)) {
              const preference: IdentityAttribute = {
                name: key ,
                value: identity[key]
              };
              identityAttribute.push(preference);
            }
          }
          return identityAttribute;
    }
    createFolder(name): Observable<any> {
        const request = {
            CreateFolderRequest: {
                "@": {
                    xmlns: "urn:zimbraMail",
                    requestId: 0
                },
                folder: {
                    "@": {
                        name: name,
                        l: 1,
                        fie: 1
                    }
                }
            }
        };
        return this.batchRequest2(request);
    }

    renameFolder(id, name): Observable<any> {
        const request = {
            FolderActionRequest: {
                "@": {
                    xmlns: "urn:zimbraMail"
                },
                action: {
                    "@": {
                        op: "rename",
                        id: id,
                        name: name
                    }
                }
            }
        };
        return this.batchRequest2(request);
    }

    processDataSources(listAccount): Observable<any> {
        const response = new Subject<any>();
        if (!Array.isArray(listAccount)) {
            listAccount = [listAccount];
        }

        const newAccounts = listAccount.filter((account) => {
            return !isNaN(+account.id);
        });

        const oldAccounts = listAccount.filter((account) => {
            return isNaN(+account.id);
        });

        const modifyDataSource = [];
        const queues = [];
        const folderRequest = [];
        if (newAccounts.length > 0) {
            for (const account of newAccounts) {
                let i = 0;
                folderRequest.push(this.createFolder(account.name).pipe(map(res => {
                    return this.generateDataSource(account, res, i);
                })));
                i++;
            }
        }

        if (oldAccounts.length > 0) {
            let i = 0;
            for (const account of oldAccounts) {
                if (account.smtpPort === "-1") {
                    delete account.smtpPort;
                }
                let r: any = {
                    "@": {
                        xmlns: "urn:zimbraMail",
                        requestId: i
                    },
                    pop3: {
                        "@": account
                    }
                };
                if (account.accountType === "IMAP") {
                    r = {
                        "@": {
                            xmlns: "urn:zimbraMail",
                            requestId: i
                        },
                        imap: {
                            "@": account
                        }
                    };
                }
                this.renameFolder(account.l, account.name);
                modifyDataSource.push(r);
                i++;
            }
            queues.push(this.modifyDataSource(modifyDataSource));
        }
        console.log(folderRequest, queues);
        if (folderRequest.length > 0) {
            forkJoin(folderRequest).subscribe(results => {
                console.log("[folderRequest] createDataSource", results);
                queues.push(this.createDataSource(results));
                forkJoin(queues).subscribe(results2 => {
                    response.next(results2);
                });
            });
        } else {
            if (queues.length > 0) {
                forkJoin(queues).subscribe(results2 => {
                    response.next(results2);
                });
            } else {
                response.next([]);
            }
        }
        return response.asObservable().pipe(take(1));
    }

    generateDataSource(account, data, i): any {
        if (data.CreateFolderResponse && data.CreateFolderResponse[0].folder) {
            account.l = data.CreateFolderResponse[0].folder[0].id;
            let r: any = {
                "@": {
                    xmlns: "urn:zimbraMail",
                    requestId: i
                },
                pop3: {
                    "@": account
                }
            };

            if (account.accountType === "IMAP") {
                r = {
                    "@": {
                        xmlns: "urn:zimbraMail",
                        requestId: i
                    },
                    imap: {
                        "@": account
                    }
                };
            }
            return r;
        }
        return null;
    }

    modifyWhiteBlackList(blackList, whiteList): Observable<any> {
        const request: any = {
            ModifyWhiteBlackListRequest: {
                "@": {
                    xmlns: "urn:zimbraAccount"
                }
            }
        };
        if (blackList.length > 0) {
            request.ModifyWhiteBlackListRequest.blackList = {
                addr: blackList
            };
        }
        if (whiteList.length > 0) {
            request.ModifyWhiteBlackListRequest.whiteList = {
                addr: whiteList
            };
        }
        return this.batchRequest2(request);
    }

    getWhiteBlackList(): Observable<{ blackList: any[], whiteList: any[] }> {
        const request = {
            GetWhiteBlackListRequest: {
                "@": {
                    xmlns: "urn:zimbraAccount"
                }
            }
        };
        return this.batchRequest2(request).pipe(map(res => {
            let blackList: any[] = [];
            let whiteList: any[] = [];
            if (res.GetWhiteBlackListResponse) {
                if (res.GetWhiteBlackListResponse[0].blackList && res.GetWhiteBlackListResponse[0].blackList[0].addr) {
                    blackList = res.GetWhiteBlackListResponse[0].blackList[0].addr;
                    if (!Array.isArray(blackList)) {
                        blackList = [blackList];
                    }
                }
                if (res.GetWhiteBlackListResponse[0].whiteList && res.GetWhiteBlackListResponse[0].whiteList[0].addr) {
                    whiteList = res.GetWhiteBlackListResponse[0].whiteList[0].addr;
                    if (!Array.isArray(whiteList)) {
                        whiteList = [whiteList];
                    }
                }
            }
            return { blackList, whiteList };
        }));
    }

    getUploadAttachmentLimit(): Observable<any> {
        return this.http.get(this.configService.API_URL + "/api/getInfo?sections=attrs",
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1), map((res: any) => {
                localStorage.setItem("sectionAttributes", JSON.stringify(res));
                const sectionAttributes = JSON.parse(localStorage.sectionAttributes);
                this.configService.set(
                    "zimbraFeatureChangePasswordEnabled",
                    sectionAttributes.attrs._attrs.zimbraFeatureChangePasswordEnabled
                );
                return res.attSizeLimit;
            }));
    }

    getDisplayUserType (): Observable<any> {
        const request = {
            GetInfoRequest: {
                "@": {
                    "xmlns": ZIMBRA_ACCOUNT,
                    "sections": "props"
                }
            }
        };
        return this.batchRequest(request);
    }

    setDisplayUserType(value: string): Observable<any> {
        return this.http.post(this.configService.API_URL + "/api/modifyPropertyDisplayUserType", { prop: value },
        { headers: CommonUtils.getZimbraHeader() }).pipe(take(1));
    }

    importFromFile(file, body): Observable<any> {
        const formData = new FormData();
        formData.append("file", file);
        return this.http.post(this.configService.API_URL + "/api/importFromFile?destination=" + body.destination + "&types=" + body.types +
        "&resolve=" + body.resolve + "&fmt=" + body.fmt + "&email=" + body.email, formData,
            { headers: CommonUtils.getZimbraHeader().delete("Content-Type").delete("Accept") }).pipe(take(1));
    }

    exportToFile(body: any): void {
        let url = this.configService.API_URL + "/api/exportToFile/?fmt=" + body.fmt + "&start=" + body.start + "&end=" + body.end +
        "&query=" + body.query + "&meta=" + body.meta + "&icalattach=" + body.icalattach + "&csvfmt=" + body.csvfmt +
        "&email=" + body.email + "&filename=" + body.filename + "&destination=" + body.destination + "&types=" + body.types;
        if (environment.isElectron) {
          url = this.configService.API_URL + "/api/exportToFile/" + body.filename + "." + body.fmt + "/?fmt=" + body.fmt + "&start=" + body.start + "&end=" + body.end +
          "&query=" + body.query + "&meta=" + body.meta + "&icalattach=" + body.icalattach + "&csvfmt=" + body.csvfmt +
          "&email=" + body.email + "&filename=" + body.filename + "&destination=" + body.destination + "&types=" + body.types + "&token=" + localStorage.getItem("token");
          ElectronService.downloadFile(url, body.filename + "." + body.fmt);
        } else {
          window.open(url, "_system");
      }
    }

    discoverRightsRequest(): Observable<any> {
        const request = {
            DiscoverRightsRequest: {
                "@": {
                    xmlns: ZIMBRA_ACCOUNT
                },
                "right": ["sendAs", "sendOnBehalfOf"]
            }
        };
        return this.http.post(this.configService.API_URL + "/api/batchRequest", request,
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1));
    }

    identityOperation(createIdentity: any[], modifyIdentity: any[], deleteIdentity: any[]): Observable<any> {
        const deleteRequest: any[] = [];
        const createRequest: any[] = [];
        const modifiedRequest: any[] = [];
        if (createIdentity.length > 0) {
            createIdentity.forEach( item => {
                createRequest.push({
                    "@": {
                        "xmlns": "urn:zimbraAccount",
                        "requestId": "0"
                    },
                    identity: {
                        "@": {
                            "name": item.name
                        },
                        a: item.a
                    }
                });
            });
        }

        if (modifyIdentity.length > 0 ) {
            modifyIdentity.forEach( item => {
                modifiedRequest.push({
                    "@": {
                        "xmlns": "urn:zimbraAccount",
                        "requestId": "0"
                    },
                    identity: {
                        "@": {
                            "id": item.id
                        },
                        a: item.a
                    }
                });
            });
        }

        if (deleteIdentity.length > 0 ) {
            deleteIdentity.forEach( id => {
                deleteRequest.push({
                    "@": {
                        "xmlns": "urn:zimbraAccount",
                        "requestId": "0"
                    },
                    identity: {
                        "@": {
                            "id": id
                        }
                    }
                });
            });
        }
        const body = {
            "DeleteIdentityRequest": deleteRequest,
            "CreateIdentityRequest": createRequest,
            "ModifyIdentityRequest": modifiedRequest
        };
        return this.http.post(this.configService.API_URL + "/api/batchRequest", body,
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1));
    }
}
