
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div>
    <div class="header" id="calendar-date-range-dialog-header">
        {{'CALENDARS.SET_DATE_RANGE' | translate }}
    </div>
    <div class="content" id="calendar-date-range-dialog-content">
        {{ 'CALENDARS.SELECT_DATE_RANGE_CONTENT' | translate }}
    </div>
    <div class="dates">
        <div class="start_date">
            <mat-form-field class="start-date-width">
                <input matInput [matDatepicker]="startDate" placeholder="{{ 'CALENDARS.START_DATE_LBL' | translate }}:"
                    [formControl]="startDateFormControl" (dateChange)="handleStartDateChanges($event)" disabled>
                <mat-datepicker-toggle matSuffix [for]="startDate" id="calendar-date-range-dialog-start-toggle">
                    <mat-icon matDatepickerToggleIcon>today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #startDate disabled="false"></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="end_date">
            <mat-form-field class="end-date-width">
                <input matInput [matDatepicker]="endDate" placeholder="{{ 'CALENDARS.END_DATE_LBL' | translate }}:"
                    [formControl]="endDateFormControl" (dateChange)="handleEndDateChanges($event)" disabled>
                <mat-datepicker-toggle matSuffix [for]="endDate" id="calendar-date-range-dialog-end-toggle">
                    <mat-icon matDatepickerToggleIcon>today</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #endDate disabled="false"></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="footer">
        <button mat-button (click)="dialogRef.close()" id="calendar-date-range-dialog-cancel-btn">
            {{ 'CANCEL' | translate }}
        </button>
        <button mat-button (click)="close()" id="calendar-date-range-dialog-save-btn">
            {{ 'COMMON.SAVE' | translate }}
        </button>
    </div>
</div>