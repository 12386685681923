
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

// import { set, get, clear, Store, del } from "idb-keyval";
const idb = require("idb-keyval/dist/idb-keyval-cjs-compat.min.js");

export class IdbUtils {
  static DB_NAME = "vncmail-database";
  static VERSION = 1;
  static KEY_VALUE_STORE = "keyval";

  static getStore() {
    const customStore = new idb.Store(this.DB_NAME, this.KEY_VALUE_STORE);
    return customStore;
  }

  static deleteDB() {
    idb.clear(this.getStore());
  }

  static getValueByKey(key) {
    return idb.get(key, this.getStore());
  }

  static setValue(key: any, value: any) {
    return idb.set(key, value, this.getStore());
  }

  static deleteValue(key) {
    return idb.del(key, this.getStore());
  }

  static clearAll() {
    idb.clear(this.getStore());
  }
}
