
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "../../../actions";
import { ContactsActionTypes } from "../actions/contact-list.action";
import { ContactFolder } from "../../models/contact-folder.model";
import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";


export interface ContactFolderState extends EntityState<ContactFolder> {
  selectedContactFolder: ContactFolder;
  trashFolder: ContactFolder;
  contactsList: any[];
  routingFolderTitle: string;
  isLoading: boolean;
  isLoaded: boolean;
}

export const contactFolderAdapter: EntityAdapter<ContactFolder> = createEntityAdapter<ContactFolder>({
  selectId: (contactFolder: ContactFolder) => contactFolder.id,
  sortComparer: sortByFirstCharacter
});

export function sortByFirstCharacter(contactFolder1: ContactFolder, contactFolder2: ContactFolder): number {
  return contactFolder1.name.charCodeAt(0) - contactFolder2.name.charCodeAt(0);
}

export const initialState: ContactFolderState = contactFolderAdapter.getInitialState({
  selectedContactFolder: null,
  trashFolder: null,
  contactsList: [],
  routingFolderTitle: "",
  isLoading: false,
  isLoaded: false
});

export function contactFolderReducer(state = initialState, action: Action): ContactFolderState {
  switch (action.type) {
    case ContactsActionTypes.SELECT_CONTACT_LIST: {
      return {
        ...state,
       selectedContactFolder: action.payload
      };
    }

    case ContactsActionTypes.SET_TRASH_FOLDER: {
      return {
        ...state,
       trashFolder: action.payload
      };
    }

    case ContactsActionTypes.SET_CONTACTS_LIST: {
      return {
        ...state,
        contactsList: action.payload
      };
    }

    case ContactsActionTypes.LOAD_FOLDERS: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ContactsActionTypes.LOAD_FOLDERS_SUCCESS: {
      if (action.payload.folders[0].id !== "2") {
        return contactFolderAdapter.addAll(action.payload.folders, {
          ...state,
          isLoading: false,
          isLoaded: true,
        });
      } else {
        return {
          ...state,
          isLoading: false,
          isLoaded: true,
        };
      }
    }

    case ContactsActionTypes.LOAD_FOLDERS_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case ContactsActionTypes.CREATE_CONTACT_FOLDER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ContactsActionTypes.CREATE_CONTACT_FOLDER_SUCCESS: {
      return contactFolderAdapter.addOne(action.payload.folder, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case ContactsActionTypes.CREATE_CONTACT_FOLDER_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case ContactsActionTypes.DELETE_CONTACT_FOLDER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ContactsActionTypes.DELETE_CONTACT_FOLDER_SUCCESS: {
      return contactFolderAdapter.removeOne(action.payload.folder.id, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case ContactsActionTypes.DELETE_CONTACT_FOLDER_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case ContactsActionTypes.UPDATE_CONTACT_FOLDER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case ContactsActionTypes.UPDATE_CONTACT_FOLDER_SUCCESS: {
      return contactFolderAdapter.updateOne(action.payload, {
        ...state,
        isLoading: false,
        isLoaded: true,
      });
    }

    case ContactsActionTypes.UPDATE_CONTACT_FOLDER_FAIL: {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
      };
    }

    case ContactsActionTypes.SET_ROUTED_FOLDER_TITLE: {
      return {
        ...state,
        routingFolderTitle: action.payload
      };
    }

    case ContactsActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.contactFolderState;
      return savedState ? { ...state, ...savedState } : state;
    }

    default: {
      return state;
    }
  }
}

export const _getSelectedContactFolder = (state: ContactFolderState) => state.selectedContactFolder;
export const _getTrashFolder = (state: ContactFolderState) => state.trashFolder;
export const _getRotedFolderTitle = (state: ContactFolderState) => state.routingFolderTitle;
export const _getIsLoading = (state: ContactFolderState) => state.isLoading;
export const _getIsLoaded = (state: ContactFolderState) => state.isLoaded;
export const _getContactsList = (state: ContactFolderState) => state.contactsList;
