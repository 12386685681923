
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="mobile-address-select-dialog">
    <div class="mail__dialog-header mobile_header mobie-address-list-header" *ngIf="!isSearchView">
        <div class="left-header">
            <div mat-button (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </div>
            <div class="header_lbl disable-select">
                <span *ngIf="type === 'to'">{{'TO_MAIL_LABLE' | translate}}</span>
                <span *ngIf="type === 'cc'">{{'CC_MAIL_LABLE' | translate}}</span>
                <span *ngIf="type === 'bcc'">{{'BCC_MAIL_LABLE' | translate}}</span>
            </div>
        </div>
        <div class="right-header">
            <div class="count" *ngIf="checkedIds.length > 0">
                <span>{{checkedIds.length}}</span>
            </div>
            <div (click)="selectUnselectAll()">
                <mat-icon class="disable-select">done_all</mat-icon>
            </div>
            <div (click)="showSearch(true)">
                <mat-icon class="disable-select">search</mat-icon>
            </div>
        </div>
    </div>
    <div *ngIf="isSearchView" class="search-select-header">
        <div>
            <button mat-icon-button (click)="showSearch(false)">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
        <div class="mobile-search-field">
            <mat-form-field class="mobile-search-form-field">
                <input matInput type="text" (keyup.enter)="searchEmailAddressesEnter()" [formControl]="searchForm" id="searchInputFieldParticipants" #searchFormInput />
            </mat-form-field>
        </div>
        <div>
            <button mat-icon-button [matMenuTriggerFor]="searchAddressMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
        </div>
    </div>
    <div class="mail__dialog-body mail__dialog-body--pad">
        <div class="content">
            <cdk-virtual-scroll-viewport [itemSize]="48" *ngIf="allLoadedEmailAddresses.length > 0" style="height: calc(100vh - 50px);" [minBufferPx]="minBufferPx" [maxBufferPx]="maxBufferPx">
                <mat-list id="addressList" style="padding-left: 10px;">
                    <span class="suggestion" *cdkVirtualFor="let address of allLoadedEmailAddresses; index as i; trackBy: trackByFn;">
                        <div class="mail-checkbox"
                            (click)="$event.preventDefault();$event.stopPropagation();onSelectAddress(i);">
                            <div *ngIf="checkedAddressStatus[i]" class="mobile-checked">
                                <mat-icon>done</mat-icon>
                            </div>
                            <vp-avatar *ngIf="!checkedAddressStatus[i]" [type]="'mail-detail-avatar'" [isAddressSelect]="true" id={{address.email}}
                                [user]="{firstLastCharacters: address?.email?.charAt(0), color: '#317bbc', email: address.email}">
                            </vp-avatar>
                        </div>
                        <div class="email_name_division">
                            <h3 class="disable-select">{{address.name}}</h3>
                            <p class="disable-select">{{address.email}}</p>
                        </div>
                    </span>
                </mat-list>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
    <div class="footer" *ngIf="checkedIds.length > 0">
        <span>{{ 'ADD_CONTACT' | translate }}</span>
        <button mat-button (click)="submit()">
            <mat-icon class="mdi-28px" fontSet="mdi" fontIcon="mdi-arrow-right"></mat-icon>
        </button>
    </div>
    <mat-menu #searchAddressMenu="matMenu">
        <button mat-menu-item (click)="changeShowNames('contacts')">
            {{ 'CONTACTS' | translate }}
        </button>
        <button mat-menu-item (click)="changeShowNames('personalandshared')" [disabled]="!isOnline">
            {{ 'PERSONAL_AND_SHARED_CONTACTS' | translate }}
        </button>
        <button mat-menu-item (click)="changeShowNames('global')" [disabled]="!isOnline">
            {{ 'GLOBAL_ADDRESS_LIST' | translate }}
        </button>
    </mat-menu>
</div>