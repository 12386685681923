
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  CalendarDateFormatterInterface,
  DateFormatterParams
} from "./calendar-date-formatter.interface";
import { formatDate } from "@angular/common";
import { Injectable } from "@angular/core";
import { DateAdapter } from "../date-adapters/date-adapter";
import { getWeekViewPeriod } from "./util";

/**
 * This will use the angular date pipe to do all date formatting. It is the default date formatter used by the calendar.
 */
@Injectable()
export class CalendarAngularDateFormatter
  implements CalendarDateFormatterInterface {
  constructor(protected dateAdapter: DateAdapter) { }

  /**
   * The month view header week day labels
   */
  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, "EEE", locale);
  }

  public monthViewColumnHeaderInMobile({ date, locale }: DateFormatterParams): string {
    return formatDate(date, "EEE", locale).substring(0, 1);
  }

  /**
   * The month view cell day number
   */
  public monthViewDayNumber({ date, locale }: DateFormatterParams): string {
    if (date.getDate() === 1) {
      return formatDate(date, "MMM d", locale);
    }
    return formatDate(date, "d", locale);
  }

  /**
   * The month view title
   */
  public monthViewTitle({ date, locale }: DateFormatterParams): string {
    return formatDate(date, "LLLL y", locale);
  }

  /**
   * The week view header week day labels
   */
  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, "dd", locale);
  }

  /**
   * The week view sub header day and month labels
   */
  public weekViewColumnSubHeader({
    date,
    locale
  }: DateFormatterParams): string {
    return formatDate(date, "EEE", locale);
  }

  public weekViewColumnSubHeaderInMobile({
    date,
    locale
  }: DateFormatterParams): string {
    return formatDate(date, "EEE", locale).substring(0, 1);
  }

  /**
   * The week view title
   */
  public weekViewTitle({
    date,
    locale,
    weekStartsOn,
    excludeDays,
    daysInWeek
  }: DateFormatterParams): string {
    const { viewStart, viewEnd } = getWeekViewPeriod(
      this.dateAdapter,
      date,
      weekStartsOn,
      excludeDays,
      daysInWeek
    );
    const format = (dateToFormat: Date, showYear: boolean) =>
      formatDate(dateToFormat, "EEE, d", locale);
    return `${format(
      viewStart,
      viewStart.getUTCFullYear() !== viewEnd.getUTCFullYear()
    )} - ${format(viewEnd, true)}`;
  }

  /**
   * The time formatting down the left hand side of the week view
   */
  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, "h a", locale);
  }

  /**
   * The time formatting down the left hand side of the day view
   */
  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, "h a", locale);
  }

  /**
   * The day view title
   */
  public dayViewTitle({ date, locale }: DateFormatterParams): string {
    // return formatDate(date, "EEEE, MMMM d, y", locale);
    return formatDate(date, "EEE, d", locale);
  }

  /**
   * The day three view header day labels
   */
  public dayThreeViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, "dd", locale);
  }

  /**
   * The day three view sub header day and month labels
   */
  public dayThreeViewColumnSubHeader({
    date,
    locale
  }: DateFormatterParams): string {
    return formatDate(date, "EEE", locale).substring(0, 1);
  }

  /**
   * The day three view title
   */
  public dayThreeViewTitle({ date, locale }: DateFormatterParams): string {
    // return formatDate(date, "EEEE, MMMM d, y", locale);
    return formatDate(date, "EEE, d", locale);
  }

  /**
   * The list view title
  */
  public listViewTitle({ date, locale }: DateFormatterParams): string {
    // return formatDate(date, "EEEE, MMMM d, y", locale);
    return formatDate(date, "EEE, d", locale);
  }

  /**
   * The year title
   */
  public yearViewTitle({ date, locale }: DateFormatterParams): string {
    return formatDate(date, "MMMM y", locale);
  }

  public monthOnlyTitle({ date, locale }: DateFormatterParams): string {
    if (date.getFullYear() !== new Date().getFullYear()) {
      return formatDate(date, "MMM y", locale);
    } else {
      return formatDate(date, "MMMM", locale);
    }
  }

  public miniViewHeaderTitle({ date, locale }: DateFormatterParams): string {
    return formatDate(date, "EEEEE", locale);
  }

  public miniViewDayNumber({ date, locale }: DateFormatterParams): string {
    return formatDate(date, "d", locale);
  }

  public workWeekViewTitle({
    date,
    locale,
    weekStartsOn,
    excludeDays,
    daysInWeek
  }: DateFormatterParams): string {
    const { viewStart, viewEnd } = getWeekViewPeriod(
      this.dateAdapter,
      date,
      weekStartsOn,
      excludeDays,
      daysInWeek,
    );

    const format = (dateToFormat: Date, showYear: boolean) =>
      formatDate(dateToFormat, "EEE, d", locale);

    return `${format(viewStart, viewStart.getUTCFullYear() !== viewEnd.getUTCFullYear())} - ${format(viewEnd, true)}`;
  }
}
