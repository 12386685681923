
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, Output, EventEmitter, TemplateRef } from "@angular/core";
import { trigger, style, transition, animate, AnimationTriggerMetadata } from "@angular/animations";
import { CalendarEvent } from "calendar-utils";
import { trackByEventId } from "../common/util";

export const collapseAnimation: AnimationTriggerMetadata = trigger("collapse", [
  transition("void => *", [
    style({ height: 0, overflow: "hidden" }),
    animate("150ms", style({ height: "*" }))
  ]),
  transition("* => void", [
    style({ height: "*", overflow: "hidden" }),
    animate("150ms", style({ height: 0 }))
  ])
]);

@Component({
  selector: "vp-calendar-open-day-events",
  template: `
    <ng-template
      #defaultTemplate
      let-events="events"
      let-eventClicked="eventClicked"
      let-isOpen="isOpen"
      let-trackByEventId="trackByEventId"
    >
      <div class="cal-open-day-events" [@collapse] *ngIf="isOpen">
        <div
          *ngFor="let event of events; trackBy: trackByEventId"
          [ngClass]="event?.cssClass"
          mwlDraggable
          [class.cal-draggable]="event.draggable"
          dragActiveClass="cal-drag-active"
          [dropData]="{ event: event }"
          [dragAxis]="{ x: event.draggable, y: event.draggable }"
        >
          <span
            class="cal-event"
            [ngStyle]="{ backgroundColor: event.color?.primary }"
          >
          </span>
          &ngsp;
          <vp-calendar-appointment-title
            [event]="event"
            [customTemplate]="eventTitleTemplate"
            view="month"
            (vpCalClick)="eventClicked.emit({ event: event })"
          >
          </vp-calendar-appointment-title>
          &ngsp;
          <vp-calendar-appointment-actions
            [event]="event"
            [customTemplate]="eventActionsTemplate"
          >
          </vp-calendar-appointment-actions>
        </div>
      </div>
    </ng-template>
    <ng-template
      [ngTemplateOutlet]="customTemplate || defaultTemplate"
      [ngTemplateOutletContext]="{
        events: events,
        eventClicked: eventClicked,
        isOpen: isOpen,
        trackByEventId: trackByEventId
      }"
    >
    </ng-template>
  `,
  animations: [collapseAnimation]
})
export class CalendarOpenDayAppointmentsComponent {
  @Input() isOpen: boolean = false;

  @Input() events: CalendarEvent[];

  @Input() customTemplate: TemplateRef<any>;

  @Input() eventTitleTemplate: TemplateRef<any>;

  @Input() eventActionsTemplate: TemplateRef<any>;

  @Output() eventClicked: EventEmitter<{ event: CalendarEvent }> = new EventEmitter<{ event: CalendarEvent; }>();

  trackByEventId = trackByEventId;
}
