
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Directive, Output, HostListener, EventEmitter, Renderer2, ElementRef, Input, AfterViewInit, OnDestroy } from "@angular/core";
import { throttleable } from "../../common/utils/throttle";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { Subject } from "rxjs";
import { takeUntil, distinctUntilChanged } from "rxjs/operators";

@Directive({
    selector: "[vpFileDragDrop]"
})
export class DragDropDirective implements AfterViewInit, OnDestroy {
    private isAlive$ = new Subject<boolean>();
    div: any;
    @Output() onFileDropped = new EventEmitter<any>();
    @Input() placeholder: string = "";
    constructor(private renderer: Renderer2, private hostElement: ElementRef, private broadcaster: MailBroadcaster) {
        renderer.addClass(hostElement.nativeElement, "vp-file-drag-drop");
        this.broadcaster.on<any>("onDragOver").pipe(takeUntil(this.isAlive$)).subscribe(val => {
            renderer.addClass(hostElement.nativeElement, "has-files-drag-over");
        });
        this.broadcaster.on<any>("onDrop").pipe(takeUntil(this.isAlive$)).subscribe(val => {
            renderer.removeClass(hostElement.nativeElement, "on-dragover");
            renderer.removeClass(hostElement.nativeElement, "has-files-drag-over");
        });
    }

    ngAfterViewInit(): void {
        this.div = document.createElement("div");
        this.div.id = "drag-drop-placeholder-div";
        this.div.className = "drag-drop-placeholder disable-select";
        this.div.innerHTML = `
          <div class="drag-drop-placeholder-text">
            <mat-icon class="mdi-24px mat-icon mdi mdi-content-copy mat-icon-no-color"
        role="img" aria-hidden="true"></mat-icon>
            ${this.placeholder}
          </div>`;
        this.renderer.appendChild(this.hostElement.nativeElement, this.div);
    }

    @HostListener("dragover", ["$event"])
    onDragOver(event) {
        event.preventDefault();
        event.stopPropagation();
        this.renderer.addClass(this.hostElement.nativeElement, "on-dragover");
        console.log("[onDragOver]", event);
        this.div.style.height = this.hostElement.nativeElement.offsetHeight + "px";
        this.div.style.lineHeight = this.hostElement.nativeElement.offsetHeight + "px";
        return false;
    }

    @HostListener("dragleave", ["$event"])
    @throttleable(2000)
    onDragLeave(event) {
        event.preventDefault();
        event.stopPropagation();
        this.renderer.removeClass(this.hostElement.nativeElement, "on-dragover");
        this.renderer.removeClass(this.hostElement.nativeElement, "has-files-drag-over");
        console.log("[onDragLeave]", event);
        return false;
    }

    @HostListener("drop", ["$event"])
    onDrop(event) {
        event.preventDefault();
        event.stopPropagation();
        this.renderer.removeClass(this.hostElement.nativeElement, "on-dragover");
        this.renderer.removeClass(this.hostElement.nativeElement, "has-files-drag-over");
        let files = event.dataTransfer.files;
        if (files.length > 0) {
            this.onFileDropped.emit(files);
        }
        console.log("[onDrop]", event);
        return false;
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }
}
