
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { AfterContentInit, Directive, ElementRef, EventEmitter, Output } from "@angular/core";
import { OnDestroy } from "@angular/core";

@Directive({
  selector: "[vpSwipe]"
})
export class SwipeDirective implements AfterContentInit, OnDestroy {
  @Output() onSwipeLeft = new EventEmitter();
  @Output() onSwipeRight = new EventEmitter();
  private swipeLeftRef: any;
  private swipeRightRef: any;

  constructor(
    private el: ElementRef
    ) {
  }

  ngOnDestroy(): void {
    if (!!this.swipeLeftRef) {
      try {
        this.swipeLeftRef.off("swipeleft");
      } catch (error) {
        console.error("swipe-directive ondestroy error: ", error);
      }
      try {
        this.swipeLeftRef.destroy();
      } catch (error) {
        console.error("swipe-directive ondestroy error: ", error);
      }
    }
    if (!!this.swipeRightRef) {
      try {
        this.swipeRightRef.off("swiperight");
      } catch (error) {
        console.error("swipe-directive ondestroy error: ", error);
      }
      try {
        this.swipeRightRef.destroy();
      } catch (error) {
        console.error("swipe-directive ondestroy error: ", error);
      }
    }
  }

  ngAfterContentInit() {
      this.swipeLeftRef = new Hammer(this.el.nativeElement).on("swipeleft", () => {
        this.onSwipeLeft.emit();
      });
      this.swipeRightRef = new Hammer(this.el.nativeElement).on("swiperight", () => {
        this.onSwipeRight.emit();
      });
  }
}
