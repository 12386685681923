
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="add_delegate_popup" class="mail_folder-create_dialog">
  <div class="content add-delegate-content">
    <div id="add_delegate_popup_header" *ngIf="!isEdit" class="message">
      {{ "PREFERENCES.ADD_DELEGATE" | translate }}
    </div>
    <div id="add_delegate_popup_header" *ngIf="isEdit" class="edit-delegated-permission">
      {{ "EDIT_DELEGATE_PERMISSIONS" | translate }} - {{searchFromEmail.value}}
    </div>
    <div class="search-share-data" *ngIf="!isEdit">
      <mat-form-field>
        <mat-label>{{'EMAIL_LBL' | translate }}:</mat-label>
        <input type="text" [formControl]="searchFromEmail" matInput [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" class="vnc_mail_autocomplete_panel" [autoActiveFirstOption]=true>
          <mat-option *ngFor="let emailItem of filteredFromEmails" [value]="emailItem">
            <span class="suggestion">
              <vp-avatar [type]="'mail-detail-avatar'" [user]="{firstLastCharacters: emailItem.email?.charAt(0), color: '#317bbc', email: emailItem.email}">
              </vp-avatar>
              <div class="email_name_division">
                <h3 class="disable-select">{{emailItem.name}}</h3>
                <p class="disable-select">{{emailItem.email}}</p>
              </div>
            </span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="delegate-rights-checkbox">
      <mat-checkbox [(ngModel)]="sendAs">{{ 'SENDAS' | translate }}</mat-checkbox>
      <mat-checkbox [(ngModel)]="sendOnBehalfOf">{{ 'SENDONBEHALFOF' | translate }}</mat-checkbox>
    </div>
    <div class="actions">
      <a id="add_delegate_createbtn" [class.action-disabled]="!(searchFromEmail.value && searchFromEmail.value !== '') || (!sendAs && !sendOnBehalfOf)" (click)="AddDelegateAction()">{{ 'OK_LBL' | translate }}</a>
      <a id="add_delegate_cancelbtn" (click)="closeDialog()">{{ 'CANCEL_MAIL_LABEL' | translate }}</a>
    </div>
  </div>
</div>