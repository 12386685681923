
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from "src/app/actions";
import { Message } from "../../shared/models";
import { Update } from "@ngrx/entity";

export const IS_LOADING = "[MAIL] Is Loading";
export const LOAD_MAIL_SUCCESS = "[MAIL] Load Mails Success";
export const LOAD_MAIL_FAILED = "[MAIL] Load Mails Failed";

export const SELECT_MESSAGE = "[MESSAGE] Select Message";
export const SELECT_MESSAGE_DETAIL = "[MESSAGE] Select Mail Detail";
export const CHECK_MESSAGE = "[MESSAGE] Check Message";
export const CHECK_ALL_MESSAGES = "[MESSAGE] Check All Messages";
export const UNSELECT_MESSAGE = "[MESSAGE] Unselect Message";
export const UNCHECK_MESSAGE = "[MESSAGE] Uncheck Message";
export const RESET_SELECTED_MESSAGES = "[MESSAGE] Reset Selected Messages";
export const RESET_CHECKED_MESSAGES = "[MESSAGE] Reset Checked Messages";
export const RESET_MESSAGES = "[MESSAGE] Reset Meesages";
export const RESET_MESSAGE_STATE = "[MESSAGE] Reset State";
export const UPDATE_MANY_MESSAGES_SUCCESS = "[MESSAGE] Update Many Messages Success";
export const UPDATE_MANY_MESSAGES_FAIL = "[MESSAGE] Update Many Messages Fail";
export const REMOVE_MESSAGE = "[MESSAGE] Remove MessageS";
export const REMOVE_MESSAGE_FAIL = "[MESSAGE] Remove Messages Fail";
export const REMOVE_ALL_MESSAGES_SUCCESS = "[MESSAGE] Remove All Messages Success";
export const REMOVE_MESSAGE_SUCCESS = "[MESSAGE] Remove Message Success";
export const REMOVE_MANY_MESSAGES = "[MESSAGE] Remove Many Messages";
export const REMOVE_MESSAGES_FROM_FOLDER = "[MESSAGE] Remove Messages From Folder";
export const UPDATE_MESSAGE_SUCCESS = "[MESSAGE] Update Message success";
export const UPDATE_SEALED_DATA = "[MESSAGE] Update Sealed Data For Message";

export class IsLoadingAction implements Action {
  readonly type = IS_LOADING;
  constructor() {}
}

export class LoadMailSuccessAction implements Action {
  readonly type = LOAD_MAIL_SUCCESS;
  constructor(public payload: Message[]) {}
}

export class LoadMailFailedAction implements Action {
  readonly type = LOAD_MAIL_FAILED;
  constructor() {}
}


export class SelectMessageAction implements Action {
  readonly type = SELECT_MESSAGE;
  constructor(public payload: string) {}
}

export class SetMessageDetailId implements Action {
  readonly type = SELECT_MESSAGE_DETAIL;
  constructor(public payload: string) {}
}

export class UnSelectMessageAction implements Action {
  readonly type = UNSELECT_MESSAGE;
  constructor(public payload: string) {}
}

export class ResetSelectedMessagesAction implements Action {
  readonly type = RESET_SELECTED_MESSAGES;
  constructor() {}
}

export class CheckMessageAction implements Action {
  readonly type = CHECK_MESSAGE;
  constructor(public payload: string) {}
}

export class CheckAllMessagesAction implements Action {
  readonly type = CHECK_ALL_MESSAGES;
  constructor(public payload: string[]) {}
}

export class UnCheckMessageAction implements Action {
  readonly type = UNCHECK_MESSAGE;
  constructor(public payload: string) {}
}

export class ResetCheckedMessagesAction implements Action {
  readonly type = RESET_CHECKED_MESSAGES;
  constructor() {}
}

export class ResetMessagesAction implements Action {
  readonly type = RESET_MESSAGES;
  constructor() {}
}

export class ResetMessageState implements Action {
  readonly type = RESET_MESSAGE_STATE;
  constructor() {}
}

export class UpdateManyMessagesSuccess implements Action {
  readonly type = UPDATE_MANY_MESSAGES_SUCCESS;
  constructor(public payload: Message[]) {}
}

export class UpdateManyMessagesFail implements Action {
  readonly type = UPDATE_MANY_MESSAGES_FAIL;
  constructor() {}
}

export class RemoveMessage implements Action {
  readonly type = REMOVE_MESSAGE;
  constructor() {}
}

export class RemoveAllMessagesSuccess implements Action {
  readonly type = REMOVE_ALL_MESSAGES_SUCCESS;
  constructor() { }
}

export class RemoveMessagesSuccess implements Action {
  readonly type = REMOVE_MESSAGE_SUCCESS;
  constructor(public payload: string) { }
}

export class RemoveManyMessages implements Action {
  readonly type = REMOVE_MANY_MESSAGES;

  constructor(public payload: string[]) {
  }
}

export class RemoveMessagesFromFolder implements Action {
  readonly type = REMOVE_MESSAGES_FROM_FOLDER;

  constructor(public payload: string) {
  }
}

export class RemoveMessageFail implements Action {
  readonly type = REMOVE_MESSAGE_FAIL;
  constructor() {}
}

export class UpdateMessageSuccess implements Action {
  readonly type = UPDATE_MESSAGE_SUCCESS;
  constructor(public payload: Update<Message>) {}
}

export class UpdateSealedData implements Action {
  readonly type = UPDATE_SEALED_DATA;
  constructor(public payload: {id: string, data: any}) {}
}

export type MailActions =
  | IsLoadingAction
  | LoadMailSuccessAction
  | LoadMailFailedAction
  | SelectMessageAction
  | SetMessageDetailId
  | UnSelectMessageAction
  | ResetSelectedMessagesAction
  | CheckMessageAction
  | UnCheckMessageAction
  | ResetCheckedMessagesAction
  | ResetMessagesAction
  | ResetMessageState
  | UpdateManyMessagesSuccess
  | UpdateManyMessagesFail
  | RemoveAllMessagesSuccess
  | ResetMessageState
  | RemoveMessagesSuccess
  | RemoveMessagesFromFolder
  | CheckAllMessagesAction
  | UpdateSealedData
  | UpdateMessageSuccess;
