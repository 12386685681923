
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
    Directive,
    Component,
    HostListener,
    OnDestroy,
    Input,
    ComponentRef,
    Injector,
    ComponentFactoryResolver,
    ViewContainerRef,
    ElementRef,
    ComponentFactory,
    Inject,
    Renderer2,
    TemplateRef,
    ChangeDetectorRef
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { PlacementArray, positionElements } from "positioning";
import { CalendarEvent } from "calendar-utils";
import { Observable, of, Subject, timer } from "rxjs";
import { takeUntil, take } from "rxjs/operators";
import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { ToastService } from "src/app/common/providers/toast.service";
import { CalendarRepository } from "../../repositories/calendar.repository";
import { CalenderUtils } from "../../utils/calender-utils";
import { CommonUtils } from "src/app/common/utils/common-util";
import { ElectronService } from "src/app/services/electron.service";
import { MailConstants } from "src/app/common/utils/mail-constants";
import * as moment from "moment-timezone";
import { CommonService } from "src/app/services/ common.service.";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";

@Component({
    selector: "vp-mini-calendar-tooltip-window",
    template: `
      <ng-template
        #defaultTemplate
        let-contents="contents"
        let-placement="placement"
        let-event="event"
        let-currentLocal="currentLocal"
        let-isAllDayMultiple="isAllDayMultiple"
        let-eventItem="eventItem"
        >
        <div class="cal-tooltip" [ngClass]="'cal-tooltip-' + placement">
          <div class="mini-calendar-tooltip-inner" *ngIf="!!event.eventItem">
            <div class="date-item" *ngIf="currentLocal ==='en'">{{event.date | date: "EEEE, MMMM d, y":"":currentLocal}}</div>
            <div class="date-item" *ngIf="currentLocal !=='en'">{{event.date | date: "EEEE, d. MMMM y":"":currentLocal}}</div>
            <span *ngIf="!!event.eventItem && event.eventItem.length > 0">
                <div class="appointment-item" *ngFor="let item of event.eventItem">
                    <div class="appointment-time" *ngIf="currentLocal === 'en'">
                      <span class="cal-event-title-start-time" [innerHTML]="item.inst[0]?.s | date:'hh:mm aaa': '': currentLocal"></span> -
                      <span class="cal-event-title-end-time" [innerHTML]="(item.inst[0]?.s + item.dur) | date:'hh:mm aaa': '': currentLocal"></span>
                    </div>
                    <div class="appointment-time" *ngIf="currentLocal === 'de'">
                      <span class="cal-event-title-start-time" [innerHTML]="item.inst[0]?.s | date:'HH:mm': '': currentLocal"></span> -
                      <span class="cal-event-title-end-time" [innerHTML]="(item.inst[0]?.s + item.dur) | date:'HH:mm': '': currentLocal"></span>
                    </div>
                    <div class="appointment-name" [ngStyle]="{color: item.colorCode , background: item.bgColor}">{{item.name}}</div>
                </div>
            </span>
            <span *ngIf="!!event.eventItem && event.eventItem.noData">
                <div class="appointment-name" *ngIf="currentLocal ==='en'">No Appointments</div>
                <div class="appointment-name" *ngIf="currentLocal !=='en'">Keine Termine</div>
            </span>
          </div>
        </div>
      </ng-template>
      <ng-template
        [ngTemplateOutlet]="customTemplate || defaultTemplate"
        [ngTemplateOutletContext]="{
          contents: contents,
          placement: placement,
          currentLocal: currentLocal,
          event: event,
          isAllDayMultiple: isAllDayMultiple,
          eventItem:eventItem
        }">
      </ng-template>
    `
})
export class MiniCalendarTooltipWindowComponent {
    @Input() contents: string;
    @Input() placement: string;
    @Input() event: CalendarEvent;
    @Input() customTemplate: TemplateRef<any>;
    currentLocal: string = "en";
    isAllDayMultiple: boolean = false;
    @Input() eventItem: any[] = [];
}

@Directive({
    selector: "[vpMiniCalendarTooltip]"
})
export class MiniCalendarTooltipDirective implements OnDestroy {
    @Input("vpMiniCalendarTooltip") contents: string; // tslint:disable-line no-input-rename
    @Input("tooltipPlacement") placement: PlacementArray = "auto"; // tslint:disable-line no-input-rename
    @Input("tooltipTemplate") customTemplate: TemplateRef<any>; // tslint:disable-line no-input-rename
    @Input("tooltipEvent") event: any; // tslint:disable-line no-input-rename
    @Input("tooltipAppendToBody") appendToBody: boolean = true; // tslint:disable-line no-input-rename
    @Input("tooltipDelay") delay: number | null = null; // tslint:disable-line no-input-rename
    @Input() eventItem: any[] = [];

    private tooltipFactory: ComponentFactory<MiniCalendarTooltipWindowComponent>;
    private tooltipRef: ComponentRef<MiniCalendarTooltipWindowComponent>;
    private cancelTooltipDelay$ = new Subject();
    private isAlive$ = new Subject<boolean>();
    isMobileView: boolean = false;
    currentLanguage: string = "en";
    requestSubscription$: any;
    constructor(
        private elementRef: ElementRef,
        private injector: Injector,
        private renderer: Renderer2,
        componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        private breakpointObserver: BreakpointObserver,
        private commonService: CommonService,
        private toastService: ToastService,
        private calendarRepository: CalendarRepository,
        private changeDetectorRef: ChangeDetectorRef,
        private electronService: ElectronService,
        private broadcaster: MailBroadcaster,
        @Inject(DOCUMENT) private document //tslint:disable-line
    ) {
        this.broadcaster.on<any>("HIDE_TOOLTIP_MINI_CALENDAR").subscribe(ev => {
            this.onMouseOut();
        });
        this.tooltipFactory = componentFactoryResolver.resolveComponentFactory(
            MiniCalendarTooltipWindowComponent
        );
        this.breakpointObserver
            .observe([Breakpoints.Small, Breakpoints.HandsetPortrait]).pipe(takeUntil(this.isAlive$))
            .subscribe((state: BreakpointState) => {
                if (state.matches) {
                    this.isMobileView = true;
                } else {
                    this.isMobileView = false;
                }
            });
        this.currentLanguage = this.electronService.isElectron
            ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE) : localStorage.getItem(MailConstants.MAIL_LANGUAGE);
    }

    ngOnDestroy(): void {
        this.hide();
    }

    @HostListener("mouseenter")
    onMouseOver(): void {
        if (this.isMobileView) {
            return;
        }
        const delay$: Observable<any> =
            this.delay === null ? timer(1000) : timer(this.delay);
        delay$.pipe(takeUntil(this.cancelTooltipDelay$)).subscribe(() => {
            if (!!this.event && this.event !== null && this.event.date) {
                const date = new Date(this.event.date);
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                const startTime = date.getTime();
                const endDate = date;
                endDate.setHours(24);
                const endTime = endDate.getTime();
                const allFolderQuery = this.calendarRepository.getAllFolderQuery();
                const displayEvent: any[] = [];
                const body = {
                    calExpandInstStart: startTime,
                    calExpandInstEnd: endTime,
                    limit: 500,
                    offset: 0,
                    types: "appointment",
                    query: allFolderQuery
                };
                if (this.requestSubscription$) {
                  this.requestSubscription$.unsubscribe();
                }
                this.requestSubscription$ = this.commonService.getAppointmentList(body).pipe(take(1)).subscribe(res => {
                    console.log("minicaltooltipdirective: ", res);
                    if (!!res && res.appt) {
                        this.eventItem = res.appt.filter(app => app.l !== "3");
                        this.eventItem.map(item => {
                            const color = this.getColor(item.l);
                            item.colorCode = item.allDay ? "#000000" : color;
                            item.bgColor = item.allDay ? this.getBackgroundColor(color) : "transparent";
                            if (item.recur) {
                                /* All Day event display in tooltip if is same as hover date */
                                for (let i = 0; i < item.inst.length ; i++) {
                                    const eventStartDate = moment(moment(item.inst[i].s).format("YYYYMMDD"));
                                    const selectedDate = moment(moment(this.event.date).format("YYYYMMDD"));
                                    if (eventStartDate.isSame(selectedDate)) {
                                        displayEvent.push(item);
                                    }
                                }
                            } else {
                                if (item.allDay && item.inst && item.inst[0]) {
                                    const startDate = moment(new Date(item.inst[0].s)).startOf("day").valueOf();
                                    const eventEndDate = moment(startDate).add(
                                        this.getDaysFromMillis(item.dur) - 1, "days"
                                        ).endOf("day").valueOf();
                                    const eventStartDate = moment(moment(item.inst[0].s).format("YYYYMMDD"));
                                    const selectedDate = moment(moment(this.event.date).format("YYYYMMDD"));
                                    const endDateItem = moment(moment(eventEndDate).format("YYYYMMDD"));
                                    if (selectedDate.isSame(eventEndDate)) {
                                        displayEvent.push(item);
                                    } else if (selectedDate.isAfter(eventStartDate) && selectedDate.isSameOrBefore(endDateItem)) {
                                        displayEvent.push(item);
                                    }
                                } else {
                                    displayEvent.push(item);
                                }
                            }
                        });
                        this.event.eventItem = displayEvent;
                        if (displayEvent.length === 0) {
                            this.event.eventItem = {
                                noData: true
                            };
                        }
                        this.changeDetectorRef.markForCheck();
                    } else {
                        this.event.eventItem = {
                            noData: true
                        };
                        this.changeDetectorRef.markForCheck();
                    }
                    this.show();
                }, error => {
                    this.toastService.showPlainMessage(error);
                });
            }
        });
    }

    @HostListener("mouseleave")
    onMouseOut(): void {
        if (this.requestSubscription$) {
            this.requestSubscription$.unsubscribe();
        }
        this.hide();
    }

    private show(): void {
        if (!this.tooltipRef) {
            this.tooltipRef = this.viewContainerRef.createComponent(
                this.tooltipFactory,
                0,
                this.injector,
                []
            );
            this.tooltipRef.instance.eventItem = this.eventItem;
            this.tooltipRef.instance.event = this.event;
            if (!!this.currentLanguage && this.currentLanguage !== null) {
                this.tooltipRef.instance.currentLocal = this.currentLanguage;
            } else {
                this.tooltipRef.instance.currentLocal = "en";
            }
            this.changeDetectorRef.markForCheck();
            if (this.appendToBody) {
                this.document.body.appendChild(this.tooltipRef.location.nativeElement);
            }
            requestAnimationFrame(() => {
                this.positionTooltip();
            });
        }
    }

    private hide(): void {
        if (this.tooltipRef) {
            this.viewContainerRef.remove(
                this.viewContainerRef.indexOf(this.tooltipRef.hostView)
            );
            this.tooltipRef = null;
        }
        this.cancelTooltipDelay$.next(true);
        this.isAlive$.next(false);
        this.isAlive$.complete();
    }

    private positionTooltip(previousPosition?: string): void {
        if (this.tooltipRef) {
            this.tooltipRef.changeDetectorRef.detectChanges();
            this.tooltipRef.instance.placement = positionElements(
                this.elementRef.nativeElement,
                this.tooltipRef.location.nativeElement.children[0],
                this.placement,
                this.appendToBody
            );
            if (previousPosition !== this.tooltipRef.instance.placement) {
                this.positionTooltip(this.tooltipRef.instance.placement);
            }
        }
    }

    getDifferenceDay(start: any, end: any): any {
        const startDate = moment(start);
        const endDate = moment(end);
        const result = endDate.diff(startDate, "days");
        if (!!result) {
            return result;
        } else {
            return 0;
        }
    }

    getColor(folderId: string): string {
        return this.calendarRepository.getFolderColorById(folderId);
    }

    getBackgroundColor(color: string): string {
        const grandient = CalenderUtils.getBackgroundGrandientColor(color);
        if (!!grandient) {
            return grandient;
        }
        return color;
    }

    getDaysFromMillis(milliseconds: number): number {
        return ((milliseconds / 3600) / 1000) / 24;
    }
}
