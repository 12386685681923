
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy, Inject, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from "@angular/core";
import { Subject } from "rxjs/internal/Subject";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/reducers/app";
import { getIncludeSharedItems, getSearchFor } from "src/app/reducers";
import { takeUntil } from "rxjs/operators";
import { SetSearchFor } from "src/app/actions/app";
import { Router } from "@angular/router";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import { AppService } from "src/app/services/app.service";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "vp-advanced-search-dialog",
    templateUrl: "./advanced-search-dialog.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedSearchDialogComponent implements OnInit, OnDestroy {
    private isAlive$ = new Subject<boolean>();
    @ViewChild(MatAutocompleteTrigger, {static: false}) autocomplete: MatAutocompleteTrigger;
    options = [
        {
            key: "EMAILS",
            value: "mail"
        },
        {
            key: "CONTACTS",
            value: "contact"
        },
        {
            key: "BRIEFCASES_LBL",
            value: "briefcase"
        }
    ];
    searchFor = localStorage.getItem("getSearchFor") || "mail";
    includeSharedItems: boolean;
    currentUrl = "";
    searchKeyword: any;
    searchKeywordItem: any[] = [];
    isHideContactSearchSelectionOption: boolean = true;
    historyKeywordItem: any[] = [];
    searchFolders: any;
    constructor(
        private dialogRef: MatDialogRef<AdvancedSearchDialogComponent>,
        private mailBroadCaster: MailBroadcaster,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router,
        private store: Store<AppState>,
        private appService: AppService,
        private changeDetectorRef: ChangeDetectorRef) {
            this.currentUrl = this.router.routerState.snapshot.url;
            if (this.data && this.data.keyword) {
                this.searchKeyword = this.data.keyword;
            }
            if (this.data && this.data.hasOwnProperty("isHideContactSearchSelectionOption")) {
                this.isHideContactSearchSelectionOption = this.data.isHideContactSearchSelectionOption;
                if (!this.isHideContactSearchSelectionOption) {
                    let index = this.options.findIndex(x => x.key === "CONTACTS");
                    if (index > -1) {
                        this.options.splice(index, 1);
                    }
                    this.changeDetectorRef.markForCheck();
                }
            }
    }

    ngOnInit() {
        this.mailBroadCaster.on(BroadcastKeys.HIDE_ADVANCED_SEARCH_DIALOG).pipe(takeUntil(this.isAlive$)).subscribe(value => {
            this.dialogRef.close();
        });

        this.store.select(getIncludeSharedItems).pipe(takeUntil(this.isAlive$)).subscribe(value => {
            console.log("[getIncludeSharedItems]", value);
            this.includeSharedItems = value;
            if (typeof value !== "undefined") {
                localStorage.setItem("includeSharedItems", value.toString());
            }
            this.changeDetectorRef.markForCheck();
        });

        this.store.select(getSearchFor).pipe(takeUntil(this.isAlive$)).subscribe(value => {
            console.log("[getSearchFor]", value);
            this.searchFor = value ||  "mail";
            if (typeof value !== "undefined") {
                localStorage.setItem("getSearchFor", value);
            }
            this.changeDetectorRef.markForCheck();
        });
    }

    close() {
        if (!this.searchKeyword) {
            this.dialogRef.close();
        } else {
            this.dialogRef.close({keyword: this.searchKeyword});
        }
    }

    ngOnDestroy() {
        this.isAlive$.next(false);
        this.isAlive$.unsubscribe();
    }

    setIncludeSharedItems() {
        if (this.includeSharedItems) {
            this.includeSharedItems = false;
        } else {
            this.includeSharedItems = true;
        }
        this.appService.setIncludeSharedItems(this.includeSharedItems);
        console.log("[setIncludeSharedItems]", this.includeSharedItems);
    }

    setSearchFor(value) {
        console.log("[setSearchFor]", value);
        this.store.dispatch(new SetSearchFor(value));
    }
    search(): void {
        if (!this.searchKeyword) {
            return;
        }
        this.dialogRef.close({keyword: this.searchKeyword});
        if (this.searchFor === "contact") {
            if (this.currentUrl.startsWith("/contacts/search")) {
                this.mailBroadCaster.broadcast(MailConstants.REFRESH_BROADCAST);
            }
            this.router.navigate(["/contacts/search"], { queryParams: { searchText: this.searchKeyword } });
        } else if (this.searchFor === "briefcase") {
            if (this.currentUrl.startsWith("/briefcase/search")) {
                this.mailBroadCaster.broadcast(MailConstants.REFRESH_BROADCAST);
            }
            this.router.navigate(["/briefcase/search/" + this.searchKeyword]);
        } else if (this.searchFor === "calendar") {
            this.mailBroadCaster.broadcast(MailConstants.REFRESH_BROADCAST);
            this.router.navigate(["/calendar"]);
            setTimeout(() => {
              this.mailBroadCaster.broadcast("CALENDAR_SEARCH", {
                search: this.searchKeyword
              });
            }, 100);
          } else {
            // this.router.navigate(["/mail/search"], { queryParams: { searchParams: btoa(this.searchKeyword) } });
            // this.mailBroadCaster.broadcast(MailConstants.SAVED_SEARCH_ROUTE);
            // this.mailBroadCaster.broadcast(MailConstants.BROADCAST_MAIL_SELECTED_TAB);
            // this.mailBroadCaster.broadcast("SEARCH_TAB_MESSAGE");
            this.mailBroadCaster.broadcast(MailConstants.SEARCH_ADVANCE_SEARCH);
        }
    }
}
