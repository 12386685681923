
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-mail-folder-dialog mail-dialog">
    <div class="mail__dialog-header" layout="row center-center">
        <div [fxHide.gt-sm]="true">
            <button (click)="close()" class="brand-color mat-button">
                <mat-icon class="disable-select">chevron_left</mat-icon>
            </button>
        </div>
        <div class="header-title ">
            <span>{{ 'FOLDERS' | translate}}</span>
        </div>
        <div class="mobile-right-close" [fxHide.xs]="true">
            <button class="brand-color mat-button" (click)="close()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="mail__dialog-body folder-body mail__dialog-body--pad scrolling-mailfolder-div">

        <mat-tree [dataSource]="nestedDataSource" [treeControl]="nestedTreeControl" class="sidebar-tree-systemfolder">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle style="white-space:no-wrap;">
                <li class="mat-tree-node" (click)="selectFolder(node)" [class.selected-class]="selectedFolder && node.id === selectedFolder.id">
                    <button mat-icon-button disabled></button>
                    <mat-icon *ngIf="node.icon">{{node.icon}}</mat-icon>
                    <mat-icon *ngIf="!node.icon" [style.color]="node.rgb">folder</mat-icon>
                    <span *ngIf="node.icon" (click)="selectFolder(node)" class="sidebar-title">
                        {{getFolderName(node)}}</span>
                    <span *ngIf="!node.icon" (click)="selectFolder(node)" class="sidebar-title">
                        {{getFolderName(node)}}</span>
                </li>
            </mat-tree-node>

            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild" style="white-space:no-wrap;">
                <li>
                    <div class="mat-tree-node" [class.selected-class]="selectedFolder && node.id === selectedFolder.id">
                        <button *ngIf="nestedTreeControl.isExpanded(node)" mat-icon-button matTreeNodeToggle
                            [attr.aria-label]="'toggle ' + node.name">
                            <mat-icon class="mat-icon-rtl-mirror">expand_more</mat-icon>
                        </button>

                        <button *ngIf="!nestedTreeControl.isExpanded(node)" mat-icon-button matTreeNodeToggle
                            [attr.aria-label]="'toggle ' + node.name">
                            <mat-icon class="mat-icon-rtl-mirror">chevron_right</mat-icon>
                        </button>
                        <div (click)="selectFolder(node)" class="folder-div">
                            <mat-icon *ngIf="node.icon">{{node.icon}}</mat-icon>
                            <mat-icon *ngIf="!node.icon" [style.color]="node.rgb">folder</mat-icon>
                            <span *ngIf="node.icon" (click)="selectFolder(node)" class="sidebar-title">
                                {{getFolderName(node)}}</span>
                            <span *ngIf="!node.icon" (click)="selectFolder(node)" class="sidebar-title">
                                {{ getFolderName(node)}}</span>
                        </div>
                    </div>
                    <ul [class.hide-folders]="!nestedTreeControl.isExpanded(node)">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                </li>
            </mat-nested-tree-node>
        </mat-tree>

        <!-- <mat-divider></mat-divider> -->

        <mat-tree [dataSource]="nestedDataSourceSubFolders" [treeControl]="nestedSubFoldersTreeControl" class="sidebar-tree-subfolders"
            [class.subfolder-visible]="isRootUserFolderCollapsed">
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle style="white-space:no-wrap;">
                <li (click)="selectFolder(node)" class="mat-tree-node" [class.selected-class]="selectedFolder && node.id === selectedFolder.id">
                    <button mat-icon-button disabled></button>
                    <mat-icon *ngIf="node.icon">{{node.icon}}</mat-icon>
                    <mat-icon *ngIf="!node.icon" [style.color]="node.rgb">folder</mat-icon>
                    <span *ngIf="node.icon" (click)="selectFolder(node)" class="sidebar-title">
                        {{getFolderName(node)}}</span>
                    <span *ngIf="!node.icon" (click)="selectFolder(node)" class="sidebar-title">
                        {{getFolderName(node)}}</span>

                </li>
            </mat-tree-node>

            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild" style="white-space:no-wrap;">
                <li>
                    <div class="mat-tree-node" [class.selected-class]="selectedFolder && node.id === selectedFolder.id">

                        <button *ngIf="nestedSubFoldersTreeControl.isExpanded(node)" mat-icon-button matTreeNodeToggle
                            [attr.aria-label]="'toggle ' + node.name">
                            <mat-icon class="mat-icon-rtl-mirror">expand_more</mat-icon>
                        </button>

                        <button *ngIf="!nestedSubFoldersTreeControl.isExpanded(node)" mat-icon-button matTreeNodeToggle
                            [attr.aria-label]="'toggle ' + node.name">
                            <mat-icon class="mat-icon-rtl-mirror">chevron_right</mat-icon>
                        </button>
                        <div (click)="selectFolder(node)" class="folder-div">
                            <mat-icon *ngIf="node.icon">{{node.icon}}</mat-icon>
                            <mat-icon *ngIf="!node.icon" [style.color]="node.rgb">folder</mat-icon>
                            <span *ngIf="node.icon" (click)="selectFolder(node)" class="sidebar-title">
                                {{getFolderName(node)}}</span>
                            <span *ngIf="!node.icon" (click)="selectFolder(node)" class="sidebar-title">
                                {{getFolderName(node)}}</span>
                        </div>
                    </div>
                    <ul [class.hide-folders]="!nestedSubFoldersTreeControl.isExpanded(node)">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                </li>
            </mat-nested-tree-node>
        </mat-tree>
    </div>
</div>