
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="tags-list">
    <!-- <div class="tags-list__heading">
        {{ 'TAGS' | translate }}
        <div class="tag-action">
            <mat-icon (click)="createNewTag()" class="tag-icon mdi-18px" fontSet="mdi" fontIcon="mdi-tag-plus"
                matTooltip="{{'CREATE_NEW_TAG' | translate }}"></mat-icon>
            <mat-icon (click)="expanded=false" *ngIf="expanded">expand_less</mat-icon>
            <mat-icon (click)="expanded=true" *ngIf="!expanded">expand_more</mat-icon>
        </div>
    </div> -->
    <div class="tags-list__heading">
        <div class="tag-header-bar" *ngIf="!isSearchTag">
            <div>
                <span class="subfolder-title">{{ 'TAGS' | translate }}</span>
                <mat-icon *ngIf="!isFullView" (click)="showOnlyTagsList(true)" class="fullview-icon" matTooltipPosition="above" matTooltip="{{'EXPAND' | translate}}">unfold_more</mat-icon>
            </div>
            <div class="folder-action" id="user-tag-action">
                <mat-icon (click)="createNewTag()" class="tag-icon in-sidebar-block mdi-18px" fontSet="mdi" fontIcon="mdi-tag-plus" matTooltip="{{'CREATE_NEW_TAG' | translate }}"></mat-icon>
                <span class="action-icons" (click)="viewSearchTagOption(true)" matTooltip="{{'SEARCH_TAGS' | translate }}"><ng-container [ngTemplateOutlet]="searchIcon"></ng-container></span>
                <span class="action-icons" (click)="expanded=false" *ngIf="expanded && !isFullView" matTooltip="{{'COLLAPSE' | translate}}"><ng-container [ngTemplateOutlet]="collapseIcon"></ng-container></span>
                <span class="action-icons" (click)="expanded=true" *ngIf="!expanded && !isFullView" matTooltip="{{'EXPAND' | translate }}"><ng-container [ngTemplateOutlet]="expandIcon"></ng-container></span>
                <mat-icon *ngIf="isFullView" (click)="showOnlyTagsList(false)" class="create-folder close-expand-tag">close</mat-icon>
            </div>
        </div>
        <div *ngIf="isSearchTag" class="tag-search-bar">
            <button (click)="viewSearchTagOption(false)" class="back-arrow-btn" mat-icon-button>
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="search-div">
                <input matInput #tagNameSearchInput [(ngModel)]="searchTag" type="text" placeholder="{{'SEARCH_TAGS' | translate}}" />
                <button class="search-btn" mat-icon-button>
                    <mat-icon>search</mat-icon>
                </button>
            </div>
            <button *ngIf="isFullView" class="close-btn" mat-icon-button>
                <mat-icon (click)="showOnlyTagsList(false)" class="create-folder">close</mat-icon>
            </button>
        </div>
    </div>
    <mat-divider class="sidebar-mat-divider"></mat-divider>
    <!-- (contextmenu)="onContextMenu($event, tag)"
                [style.backgroundColor]="tag.rgb || tag.color || defaultColor"
                *cdkVirtualFor="let tag of tags"
                [style.color]="getTagColor(tag.rgb || tag.color || defaultColor)" -->
    <div class="tags-list__body">
        <cdk-virtual-scroll-viewport itemSize="20" [ngStyle]="{ 'max-height': getMaxHeight()}" *ngIf="expanded" class="tags-viewport">
            <div class="tag-item" [class.selected-tag]="currentTab?.id === tag.id || currentTagName?.toLowerCase() === tag.name.toLowerCase() || selectedTagName?.toLowerCase() === tag.name.toLowerCase()" (click)="openTag(tag)" [contextMenu]="tagMenu"
                (contextmenu)="onContextMenu($event, tag)"
                *cdkVirtualFor="let tag of tags | vpTagNameSearch : searchTag; let i = index">
                <mat-icon [style.color]="tag.rgb || tag.color || defaultColor" class="mdi-18px margin-left" fontSet="mdi" fontIcon="mdi-tag"></mat-icon>
                <span class="no-select" matTooltip="{{ tag.name }}">{{ tag.name }}</span>
                <!-- <mat-icon (click)="$event.stopPropagation();deleteTag(tag)">close</mat-icon> -->
            </div>
        </cdk-virtual-scroll-viewport>

    </div>
</div>

<context-menu #tagMenu id="tagMenu">
    <ng-template contextMenuItem [enabled]="contextMenuActions.isEnableNewTag" (execute)="createNewTag()">
        <div class="context-item">
            <span class="item-icon-block">
                <ng-container [ngTemplateOutlet]="tagIcon"></ng-container>
            </span>
            <span class="item-label-block">{{ 'NEW_TAG_ACTION' | translate }}</span>
        </div>
    </ng-template>
    <ng-template contextMenuItem [enabled]="contextMenuActions.isEnableMarkRead">
        <div class="context-item">
            <span class="item-icon-block">
                <ng-container [ngTemplateOutlet]="markAllIcon"></ng-container>
            </span>
            <span class="item-label-block">{{ 'ALL_READ_FODER_ACTION' | translate }}</span>
        </div>
    </ng-template>
    <ng-template contextMenuItem [enabled]="contextMenuActions.isEnableRename" (execute)="renameTag()">
        <div class="context-item">
            <span class="item-icon-block">
                <ng-container [ngTemplateOutlet]="editTagIcon"></ng-container>
            </span>
            <span class="item-label-block">{{ 'EDIT_TAG_LBL' | translate }}</span>
        </div>
    </ng-template>
    <ng-template contextMenuItem [enabled]="contextMenuActions.isEnableColorEdit" [subMenu]="colorSubMenu">
        <div class="context-item-color">
            <mat-radio-group class="color-selection-radio tag-context-menu with-context-menu" (change)="updateDefaultColor()" [(ngModel)]="tagColor">
                <mat-radio-button class="grey-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#A8B0B9'}" value="#A8B0B9" (click)="updateDefaultColor('#A8B0B9')">
                    <span *ngIf="tagColor?.toUpperCase() === '#A8B0B9'">
                      <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
                    </span>
                  </mat-radio-button>
                  <mat-radio-button class="sky-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#00B8D4'}" value="#00B8D4" (click)="updateDefaultColor('#00B8D4')">
                    <span *ngIf="tagColor?.toUpperCase() === '#00B8D4'">
                      <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
                    </span>
                  </mat-radio-button>
                  <mat-radio-button class="blue-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#388EDA'}" value="#388EDA" (click)="updateDefaultColor('#388EDA')">
                    <span *ngIf="tagColor?.toUpperCase() === '#388EDA'">
                      <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
                    </span>
                  </mat-radio-button>
                  <mat-radio-button class="navy-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#6358C7'}" value="#6358C7" (click)="updateDefaultColor('#6358C7')">
                    <span *ngIf="tagColor?.toUpperCase() === '#6358C7'">
                      <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
                    </span>
                  </mat-radio-button>
                  <mat-radio-button class="red-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#F24336'}" value="#F24336" (click)="updateDefaultColor('#F24336')">
                    <span *ngIf="tagColor?.toUpperCase() === '#F24336'">
                      <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
                    </span>
                  </mat-radio-button>
                  <mat-radio-button class="orange-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#FFAD16'}" value="#FFAD16" (click)="updateDefaultColor('#FFAD16')">
                    <span *ngIf="tagColor?.toUpperCase() === '#FFAD16'">
                      <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
                    </span>
                  </mat-radio-button>
                  <mat-radio-button class="green-radio" [ngClass]="{'active-radio' : tagColor?.toUpperCase() === '#27AB7F'}" value="#27AB7F" (click)="updateDefaultColor('#27AB7F')">
                    <span *ngIf="tagColor?.toUpperCase() === '#27AB7F'">
                      <ng-container *ngTemplateOutlet="checkIcon"></ng-container>
                    </span>
                  </mat-radio-button>
            </mat-radio-group>
            <span (click)="openSubMenu($event)" class="expand-menu-color-icon" matTooltipPosition="above" matTooltip="{{'MORE_TITLE' | translate}}">
                <ng-container [ngTemplateOutlet]="moreIcon"></ng-container>
            </span>
        </div>
    </ng-template>
    <ng-template contextMenuItem divider="true"></ng-template>
    <ng-template contextMenuItem [enabled]="contextMenuActions.isEnableDelete" (execute)="deleteSelectedTag()">
        <div class="context-item">
            <span class="item-icon-block">
                <ng-container [ngTemplateOutlet]="deleteIcon"></ng-container>
            </span>
            <span class="item-label-block">{{ 'DELETE_TAG_ACTION' | translate }}</span>
        </div>
    </ng-template>
</context-menu>

<context-menu #colorSubMenu id="colorSubMenu" class="color-contextmenu">
    <ng-template contextMenuItem>
        <div class="expanded-color-palet-menu" (click)="$event.stopPropagation();$event.preventDefault()" (mouseleave)="closePicker($event,'leave')">
            <color-chrome [color]="tagColor" (onChange)="handleColorChange($event)"></color-chrome>
            <div class = "color-action">
                <vnc-button class="no-hover" [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" [type]="'secondary'" label="{{'CANCEL_MAIL_LABEL' | translate }}" (click)="closePicker($event,'leave')"></vnc-button>
                <vnc-button [height]="'medium'" [padding]="'small'" [shape]="'rectangle'" (click)="saveColor($event)">
                    <span>{{'SAVE' | translate }}</span>
                </vnc-button>
            </div>
        </div>
    </ng-template>
</context-menu>

<ng-template #tagIcon>
    <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/tag-new" stroke="none" stroke-width="1" fill="#8B96A0" fill-rule="evenodd">
            <path d="M11,2 C11.5,2 11.9259259,2.14814815 12.2777778,2.44444444 L12.40625,2.5625 L21.40625,11.5625 C21.78125,11.9375 21.96875,12.40625 21.96875,12.96875 C21.96875,13.46875 21.8206019,13.8946759 21.5243056,14.2465278 L21.40625,14.375 L14.375,21.40625 C14,21.78125 13.53125,21.96875 12.96875,21.96875 C12.46875,21.96875 12.0428241,21.8206019 11.6909722,21.5243056 L11.5625,21.40625 L2.5625,12.40625 C2.22916667,12.0729167 2.04398148,11.6655093 2.00694444,11.1840278 L2,11 L2,3.96875 C2,3.40625 2.1875,2.9375 2.5625,2.5625 C2.89583333,2.22916667 3.30324074,2.04398148 3.78472222,2.00694444 L3.96875,2 L11,2 Z M11.013,3.997 L11,4 L4,4 L4,11 L3.997,11.013 L12.954,19.97 L12.96875,19.96875 L12.982,19.97 L19.97,12.982 L19.96875,12.96875 L19.97,12.954 L11.013,3.997 Z M6.4921875,5.015625 C6.9140625,5.015625 7.265625,5.15625 7.546875,5.4375 C7.828125,5.71875 7.96875,6.0703125 7.96875,6.4921875 C7.96875,6.9140625 7.828125,7.265625 7.546875,7.546875 C7.265625,7.828125 6.90625,7.96875 6.46875,7.96875 C6.0625,7.96875 5.71875,7.828125 5.4375,7.546875 C5.15625,7.265625 5.015625,6.9140625 5.015625,6.4921875 C5.015625,6.0703125 5.15625,5.71875 5.4375,5.4375 C5.71875,5.15625 6.0703125,5.015625 6.4921875,5.015625 Z" id="Combined-Shape" fill="#8B96A0"></path>
        </g>
    </svg>
</ng-template>

<ng-template #deleteIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon/product/delete-new</title>
      <g id="Icon/product/delete-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M18,7 L6,7 L6,18.2857143 C6,19.7746499 7.19131256,21 8.66666667,21 L15.3333333,21 C16.8086874,21 18,19.7746499 18,18.2857143 L18,7 Z M8,9 L16,9 L16,18.2857143 C16,18.6824929 15.6913126,19 15.3333333,19 L8.66666667,19 L8.57834889,18.9935231 C8.25938319,18.9465317 8,18.649428 8,18.2857143 L8,9 Z M13,3 C13.7404864,3 14.3869825,3.40242038 14.7327215,4.00049436 L18,4 C18.5522847,4 19,4.44771525 19,5 C19,5.55228475 18.5522847,6 18,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L9.26760632,3.99992752 C9.61342606,3.40216612 10.2597476,3 11,3 L13,3 Z" id="Combined-Shape" fill="#8B96A0"></path>
      </g>
    </svg>
</ng-template>
<ng-template #markAllIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Icon/product/done-all</title>
        <g id="Icon/product/done-all" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icon-24px" transform="translate(0.410000, 5.590000)" fill="#8B96A0">
                <path d="M17.59,1.41 L16.18,0 L9.84,6.34 L11.25,7.75 L17.59,1.41 L17.59,1.41 Z M21.83,0 L11.25,10.58 L7.07,6.41 L5.66,7.82 L11.25,13.41 L23.25,1.41 L21.83,0 L21.83,0 Z M0,7.82 L5.59,13.41 L7,12 L1.42,6.41 L0,7.82 L0,7.82 Z" id="Shape"></path>
            </g>
        </g>
    </svg>
</ng-template>
<ng-template #editTagIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Icon/product/edit</title>
        <g id="Icon/product/edit" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icon-24px" transform="translate(3.000000, 2.997500)" fill="#8B96A0">
                <path d="M0,14.2525 L0,18.0025 L3.75,18.0025 L14.81,6.9425 L11.06,3.1925 L0,14.2525 L0,14.2525 Z M17.71,4.0425 C18.1,3.6525 18.1,3.0225 17.71,2.6325 L15.37,0.2925 C14.98,-0.0975 14.35,-0.0975 13.96,0.2925 L12.13,2.1225 L15.88,5.8725 L17.71,4.0425 L17.71,4.0425 Z" id="Shape"></path>
            </g>
        </g>
    </svg>
</ng-template>
<ng-template #moreIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Icon/product/more-vert</title>
        <g id="Icon/product/more-vert" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Icon-24px" transform="translate(10.000000, 4.000000)" fill="#8B96A0">
                <path d="M2,4 C3.1,4 4,3.1 4,2 C4,0.9 3.1,0 2,0 C0.9,0 0,0.9 0,2 C0,3.1 0.9,4 2,4 L2,4 Z M2,6 C0.9,6 0,6.9 0,8 C0,9.1 0.9,10 2,10 C3.1,10 4,9.1 4,8 C4,6.9 3.1,6 2,6 L2,6 Z M2,12 C0.9,12 0,12.9 0,14 C0,15.1 0.9,16 2,16 C3.1,16 4,15.1 4,14 C4,12.9 3.1,12 2,12 L2,12 Z" id="Shape"></path>
            </g>
        </g>
    </svg>
</ng-template>

<ng-template #checkIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Icon/product/check" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <polygon id="Shape" fill="#ffffff" points="9 16.17 4.83 12 3.41 13.41 9 19 21 7 19.59 5.59"></polygon>
      </g>
  </svg>
  </ng-template>
<ng-template #searchIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Icon/product/search</title>
    <g id="Icon/product/search" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icon-24px" transform="translate(3.000000, 3.000000)" fill="#8B96A0">
            <path d="M12.5,11 L11.71,11 L11.43,10.73 C12.41,9.59 13,8.11 13,6.5 C13,2.91 10.09,0 6.5,0 C2.91,0 0,2.91 0,6.5 C0,10.09 2.91,13 6.5,13 C8.11,13 9.59,12.41 10.73,11.43 L11,11.71 L11,12.5 L16,17.49 L17.49,16 L12.5,11 L12.5,11 Z M6.5,11 C4.01,11 2,8.99 2,6.5 C2,4.01 4.01,2 6.5,2 C8.99,2 11,4.01 11,6.5 C11,8.99 8.99,11 6.5,11 L6.5,11 Z" id="Shape"></path>
        </g>
    </g>
</svg>
</ng-template>
<ng-template #expandIcon>
    <svg width="24px" height="25px" viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Icon/product/keyboard-arrow-down-new</title>
        <g id="Icon/product/keyboard-arrow-down-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M7.25735931,9.79289322 C7.6478836,9.40236893 8.28104858,9.40236893 8.67157288,9.79289322 L12.5,13.6213203 L16.3284271,9.79289322 C16.7189514,9.40236893 17.3521164,9.40236893 17.7426407,9.79289322 C18.133165,10.1834175 18.133165,10.8165825 17.7426407,11.2071068 L12.5,16.4497475 L7.25735931,11.2071068 C6.86683502,10.8165825 6.86683502,10.1834175 7.25735931,9.79289322 Z" id="Path" fill="#8B96A0"></path>
        </g>
    </svg>
</ng-template>
<ng-template #collapseIcon>
    <svg width="24px" height="25px" viewBox="0 0 24 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Icon/product/keyboard-arrow-up-new</title>
        <g id="Icon/product/keyboard-arrow-up-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M17.7426407,16.1568542 C17.3521164,16.5473785 16.7189514,16.5473785 16.3284271,16.1568542 L12.5,12.3284271 L8.67157288,16.1568542 C8.28104858,16.5473785 7.6478836,16.5473785 7.25735931,16.1568542 C6.86683502,15.76633 6.86683502,15.133165 7.25735931,14.7426407 L12.5,9.5 L17.7426407,14.7426407 C18.133165,15.133165 18.133165,15.76633 17.7426407,16.1568542 Z" id="Path" fill="#8B96A0"></path>
        </g>
    </svg>
</ng-template>