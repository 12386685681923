
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MailBroadcaster } from "../../../common/providers/mail-broadcaster.service";
import { getActiveProfile, getUserProfile, RootState, getFederatedApps } from "src/app/reducers";
import { Store } from "@ngrx/store";
import { getContactById } from "../../../reducers/index";
import { Utils } from "src/app/common/utils";
import { ConfigService } from "src/app/config.service";
import { switchMap, takeUntil, Subject, filter, take, of } from "rxjs";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { ElectronService } from "src/app/services/electron.service";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { SetActiveProfile, SetRightSidebarStatus } from "src/app/actions/app";
import { ToastService } from "src/app/common/providers/toast.service";
import { CommonRepository } from "src/app/mail/repositories/common-repository";
import { UserProfile } from "../../models";
import { RandomColor } from "src/app/common/utils/random-color";
import { MailService } from "src/app/mail/shared/services/mail-service";
import { CommonUtils } from "src/app/common/utils/common-util";
import { META_TASK_MENTION, TICKET_MENTION } from "vnc-library";
import { DomSanitizer } from "@angular/platform-browser";
import { ContactRootState } from "src/app/contacts/store";

@Component({
  selector: "vp-right-sidebar",
  templateUrl: "./right-sidebar.component.html",
  styleUrls: [
    "./right-sidebar.component.scss"
  ]
})
export class RightSidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private breakpointObserver: BreakpointObserver,
    private electronService: ElectronService,
    private mailService: MailService,
    private store: Store<RootState>,
    private toastService: ToastService,
    private commonRepository: CommonRepository,
    private translate: TranslateService,
    private config: ConfigService,
    private changeDetectionRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    public appStore: Store<ContactRootState>,
    ) {
    this.isLargeScreen = this.breakpointObserver.isMatched("(min-width: 1480px)");
    this.breakpointObserver
      .observe("(min-width: 1480px)").pipe(takeUntil(this.isAlive$))
      .subscribe((state: BreakpointState) => {
        this.isLargeScreen = state.matches;
      });
    this.config.currentLanguage.asObservable().pipe(switchMap(lang => this.translate.getTranslation(lang)), takeUntil(this.isAlive$))
      .subscribe(v => {
        this.translations = v;
      });

      this.appStore.select(getUserProfile).pipe(filter(v => !!v)).subscribe(res => {
        this.userJID = res;
      });

  }

  get fullName(): string {
    return [this.contact.first_name, this.contact.last_name].filter(v => !!v).join(" ");
  }
  contact: any;
  translations: any = {};
  private isAlive$ = new Subject<boolean>();
  isLargeScreen: boolean;
  currentUser: UserProfile;
  isLoggedInUser: any;
  supportedApps = ["talk", "mail", "task"];
  searchParams: any = {
    q: "*:*",
    sort: "created_dt desc",
    start: 0,
    apps: this.supportedApps.join(","),
    limit: 20
  };
  searchResponse: any;
  activeEmail: string;
  allContacts: any[] = [];
  emojiOnly: boolean = false;
  userJID: any = {};
  isSearchResult: boolean;
  hasMention: boolean;
  actorName: string;
  isMyMessage: boolean;
  totalParticipants: any;
  fromJid: string;
  translationKey: string;
  fullActorName: string;
  isMeeting: boolean;
  keyword: any;
  isShowTalkOptions: boolean = false;
  isShowTaskOption: boolean = false;
  isShowTicketOption: boolean = false;

  otherUserName: string = "";

  ngOnInit(): void {
    this.store.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(res => {
      if (!!res) {
        this.currentUser = res;
      }
    });
    this.store.select(getFederatedApps).pipe(filter(apps => !!apps && apps.length > 0), takeUntil(this.isAlive$)).subscribe(apps => {
      const isTalkAppAvailable = apps.filter(fa => fa.name === "vnctalk")[0];
      if (!!isTalkAppAvailable) {
        this.isShowTalkOptions = true;
      } else {
        this.isShowTalkOptions = false;
      }
      const isTaskAppAvailable = apps.filter(fa => fa.name === "vnctask")[0];
      if (!!isTaskAppAvailable) {
        this.isShowTaskOption = true;
      } else {
        this.isShowTaskOption = false;
      }
      const isProjectAppAvailable = apps.filter(fa => fa.name === "vncproject")[0];
      if (!!isProjectAppAvailable) {
        this.isShowTicketOption = true;
      } else {
        this.isShowTicketOption = false;
      }
      this.changeDetectionRef.markForCheck();
    });
    this.store.select(getActiveProfile).pipe(switchMap(email => {
      console.log("getActiveProfile", email);
      this.activeEmail = email;
      this.searchResponse = [];
      if (!!email) {
        this.contact = {
          email: this.activeEmail,
          first_name: this.activeEmail.split("@")[0]
        };
        this.contact.firstLastCharacters = Utils.getCharacters(this.activeEmail.split("@")[0], "");
        this.callSearchActivity("talk");
        if (this.currentUser.email === email) {
          return this.mailService.getLoggedInUserContactInfo(this.currentUser.email);
        }
        return this.store.select(state => getContactById(state, email)).pipe(takeUntil(this.isAlive$));
      } else {
        this.contact = {};
        return of(null);
      }
    }), takeUntil(this.isAlive$)).subscribe(contact => {
      if (!!contact) {
        if (!contact.email && contact.jid) {
          contact.email = contact.jid;
        } else if (!contact.email && contact.emails) {
          contact.email = contact.emails[0].email;
        }
        this.contact = contact;
        console.log("rightsidebar contact: ", contact);
        this.callSearchActivity("talk");
        this.contact.firstLastCharacters = Utils.getCharacters(contact.first_name, contact.last_name);
      }
    });
  }

  getBgAvatarColor(jid) {
    return RandomColor.getCharColor(jid);
  }

  getAvatarUrl(jid) {
    return this.contact.avatar;
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  copyEmail(email: string) {
    console.log("copyEmail", email);
    MailUtils.copyToClipboard([email]);
    this.toastService.show("COPIED_TO_CLIPBOARD");
  }

  openURL(url: string) {
    if (typeof cordova !== "undefined") {
      if (device.platform === "iOS") {
        window.open(url, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(url, {
          openExternal: true
        });
      }
    } else if (this.electronService.isElectron) {
      this.electronService.openExternalUrl(url);
    } else {
      window.open(url, "_blank");
    }
  }

  closeSidebar() {
    this.store.dispatch(new SetRightSidebarStatus(false));
    this.store.dispatch(new SetActiveProfile(null));
  }

  handleProfileAction(action) {
    switch (action) {
      case "audio": this.startAudioCall(); break;
      case "video": this.startVideoCall(); break;
      case "chat": this.startChat(); break;
      case "mail": this.composeEmail(); break;
      case "ticket": this.createTicket(); break;
      case "task": this.createTask(); break;
    }
  }

  composeEmail(): void {
    this.commonRepository.sendEmail(this.contact.email);
  }

  createTask(): void {
    this.commonRepository.createTask(this.contact.username);
  }

  createTicket(): void {
    this.commonRepository.createTicket(this.contact.username);
  }

  startAudioCall(): void {
    if (this.isLoggedInUser) {
      this.toastService.show("CAN_NOT_CALL_YOURSELF");
      return;
    }
    this.commonRepository.makeTalkAudioChatVideoOperation(this.contact.jid, "audio-call", "group-audio");
  }

  startVideoCall(): void {
    if (this.isLoggedInUser) {
      this.toastService.show("CAN_NOT_CALL_YOURSELF");
      return;
    }
    this.commonRepository.makeTalkAudioChatVideoOperation(this.contact.jid, "video-call", "group-video");
  }

  startChat(): void {
    if (this.isLoggedInUser) {
      this.toastService.show("CAN_NOT_CHAT_YOURSELF");
      return;
    }
    this.commonRepository.makeTalkAudioChatVideoOperation(this.contact.jid, "start-chat", "group-chat");
  }

  underDevelopment() {
    this.toastService.show("COMING_SOON");
  }

  openRecentActivity(item) {
    if (item.type === "talk") {
      if (item.chatType === "chat") {
        this.commonRepository.jumpToChat(this.contact, item.talkId, item.createdDt);
      } else {
        this.underDevelopment();
      }
    } else if (item.type === "mail") {
      console.log("openRecentActivity item: ", item);
      this.commonRepository.openMail(item.mailFolderID, item.id);
      this.closeSidebar();
    } else if (item.type === "task") {
      this.commonRepository.openTask(item.id);
    } else {
      this.underDevelopment();
    }
  }

  onSelectedTabChangeActivity(ev: any) {
    if (ev.index === 0) {
      this.callSearchActivity("talk");
    } else if (ev.index === 1) {
      this.callSearchActivity("mail");
    }
  }
 async callSearchActivity(apps: string) {
    console.log("callSearchActivity", this.contact, this.userJID);
    if (!!this.searchResponse) {
      this.searchResponse.docs = [];
    }
    if (this.contact.jid || this.contact.emails && this.contact.emails.length > 0) {
      this.searchParams.from_s = (this.contact.jid) ? this.contact.jid : this.contact.emails[0].email;
      const email = this.searchParams.from_s;
      const username = email.split("@")[0];
      this.otherUserName = await this.mailService.getNameByEmail(username);
      this.searchParams.apps = apps;
      this.commonRepository.searchDocs(this.searchParams).pipe(take(1)).subscribe(docs => {
        this.searchResponse = docs;
        this.mapSearchDocs();
        this.changeDetectionRef.detectChanges();
      });
    } else {
      if (this.contact.email) {
        this.otherUserName = await this.mailService.getNameByEmail(this.contact.first_name);
        this.searchParams.from_s = this.contact.email;
        this.searchParams.apps = apps;
        this.commonRepository.searchDocs(this.searchParams).pipe(take(1)).subscribe(docs => {
          this.searchResponse = docs;
          this.mapSearchDocs();
          this.changeDetectionRef.detectChanges();
        });
      }
    }
  }

  mapSearchDocs() {
    this.searchResponse.docs.map(doc => {
      this.processMessageBody(doc).then((res: any) => {
          doc.contentTxt = this.getInnerText(res.changingThisBreaksApplicationSecurity);
      });
    });
  }

  getInnerText(htmlString) {
    let element = document.createElement("div");
    element.innerHTML = htmlString;
    return this.extractTextContent(element);
  }

  extractTextContent(element) {
    let text = "";
    for (let node of element.childNodes) {
        if (node.nodeType === Node.TEXT_NODE) {
            text += node.textContent.trim() + " ";
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            text += this.extractTextContent(node);
        }
    }
    return text.trim();
  }


  showFeatureUnderDevelopmentToast(): void {
    this.toastService.show("FEATURE_NEXT_VERSION_LBL");
  }

  private processMessageBody(message: any, skipEmoji?: boolean, processOnly?: boolean) {
    let messageBody = message?.contentTxt || "";
    try {
        if (message.htmlBody) {
            messageBody = message.htmlBody;
        } else if (message.html && message.html.body) {
            messageBody = message.html.body;
        }
        if (!!messageBody) {
            messageBody = messageBody.trim();
            if (/^:[a-zA-Z0-9-_+]+:$/g.test(messageBody)) {
                this.emojiOnly = true;
            }
        }

    } catch (error) {
        // eslint-disable-next-line no-console
        console.warn("[processMessageBody]", error);
    }

    return new Promise(async (resolve) => {
        const references = message.references;
        let isTicketMention = false;

        if (messageBody.startsWith("#quickreply_")) {
            const additionalActionsTranslationKeys = ["QUICK_REPLY_CANT_TALK_WHATS_UP", "QUICK_REPLY_CALL_YOU_RIGHT_BACK", "QUICK_REPLY_CALL_YOU_LATER", "QUICK_REPLY_CANT_TALK_CALL_ME_LATER"];
            let additionActionTranslations = {};
            this.translate.get(additionalActionsTranslationKeys).pipe(take(1)).subscribe(v => {
                additionActionTranslations = v;
            });
            try {
                const translateKey = messageBody.split("quickreply_")[1].trim();
                if (additionalActionsTranslationKeys.indexOf(translateKey) > -1) {
                    messageBody = additionActionTranslations[translateKey];
                    message.cachedContent = additionActionTranslations[translateKey];
                }
            } catch (error) {

            }
        }

        if (!message.forwardMessage) {
            let translations: any = {};
            this.translate.get(["TICKET_MENTION_RECEIVER_MESSAGE", "TICKET_MENTION_SENDER_MESSAGE", "META_TASK_MENTION_RECEIVER_MESSAGE", "META_TASK_MENTION_SENDER_MESSAGE"]).pipe(take(1)).subscribe(v => {
                translations = v;
            });
            isTicketMention = CommonUtils.parseRedmineMentions(TICKET_MENTION, messageBody).length > 0 || CommonUtils.parseRedmineMentions(META_TASK_MENTION, messageBody).length > 0;
            try {
                if (CommonUtils.parseRedmineMentions(TICKET_MENTION, messageBody).length > 0) {
                    const textToReplace = CommonUtils.parseRedmineMentions(TICKET_MENTION, messageBody)[0];
                    let userjid = textToReplace.split("#")[1];
                    let mentionText = translations.TICKET_MENTION_RECEIVER_MESSAGE.replace("{{ sender_name }}", this.otherUserName);
                    if (this.userJID?.email !== userjid) {
                        mentionText = translations.TICKET_MENTION_SENDER_MESSAGE.replace("{{ receiver_name }}", this.otherUserName );
                    }
                    isTicketMention = true;
                    messageBody = messageBody.replace(textToReplace, mentionText).replace(/&lt;/g, "<").replace(/&gt;/g, ">");
                }
            } catch (error) {

            }
        }
        if (messageBody.includes("You are mentioned in a comment of this topic:")) {
          let translations: any = {};
          this.translate.get(["TOPIC_MENTION_MESSAGE_RECEIVER", "TOPIC_MENTION_MESSAGE_SENDER"]).pipe(take(1)).subscribe(v => {
              translations = v;
          });

          if (messageBody.includes(this.contact.email) ) {
              let textReplace = messageBody.split("You are mentioned in a comment of this topic:")[1];
              const userName = await this.mailService.getNameByEmail(this.contact.email);
              let mentionText = translations.TOPIC_MENTION_MESSAGE_SENDER.replace("{{ USERNAME }}", userName) ;
              messageBody = mentionText + textReplace;
          } else {
            let textReplace = messageBody.split("You are mentioned in a comment of this topic:")[1];
            let mentionText = translations.TOPIC_MENTION_MESSAGE_RECEIVER ;
            messageBody = mentionText + textReplace;
          }

        }
        if (!!message.cachedContent && !this.isSearchResult && !skipEmoji && !isTicketMention) {
            messageBody = message.cachedContent;
        } else {
            messageBody = CommonUtils.generateCachedBody(messageBody, this.isSearchResult ? this.keyword : null, skipEmoji);
            if (!this.isSearchResult && !skipEmoji) {
                const newMsg = { ...message, cachedContent: messageBody };

                // this.conversationRepo.getSelectedConversation().pipe(filter(res => !!res), take(1)).subscribe(selectedConversation => {
                //     const convTarget = !!message.convTarget ? message.convTarget : selectedConversation.Target;
                //     this.conversationRepo.updateMessage(newMsg, convTarget);
                // });

            }
        }

        if (message.group_action && (message.group_action === "ADD_PARTICIPANTS"
            || message.group_action.type === "ADD_PARTICIPANTS")) {
            if (message.group_action.data) {
                const participants = message.group_action.data.split(",");
                messageBody = messageBody.split("<br>")[1];
                if (!messageBody) { // e.g. when add a new user to omemo chat
                    messageBody = "";
                    participants.forEach((pJid, index) => {
                        const pDisplayName = pJid.split("@")[0].replace(".", " ");
                        messageBody += pDisplayName;
                        if (index !== participants.length - 1) {
                            messageBody += ", ";
                        }
                    });
                }
                this.totalParticipants = participants.length;
            } else {
                const firstLine = messageBody.split("<br>")[0];
                messageBody = messageBody.split("<br>")[1];
                if (!messageBody) { // e.g. when add a new user to omemo chat
                    this.totalParticipants = 1;
                    messageBody = "";
                } else {
                  this.totalParticipants = parseInt(firstLine.replace(/\D+/g, ""), 10);
                }
            }
            this.actorName = "this.contactRepo.getFullName(this.fromJid)";
            this.translationKey = this.totalParticipants > 1 ? "USER_ADD_N_PARTICIPANTS" : "USER_ADD_N_PARTICIPANT";
        } else if (message.group_action && (message.group_action === "UPDATE_AVATAR"
            || message.group_action.type === "UPDATE_AVATAR")) {
            this.fullActorName = messageBody.replace("has updated the group photo", "").replace("hat das Foto der Gruppe aktualisiert", "");
            this.translationKey = "HAS_UPDATED_GROUP_PHOTO";
        } else if (message.group_action && (message.group_action === "UPDATE_ENCRYPTION"
            || message.group_action.type === "UPDATE_ENCRYPTION" || (!!message.group_action.type.type && message.group_action.type.type === "UPDATE_ENCRYPTION"))) {
            this.translationKey = "ENABLED_E2E_ENCRYPTION";
            if (messageBody.indexOf("disabled E2E encryption for the chat") !== -1) {
                this.translationKey = "DISABLED_E2E_ENCRYPTION";
            }
            this.fullActorName = messageBody.replace("enabled E2E encryption for the chat", "").replace("disabled E2E encryption for the chat", "");
        } else if (message.group_action && (message.group_action === "created"
            || message.group_action.type === "created")) {
            // this.logger.info("groupCreatedCheck: ", message);
            if (!!message.convTarget) {
                if (message.convTarget.indexOf("talk_meeting@conference") > -1) {
                    this.isMeeting = true;
                }
            }
            // this.translationParam = { groupName: messageBody.replace(/Group\s"/, "").replace(/"\sCreated/, "")};
        } else if (!!message.group_action && typeof message.group_action?.type === "string" && message.group_action?.type?.indexOf("MISSED_CALL") !== -1) {
            try {
                const data = JSON.parse(message.group_action?.type);
                let missCallJids = data.jids;
            } catch (ex) {
                // this.logger.sentryErrorLog("parse group_action error", ex);
            }
        } else if (message.group_action && message.group_action.type === "JOINED_PARTICIPANT") {
            const joinee = message.body.split(" ")[0];
            this.fullActorName = "this.contactRepo.getFullName(joinee)";
            if (joinee === this.userJID?.email) {
                this.translationKey = "YOU_JOINED_THE_GROUPCHAT";
            } else {
                this.translationKey = "JOINED_THE_GROUPCHAT";
            }
        }

        if (message.forwardMessage) {
            let translations: any = {};
            this.translate.get(["TICKET_MENTION_RECEIVER_MESSAGE", "TICKET_MENTION_SENDER_MESSAGE", "META_TASK_MENTION_RECEIVER_MESSAGE", "META_TASK_MENTION_SENDER_MESSAGE"]).pipe(take(1)).subscribe(v => {
                translations = v;
            });
            try {
                if (CommonUtils.parseRedmineMentions(TICKET_MENTION, messageBody).length > 0) {
                    const textToReplace = CommonUtils.parseRedmineMentions(TICKET_MENTION, messageBody)[0];
                    let userjid = textToReplace.split("#")[1];
                    // "this.contactRepo.getFullName(message.forwardMessage.from)"
                    let mentionText = translations.TICKET_MENTION_RECEIVER_MESSAGE.replace("{{ sender_name }}", this.otherUserName);
                    if (this.userJID?.email !== userjid) {
                        mentionText = translations.TICKET_MENTION_SENDER_MESSAGE.replace("{{ receiver_name }}", this.otherUserName );
                    }
                    messageBody = messageBody.replace(textToReplace, mentionText);
                } else if (CommonUtils.parseRedmineMentions(META_TASK_MENTION, messageBody).length > 0) {
                    const textToReplace = CommonUtils.parseRedmineMentions(META_TASK_MENTION, messageBody)[0];
                    let userjid = textToReplace.split("#")[1];
                    let mentionText = translations.TICKET_MENTION_RECEIVER_MESSAGE.replace("{{ sender_name }}", this.otherUserName);
                    if (this.userJID?.email !== userjid) {
                        mentionText = translations.TICKET_MENTION_SENDER_MESSAGE.replace("{{ receiver_name }}", this.otherUserName );
                    }
                    messageBody = messageBody.replace(textToReplace, mentionText);
                }
            } catch (error) {

            }
            if (!processOnly) {
                messageBody = this.sanitizer.bypassSecurityTrustHtml(messageBody.replace(/<a href=/g, "<a target=\"_blank\" class=\"open-new-window\" href=")
                    .replace(/<a class="([a-z\s0-9]*)"\shref=/g, "<a target=\"_blank\" class=\"open-new-window\" href="));
            }
        }

        if (messageBody.includes("http://") || messageBody.includes("https://")) {
        }
        if (!skipEmoji) {
            messageBody = this.sanitizer.bypassSecurityTrustHtml(messageBody.replace(/<a href=/g, "<a target=\"_blank\" class=\"open-new-window\" href=")
                .replace(/<a class="([a-z\s0-9]*)"\shref=/g, "<a target=\"_blank\" class=\"open-new-window\" href="));
            this.changeDetectionRef.markForCheck();
        }
        resolve(messageBody);
    });
  }

}
