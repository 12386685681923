
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="sidebar_rename_search_popup" class="mail_folder-create_dialog">
  <div class="content">
    <div id="sidebar_rename_search_header" class="message">
      {{ "RENAME_FOLDER" | translate }} "{{oldSearchName}}"
    </div>
    <div>
      <mat-form-field>
        <input id="sidebar_rename_search_name" [(ngModel)]="searchTitle" #searchNameInput matInput placeholder="{{ 'CONTACT_SEARCH_NAME'| translate }}" (keyup)="changeText($event)" autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false" [maxLength]="maxSearchTitleLength">
        <mat-hint>{{searchNameInput.value.length}}/{{maxSearchTitleLength}}</mat-hint>
      </mat-form-field>
    </div>
    <div class="actions">
      <a id="sidebar_rename_search_cancelbtn" (click)="closeDialog()">{{ 'CANCEL_MAIL_LABEL' | translate }}</a>
      <a id="sidebar_rename_search_renamebtn" [class.action-disabled]="searchTitle.length < 1" (click)="folderAction()">{{ 'RENAME_FODLER_LBL' | translate }}</a>
    </div>
  </div>
</div>
