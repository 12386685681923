
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { MailRootState } from "../store";
import { Store } from "@ngrx/store";
import { LoadMailMessagesSuccess,
    LoadMailMessageFail,
    DeleteMailMessageSuccess,
    UpdateMailMessageSuccess,
    AddMailMessageSuccess
} from "../store/actions";
import { Message, SaveSendMessage } from "../shared/models";
import { Observable, Subject } from "rxjs";
import { MailService } from "../shared/services/mail-service";
import { getOnlineStatus } from "src/app/reducers";
import { take } from "rxjs/operators";
import { ConversationRepository } from "./conversation.repository";

@Injectable()
export class MessageRepository {

    isOnline = false;

    constructor(
        private store: Store<MailRootState>,
        private convRepository: ConversationRepository,
        private mailService: MailService
    ) {
      this.store.select(getOnlineStatus).subscribe(res => {
        this.isOnline = res;
      });
    }

    setConversationLoadedSuccess(messages: Message[]): void {
        console.log("[MessageRepository][setConversationLoadedSuccess]", messages);
        this.store.dispatch(new LoadMailMessagesSuccess(messages));
    }

    // setConversationLoadedFail(): void {
    //     this.store.dispatch(new LoadMailMessageFail());
    // }

    setDeleteMessageSuccess(message: Message): void {
        this.store.dispatch(new DeleteMailMessageSuccess(message));
    }

    setUpdateMessageSuccess(message: Message): void {
        this.store.dispatch(new UpdateMailMessageSuccess({ id: message.id, changes: message }));
    }

    setAddMessageSuccess(message: Message): void {
        this.store.dispatch(new AddMailMessageSuccess(message));
    }

    messageAction(message: Message, folderId: any): Observable<string> {
        const response = new Subject<string>();
        const body = {
            op: "move",
            id: message.id,
            l: folderId
        };
        this.convRepository.messageAction(body).subscribe(res => {
            this.setDeleteMessageSuccess(message);
            response.next(message.id);
        });
        return response.asObservable();
    }
}
