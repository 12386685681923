
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import TIMEZONE from "./timezone";

export class PreferenceConstants {
    static TESTS = [
        "From",
        "To",
        "Cc",
        "To or Cc",
        "Subject",
        "Message",
        "Size",
        "Date",
        "Body",
        "Attachment",
        "Read Receipt",
        "Address in",
        "Calendar",
        "Social",
        "Header Named"
    ];
    static OPTION1 = [
        "matches exactly",
        "does not match exactly",
        "contains",
        "does not contain",
        "matches wildcard condition",
        "does not match wildcard condition"
    ];
    static OPTION2 = [
        "all",
        "localpart",
        "domain"
    ];
    static OPTION4 = [
        "in conversations I started",
        "in conversations I participated",
        "mass marketing (bulk)",
        "from distribution list",
        "marked as",
        "flagged"
    ];
    static OPTION5 = [
        "under",
        "not under",
        "over",
        "not over"
    ];
    static OPTION6 = [
        "B",
        "KB",
        "MB",
        "GB"
    ];
    static MENU2 = {
        "From": PreferenceConstants.OPTION1,
        "To": PreferenceConstants.OPTION1,
        "Cc": PreferenceConstants.OPTION1,
        "To or Cc": PreferenceConstants.OPTION1,
        "Subject": PreferenceConstants.OPTION1,
        "Message": ["is", "is not"],
        "Size": PreferenceConstants.OPTION5,
        "Date": ["before", "not before", "after", "not after"],
        "Body": ["contains", "does not contain"],
        "Social": ["Facebook notifications", "LinkedIn messages and connections", "Twitter notifications"],
        "Calendar": ["invite is requested", "invite is not requested", "invite is replied", "invite is not replied"],
        "Address in": ["From", "To", "Cc", "To or Cc"],
        "Attachment": ["exists", "does not exist"],
        "Read Receipt": ["exists", "does not exist"],
        "Header Named": "input"
    };
    static HEADER_NAMED_OPTIONS = PreferenceConstants.OPTION1.concat(["exists", "does not exist"]);
    static MENU3 = {
        "From": "input",
        "To": "input",
        "Cc": "input",
        "To or Cc": "input",
        "Body": "input",
        "Size": "input",
        "Subject": "input",
        "Date": "datetime",
        "Address in": ["in", "not in", "is me", "is not me"],
        "Message": PreferenceConstants.OPTION4,
        "Header Named": PreferenceConstants.HEADER_NAMED_OPTIONS
    };
    static MENU4 = {
        "From": PreferenceConstants.OPTION2,
        "To": PreferenceConstants.OPTION2,
        "Cc": PreferenceConstants.OPTION2,
        "To or Cc": PreferenceConstants.OPTION2,
        "Size": PreferenceConstants.OPTION6,
        "Address in": ["Contacts", "My Frequent Emails"],
        "Message": ["read", "priority", "high importance", "normal importance", "low importance"],
        "Header Named": "input"

    };
    static ACTIONS = [
        "Keep in Inbox",
        "Delete",
        "Move into folder",
        "Mark as",
        "Redirect to Address"
    ];

    static LANGUAGES = {
        ar: "Arabic",
        // zh: 'Chinese',
        zh_CN: "Chinese (China)",
        zh_HK: "Chinese (Hong Kong)",
        zh_TW: "Chinese (Taiwan)",
        da: "Danish",
        nl: "Dutch",
        // en: 'English',
        en_AU: "English (Australia)",
        en_GB: "English (United Kingdom)",
        en_US: "English (United States)",
        // fr: 'French',
        fr_CA: "French (Canada)",
        fr_FR: "French (France)",
        de: "German",
        hi: "Hindi",
        hu: "Hungarian",
        it: "Italian",
        ja: "Japanese",
        ko: "Korean",
        ms: "Malay",
        pl: "Polish",
        // pt: 'Portuguese',
        pt_BR: "Portuguese (Brazil)",
        pt_PT: "Portuguese (Portugal)",
        ro: "Romanian",
        ru: "Russian",
        es: "Spanish",
        sv: "Swedish",
        tr: "Turkish",
        th: "Thai",
        uk: "Ukrainian"
    };

    static TIMEZONE = TIMEZONE;

    static DEFAULT_SETTINGS = {
        DEFAULT_AUTO_SAVE_DRAFT_INTERVAL: 15,
        AUTO_SAVE_IDLE_TIME: 3,
        DEFAULT_MAX_MESSAGE_SIZE: 250000,
        POP_DOWNLOAD_SINCE_ALL: 0,
        POP_DOWNLOAD_SINCE_NO_CHANGE: 1,
        POP_DOWNLOAD_SINCE_FROM_NOW: 2,
        POP_DELETE_OPTION_KEEP: "keep",
        POP_DELETE_OPTION_READ: "read",
        POP_DELETE_OPTION_TRASH: "trash",
        POP_DELETE_OPTION_HARD_DELETE: "delete",
        SEND_RECEIPT_NEVER: "never",
        SEND_RECEIPT_ALWAYS: "always",
        SEND_RECEIPT_PROMPT: "prompt"
    };
}
