
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { EnvironmentTheme } from "./environment.interface";

export const theme: EnvironmentTheme = {
  title: "VNCmail",
  appLogo: "assets/images/logo.png",
  textLogo: "<span class=\"vnc\">VNC</span><span class=\"mail\">mail</span>",
  theme: "default",
  aboutUsLogo: "assets/images/VNCmail.svg",
  aboutText: "<span class=\"disable-select\">VNC</span><span class=\"product_name disable-select\">mail</span>",
  mobileTextLogo: "VNC",
  profileBackgroundImage: "/assets/img/profile-background.jpg",
  profileBackgroundImage2: "/assets/img/background.png"
};
