
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy,
    ViewChild, OnInit, AfterViewInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { FlatTreeControl } from "@angular/cdk/tree";
import { MatTreeFlatDataSource, MatTreeFlattener} from "@angular/material/tree";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { of as observableOf, Subject, Observable } from "rxjs";
import { MailFolderFlatNode, MailFolder, FolderLink } from "src/app/mail/models/mail-folder.model";
import { ContextMenuComponent, ContextMenuService } from "ngx-contextmenu";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { SearchFolder } from "src/app/shared/models/search-folder";
import { CommonUtils } from "src/app/common/utils/common-util";
import { Store } from "@ngrx/store";
import { MailRootState, getSelectedMailFolder } from "src/app/mail/store";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MailFolderRepository } from "src/app/mail/repositories/mail-folder-repository";
import { Router, NavigationEnd } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { MaildragService } from "../../services/maildrag.service";
import { MouseflowFolderService } from "../../services/mouseflow-folder.service";
import { PreferenceService } from "src/app/preference/shared/services/preference.service";
import { ToastService } from "src/app/common/providers/toast.service";
import { ElectronService } from "src/app/services/electron.service";
import { takeUntil, filter, debounceTime, take, distinctUntilChanged } from "rxjs/operators";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { DataSource } from "src/app/preference/shared/models";
import { getExpandMailFolders, getReadingPane } from "src/app/reducers";
import { MailService } from "src/app/mail/shared/services/mail-service";
import { SetExpandMailFolders } from "src/app/actions/app";
import { getUserFolders } from "src/app/mail/store/selectors";
import { ResizeEvent } from "angular-resizable-element";
import { ConversationRepository } from "src/app/mail/repositories/conversation.repository";
import { ContextMenuFixService } from "src/app/shared/contextMenu.service";
import { VncLibraryService } from "vnc-library";

@Component({
    selector: "vp-folder-list",
    templateUrl: "./folder-list.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FolderListComponent implements OnDestroy, OnInit, AfterViewInit {
    treeControl: FlatTreeControl<MailFolderFlatNode>;
    treeFlattener: MatTreeFlattener<MailFolder, MailFolderFlatNode>;
    dataSource: MatTreeFlatDataSource<MailFolder, MailFolderFlatNode>;
    folders: MailFolder[] = [];
    @ViewChild(CdkVirtualScrollViewport, {static: false}) virtualScroll: CdkVirtualScrollViewport;
    private isAlive$ = new Subject<boolean>();
    @ViewChild("folderMenu", {static: false}) contextMenu: ContextMenuComponent;
    folderInAction: FolderLink;
    emptyAction = {
        key: MailConstants.EMPTY_FOLDER_ACTION
    };
    selectedFolder: MailFolder;
    folderColor: string = "black";
    private changeColor = new Subject();
    searchFolders: SearchFolder[] = [];
    searchQuery: string = "";
    isRootUserFolderCollapsed: boolean = false;
    dragSelected: any;
    dragStatus: boolean = false;
    isNewFolderCreated: boolean = false;
    disableMoveFolder: any = [];
    expandedNodes: MailFolder[] = [];
    isSmartSearchCollapsed: boolean = false;
    syncPolling: any;
    emailTemplateFolder: MailFolder;
    availableZimlets: any[] = [];
    isZimletsExpand: boolean = true;
    isLoading: boolean = false;
    contextMenuActions = {
        "isEnableNewFolder": true,
        "isEnableMarkRead": true,
        "isEnableShare": true,
        "isEnableMove": true,
        "isEnableRename": true,
        "isEnableDelete": true,
        "isEnableEmpty": true,
        "isEnableEmptyTrash": true,
        "isEnableColorControl": true,
        "isExternalFolder" : false,
        "isEditFolder": true,
        "isEnableExpandAll": true
    };
    mouseEntered: boolean;
    draggedFolder: any;
    folderToMove: { source: MailFolder; target: MailFolder; };
    startScrolling: any;
    currentUrl = "";
    isEnabledEmailFormsZimlet: boolean;
    isOnMobileDevice: boolean = CommonUtils.isOnMobileDevice();
    isSharingFeatureEnabled: boolean = true;
    pageNumber: number = 1;
    systemFolders: MailFolder[];
    currentRange: any;
    expandMailFolders: any = [];
    allExpandMailFolders: any = [];
    previewPanel = !!localStorage.getItem("readingPane") ? localStorage.getItem("readingPane") : "right";
    @Output() onDragDrop = new EventEmitter<any>();
    constructor(private store: Store<MailRootState>,
        private vncLibraryService: VncLibraryService,
        private changeDetectionRef: ChangeDetectorRef,
        private contextMenuService: ContextMenuFixService,
        private mailFolderRepository: MailFolderRepository,
        private router: Router,
        private translate: TranslateService,
        private snackBar: MatSnackBar,
        private mailBroadCaster: MailBroadcaster,
        public maildragService: MaildragService,
        private mouseflowFolderService: MouseflowFolderService,
        private preferenceService: PreferenceService,
        private toastService: ToastService,
        private electronService: ElectronService,
        private mailService: MailService,
        private convRepository: ConversationRepository,
        ) {
        this.currentUrl = this.router.routerState.snapshot.url;
        this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel,
        this._isExpandable, this._getChildren);
        this.treeControl = new FlatTreeControl<MailFolderFlatNode>(this._getLevel, this._isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        this.router.events.pipe(takeUntil(this.isAlive$)).pipe(filter(e => e instanceof NavigationEnd)).subscribe(data => {
            this.currentUrl = this.router.routerState.snapshot.url;
            this.changeDetectionRef.markForCheck();
        });

        this.store.select(getExpandMailFolders).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            if (res) {
                this.allExpandMailFolders = res.split(",");
                this.allExpandMailFolders.forEach(element => {
                    if (this.expandMailFolders.indexOf(element.split(":")[0] > -1)) {
                        this.expandMailFolders.push(element.split(":")[0]);
                    }
                });
            }
        });

        this.store.select(getSelectedMailFolder).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            this.selectedFolder = res;
            if (res) {
                if (this.treeControl.isExpanded(this.selectedFolder)) {
                    this.expandParentFolder(this.selectedFolder, this.mailFolderRepository.getRootFolder(this.selectedFolder));
                }
                this.searchQuery = null;
            }
            this.changeDetectionRef.markForCheck();
        });

        this.store.select(getUserFolders).pipe(distinctUntilChanged(), debounceTime(1000), takeUntil(this.isAlive$)).subscribe(res => {
            console.log("[FolderListComponent] getUserFolders", res);

            this.folders = res;
            this.initDataSource();
            if (this.selectedFolder) {
                this.expandSelectedTree();
            }
            this.changeDetectionRef.markForCheck();
        });

        this.store.select(getReadingPane).pipe(takeUntil(this.isAlive$)).subscribe(value => {
            // console.log("[getReadingPane]", value);
            if (this.previewPanel !== value) {
              this.previewPanel = value;
              this.changeDetectionRef.markForCheck();
            }
        });

        this.mailBroadCaster.on<any>(MailConstants.EXPAND_FOLDER).pipe(takeUntil(this.isAlive$))
            .subscribe(data => {
                if (data) {
                    this.pushUniqueExpandNode(data.selectedFolder);
                    this.expandParentFolder(data.selectedFolder, this.mailFolderRepository.getRootFolder(data.selectedFolder));
                    this.expandSelectedTree();
                    this.changeDetectionRef.markForCheck();
                }
            });

        this.mailBroadCaster.on<any>(MailConstants.UPDATE_TARGET_FOLDER).pipe(takeUntil(this.isAlive$))
            .subscribe(res => {
                if (res) {
                    this.expandedNodes.splice(this.expandedNodes.indexOf(this.folderInAction), 1);
                    this.folderInAction = res;
                    this.pushUniqueExpandNode(res);
                    this.expandSelectedTree();
                }
            });

        this.mailBroadCaster.on<any>("MARK_FOR_CHECK_BROADCAST").pipe(takeUntil(this.isAlive$))
            .subscribe(res => {
                setTimeout(() => {
                    this.changeDetectionRef.markForCheck();
                }, 500);
            });

        this.mailBroadCaster.on<any>(MailConstants.FOLDER_CREATE_BROADCAST).pipe(takeUntil(this.isAlive$))
            .subscribe(res => {
            this.isNewFolderCreated = true;
        });

        this.convRepository.getIsConversationLoading().pipe(takeUntil(this.isAlive$)).subscribe(isLoading => {
            // console.log("[getIsConversationLoading]", isLoading);
            this.isLoading = isLoading;
            this.changeDetectionRef.markForCheck();
        });
    }

    hasNestedChild = (_: number, nodeData: MailFolder) => {
        return nodeData.children && nodeData.children.length > 0
        || nodeData.link && nodeData.link.length > 0;
    }

    ngOnInit(): void {
        this.changeColor.pipe(
            debounceTime(1000)
        ).subscribe(e => {
            this.mailFolderRepository.changeFolderColor(this.folderInAction, this.folderColor);
            this.closeContextMenuOnScroll();
        });

        this.maildragService.getDraggableData().pipe(debounceTime(150), takeUntil(this.isAlive$)).subscribe(res => {
            if (res.event === "start") {
                this.maildragService.dragStatus = true;
                this.maildragService.dragSelected = res.data.source.data;
            } else {
                this.maildragService.dragSelected = null;
                this.maildragService.dragStatus = false;
            }
            console.log("[sidebar.component.getDraggableData]", res, this.maildragService.dragStatus, new Date());
            this.disableMoveFolder = [];
        });
    }

    expandSelectedTree() {
        this.expandedNodes.forEach(node => {
            this.treeControl.expand(node);
        });
    }

    pushUniqueExpandNode(node) {
        if (node !== undefined) {
            const oldNode = this.expandedNodes.find(x => x.id === node.id);
            if (oldNode) {
                this.expandedNodes.splice(this.expandedNodes.indexOf(oldNode), 1);
            }
            this.expandedNodes.push(node);
            this.updateZimbraPrefFoldersExpanded(node.id, true);
        }
    }

    updateZimbraPrefFoldersExpanded(id, isAdd) {
        let mailBoxMetadata = {};
        if (isAdd) {
            if (this.expandMailFolders.indexOf(id) === -1) {
                this.allExpandMailFolders.push(id + ":true");
                this.expandMailFolders.push(id);
            }
        } else {
            this.allExpandMailFolders = this.allExpandMailFolders.filter(ele => (ele.split(":")[0] !== id));
            this.expandMailFolders = this.expandMailFolders.filter(v => v.toString() !== id.toString());
        }
        mailBoxMetadata["zimbraPrefFoldersExpanded"] = this.allExpandMailFolders.toString();
        this.store.dispatch(new SetExpandMailFolders(this.allExpandMailFolders.toString()));
        this.mailService.setMailBoxMetaData(mailBoxMetadata).pipe(take(1)).subscribe(resp => {
        });
    }

    expandParentFolder(targetFolder: MailFolder, rootFolder: MailFolder) {
        if (targetFolder.l === MailConstants.ROOT_MAIL_FOLDER_ID) {
            this.pushUniqueExpandNode(targetFolder);
            return;
        }
        const folder = this.mailFolderRepository.getParentFolder(targetFolder, rootFolder);
        if (folder) {
            this.pushUniqueExpandNode(folder);
        }
        this.changeDetectionRef.markForCheck();
        if (folder && folder.l !== MailConstants.ROOT_MAIL_FOLDER_ID) {
            this.expandParentFolder(folder, rootFolder);
        } else {
            return;
        }
    }

    removeNodeFromList(node: MailFolder) {
        this.folderInAction = null;
        const tempNode = this.expandedNodes.find(n => n.id === node.id);
        if (tempNode) {
            this.expandedNodes.splice(this.expandedNodes.indexOf(tempNode), 1);
            this.updateZimbraPrefFoldersExpanded(tempNode.id, false);
        }
    }

    mouseDownAction(node: any) {
        this.folderInAction = node;
        if (this.folderInAction.owner && !this.folderInAction.oname) {
            this.removeBrokenLinkFolder(this.folderInAction);
            return;
        }
        if (this.folderInAction.rgb) {
            this.folderColor = this.folderInAction.rgb.toLowerCase();
        } else {
            this.folderColor = "black";
        }
        if (!node.icon) {
            if (node.perm && node.perm === "r") {
                this.contextMenuActions.isEnableColorControl = false;
            } else {
                this.contextMenuActions.isEnableColorControl = true;
            }
        } else {
            this.contextMenuActions.isEnableColorControl = false;
        }
        this.changeDetectionRef.markForCheck();
    }

    updateDefaultColor(color: string) {
        this.folderColor = color;
        this.changeColor.next(true);
    }

    closeContextMenuOnScroll() {
        if (!MailUtils.isIE() && this.contextMenuService && document.querySelector(".ngx-contextmenu")) {
            const keyEvent = new KeyboardEvent("keydown", { key: "Escape" });
            this.contextMenuService.closeAllContextMenus({
                eventType: "cancel",
                event: keyEvent
            });
        }
    }

    onContextMenu($event: MouseEvent, node: any) {
        if (!node) {
            return;
        }
        this.folderInAction = node;
        if (this.folderInAction.owner && !this.folderInAction.oname) {
            this.removeBrokenLinkFolder(this.folderInAction);
            this.closeContextMenuOnScroll();
            return;
        }
        this.contextMenuActions.isEnableEmptyTrash = false;
        this.contextMenuActions.isEditFolder = true;
        this.contextMenuActions.isEnableExpandAll = false;
        if (node.children) {
            this.contextMenuActions.isEnableExpandAll = true;
        }
        this.emptyAction.key = MailConstants.EMPTY_FOLDER_ACTION;
        const externalAccount = this.getExternalAccount(node);
        console.log("[onContextMenu]", node);
        switch (node.name) {
            case MailConstants.INBOX_FOLDER_TITLE:
            case MailConstants.SENT_FOLDER_TITLE:
                if (node.id === "2" || node.id === "7") {
                    this.resetAction(true, true, true, true, false, false, false);
                } else {
                    this.resetAction(true, true, true, true, true, true, true);
                }
                break;
            case MailConstants.DRAFTS_FOLDER_TITLE:
                if (node.id === "6") {
                    this.resetAction(false, true, true, true, false, false, false);
                } else {
                    this.resetAction(true, true, true, true, true, true, true);
                }
                break;
            case MailConstants.TRASH_FOLDER_TITLE:
                if (node.id === "3") {
                    this.contextMenuActions.isEditFolder = false;
                    this.emptyAction.key = MailConstants.EMPTY_TRASH_ACTION;
                    this.resetAction(true, true, true, false, false, false, false);
                } else {
                    this.resetAction(true, true, true, true, true, true, true);
                }
                break;
            case MailConstants.SPAM_FOLDER_TITLE:
                if (node.id === "4") {
                    this.contextMenuActions.isEditFolder = false;
                    this.resetAction(false, true, true, true, false, false, false);
                } else {
                    this.resetAction(true, true, true, true, true, true, true);
                }
                break;
            case MailConstants.CHATS_FOLDER_TITLE:
                this.contextMenuActions.isEditFolder = false;
                this.resetAction(true, true, node.n && node.n > 0, true, false, false, false);
                break;
            case MailConstants.STARRED_FOLDER_TITLE:
                this.contextMenuActions.isEditFolder = false;
                this.resetAction(false, true, false, false, false, false, false);
                break;
            default:
                if ( externalAccount !== null && externalAccount !== undefined ) {
                    if (externalAccount.l.toString() === node.id.toString() ) {
                        this.resetAction(true, true, true, true, false, false, false, true);
                    } else if ( externalAccount.l.toString() === node.id.toString() && node.f ) {
                        this.resetAction(true, false, false, false, false, false, false, true);
                    } else {
                        this.resetAction(true, true, true, true, true, true, true);
                    }
                } else {
                    this.resetAction(true, true, true, true, true, true, true);
                }
                break;
        }

        if ( node.f && node.f.indexOf("y") !== -1 && node.name.toLowerCase() === "inbox" ) {
            this.resetAction(true, true, false, true, false, false, false);
        } else if ( node.f && node.f.indexOf("u") !== -1 && node.name.toLowerCase() === "chats" ) {
            this.resetAction(false, true, true, false, false, false, false);
        } else if ( node.f && node.f.indexOf("y") !== -1 && node.name.toLowerCase() === "drafts" ) {
            this.resetAction(false, false, false, true, false, false, false);
        } else if ( node.f && node.f.indexOf("y") !== -1 && node.name.toLowerCase() === "junk" ) {
            this.resetAction(false, true, true, false, false, false, false);
        } else if ( node.f && node.f.indexOf("y") !== -1 && node.name.toLowerCase() === "sent" ) {
            this.resetAction(true, true, false, true, false, false, false);
        }
        this.contextMenuService.show.next({
            contextMenu: this.contextMenu,
            event: $event,
            item: node,
        });
        $event.preventDefault();
        $event.stopPropagation();
    }

    resetAction(isEnableNewFolder,
        isEnableMarkRead,
        isEnableEmpty,
        isEnableShare,
        isEnableMove,
        isEnableDelete,
        isEnableRename,
        isExternalFolder?
    ) {
        if (!this.folderInAction) {
            return;
        }
        if ( isExternalFolder === undefined ) {
            this.contextMenuActions.isExternalFolder = false;
        }
        if (this.folderInAction.perm && this.folderInAction.perm === "r") {
            this.contextMenuActions.isEnableNewFolder = false;
            this.contextMenuActions.isEnableEmpty = false;
            this.contextMenuActions.isEnableMarkRead = false;
            this.contextMenuActions.isEnableShare = false;
            this.contextMenuActions.isEnableMove = false;
            this.contextMenuActions.isEnableDelete = false;
            this.contextMenuActions.isEnableRename = false;
            this.changeDetectionRef.markForCheck();
            return;
        }
        this.contextMenuActions.isEnableNewFolder = isEnableNewFolder;
        if (this.folderInAction.u && this.folderInAction.u !== "0") {
            this.contextMenuActions.isEnableMarkRead = isEnableMarkRead;
        } else {
            this.contextMenuActions.isEnableMarkRead = false;
        }
        if (this.folderInAction.children || (this.folderInAction.n && this.folderInAction.n !== "0")) {
            this.contextMenuActions.isEnableEmpty = isEnableEmpty;
        } else {
            this.contextMenuActions.isEnableEmpty = false;
        }
        this.contextMenuActions.isEnableShare = isEnableShare;
        this.contextMenuActions.isEnableMove = isEnableMove;
        this.contextMenuActions.isEnableDelete = isEnableDelete;
        this.contextMenuActions.isEnableRename = isEnableRename;

        if ( isExternalFolder === true) {
            this.contextMenuActions.isExternalFolder = true;
        }
        if (this.isEnabledEmailFormsZimlet && this.folderInAction.name === MailConstants.EMAIL_TEMPLATE) {
            this.contextMenuActions.isEnableMove = false;
            this.contextMenuActions.isEnableDelete = false;
        }
        this.changeDetectionRef.markForCheck();
    }

    routeToFolder(folder) {
        console.log("[routeToFolder]", folder.id);
        if (this.isLoading) {
            return false;
        }
        this.closeContextMenuOnScroll();
        if (folder.owner && ! folder.oname) {
            this.removeBrokenLinkFolder(folder);
            return;
        }
        this.selectedFolder = folder;
        this.expandedNodes = [];
        this.searchQuery = null;
        this.changeDetectionRef.markForCheck();
        const absFolderPath = this.mailFolderRepository.getAbsolutePathShareFolder(folder);
        if (this.electronService.isElectron) {
            this.electronService.setToStorage(MailConstants.SELECTED_FOLDER_ABSPATH, absFolderPath);
        } else {
            localStorage.setItem(MailConstants.SELECTED_FOLDER_ABSPATH, absFolderPath);
        }
        if (this.router.url === "/mail/folder/" + folder.id) {
            this.mailBroadCaster.broadcast(MailConstants.REFRESH_BROADCAST);
        } else {
            this.router.navigate(["/mail/folder", folder.id]);
        }
        console.log("[routeToFolder] /mail/folder", folder.id);
        if (this.previewPanel === "off") {
            this.mailBroadCaster.broadcast("HIDE_MAIL_LIST_FROM_MAIL_DETAIL", false);
        }
    }

    routeCompose() {
        this.selectedFolder = null;
        this.router.navigate(["/mail/compose"]);
    }

    expandCollapseUserRootFolder(isExpnaded) {
        this.isRootUserFolderCollapsed = isExpnaded;
        this.changeDetectionRef.markForCheck();
        if (!isExpnaded) {
            setTimeout(() => {
                if (this.currentRange) {
                    this.dataSource.data = this.folders.slice(this.currentRange.start, this.currentRange.end + 1);
                }
                this.changeDetectionRef.markForCheck();
                if (this.virtualScroll) {
                    this.virtualScroll.renderedRangeStream.pipe(takeUntil(this.isAlive$)).subscribe(range => {
                        this.dataSource.data = this.folders.slice(range.start, range.end);
                        this.currentRange = range;
                        this.changeDetectionRef.markForCheck();
                    });
                }
            }, 1000);
        }
    }

    openMoveFolderDialog() {
        this.mailBroadCaster.broadcast("openMoveFolderDialog", { targetFolder: this.folderInAction, isRename: true });
    }

    handleColorChange(event: any) {
        this.folderColor = event.color.hex;
        this.changeColor.next(event);
    }

    markAllMailRead() {
        this.mailFolderRepository.markAllMailRead(this.folderInAction);
    }

    createNewFolder(folder?: MailFolder) {
        this.mailBroadCaster.broadcast("createNewFolder", { targetFolder: folder });
    }

    renameFolder() {
        this.mailBroadCaster.broadcast("renameFolder", { targetFolder: this.folderInAction, isRename: true });
    }

    searchFolder(folder?: any) {
        this.mailBroadCaster.broadcast("searchFolder", { targetFolder: this.folderInAction });
    }

    deleteFolder() {
        this.mailBroadCaster.broadcast("deleteFolder", { targetFolder: this.folderInAction });
    }

    emptyFolder() {
        this.mailBroadCaster.broadcast("emptyFolder", { targetFolder: this.folderInAction });
    }

    shareFolder() {
        this.mailBroadCaster.broadcast("shareFolder", { targetFolder: this.folderInAction });
    }

    private isValidateFolderToMove(source, target): boolean {
        const folderId = source.id;
        const folderName = source.name.toLowerCase();
        if (target.absFolderPath.toLowerCase().indexOf(source.absFolderPath.toLowerCase() + "/") !== -1 || target.name === "Spam"
            || target.name === "Drafts" || target.name === "Starred"
            || folderId === target.id || folderId === target.l
            || (target.link && (target.link.find(f => f.id === folderId) || target.link.find(f => f.name.toLowerCase() === folderName)))
            || (target.children && (target.children.find(f => f.id === folderId)
            || target.children.find(f => f.name.toLowerCase() === folderName)))) {
                return false;
        }
        return true;
    }

    mouseEnter(node, event): void {
        // console.log("[sidebar.component.mouseEnter]", this.maildragService.dragStatus, node, event.clientY);
        this.mouseEntered = true;
        if (!!this.maildragService.draggedFolder) {
            if (event.clientY < 200) {
                if (!!this.startScrolling) {
                    clearInterval(this.startScrolling);
                }
                this.startScrolling = setInterval(() => {
                    const element = document.querySelector(".sidebar-folders");
                    element.scrollTop = element.scrollTop - 20;
                    if (element.scrollTop === 0 && this.startScrolling) {
                        clearInterval(this.startScrolling);
                    }
                }, 200);
            } else if (event.clientY > window.innerHeight - 60) {
                if (!!this.startScrolling) {
                    clearInterval(this.startScrolling);
                }
                this.startScrolling = setInterval(() => {
                    const element = document.querySelector(".sidebar-folders");
                    const subFolderElement = <HTMLElement>document.querySelector(".sidebar-tree-subfolders");
                    element.scrollTop = element.scrollTop + 20;

                    if (element.scrollTop > subFolderElement.offsetHeight + 60 && !!this.startScrolling) {
                        clearInterval(this.startScrolling);
                    }
                }, 200);
            } else {
                if (!!this.startScrolling) {
                    clearInterval(this.startScrolling);
                }
            }
            // console.log("[sidebar.component.mouseEnter] data", this.maildragService.draggedFolder.data);
            const body = document.querySelector("body");
            if (!this.isValidateFolderToMove(this.maildragService.draggedFolder.data, node)) {
                body.classList.add("drop-inactive");
                body.classList.remove("drop-active");
                this.disableMoveFolder[node.id] = true;
                this.maildragService.folderToMove = null;
            } else {
                this.maildragService.folderToMove = {source: this.maildragService.draggedFolder.data, target: node};
                this.disableMoveFolder[node.id] = false;
                body.classList.remove("drop-inactive");
                body.classList.add("drop-active");
            }
            this.changeDetectionRef.markForCheck();
            return;
        }
        if (this.maildragService.dragStatus && this.maildragService.dragSelected && this.maildragService.dragSelected.l === "6") {
            this.disableMoveFolder[node] = true;
            this.changeDetectionRef.markForCheck();
        }
        setTimeout(() => {
            this.disableFolder(node.id);
            if (this.maildragService.dragStatus) {
                this.mouseflowFolderService.mouseEnter(node, this.maildragService.dragSelected);
            }
        }, 200);
    }

    mouseOut(node): void {
        // console.log("[mouseOut]", node);
        this.mouseEntered = false;
        if (this.startScrolling) {
            clearInterval(this.startScrolling);
        }
        if (!!this.maildragService.draggedFolder) {
            const body = document.querySelector("body");
            this.disableMoveFolder[node.id] = false;
            this.maildragService.folderToMove = null;
            body.classList.add("drop-inactive");
            body.classList.remove("drop-active");
            this.changeDetectionRef.markForCheck();
            return;
        }

        setTimeout(() => {
            if (this.maildragService.dragStatus) {
                this.mouseflowFolderService.mouseOut(node, this.maildragService.dragSelected);
            }
            this.disableFolder(node.id);
        }, 200);
    }

    disableFolder(node): void {
        // console.log("[disableFolder]", node, this.maildragService.dragSelected);
        if (this.maildragService.dragStatus) {
            let find = this.maildragService.dragSelected.l === node.toString();
            if (!!this.maildragService.dragSelected.m) {
                find = this.maildragService.dragSelected.m.find((element) => {
                    return element.l === node.toString();
                });
            }

            if (find || this.maildragService.dragSelected.l === "6") {
                this.disableMoveFolder[node] = true;
            } else {
                this.disableMoveFolder[node] = false;
            }
        } else {
            this.disableMoveFolder[node] = false;
        }
    }

    handleMouseOverExpand(event, node): void {
        if (this.maildragService.dragStatus || !!this.maildragService.draggedFolder) {
            const el: HTMLElement = event.relatedTarget as HTMLElement;
            el.click();
        }
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.unsubscribe();
        if ( this.syncPolling ) {
            this.syncPolling.unsubscribe();
        }
    }

    expandCollapseSmartSearch(isExpanded: boolean): void {
        this.isSmartSearchCollapsed = isExpanded;
        this.changeDetectionRef.markForCheck();
    }

    private folderSuccessAction(
        sourceFolder: MailFolder,
        currentParentId: string,
        translationKey: string,
        canUndo: boolean
    ): void {
        let translationText = "";
        let undoText = "";
        this.translate
            .get(translationKey, { folderName: sourceFolder.name })
            .pipe(take(1))
            .subscribe((text: string) => {
                translationText = text;
            });

        if (canUndo) {
            this.translate
                .get("UNDO")
                .pipe(take(1))
                .subscribe((text: string) => {
                    undoText = text.toUpperCase();
                });
            this.vncLibraryService.openSnackBar(translationText, "action", undoText, "", 2000, "bottom", "left").subscribe((r) => {
                if (r === "Action") {
                    this.performUndo(sourceFolder, currentParentId);
                }
            });
        }
    }

    performUndo(sourceFolder, targetFolderId) {
        this.mailFolderRepository
            .moveMailFolder(sourceFolder.id, targetFolderId)
            .subscribe(res => {
                let translationText = "";
                this.translate
                    .get("FOLDER_RESTORED_MSG")
                    .pipe(take(1))
                    .subscribe((text: string) => {
                        translationText = text;
                    });
                this.vncLibraryService.openSnackBar(translationText, "checkmark",
                "", "", 2000, "bottom", "left").subscribe(res => {
                });
            });
    }

    onScroll(ev): void {
        console.log("[onScroll]", ev);
        const divs: any = document.querySelectorAll(".system_folder .dropbox_div");
        divs.forEach(item => {
            (<HTMLElement>item).style.left = ev.target.scrollLeft + 200 + "px";
        });
    }

    getExternalAccount(node: MailFolder): DataSource {
        if (this.preferenceService.dataSource$ !== undefined && this.preferenceService.dataSource$.length > 0 ) {
            return this.preferenceService.dataSource$.filter(source => source.l.toString() === node.id.toString())[0];
        }
        return null;
    }

    getExternalMail(): void {
        const account: DataSource = this.getExternalAccount(this.folderInAction);
        this.preferenceService.importData(account).pipe(takeUntil(this.isAlive$)).subscribe( res => {
            this.checkImportStatus(account);
        });
    }

    checkImportStatus(account: DataSource ) {
        const errors = [];
        const dataSources: DataSource[] = [];
        this.preferenceService.getImportStatus().subscribe(data => {
            if (data.GetImportStatusResponse) {
                if (data.GetImportStatusResponse[0].pop3) {
                    let pop3 = data.GetImportStatusResponse[0].pop3;
                    if (!Array.isArray(pop3)) {
                        pop3 = [pop3];
                    }
                    for (const a of pop3) {
                        if (a.err) {
                            errors.push(a.err);
                        }
                        dataSources.push(a);
                    }
                }
                if (data.GetImportStatusResponse[0].imap) {
                    let imap = data.GetImportStatusResponse[0].imap;
                    if (!Array.isArray(imap)) {
                        imap = [imap];
                    }
                    for (const a of imap) {
                        if (a.err) {
                            errors.push(a.err);
                        }
                        dataSources.push(a);
                    }
                }
            }
            if (errors.length === 0) {
                const source: any =  dataSources.filter(datasouce => account.id === datasouce.id)[0];
                if ( source.isRunning === true ) {
                    setTimeout(() => {
                        this.checkImportStatus(account);
                    }, 10000);
                } else {
                    let translationText: any = {};
                    this.translate.get(["ACCOUNT", "LOADED"]).pipe(take(1)).subscribe((text: string) => {
                        translationText = text;
                    });
                    this.vncLibraryService.openSnackBar(translationText.ACCOUNT + " \"" + account.name + "\" " + translationText.LOADED, "checkmark",
                    "", "", 2000, "bottom", "left").subscribe(res => {
                    });
                }
            }
        });
    }

    editFolderProperties(): void {
        this.mailBroadCaster.broadcast("editFolderProperties", { targetFolder: this.folderInAction });
    }

    public isVisibleExternalFolder = (item: any): boolean => {
        return this.contextMenuActions.isExternalFolder ? true : false;
    }

    expandAllFolders(mailFolder: MailFolder): void {
        if (!!mailFolder) {
            this.treeControl.expand(mailFolder);
            this.updateZimbraPrefFoldersExpanded(mailFolder?.id, true);
            const children =  MailUtils.getChildFolders([mailFolder]);
            children.forEach(folder => {
                if (folder.children) {
                    this.treeControl.expand(folder);
                    this.updateZimbraPrefFoldersExpanded(folder.id, true);
                }
            });
        }
    }

    dragStarted($event) {
        console.log("[dragStarted]", $event);
        this.maildragService.draggedFolder = $event.source;
        this.changeDetectionRef.markForCheck();
    }

    dragEnded($event) {
        console.log("[dragEnded]", $event, this.maildragService.folderToMove);
        this.maildragService.draggedFolder = null;
        const body = document.querySelector("body");
        body.classList.remove("drop-inactive");
        body.classList.remove("drop-active");
        if (!!this.maildragService.folderToMove) {
            const folderToMove = this.maildragService.folderToMove;
            this.mailFolderRepository
            .moveMailFolder(folderToMove.source.id, folderToMove.target.id)
            .subscribe(res => {
              this.folderSuccessAction(
                folderToMove.target,
                folderToMove.source.id,
                "FOLDER_MOVED_MSG",
                true
              );
            });
        }
        this.maildragService.folderToMove = null;
    }

    removeBrokenLinkFolder(folder: any): void {
        this.mailBroadCaster.broadcast("removeBrokenLinkFolder", { targetFolder: folder });
    }

    transformer = (node: MailFolder, level: number) => {
        const flat = node as MailFolderFlatNode;
        flat.level = level;
        flat.expandable = !!node.children;
        // console.log("[expandFolders] transformer", node, this.expandMailFolders);
         if ((node.children && node.children.length > 0 || node.link && node.link.length > 0)
            && this.expandMailFolders.indexOf(node.id) > -1 ) {
            this.expandedNodes.push(node);
            this.treeControl.expand(node);
        }
        return flat;
    }

    private _getLevel = (node: MailFolderFlatNode) => node.level;

    private _isExpandable = (node: MailFolderFlatNode) => {
        return node.children && node.children.length > 0 || node.link && node.link.length > 0;
    }

    private _getChildren(node: MailFolder): Observable<MailFolder[]> {
        let folders = node.children || [];
        if (node.link) {
            folders = [...folders, ...node.link.map(v => v as MailFolder)];
        }
        return observableOf(folders);
    }

    initDataSource() {
        console.log("[initDataSource]");
        this.emailTemplateFolder = this.dataSource.data.find(f => f.name === MailConstants.EMAIL_TEMPLATE);
        this.emailTemplateFolder = !!this.emailTemplateFolder ?
        this.emailTemplateFolder : {id: "", name: "Email Volergen", originalFolder: ""};
        if (!this.dataSource.data) {
            this.dataSource.data = this.folders.slice(0, 10);
        } else if (this.currentRange) {
            this.dataSource.data = this.folders.slice(this.currentRange.start, this.currentRange.end);
        }
        this.changeDetectionRef.markForCheck();
    }

    ngAfterViewInit() {
        console.log("[FolderListComponent]", this.folders);
        this.emailTemplateFolder = this.dataSource.data.find(f => f.name === MailConstants.EMAIL_TEMPLATE);
        this.emailTemplateFolder = !!this.emailTemplateFolder ?
        this.emailTemplateFolder : {id: "", name: "Email Volergen", originalFolder: ""};
        this.changeDetectionRef.markForCheck();
        this.virtualScroll.renderedRangeStream.pipe(takeUntil(this.isAlive$)).subscribe(range => {
            this.dataSource.data = this.folders.slice(range.start, range.end);
            this.currentRange = range;
            this.changeDetectionRef.markForCheck();
        });
    }

    validate(event: ResizeEvent): boolean {
        const MIN_DIMENSIONS_PX: number = 100;
        const MAX_DIMENSIONS_PX: number = 500;
        if (event.rectangle.height && ((event.rectangle.height < MIN_DIMENSIONS_PX)
        && (event.rectangle.height > MAX_DIMENSIONS_PX))
        ) {
        return false;
        }
        return true;
    }

    onResizing(event: ResizeEvent): void {
        const MIN_DIMENSIONS_PX: number = 100;
        const MAX_DIMENSIONS_PX: number = 500;
        if (event.rectangle && event.rectangle.height && ((event.rectangle.height > MIN_DIMENSIONS_PX)
        && (event.rectangle.height < MAX_DIMENSIONS_PX))
        ) {
            const folderList = document.querySelector(".user-folders-viewport.cdk-virtual-scroll-viewport");
            (<HTMLElement>folderList).style.height = event.rectangle.height + "px";
        }
        this.changeDetectionRef.markForCheck();
    }
}
