
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ConfigService } from "../config.service";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import { SearchItem } from "../shared/models/search-item";
import { Store } from "@ngrx/store";
import { MailRootState } from "../mail/store";
import { getMailFolders } from "src/app/mail/store/selectors";
import { filter } from "rxjs/internal/operators/filter";
import { take } from "rxjs/internal/operators/take";
import { getIncludeSharedItems } from "src/app/reducers";
import { MailUtils } from "../mail/utils/mail-utils";
import { MailFolder } from "src/app/mail/models/mail-folder.model";

@Injectable()
export class HeaderService {
  private isAlive$ = new Subject<boolean>();
  openSubFolder: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  openRefineSearchDialog: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private httpService: HttpService,
    private store: Store<MailRootState>,
    private configService: ConfigService) { }

  searchRequest(searchText: string, types: string): Observable<any> {
    let searchInShareFolders = false;
    this.store.select(getIncludeSharedItems).pipe(take(1)).subscribe(value => {
      searchInShareFolders = value;
    });
    let query = searchText;
    if (searchInShareFolders) {
      const shareFoldersQuery =  this.getMailShredFolders();
      query = "(" + searchText + ")" + " (" + shareFoldersQuery + ")";
    }
    const body = {
      limit: "10",
      query: query,
      types: types
    };
    return this.httpService.post<any>(this.configService.API_URL + "api/searchRequest", body);
  }

  parseSearchItem(res: any): SearchItem[] {
    const searchItem: SearchItem[] = [];
    if (res.m) {
      if (res.m.su) {
        searchItem.push({ type: "message", item: res.m.su });
      } else {
        res.m.map(message => {
          if (message.su) {
            searchItem.push({ type: "message", item: message.su });
          }
        });
      }
    }
    return searchItem;
  }

  getMailShredFolders(): string {
    let query = "";
    let response: any;
    this.store.select(getMailFolders).pipe(filter(f => !!f)).pipe(take(1)).subscribe(res => {
      if (res && res.length > 0) {
         response = res;
      }
    });
    return MailUtils.getMailShareFoldersQuery(response);
  }
}
