
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef } from "@angular/core";
import { DateAdapter } from "../date-adapters/date-adapter";
import { TranslateService } from "@ngx-translate/core";
import { CommonUtils } from "src/app/common/utils/common-util";
import { Store } from "@ngrx/store";
import { CalendarState } from "src/app/reducers/calendar.reducer";
import { RootState } from "src/app/reducers";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { getTagById } from "src/app/mail/store/selectors";
import { take, takeUntil } from "rxjs/operators";
import { ElectronService } from "src/app/services/electron.service";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { Subject } from "rxjs";
import { MailTag } from "src/app/mail/models/mail-tag.model";
import { CommonRepository } from "src/app/mail/repositories/common-repository";
import * as _ from "lodash";
import { CalendarRepository } from "../../repositories/calendar.repository";
import { CommonService } from "src/app/services/ common.service.";


@Component({
  selector: "vp-calendar-appointment-title",
  templateUrl: "./calendar-appointment-title.component.html",
})
export class CalendarAppointmentTitleComponent implements OnDestroy , OnChanges {
  constructor(
    protected dateAdapter: DateAdapter,
    protected translate: TranslateService,
    private store: Store<CalendarState | RootState>,
    private broadcaster: MailBroadcaster,
    private commonRepository: CommonRepository,
    private electronService: ElectronService,
    public calendarRepository: CalendarRepository,
    public commonService: CommonService,
    private changeDetectionRef: ChangeDetectorRef,

  ) {
    this.daysInWeek = this.calendarRepository.daysInWeek;
    this.noTitle = this.translate.instant("CALENDARS.NO_TITLE");
    const language =  this.electronService.isElectron
    ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE) : localStorage.getItem(MailConstants.MAIL_LANGUAGE);
    if (!!language && language !== null) {
      this.locale = language;
    } else {
      this.locale = "en";
    }
    this.broadcaster.on<any>("appointmentTimeChanged").pipe(takeUntil(this.isAlive$)).subscribe(v => {
      if (v && v.event.eventId === this.event.eventId) {
        this.sampleAppointment = {};
        this.sampleAppointment.start = v.newStart;
        this.sampleAppointment.end = v.newEnd;
      }
    });
    this.broadcaster.on<any>("appointmentTimeEnded").pipe(takeUntil(this.isAlive$)).subscribe(v => {
      if (v && v.event.eventId === this.event.eventId) {
        this.sampleAppointment = null;
      }
    });
    this.commonRepository.getZimbraTagsList().pipe(takeUntil(this.isAlive$)).subscribe(allTags => {
      this.allZimbraTags = allTags;
    });

    const mobileScreen = CommonUtils.isOnMobileDevice();
    if (mobileScreen) {
        this.isMobileScreen = true;
    }
  }
  @Input() event: any;

  @Input() customTemplate: TemplateRef<any>;

  @Input() view: string;

  noTitle: string = "";

  locale: string = "en";
  rainbowTagIcon = CommonUtils.getFullUrl("/assets/images/icon-rainbow-tag.svg", "calendar");
  sampleAppointment: any;
  allZimbraTags: MailTag[] = [];

  daysInWeek: any;
  isMobileScreen: boolean = false;
  private isAlive$ = new Subject<boolean>();

  eventDescriptionText: string = "";

  pastCheck(): boolean {
    return new Date() > this.event.end;
  }

  ngOnDestroy(): void {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("calendarAppointmentTitle event: ", this.event);
    this.getMembersList();
  }

  checkTimeSpanUnderAnHour(): boolean {
    let timeSpan = 0;
    if (this.event.end && this.event.start) {
      timeSpan = this.dateAdapter.differenceInMinutes(this.event.end, this.event.start);
    }

    return timeSpan <= 30;
  }

  isLightColor(color: string): boolean {
    // if (!!color && color !== "" && color !== null) {
    //   const isDark = CalenderUtils.lightOrDark(color) === "dark";
    //   if (isDark) {
    //       return false;
    //   } else {
    //       return true;
    //   }
    // }
    // return false;
    return true;
  }

  getTagColor(ids: string): string {
    let color = MailConstants.DEFAULT_COLOR;
    if (ids) {
      const tags = ids.split(",");
      this.store.select(state => getTagById(state, tags[0])).pipe(take(1)).subscribe(t => {
        if (!!t) {
          if (t.color && MailConstants.COLOR_LIST[t.color]) {
            t.color = MailConstants.COLOR_LIST[t.color];
          }
          if (t.rgb) {
            t.rgb = t.rgb.toLowerCase();
            if (t.rgb === "#999") {
              t.rgb = "#999999";
            }
          }
          color = t.rgb || t.color || color;
        }
      });
    }
    return color;
  }

  getTagColorByNames(names: string = this.event.tn): string {
    let color = MailConstants.DEFAULT_COLOR;
    if (names) {
      const tagNames = names.split(",");
      const tags = this.allZimbraTags.filter(tt => tt.name.toLowerCase() === tagNames[0].toLowerCase());
      if (!!tags && !!tags[0]) {
        // console.log("getTagColorByNames tags: ", tags);
        const t = tags[0];
        if (t.color && MailConstants.COLOR_LIST[t.color]) {
          t.color = MailConstants.COLOR_LIST[t.color];
        }
        if (t.rgb) {
          t.rgb = t.rgb.toLowerCase();
          if (t.rgb === "#999") {
            t.rgb = "#999999";
          }
        }
        color = t.rgb || t.color || color;

      }
    }
    return color;
  }
  getMembersList() {
    if (this.calendarRepository.daysInWeek === 1) {
      const body = {
        id: this.event.invId,
        ridz: this.event.inst[0].ridZ
      };
      this.commonService.getMsgRequest(body).pipe(take(1)).subscribe(resp => {
        if ( resp.m[0].inv[0].comp[0].descHtml &&  resp.m[0].inv[0].comp[0].descHtml[0]) {
          let HTMLdescription = resp.m[0].inv[0].comp[0].descHtml[0]._content;
          const plainText = this.getPlainTextFromHTML(HTMLdescription);
          this.eventDescriptionText = plainText;
        }
        this.event.participants = resp.m[0].inv[0].comp[0].at;
        this.changeDetectionRef.markForCheck();
      });
    } else {
      return;
    }

  }


  getPlainTextFromHTML(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  }

}
