
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div #calMonthView class="cal-month-view" [ngClass]="{'margin-left-85': !isSidebarExpanded}">
  <div (swipeleft)="onViewSwipe($event)" (swiperight)="onViewSwipe($event)">
    <vp-calendar-month-view-header
      [days]="columnHeaders"
      [locale]="locale"
      (columnHeaderClicked)="columnHeaderClicked.emit($event)"
      [customTemplate]="headerTemplate"
    >
    </vp-calendar-month-view-header>
  </div>
  <div class="cal-days">
    <div
      *ngFor="let rowIndex of view.rowOffsets; trackBy: trackByRowOffset"
      >
      <!-- [ngStyle]="{ height: rowOffsetHeight + 'px' }" -->
      <div class="cal-cell-row" (swipeleft)="onViewSwipe($event)" (swiperight)="onViewSwipe($event)">
        <div class="cal-cell cal-day-cell week-num-cell" *ngIf="showCalendarWeekNumber">
            {{ view.days[rowIndex].date | vpWeekNum }}
        </div>
        <vp-calendar-month-cell
          *ngFor="
            let day of view.days
              | slice: rowIndex:rowIndex + view.totalDaysVisibleInWeek;
            trackBy: trackByDate
          "
          [ngClass]="day?.cssClass"
          [day]="day"
          [openDay]="openDay"
          [locale]="locale"
          [tooltipPlacement]="tooltipPlacement"
          [tooltipAppendToBody]="tooltipAppendToBody"
          [tooltipTemplate]="tooltipTemplate"
          [tooltipDelay]="tooltipDelay"
          [customTemplate]="cellTemplate"
          [colWidth]="colCellWidth"
          [ngStyle]="{ backgroundColor: day.backgroundColor }"
          (vpCalClick)="dayClicked.emit({ day: day })"
          [clickListenerDisabled]="dayClicked.observers.length === 0"
          [refresh]="eventRefresh"
          (highlightDay)="toggleDayHighlight($event.event, true)"
          (unhighlightDay)="toggleDayHighlight($event.event, false)"
          mwlDroppable
          dragOverClass="cal-drag-over"
          (drop)="
            eventDropped(
              day,
              $event.dropData.event,
              $event.dropData.draggedFrom
            )
          "
          (eventClicked)="handleAppointmentClicked($event)"
          (dayHeaderClicked)="dayHeaderClicked.emit($event)"
          (onContextMenuClicked)="onContextMenuClicked.emit($event)"
          (onDoubleClicked)="handleDoubleClickedHandled($event)"
          (contextmenu)="hourSegmentContextMenuClicked.emit({ $event: $event, segment: day })"
          (longPressClick)="longPressClicked.emit($event)"
        >
        </vp-calendar-month-cell>
      </div>
      <vp-calendar-open-day-events
        [isOpen]="openRowIndex === rowIndex"
        [events]="openDay?.events"
        [customTemplate]="openDayEventsTemplate"
        [eventTitleTemplate]="eventTitleTemplate"
        [eventActionsTemplate]="eventActionsTemplate"
        (eventClicked)="eventClicked.emit({ event: $event.event })"
        mwlDroppable
        dragOverClass="cal-drag-over"
        (drop)="
          eventDropped(
            openDay,
            $event.dropData.event,
            $event.dropData.draggedFrom
          )
        "
      >
      </vp-calendar-open-day-events>
    </div>
  </div>
</div>
