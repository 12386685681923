
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Action } from ".";
import { Contact } from "../contacts/models/contact.model";
import { AppActionTypes } from "./app";

export class AllContactsActionTypes extends AppActionTypes {
  // load All Contacts
  static LOAD_ALL_CONTACTS = "[Contacts] Load All Contacts";
  static LOAD_ALL_CONTACTS_FAIL = "[Contacts] Load All Contacts Fail";
  static LOAD_ALL_CONTACTS_SUCCESS = "[Contacts] Load All Contacts Success";
}

export class LoadAllContacts implements Action {
  readonly type = AllContactsActionTypes.LOAD_ALL_CONTACTS;
}

export class LoadAllContactsFail implements Action {
  readonly type = AllContactsActionTypes.LOAD_ALL_CONTACTS_FAIL;
}

export class LoadALLContactsSuccess implements Action {
  readonly type = AllContactsActionTypes.LOAD_ALL_CONTACTS_SUCCESS;
  constructor(public payload: { contact: Contact[] }) { }
}
